<nav class="navbar navbar-expand-sm navbar-light">
  <a style="color: #cc0000" class="navbar-brand" [ngClass]="{ 'Homelablelink-osp': true }"
    (click)="activateMenu(true, 'logo')" href="#">
    <img class="cmpnylogo" src="./assets/images/toyota-logo.png" alt="Toyota Logo" />
    <label>OVERSEAS PARTS</label>
  </a>
  <div class="menu-group" id="fodContent" style="width: 100%; height: 100%; float: bottom">
    <mat-nav-list class="inline" style="width: fit-content; margin-right: 8px">
      <div class="language">
        <a class="home" mat-list-item (click)="activateMenu(true, 'home'); activationtab('0'); drop('')"
          [routerLink]="navlinks.fod.route" [ngClass]="{ lablelink: true, 'nav-active-home': activateHomeMenu }">
          <img class="home-icon" src="./assets/images/Shape 1903.png" alt="Home icon" />
        </a>
      </div>
    </mat-nav-list>
    <mat-nav-list class="inline">
      <div class="language">
        <ul>
          <li (click)="drop('ordering'); activationtab('1')" [ngClass]="{ 'nav-active': activatePartsOrdering }"
            #toggleButton1>
            OS PARTS ORDERING
            <img src="./assets/images/baseline-keyboard_arrow_right-24px (1).svg" alt="dropdown icon" class="downarrow"
              #toggleArrow1 /><span *ngIf="!activatePartsOrdering" class="divider"></span>
          </li>
          <div class="dropdown" *ngIf="id === 'ordering'" #menu>
            <ul (click)="activationtab('1')" *ngIf="access1.isPartsAvailable">
              <li [routerLink]="['ordering/jobstatus']">
                {{ navlinks.main.jobstatus.displayName }}
              </li>
              <li [routerLink]="['ordering/orderforecast']">
                {{ navlinks.main.orderforecast.displayName }}
              </li>
              <li [routerLink]="['ordering/adjustmentinquiry']">
                {{ navlinks.main.adjustmentinquire.displayName }}
              </li>
              <li [routerLink]="['ordering/rundownmaintenance']">
                {{ navlinks.main.rundownmaintenance.displayName }}
              </li>

              <li *ngIf="access1.bulkadjustmentview" [routerLink]="['ordering/bulkadjustment']">
                {{ navlinks.main.bulkadjustment.displayName }}
              </li>

              <li [routerLink]="['ordering/reports']">
                {{ navlinks.main.reports.displayName }}
              </li>
              <li [routerLink]="['ordering/famaintenance']">
                {{ navlinks.main.fluctuation.displayName }}
              </li>
              <li [routerLink]="['ordering/getsudostatus']">
                {{ navlinks.main.getsudo.displayName }}
              </li>
              <li [routerLink]="['ordering/airfreightorder']">
                {{ navlinks.main.airfreightorder.displayName }}
              </li>
             
            </ul>
          </div>
        </ul>
      </div>
    </mat-nav-list>
    <!-- pipeline drop -->

    <mat-nav-list class="inline">
      <div class="language">
        <ul>
          <li (click)="drop('pipe'); activationtab('2')" [ngClass]="{ 'nav-active': activatePipeLine }" #toggleButton2>
            OS PIPELINE
            <img src="./assets/images/baseline-keyboard_arrow_right-24px (1).svg" alt="dropdown icon" class="downarrow"
              #toggleArrow2 /><span class="divider"></span>
          </li>
          <div class="dropdown" *ngIf="id === 'pipe'" #menu>
            <ul (click)="activationtab('2')">
              <li [routerLink]="['pipe/jobstatus']" *ngIf="accessPipe.screenAccess.pipeJobStatus">
                {{ ospipenavlinkitems.main.pipejobStatus.displayName }}
              </li>
              <li [routerLink]="['pipe/partsinquire']" *ngIf="accessPipe.screenAccess.partsInquire">
                {{ ospipenavlinkitems.main.PartsInquire.displayName }}
              </li>

              <li [routerLink]="['pipe/partsmaintenance']" *ngIf="accessPipe.screenAccess.partsMaintenance">
                {{ ospipenavlinkitems.main.PartMaintenance.displayName }}
              </li>
              <li [routerLink]="['pipe/containermaintenance']" *ngIf="accessPipe.screenAccess.containerMaintenance">
                {{ ospipenavlinkitems.main.ContainerMaintenance.displayName }}
              </li>
              <li [routerLink]="['pipe/sealverification']" *ngIf="accessPipe.screenAccess.sealVerification">
                {{ ospipenavlinkitems.main.SealVerification.displayName }}
              </li>
              <li [routerLink]="['pipe/reports']" *ngIf="accessPipe.screenAccess.pipeReports">
                {{ ospipenavlinkitems.main.pipeReports.displayName }}
              </li>
              <li [routerLink]="['pipe/productionprogress']" *ngIf="accessPipe.screenAccess.productionProgress">
                {{ ospipenavlinkitems.main.productionProgress.displayName }}
              </li>

              
            </ul>
          </div>
        </ul>
      </div>
    </mat-nav-list>

    <mat-nav-list class="inline">
      <div class="language">
        <ul>
          <li (click)="drop('devanning'); activationtab('3')" [ngClass]="{ 'nav-active': activateLlp }" #toggleButton3>
            OS INVENTORY MANAGEMENT
            <img src="./assets/images/baseline-keyboard_arrow_right-24px (1).svg" alt="dropdown icon" class="downarrow"
              #toggleArrow3 /><span *ngIf="!activateLlp" class="divider"></span>
          </li>
          <div class="dropdown" *ngIf="id === 'devanning'" #menu>
            <ul (click)="activationtab('3')">
              <!-- <li *ngFor="let data of osInventoryLinkItems.main" [routerLink]="data.link">{{ data.displayName}}</li> -->
              <li [routerLink]="['inventory/modulestocking']" >
                {{ ospipenavlinkitems.main.moduleStocking.displayName }}
              </li>
              <li [routerLink]="['inventory/stocking-pace-inquiry']" >
                {{ ospipenavlinkitems.main.paceInquire.displayName }}
              </li>
              <li [routerLink]="['inventory/stocking-pace-maintenance']" >
                {{ ospipenavlinkitems.main.paceMaintenance.displayName }}
              </li>
              <li [routerLink]="['inventory/module-type']" >
                {{ ospipenavlinkitems.main.moduleType.displayName }}
              </li>
              <li [routerLink]="['inventory/container-ordering']" >
                {{ ospipenavlinkitems.main.containerOrdering.displayName }}
              </li>
            </ul>
          </div>
        </ul>
      </div>
    </mat-nav-list>

    <mat-nav-list class="inline">
      <div class="language">
        <ul>
          <li (click)="drop('setup'); activationtab('4')" [ngClass]="{ 'nav-active': activatesetup }" #toggleButton4>
            SETUP
            <img src="./assets/images/baseline-keyboard_arrow_right-24px (1).svg" alt="dropdown icon" class="downarrow"
              #toggleArrow4 /><span *ngIf="!activatesetup" class="divider"></span>
          </li>
          <div class="dropdownsetup" *ngIf="id === 'setup'">
            <ul (click)="activationtab('4')">
              <li [routerLink]="['setup/master']">
                {{ navlinks.main.master.displayName }}
              </li>
              <li [routerLink]="['setup/calendar']">
                {{ navlinks.main.calendar.displayName }}
              </li>
              <li [routerLink]="['setup/shifts']">
                {{ navlinks.main.shifts.displayName }}
              </li>

             
            </ul>
          </div>
        </ul>
      </div>
    </mat-nav-list>
    <mat-nav-list class="inline">
      <div class="language">
        <ul>
          <li (click)="drop('help'); activationtab('5')" [ngClass]="{ 'nav-active': activateHelp }" #toggleButton5>
            HELP
            <img src="./assets/images/baseline-keyboard_arrow_right-24px (1).svg" alt="dropdown icon" class="downarrow"
              #toggleArrow5 />
            <span *ngIf="!activateHelp" class="divider"></span>
          </li>
          <div class="dropdownsetup" *ngIf="id === 'help'">
            <ul (click)="activationtab('5')">
              <li>
                <a target="_blank" rel="noreferrer noopener" href="assets\data\OSPARTS User Manual V1.pdf">OSPARTS User
                  Manual</a>
              </li>
              <li>
                <!-- <a href="#" id="atag">
                 <iframe style="display:none;" id="myFrame"></iframe> ospipe user Manual</a> -->
                <a target="_blank" (click)="getUserManuals3Link()" rel="noreferrer noopener">OSPIPE User Manual</a>
              </li>
              <li [routerLink]="['help/trainingvideos']">
                {{ navlinks.main.help.displayName }}
              </li>
              <li [routerLink]="['help/releasenotes']">OSPARTS Release Notes</li>
              <li [routerLink]="['help/pipereleasenotes']">OSPIPE Release Notes</li>
            </ul>
          </div>
        </ul>
      </div>
    </mat-nav-list>

    <mat-nav-list class="inline" style="float: right; margin-bottom: 10px; margin-top: 10px">
      <a>
        <mat-form-field appearance="fill" [style.width.px]="100">
          <mat-label [disabled]="isDisabled"
            style="font: Bold 11px/2px Roboto, sans-serif; color: #161718">NAMC</mat-label>
          <mat-select disableOptionCentering panelClass="myPanelClass" *ngIf="!showPlantName" name="plant_name"
            [(value)]="plantName" (selectionChange)="onNAMCChange($event)"
            style="font: Bold 11px/13px Roboto, sans-serif" [disabled]="!allowall">
            <mat-option disabled>-SELECT NAMC-</mat-option>
            <mat-option *ngFor="let data of namcData; let i = index" [value]="data">
              {{ data.label }}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </a>
      <a mat-list-item>
        <div class="usermenu" [matMenuTriggerFor]="beforeMenu">
          {{ userName }}
          <mat-icon class="user-arrow-down">expand_more</mat-icon>
        </div>
        <mat-menu class="usermenu" #beforeMenu="matMenu" xPosition="before">
          <button *ngFor="let ct of roles" class="menu-items-text" mat-menu-item>
            <mat-icon>{{ navlinks.user.person.icon }} </mat-icon> {{ ct }}
          </button>
          <button class="menu-items-text" mat-menu-item (click)="logout()">
            <mat-icon>{{ navlinks.user.logout.icon }} </mat-icon>
            {{ navlinks.user.logout.displayName }}
          </button>
        </mat-menu>
      </a>
    </mat-nav-list>
  </div>
</nav>