import { Component } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { ICellRendererParams } from 'ag-grid-community';
import { ActivatedRoute, Router } from '@angular/router';
@Component({
  selector: 'delete-btn-cell-co-renderer',
  template: `
    <span class="detailiconhome">
      <img
        class="detailiconhome"
        src="assets/images/icon-delete.svg"
        title="delete"
        alt="Delete"
        style="width=20px;height=20px"
        (click)="btnClickedHandler($event)" 
      />
    </span>
  `,
  // styles: [
  //   `
  //     .detailiconhome {
  //       padding: 0;
  //       margin: 0;
  //     }
  //     .detailiconhome img {
  //       display: block;
  //       margin: 0;
  //       padding: 0;
  //     }
  //   `,
  // ],
})
// <img
      
//         class="img-tool"
//         src="./assets/images/edit.svg"
//         alt="edit button icon"
//         title="Edit"
//       />



export class DeleteBtnCellCoRender implements ICellRendererAngularComp {

  pageName: any;
  detailPage: any;
  deleteFlag: boolean = false;
  deleteBtn: any;
  disabled: boolean= false;
  access;
  
  constructor(private router: Router, private route: ActivatedRoute, ){}
    refresh(params: ICellRendererParams): boolean {
		return true;
	}

  private params: any;
  agInit(params: any): void {
    this.access = JSON.parse(localStorage.getItem('prodProgress'));

    this.params = params;     
  }
  btnClickedHandler(event: any) {
    this.params.clicked(this.params.data);
  }

}