import { Component } from '@angular/core';

@Component({
  selector: 'app-actual-arrival-editable',
  template: `
    <div style="margin: 3px 0px 6px;">
  <input #input [(ngModel)]="value" type="date" class="grid-cell-edit-layout " (change)="textUpdated()" />
  <small *ngIf="errorMsg" class="error">{{errorMsg}}</small><br>
  <small *ngIf="!value && checkKeyInObject('isArrived') && params.data.isArrived  && params.context.componentParent.submitted" class="error">In case of Arrived ETA is required</small>
</div>
  `,
  styles: [
    `
      input::-webkit-outer-spin-button,
      input::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
      }
      .grid-cell-edit-layout {
        width: 100%;
        border: 1px solid #d1d1d1;
      }
      .invalid-text {
        border: 2px solid red !important;
      }
      .error{color:red}
    `,
  ],
})
export class EtaDateEditableComponent {
  public params: any;
  showInput = true;
  value: any;
  isEmpty = true;
  errorMsg = null;

  // lastDayOfVanMonth = localStorage.getItem('lastDayOfVanMonth');


  constructor() {

  }

  agInit(params: any): void {
    params.data.etaDate = this;
    if (params.column === 'eta_date') {
      this.value = params.data.eta_date;
    }
    this.params = params;
  }

  // called when the cell is refreshed
  refresh(params: any): boolean {
    this.params = params;
    return true;
  }


  textUpdated() {
    if (this.params.column === 'eta_date') {
      if (this.value !== this.params.data.eta_date) {
        this.params.data.eta_date = this.value;
        this.params.data.isUpdated = true
      }
    } else {
      this.params.data.finalquantity = this.value;
    }
    // if(this.value)
    //   this.checkNonworkingday(this.params.data.partIndex, this.value);
  }

  onKeyPress(event) {
    if (event.target.value.length <= 4) {
      return true;
    } else {
      return false;
    }
  }

  checkNonworkingday(part_index, selected_date): void {
    let validDate: any = this.params.context.componentParent.checkNonworkingday(part_index, selected_date);
    if (validDate) {
      this.errorMsg = validDate.msg;
      if(validDate.check == 1){
      this.value = null;
      this.params.data.eta_date = null;
      }
    } else {
      this.errorMsg = null;
    }
  //   setTimeout(()=>{ 
  //     this.errorMsg = null;
  // }, 10000);
  }
  checkKeyInObject(key: string): boolean {
    return this.params.data.hasOwnProperty(key);
  }
}
