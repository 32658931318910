
import { Component, ComponentFactoryResolver, Inject, ViewContainerRef } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { ICellRendererParams } from 'ag-grid-community';
import { Router, ActivatedRoute } from '@angular/router';
import { MAT_DIALOG_DATA } from '@angular/material/dialog'; // If you're using Angular Material Dialog
import { EditBreaksComponent } from '../../edit-breaks/edit-breaks.component';
import {
  MatLegacyDialog as MatDialog,
  MatLegacyDialogConfig as MatDialogConfig,
} from '@angular/material/legacy-dialog';
@Component({
  selector: 'edit-btn-cell-renderer',
  template: `
    <span class="detailiconhome">
      <img
        class="img-tool"
        *ngIf="access || access1"
        src="./assets/images/edit.svg"
        alt="edit button icon"
        title="Edit"
        (click)="onEditClick()"
      />
    </span>
  `,
})
// (click)="btnClickedHandler($event)"
// *ngIf="editable"

export class EditBtnCellRender implements ICellRendererAngularComp {
   private params: any;
  access;
  access1


  constructor(
    private vcr: ViewContainerRef,  // This is needed for dynamically adding components
    private cfr: ComponentFactoryResolver // Component Factory Resolver
  ) {}

  agInit(params: ICellRendererParams): void {
    this.access = JSON.parse(localStorage.getItem('shiftAccess1'));
    this.access1 = JSON.parse(localStorage.getItem('shiftAccess2'));

    this.params = params;
  }

  refresh(params: ICellRendererParams): boolean {
    return true;
  }

  // This function is for handling the click event of the "Edit" button
  onEditClick():void {
    this.params.clicked(this.params.data)
    // this.showEditPopup(this.params);
    // console.log(this.params)
    // const rowIndex = this.params.node.rowIndex;
    // const rowNode = this.params.api.getDisplayedRowAtIndex(rowIndex);
    // this.params.api.startEditingCell({rowIndex:rowIndex,colKey:'time_from'});
    // this.params.api.startEditingCell({rowIndex:rowIndex,colKey:'time_to'});
   
  
  }

  // Show the edit popup inside the grid
  // showEditPopup(params: any) {
  //   const rowData = params.data;

  //   // Dynamically create the popup component and inject it into the grid
  //   const componentFactory = this.cfr.resolveComponentFactory(EditBreaksComponent);
  //   const componentRef = this.vcr.createComponent(componentFactory);
    
  //   // Pass the row data to the popup component
  //   componentRef.instance.data = rowData;

  //   // You can also add logic to close the popup or perform any other actions.
  //   console.log('Edit popup opened for row:', rowData);
  // }

  // The rendering logic for the "Edit" button
  // actionCellRenderer(params) {
  //   let eGui = document.createElement("div");

  //   let editingCells = params.api.getEditingCells();
  //   let isCurrentRowEditing = editingCells.some((cell) => {
  //     return cell.rowIndex === params.node.rowIndex;
  //   });

  //   if (isCurrentRowEditing) {
  //     eGui.innerHTML = `
  //       <button class="action-button update" data-action="update">Update</button>
  //       <button class="action-button cancel" data-action="cancel">Cancel</button>
  //     `;
  //   } else {
  //     eGui.innerHTML = `
  //       <button class="action-button edit" data-action="edit">Edit</button>
  //     `;

  //     // Add event listener to the "Edit" button
  //     const editButton = eGui.querySelector('.action-button.edit');
  //     if (editButton) {
  //       editButton.addEventListener('click', () => {
  //         this.showEditPopup(params);
  //       });
  //     }
  //   }

  //   return eGui;
  // }
}