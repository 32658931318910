import { Component, HostListener, Inject, Input, OnInit, ViewChild, Pipe, OnDestroy } from '@angular/core';
import { HeaderCheckboxComponent } from '../core/grid/header-checkbox/header-checkbox.component';
import { GridRefreshComponent } from '../core/grid/grid-refresh/grid-refresh.component';
import { RowCheckboxComponent } from '../core/grid/row-checkbox/row-checkbox.component';
import { NgxSpinnerService } from 'ngx-spinner';
import { CustomTooltipComponent } from '../core/custom-tooltip/custom-tooltip.component';
import { GriddataService } from '../services/griddata.service';
import {
  MatLegacyDialog as MatDialog,
  MatLegacyDialogConfig as MatDialogConfig,
} from '@angular/material/legacy-dialog';
import { GridProdVolEditorComponent } from '../core/grid/grid-text-editrundown.component';
import { ExcelService } from '../services/excel.service';
import { OrderforecastService } from '../services/orderforecast/orderforecast.service';

import {
  allowedFilesizeInBytes,
  allowedFileTypes,
  checkAccess,
  dateFilterComparator,
  warningMessage,
  successMessage,
  getCellStyleConfig,
  resetSortingAndFilters,
  freezingUploadMesssage,
  getRowCheckboxSyleConfig,
  rundownFreezeMessageforCalc,
} from '../constants';

import * as moment from 'moment';
import { RundownService } from '../services/rundown/rundown.service';
import * as XLSX from 'xlsx';
import { MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { GridVanningVolEditorComponent } from '../core/grid/grid-vanvolum-edit.component';
import { GridProdDtEditorComponent } from '../core/grid/grid-prodDt-edit.component';
import { GridVanDtEditorComponent } from '../core/grid/grid-vanDt-edit.component';
import { GridOrdDtEditorComponent } from '../core/grid/grid-ordDt-edit.component';
import { GridCalcDtEditorComponent } from '../core/grid/grid-calcDt-edit.component';
import { RundownDialogComponent } from '../core/rundown-dialog/rundown-dialog.component';
import { BulkadjustmentService } from '../services/bulk-adjustment/bulkadjustment.service';
import { RundownUploadComponent } from './rundown-upload/rundown-upload.component';
import { GridApi } from 'ag-grid-community';
import { forkJoin, Subscription } from 'rxjs';
import { OSPStateService } from '../services/osp-state.service';
import { dateIsValid, dateComparator, freezingMesssage } from '../constants';
import { GetSudoStatusService } from '../services/get-sudo-status/get-sudo-status.service';
import { ConfirmCancelDailogComponent } from '../core/confirm-cancel-dailog/confirm-cancel-dailog.component';
import { LookupService } from '../services/lookup/lookup.service';
import * as mm from 'moment-timezone';
import { log } from 'console';

@Component({
  selector: 'app-run-down-maintenance',
  templateUrl: './run-down-maintenance.component.html',
  styleUrls: ['./run-down-maintenance.component.scss'],
})
export class RunDownMaintenanceComponent implements OnInit, OnDestroy {
  namcValue;
  namcName = '';
  user = '';
  userRole = '';
  destCode = '';
  uploadError = false;
  rowData = [];
  @ViewChild('osPartsGrid') osPartsGrid;
  columnDefsMaster;
  columnDefs;

  public defaultColDef;
  rowSelection;

  msgNoContent = 'No Content';
  gridOptions;
  paginationPageSize;
  paginationNumberFormatter;
  pagination = 'true';
  context: any;
  editType;
  suppressClickEdit = true;
  tooltipShowDelay = 0;
  frameworkComponents = { customTooltip: CustomTooltipComponent };

  editEnabled: boolean = false;
  uploadEnabled: boolean = false;
  freezeStatus: boolean = false;
  filter: boolean = false;
  showToggleAll: boolean = false;
  showClear: boolean = true;

  selectedDestCode: any = [];
  selectedLinePath: any = [];

  successMessage = '';
  warningMessage = '';
  vanningFrom: any;
  vanningTo: any;

  linePathList: any = [];

  isEditMode: boolean = false;

  selectedShowHideList: any = [];
  headerCheckboxChecked: boolean = false;
  showHideList: any = [];
  offset = 0;
  dataCount: number = 0;
  userName = '';
  currentMonthData = [];
  lastDayOfVanMonth = '';
  minVanningFrom = '';
  fileBuffer: any;
  modifiedRowData = [];
  batchRunLogID = 0;
  timesEditApiCalled = 0;
  editApiStartPosition = 0;
  editApiDefaultLimit = 5000;
  editApiEndPosition = this.editApiDefaultLimit;
  rundownSubscription$: Subscription;
  editRowSelected: boolean = false;
  getSudoDate;
  getSudoDatesList;
  //agRightAlignedCell = 'ag-right-aligned-cell';
  fileName = '';
  batchCheck = true;
  finalInialBatchCheck = false;
  freezingMesssage = '';
  maxVanDate = '';
  maxProdDate = '';
  gridApi: GridApi;
  batchStatus = 0;
  access1;
  itContact: string;
  sudoDate = [];

  dateFormat = 'MM/DD/YYYY';
  newDate = String(mm().tz('US/Michigan').format(this.dateFormat)).substring(0, 10);

  modalComponent = 'modal-component';

  defaultPageSize: any = 100000;

  constructor(
    private readonly spinner: NgxSpinnerService,
    private readonly excelService: ExcelService,
    private readonly gridDataService: GriddataService,
    public readonly dialog: MatDialog,
    private readonly rundownService: RundownService,
    private readonly service: OrderforecastService,
    private readonly bulkadjustmentService: BulkadjustmentService,
    private readonly stateService: OSPStateService,
    private readonly getSudoService: GetSudoStatusService,
    private readonly lookupService: LookupService
  ) {
    this.context = {
      componentParent: this,
    };
  }

  ngOnDestroy(): void {
    this.rundownSubscription$.unsubscribe();
  }

  ngOnInit(): void {
    this.gridOptions = this.gridDataService.getGridOptions();
    this.gridOptions.onGridReady = function (params) {
      params.api.hideOverlay();
      this.gridApi = params.api;
    };

    this.gridOptions.defaultColDef.cellStyle = getCellStyleConfig;

    this.columnDefsMaster = [
      {
        headerName: '',
        field: 'rowCheckBox',

        width: 44,
        headerComponentParams: {
          headerChecked: this.headerCheckboxChecked,
        },
        headerComponentFramework: HeaderCheckboxComponent,
        floatingFilterComponentFramework: GridRefreshComponent,
        floatingFilterComponentParams: { suppressFilterButton: true },
        cellClass: 'ui-grid-cell-contents-auto',

        cellStyle: getRowCheckboxSyleConfig,
        cellRendererFramework: RowCheckboxComponent,
        floatingFilter: true,
        tooltipValueGetter: (params) => {
          if (new Date(params.data.prod_date) < new Date(this.newDate)) {
            return 'You are not allowed to edit past records.';
          } else {
            return '';
          }
        },
      },

      {
        headerName: 'Line Path',
        field: 'line_path',
        width: 100,
        floatingFilter: true,
      },
      {
        headerName: 'Calc DT',
        headerTooltip: 'Calc Date(mm/dd/yyyy)',

        field: 'rundownData',
        cellRendererFramework: GridCalcDtEditorComponent,
        cellRendererParams: { column: 'calc_date' },
        sortable: true,
        width: 130,
        floatingFilter: true,

        comparator: dateComparator,
        valueGetter: (params) => {
          if (!this.editEnabled || !params.data.rowCheckBox) {
            return params.data.calc_date;
          } else {
            const value = params.data.rundownData?.value ?? params.data.calc_date;

            return value;
          }
        },

        filter: 'agDateColumnFilter',
        filterParams: {
          comparator: dateFilterComparator,
        },
      },
      {
        headerName: 'Order DT',
        headerTooltip: 'Order Date(mm/dd/yyyy)',
        field: 'rundownOrderDate',
        cellRendererFramework: GridOrdDtEditorComponent,
        cellRendererParams: { column: 'order_date' },
        valueGetter: (params) => {
          if (!this.editEnabled || !params.data.rowCheckBox) {
            return params.data.order_date;
          } else {
            const value = params.data.rundownOrderDate?.value ?? params.data.order_date;

            return value;
          }
        },

        sortable: true,
        width: 130,
        floatingFilter: true,

        comparator: dateComparator,
        filter: 'agDateColumnFilter',
        filterParams: {
          comparator: dateFilterComparator,
        },
      },
      {
        headerName: 'Van DT',
        headerTooltip: 'Vanning Date(mm/dd/yyyy)',
        field: 'rundownVanningDate',
        cellRendererFramework: GridVanDtEditorComponent,
        cellRendererParams: { column: 'vanning_date' },

        valueGetter: (params) => {
          if (!this.editEnabled || !params.data.rowCheckBox) {
            return params.data.vanning_date;
          } else {
            const value = params.data.rundownVanningDate?.value ?? params.data.vanning_date;

            return value;
          }
        },
        sortable: true,
        width: 130,
        floatingFilter: true,

        comparator: dateComparator,
        filter: 'agDateColumnFilter',
        filterParams: {
          comparator: dateFilterComparator,
        },
      },

      {
        headerName: 'Van Vol',
        width: 90,

        field: 'rundownVanningVol',
        filter: 'agNumberColumnFilter',
        floatingFilterComponentParams: { suppressFilterButton: false },
        cellRendererFramework: GridVanningVolEditorComponent,
        cellRendererParams: { column: 'vanning_qty' },

        valueGetter: (params) => {
          if (!this.editEnabled || !params.data.rowCheckBox) {
            return params.data.vanning_qty;
          } else {
            const value = params.data.rundownVanningVol?.value ?? params.data.vanning_qty;

            return value;
          }
        },
        filterParams: {
          numberParser: (text) => {
            return text === null ? null : parseFloat(text.replace(',', '.'));
          },
        },
        floatingFilter: true,
        sortable: true,
        //cellClass: this.agRightAlignedCell,
      },
      {
        headerName: 'Prod DT',
        headerTooltip: 'Prod Date(mm/dd/yyyy)',
        sortable: true,
        width: 130,
        floatingFilter: true,

        field: 'rundownProdDate',
        cellRendererFramework: GridProdDtEditorComponent,
        cellRendererParams: { column: 'prod_date' },

        valueGetter: (params) => {
          if (!this.editEnabled || !params.data.rowCheckBox) {
            return params.data.prod_date;
          } else {
            const value = params.data.rundownProdDate?.value ?? params.data.prod_date;

            return value;
          }
        },
        comparator: dateComparator,
        filter: 'agDateColumnFilter',

        filterParams: {
          comparator: dateFilterComparator,
        },
      },
      {
        headerName: 'Prod Vol',
        field: 'rundownProdVolume',
        floatingFilterComponentParams: { suppressFilterButton: false },
        filter: 'agNumberColumnFilter',
        cellRendererFramework: GridProdVolEditorComponent,
        cellRendererParams: { column: 'prod_qty' },
        width: 110,
        floatingFilter: true,

        valueGetter: (params) => {
          if (!this.editEnabled || !params.data.rowCheckBox) {
            return params.data.prod_qty;
          } else {
            const value = params.data.rundownProdVolume?.value ?? params.data.prod_qty;

            return value;
          }
        },
        filterParams: {
          numberParser: (text) => {
            return text === null ? null : parseFloat(text.replace(',', '.'));
          },
        },
      },
      {
        headerName: 'Calc Status',
        field: 'order_status',
        width: 150,
        floatingFilter: true,

        valueGetter: function (params) {
          if (params.data.order_status === 'F') {
            return 'Final Calc Completed';
          } else if (params.data.order_status === 'C') {
            return 'Initial Calc Completed';
          }
          return params.data.order_status;
        },
      },
      {
        headerName: 'PPR Status',
        field: 'ppr_status',
        width: 100,
        floatingFilter: true,
        headerClass: 'ag-theme-custom-text-right',
      },
      {
        headerName: 'Updated DT',
        headerTooltip: 'Updated Date (mm/dd/yyyy hh:mm:ss (Eastern))',
        field: 'date_time',
        width: 174,
        floatingFilter: true,
      },
      {
        headerName: 'User',
        field: 'userid',
        width: 198,
        floatingFilter: true,
        tooltipField: 'userid',
      },
    ];

    this.columnDefs = [...this.columnDefsMaster];

    this.rowSelection = 'multiple';
    this.editType = 'fullRow';

    this.rundownSubscription$ = this.stateService.getNamc().subscribe((observable) => {
      this.initializeData();
    });
  }

  initializeData() {
    this.spinner.show();
    this.itContact = localStorage.getItem('itContact');

    if (localStorage.getItem('namcvalue')) {
      this.namcValue = localStorage.getItem('namcvalue');
    }
    if (localStorage.getItem('namcName')) {
      this.namcName = localStorage.getItem('namcName');
    }
    if (localStorage.getItem('UserName')) {
      this.userName = localStorage.getItem('UserName');
    }
    if (localStorage.getItem('workdayId')) {
      this.user = localStorage.getItem('workdayId');
    }
    if (localStorage.getItem('UserRoles')) {
      this.userRole = localStorage.getItem('UserRoles');
    }
    if (localStorage.getItem('namcDestCode')) {
      this.destCode = localStorage.getItem('namcDestCode');
    }

    const rundownPermission = checkAccess();
    this.access1 = rundownPermission;

    this.onResetDropDown();
    this.uploadEnabled = false;
    this.uploadError = false;

    this.removeMessage();

    this.loadData();
  }

  isFinalForecastCompleted = () => {
    var data = {
      business_entity: this.namcValue,
      workid: this.user,
      user_role: this.userRole,
    };
    this.rundownService.isFinalForecastCompleted(data).subscribe({
      error: this.errorCallback,
      next: (res) => {
        this.spinner.hide();
        this.finalInialBatchCheck = res.body.data.rundownUpload;
        if (!this.finalInialBatchCheck && !this.freezingMesssage) {
          this.freezingMesssage = rundownFreezeMessageforCalc;
        } else {
          this.freezingMesssage = '';
          this.uploadRecord();
        }
      },
    });
  };

  isFinalForecastCompletedEdit = () => {
    var data = {
      business_entity: this.namcValue,
      workid: this.user,
      user_role: this.userRole,
    };
    this.rundownService.isFinalForecastCompleted(data).subscribe({
      error: this.errorCallback,
      next: (res) => {
        this.spinner.hide();
        this.finalInialBatchCheck = res.body.data.rundownUpload;
        if (!this.finalInialBatchCheck && !this.freezingMesssage) {
          this.freezingMesssage = rundownFreezeMessageforCalc;
        } else {
          this.freezingMesssage = '';
          this.getCalendar();
        }
      },
    });
  };
  
  getBatchStatus(callback) {
    var data = {
      business_entity: this.namcValue,
      workid: this.user,
      user_role: this.userRole,
    };

    this.lookupService.getBatchStatus(data).subscribe({
      error: this.errorCallback,
      next: (res) => {
        const count = res.body.data.runningBatches?.length;

        if (count > 0) {
          this.freezingMesssage = `${res.body.data.runningBatches}  ${freezingMesssage}`;
          this.spinner.hide();
          this.batchCheck = true;
        } else {
          this.freezingMesssage = '';
          this.batchCheck = false;
          callback ? callback() : (this.batchCheck = false);
        }
      },
    });
  }

  exportAsXLSX(): void {
    this.removeMessage();
    if (this.uploadEnabled) {
      return;
    }

    this.spinner.show();
    let easternCurrentDate = String(mm().tz('US/Michigan').format('YYYY-MM-DD_HH:mm:ss'));
    console.log(easternCurrentDate);

    let data = [];
    if (this.headerCheckboxChecked) {
      this.osPartsGrid.gridOptions.api.forEachNodeAfterFilter((node) => {
        data.push(node.data);
      });
    } else {
      data = this.getModifiedRowData();
    }

    if (data.length < 1) {
      this.spinner.hide();
      this.warningMessage = warningMessage.noRowsSelected;

      window.scroll(0, 0);
    } else {
      setTimeout(async () => {
        this.spinner.hide();
        const exportData: any = [];

        data.forEach((e) => {
          if (e.order_status === 'F') {
            return (e.order_status = 'Final Calc Completed');
          } else if (e.order_status === 'C') {
            return (e.order_status = 'Initial Calc Completed');
          }

          const obj = {
            line_path: e.line_path,
            calc_date: e.calc_date,
            order_date: e.order_date,
            vanning_date: e.vanning_date,
            vanning_qty: e.vanning_qty,
            prod_date: e.prod_date,
            prod_qty: e.prod_qty,
            order_status: e.order_status,
            ppr_status: e.ppr_status,
            date_time: e.date_time,
            userid: e.userid,
          };
          exportData.push(obj);
        });

        const headers = [
          'Line Path',
          'Calc DT',
          'Order DT',
          'Van DT',
          'Van Vol',
          'Prod DT',
          'Prod Vol',
          'Calc Status',
          'PPR Status',
          'Updated DT',
          'User',
        ];

        const timestamp = easternCurrentDate

          .split('.')[0]
          .replace('T', '_')
          .split('')
          .filter((d) => d !== '-' && d !== ':')
          .join('');

        if (exportData.length === data.length) {
          this.excelService.exportAsExcelFile(exportData, 'Rundown-Maintenance_' + timestamp, headers);
        }
      }, 100);
    }
  }

  loadData() {
    const data = {
      business_entity: this.namcValue,
      workid: this.user,
      user_role: this.userRole,
    };

    const getRundownProdDatedata = {
      offset: 0,
      limit: 5000,
      business_entity: this.namcValue,
      workid: this.user,
      user_role: this.userRole,
      prod_date: new Date(this.newDate),
    };

    const batchStatusData = {
      business_entity: this.namcValue,
      workid: this.user,
      user_role: this.userRole,
    };

    const dataCalendar = {
      event: 'get sudo',
      workid: this.user,
      user_role: this.userRole,
    };

    const lookupSubscriptions = [
      this.service.getLinePath(this.namcValue, this.userRole, this.user),
      this.rundownService.getMaxDate(data),
      this.rundownService.getRundownProdDate(getRundownProdDatedata),
      this.lookupService.getBatchStatus(batchStatusData),
      this.rundownService.getCalendar(data),
      this.getSudoService.fetchGetSudoDates(dataCalendar),
      this.rundownService.isFinalForecastCompleted(data),
    ];

    forkJoin(lookupSubscriptions).subscribe({
      next: (res) => {
        // get linepath list
        this.linePathList = res[0].body.data;
        if (this.linePathList.length === 1) {
          this.selectedLinePath = this.linePathList;
        } else {
          this.selectedLinePath = [];
        }

        //get Max dates
        this.vanningFrom = res[1].body.data.minVanDate[0].vanning_date;
        this.minVanningFrom = this.vanningFrom;

        const maxVanDate = new Date(res[1].body.data.maxVanDate[0].max);
        this.maxVanDate = new Date(maxVanDate).toLocaleDateString('en-US');
        this.maxProdDate = new Date(res[1].body.data.maxProdDate[0].max).toLocaleDateString('en-US');
        this.lastDayOfVanMonth = new Date(maxVanDate.getFullYear(), maxVanDate.getMonth() + 1, 0).toLocaleDateString(
          'en-US'
        );

        localStorage.setItem('minVanningFrom', this.minVanningFrom);
        localStorage.setItem('lastDayOfVanMonth', this.lastDayOfVanMonth);

        //get Rundown Prod date greater than current date
        this.currentMonthData = res[2].body.data.data;

        //get batch status
        const count = res[3].body.data.runningBatches?.length;

        if (count > 0) {
          this.freezingMesssage = `${res[3].body.data.runningBatches}  ${freezingMesssage}`;
          this.spinner.hide();
          this.batchCheck = true;
        } else {
          this.freezingMesssage = '';
          this.batchCheck = false;
        }

        //get calendar
        this.calendar = res[4].body.data.data;

        //get sudo dates
        this.sudoDate = res[5].body.calenderResponse.map((row) => {
          const rowDate = row.start_date.split('T')[0].split('-');

          const date = `${rowDate[1]}/${rowDate[2]}/${rowDate[0]}`;
          return { date };
        });

        //final inital calc batch checks
        this.finalInialBatchCheck = res[6].body.data.rundownUpload;
        if (!this.finalInialBatchCheck && !this.freezingMesssage) {
          this.freezingMesssage = rundownFreezeMessageforCalc;
        }
        this.spinner.hide();
      },
      error: this.errorCallback,
    });
  }

  loadGetSudoDates() {
    let isTodayGetSudo = false;

    this.getSudoDate = this.sudoDate.filter((getsudo) => {
      return moment(getsudo.date).isSame(new Date(this.newDate), 'day');
    });

    if (this.getSudoDate.length !== 0) {
      isTodayGetSudo = true;
    }

    return isTodayGetSudo;
  }

  checkIfAnySelected() {
    if (this.selectedLinePath.length > 0 && this.vanningFrom) {
      return true;
    }

    return false;
  }
  removeMessage() {
    this.warningMessage = '';
    this.successMessage = '';
    this.fileName = '';
    this.uploadError = false;
  }

  resetGrid() {
    if (this.osPartsGrid) {
      resetSortingAndFilters(this.osPartsGrid);

      return;
    }
  }

  onSearch(offset, removeMessage) {
    if (removeMessage) {
      this.removeMessage();
      this.headerCheckboxChecked = false;
    }

    this.editEnabled = false;
    this.isEditMode = false;
    if (offset === 0) {
      this.resetGrid();

      this.setPageSizeToAll(this.defaultPageSize);
      this.rowData = [];
      this.offset = 0;
    }
    if (!this.checkIfAnySelected()) {
      this.warningMessage = warningMessage.rundownSearchError;

      window.scroll(0, 0);
      this.spinner.hide();
      return false;
    }
    if (this.offset === 0) {
      this.spinner.show();
    }

    if (this.selectedLinePath.length > 0) {
      var selectedLinePath = [];
      this.selectedLinePath.forEach((element) => {
        selectedLinePath.push(element.line_path);
      });
    }
    var data = {
      offset: 0,
      limit: 5000,
      business_entity: this.namcValue,
      workid: this.user,
      user_role: this.userRole,
      line_path: selectedLinePath,
      vanning_from: this.vanningFrom,
    };
    this.rundownService.getRundownGrid(data).subscribe({
      error: this.errorCallback,
      next: (success) => {
        if (this.checkIfAnySelected()) {
          //validation to search
          this.rowData.push(...success.body.data.data);
          this.osPartsGrid.gridOptions.api.setRowData(this.rowData);
          this.osPartsGrid.gridOptions.api.refreshHeader();
          this.dataCount = parseInt(success.body.data.rowCount);
          var records = this.dataCount - (this.offset + 1) * 5000;

          if (records >= 1) {
            this.offset++;
            this.onSearch(this.offset * 5000, false);
          } else {
            this.spinner.hide();
          }
        }
        // focus logic

        this.setPageSizeToAll();

        this.osPartsGrid.gridOptions.api.setRowData(this.rowData);

        const rowIndex = this.rowData.findIndex((row) => {
          const rowVanDate = `${row.vanning_date.split('/')[2]}-${row.vanning_date.split('/')[0]}-${
            row.vanning_date.split('/')[1]
          }`;

          return rowVanDate === this.minVanningFrom;
        });

        // When we use ensureIndexVisible, it will display that index as last row.
        // So to show it at the top, adding 9 rows index offset here.
        // If The row we are trying to focus on, is in the last 10 rows of rowData then we dont need to add any offset.
        const rowOffset = this.rowData.length > rowIndex + 9 ? 9 : 0;
        if (rowIndex) this.osPartsGrid.gridOptions.api.ensureIndexVisible(rowIndex + rowOffset);
      },
    });
  }

  getdownloadTemplate() {
    this.removeMessage();
    this.spinner.show();
    var data = {
      business_entity: this.namcValue,
      workid: this.user,
      user_role: this.userRole,
      file_name: 'rundown_template.xlsx',
    };
    this.rundownService.getdownloadTemplate(data).subscribe({
      error: this.errorCallback,
      next: (success) => {
        if (success) {
          const a = document.createElement('a');
          a.href = success.body.data;

          a.target = '_blank';
          a.rel = 'noreferrer noopener';
          if (a) {
            window.location.href = a.href;
          }
          this.spinner.hide();
        } else {
          this.spinner.hide();
          this.warningMessage = warningMessage.fileNotAvilable;

          window.scroll(0, 0);
        }
      },
    });
  }

  onPageSizeChanged() {
    if (this.gridOptions?.api) {
      this.gridOptions.api.paginationSetPageSize(Number(this.paginationPageSize));
    }
  }

  setPageSizeToAll(pageSize?) {
    this.paginationPageSize = this.rowData.length;

    if (pageSize) {
      this.paginationPageSize = 0;
      this.rowData.length = 0;
    }

    this.onPageSizeChanged();
  }

  getModifiedRowData(): any {
    const modifiedRowData = this.rowData.filter((row) => row.rowCheckBox);
    return modifiedRowData;
  }

  onResetDropDown() {
    this.vanningFrom = this.minVanningFrom;
    this.rowData = [];

    if (this.linePathList?.length === 1) {
      this.selectedLinePath = this.linePathList;
    } else {
      this.selectedLinePath = [];
    }

    this.setPageSizeToAll(this.defaultPageSize);

    this.editEnabled = false;
    this.uploadEnabled = false;
    this.removeMessage();
    if (this.osPartsGrid) {
      this.headerCheckboxChecked = false;
      this.osPartsGrid.gridOptions.api.refreshHeader();
      this.resetGrid();
    }
  }

  calendar: any;

  getCalendar = () => {
    var data = {
      workid: this.user,
      user_role: this.userRole,
      business_entity: this.namcValue,
    };
    this.rundownService.getCalendar(data).subscribe({
      error: this.errorCallback,
      next: (res) => {
        this.calendar = res.body.data.data;
        this.editValidation();
      },
    });
  };

  ReviewChanges() {
    this.spinner.show();
    this.removeMessage();

    this.getBatchStatus(this.isFinalForecastCompletedEdit);
  }

  editValidation = async () => {
    this.spinner.hide();
    this.modifiedRowData = this.getModifiedRowData();
    var editedRecords = [];

    editedRecords = this.modifiedRowData.filter(
      (element) =>
        element.isProdVolUpdated ||
        element.isVanningVolUpdated ||
        element.isProdDateUpdated ||
        element.isVanningDateUpdated ||
        element.isCalcDateUpdated ||
        element.isOrderDateUpdated
    );

    if (editedRecords.length === 0) {
      this.warningMessage = warningMessage.editToReview;

      window.scroll(0, 0);
      return;
    }
    await Promise.all(
      editedRecords.map(async (element, index) => {
        const p = [];
        element.error = '';
        var lastIndex = 0;
        var group = this.rowData.filter((x, index) => {
          var vanOrdGroup =
            x.old_vanning_date === element.old_vanning_date &&
            x.line_path === element.line_path &&
            x.old_calc_date === element.old_calc_date &&
            x.old_order_date === element.old_order_date;
          if (vanOrdGroup) {
            lastIndex = index;
          }
          return vanOrdGroup;
        });
        //if Calc Date Updated, Calc_date validation
        if (element.isCalcDateUpdated) {
          var calcDate = moment(element.calc_date);
          var orderDt = moment(element.order_date);
          var calcDateValid = await dateIsValid(element.calc_date);

          if (!calcDateValid) {
            p.push('Invalid Calc date format. ');
          }
          if (calcDate.format('dddd') === 'Sunday') {
            p.push('Calc Date can not be Sunday. ');
          }
          if (new Date(element.order_date) < new Date(element.calc_date)) {
            p.push('Calc date cannot be greater than Order date. ');
          }
          if (new Date(element.calc_date) < new Date(this.newDate)) {
            p.push('Calc date should not be less than current date. ');
          }

          if (new Date(element.calc_date) < new Date(element.maxcalc)) {
            p.push('Calc date not greater than max calc dt with order status. ');
          }
          if (new Date(element.vanning_date) < new Date(element.calc_date)) {
            p.push('Calc date cannot be greater than Vanning date. ');
          }
          if (new Date(element.prod_date) < new Date(element.calc_date)) {
            p.push('Calc date cannot be greater than Prod date. ');
          }
          // if (calcDateValid) {
          if (lastIndex < this.rowData.length - 1) {
            if (this.rowData[lastIndex + 1].line_path === element.line_path) {
              var nextGrpCalcDt = this.rowData[lastIndex + 1].calc_date;

              if (new Date(element.calc_date) > new Date(nextGrpCalcDt)) {
                p.push('Calc date cannot be greater to calc date in next ord/vanDt group. ');
              }
            }
          }
          if (lastIndex - group.length >= 0) {
            if (this.rowData[lastIndex - group.length].line_path === element.line_path) {
              var previousGrpVanDt = this.rowData[lastIndex - group.length].calc_date;
              if (new Date(element.calc_date) < new Date(previousGrpVanDt)) {
                p.push('Calc dt not greater than prev ord dts calc dt. ');
              }
            }
          }
          var calcValidation = group.filter((x) => x.calc_date === element.calc_date);

          //validating for calc  group
          if (calcValidation.length !== group.length) {
            p.push('Calc date not same within ord/van Dt group. ');
          }

          var calculatedDate = calcDate.format(this.dateFormat);
          var checkDate = [];
          console.log('eid calendar', this.calendar);

          checkDate = this.calendar.filter(
            (x) =>
              (moment(x.start_date).isSame(calculatedDate, 'day') ||
                new Date(x.start_date) < new Date(calculatedDate)) &&
              (moment(x.end_date).isSame(calculatedDate, 'day') || new Date(x.end_date) > new Date(calculatedDate)) &&
              x.holiday_indicator.toLowerCase() === 'y'
          );

          // if (checkDate.length != 0) {
          //   p.push('Calc date cannot be a holiday. ');
          // }

          if (calcDate.format('dddd') === 'Saturday') {
            var checkDateSat = this.calendar.filter(
              (x) =>
                (moment(x.start_date).isSame(calculatedDate, 'day') ||
                  new Date(x.start_date) < new Date(calculatedDate)) &&
                (moment(x.end_date).isSame(calculatedDate, 'day') || new Date(x.end_date) > new Date(calculatedDate)) &&
                x.holiday_indicator.toLowerCase() === 'n'
            );

            if (checkDateSat.length === 0 || checkDate.length !== 0) {
              p.push('Calc Date can not be Saturday, holiday. ');
            }
          }
          if (element.order_date === element.calc_date) {
            p.push('Calc date cannot be equal to Order date. ');
          }
        }

        //when order date or calc date is updated, check for calc_date = order_date - 1 .

        if (element.isCalcDateUpdated || element.isOrderDateUpdated) {
          if (dateIsValid(element.order_date) && dateIsValid(element.calc_date)) {
            var i = 1;
            while (i > 0) {
              var calcDate1 = moment(element.order_date).subtract(i, 'days');

              var calculatedCalcDate = '';
              if (calcDate1.format('dddd') === 'Sunday') {
                //if order date is monday then calc date is Saturday(order date - 1).
                calcDate1 = moment(element.order_date).subtract(i + 1, 'days');
                calculatedCalcDate = calcDate1.format(this.dateFormat);
                var checkDateSatu = [];
                checkDateSatu = this.calendar.filter(
                  (x) =>
                    new Date(x.start_date) <= new Date(calculatedCalcDate) &&
                    new Date(x.end_date) >= new Date(calculatedCalcDate) &&
                    x.holiday_indicator.toLowerCase() === 'n'
                );

                //if we cant find row in calendar_lookup as holiday indicator as N.
                //that means its a Holiday, than we assign calc date as friday.
                if (checkDateSatu.length === 0) {
                  i = i + 2;
                  calcDate1 = moment(element.order_date).subtract(i, 'days');
                }
              }

              calculatedCalcDate = calcDate1.format(this.dateFormat);
              var checkDate1 = [];
              checkDate1 = this.calendar.filter(
                (x) =>
                  new Date(x.start_date) <= new Date(calculatedCalcDate) &&
                  new Date(x.end_date) >= new Date(calculatedCalcDate) &&
                  x.holiday_indicator.toLowerCase() === 'y'
              );

              if (checkDate1.length === 0) {
                /*if (
                  element.calc_date !== calculatedCalcDate &&
                  element.isCalcDateUpdated
                ) {
                  p.push(
                    'Calc date should be previous working day of order date. '
                  );
                }*/
                if (!element.isCalcDateUpdated && element.isOrderDateUpdated) {
                  element.calc_date = calculatedCalcDate;
                }
                i = 0;
              } else {
                i++;
              }
            }
          }
          //Calc date cannot be same for multiple order date.
          var sameCalcDate = this.rowData.filter(
            (x) => x.line_path === element.line_path && x.calc_date === element.calc_date
          );

          var uniqueOrderDate = sameCalcDate[0]?.order_date;

          var checkOrderDates = sameCalcDate.filter((x) => x.order_date !== uniqueOrderDate);

          if (checkOrderDates.length !== 0) {
            p.push('Calc date cannot be same for multiple order date');
          }
        }
        //when order is edited,validations for order date
        if (element.isOrderDateUpdated) {
          var orderDt = moment(element.order_date);
          var isOrderDateToday = orderDt.isSame(new Date(this.newDate), 'day');
          var orderDateValid = await dateIsValid(element.order_date);

          if (!orderDateValid) {
            p.push('Invalid Order date format. ');
          }
          if (isOrderDateToday) {
            p.push('Order date cannot be today. ');
          }
          if (new Date(element.calc_date) > new Date(element.order_date)) {
            p.push('Order date should be greater than calc date. ');
          }
          if (new Date(element.order_date) > new Date(element.vanning_date)) {
            p.push('Order date cannot be greater than vanning date. ');
          }
          if (new Date(element.order_date) > new Date(element.prod_date)) {
            p.push('Order date cannot be greater than prod date. ');
          }

          //Calc date cannot be same for multiple order date.
          var sameOrderDate = this.rowData.filter(
            (x) => x.line_path === element.line_path && x.order_date === element.order_date
          );

          var uniqueCalcDate = sameOrderDate[0]?.calc_date;

          var checkCalcDates = sameOrderDate.filter((x) => x.calc_date !== uniqueCalcDate);

          if (checkCalcDates.length !== 0) {
            p.push('Order date cannot be same for multiple calc date');
          }

          if (orderDateValid) {
            if (lastIndex < this.rowData.length - 1) {
              if (this.rowData[lastIndex + 1].line_path === element.line_path) {
                var nextGrpOrdDt = this.rowData[lastIndex + 1].order_date;

                if (new Date(element.order_date) > new Date(nextGrpOrdDt)) {
                  p.push('Order date cannot be greater than order date in next ord/vanDt group. ');
                }
              }
            }

            if (lastIndex - group.length >= 0) {
              if (this.rowData[lastIndex - group.length].line_path === element.line_path) {
                var previousGrpOrdDt = this.rowData[lastIndex - group.length].order_date;
                if (new Date(element.order_date) < new Date(previousGrpOrdDt)) {
                  p.push('Order date cannot be less than order date in previous ord/vanDt group.');
                }
              }
            }

            //validating for order group
            var orderValidation = group.filter((x) => x.order_date === element.order_date);

            if (orderValidation.length !== group.length) {
              p.push('Order date not same within ord/van Dt group. ');
            }
            if (orderDt.format('dddd') === 'Saturday') {
              var checkDateInCalendar = [];
              checkDateInCalendar = this.calendar.filter(
                (x) =>
                  (moment(x.start_date).isSame(calculatedDate, 'day') ||
                    new Date(x.start_date) < new Date(calculatedDate)) &&
                  (moment(x.end_date).isSame(calculatedDate, 'day') ||
                    new Date(x.end_date) > new Date(calculatedDate)) &&
                  x.holiday_indicator.toLowerCase() === 'n'
              );

              if (checkDateInCalendar.length === 0) {
                p.push('Order Date can not be Saturday, holiday. ');
              }
            }
          }
        }

        //when van dt is edited, Vanning date validation
        if (element.isVanningDateUpdated) {
          var vanDateValid = await dateIsValid(element.vanning_date);
          if (!vanDateValid) {
            p.push('Invalid Vanning date format.');
          }
          if (new Date(element.calc_date) > new Date(element.vanning_date)) {
            p.push('Vanning Date cannot be lesser than Calc date. ');
          }
          if (new Date(element.order_date) > new Date(element.vanning_date)) {
            p.push('Vanning Date cannot be lesser than order date. ');
          }
          if (new Date(element.prod_date) < new Date(element.vanning_date)) {
            p.push('Vanning Date cannot be greater than prod date. ');
          }
          var vanningDtValidation = group.filter((x) => x.vanning_date === element.vanning_date);

          //validating for order/van dt group
          if (vanningDtValidation.length !== group.length) {
            p.push('Van date not same within ord/van date group. ');
          }
          if (lastIndex < this.rowData.length - 1) {
            if (this.rowData[lastIndex + 1].line_path === element.line_path) {
              var nextGrpVanDt = this.rowData[lastIndex + 1].vanning_date;
              if (new Date(element.vanning_date) > new Date(nextGrpVanDt)) {
                p.push('Van date cannot be greater to van date in next ord/vanDt group. ');
              }
            }
          }

          if (lastIndex - group.length >= 0) {
            if (this.rowData[lastIndex - group.length].line_path === element.line_path) {
              var previousGrpVanDt = this.rowData[lastIndex - group.length].vanning_date;
              if (new Date(element.vanning_date) < new Date(previousGrpVanDt)) {
                p.push('Van date cannot be less than van date in previous ord/vanDt group. ');
              }
            }
          }
        }

        //when prod dt is edited, Prod date validation
        if (element.isProdDateUpdated) {
          var prodDateValid = await dateIsValid(element.prod_date);

          if (!prodDateValid) {
            p.push('Invalid Prod Date format. ');
          }
          if (new Date(element.prod_date) < new Date(element.vanning_date)) {
            p.push('Prod Date cannot be lesser than Van date. ');
          }
          if (new Date(element.prod_date) < new Date(element.order_date)) {
            p.push('Prod date cannot be less than order date. ');
          }
          if (new Date(element.prod_date) < new Date(element.calc_date)) {
            p.push('Prod date cannot be less than calc date. ');
          }
        }
        //when vanning volume is edited, Vanning volume validation:
        if (element.isVanningVolUpdated) {
          if (element.vanning_qty === 0 || !element.vanning_qty) {
            p.push('Vanning Vol cannot be null or 0. ');
          }
          var VanningValidation = group.filter((x) => x.vanning_qty === element.vanning_qty);
          //validating vanning vol for vanning group
          if (VanningValidation.length !== group.length) {
            p.push('Van vol not same in order/van date group. ');
          }
          var prodVol = 0;
          group.forEach((element) => {
            prodVol += element.prod_qty;
          });

          if (element.vanning_qty !== prodVol) {
            p.push('Van vol is wrong compared to prod vol. ');
          }
        }
        //when prod volume is edited, vanning volume validation:
        if (element.isProdVolUpdated) {
          if (element.prod_qty === 0 || !element.prod_qty) {
            p.push('Prod vol cannot be null or 0. ');
          }

          var prodVol = 0;
          group.forEach((element) => {
            prodVol += element.prod_qty;
          });

          if (element.vanning_qty !== prodVol) {
            p.push('Prod vol sum must be equal to van vol for order/vanDt group. ');
          }
        }
        const temp1 = [];
        const array = [];
        var dataDuplicates = this.rowData.reduce(function (a, data, i) {
          if (
            element.line_path === data.line_path &&
            element.order_date === data.order_date &&
            element.calc_date === data.calc_date &&
            element.vanning_date === data.vanning_date &&
            element.prod_date === data.prod_date
          ) {
            array.push(data);
            temp1.push(i + 1);
          }
        }, []);

        if (temp1.length > 1) {
          p.push(`Duplicate record(s) found. `);
        }
        if (p.length > 0) {
          element.error = p
            .map(function (val, index) {
              return `${(index + 1).toString()}.   ${val}`;
            })
            .join('\n');
        }
      })
    );

    if (editedRecords.length > 0) {
      this.openReviewDialog(editedRecords);
    }
  };

  //Edit function review dialog box.
  openReviewDialog(editedRecords) {
    var data = {
      modifiedRowData: editedRecords,
    };
    if (data.modifiedRowData.length > 0) {
      const dialogConfig = new MatDialogConfig();
      dialogConfig.disableClose = true;
      dialogConfig.id = this.modalComponent;
      dialogConfig.height = '350px';
      dialogConfig.width = '1000px';

      dialogConfig.data = data;
      const dialogRef = this.dialog.open(RundownDialogComponent, dialogConfig);
      dialogRef.afterClosed().subscribe((res) => {
        if (res === 'cancel') {
          return;
        } else if (res === 'save') {
          if (!this.uploadEnabled) {
            var updatedData = [];
            data.modifiedRowData.forEach((element) => {
              var updatedFields = {
                calc_date: element.calc_date,
                line_path: element.line_path,
                old_calc_date: element.old_calc_date,
                old_order_date: element.old_order_date,
                old_prod_date: element.old_prod_date,
                old_vanning_date: element.old_vanning_date,
                order_date: element.order_date,
                prod_date: element.prod_date,
                prod_qty: element.prod_qty,
                vanning_date: element.vanning_date,
                isOrderDateUpdated: element.isOrderDateUpdated,
                vanning_qty: element.vanning_qty,
              };
              updatedData.push(updatedFields);
            });

            this.spinner.show();
            this.editRundown(updatedData);
          }
        }

        this.headerCheckboxChecked = false;
        this.osPartsGrid.gridOptions.api.refreshHeader();
        this.isEditMode = !this.isEditMode;
        this.editEnabled = this.isEditMode;
        this.osPartsGrid.gridOptions.api.forEachNode(function (rowNode, index) {
          if (rowNode.data.rowCheckBox) {
            rowNode.setSelected(false);
            rowNode.data.checkedEditMode = false;
            rowNode.data.rowCheckBox = false;
          }
          rowNode.setData(rowNode.data);
        });
      });
    } else {
      this.warningMessage = warningMessage.selectRowsToEdit;

      window.scroll(0, 0);
    }
  }

  editRundown(updatedData) {
    var data1 = {
      business_entity: this.namcValue,
      userid: this.userName,
      dest_code: this.destCode,
      workid: this.user,
      user_role: this.userRole,
      data: updatedData.slice(this.editApiStartPosition, this.editApiEndPosition),
    };

    this.spinner.show();
    this.rundownService.editRundown(data1).subscribe({
      error: this.errorCallback,
      next: (response) => {
        console.log('response.body.data', response);

        const { updatedRows } = response.body.data;

        if (updatedData.length > this.editApiEndPosition) {
          this.timesEditApiCalled++;

          this.editApiStartPosition = this.timesEditApiCalled * this.editApiDefaultLimit;

          this.editApiEndPosition = this.editApiStartPosition + this.editApiDefaultLimit;
          return this.editRundown(updatedData);
        }

        this.spinner.hide();
        this.timesEditApiCalled = 0;
        this.editApiStartPosition = 0;
        this.editApiEndPosition = this.editApiDefaultLimit;

        if (updatedRows.length > 0) {
          this.successMessage = successMessage.recordUpdated;
          this.rowData = updatedRows;
        } else {
          this.warningMessage = `${warningMessage.reportsNotUpdated}  ${this.itContact}.`;
        }

        window.scroll(0, 0);
        this.setPageSizeToAll();

        // this.onSearch(0, false);
      },
    });
  }
  cancelEdit(): void {
    this.removeMessage();
    this.openDialog1();
  }

  canDeactivate() {
    return this.editEnabled;
  }

  //popup when leaving screen with unsaved changes.
  openDialog1() {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.id = this.modalComponent;
    dialogConfig.height = '204px';
    dialogConfig.width = '475px';
    const dialogRef = this.dialog.open(ConfirmCancelDailogComponent, dialogConfig);
    dialogRef.afterClosed().subscribe((res) => {
      if (res === 'cancel') {
        this.columnDefs[7].cellStyle = function (params) {
          if ('modified' in params.data) {
            return { backgroundColor: '#E4ECF0' };
          } else {
            return { backgroundColor: 'white' };
          }
        };

        this.onSearch(0, true);
        this.editEnabled = false;
        this.isEditMode = false;
        this.osPartsGrid.gridOptions.api.refreshHeader();
      }
    });
  }

  onEditMode() {
    this.removeMessage();
    let data = [];

    if (this.uploadEnabled) {
      return;
    }
    data = this.getModifiedRowData();
    if (data.length > 0) {
      this.isEditMode = !this.isEditMode;
      this.editEnabled = this.isEditMode;

      if (this.isEditMode) {
        this.osPartsGrid.gridOptions.api.forEachNode(function (rowNode, index) {
          rowNode.data.checkedEditMode = true;
          if (!rowNode.data.rowCheckBox) {
            rowNode.data.checkedEditMode = false;
          }
          rowNode.setData(rowNode.data);
        });
      } else {
        this.osPartsGrid.gridOptions.api.forEachNode(function (rowNode, index) {
          if (rowNode.data.rowCheckBox) {
            rowNode.data.checkedEditMode = false;
          }
          rowNode.setData(rowNode.data);
        });
      }
      setTimeout(() => {
        window.scroll(0, document.body.scrollHeight);
      }, 100);
    } else {
      this.isEditMode = false;
      this.editEnabled = false;
      this.warningMessage = warningMessage.selectRowsToEdit;
      window.scroll(0, 0);
    }
  }

  userCheckChanged(checked, rowIndex) {
    if (
      this.warningMessage === 'Please select at least one row to edit.' ||
      this.warningMessage === 'No rows are selected.'
    ) {
      this.warningMessage = '';
    }
    this.spinner.show();
    const rowNode = this.osPartsGrid.gridOptions.api.getDisplayedRowAtIndex(rowIndex);
    if (checked === true) {
      rowNode.setSelected(true);
      if (this.isEditMode) {
        rowNode.data.checkedEditMode = true;
      }
      rowNode.data.rowCheckBox = true;
    } else {
      rowNode.setSelected(false);
      rowNode.data.checkedEditMode = false;
      rowNode.data.rowCheckBox = false;
    }

    this.gridOptions.getRowStyle = function (params) {
      if (params.node.rowIndex === rowIndex) {
        const color = checked ? '#E4ECF0' : 'white';
        return { background: color };
      }
      return { background: 'white' };
    };

    rowNode.setData(rowNode.data);
    this.toggleHeaderCheckbox(checked);
    this.spinner.hide();
  }

  // headerCheckChanged(event) {
  //   if(this.warningMessage === 'Please select at least one row to edit.' || this.warningMessage === 'No rows are selected.'){
  //     this.warningMessage = ''
  //   }
  //   this.headerCheckboxChecked = event.checked;
  //   this.editRowSelected = event.checked;

  //   const headerChecked = event.checked;

  //   const isEditable = this.isEditMode;

  //   this.osPartsGrid.gridOptions.api.forEachNode((rowNode) => {
  //     if (headerChecked) {
  //       if (isEditable) {
  //         rowNode.data.checkedEditMode = true;
  //       }

  //       if (new Date(rowNode.data.prod_date) <= new Date(this.newDate)) {
  //         rowNode.data.rowCheckBox = false;
  //       } else {
  //         rowNode.data.rowCheckBox = true;
  //       }
  //     } else {
  //       rowNode.data.rowCheckBox = false;
  //       rowNode.data.checkedEditMode = false;
  //     }

  //     rowNode.setData(rowNode.data);
  //   });
  // }

  headerCheckChanged(event) {
    if (
      this.warningMessage === 'Please select at least one row to edit.' ||
      this.warningMessage === 'No rows are selected.'
    ) {
      this.warningMessage = '';
    }
    this.headerCheckboxChecked = event.checked;
    const headerChecked = event.checked;
    const isEditable = this.isEditMode;
    this.osPartsGrid.gridOptions.api.forEachNode(function (rowNode, index) {
      if (headerChecked) {
        if (isEditable) {
          rowNode.data.checkedEditMode = true;
        }
        rowNode.data.rowCheckBox = true;
      } else {
        rowNode.data.rowCheckBox = false;
        rowNode.data.checkedEditMode = false;
      }

      rowNode.setData(rowNode.data);
    });
  }

  toggleHeaderCheckbox(checked) {
    if (!checked) {
      this.headerCheckboxChecked = false;

      this.osPartsGrid.gridOptions.api.refreshHeader();

      return;
    }

    const rowCount = this.osPartsGrid.gridOptions.rowData.length;
    const selectedRowCount = this.osPartsGrid.gridOptions.rowData.filter((x) => x.rowCheckBox === true).length;

    if (rowCount === selectedRowCount) {
      this.headerCheckboxChecked = true;

      this.osPartsGrid.gridOptions.api.refreshHeader();
    }
  }

  @HostListener('drop', ['$event']) onDrop(event) {
    this.onFileChange(event, true);
  }
  onFileChange(event: any, isDrag) {
    this.spinner.show();
    this.onResetDropDown();
    this.getRudnownProdDate();
    this.editEnabled = false;
    this.rowData = [];
    this.uploadError = false;
    const file: File = event.target.files.item(0);
    const sizeInBytes = file.size;

    setTimeout(() => {
      //for spinner enabling issue

      const file = event.target.files.item(0);
      this.excelService.uploadedFile = file; /*file reader */
      this.fileName = file.name;

      const fileType = this.fileName.split('.')[this.fileName.split('.').length - 1];

      if (!allowedFileTypes.includes(fileType)) {
        this.warningMessage = warningMessage.onlyxlxsAlloed;
        this.spinner.hide();

        return false;
      }
      if (!(sizeInBytes <= allowedFilesizeInBytes)) {
        this.warningMessage = warningMessage.uploadFileSizeTooBig;
        this.spinner.hide();

        return false;
      }

      // const target: DataTransfer = <DataTransfer>(event.target);
      const target: DataTransfer = (isDrag ? event.dataTransfer : event.target) as DataTransfer;

      const reader: FileReader = new FileReader();

      reader.onload = (e: any) => {
        /* read workbook */
        const bstr: string = e.target.result;
        const wb: XLSX.WorkBook = XLSX.read(bstr, {
          type: 'binary',
          cellDates: true,
          dateNF: 'mm/dd/yyyy;@',
        });

        /* grab first sheet */
        const wsname: string = wb.SheetNames[0];
        const ws: XLSX.WorkSheet = wb.Sheets[wsname];

        /*Removes empty spaces and convert to lower case */
        const range = XLSX.utils.decode_range(ws['!ref']);

        console.log(range.e.c);

        //checking length of columns, 6 are valid fields.
        if (range.e.c < 6) {
          this.warningMessage = warningMessage.invalidFileTemplate;
          this.spinner.hide();
        }

        for (let C = range.s.c; C <= range.e.c; C++) {
          const address = `${XLSX.utils.encode_col(C)}1`; // <-- first row

          if (!ws[address]) {
            continue;
          }

          if (ws[address].w) {
            ws[address].w = ws[address].w.replace(/\s/g, '').toLowerCase();

            var validColumns = ['linepath', 'orderdt', 'vandt', 'vanvol', 'proddt', 'prodvol', 'error/comments'];
            var columnCheck = validColumns.filter((x) => x === ws[address].w).length;

            if (ws[address].w === 'linepath') {
              ws[address].w = 'line_path';
            }
            if (ws[address].w === 'orderdt') {
              ws[address].w = 'order_date';
            }
            if (ws[address].w === 'vandt') {
              ws[address].w = 'vanning_date';
            }
            if (ws[address].w === 'vanvol') {
              ws[address].w = 'vanning_qty';
            }
            if (ws[address].w === 'proddt') {
              ws[address].w = 'prod_date';
            }
            if (ws[address].w === 'prodvol') {
              ws[address].w = 'prod_qty';
            }

            if (columnCheck === 0) {
              //any extra columns exist
              this.warningMessage = warningMessage.invalidFileTemplate;
              this.spinner.hide();
            }
          } else {
            this.warningMessage = warningMessage.invalidFileTemplate;
            this.spinner.hide();
          }
        }
        if (this.warningMessage === '') {
          /* convert to Json */
          this.excelService.uploadedData = XLSX.utils.sheet_to_json(ws, {
            raw: false,
            dateNF: 'mm/dd/yyyy',
          });
          this.spinner.hide();

          var errorcount = 0;
          const fileData = this.excelService.uploadedData;
          if (fileData.length === 0) {
            this.warningMessage = warningMessage.fileEmpty;

            window.scroll(0, 0);
          } else {
            this.openDialogVanningRange(fileData);
          }
        }

        event.target.value = null; //To clear the file uploaded
      };
      reader.readAsBinaryString(target.files[0]);
    }, 1000);
  }

  vanningRange = {};

  //In upload to get the vanning range.
  openDialogVanningRange(fileData) {
    if (fileData.length > 0) {
      const dialogConfig = new MatDialogConfig();
      dialogConfig.disableClose = true;
      dialogConfig.id = this.modalComponent;
      dialogConfig.width = '421px';
      dialogConfig.height = '250px';

      const dialogRef = this.dialog.open(RundownUploadComponent, dialogConfig);
      const addGetResponse = dialogRef.componentInstance.vanningRangeEventEmitter.subscribe((res) => {
        this.spinner.show();

        var vanFrom = moment(res.vanningFrom).format(this.dateFormat);
        var vanTo = moment(res.vanningTo).format(this.dateFormat);
        this.vanningRange = { vanningFrom: vanFrom, vanningTo: vanTo };

        //sorting with line_path,order_date,vanning_date
        var JSONDataSorted = fileData
          .map((a) => [
            (
              a.line_path +
              this.formatDate(a.order_date) +
              this.formatDate(a.vanning_date) +
              this.formatDate(a.prod_date)
            )
              .toLowerCase()
              .replace(/([a-z])|(\d)|./gs, (m, l, d) => (l ? 1 : d ? 2 : 3) + m),
            a,
          ])
          .sort(([a], [b]) => a.localeCompare(b))
          .map((a) => a[1]);

        var vanningRangeCheck =
          JSONDataSorted[0].vanning_date === vanFrom &&
          JSONDataSorted[JSONDataSorted.length - 1].vanning_date === vanTo;

        if (vanningRangeCheck) {
          var dataCalendar = {
            workid: this.user,
            user_role: this.userRole,
            business_entity: this.namcValue,
          };
          this.rundownService.getCalendar(dataCalendar).subscribe({
            error: this.errorCallback,
            next: (res) => {
              this.calendar = res.body.data.data;
              this.validation(fileData, JSONDataSorted);
            },
          });
        } else {
          fileData = [];
          this.warningMessage = warningMessage.uploadVanningDatesMisMatchError;
          this.spinner.hide();

          window.scroll(0, 0);
        }
      });
      dialogRef.afterClosed().subscribe((res) => {
        addGetResponse.unsubscribe();
      });
    } else {
      this.warningMessage = warningMessage.noDataToUpload;

      window.scroll(0, 0);
    }
  }

  formatDate(_d) {
    if (_d.indexOf('/') !== -1) {
      const a = _d.split('/');
      const p = `${a[2]}-${a[0].length > 1 ? a[0] : '0' + a[0]}-${a[1].length > 1 ? a[1] : '0' + a[1]}`;
      return p;
    }
    return _d;
  }

  validation(JSONData, JSONDataSorted) {
    let p = [];
    let result = true,
      count = 0,
      temp: any = -1;

    JSONDataSorted.forEach((element, index) => {
      p = [];
      element.remarks = '';

      if (element.line_path === undefined) {
        p.push(' Line path cannot be empty.');
      } else if (!/^\d+$/.test(element.line_path)) {
        p.push(' Line path must be integer.');
      } else if (Number(element.line_path) <= 0) {
        p.push(' Line path must be greater than zero.');
      } else {
        temp = this.linePathList.filter((x) => x.line_path.trim() === element.line_path.trim()).length;

        if (temp === 0) {
          p.push(` Invalid line path.`);
        }
      }

      //empty, integer, >0 checks for vanning vol
      if (element.vanning_qty === undefined) {
        p.push(' Vanning vol cannot be empty.');
      } else if (!/^\d+$/.test(element.vanning_qty)) {
        p.push(' Vanning vol must be integer.');
      } else if (Number(element.vanning_qty) <= 0) {
        p.push(' Vanning vol must be greater than zero.');
      }

      //empty, integer, >0 checks for prod vol
      if (element.prod_qty === undefined) {
        element.prod_qty = '';
        p.push(' Prod vol cannot be empty.');
      } else if (!/^\d+$/.test(element.prod_qty)) {
        p.push(' Prod vol must be integer.');
      } else if (Number(element.prod_qty) <= 0) {
        p.push(' Prod vol must be greater than zero.');
      }
      //vanning date validation
      if (element.vanning_date === undefined) {
        element.Vanning_date = '';
        p.push(' Vanning date cannot be empty.');
      } else if (String(new Date(element.vanning_date)) === 'Invalid Date') {
        p.push(' Vanning date must follow mm/dd/yyyy format.');
      } else if (new Date(element.vanning_date) < new Date(element.order_date)) {
        p.push(' Van date must be greater than the order date.');
      }
      //order date validation
      if (element.order_date === undefined) {
        element.order_date = '';
        p.push(' Order date cannot be empty.');
      } else if (String(new Date(element.order_date)) === 'Invalid Date') {
        p.push(' Order date must follow mm/dd/yyyy format.');
      }
      //prod date validation

      if (element.prod_date === undefined) {
        element.prod_date = '';
        p.push(' Prod_date cannot be empty.');
      } else if (String(new Date(element.prod_date)) === 'Invalid Date.') {
        p.push(' Prod date must follow mm/dd/yyyy format.');
      } else if (new Date(element.prod_date) < new Date(element.vanning_date)) {
        p.push(' Prod date must be greater than van date.');
      }
      var lastIndex = 0;
      var vanGroup = JSONDataSorted.filter(
        (x) =>
          x.vanning_date === element.vanning_date &&
          x.line_path === element.line_path &&
          x.order_date === element.order_date
      );
      var VanningValidation = vanGroup.filter((x) => x.vanning_qty === element.vanning_qty);
      if (vanGroup) {
        lastIndex = index;
      }

      //validating vanning vol for vanning group
      if (VanningValidation.length !== vanGroup.length) {
        p.push(' Van vol can not change within line path, order date and van date');
      }

      var prodVol = 0;
      vanGroup.forEach((element) => {
        prodVol += Number(element.prod_qty);
      });

      if (Number(element.vanning_qty) !== prodVol) {
        p.push(' Total Prod vol does not match van vol for the grouping.');
      }

      if (lastIndex - vanGroup.length >= 0) {
        if (JSONDataSorted[lastIndex - vanGroup.length].line_path === element.line_path) {
          var previousGrpVanDt = JSONDataSorted[lastIndex - vanGroup.length].vanning_date;
          if (new Date(element.vanning_date) < new Date(previousGrpVanDt)) {
            p.push('Van date cannot be less than van date in previous ord/vanDt group. ');
          }
          var previousGrpOrdDt = JSONDataSorted[lastIndex - vanGroup.length].order_date;
          if (new Date(element.order_date) < new Date(previousGrpOrdDt)) {
            p.push(`Order date cannot be less than order date in previous ord/vanDt group. `);
          }
          var previousGrpProdDt = JSONDataSorted[lastIndex - vanGroup.length].prod_date;
          if (new Date(element.prod_date) < new Date(previousGrpProdDt)) {
            p.push('Prod date cannot be less than prod date in previous ord/vanDt group. ');
          }
        }
      }
      if (new Date(element.prod_date) <= new Date(this.newDate)) {
        p.push(' cannot chg prod vol for prod date <= curr dt.');
      } else {
        if (new Date(element.vanning_date) <= new Date(this.lastDayOfVanMonth)) {
          var UpdateRecord = this.currentMonthData.filter(
            (x) =>
              x.order_date === element.order_date ||
              x.vanning_date === element.vanning_date ||
              x.prod_date === element.prod_date
          );

          var UpdateRecord1 = UpdateRecord.filter((x) => {
            if (x.order_date === element.order_date) {
              element.calc_date = x.calc_date;
            }
          });
          var updatedProdVanDate = [];
          updatedProdVanDate = UpdateRecord1.filter(
            (x) =>
              (x.order_date === element.order_date &&
                x.vanning_date === element.vanning_date &&
                x.prod_date !== element.prod_date &&
                Number(x.vanning_qty) === Number(element.vanning_qty)) ||
              (x.order_date === element.order_date &&
                x.vanning_date !== element.vanning_date &&
                x.prod_date === element.prod_date &&
                Number(x.vanning_qty) === Number(element.vanning_qty))
          );

          if (updatedProdVanDate.length !== 0) {
            p.push(
              ` Cannot add new Van date and Prod date combination
              for curr/prioior van month.`
            );
          }
        }
      }
      const temp1 = [];

      JSONDataSorted.reduce(function (a, data, i) {
        if (
          element.line_path === data.line_path &&
          element.order_date === data.order_date &&
          element.vanning_date === data.vanning_date &&
          element.prod_date === data.prod_date &&
          i !== index
        ) {
          temp1.push(i + 2);
        }
      }, []);

      if (temp1.length > 0) {
        p.push(` Duplicate record found.`);
      }

      if (p.length > 0) {
        result = false;
        element.remarks = p.join('\r\n');
        count += 1;
      } else {
        element.remarks = '';

        //in order date valid condition CalcDate Calculation
        // if (!element.calc_date) {
        var i = 1;
        while (i > 0) {
          var calcDate = moment(element.order_date).subtract(i, 'days');

          if (calcDate.format('dddd') === 'Sunday') {
            calcDate = moment(element.order_date).subtract(i + 2, 'days');
          } else if (calcDate.format('dddd') === 'Saturday') {
            calcDate = moment(element.order_date).subtract(i + 1, 'days');
          }
          var calculatedDate = calcDate.format(this.dateFormat);
          var checkDate = [];
          checkDate = this.calendar.filter(
            (x) =>
              new Date(x.start_date) <= new Date(calculatedDate) &&
              new Date(x.end_date) >= new Date(calculatedDate) &&
              x.holiday_indicator.toLowerCase() === 'y'
          );
          if (checkDate.length === 0) {
            element.calc_date = calculatedDate;
            element.dest_code = this.destCode;

            new Date(element.calc_date) < new Date(this.newDate)
              ? (element.order_status = 'F')
              : (element.order_status = '');

            if (moment(element.calc_date).isSame(new Date(this.newDate), 'day')) {
              console.log('newdate---', element.calc_date);

              let OrderStatusIndex = UpdateRecord.findIndex(
                (x) => x.calc_date === element.calc_date && x.line_path === element.line_path
              );

              console.log('index', OrderStatusIndex, UpdateRecord[OrderStatusIndex].order_status);

              if (OrderStatusIndex > -1) {
                element.order_status = UpdateRecord[OrderStatusIndex].order_status;
              }
            }
            element.userid = this.userName;
            i = 0;
          } else {
            i++;
          }
        }
        // } else {
        // element.dest_code = this.destCode;
        // new Date(element.calc_date) < new Date(this.newDate)
        //   ? (element.order_status = 'F')
        //   : (element.order_status = '');

        // element.userid = this.userName;
      }
      // }
    });

    // if no errors in uploaded file.
    if (result) {
      this.rowData = JSONDataSorted; //JSONData;
      this.warningMessage = warningMessage.uploadEnabled;

      this.setPageSizeToAll();

      this.osPartsGrid.gridOptions.api.setRowData(this.rowData);

      this.uploadEnabled = true;

      this.spinner.hide();
      setTimeout(() => {
        window.scroll(0, document.body.scrollHeight);
      }, 100);
    } else {
      this.uploadError = true;
      this.uploadEnabled = false;
      this.rowData = [];
      this.excelService.uploadedData = JSONData; //JSONDataSorted
      this.uploadToS3();

      this.spinner.hide();

      this.warningMessage = `(${count})  ${warningMessage.uploadInvalidRecords} `;

      window.scroll(0, 0);
    }
  }
  getWrappedColumn(v) {
    return { v, t: 's', s: { alignment: { wrapText: true } } };
  }
  getExportDataError() {
    const data = [];

    const dataToExport = this.excelService.uploadedData;

    dataToExport.forEach((row) => {
      data.push({
        line_path: row.line_path,
        order_date: row.order_date,
        vanning_date: row.vanning_date,
        vanning_qty: row.vanning_qty,
        prod_date: row.prod_date,
        prod_qty: row.prod_qty,
        remarks: row.remarks ? this.getWrappedColumn(row.remarks) : '',
      });
    });

    return data;
  }
  getExportData() {
    const data = [];

    const dataToExport = this.excelService.uploadedData;

    dataToExport.forEach((row) => {
      data.push({
        business_entity: this.namcValue,
        line_path: row.line_path,
        order_date: row.order_date,
        vanning_date: row.vanning_date,
        vanning_qty: row.vanning_qty,
        prod_date: row.prod_date,
        prod_qty: row.prod_qty,
        calc_date: row.calc_date,
        dest_code: this.destCode,
        order_status: row.order_status,
        userid: row.userid,
      });
    });

    return data;
  }

  getErrorReport() {
    let easternCurrentDate = String(mm().tz('US/Michigan').format('YYYY-MM-DD_HH:mm:ss'));
    this.warningMessage = '';
    var exportData = [];

    this.excelService.uploadedData.forEach((e) => {
      const obj = {
        line_path: e.line_path,
        order_date: e.order_date,
        vanning_date: e.vanning_date,
        vanning_qty: e.vanning_qty,
        prod_date: e.prod_date,
        prod_qty: e.prod_qty,
        remarks: this.getWrappedColumn(e.remarks),
      };
      exportData.push(obj);
    });
    const headers = ['Line Path', 'Order DT', 'Van DT', 'Van Vol', 'Prod DT', 'Prod Vol', 'Error/Comments'];
    const Etimestamp = easternCurrentDate

      .split('.')[0]
      .replace('T', '_')
      .split('')

      .filter((d) => d !== '-' && d !== ':')
      .join('');

    const timestamp = new Date(this.newDate)
      .toISOString()
      .split('.')[0]
      .replace('T', '_')
      .split('')
      .filter((d) => d !== '-' && d !== ':')
      .join('');
    if (exportData.length > 0) {
      this.excelService.exportExcelFileStyle(
        exportData,
        this.fileName.split('.')[0] + this.namcName + '_error_' + Etimestamp + '.xlsx',
        headers
      );
    }
  }

  uploadData() {
    this.removeMessage();
    this.getBatchStatus(this.isFinalForecastCompleted);
  }

  uploadRecord = () => {
    if (this.uploadEnabled) {
      this.openDialog('upload');
    }
    this.uploadEnabled = false;
  };

  //Confirmation of upload
  openDialog(data) {
    if (data !== '') {
      const dialogConfig = new MatDialogConfig();
      dialogConfig.disableClose = true;
      dialogConfig.id = this.modalComponent;
      dialogConfig.width = '350px';
      dialogConfig.data = data;

      const dialogRef = this.dialog.open(saveDialog, dialogConfig);
      dialogRef.afterClosed().subscribe((res) => {
        this.spinner.show();

        if (res === 'cancel') {
          this.spinner.hide();
          this.uploadEnabled = true;
        } else if (res === 'save') {
          this.spinner.show();

          this.getBatchStatus(this.uploadToS3);
          this.excelService.uploadedData = this.rowData;
        }
        this.headerCheckboxChecked = false;
        this.osPartsGrid.gridOptions.api.refreshHeader();
        this.osPartsGrid.gridOptions.api.forEachNode(function (rowNode, index) {
          if (rowNode.data.rowCheckBox) {
            rowNode.setSelected(false);
            rowNode.data.checkedEditMode = false;
            rowNode.data.rowCheckBox = false;
          }
          rowNode.setData(rowNode.data);
        });
      });
    } else {
      this.warningMessage = warningMessage.noDataToUpload;

      window.scroll(0, 0);
    }
  }

  uploadDataToAPI(fileName) {
    var updatedData = [];
    this.uploadEnabled = false;
    this.rowData.forEach((element) => {
      var updatedFields = {
        business_entity: this.namcValue,
        calc_date: element.calc_date,
        dest_code: this.destCode,
        line_path: element.line_path,
        order_date: element.order_date,
        prod_date: element.prod_date,
        prod_qty: element.prod_qty,
        vanning_date: element.vanning_date,
        vanning_qty: element.vanning_qty,
        userid: this.userName,
      };
      updatedData.push(updatedFields);
    });

    var data1 = {
      workid: this.user,
      user_role: this.userRole,
      business_entity: this.namcValue,
      business_EntityName: this.namcName,
      fileKey: fileName,
      vanningRange: this.vanningRange,
      startPosition: this.editApiStartPosition,
      endPosition: this.editApiEndPosition,
      batchRunLogID: this.batchRunLogID,
      userid: this.userName,
    };

    this.rundownService.uploadRundown(data1).subscribe({
      error: this.errorCallback,
      next: (success) => {
        const { updatedRows, uploadedRows } = success.body.data;
        if (updatedRows === 0) {
          this.warningMessage = this.warningMessage = `${warningMessage.reportsNotUpdated}  ${this.itContact}.`;
          this.rowData = [];

          window.scroll(0, 0);
        } else {
          if (this.rowData.length > this.editApiEndPosition) {
            this.timesEditApiCalled++;
            this.batchRunLogID = success.body.data.batchRunLogID;
            this.editApiStartPosition = this.timesEditApiCalled * this.editApiDefaultLimit;

            this.editApiEndPosition = this.editApiStartPosition + this.editApiDefaultLimit;
            return this.uploadDataToAPI(fileName);
          } else {
            this.spinner.hide();
          }
        }
        this.timesEditApiCalled = 0;
        this.editApiStartPosition = 0;
        this.editApiEndPosition = this.editApiDefaultLimit;

        this.successMessage = updatedRows + ' ' + successMessage.recordUploaded;

        const dataTosend = {
          workid: this.user,
          user_role: this.userRole,
          NAMC: this.namcName,
          batch_name: `osp-parts-verification-batch`,
        };

        if (this.namcValue === '13' || this.namcValue === '14' || this.namcValue === '15') {
          this.bulkadjustmentService.calculation(dataTosend).subscribe(
            (success) => {
              if (success.body.status) {
                console.log('osp-parts-verification-batch  batch started successfully for your request');
              }
              this.spinner.hide();
            },
            (err) => {
              this.spinner.hide();
              this.successMessage = '';
              this.warningMessage = `${warningMessage.errorOspPartsVerificationBatch}  ${this.itContact}.`;

              window.scroll(0, 0);
            }
          );
        }

        //calling osp-buildrequirement-batch if get sudo day
        // if (this.namcValue !== '13' && this.namcValue !== '14' && this.namcValue !== '15') {
        //   const isTodayGetSudo = this.loadGetSudoDates();

        //   if (isTodayGetSudo) {
        //     const dataTosend = {
        //       workid: this.user,
        //       user_role: this.userRole,
        //       business_entity: this.namcValue,
        //       fileName: `build-requirement/${this.namcName}/inbound/GETSUDO_BLDREQ_${this.namcName}.txt`,
        //       batch_name: `osp-buildrequirement-nqc-batch`,
        //     };

        //     this.bulkadjustmentService.calculation(dataTosend).subscribe(
        //       (success) => {
        //         if (success.body.status) {
        //           console.log('osp-build-requirement-batch batch started successfully for your request');
        //         }
        //         this.spinner.hide();
        //       },
        //       (err) => {
        //         this.spinner.hide();
        //         this.warningMessage = `${warningMessage.errorOspBuildRequirementBatch}  ${this.itContact}.`;

        //         this.successMessage = '';

        //         window.scroll(0, 0);
        //       }
        //     );
        //   }
        // }
        const isTodayGetSudo = this.loadGetSudoDates();
        const dataTosend1 = {
          workid: this.user,
          user_role: this.userRole,
          businessEntity: this.namcValue,
          batch_name: `osp-parts-interface-batch`,
          isRundownTrigger: true,
          blreqToTrigger: isTodayGetSudo,
        };

        //CALLING parts interface batch
        this.bulkadjustmentService.calculation(dataTosend1).subscribe(
          (success) => {
            if (success.body.status) {
              console.log('osp-parts-interface-batch batch started successfully for your request');
            }
            this.spinner.hide();
          },
          (err) => {
            this.spinner.hide();
            this.successMessage = '';
            this.warningMessage = `${warningMessage.errorOspPartsInterfaceBatch}  ${this.itContact}.`;

            window.scroll(0, 0);
          }
        );

        this.rowData = uploadedRows;

        window.scroll(0, 0);
      },
    });
  }

  errorCallback = (error) => {
    console.error(error);
    this.spinner.hide();
    this.warningMessage = warningMessage.apiLogicFail;
    this.rowData = [];
    this.osPartsGrid.gridOptions.api.setRowData(this.rowData);
  };

  cancelSave() {
    this.rowData = [];
    this.uploadEnabled = false;
    this.removeMessage();
  }

  getFileName() {
    const timestamp = new Date(this.newDate)
      .toISOString()
      .split('.')[0]
      .replace('T', '_')
      .split('')
      .filter((d) => d !== '-' && d !== ':')
      .join('');
    return this.uploadError
      ? `Rundown/${this.namcName}/error/Rundown_error_${this.user}_${timestamp}.xlsx`
      : `Rundown/${this.namcName}/upload/Rundown_upload_${this.user}_${timestamp}.xlsx`;
  }

  uploadToS3 = () => {
    const data = this.uploadError ? this.getExportDataError() : this.getExportData();

    const fileName = this.getFileName();
    const headers = this.uploadError
      ? ['LINE_PATH', 'ORDER_DATE', 'VANNING_DATE', 'VANNING_QTY', 'PROD_DATE', 'PROD_QTY', 'ERROR/COMMENTS']
      : [
          'business_entity',
          'line_path',
          'order_date',
          'vanning_date',
          'vanning_qty',
          'prod_date',
          'prod_qty',
          'calc_date',
          'dest_code',
          'order_status',
          'userid',
        ];
    setTimeout(async () => {
      this.fileBuffer = await this.excelService.getFileBuffer(data, fileName, headers);

      this.uploadExcelDataToS3({ fileName });
    }, 1000);
  };

  uploadExcelDataToS3({ fileName }) {
    const data = {
      workid: this.user,
      user_role: this.userRole,
      fileName: fileName,
    };
    this.bulkadjustmentService.getExcelFileUrl(data).subscribe({
      next: (response) => {
        if (response.body.signedUrl) {
          const a = document.createElement('a');
          a.href = response.body.signedUrl;

          this.bulkadjustmentService.putDatainExcel(this.fileBuffer, a.href).subscribe({
            next: (res) => {
              if (!this.uploadError) {
                this.uploadDataToAPI(fileName);
              }
            },
            error: this.errorCallback,
          });
        }
      },
      error: this.errorCallback,
    });
  }

  getRudnownProdDate = () => {
    var data = {
      offset: 0,
      limit: 5000,
      business_entity: this.namcValue,
      workid: this.user,
      user_role: this.userRole,
      prod_date: new Date(this.newDate),
    };
    this.rundownService.getRundownProdDate(data).subscribe({
      error: this.errorCallback,
      next: (res) => {
        this.currentMonthData = res.body.data.data;
      },
    });
  };
}

@Component({
  selector: 'dialog-overview-example-dialog',
  templateUrl: 'dialog.component.html',
  styleUrls: ['../core/master-delete/master-delete.component.scss'],
})
export class saveDialog {
  constructor(public dialogRef: MatDialogRef<saveDialog>) {}

  onNoClick(): void {
    this.dialogRef.close();
  }
}