import { Component } from '@angular/core';
import * as moment from 'moment';

@Component({
  selector: 'production-progress-text-editor',
  template: `
    <label *ngIf="showInput">{{ value }} </label>
    <input
      *ngIf="!showInput"
      #input
      type="number"
      (keypress)="onKeyPress($event)"
      maxlength="5"
      pattern="[0-9]"
      [(ngModel)]="value"
      class="grid-cell-edit-layout "
      (change)="textUpdated()"
      autofocus
    />
  `,
  styles: [
    `
    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }
    .grid-cell-edit-layout {
      width: 100%;
      border: 1px solid black;
    }
    .invalid-text {
      border: 2px solid red !important;
    }
    `,
  ],
})
export class ProdProgressFinalEditComponent {
  public params: any;
  showInput = true;
  value: any;
  isEmpty = true;
  isPastDay: boolean;

  constructor() { }

  agInit(params: any): void {
    localStorage.setItem('isFinal', 'false');


    console.log("inside review chnages--------------", params.column)
    // if (params.column === 'int_plan_volume') {
    //   params.data.intPlanVol = this;

    //   this.value = params.data.int_plan_volume;


    //   if (this.value) {
    //     this.isEmpty = false;
    //   } else {
    //     this.isEmpty = true;
    //   }
    // }
    if (params.column === 'final') {
      params.data.Final = this;

      this.value = params.data.final;


      if (this.value) {
        this.isEmpty = false;
      } else {
        this.isEmpty = true;
      }
    }

    // if (params.column === 'cumul_plan_net') {
    //   params.data.cumulPlanNet = this;
    //   this.value = params.data.cumul_plan_net;

    //   this.isEmpty = false;
    // } else {
    //   this.isEmpty = true;
    // }
    this.params = params;
    const today = moment();
    console.log("Today's Date:", today.format('MM/DD/YYYY'));

    
    // Assuming 'actual_date' is in the format 'MM/DD/YYYY'
    let actualDate = moment(this.params?.data.actual_date, 'MM/DD/YYYY');
    console.log("Actual Date:", actualDate.format('MM/DD/YYYY'));
    
    // Check if actualDate is before today
    if (actualDate.isBefore(today, 'day')) {
      this.isPastDay = true;  // Show input if the actualDate is before today
    } else {
      this.isPastDay = false;  // Hide input for today's date or future dates
    }
    

    if (
      this.params.data.checkedEditMode && this.isPastDay


    ) {
      this.showInput = false;
    } else {
      this.showInput = true;
    }
  }

  // called when the cell is refreshed
  refresh(params: any): boolean {
    this.params = params;
    return true;
  }

  public textUpdated() {
    console.log("textupadted")
    console.log(" outside inside if", this.params)


    localStorage.setItem('isFinal', 'true');
    if (this.params.column === 'final') {
      if (this.value !== this.params.data.final) {
        console.log(" outside inside if", this.params)

        this.params.data.final = this.value;
        this.params.data.Final = true;
        this.params.data.Updated = true;

      }
    } else {
      this.params.data.Final = this.value;
      this.params.data.Updated = false;

    }






  }

  onKeyPress(event) {
    const charCode = event.charCode;
    // Allow digits only (0-9)
    if (charCode < 48 || charCode > 57) {
      event.preventDefault();
    }
    if (event.target.value.length <= 4) {
      return true;
    } else {
      return false;
    }
  }
  stopTyping() {
    return false;
  }

}
