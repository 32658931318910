import { Component, Input } from '@angular/core';
import * as mm from 'moment-timezone';
import { ChangeDetectorRef } from '@angular/core';
import { debounceTime } from 'rxjs/operators';
import { Subject } from 'rxjs';

@Component({
  selector: 'app-airfreight-arrived-checkbox',
  template: `
  <mat-checkbox (change)="checkCheckBoxvalue($event)" [(ngModel)]="value"></mat-checkbox>
  <!-- <input type="checkbox" (change)="checkCheckBoxvalue($event)" [(ngModel)]="value"> -->
  `,
  styles: [
    `
      ::ng-deep .mat-checkbox .mat-checkbox-frame {
        border: 1px solid #c5c5c5 !important;
      }
      ::ng-deep .mat-checkbox-disabled .mat-checkbox-background {
        background-color: #d3d3d3;
      }
    `,
  ],
})
// <label *ngIf="showInput" style="color:#2688DA"> &#10004; </label>
//   <mat-checkbox
//     [disabled]="disable"
//     *ngIf="showCheckBox"
//     (change)="checkCheckBoxvalue($event)"
//     [(ngModel)]="value"
//   ></mat-checkbox>
export class AirfreightArrivedCheckboxComponent {

  @Input() cell: any;

  showInput: boolean;
  value = '';
  isEmpty = true;
  showCheckBox = false;
  editable: boolean;
  disable: boolean = false;

  public params: any;
  agInit(params: any): void {
    // console.log(params);
    if (params.column === 'isArrived') {
      params.data.ArrivedChecked = this;
      this.value = params.data.isArrived;
      if(this.value == null || this.value === undefined)
      this.value = params.data.arrivedChecked;
      // this.disable = params.data.disabled.supplieredit;
    }

    if (this.value) {
      this.showInput = true;
    } else {
      this.showInput = false;
    }
    this.params = params;
    if (this.params.data.checkedEditMode) {
      this.showCheckBox = true;
      this.showInput = false;
    } else {
      this.showCheckBox = false;
    }
    if (this.params.data.checkedEditMode && new Date(this.params.data.date) <= new Date(String(mm().tz('US/Michigan').format('MM/DD/YYYY')).substring(0, 10))) {
      this.disable = true;
    }
  }

  private inputSubject = new Subject<void>();
  constructor(private cdr: ChangeDetectorRef) {
    this.inputSubject.pipe(debounceTime(300)).subscribe(() => {
      this.changeVancenterETA(this.params.data.partIndex);
    });
  }
  changeVancenterETA(part_index): void {
    this.params.context.componentParent.changeVancenterETA(part_index);
    this.cdr.detectChanges(); // Trigger change detection
  }
  checkCheckBoxvalue(event) {
    if (this.params.column === 'isArrived') {
      this.params.data.isArrived = this.value;
      this.changeVancenterETA(this.params.data.partIndex);
    }
  }
}
