import { logErrors } from './shared/logger';
import * as mm from 'moment-timezone';
import * as moment from 'moment';
import { ColumnState, GridOptions } from 'ag-grid-enterprise';

export class Constants {
  public navLinkItems = {
    fod: { displayName: 'Home', route: 'dashboard' },
    user: {
      person: { displayName: 'MAX JONES', icon: 'person' },
      id: { displayName: '10034' },
      profile: { displayName: 'SUPER ADMIN' },
      help: { displayName: 'HELP', icon: 'help' },
      settings: { displayName: 'SETTINGS', icon: 'settings' },
      logout: { displayName: 'LOGOUT', icon: 'logout' },
    },
    main: {
      jobstatus: { displayName: 'Job/Process Status' },
      orderforecast: { displayName: ' Order/Forecast Maintenance' },
      adjustmentinquire: { displayName: 'Adjustment Inquiry' },
      rundownmaintenance: { displayName: ' Rundown Maintenance' },
      bulkadjustment: { displayName: 'Bulk Adjustment' },
      reports: { displayName: 'Reports' },
      getsudo: { displayName: 'GetSudo Status' },

      fluctuation: { displayName: '  Fluctuation Allowance Maintenance' },
      airfreightorder: { displayName: 'Air Freight Order' },
      master: { displayName: 'Master' },
      calendar: { displayName: 'Calendar' },
      help: { displayName: 'Training Videos' },

      summary: { displayName: 'Summary' },
      shifts: { displayName: 'Shifts' },

    },
    // getPlantApiUrl: "GetFieldDetails",
    // getUserAccessUrl: 'GetUserAccess'
  };
  // public lookupUrl = '';
  // public lookupApi = ''
  // constructor() {
  // 	this.lookupUrl = 'https://lgvb6mmxyh.execute-api.us-west-2.amazonaws.com/dev/';
  // 	this.lookupApi = 'https://4f71bcjso3.execute-api.us-west-2.amazonaws.com/dev/'
  // }

  public osPipenavLinkItems = {

    main: {
      PartsInquire: { displayName: 'Parts Inquiry' },
      PartMaintenance: { displayName: 'Parts Maintenance' },
      ContainerMaintenance: { displayName: ' Container Maintenance' },
      SealVerification: { displayName: 'Seal Verification' },
      pipejobStatus: { displayName: 'Job/Process Status' },
      pipeReports: { displayName: 'Reports' },
      paceInquire: { displayName: 'Stocking Pace Inquiry' },
      paceMaintenance: { displayName: 'Stocking Pace Maintenance' },
      containerOrdering: { displayName: 'Container Ordering' },
      moduleType: { displayName: 'Module Type' },

      productionProgress: { displayName: 'Production Progress' },


      moduleStocking: { displayName: 'Module Stocking' }

    },

    // getPlantApiUrl: "GetFieldDetails",
    // getUserAccessUrl: 'GetUserAccess'
  };
  // public osInventoryLinkItems = {
  //   main: [

  //     { displayName: 'Stocking Pace Inquiry',link:'inventory/stocking-pace-inquiry'},

  //   ]
  // }
}


export const autoRefreshInterval = 1 * 60 * 1000; // 1 minute

export const freezingMesssage = `batch jobs are running, so Edit/Upload is not allowed. Please try after a few minutes.`;
export const freezingUploadMesssage = `batch jobs are running, so Upload is not allowed. Please try after a few minutes.`;
export const rundownFreezeMessageforCalc = `Rundown Edit/Upload not allowed in between calc1 and final calc. Kindly upload post Final calc`;
export const allowedFilesize = 5000; // Kilobytes
export const allowedFilesizeInBytes = allowedFilesize * 1024;
export const allowedFileTypes = ['xlsx', 'xls'];

export const PitchfreezingMsg = `Pitch calculation batch jobs are running, so editing is not allowed. Please try again in a few minutes.`;




export const warningMessage = {
  errorMessage: 'Something went wrong!', //api fail
  rundownSearchError: 'Please select mandatory fields.',

  errorOspPartsInterfaceBatch: `Some problem occurred in osp-parts-interface-batch. Please contact IT support team,`,
  errorOspBuildRequirementBatch: `Some problem occurred in osp-build-requirement-batch. Please contact IT support team,`,
  errorOspPartsVerificationBatch: `Some problem occurred in osp-parts-verification-batch. Please contact IT support team,`,
  selectdest: 'Please select mandatory fields.',
  stockingdateInvalid: 'Stocking DT From cannot be greater than Stocking DT To.',

  //noRowSelected:'No rows are selected.',
  selectRowsToEdit: 'Please select at least one row to edit.',

  uploadInvalidRecords: 'Invalid records found in',
  //uploadEmptyTemplateError:'No records to upload.',
  uploadVanningDatesMisMatchError: `Selected Vanning date range is not matching with the Vanning date in the uploaded rundown file.`,
  //uploadFileEmpty:'File is Empty.',
  //uploadInvalidTemplate:'Invalid file template.',
  uploadFileSizeTooBig: `File size is too big. Allowed file size is: ${allowedFilesize} KB.`,
  //uploadErrorFileType:`Only ${allowedFileTypes} files are accepted.`,
  //uploadSuccess: 'Record(s) saved successfully.',
  //uploadError: `Record not saved, please reachout to technical team.`,
  selectDock: 'Please select dock',
  selectToSearch: 'Please select at least one field.',
  selectVanFrom: 'Please select Van From.',
  addRecord: 'Record(s) added successfully.',
  duplicateRecord: 'Duplicate Record(s) Found.',
  apiLogicFail: 'Technical failure occurred. Please contact IT support team,',
  duplicatesFound: 'Duplicate Record(s) found.',
  requiredFieldsMissing: 'Some required fields are missing in the data. Please contact IT support team,',
  noRowsSelected: 'Please select at least one row before downloading.',
  fileNotAvilable: 'File not available.',
  reportDownload: 'Please select at least one report to download.',
  fileEmpty: 'Please check the file, there is no data in the file.',
  invalidFileTemplate: 'Invalid file template, please use the template file in the screen as reference.',
  noColEmpty: 'No column should be empty.',
  invalidBusinessEntity: 'Invalid Business Entity.',
  editToReview: 'No data has been updated. Please modify.',
  noDataAvailble: 'There is no data available for your search.',
  waitToLoadData: 'Please wait while data is being loaded.',
  uploadFileError: 'There are Errors in the uploaded file. Please check error report and try again.',
  reportsNotUpdated: 'Record(s) could not be updated. Please contact IT support team,',
  recordsNotUpdated: 'Records in the provided excel have not been modified. Please update the data to make changes.',
  onlyxlxsAlloed: 'Kindly upload an Excel file.',
  noDataToUpload: 'No data in the file to upload.',
  //recordsNotSaved: 'Record(s) not saved,Please contact IT support team,',
  reportsNotVailble: ' Report(s) not available. Please contact OS IT and Business Support,',
  fileNotAva: 'File not available. Please contact OS IT and Business Support.',

  invalidKanbanSearch: 'Invalid Kanban provided. Please try again with valid Kanban.',

  uploadEnabled: `Please review the adjustments, then click on Upload Data button to upload your data.`,

  ccsAPIFailed: `CCS API has failed. Please contact IT support team.`,
  invalidRenbanSearch: "Invalid Renban provided. Please try again with valid Renban.",
  invalidVesselName: "Invalid Vessel Name  provided. Please try again with valid Vessel Name",
  selectToSearch2: 'Please select dock and prod date from.',
  errorLLPshopfloordevannigBatch: `Some problem occurred in osp-shopfloor-devanning-batch. Please contact IT support team,`,


  ccsDataNotAvailable: (namcName) => `CCS data not available for NAMC: ${namcName}.`,
  bulkDownloadError: `Download only current and future Vanning (dates) records for bulk upload.`,
  duplicateModuleType: `Duplicate Record - record is already exist.`

};

export const successMessage = {
  recordTapped: 'Success! Tapped quantity has been updated',
  recordUpdated: 'Record(s) Updated Successfully.',
  recordUploaded: 'Record(s) Uploaded Successfully.',
  deleteRecord: 'Record(s) Deleted Successfully.',
  bulkAdjStarted: `The bulk adjustment batch has started. 
    Edit and upload functionalities will be restricted for the following screens while the bulk adjustment is running (FA, Rundown). 
    Please check the status of the bulk adjustment batch in the job status screen.`,
  containerUndevan: 'Success! Container has been undevanned',
  containerDevan: 'Success! Container has been devanned',
  moduleTypeDeleted: 'Module Type Deleted!',
  moduleTypeUpdated: 'Module Type Updated!',
  moduleTypeAdded: 'Module Type Added!',
  //shift screen
  shiftsAdded: 'Shifts added successfully',
  shiftBreaksAdded: 'Shifts Breaks added successfully',
  shiftBreaksUpdated: 'Shifts Breaks updated successfully',

  stockAll: 'All the selected modules are  now stocked for the Dock ',
  unstockAll: 'All the selected modules are  now unstocked for the Dock '



};

// To be used inside filter params when using date filter
export function dateFilterComparator(filterLocalDateAtMidnight, cellValue) {
  const dateAsString = cellValue;

  if (dateAsString == null) {
    return 0;
  }

  // In the example application, dates are stored as dd/mm/yyyy
  // We create a Date object for comparison against the filter date
  const dateParts = dateAsString.split('/');
  const year = Number(dateParts[2]);
  const month = Number(dateParts[0]) - 1;
  const day = Number(dateParts[1]);
  const cellDate = new Date(year, month, day);

  // Now that both parameters are Date objects, we can compare
  if (cellDate < filterLocalDateAtMidnight) {
    return -1;
  } else if (cellDate > filterLocalDateAtMidnight) {
    return 1;
  }
  return 0;
}

//common code in home and calendar
export function getDayName(currentYear, currentMonth, i) {
  const days = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
  const dayName = new Date(`${currentMonth} ${i},${currentYear}`);

  const day = dayName.getDay();
  return days[day];
}

export function getDaysInMonth(year, month) {
  return new Date(year, month, 0).getDate();
}

export function dateComparator(date1, date2) {
  function monthToNum(date) {
    if (date === undefined || date === null || date.length !== 10) {
      return null;
    }

    const newDate: Date = new Date(date);

    const yearNumber = newDate.getFullYear();
    const monthNumber = newDate.getMonth();
    const dayNumber = newDate.getDate();

    const result = yearNumber * 10000 + monthNumber * 100 + dayNumber;
    // 29/08/2004 => 20040829
    return result;
  }

  const date1Number = monthToNum(date1);
  const date2Number = monthToNum(date2);

  if (date1Number === null && date2Number === null) {
    return 0;
  }
  if (date1Number === null) {
    return -1;
  }
  if (date2Number === null) {
    return 1;
  }

  return date1Number - date2Number;
}

export function onKeyPress(event: KeyboardEvent) {
  const allowedKeys = ['Backspace', 'Tab', 'ArrowLeft', 'ArrowRight', 'Delete'];
  const isNumber = /^[0-9]$/.test(event.key);

  // Allow number keys, allowed keys, and prevent default for others
  if (!isNumber && !allowedKeys.includes(event.key)) {
    event.preventDefault();
  }
}

export function dateIsValid(dateStr) {
  const regex = /^\d{2}\/\d{2}\/\d{4}$/;

  if (dateStr.match(regex) === null) {
    return false;
  }

  const [month, day, year] = dateStr.split('/');

  // 👇️ format Date string as `yyyy-mm-dd`
  const isoFormattedStr = `${year}-${month}-${day}`;

  const date = new Date(isoFormattedStr);

  const timestamp = date.getTime();

  if (typeof timestamp !== 'number' || Number.isNaN(timestamp)) {
    return false;
  }
  return true;
}

// parts roles
const ospReadonly = 'OSP.READONLY';
const ospUser = 'OSP.USER';
const ospNamcAdmin = 'OSP.NAMCADMIN';
const ospAdmin = 'OSP.ADMIN';

// pipe roles
const ospipeAdmin = 'OSPIPE.ADMIN';
const ospipeNamcAdmin = 'OSPIPE.NAMCADMIN';
const ospipeLP = 'OSPIPE.LP';
const ospipeFlUser = 'OSPIPE.FLUSER';
const ospipeSeUser = 'OSPIPE.SECUSER';
const ospipeReadonly = 'OSPIPE.READONLY';

export function checkAccess() {
  const userRole = localStorage.getItem('UserRoles');

  let checkRole;

  if (userRole.includes(ospReadonly)) {
    checkRole = ospReadonly;
  }
  if (userRole.includes(ospUser)) {
    checkRole = ospUser;
  }
  if (userRole.includes(ospNamcAdmin)) {
    checkRole = ospNamcAdmin;
  }
  if (userRole.includes(ospAdmin)) {
    checkRole = ospAdmin;
  }

  // let isPartsAvailable=false;

  let access = {
    edit: false,
    upload: false,
    download: false,
    calendarupload: false,
    calendardownload: false,
    holdrelease: false,
    bulkadjustmentview: false,
    mastersetupfunc: false,
    isPartsAvailable: false
  };
  switch (checkRole) {
    case ospReadonly:
      access = {
        edit: false,
        upload: false,
        calendarupload: false,
        holdrelease: false,
        bulkadjustmentview: false,
        mastersetupfunc: false,
        download: false,
        calendardownload: false,
        isPartsAvailable: true
      };
      break;
    case ospUser:
    case ospNamcAdmin:
      access = {
        edit: true,
        upload: true,
        download: true,
        calendarupload: false,
        holdrelease: true,
        bulkadjustmentview: true,
        mastersetupfunc: true,
        calendardownload: false,
        isPartsAvailable: true
      };
      break;
    case ospAdmin:
      access = {
        edit: true,
        upload: true,
        download: true,
        calendarupload: true,
        holdrelease: true,
        bulkadjustmentview: true,
        mastersetupfunc: true,
        calendardownload: true,
        isPartsAvailable: true
      };
      break;
    default:
      access.isPartsAvailable = false;
      logErrors('No role found!');
      break;
  }
  return access;
}

export function checkAccessPipe() {
  const userRole = localStorage.getItem('UserRoles');

  let checkRole;

  if (userRole.includes(ospipeReadonly)) {
    checkRole = ospipeReadonly;
  }
  if (userRole.includes(ospipeSeUser)) {
    checkRole = ospipeSeUser;
  }
  if (userRole.includes(ospipeFlUser)) {
    checkRole = ospipeFlUser;
  }
  if (userRole.includes(ospipeLP)) {
    checkRole = ospipeLP;
  }
  if (userRole.includes(ospipeNamcAdmin)) {
    checkRole = ospipeNamcAdmin;
  }
  if (userRole.includes(ospipeAdmin)) {
    checkRole = ospipeAdmin;
  }

  let screenAccess = {
    partsInquire: false,
    partsMaintenance: false,
    containerMaintenance: false,
    sealVerification: false,
    pipeJobStatus: false,
    pipeReports: false,
    productionProgress: false

  };

  let isPipeAvailable = true;

  let access = {
    edit: false,
    matchmismatch: false,
    modulelevaltapping: false

  };
  switch (checkRole) {
    case ospipeReadonly:
      access = {
        edit: false,
        matchmismatch: false,
        modulelevaltapping: false
      };
      screenAccess = {
        partsInquire: true,
        partsMaintenance: true,
        containerMaintenance: true,
        sealVerification: true,
        pipeJobStatus: true,
        pipeReports: true,
        productionProgress: true,

      };
      break;
    case ospipeSeUser:
      access = {
        edit: false,
        matchmismatch: true,
        modulelevaltapping: false

      };
      screenAccess = {
        partsInquire: false,
        partsMaintenance: false,
        containerMaintenance: false,
        sealVerification: true,
        pipeJobStatus: false,
        pipeReports: false,
        productionProgress: false,

      };
      break;
    case ospipeFlUser:
      access = {
        edit: true,

        modulelevaltapping: true,
        matchmismatch: false

      };
      screenAccess = {
        partsInquire: true,
        partsMaintenance: true,
        containerMaintenance: true,
        sealVerification: false,
        pipeJobStatus: true,
        pipeReports: true,
        productionProgress: true

      };
      break;
    case ospipeLP:
      access = {
        edit: false,
        matchmismatch: true,
        modulelevaltapping: false


      };
      screenAccess = {
        partsInquire: false,
        partsMaintenance: false,
        containerMaintenance: false,
        sealVerification: true,
        pipeJobStatus: false,
        pipeReports: false,
        productionProgress: false,

      };
      break;
    case ospipeAdmin:
      access = {
        edit: true,
        matchmismatch: false,
        modulelevaltapping: true

      };
      screenAccess = {
        partsInquire: true,
        partsMaintenance: true,
        containerMaintenance: true,
        sealVerification: true,
        pipeJobStatus: true,
        pipeReports: true,
        productionProgress: true

      };
      break;
    case ospipeNamcAdmin:
      access = {
        edit: true,
        matchmismatch: false,
        modulelevaltapping: true

      };
      screenAccess = {
        partsInquire: true,
        partsMaintenance: true,
        containerMaintenance: true,
        sealVerification: true,
        pipeJobStatus: true,
        pipeReports: true,
        productionProgress: true

      };
      break;
    default:
      isPipeAvailable = true;
      logErrors('No role found!');
      break;
  }
  return { screenAccess, access, isPipeAvailable };
}

// Cell Style Config for Order/Forecast and Rundown
// Sets cell style/background color based on the vanning date and current easter date
// Grey, Blue or Yellow respectively for Old, Current and Future vanning dates
export function getCellStyleConfig(params) {
  // console.log("params",params.data);

  const minVanningFrom = localStorage.getItem('minVanningFrom');

  if (minVanningFrom === 'null') {
    return { background: '#f8f9fa' };
  } else if (minVanningFrom) {
    if (
      new Date(params.data.vanning_date).toISOString().split('T')[0] <
      new Date(minVanningFrom).toISOString().split('T')[0]
    ) {
      return { background: '#f8f9fa' }; // grey color
    } else if (
      new Date(params.data.vanning_date).toISOString().split('T')[0] >
      new Date(minVanningFrom).toISOString().split('T')[0]
    ) {
      return { background: '#cfe4f5' }; // blue color
    } else {
      return { background: '#e6ef76cf' }; // yellow color
    }
  }
}

// Cell Style Config for prod progress
// Sets cell style/background color based on the vanning date and current easter date
// Grey, Blue or Yellow respectively for Old, Current and Future vanning dates

export function getCellStyleConfig2(params) {
  const currentTime = mm().tz('US/Michigan'); // Current time in 'US/Michigan'
  const easternCurrentDate = currentTime.format('MM/DD/YYYY HH:mm:ss'); // Format as 'MM/DD/YYYY HH:mm:ss'

  // Calculate one minute ago in the same time zone and format
  const oneMinuteAgo = currentTime.clone().subtract(1, 'minute').format('MM/DD/YYYY HH:mm:ss');

  // `Updated DateTime` is already in the correct format from SQL
  const updateDateTime = params.data.updated_datetime;

  // Debugging logs for validation
  console.log('Eastern Current Time:', easternCurrentDate);
  console.log('One Minute Ago:', oneMinuteAgo);
  console.log('Updated DateTime:', updateDateTime);

  // Check if `is_recalculate` is true first (yellow highlight)
  if (params.data.is_recalculate) {
    return { background: '#e6ef76cf' }; // Highlight row with yellow color
  }
  // Perform direct string comparison for date range (blue highlight)
  else if (updateDateTime >= oneMinuteAgo && updateDateTime <= easternCurrentDate) {
    return { background: '#cfe4f5' }; // Highlight row with blue color
  }

  // Default case: white background for all other rows
  return { background: '#ffffff' };
}

// Cell Style Config for Order/Forecast and Rundown
// Sets cell style/background color based on the vanning date and current easter date
// Grey, Blue or Yellow respectively for Old, Current and Future vanning dates
export function getRowCheckboxSyleConfig(params) {
  // console.log("params",params.data);

  const styleConfig = getCellStyleConfig(params);

  return {
    ...styleConfig,
    'padding-left': '12px',
    'line-height': '20px',
    'padding-top': '5px',
  };
}

export function validateVanningDates({ vanningFrom, vanningTo }) {
  let res = { valid: true, error: null };

  if (!vanningFrom || !vanningTo) return res;

  if (new Date(vanningFrom) > new Date(vanningTo)) {
    res = {
      valid: false,
      error: 'Van From date cannot be greater than Van To date.',
    };
  }

  return res;
}

export function resetSortingAndFilters(grid) {
  if (grid) {
    // grid.gridOptions.columnApi.resetColumnState();

    // const hideItems = this.showHideList.filter((item) => {
    //   return !this.selectedShowHideList.map((sItem) => sItem.value).includes(item.value);
    // });

    // this.onShowHideChange({ value: this.selectedShowHideList });

    const gridOptions: GridOptions = grid.gridOptions;

    // Reset all filters
    gridOptions.api.setFilterModel(null);

    // Current Column State
    const columnState: ColumnState[] = gridOptions.columnApi.getColumnState();

    // Removing sorting from all columns and creating a new unsorted column state
    const unsortedColumnState = columnState.map((state) => {
      state.sort = null;
      state.sortIndex = null;
      return state;
    });

    gridOptions.columnApi.applyColumnState({ state: unsortedColumnState });

    grid.gridOptions = gridOptions;
  }

}

export function roundUp(value: number, decimalPlaces: number): number {
  const factor = Math.pow(10, decimalPlaces);
  return Math.ceil(value * factor) / factor;
}
export function getUniqueByKey(array, key) {
  const seen = new Set();
  return array.filter(item => {
    const value = item[key];
    if (seen.has(value)) {
      return false; // Skip if the value has already been seen
    } else {
      seen.add(value); // Add value to the set
      return true; // Keep the first occurrence
    }
  });
}

// function to get unique value from array
export function getUnique<T>(arr: T[], key: keyof T): T[] {
  const seen = new Set();
  return arr.filter((item) => {
    if (seen.has(item)) {
      return false;
      // Skip the duplicate   
    }
    seen.add(item);
    return true; // Keep the item 
  });
}


// Helper function to convert Year-Week to Date
export function yearWeekToDate(yearWeek: string): Date {
  const year = parseInt(yearWeek.slice(0, 4), 10);  // Extract year
  const week = parseInt(yearWeek.slice(4, 6), 10);  // Extract week number

  const date = new Date(year, 0, 1);  // Start at the first day of the year
  const days = (week - 1) * 7;  // Calculate days to add based on the week number
  date.setDate(date.getDate() + days);

  // Adjust to get the first Monday of the week (week starts on Monday)
  const dayOfWeek = date.getDay();
  const adjustDays = dayOfWeek <= 1 ? 0 : 1;  // If Sunday, adjust to Monday
  date.setDate(date.getDate() - dayOfWeek + adjustDays);

  return date;
}

