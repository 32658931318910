import { Injectable } from '@angular/core';
import { EnvironmentConfiguration } from 'src/app/environment/services/environment.configuration';

import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable,of } from 'rxjs';
import { catchError } from 'rxjs/operators';


@Injectable({
  providedIn: 'root',
})
export class ProdprogressService {
  getHttpCustomOptions() {
    return {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Accept: 'application/json',
        authorization: localStorage.getItem('Token'),
        'X-Api-Key': this.environment.prodProgressKey,
      }),
    };
  }

  prodProgressApiUrl = '';

  constructor(
    private http: HttpClient,
    private environment: EnvironmentConfiguration
  ) {
    this.prodProgressApiUrl = environment.prodProgressUrl;
  }


  getDock(data): Observable<any> {
    return this.http.post<any>(
      `${this.prodProgressApiUrl}/getDock`,
      data,
      this.getHttpCustomOptions()).pipe(catchError(error => of({ error })))
    
  }
  getProdProgressData(data): Observable<any> {
    return this.http.post<any>(
      `${this.prodProgressApiUrl}/getProgressData`,
      data,
      this.getHttpCustomOptions()).pipe(catchError(error => of({ error })))
  }

  editCumulPlanNet(data): Observable<any> {
    return this.http.put<any>(
      `${this.prodProgressApiUrl}/editCumalPlanNet`,
      data,
      this.getHttpCustomOptions()).pipe(catchError(error => of({ error })))
  }
  IntplanVolUpdate(data): Observable<any> {
    return this.http.put<any>(
      `${this.prodProgressApiUrl}/editintplanVol`,
      data,
      this.getHttpCustomOptions()).pipe(catchError(error => of({ error })))
  }
  setIsRecalculate(data): Observable<any> {
    return this.http.put<any>(
      `${this.prodProgressApiUrl}/isRecalculate`,
      data,
      this.getHttpCustomOptions()).pipe(catchError(error => of({ error })))
  }

  RecalculatePP(data): Observable<any> {
    return this.http.put<any>(
      `${this.prodProgressApiUrl}/recalculatepp`,
      data,
      this.getHttpCustomOptions()).pipe(catchError(error => of({ error })))
  }






}
