import { Component, OnInit, Inject, EventEmitter, Output, ViewChild, ElementRef, AfterViewInit } from '@angular/core';
import { FormArray, UntypedFormBuilder, UntypedFormGroup, Validators, AbstractControl, ValidationErrors } from '@angular/forms';
import {
  MatLegacyDialogRef as MatDialogRef,
  MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA,
} from '@angular/material/legacy-dialog';
import { log } from 'util';
import { AirfreightArrivedCheckboxComponent } from '../airfreight-arrived-checkbox/airfreight-arrived-checkbox.component';
import { AirorderNewrowComponent } from './airorder-newrow/airorder-newrow.component';
import { CustomTooltipComponent } from 'src/app/core/custom-tooltip/custom-tooltip.component';
import * as moment from 'moment';
import { GriddataService } from 'src/app/services/griddata.service';
import { GridVanningVolEditorComponent } from 'src/app/core/grid/grid-vanvolum-edit.component';
import { ActualArrivalEditableComponent } from './actual-arrival-editable/actual-arrival-editable.component';
import { roundUp } from 'src/app/constants';
import { EtaDateEditableComponent } from './actual-arrival-editable/eta-date-editable.component';
import { NoOfPiecesEditableComponent } from './actual-arrival-editable/no-of-pieces-editable.component';
import { ConfEditableComponent } from './actual-arrival-editable/conf-editable.component';
import { DeadlineEditableComponent } from './actual-arrival-editable/deadline-editable.component';
import { EtaTimeEditableComponent } from './actual-arrival-editable/eta-time-editable.component';
import { CaseNoEditableComponent } from './actual-arrival-editable/case-no-editable.component';
import { Location } from '@angular/common';
import { Part } from 'src/app/shared/part-model';
import { AirfreightApicallsService } from 'src/app/services/air-freight-validation/airfreight-apicalls.service';
import { LookupService } from 'src/app/services/lookup/lookup.service';
import { Subscription, filter, forkJoin } from 'rxjs';
import { logErrors } from 'src/app/shared/logger';
import { NgxSpinnerService } from 'ngx-spinner';
import * as mm from 'moment-timezone';
import { Router } from '@angular/router';
import { ValidationService } from 'src/app/services/air-freight-validation/validations.service';
import { CustomHeaderComponent } from '../custom-header/custom-header.component';
import { DataServiceService } from 'src/app/services/air-freight-validation/data-service.service';
import { ScheduleDeleteComponent } from '../schedule-delete/schedule-delete.component';
import {
  MatLegacyDialog as MatDialog,
  MatLegacyDialogConfig as MatDialogConfig,
} from '@angular/material/legacy-dialog';
import { AirorderDeleteComponent } from '../delete-airorder/airorder-delete.component';
import { ConfigParameterComponent } from './config-parameter/config-parameter.component';
import { element } from 'protractor';
import { ConfLotsEditableComponent } from './actual-arrival-editable/conf-lots-editable.component';
import { NoOfLotsEditableComponent } from './actual-arrival-editable/no-of-lots.editable.component';
import { Dropdown } from 'primeng/dropdown';
import { OSPStateService } from 'src/app/services/osp-state.service';
import { Payload, createPayload } from './save-airfright-payload.interface';
import { formatOrderData } from './formatEditData';
import { AnyGridOptions } from 'ag-grid-community';
import { AnyNaptrRecord } from 'dns';
import { tickFormat } from 'ag-charts-community/dist/cjs/es5/sparklines-util';

@Component({
  selector: 'app-add-edit-air-record',
  templateUrl: './add-edit-air-record.component.html',
  styleUrls: ['./add-edit-air-record.component.scss'],
})
export class AddEditAirRecordComponent implements OnInit, AfterViewInit {
  editEventEmitter = new EventEmitter();
  addMasterEventEmitter = new EventEmitter();
  editMasterEventEmitter = new EventEmitter();
  @ViewChild('formTop', { static: false }) formTop!: ElementRef;
  @ViewChild('dockNoDropdown') dockNoDropdown!: Dropdown;
  @ViewChild('kanbanNoInput') kanbanNoInput!: ElementRef;
  @ViewChild('piecesToOrderInput') piecesToOrderInput!: ElementRef;
  @ViewChild('aircaseInput') aircaseInput!: ElementRef;
  @Output() viewCartClicked: EventEmitter<void> = new EventEmitter<void>();
  @Output() orderAdded = new EventEmitter<any>();
  master_id: any;
  master_name: any;
  key_item: any;
  data_item: any;
  gridOptions = {} as any;
  isLotsSelected: boolean = true;
  business_entity: any;
  new_key_item: any;
  cstatus: any;
  showaddpopup: boolean = false;
  showeditpopup: boolean = false;
  isExpandedIndex: number | null = null; // Track the index of the currently expanded panel
  columnDefs: any[] = [];
  rowData;
  context: any;
  popupname: any;
  masterId: any;
  masterName: any;
  part_type: any;
  dataItem: any;
  kanbanList = [];
  partList = [];
  kanbanNo: any;
  enableError: boolean = false;
  userForm: UntypedFormGroup;
  warningMessage: string[] = [];
  successMessage = '';
  minDate: String;
  estimatedCost: any;
  boxWeight: any;
  piecesToOrder: any;
  lotsToOrder: any;
  easternCurrentDate = String(mm().tz('US/Michigan').format('YYYY-MM-DD')).substring(0, 10);
  addingParts = [];
  addOrEditform2: FormArray;
  adjustSubscription$: Subscription;
  parts: Part[] = []; // Declare and initialize here
  isExpanded: boolean[] = []; // Declare and initialize here
  todaysDate = String(mm().tz('US/Michigan').format('MM/DD/YYYY')).substring(0, 10);
  enableview: boolean = false;
  frameworkComponents = {
    customTooltip: CustomTooltipComponent,
    buttonRenderer: AirorderNewrowComponent,
  };
  @ViewChild('osPartsGrid') osPartsGrid;
  toOrder: any;
  addAirOrderPart: any = [];
  addAirOrderKanban: any = [];
  addAirOrderDestcode: any = [];
  addAirOrderDock: any = [];
  getAircodeForSelectedDock: any = [];
  namcValue: string;
  user: string;
  userRole: any;
  params: any;
  userName: string;
  filteredData = [];
  selectedDestCode: any;
  defaultOrderType: any;
  addAirReason: any = [];
  fb: any;
  totalParts: number;
  addBudgetCodes: any = [];
  managerDetails: any = [];
  kvcDetails: any = [];
  addAirCaseCode: any = [];
  // addOrEditform: FormArray;
  qpcforParts: any = [];
  schedules: any;
  manager_filter_id: any;
  kvc_filter_id: any;
  costperKilo: any = [];
  globalQpc: any;
  calendarList: [] = null;
  // new variables 
  all_dock: [];
  // all_dock : [];
  //checked
  constructor(
    private readonly airFreightService: AirfreightApicallsService,
    private readonly lookupservice: LookupService,
    private readonly spinner: NgxSpinnerService,
    private router: Router,
    private orderService: ValidationService,
    public dialog: MatDialog,
    private readonly stateService: OSPStateService,
    private readonly formBuilder: UntypedFormBuilder,
    private readonly gridDataService: GriddataService,
    private location: Location,
    private dataService: DataServiceService
  ) {
    const suppressTabEvent = (params) => {
      const event = params.event;
      if (event.key === 'Tab') {
        const focusableElements = params.node.gridOptionsWrapper.gridOptions.api.getCellRendererInstances({ rowNodes: [params.node] });
        if (focusableElements.length > 0) {
          focusableElements[0].getGui().querySelector('input').focus();
          return true; // Suppress default tab behavior
        }
      }
      return false;
    };
    this.context = {
      componentParent: this,
    };
    this.columnDefs = [
      {
        headerName: '', // New column for actions (Delete button)
        field: 'partIndex',
        cellRendererFramework: ScheduleDeleteComponent, // Renders the delete button
        width: 70, // Adjust the width as needed
        sortable: false,
        context: { componentParent: this },// Pass the parent component context
      },
      {
        headerComponentFramework: CustomHeaderComponent,
        headerName: 'Arrival DT',
        field: 'arrivalDate',
        sortable: false,
        flex: 1,
        cellRendererParams: { column: 'arriving_date' },
        cellRendererFramework: ActualArrivalEditableComponent,
        suppressKeyboardEvent: suppressTabEvent
      },
      {
        headerComponentFramework: CustomHeaderComponent,
        headerName: 'Deadline DT',
        field: 'deadlineDate',
        sortable: false,
        flex: 1,
        cellRendererFramework: DeadlineEditableComponent,
        cellRendererParams: { column: 'deadline_dates' },
        suppressKeyboardEvent: suppressTabEvent
      },
      {
        headerComponentFramework: CustomHeaderComponent,
        headerName: '#of Pieces',
        field: 'noOfPieces',
        sortable: false,
        flex: 1,
        cellRendererFramework: NoOfPiecesEditableComponent,
        cellRendererParams: { column: 'number_of_pieces' },
        hide: this.isLotsSelected,
        suppressKeyboardEvent: suppressTabEvent
      },
      {
        headerComponentFramework: CustomHeaderComponent,
        headerName: '#of Lots',
        field: 'noOfLots',
        sortable: false,
        flex: 1,
        cellRendererFramework: NoOfLotsEditableComponent,
        cellRendererParams: { column: 'number_of_lots' },
        hide: !this.isLotsSelected,
        suppressKeyboardEvent: suppressTabEvent
      },
      {
        headerName: 'Conf Lots',
        field: 'configureLots',
        sortable: false,
        flex: 1,
        cellRendererFramework: ConfLotsEditableComponent,
        cellRendererParams: { column: 'conf_lots' },
        hide: !this.isLotsSelected,
        suppressKeyboardEvent: suppressTabEvent
      },
      {
        headerName: 'Conf Pieces',
        field: 'configurePieces',
        flex: 1,
        // tooltipField: 'vessel_name',
        sortable: false,
        cellRendererFramework: ConfEditableComponent,
        cellRendererParams: { column: 'conf_pieces' },
        hide: this.isLotsSelected,
        suppressKeyboardEvent: suppressTabEvent
      },

      {
        // headerComponentFramework: CustomHeaderComponent,
        headerName: 'Van Center ETA DT',
        field: 'etaDate',
        sortable: false,
        flex: 1,
        tooltipField: 'container_number',
        cellRendererFramework: EtaDateEditableComponent,
        cellRendererParams: { column: 'eta_date' },
        suppressKeyboardEvent: suppressTabEvent
      },

      {
        headerName: 'Renban',
        field: 'casenoEditable',
        sortable: false,
        flex: 1,
        cellRendererFramework: CaseNoEditableComponent,
        cellRendererParams: { column: 'case_no' },
        suppressKeyboardEvent: suppressTabEvent
      },
      {
        headerName: 'Arrived?',
        field: 'arrivedChecked',
        flex: 1,
        tooltipField: 'Arrived?',
        cellRendererFramework: AirfreightArrivedCheckboxComponent,
        cellRendererParams: { column: 'isArrived' },
        sortable: false,
        suppressKeyboardEvent: suppressTabEvent
      },
      //AirfreightArrivedCheckboxComponent
    ];


    this.gridOptions = this.gridDataService.getGridOptions();
    this.gridOptions.onGridReady = function (params) {
      this.gridApi = params.api;
    };

    this.getGridOptions();
  }
  ngAfterViewInit(): void {
    this.isExpanded[0] = true;
  }
  // get addOrEdit() {
  //   return this.addOrEditform.controls;
  // }
  access;
  isFirstLoad = true;
  ngOnDestroy(): void {
    this.adjustSubscription$.unsubscribe();
  }
  // variables used for edit order detailes only
  orderDetailsForEdit: any = null;
  for_edit = false;
  for_add_part = false;
  partdata = null
  ngOnInit(): void {
    this.dataService.filteredData$.subscribe((data: any) => {
      if (data) {
        if (data.forEdit) {
          this.for_edit = true;
          this.orderDetailsForEdit = data.data;
        } else if (data.forAddPart != undefined) {
          this.for_add_part = true;
          this.partdata = {
            partNo: data.forAddPart,
            dock: data.dock
          }
        }
      }
    });
    localStorage.setItem('isClearMsg', 'false');

    this.todaysDate = String(mm().tz('US/Michigan').format('YYYY-MM-DD')).substring(0, 10);
    //this.todaysDate='2025-01-31'

    this.minDate = new Date(this.todaysDate).toISOString().split('T')[0];

    this.business_entity = localStorage.getItem('namcvalue');

    if (localStorage.getItem('UserName')) {
      this.userName = localStorage.getItem('UserName');
    } else {
      this.userName = 'Test User';
    }
    this.addOrEditform.get('orderDetails.orderDate').setValue(this.todaysDate);
    this.addOrEditform.get('orderDetails.raisedBy').setValue(this.userName);

    this.toOrder = [
      { order_type: 'Pieces' },
      { order_type: 'Lots' }
    ];
    // Set the default value to "Lots"
    this.defaultOrderType = this.toOrder.find((item) => item.order_type === 'Lots');
    if (this.defaultOrderType) {
      this.addOrEditform.get('orderDetails.orderType').setValue(this.defaultOrderType);
    }
    this.loadDrop();

    this.adjustSubscription$ = this.stateService.getNamc().subscribe((observable) => {
      if (!this.isFirstLoad) {
        this.router.navigate(['ordering/airfrorderTypeeightorder']);
      }
      this.isFirstLoad = false; // Set to false after the first load
    });
  }
  async loadDrop() {
    this.spinner.show();
    this.namcValue = localStorage.getItem('namcvalue');
    if (localStorage.getItem('workdayId')) {
      this.user = localStorage.getItem('workdayId');
    }
    if (localStorage.getItem('UserRoles')) {
      this.userRole = localStorage.getItem('UserRoles');
    }
    await this.callApiForAdd();
  }

  // calling all apis needed to create air order record
  fill_row_data_for_part(part_index) {
    // this.addAirOrderPart[part_index] = this.temp_addAirOrderPart;
    // this.addAirOrderKanban[part_index] = this.temp_addAirOrderKanban;
    this.addAirOrderDock[part_index] = this.temp_addAirOrderDock;
    // this.addAirCaseCode[part_index] = this.temp_addAirCaseCode;
    // const firstPart = this.obj_parts.at(part_index) as UntypedFormGroup;
    // firstPart.patchValue({ reason: this.addAirReason[0] });
    this.addAirOrderPart[part_index] = [];
    this.addAirOrderKanban[part_index] = [];
    this.addAirCaseCode[part_index] = [];

    const part = this.obj_partsByIndex(part_index);
    part.get('cubicDimension').disable();
    if (this.addAirReason.length > 0)
      part.get('reason').setValue(this.addAirReason[0]);
    if (this.addAirOrderDestcode.length > 0)
      part.get('destCode').setValue(this.addAirOrderDestcode[0]);

  }

  temp_addAirOrderPart: [];
  temp_addAirOrderKanban: [];
  temp_addAirOrderDock: [];
  temp_addAirCaseCode: [];


  async callApiForAdd() {
    const addApiSubcriptions = [
      this.lookupservice.getPartNumber(),
      this.lookupservice.getKanban(),
      this.lookupservice.getDestCode(),
      this.lookupservice.getDock(),
      this.airFreightService.getReason(),
      this.airFreightService.getBudgetCodesDefault(),
      this.airFreightService.getManagerDetails(),
      this.airFreightService.getKvcDetails(),
      this.airFreightService.getAirCaseCodes(),
      this.lookupservice.getqpcforpart(),
      this.airFreightService.getAirCostData(),
      this.airFreightService.getCalendar()
    ];

    forkJoin(addApiSubcriptions).subscribe({
      error: this.errorCallback,
      next: (res) => {
        // get part no
        if (!res[0].error)
          this.temp_addAirOrderPart = res[0].body.data;
        else
          this.warningMessage.push("Try Again! Unable to get part No.");

        // get Kanban
        if (!res[1].error)
          this.temp_addAirOrderKanban = res[1].body.data;
        else
          this.warningMessage.push("Try Again! Unable to get kanban");

        // get getDestCode
        if (!res[2].error)
          this.addAirOrderDestcode.push(...res[2].body.data);
        else
          this.warningMessage.push("Try Again! Unable to get DEST Code");

        // get getDock
        if (!res[3].error)
          this.temp_addAirOrderDock = res[3].body.data;
        else
          this.warningMessage.push("Try Again! Unable to get DOCK");

        // get getReason
        if (!res[4].error) {
          this.addAirReason.push(...res[4].body.data);
          // if (this.addAirReason.length > 0)             
          //   this.addOrEditform.get('orderDetails.reason').setValue(this.addAirReason[0]);
        }
        else
          this.warningMessage.push("Try Again! Unable to get Reasons");

        // get getBudgetCodesDefault
        if (!res[5].error) {
          this.addBudgetCodes.push(...res[5].body.data);
          // const partNoList = this.addBudgetCodes.filter((element) => element.is_default === true);
          this.addOrEditform.get('orderDetails.budgetCodes').setValue(this.addBudgetCodes.filter((x) => { return x.is_default == true })[0]);
        }
        else
          this.warningMessage.push("Try Again! Unable to get Budget code");

        // get getManagerDetails
        if (!res[6].error) {
          this.managerDetails.push(...res[6].body.data);
          this.addOrEditform.get('orderDetails.managerName').setValue(this.managerDetails.filter((x) => { return x.is_default == true })[0]);
        } else
          this.warningMessage.push("Try Again! Unable to get Manager details");

        //getKvcDetails
        if (!res[7].error) {
          this.kvcDetails.push(...res[7].body.data);
          this.addOrEditform.get('orderDetails.KvcName').setValue(this.kvcDetails.filter((x) => { return x.is_default == true })[0]);
        } else
          this.warningMessage.push("Try Again! Unable to get KVC details");

        //getAirCaseCodes
        if (!res[8].error) {
          this.temp_addAirCaseCode = res[8].body.data;
          this.getAircodeForSelectedDock = this.temp_addAirCaseCode;
        }
        else
          this.warningMessage.push("Try Again! Unable to get Air case code");

        //getqpcforpart
        if (!res[9].error)
          this.qpcforParts.push(...res[9].body.data);
        else
          this.warningMessage.push("Try Again! Unable to get QPC details");
        //getAirCostData
        if (!res[10].error)
          this.costperKilo.push(...res[10].body.data);
        else
          this.warningMessage.push("Try Again! Unable to get Air cost details");
        //getAirCostData
        if (!res[11].error)
          this.calendarList = res[11].body.data;
        else
          this.warningMessage.push("Try Again! Unable to get Calender details");

        // this.addAirOrderDock = this.addAirOrderDock.filter(
        //   (value, index, self) => index === self.findIndex((t) => t.dock === value.dock)
        // );


        // this.addAirCaseCode = this.addAirCaseCode.filter(
        //   (value, index, self) => index === self.findIndex((t) => t.air_case_code === value.air_case_code)
        // );

        // if (this.addAirOrderDestcode.length === 1) {
        //   this.selectedDestCode = this.addAirOrderDestcode[0];
        //   this.addOrEditform.get('orderDetails.destCode').setValue(this.selectedDestCode);
        // }

        this.fill_row_data_for_part(0);
        this.spinner.hide();
      },
      complete: () => {
        this.toOrder = [{ order_type: 'Lots' }, { order_type: 'Pieces' }];
        if (this.for_edit)
          this.appendOrderDetails();
        else if (this.for_add_part) {
          this.appendPartDetails();
        }
        // else
        //   this.router.navigate(['ordering/airfreightorder']);
      }
    });
    // this.toOrder = [{ order_type: 'Lots' }, { order_type: 'Pieces' }];
  }
  resetWorning() {
    this.warningMessage = [];
    this.payload = null;
    this.submitted = false;
  }


  // saveUser() { }

  submitted: boolean = false;
  // checked
  scrollToTop() {
    if (this.formTop) {
      this.formTop.nativeElement.scrollIntoView({ behavior: 'smooth' });
    }
  }
  // checked
  removeMessage() {
    this.resetWorning();
    this.successMessage = '';
  }
  //checked
  removeParts(index: number) {
    if (this.obj_parts.length == 1) {
      this.resetWorning();
      this.warningMessage.push('Air order having only one part, cannot be deleted.');
      return; // Exit the function without deleting
    } else {
      const dialogConfig = new MatDialogConfig();
      dialogConfig.disableClose = true;
      dialogConfig.id = 'modal-component';
      dialogConfig.height = '204px';
      dialogConfig.width = '470px';
      dialogConfig.data = [{ isParts: true }];
      const dialogRef = this.dialog.open(AirorderDeleteComponent, dialogConfig);
      dialogRef.afterClosed().subscribe((res) => {
        if (res === 'cancel') return;
        else if (res === 'save') this.obj_parts.removeAt(index);
      });
    }

  }
  // checked
  getGridOptions() {
    this.gridOptions = {} as any;

    this.gridOptions.headerHeight = 38;

    this.gridOptions.rowHeight = 28;
    this.gridOptions.floatingFiltersHeight = 0;

    this.gridOptions.rowBuffer = 20;
    this.gridOptions.suppressMovableColumns = true;

    this.gridOptions.enableCellTextSelection = true;
    this.gridOptions.suppressContextMenu = false;
    this.gridOptions.suppressClipboardPaste = true;

    this.gridOptions.defaultColDef = {
      sortable: true,
      suppressMenu: true,
      filter: false,
      floatingFilter: false,

      wrapText: true,
      autoHeight: true,

      suppressPaste: false,

      floatingFilterComponentParams: { suppressFilterButton: true },
      unSortIcon: true,
      icons: {
        sortUnSort: '<img src="../../../assets/images/Rectangle8.svg">',
      },
      filterParams: {
        textCustomComparator(filter, value, filterText) {
          const filterTextLoweCase = filterText.toLowerCase();

          let valueLowerCase = '';
          if (value.params) {
            valueLowerCase = value.value.toString().toLowerCase();
          } else {
            valueLowerCase = value.toString().toLowerCase();
          }
          function contains(target, lookingFor) {
            if (target === null) {
              return false;
            }
            return target.indexOf(lookingFor) >= 0;
          }
          return contains(valueLowerCase, filterTextLoweCase);
        },

        // Debounce time - Delay before Floating filter search
        debounceMs: 1000,

        caseSensitive: true,
        suppressAndOrCondition: true,
        inRangeInclusive: true,
      },
    };

    return this.gridOptions;
  }

  // Method to handle the dropdown's change event
  // checked
  onLotsPiecesChange(event: any): void {
    this.isLotsSelected = event.value?.order_type === 'Lots';
    let parts: FormArray = this.obj_parts;
    for (let index = 0; index < parts.length; index++) {
      const part = this.obj_partsByIndex(index);
      part.get('cubicDimension').reset();
      part.get('boxWeight').reset();
      part.get('estimatedCost').reset();
      part.get('lotsToOrder').reset();
      part.get('piecesToOrder').reset();
      part.get('totalOrder').reset();
      let scheduleArray = part.get('obj_schedules') as FormArray;
      for (let s_index = 0; s_index < scheduleArray.length; s_index++) {
        let schedule = scheduleArray.at(s_index) as UntypedFormGroup;
        schedule.get('number_of_pieces').reset();
        schedule.get('noOfPieces').reset();
        schedule.get('noOfLots').reset();
        schedule.get('number_of_lots').reset();
        schedule.get('configurePieces').reset();
        schedule.get('conf_pieces').reset();
        schedule.get('configureLots').reset();
        schedule.get('conf_lots').reset();
      }
    }
    this.updateColumnVisibility();
  }
  // checked
  updateColumnVisibility() {
    this.columnDefs = this.columnDefs.map((colDef) => {
      if (colDef.field === 'noOfLots') {
        return {
          ...colDef,
          hide: !this.isLotsSelected, // Show this column if isLotsSelected is true
        };
      }
      if (colDef.field === 'configureLots') {
        return {
          ...colDef,
          hide: !this.isLotsSelected, // Hide this column if isLotsSelected is true
        };
      }
      if (colDef.field === 'noOfPieces') {
        return {
          ...colDef,
          hide: this.isLotsSelected, // Hide this column if isLotsSelected is true
        };
      }
      if (colDef.field === 'configurePieces') {
        return {
          ...colDef,
          hide: this.isLotsSelected, // Hide this column if isLotsSelected is true
        };
      }
      return colDef; // Keep other columns unchanged
    });




    // var updatedCF = [];
    // this.columnDefs.forEach((colDef) => {
    //   if (colDef.field === 'noOfLots') {
    //     colDef.hide = !this.isLotsSelected; // Show this column if isLotsSelected is true
    //   } if (colDef.field === 'configureLots') {
    //     colDef.hide = !this.isLotsSelected; // Hide this column if isLotsSelected is true
    //   } if (colDef.field === 'noOfPieces') {
    //     colDef.hide = this.isLotsSelected; // Hide this column if isLotsSelected is true
    //   } if (colDef.field === 'configurePieces') {
    //     colDef.hide = this.isLotsSelected; // Hide this column if isLotsSelected is true
    //   }
    //   updatedCF.push(colDef);
    // });
    // this.columnDefs = updatedCF;

  }
  // checked
  handleKeydown(inputType: string, event, part_index: number): void {
    //this.allowOnlyNumbers(event);
    // this.updateSchedulecount(part_index);
    if (inputType === 'input1') {
      this.calculateTotalOrder(part_index);
      this.calculateEstimationCost(part_index);
    } else {
      this.calculateEstimationCost(part_index);
      this.calculateTotalOrder(part_index);
    }
    this.validatecountMatch(part_index);
  }
  // checked
  calculateTotalOrder(part_index: number) {
    // pieces== no of pieces%qpc
    // lots= no of lots%qpc
    const part = this.obj_partsByIndex(part_index);
    const qpc_orderlot = part.get('qpcBox').value;
    const piecesToOrder = part.get('piecesToOrder').value;
    const lotsToOrder = part.get('lotsToOrder').value;

    let pieces = lotsToOrder * qpc_orderlot;
    let lots = piecesToOrder / qpc_orderlot;

    // If either qpc_orderlot or piecesToOrder is missing, clear totalOrder
    if (!this.isLotsSelected) {
      if (!piecesToOrder) {
        part.get('totalOrder').setValue('');
      } else {
        const calculateValue = piecesToOrder;
        part.get('totalOrder').setValue(calculateValue);
      }
    } else {
      if (!lotsToOrder && !qpc_orderlot) {
        part.get('totalOrder').setValue('');
      } else {
        const calculateValue = lotsToOrder * qpc_orderlot;
        part.get('totalOrder').setValue(calculateValue);
      }
    }
  }

  // checked
  calculateEstimationCost(part_index: number) {
    const part = this.obj_partsByIndex(part_index);

    // same for complete NAMC
    let cost_per_kilo = 0;
    let boxWeight = 0;
    const piecesToOrder = Number(part.get('piecesToOrder').value);
    const qpc_orderlot = Number(part.get('qpcBox').value);
    const lotsToOrder = part.get('lotsToOrder').value;

    const _costCalculation = part.get('costCalculation').value;
    if (_costCalculation === 'Weight') {
      cost_per_kilo = Number(this.costperKilo[0].cost_per_kilo);
      boxWeight = Number(part.get('boxWeight').value);
    } else {
      cost_per_kilo = Number(this.costperKilo[0].cost_per_cubic);
      boxWeight = Number(part.get('cubicDimension').value);
    }

    if (!this.isLotsSelected) {
      if (!boxWeight && !piecesToOrder && !qpc_orderlot && !cost_per_kilo) {
        part.get('estimatedCost').setValue('');
      } else {
        const calculateCost = (piecesToOrder / qpc_orderlot) * boxWeight * cost_per_kilo;
        part.get('estimatedCost').setValue(roundUp(calculateCost, 2));
      }
    } else {
      //est cost (when weight) = no of lots * weight * cost per kilo
      if (!boxWeight && !this.lotsToOrder && !cost_per_kilo) {
        part.get('estimatedCost').setValue('');
      } else {
        const calculateCost = lotsToOrder * boxWeight * cost_per_kilo;
        part.get('estimatedCost').setValue(roundUp(calculateCost, 2));
      }
    }
  }
  handleCostCalculationChange(part_index, event: Event): void {
    const part = this.obj_partsByIndex(part_index);

    // same for complete NAMC
    // let cost_per_kilo = 0;
    // let boxWeight = 0;
    // const piecesToOrder = Number(part.get('piecesToOrder').value);
    // const qpc_orderlot = Number(part.get('qpcBox').value);
    // const lotsToOrder = part.get('lotsToOrder').value;


    const inputElement = event.target as HTMLInputElement;
    const selectedValue = inputElement.value;
    if (selectedValue == "Weight") {
      part.get('cubicDimension').disable();
      part.get('boxWeight').enable();
    } else if (selectedValue == "Volume") {
      part.get('boxWeight').disable();
      part.get('cubicDimension').enable();
    }
    part.get('cubicDimension').reset();
    part.get('boxWeight').reset();
    part.get('estimatedCost').reset();
  }
  partTypeChange() {
    let parts: FormArray = this.obj_parts;
    for (let index = 0; index < parts.length; index++) {
      const part = this.obj_partsByIndex(index);
      part.get('partNo').reset();
      part.get('kanbanNo').reset();
    }
  }
  goBack() {
    localStorage.setItem('isClearMsg', 'true');
    this.location.back();
  }
  onCancel() {
    this.goBack();
  }
  onKanbanChange(part_index): void {
    // get part details  by index
    const part = this.obj_partsByIndex(part_index);
    const dock = part.get('dockNo').value?.dock;
    const kanban = part.get('kanbanNo').value?.kanban;
    // part.get('partNo').disable();
    const item = this.qpcforParts.find((entry) => entry.dock === dock && entry.kanban === kanban);
    if (item) {
      part.get('partNo').setValue({ item_id: item.item_id });
      // part.get('partNo').setValue(item.item_id);
      part.get('part_description').setValue(item.part_description);
      part.get('qpcBox').setValue(item.order_lot);
    }

    // Your logic to update the form values based on kanban and dock selection
    // const item = this.qpcforParts.find((entry) => entry.kanban === kanban);
    // if (item) {
    //   part.get('partNo').setValue({ item_id: item.item_id });
    //   part.get('part_description').setValue(item.part_description);
    // }

    // if (dock && kanban) {
    //   const itemWithDock = this.qpcforParts.find((entry) => entry.dock === dock && entry.kanban === kanban);
    //   if (itemWithDock) {
    //     part.get('qpcBox').setValue(itemWithDock.order_lot);
    //   }
    // } else {
    //   const partNoList = this.qpcforParts.filter((element) => element.kanban === kanban);
    //   this.addAirOrderDock[part_index] = partNoList;
    //   this.addAirOrderDock[part_index] = this.addAirOrderDock[part_index].filter((value, index, self) => index === self.findIndex((t) => t.dock === value.dock)
    //   );
    // }
    this.handleKeydown('input1', 'event', part_index);
  }

  onPartNoChange(part_index): void {
    const part = this.obj_partsByIndex(part_index);
    const part_no = part.get('partNo').value?.item_id;
    const dock = part.get('dockNo').value?.dock;
    // part.get('kanbanNo').disable();
    const item = this.qpcforParts.find((entry) => entry.dock === dock && entry.item_id === part_no);
    if (item) {
      part.get('kanbanNo').setValue({ kanban: item.kanban });
      part.get('part_description').setValue(item.part_description);
      part.get('qpcBox').setValue(item.order_lot);
    }

    // if (part_no && !dock) {

    // }

    // if (dock && part_no) {
    //   const itemWithDock = this.qpcforParts.find((entry) => entry.dock === dock && entry.item_id === part_no);
    //   if (itemWithDock) {

    //   }
    // } else {
    //   const partNoList = this.qpcforParts.filter((element) => element.item_id === part_no);
    //   this.addAirOrderDock[part_index] = partNoList;
    //   this.addAirOrderDock[part_index] = this.addAirOrderDock[part_index].filter(
    //     (value, index, self) => index === self.findIndex((t) => t.dock === value.dock)
    //   );
    // }
    this.handleKeydown('input1', 'event', part_index);
  }

  async onDockChange(part_index) {
    const part = this.obj_partsByIndex(part_index);
    const dock = part.get('dockNo').value?.dock;
    // const part_no = part.get('partNo').value?.item_id;
    // const kanban = part.get('kanbanNo').value?.kanban;
    // part.get('kanbanNo').enable();
    // part.get('partNo').enable();
    if (dock) {
      // reset arrival date vancenter date and deadline date for non working day check
      let scheduleArray = part.get('obj_schedules') as FormArray;
      for (let s_index = 0; s_index < scheduleArray.length; s_index++) {
        let schedule = scheduleArray.at(s_index) as UntypedFormGroup;
        schedule.get('deadlineDate').reset();
        schedule.get('etaDate').reset();
        schedule.get('arrivalDate').reset();
      }

      let defaultAirCode = [];
      this.getAircodeForSelectedDock.forEach((item) => {
        if (String(item.dock) === String(dock)) {
          defaultAirCode.push(item);
        }
      });
      // this.addAirCaseCode[part_index] = [];  // I commented out this line due to a sonar issue.
      this.addAirCaseCode[part_index] = defaultAirCode;
      const defaultItem = this.addAirCaseCode[part_index].find((item) => item.is_default === true);
      if (defaultItem) {
        this.addAirCaseCode[part_index] = this.addAirCaseCode[part_index].filter((item) => item.is_default !== true);
        this.addAirCaseCode[part_index].unshift(defaultItem);
        part.get('airCode').setValue(this.addAirCaseCode[part_index][0]);
      }

      // Reset kanban and part dropdowns and reload options
      const partNoList = this.qpcforParts.filter((element) => element.dock === dock);

      // Populate new kanban options
      const newkanban = partNoList.map((item) => ({ kanban: item.kanban })).sort((a, b) => parseInt(a.kanban) - parseInt(b.kanban));

      // Populate new part options
      const newPartNoList = partNoList.map((item) => ({ item_id: item.item_id })).sort((a, b) => parseInt(a.item_id) - parseInt(b.item_id));
      this.addAirOrderKanban[part_index] = [];
      this.addAirOrderPart[part_index] = [];
      // Update dropdown options
      this.addAirOrderKanban[part_index] = newkanban;
      this.addAirOrderPart[part_index] = newPartNoList;

      // Reset the selected value in the kanban and partNo form controls
      part.get('kanbanNo').reset();
      part.get('partNo').reset();
      part.get('qpcBox').reset();
      part.get('part_description').reset();
      part.get('kanbanNo').enable();
      part.get('partNo').enable();
    }

    // if (dock && part_no) {
    //   const item = this.qpcforParts.find((entry) => entry.dock === dock && entry.item_id === part_no);
    //   if (item) {
    //     part.get('qpcBox').setValue(item.order_lot);
    //     // this.addOrEditform.controls.qpcBox.setValue(item.order_lot);
    //   }
    // } else if (dock && kanban) {
    //   const item = this.qpcforParts.find((entry) => entry.dock === dock && entry.kanban === kanban);
    //   if (item) {
    //     part.get('qpcBox').setValue(item.order_lot);
    //     // this.addOrEditform.controls.qpcBox.setValue(item.order_lot);
    //   }
    // } else {
    //   const partNoList = this.qpcforParts.filter((element) => element.dock === dock);

    //   const newPartNoList = partNoList.map((item) => ({ item_id: item.item_id })).sort((a, b) => parseInt(a.item_id) - parseInt(b.item_id));
    //   const newkanban = partNoList.map((item) => ({ kanban: item.kanban })).sort((a, b) => parseInt(a.kanban) - parseInt(b.kanban));
    //   this.addAirOrderKanban[part_index] = newkanban;
    //   this.addAirOrderPart[part_index] = newPartNoList;
    // }

    this.handleKeydown('input1', 'event', part_index);
  }

  preventKeyPress(event: KeyboardEvent): void {
    event.preventDefault(); // Prevent any key press
  }

  requiredSchedules(getSchedules) {
    if (getSchedules.length > 0) {
      // Check if any schedule is missing required fields
      const missingField = getSchedules.some((schedule) => !schedule.arriving_date || !schedule.deadline_dates);

      if (missingField) {
        this.resetWorning();
        this.warningMessage.push('At least 1 arrival schedule must be filled in.');
        this.scrollToTop();
        return false; // Return false to indicate validation failed
      }

      // Proceed with mapping the schedules if all fields are present
      this.schedules = getSchedules.map((schedule) => ({
        arriving_date: schedule.arriving_date,
        eta_date: schedule.eta_date,
        case_no: schedule.case_no,
        deadline_date: schedule.deadline_dates,
        number_of_pieces: schedule.number_of_pieces,
        number_of_lots: schedule.number_of_lots,
        conf_lots: schedule.conf_lots,
        conf_pieces: schedule.conf_pieces,
        air_order_status: 'CREATED',
      }));
      return true; // Validation passed
    } else {
      this.resetWorning();
      this.warningMessage.push('At least 1 arrival schedule must be filled in.');
      this.scrollToTop();
      return false; // No schedules added, validation failed
    }
  }

  checkIfPartExist(newpart) {
    // Check if the part already exists by comparing part_num
    const partExists = this.parts.some(
      (part) => part.part_num === newpart.part_num && part !== newpart // Ensure it's not the same part being updated
    );
    if (partExists) {
      // If the part exists, show a warning and do not add a new part
      this.resetWorning();
      this.warningMessage.push('This part number already exists. Please check the part numbers.');
      this.scrollToTop();
      return true; // Stop further execution and return true
    }
    return false; // No duplicate, safe to add the new part
  }
  getMangerIdByNameAndRole() {
    const manager_id = this.addOrEditform.get('orderDetails.managerName').value?.contact_name || '';
    const role = this.managerDetails.filter((role) => role.contact_name === manager_id);
    this.manager_filter_id = role[0]?.contact_id;
    return role;
  }
  getKvcIDdByNameAndRole() {
    const kvc_id = this.addOrEditform.get('orderDetails.KvcName').value?.contact_name || '';
    const role = this.kvcDetails.filter((role) => role.contact_name === kvc_id);
    this.kvc_filter_id = role[0]?.contact_id;
    return role;
  }

  empty_valueCheck(value: any) {
    if (Number.isNaN(value) || value == undefined || value == '' || value == null) return true;
    return false;
  }

  // checked
  onKeyPress(event: KeyboardEvent): void {
    const allowedKeys = ['Backspace', 'Tab', 'ArrowLeft', 'ArrowRight', 'Delete'];
    const isNumber = /^[0-9]$/.test(event.key);

    // Allow number keys, allowed keys, and prevent default for others
    if (!isNumber && !allowedKeys.includes(event.key)) {
      event.preventDefault();
    }
  }
  // checked
  onKeyPressDecimalAllow(event: KeyboardEvent, part_index) {
    const allowedKeys = ['Backspace', 'Tab', 'ArrowLeft', 'ArrowRight', 'Delete'];

    // Access the value of boxWeight directly from the form control
    const part = this.obj_partsByIndex(part_index);

    const inputValue = part.get('boxWeight').value;

    // const inputValue = this.addOrEdit.boxWeight.value;

    const isNumber = /^[0-9]$/.test(event.key);
    const isDecimalPoint = event.key === '.';

    // Allow number keys and allowed keys
    if (isNumber || allowedKeys.includes(event.key)) {
      return;
    }

    if (isDecimalPoint) {
      // Allow only one decimal point
      if (inputValue.includes('.')) {
        event.preventDefault();
      }
      return;
    }

    // Allow numbers only up to two decimal places
    const [integerPart, decimalPart] = inputValue.split('.');

    // Simplified logic: prevent default if there are already two decimal places
    if (decimalPart && decimalPart.length >= 2) {
      event.preventDefault();
    }
  }

  formatDate(date) {
    return date && date !== '' && moment(date).isValid() ? moment(date).format('MM/DD/YYYY') : null;
  }

  //----------------------------------- updated code-------------------------------------------
  getCurrentDateFormatted(): string {
    const currentDate = new Date();
    const year = currentDate.getFullYear();
    const month = String(currentDate.getMonth() + 1).padStart(2, '0'); // Months are zero-based
    const day = String(currentDate.getDate()).padStart(2, '0');
    return `${year}-${month}-${day}`;
  };
  addOrEditform: UntypedFormGroup = this.formBuilder.group({
    orderDetails: this.formBuilder.group({
      air_order_no: [null],
      orderType: [null, Validators.required],
      partType: ['Production'],
      managerName: [null],
      KvcName: [null],
      budgetCodes: [null],
      orderDate: [null, Validators.required],
      raisedBy: [null],
      responsibilityregion: ['Plant']
    }),
    obj_parts: this.formBuilder.array([this.createParts()])
  });
  get obj_parts(): FormArray {
    return this.addOrEditform ? this.addOrEditform.get('obj_parts') as FormArray : null;
  }
  allowonlytwodecimalandnumber(event: KeyboardEvent): void {
    const allowedKeys = ['Backspace', 'Tab', 'ArrowLeft', 'ArrowRight', 'Delete'];
    const inputElement = event.target as HTMLInputElement;
    const inputValue = inputElement.value;
    const isNumber = /^[0-9]$/.test(event.key);
    const isDecimalPoint = event.key === '.';
    if (isNumber || allowedKeys.includes(event.key)) {
      return;
    }
    if (isDecimalPoint) {
      // Allow only one decimal point
      if (inputValue.includes('.')) {
        event.preventDefault();
      }
      return;
    }
    event.preventDefault();
  }
  obj_partsByIndex(part_index): UntypedFormGroup {
    let partsArray = this.addOrEditform.get('obj_parts') as FormArray;

    return partsArray.at(parseInt(part_index)) as UntypedFormGroup;
    // const dock = part.get('dockNo').value?.dock;
  }

  addParts() {
    this.obj_parts.push(this.createParts());
    this.fill_row_data_for_part(this.obj_parts.length - 1);
  }
  createParts(): UntypedFormGroup {
    return this.formBuilder.group({
      // row no 1
      dockNo: [null, Validators.required],
      airCode: [null, Validators.required],
      destCode: [null, Validators.required],
      qpcBox: [null, Validators.required],
      // row no 2
      kanbanNo: [{ value: null, disabled: false }, Validators.required],
      partNo: [{ value: null, disabled: false }, Validators.required],
      part_description: [null],
      pSETAInfo: ['Long'],
      // row no 3
      piecesToOrder: [null],
      lotsToOrder: [null],
      costCalculation: ['Weight'],
      totalOrder: [null],
      partArrangement: ['ADD'],
      // row no 4
      cubicDimension: [null],
      boxWeight: [null],
      estimatedCost: [null],
      volumesize: ['Additional Parts'],
      // row no 5
      reason: [null, Validators.required],
      secondComment: [null],
      firstComment: [null],
      vanConfirmation: ['No'],
      // row no 7
      obj_schedules: this.formBuilder.array([this.createArrivalDate(this.obj_parts ? this.obj_parts.length : 0)]),
    });
  }
  createArrivalDate(part_index): UntypedFormGroup {
    return this.formBuilder.group({
      partIndex: [part_index],
      arrivalDate: [null],
      arriving_date: [null],
      deadlineDate: [null],
      deadline_dates: [null],
      noOfPieces: [null],
      number_of_pieces: [null],
      noOfLots: [null],
      number_of_lots: [null],
      configurePieces: [null],
      conf_pieces: [null],
      configureLots: [null],
      conf_lots: [null],
      etaDate: [null],
      eta_date: [null],
      casenoEditable: [''],
      case_no: [null],
      schedule_error: [false],
      arrivedChecked: [false],
      isArrived: [false],
      sched_id: [null]
    });
  }
  // return this.addOrEditform.get('obj_parts') as FormArray;
  addArrival_schedules(partIndex: number) {
    // let partsArray = this.obj_parts;
    // let part = partsArray.at(partIndex) as UntypedFormGroup;
    let part = this.obj_partsByIndex(partIndex);
    let nestedArray = part.get('obj_schedules') as FormArray;
    nestedArray.push(this.createArrivalDate(partIndex));
  }
  deleteArrival_schedules(partIndex: number, nestedIndex: number) {
    if (partIndex == null && nestedIndex === 0) {
      this.resetWorning();
      this.warningMessage.push(`Part having only one Schedule, cannot be deleted.`);
      this.scrollToTop();
      return; // Exit the function without deleting
    } else {
      const dialogConfig = new MatDialogConfig();
      dialogConfig.disableClose = true;
      dialogConfig.id = 'modal-component';
      dialogConfig.height = '204px';
      dialogConfig.width = '470px';
      dialogConfig.data = [{ isSchedule: true }];

      const dialogRef = this.dialog.open(AirorderDeleteComponent, dialogConfig);

      dialogRef.afterClosed().subscribe((res) => {
        if (res === 'cancel') {
          return;
        } else if (res === 'save') {
          const partsArray = this.obj_parts;
          const part = partsArray.at(partIndex) as UntypedFormGroup;
          const nestedArray = part.get('obj_schedules') as FormArray;
          nestedArray.removeAt(nestedIndex);
        }
      });
    }
  }
  payload: any = null;
  sumerror: boolean = false;
  countMatch: boolean[] = [];
  isSubmitting = false;
  async onSubmit() {
    this.isSubmitting = true;
    this.resetWorning();
    this.submitted = true;
    let t_payload: any = await createPayload(this.addOrEditform.value, this.addOrEditform.getRawValue(), this.easternCurrentDate, this.business_entity, this.userName, this.user, this.userRole, this.calendarList);
    console.log(t_payload);
    this.payload = t_payload;
    t_payload.payloadArray.forEach((x, index) => {
      this.countMatch[index] = x.isCountMatched;
    });
    // console.log(t_payload);
    if (t_payload.errors.length > 0) {
      this.warningMessage = this.payload.errors;
      this.scrollToTop();
      this.isSubmitting = false;
      return false;
    } else {
      let validForm = true;
      t_payload.payloadArray.forEach((x, index) => {
        if (x.warnings.length > 0) {
          this.countMatch[index] = x.isCountMatched;
          this.scrollToTop();
          validForm = false;
        }
      });
      if (validForm && !this.for_edit) {
        this.airFreightService.addAirOrderDetails(t_payload.payloadArray).subscribe({
          error: (error) => {
            logErrors(error);
            this.spinner.hide();
            this.resetWorning();
            this.warningMessage.push('Try again! Failed to add Air Freight Order');
          },
          next: (responseData) => {
            if (responseData) {
              this.spinner.hide();
              this.orderService.setOrderData(responseData?.body?.addResponse?.updatedRows?.data);
              localStorage.setItem('isAdd', 'true');
              this.router.navigate(['ordering/airfreightorder']);
            }
          },
        });
      } else if (validForm && this.for_edit) {
        this.airFreightService.editAirOrderDetails(t_payload.payloadArray).subscribe({
          error: (error) => {
            logErrors(error);
            this.spinner.hide();
            this.resetWorning();
            this.warningMessage.push('Try again! Failed to Update Air Freight Order');
          },
          next: (responseData) => {
            if (responseData) {
              this.orderService.setEditedData(responseData);
              localStorage.setItem('isAdd', 'false');
              this.router.navigate(['ordering/airfreightorder']);
            }
          },
        });
      } else {
        this.scrollToTop();
      }
      this.spinner.hide();
      this.isSubmitting = false;
      // else {
      //   this.warningMessage.push('form is not valid');
      //   this.scrollToTop();
      //   return false;
      // }
      // alert(validForm);
    }

  }
  errorCallback = (error) => {
    logErrors(error);
    this.spinner.hide();
    this.resetWorning()
    this.warningMessage.push('Something went wrong! Please contact support team.');
  };
  async appendOrderDetails() {
    if (this.orderDetailsForEdit != null) {
      this.orderDetailsForEdit = await formatOrderData(this.orderDetailsForEdit);
      // console.log(this.orderDetailsForEdit);
      let OD = this.orderDetailsForEdit.orderDetails;
      // append order details data
      let temp_OD = this.addOrEditform.get('orderDetails') as UntypedFormGroup;
      // aditional settings
      if (OD.orderType == 'Lots') {
        this.isLotsSelected = true;
        this.updateColumnVisibility();
      } else {
        this.isLotsSelected = false;
        this.updateColumnVisibility();
      }
      // console.log(this.addBudgetCodes);
      // console.log(OD.budgetCodes);
      // console.log(this.addBudgetCodes.filter((x) => { return x.budget_code == OD.budgetCodes })[0]);
      temp_OD.patchValue({
        orderType: { order_type: OD.orderType },
        partType: OD.partType,
        budgetCodes: this.addBudgetCodes.filter((x) => { return x.budget_code == OD.budgetCodes })[0],
        KvcName: this.kvcDetails.filter((item) => { return item.contact_name.trim() === OD.kvcName })[0],
        responsibilityregion: OD.responsibilityregion,
        raisedBy: OD.raisedBy,
        orderDate: moment(OD.orderDate).format('YYYY-MM-DD'),
        managerName: this.managerDetails.filter((x) => { return x.contact_name == OD.managerName })[0],
        air_order_no: OD.air_order_no,
      });
      this.getMangerIdByNameAndRole();
      this.getKvcIDdByNameAndRole();
      temp_OD.get('orderType').disable();
      temp_OD.get('raisedBy').disable();
      temp_OD.get('orderDate').disable();
      temp_OD.get('partType').disable();

      this.orderDetailsForEdit.order_parts.forEach(async (x, index) => {
        if (index != 0) {
          this.addParts();
        }
        const part = this.obj_parts.at(index) as UntypedFormGroup;
        part.patchValue({ dockNo: { dock: x.dockNo } });
        await this.onDockChange(index);
        // this.partTypeChange();
        part.patchValue({
          destCode: { dest_code: x.destCode }, // Same for dockNo
          part_description: x.part_description,
          airCode: this.addAirCaseCode[index].filter((item) => { return item.air_case_code == x.airCode })[0],
          piecesToOrder: x.piecesToOrder,
          lotsToOrder: x.lotsToOrder,
          qpcBox: x.qpcBox,
          totalOrder: x.totalOrder,
          cubicDimension: x.cubicDimension,
          boxWeight: x.boxWeight,
          costCalculation: x.costCalculation,
          estimatedCost: x.estimatedCost,
          vanConfirmation: x.vanConfirmation,
          firstComment: x.firstComment,
          volumesize: x.volumesize,
          secondComment: x.secondComment,
          reason: { reason_code: x.reason },
        });
        if (OD.partType == 'Production') {
          part.patchValue({
            partNo: { 'item_id': x.partNo },
            kanbanNo: { 'kanban': x.kanbanNo }
          });
        } else {
          part.patchValue({
            partNo: x.partNo,
            kanbanNo: x.kanbanNo
          });
        }
        part.get('destCode').disable();
        part.get('dockNo').disable();
        part.get('partNo').disable();
        part.get('kanbanNo').disable();
        // part.get('orderDate').disable();
        let scheduleArray = part.get('obj_schedules') as FormArray;
        x.schedules.forEach((s, s_index) => {
          if (s_index != 0) {
            this.addArrival_schedules(index);
          }
          let schedule = scheduleArray.at(s_index) as UntypedFormGroup;
          schedule.patchValue({
            partIndex: index,
            arrivalDate: moment(s.arrivalDate).format('YYYY-MM-DD'),
            arriving_date: moment(s.arrivalDate).format('YYYY-MM-DD'),
            deadlineDate: moment(s.deadlineDate).format('YYYY-MM-DD'),
            deadline_dates: moment(s.deadlineDate).format('YYYY-MM-DD'),
            noOfPieces: s.noOfPieces,
            number_of_pieces: s.noOfPieces,
            noOfLots: s.noOfLots,
            number_of_lots: s.noOfLots,
            configurePieces: s.configurePieces,
            conf_pieces: s.configurePieces,
            configureLots: s.configureLots,
            conf_lots: s.configureLots,
            // etaDate: moment(s.etaDate).format('YYYY-MM-DD'),
            eta_date: s.etaDate ? moment(s.etaDate).format('YYYY-MM-DD') : null,
            casenoEditable: s.casenoEditable,
            case_no: s.casenoEditable,
            arrivedChecked: s.orderstatus,
            // ArrivedChecked:x.orderstatus,
            isArrived: s.orderstatus,
            sched_id: s.sched_id
          });
          // schedule.get('isArrived').setValue(s.orderstatus);
        });
      });

    }
  }
  checkNonworkingday(part_index, selected_date): any {
    const part = this.obj_partsByIndex(part_index);
    let selected_dock = part.get('dockNo').value;
    if (!selected_dock)
      return { "check": '1', "msg": "Please select DOCK first" };
    const filteredList = this.calendarList.filter((item: any) => item.dock == selected_dock.dock);
    const filteredData: any = filteredList.filter(
      (item: any) => new Date(item.act_prod_date.split('T')[0]).getTime() == new Date(selected_date).getTime()
    );
    if (filteredData.length == 0 || filteredData[0]?.work_status == '0')
      return { "check": '2', "msg": "Selected date is non working day" };
    else
      return null;
  }
  configparamcall() {
    // alert("calling");
  }
  /** 
  cost
      -- cost per killo
      -- cost per cubic
  budject code -- done
  AIR case code -- done
  AIR fright contact
  Receiving ETA time
   */



  ConfigureParameter() {
    const modalComponent = 'modal-component';
    localStorage.setItem('isBudgetCodeSetToDefault', 'false');
    localStorage.setItem('isAirCaseCodeSetToDefault', 'false');
    localStorage.setItem('isContactadded', 'false');
    localStorage.setItem('iscostUpdate', 'false');
    localStorage.setItem('isAirCaseCodeSetToDefault_payload', '');

    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.id = modalComponent;
    dialogConfig.maxHeight = '550px';
    dialogConfig.width = '850px';
    const dialogRef = this.dialog.open(ConfigParameterComponent, dialogConfig);
    dialogRef.beforeClosed().subscribe((res) => {
      if (res === 'cancel') {
        let isBudgetCodeSetToDefault = localStorage.getItem('isBudgetCodeSetToDefault');
        let isAirCaseCodeSetToDefault = localStorage.getItem('isAirCaseCodeSetToDefault');
        let isContactadded = localStorage.getItem('isContactadded');
        let isDefaultContact = localStorage.getItem('isMrgSetToDefault');
        let updated_dock = localStorage.getItem('isAirCaseCodeSetToDefault_payload');
        let iscostUpdate = localStorage.getItem('iscostUpdate');
        if (iscostUpdate === 'true') {
          this.spinner.show();
          this.airFreightService.getAirCostData().subscribe({
            error: (error) => {
              logErrors(error);
              this.spinner.hide();
              this.resetWorning()
              this.warningMessage.push('Try again! Error in getting Updated COST please connect Support team');
            },
            next: (data) => {
              if (!data.error) {
                if (data.body) {
                  this.costperKilo = [];
                  this.costperKilo.push(data.body.data[0]);
                  let parts: FormArray = this.obj_parts;
                  for (let index = 0; index < parts.length; index++) {
                    this.calculateEstimationCost(index);
                  }
                  this.spinner.hide();
                  window.scroll(0, 0);
                } else {
                  this.spinner.hide();
                }
              } else
                this.warningMessage.push('Try again! Error in getting Updated COST please connect Support team');
            },
          });
        }
        if (isBudgetCodeSetToDefault === 'true') {
          this.spinner.show();
          this.airFreightService.getBudgetCodesDefault().subscribe({
            error: (error) => {
              logErrors(error);
              this.spinner.hide();
              this.resetWorning()
              this.warningMessage.push('Try again! Error in getting Updated Budget code please connect Support team');
            },
            next: (data) => {
              if (!data.error) {
                if (data.body) {
                  this.addBudgetCodes = [];
                  this.addBudgetCodes.push(...data.body.data);
                  let a = data.body.data.find((item) => item.is_default === true);
                  // set budget code after Update default budget code
                  this.addOrEditform.get('orderDetails.budgetCodes').setValue(a);
                  this.spinner.hide();
                  window.scroll(0, 0);
                } else {
                  this.spinner.hide();
                }
              } else {
                this.warningMessage.push('Try again! Error in getting Updated Budget code please connect Support team');
              }
            },
          });
        }
        if (isAirCaseCodeSetToDefault === 'true') {
          this.spinner.show();
          this.airFreightService.getAirCaseCodes().subscribe({
            error: (error) => {
              logErrors(error);
              this.spinner.hide();
              this.resetWorning()
              this.warningMessage.push('Try again! Error in getting updated AIR CASE CODE please connect Support team');
            },
            next: (data) => {
              if (!data.error) {
                if (data.body) {
                  this.getAircodeForSelectedDock = data.body.data;
                  let updated_docks_temp: string[] = updated_dock.split('-');
                  let parts: FormArray = this.obj_parts;
                  for (let index = 0; index < parts.length; index++) {
                    const part = this.obj_partsByIndex(index);
                    const dock_temp = part.get('dockNo').value?.dock;
                    if (updated_docks_temp.includes(dock_temp)) {
                      let defaultAirCode = [];
                      this.getAircodeForSelectedDock.forEach((item) => {
                        if (String(item.dock) === String(dock_temp)) {
                          defaultAirCode.push(item);
                        }
                      });
                      this.addAirCaseCode[index] = defaultAirCode;
                      const defaultItem = this.addAirCaseCode[index].find((item) => item.is_default === true);
                      if (defaultItem) {
                        this.addAirCaseCode[index] = this.addAirCaseCode[index].filter((item) => item.is_default !== true);
                        this.addAirCaseCode[index].unshift(defaultItem);
                        part.get('airCode').setValue(this.addAirCaseCode[index][0]);
                      }
                    }
                  }
                  this.spinner.hide();
                  window.scroll(0, 0);
                } else {
                  this.spinner.hide();
                }
              } else {
                this.warningMessage.push('Try again! Error in getting updated AIR CASE CODE please connect Support team');

              }
            },
          });
        }
        if (isContactadded === 'true' || isDefaultContact === 'true') {
          this.spinner.show();
          this.airFreightService.getKvcDetails().subscribe({
            error: (error) => {
              logErrors(error);
              this.spinner.hide();
              this.resetWorning()
              this.warningMessage.push('Try again! Error in getting updated contacts please connect Support team');
            },
            next: (data) => {
              if (!data.error) {
                if (data.body) {
                  this.kvcDetails = [];
                  this.kvcDetails.push(...data.body.data);
                  if (isDefaultContact === 'true') {
                    let a = data.body.data.find((item) => item.is_default === true);
                    if (a) {
                      this.addOrEditform.get('orderDetails.KvcName').setValue(a);
                      // this.addOrEditform.controls.KvcName.setValue(a);
                    }
                  }
                  this.spinner.hide();
                  window.scroll(0, 0);
                } else {
                  this.spinner.hide();
                }
              } else {
                this.warningMessage.push('Try again! Error in getting updated contacts please connect Support team');
              }
            },
          });
          this.airFreightService.getManagerDetails().subscribe({
            error: (error) => {
              logErrors(error);
              this.spinner.hide();
              this.resetWorning()
              this.warningMessage.push('Try again! Error in getting updated Managers please connect Support team');
            },
            next: (data) => {
              if (!data.error)
                if (data.body) {
                  this.managerDetails = [];
                  this.managerDetails.push(...data.body.data);
                  if (isDefaultContact === 'true') {
                    let a = data.body.data.find((item) => item.is_default === true);

                    if (a) {
                      // this.addOrEditform.controls.managerName.setValue(a);
                      this.addOrEditform.get('orderDetails.managerName').setValue(a);
                    }
                  }
                  this.spinner.hide();
                  window.scroll(0, 0);
                } else
                  this.spinner.hide();
              else
                this.warningMessage.push('Try again! Error in getting updated Managers please connect Support team');
            },
          });
        }

        // this.callApiForAdd();
        this.columnDefs[7].cellStyle = function (params) {
          if ('modified' in params.data) {
            return { backgroundColor: '#E4ECF0' };
          } else {
            return { backgroundColor: 'white' };
          }
        };
      }
    });
  }
  async appendPartDetails() {
    if (this.partdata != null) {
      const part = this.obj_parts.at(0) as UntypedFormGroup;
      part.patchValue({ dockNo: { dock: this.partdata.dock } });
      await this.onDockChange(0);
      part.patchValue({ partNo: { 'item_id': this.partdata.partNo } });
      await this.onPartNoChange(0);
    }
  }
  validatecountMatch(part_index):void {
    if (this.submitted) {
      const part = this.obj_partsByIndex(part_index);
      let value = 0;
      if (this.isLotsSelected) {
        value = part.get('lotsToOrder').value;
      } else {
        value = part.get('piecesToOrder').value;
      }
      let schedulecount = 0;
      let scheduleArray = part.get('obj_schedules') as FormArray;
      scheduleArray.value.forEach(element => {
        if (this.isLotsSelected) {
          schedulecount = schedulecount + parseInt(element.number_of_lots+'');
        } else {
          schedulecount = schedulecount + parseInt(element.number_of_pieces+'');
        }
      });
      console.log(parseInt(value+'') === schedulecount,parseInt(value+''), schedulecount);
      this.countMatch[part_index] = parseInt(value+'') === schedulecount;
    }
  }
  changeVancenterETA(part_index):void{
    const part = this.obj_partsByIndex(part_index);
    let scheduleArray = part.get('obj_schedules') as FormArray;
    let checked = false;
    scheduleArray.value.forEach(element => {
      if(!checked && element.isArrived){
        part.get('vanConfirmation').setValue("Yes");
        checked = true;
      }
    });
    if(!checked)
      part.get('vanConfirmation').setValue("No");
  }
}