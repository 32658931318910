import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { EnvironmentConfiguration } from 'src/app/environment/services/environment.configuration';

@Injectable({
  providedIn: 'root',
})
export class ContainerOrderingService {
  getToken = () => localStorage.getItem('Token');
  appJson = 'application/json';

  getBusinessEntity = () => localStorage.getItem('namcvalue');

  getBusinessEntityName = () => localStorage.getItem('namcName');

  getUserRole = () => localStorage.getItem('UserRoles');

  getUserUPN = () => localStorage.getItem('UPN');

  getWorkID = () => localStorage.getItem('workdayId');

  getUserName = () => localStorage.getItem('UserName');
  
  getApiHeaders = () => {
    return new HttpHeaders({
      'Content-Type': 'application/json',
      Accept: 'application/json',
        Authorization: this.getToken(),
      // 'X-Api-Key': this.environment.moduleStockingKey     
    });
  };

  httpCustomOptions = () => {
    return {
      headers: new HttpHeaders({
        'Content-Type': this.appJson,
        Accept: this.appJson,
        Authorization: this.getToken(),
         'X-Api-Key': this.environment.moduleStockingKey
      }),
    };
  };

  httpCustomOptions1 = () => {
    return {
      params: {
        business_entity: this.getBusinessEntity(),
        user_role: this.getUserRole(),
        workid: this.getWorkID(),
      },
      headers: this.getApiHeaders(),
    };
  };

  apiUrl = '';

  constructor(private http: HttpClient, private environment: EnvironmentConfiguration) {
    this.apiUrl = environment.moduleStockingUrl;
    //this.apiUrl = 'http://localhost:3000/api/v1/inv-module-stocking';
  }
  //app;

  getCOFilter(data): Observable<any> {
    return this.http.post<any>(
      `${this.apiUrl}/cofilter`,
      data,
      this.httpCustomOptions()
    );
  }

  getcontainerOrdering(data): Observable<any> {
    return this.http.post<any>(
      `${this.apiUrl}/getcontorder`,
      data,
      this.httpCustomOptions()
    );
  }

  // getModules(data): Observable<any> {
  //   const url = `${this.apiUrl}/modulelist?business_entity=${data.business_entity}&workid=${data.workid}&user_role=${data.user_role}&container_renban=${data.container_renban}&dock=${data.dock}`;
  //   return this.http.get<any>(url, this.httpCustomOptions());
  // }

  getModules(data): Observable<any> {
    return this.http.post<any>(
      `${this.apiUrl}/modulelist`,
      data,
      this.httpCustomOptions()
    );
  }

  editContainerOrdering(data): Observable<any> {
    return this.http.put<any>(
      `${this.apiUrl}/editcontorder`,
      data,
      this.httpCustomOptions()
    );
  }

  // deleteContainerOrdering(data): Observable<any> {
  //   const url = `${this.apiUrl}/contorder?business_entity=${data.business_entity}&workid=${data.workid}&user_role=${data.user_role}&container_renban=${data.container_renban}&dock=${data.dock}`;
  //   return this.http.delete<any>(url, this.httpCustomOptions());
  // }

  deleteContainerOrdering(data): Observable<any> {
    return this.http.post<any>(
      `${this.apiUrl}/delcontorder`,
      data,
      this.httpCustomOptions()
    );
  }

}
