import { Component, Input } from '@angular/core';
import * as mm from 'moment-timezone';

@Component({
  selector: 'app-calender-grid-checkbox',
  template: `
    <!-- <label *ngIf="showInput" style="color:#2688DA"> &#10004; </label>
    <mat-checkbox
      [disabled]="disable"
      (change)="checkCheckBoxvalue($event)"
      [(ngModel)]="value"
    ></mat-checkbox> -->
    <div class="checkbox-container">
      <label *ngIf="showInput" class="checkmark-label">&#10004;</label>
      <mat-checkbox
        [disabled]="disable"
        (change)="checkCheckBoxvalue($event)"
        [(ngModel)]="value"
      ></mat-checkbox>
    </div>
  `,
  styles: [
    `
    .checkbox-container {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 100%;
      }
      .checkmark-label {
        color: #2688da;
        margin-right: 5px;
      }
      ::ng-deep .mat-checkbox .mat-checkbox-frame {
        border: 1px solid #c5c5c5 !important;
      }
      ::ng-deep .mat-checkbox-disabled .mat-checkbox-background {
        background-color: #d3d3d3;
      }
    `,
  ],
})
export class ModuleRowCheckboxComponent {
  @Input() cell: any;

  showInput: boolean;
  value = '';
  isEmpty = true;
  showCheckBox = true;
  editable: boolean;
  disable: boolean = false;

  public params: any;

  agInit(params: any): void {
    //console.log('moduletype params', params);

    // Handle 'moduleToDock' column logic
    if (params.column === 'moduleToDock') {
      this.showCheckBox = true;
      //console.log('moduletype params', params);

      // Set the checkbox value based on 'is_module_to_dock'
      //this.value = params.data.is_module_to_dock; // true or false

      this.value = params.data.is_module_to_dock; 
      if (params.data.logic === "1" ){
        this.disable = !params.data.is_module_to_dock;         
      }else if (params.data.logic === "2"){            
            this.disable = params.data.is_module_to_dock;
      }else{
        //should be bad data
        this.disable = false;
      }

    }

    // Handle 'conModule' column logic specifically
    if (params.column === 'conModule') {
      this.showCheckBox = true;
      //console.log('conModule params', params);

      // Set the checkbox value based on 'is_controlling_module'
      this.value = params.data.is_controlling_module; // true or false
      // Optionally disable the checkbox if necessary
      // this.disable = params.data.disabled.is_controlling_module;
      if (params.data.logic === "1" ){
        this.disable = !params.data.is_module_to_dock;         
      }else if (params.data.logic === "2"){            
            this.disable = params.data.is_module_to_dock;
      }else{
        //should be bad data
        this.disable = false;
      }
    }

    // Add additional logic for other columns as needed

    this.params = params;

    // Conditional checkbox visibility based on 'checkedEditMode'
    if (this.params.data.checkedEditMode) {
      this.showCheckBox = true;
      this.showInput = false;
    } else {
      this.showCheckBox = false;
    }

    // Disable the checkbox if the date condition is met
    if (
      this.params.data.checkedEditMode &&
      new Date(this.params.data.date) <= new Date(String(mm().tz('US/Michigan').format('MM/DD/YYYY')).substring(0, 10))
    ) {
      this.disable = true;
    }
  }

  constructor() {}

  checkCheckBoxvalue(event) {
    // Handle changes for 'conModule' column specifically
    if (this.params.column === 'conModule') {
      this.params.data.is_controlling_module = this.value; // Update the data based on the checkbox value

    }else if (this.params.column === 'moduleToDock'){
      this.params.data.is_module_to_dock = this.value;
    }else{
      //do nothing
    }
    // Optionally handle changes for other columns

    // Notify parent component about changes
    this.params.context.componentParent.editModuleTypeCheck(this.params);
  }
}
