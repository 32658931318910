<div class="background">
  <div class="successMessage alert-dismissible">

    <div class="successMessage alert-dismissible">
      <div *ngIf="warningMessage" class="alert alert-warning" role="alert">
        {{ warningMessage }}
        <button type="button" class="btn-close" (click)="removeMessage()" data-bs-dismiss="alert"
          aria-label="Close"></button>
      </div>

      <div *ngIf="successMessage" class="alert alert-success" role="alert">
        {{ successMessage }}
        <button type="button" class="btn-close" (click)="removeMessage()" data-bs-dismiss="alert"
          aria-label="Close"></button>
      </div>
    </div>
  </div>
  <div class="container-fluid">
    <div class="d-flex flex-row">
      <div class="p-2 flex-grow-1">
        <h2 class="heading">Module Stocking</h2>
      </div>
    </div>
  </div>
  <div class="container-fluid">
    <div class="row">
      <div class="card">
        <div class="container-fluid">
          <div class="row">
            <div class="col-xl-1 col-md-2 col-sm-3">
              <div class="drpchildctrl">
                <span class="v1"></span>
                <label class="searchLable">DOCK</label>
                <p-multiSelect [(ngModel)]="selectedDock" [showToggleAll]="false" (onChange)="dockChange()"
                  [options]="dockList" [showTransitionOptions]="'0ms'" [hideTransitionOptions]="'0ms'"
                  [defaultLabel]="'--Select--'" [virtualScroll]="true" [itemSize]="25" autoWidth="false"
                  [style]="{ width: '120%' }" [resetFilterOnHide]="true" optionLabel="dock"
                  [panelStyle]="{ width: '140%' }">
                </p-multiSelect>
              </div>
            </div>

            <div class="col-xl-1 col-md-2 col-sm-3">
              <div class="drpchildctrl">
                <label class="searchLable1">STOCKING DT FROM</label>
                <input [(ngModel)]="stockingDtFrom" class="form-control-sm " type="date" [max]="stockingDtTo"
                  autoWidth="false" />
              </div>
            </div>
            <div class="col-xl-1 col-md-2 col-sm-3">
              <div class="drpchildctrl">
                <label class="searchLable1" style="margin-left: 13px;">STOCKING DT TO</label>
                <input type="date" [(ngModel)]="stockingDtTo" class="form-control-sm" [min]="stockingDtFrom"
                  autoWidth="false" style="margin-left: 13px;" />

              </div>
            </div>
            <div class="col-xl-1 col-md-2 col-sm-3 reset_btn">
              <label class="resetIcon" (click)="onResetDropDown()">
                <img src="./assets/images/Group 115380.png" alt="refresh icon" />
              </label>
              <label class="resetlabel" (click)="onResetDropDown()">Reset</label>
              <span class="vertical"></span>
            </div>
            <div class="col-xl-1 col-md-2 col-sm-3 search">
              <button class="search_btn" (click)="onSearch(0)">
                SEARCH
              </button>
            </div>

          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="container-fluid">
    <div class="row">
      <div class="col-6">
        <img class="img-tool" (click)="exportAsXLSX()" src="./assets/images/download.svg" alt="download icon"
          title="Download" />
      </div>
      <div class="col-1">
        <button class="stock_btn" *ngIf="!isAllSelect && access2" (click)="stockUnstockAll(true)">STOCK ALL</button>
      </div>

      <div class="col-1">
        <button class="stock_btn" *ngIf="!isAllSelect && access2" (click)="stockUnstockAll(false)">UNSTOCK
          ALL</button>
      </div>
      <div class="col-2">
        <div class="records">
          <div class="drpchildctrl">
            <p-multiSelect #dd class="page-drpdwn-label showhide" [(ngModel)]="selectedShowHideList"
              (onChange)="onShowHideChange($event)" [options]="showHideList" optionLabel="label"
              [displaySelectedLabel]="false" [defaultLabel]="'SHOW/HIDE COLUMN'" [showTransitionOptions]="'0ms'"
              [hideTransitionOptions]="'0ms'" autoWidth="true" [style]="{ width: '100%', background: '#F5F5F5' }"
              [resetFilterOnHide]="true">
              <p-footer>
                <div class="flex justify-content-between p-1">
                  <button type="button" (click)="saveShowHide();dd.hide()" class="save_btn"> Save</button>

                </div>
              </p-footer>
            </p-multiSelect>
          </div>
        </div>
      </div>


      <div class="col-2">
        <div class="records">
          <label class="page-drpdwn-label">RECORDS / PAGE:</label>
          <select class="page-drpdwn" (change)="onPageSizeChanged()" [(ngModel)]="paginationPageSize">
            <option value="10" selected>10</option>
            <option value="30">30</option>
            <option value="50">50</option>
            <option value="{{ rowData.length }}">All</option>
          </select>
        </div>
      </div>
    </div>
  </div>


  <div id="dvgrid" class="gridStyle">
    <app-grid class="table-outer" #osPartsGrid [columnDefs]="columnDefs" [rowData]="rowData"
      [rowSelection]="rowSelection" [gridOptions]="gridOptions" [pagination]="pagination"
      [paginationPageSize]="paginationPageSize" [context]="context" [suppressClickEdit]="suppressClickEdit"
      [frameworkComponents]="frameworkComponents" [tooltipShowDelay]="tooltipShowDelay" [tooltipMouseTrack]="true"
      [editType]="editType" (selectionChanged)="onSelectionChanged()">
    </app-grid>
  </div>
</div>

<ngx-spinner bdColor="rgba(51,51,51,0.8)" size="medium" color="#fff" type="ball-scale-multiple">
  <p style="font-size: 20px; color: white">Loading... Please wait...</p>
</ngx-spinner>