import { Component, OnInit } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import * as moment from 'moment';

@Component({
  selector: 'app-devanbuttonrender',
  template: `
  <span  *ngIf="isPastDate && access" class="edit_icon">
  <img class="imgTool"  (click)="Recalculate('value')"
  src="./assets/images/calculator.svg" title="Recalculate"
  alt="edit button icon"
  >
</span>

  `,
  styles: [
    `
    .imgTool{
    border: 1px solid #d1d1d1;
    background: transparent linear-gradient(180deg, #ffffff 0%, #e4ecf0 100%) 0% 0% no-repeat padding-box;
    cursor: pointer;
    width: 30px;
    height: 30px;
    margin-right: 8px;
}
    `,
  ],
})
export class recalculateComponent implements ICellRendererAngularComp {
  params;
  isPastDate:boolean ;
  reportdate = new Date();
  access;


  agInit(params): void {
    this.params = params;
    // this.label="DEVAN";
    this.access = JSON.parse(localStorage.getItem('prodProgress'));

    console.log("this.reportdate",this.reportdate)
    let date2= moment(this.reportdate).format('MM/DD/YYYY');
    console.log("date",date2)


    this.params = params;

    let date = moment(this.reportdate).format('MM/DD/YYYY');  // Today's date formatted as 'MM/DD/YYYY'
    console.log("Today's Date:", date);
    
    // Assuming 'this.params.data.actual_date' is also a date in 'MM/DD/YYYY' format
    let actualDate = moment(this.params.data.actual_date, 'MM/DD/YYYY'); // Convert actual_date to moment object
    
    // Compare the two dates
    if (actualDate.isBefore(moment(date, 'MM/DD/YYYY'), 'day') && !this.params.data.checkedEditMode 
   ) {
        this.isPastDate = true; // If actual_date is before today's date, set isPastDate to true
    } else  if (actualDate.isSame(moment(), 'day') && this.params.data.actual_shift === '1' && !this.params.data.checkedEditMode ){
        this.isPastDate = true; // Otherwise, set it to false (future or today's date)
    }else{
      this.isPastDate = false;

    }
    
    console.log("isPastDate:", this.isPastDate);
    

  

  }

  refresh(params?: any): boolean {
    return true;
  }


  Recalculate(value) {
    console.log("editdata")
    this.params.context.componentParent.Recalculate(
      this.params.data
    );

  }
}
