import { Component } from '@angular/core';
import * as moment from 'moment';
import * as mm from 'moment-timezone';
import { AirfreightApicallsService } from 'src/app/services/air-freight-validation/airfreight-apicalls.service';

@Component({
  selector: 'app-actual-arrival-editable',
  template: ` 
    <input 
      #input 
      [(ngModel)]="value" 
      type="date" 
      class="grid-cell-edit-layout " 
      (change)="textUpdated()" 
      [min]="minDate"
    />`,
  styles: [
    `
      input::-webkit-outer-spin-button,
      input::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
      }
      .grid-cell-edit-layout {
        width: 123px;
        height: 26px;
        border: 1px solid #d1d1d1;
      }
      .invalid-text {
        border: 2px solid red !important;
      }
    `,
  ],
})
export class EditDeadlineEditableComponent {
  public params: any;
  showInput = true;
  value: any;
  isEmpty = true;
  easternCurrentDate: string;
  minDate: string;
  calendarList: any[] = [];

  constructor(private readonly airFreightService: AirfreightApicallsService) {}

  async agInit(params: any): Promise<void> {
    console.log('i am called 12', params);  // Log params to check if the data is being passed correctly

    // Use the deadline_date from the params.data as 'today'
    const raisedDate = new Date(params.data.date_raised);  // Get 'deadline_date' from params.data
    const today = new Date(raisedDate);  // Convert deadline_date to a JavaScript Date object

    // Fetch calendar data after component initialization (async)
    await this.fetchCalendarData();
    console.log(this.calendarList);

    let firstWorkingDay: string = '';
    let secondWorkingDay: string = '';
    let skippedDays = 0;

    // Loop through the calendar to find the first and second working days
    for (let i = 0; i < this.calendarList.length; i++) {
      const calendarDate = this.calendarList[i].act_prod_date;
      const workStatus = this.calendarList[i].work_status;

      // Only count working days (status = 1) after the raised date
      if (new Date(calendarDate) > raisedDate && workStatus === 1) {
        skippedDays++; // Count working days after the raised date

        if (skippedDays === 1) {
          firstWorkingDay = calendarDate; // First working day after the raised date
        }

        if (skippedDays === 3) {
          secondWorkingDay = calendarDate; // Second working day after the raised date
          break;
        }
      }
    }

    // // Now, let's handle disabling and enabling days
    // for (let i = 0; i < this.calendarList.length; i++) {
    //   const calendarDate = this.calendarList[i].act_prod_date;

    //   // Disable days before the second working day (inclusive)
    //   if (new Date(calendarDate) > today && new Date(calendarDate).toISOString().substring(0, 10) !== secondWorkingDay) {
    //     this.calendarList[i].work_status = 0; // Disable non-working days
    //   }
    // }

    // Set the minimum date as the second working day
    this.minDate = secondWorkingDay;

    // Initialize the params and value for your grid
    if (params.column === 'deadline_date') {
      this.value = params.data.deadline_date;
      this.value = moment(this.value).format('YYYY-MM-DD');
    }

    this.params = params; // Update params after initializing
    console.log(this.params, 'params......');
  }

  // Fetch the calendar data using the Airfreight API
  private async fetchCalendarData(): Promise<void> {
    try {
      const response = await this.airFreightService.getCalendarList().toPromise();
      if (response && response.body && response.body.data) {
        console.log('response----getCalendar', response);

        this.calendarList = response.body.data; // Update calendarList with data from the API

        // Sorting the calendarList by act_prod_date in ascending order
        this.calendarList = Array.from(
          new Map(this.calendarList.map(item => [`${item.act_prod_date}-${item.work_status}`, item])).values()
        );
        this.sortByActProdDate(this.calendarList);
        console.log('Sorted Calendar:', this.calendarList);
      }
    } catch (error) {
      console.error('Error fetching calendar data:', error);
    }
  }

  // Sort the calendar list by act_prod_date
  private sortByActProdDate(data: any[]): any[] {
    return data.sort((a, b) => {
      const dateA = new Date(a.act_prod_date);
      const dateB = new Date(b.act_prod_date);
      return dateA.getTime() - dateB.getTime(); // Sorting in ascending order
    });
  }

  // called when the cell is refreshed
  refresh(params: any): boolean {
    this.params = params;
    return true;
  }

  public textUpdated() {
    console.log(this.params.data, 'ui');
    if (this.params.column === 'deadline_date') {
      if (this.value !== this.params.data.deadline_date) {
        this.params.data.deadline_date = this.value;
        this.params.data.isUpdated = true;
      }
    }
  }

  onKeyPress(event: KeyboardEvent): boolean {
    if (event.target && event.target instanceof HTMLInputElement) {
      if (event.target.value.length <= 4) {
        return true;
      } else {
        return false;
      }
    }
    return false;
  }
}
