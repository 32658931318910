import { Component, OnInit, Inject, EventEmitter } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { log } from 'console';
import { OspService } from 'src/app/core/master-services/osp.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { warningMessage } from 'src/app/constants';
import * as moment from 'moment';
import { getUnique, getUniqueByKey, yearWeekToDate } from 'src/app/constants';



@Component({
  selector: 'app-shifts-edit-dialog',
  templateUrl: './shifts-edit-dialog.component.html',
  styleUrls: ['./shifts-edit-dialog.component.scss']
})
export class ShiftsEditDialogComponent implements OnInit {
  addShiftsEventEmitter = new EventEmitter();
  editShiftsEventEmitter = new EventEmitter();
  rowData = [];
  errorMessage1: any;
  errorMessageUpdate = false;
  master_id: any;
  master_name: any;
  key_item: any;
  data_item: any;
  business_entity: any;
  new_key_item: any;
  cstatus: any;
  showaddpopup: boolean = false;
  showeditpopup: boolean = false;
  dock: any;
  popupname: any;
  shiftNumber: any;
  dayWeek: any;
  startTime: any;
  endTime: any;
  inMasterCalendar: any;
  inNextDay: any;
  dataItem: any;
  enableError: boolean = false;
  userForm: UntypedFormGroup;
  shiftNumbers: any[] = [];
  dayWeeks: any[] = [];
  masterCalendar: any[] = [];
  nextDay: any[] = [];
  errorMessage: any;
  hoursArray = new Array(24);
  mintuesArray = new Array(60);
  addOrEditform: UntypedFormGroup = this.formBuilder.group({
    dock: ['', Validators.required],
    shiftNumber: [1],
    dayWeek: [2],
    inMasterCalendar: ['N'],
    inNextDay: ['N'],
    effectiveTo: [null],
    effectiveFrom: [null, Validators.required],
    startHoursHH: ['', Validators.required],
    startMintuesMM: ['', Validators.required],
    endHoursHH: ['', Validators.required],
    endMintuesMM: ['', Validators.required],
  },
  );
  warningMessage: string;
  itContact: any;
  successMessage: string;
  effectiveFrom:any;
  effectiveTo:any;
  onInitprodDate: any;
  onIntprodDateTo: any;


  constructor(
    @Inject(MAT_DIALOG_DATA) public data,
    private readonly dialogeRef: MatDialogRef<ShiftsEditDialogComponent>,
    private readonly formBuilder: UntypedFormBuilder,
    private readonly ospService: OspService,
    private readonly spinner: NgxSpinnerService,


  ) { }
  get addOrEdit() {
    return this.addOrEditform.controls;
  }
  ngOnInit(): void {

    this.business_entity = localStorage.getItem('namcvalue');
    this.shiftNumbers = [
      { value: 1 },
      { value: 2 },
      { value: 3 }
    ];
    this.dayWeeks = [
      { label: 'Sunday', value: 1 },
      { label: 'Monday', value: 2 },
      { label: 'Tuesday', value: 3 },
      { label: 'Wednesday', value: 4 },
      { label: 'Thursday', value: 5 },
      { label: 'Friday', value: 6 },
      { label: 'Saturday', value: 7 },
    ];

    this.masterCalendar = [
      { value: 'N' },
      { value: 'Y' },
    ];
    this.nextDay = [
      { value: 'N' },
      { value: 'Y' },
    ];
    console.log("data---", this.data);
    this.effectiveFrom=this.onInitprodDate
    this.effectiveTo=this.onIntprodDateTo
    this.addOrEditform.controls.startHoursHH.valueChanges.subscribe((hour: number) => {
      if (hour !== null) {
        this.addOrEditform.controls.startMintuesMM.setValue(0);  // Set minutes to 00
      }
    });
 
    // Listen for changes in the 'toHoursHH' field and set minutes to '00' when hour is selected
    this.addOrEditform.controls.endHoursHH.valueChanges.subscribe((hour: number) => {
      if (hour !== null) {
        this.addOrEditform.controls.endMintuesMM.setValue(0);  // Set minutes to 00
      }
    });


    if (this.data?.mode === 'edit') {
      if (this.data.data?.dock) {
        this.addOrEdit.dock.setValue(
          this.data.data.dock
        );

      }
      
    }
  }
  formatNumber(num: number): string {
    return num < 10 ? '0' + num : num.toString();
  }
  submitted: boolean = false;
  convertDateFormat(date: string): string {
    const dateObj = new Date(date);
    const month = dateObj.getMonth() + 1;  // Months are 0-indexed
    const day = dateObj.getDate();
    const year = dateObj.getFullYear();

    // Return the date in mm/dd/yyyy format
    return `${month < 10 ? '0' + month : month}/${day < 10 ? '0' + day : day}/${year}`;
  }
  onEffectiveFromChange(event: Event) {
    const input = event.target as HTMLInputElement;
    this.effectiveFrom = input.value;

    // If the "Effective From" date is greater than the "Effective To" date, update "Effective To"
    if (this.effectiveFrom && this.effectiveTo && this.effectiveFrom > this.effectiveTo) {
      this.effectiveTo = this.effectiveFrom;
    }
  }

  addShiftsDetails() {
    if (!this.addOrEditform.valid) {
      this.submitted = true;
      return;
    }
  
    const { startHoursHH, startMintuesMM, endHoursHH, endMintuesMM } = this.addOrEditform.value;
    const startTimeNew = `${startHoursHH.toString().padStart(2, '0')}:${startMintuesMM.toString().padStart(2, '0')}:00`;
    const endTimeNew = `${endHoursHH.toString().padStart(2, '0')}:${endMintuesMM.toString().padStart(2, '0')}:00`;
  
    if (this.isStartTimeGreaterThanEndTime(startTimeNew, endTimeNew)) {
      this.submitted = true;
      this.errorMessage = 'Start time must be earlier than end time.';
      return; // Return early, preventing further execution
    }
  
    // Convert start and end times to minutes
    const formattedStartTime = this.convertToFullTimeFormat(startTimeNew);
    const formattedEndTime = this.convertToFullTimeFormat(endTimeNew);
  
    // Calculate the duration in minutes
    const duration = this.calculateDuration(formattedStartTime, formattedEndTime);
  
    // Prepare the emitted data from the form controls
    const addEmittedData = {
      dock: this.addOrEdit.dock.value,
      shiftNumber: this.addOrEdit.shiftNumber.value,
      dayWeek: this.addOrEdit.dayWeek.value,
      startTime: startTimeNew, // Keep startTime as HH:mm
      endTime: endTimeNew, // Keep endTime as HH:mm
      duration: duration, // Duration in minutes
      inNextDay: this.addOrEdit.inNextDay.value,
      inMasterCalendar: this.addOrEdit.inMasterCalendar.value,
      effectiveTo: this.addOrEdit.effectiveTo.value || '12/31/2999',
      effectiveFrom: this.addOrEdit.effectiveFrom.value,
    };
  
    let isCall = false;
    let selectedPartNo: string[] = []; // Initialize selectedPartNo as an array
  
    // Check if effectiveFrom has a value and is not empty
    if (addEmittedData.effectiveFrom.length > 0) {
      isCall = true;
      selectedPartNo.push(formatDate(addEmittedData.effectiveFrom)); // Format the date and push it into the array
    }
  
    // API call block
    if (isCall) {
      const data = {
        offset: 0,
        limit: 8000,
        business_entity: localStorage.getItem('namcvalue'),
        workid: localStorage.getItem('workdayId'),
        user_role: localStorage.getItem('UserRoles'),
        dock: [this.addOrEdit.dock.value]
      };
  
      this.ospService.getShiftDetails(data).subscribe({
        error: this.errorCallback,
        next: (res) => {
          if (res.body.shiftResponce.data.length === 0) {
            this.rowData.push(...res.body.shiftResponce.data);
            this.errorMessage = null;
            this.spinner.hide();
            this.addShiftsEventEmitter.emit(addEmittedData);
            this.dialogeRef.close();
          } else {
            const filteredParts = res.body.shiftResponce.data;
  
            if (filteredParts?.length > 0) {
              const checkEffectiveDates = (part) => {
                const partEffectiveFrom = part.from_date;
                const partEffectiveTo = part.to_date;
                const emittedEffectiveFrom = formatDate(addEmittedData.effectiveFrom);
                const emittedEffectiveTo = formatDate(addEmittedData.effectiveTo);
  
                return this.isEffectiveDateInRange(emittedEffectiveFrom, emittedEffectiveTo, partEffectiveFrom, partEffectiveTo);
              };
  
              const checkResults = filteredParts.map((part) => {
                const isValid = checkEffectiveDates(part);
                return {
                  shift_no: part.shift_no,
                  shift_id: part.shift_id,
                  from_date: part.from_date,
                  to_date: part.to_date,
                  isEffectiveInRange: isValid,
                };
              });
  
              const invalidShifts = checkResults.filter((result) => result.isEffectiveInRange);
  
              // If there are invalid shifts, prevent API call and show error
              if (invalidShifts?.length !== 0) {
                const uniqueShiftNumbers = getUnique(invalidShifts.map((result) => result.shift_no), 'shift_no');
                this.spinner.hide();
                this.warningMessage = `This configuration conflicts with shift with effective dates overlapping with ${formatDate(addEmittedData.effectiveFrom)} 'to' ${formatDate(addEmittedData.effectiveTo)}`;
                // this.errorMessage1 = `This configuration conflicts with shift with effective dates overlapping with ${formatDate(addEmittedData.effectiveFrom)} 'to' ${formatDate(addEmittedData.effectiveTo)} === '12/31/2999' ? '' : ' to ' + ${formatDate(addEmittedData.effectiveTo)}`;
                // this.errorMessage1 = `Shift Number ${uniqueShiftNumbers.join(', ')} are already part of the inventory policy with effective dates overlapping with ${addEmittedData.effectiveFrom}${addEmittedData.effectiveTo === '12/31/2999' ? '' : ' to ' + addEmittedData.effectiveTo}`;
                // This configuration conflicts with shift [1,sat,20090907,...]
                // Exit early to prevent further execution
                console.log('Error Message:', this.warningMessage);
                return;
              }
            }
            
            // If no invalid shifts, emit the data and close dialog
            this.spinner.hide();
            this.addShiftsEventEmitter.emit(addEmittedData);
            this.dialogeRef.close();
          }
        }
      });
    }
  
    function formatDate(dateStr: string): string {
      const date = new Date(dateStr); // Convert string to Date object
      date.setHours(0, 0, 0, 0); // Ensure the time is set to midnight local time to avoid time zone issues
  
      const month = (date.getMonth() + 1).toString().padStart(2, '0'); // Get month and format as 'MM'
      const day = date.getDate().toString().padStart(2, '0'); // Get day and format as 'dd'
      const year = date.getFullYear(); // Get full year 'yyyy'
  
      return `${month}/${day}/${year}`; // Return formatted date as 'MM/dd/yyyy'
    }
  
    function getUnique(arr, key) {
      return [...new Set(arr.map(item => item[key]))];
    }
  }
  

  private isStartTimeGreaterThanEndTime(startTime: any, endTime: any): any {
    // Compare times lexicographically since HH:mm format works lexicographically (e.g., "08:00" < "10:30")
    return startTime >= endTime;
  }
  isEffectiveDateInRange = (effectiveFrom, effectiveTo, yearweekFrom, yearweekTo) => {
    return effectiveFrom <= yearweekTo && effectiveTo >= yearweekFrom;
  };
  // Convert time string (HH:mm) to total minutes (from midnight)
  convertToFullTimeFormat(time: string): number {
    const [hours, minutes] = time.split(':').map(Number);
    return hours * 60 + minutes; // Return total minutes from midnight
  }

  // Calculate the duration in minutes between startTime and endTime
  calculateDuration(startTime: number, endTime: number): number {
    let durationInMinutes = endTime - startTime;

    // If the end time is earlier than start time (meaning it crosses over midnight), adjust for next day
    if (durationInMinutes < 0) {
      durationInMinutes += 1440; // Add 1440 minutes (1 full day) if endTime is on the next day
    }

    return durationInMinutes;
  }
  formatDurationAsInterval(durationInMinutes: number): string {
    const hours = Math.floor(durationInMinutes / 60);
    const minutes = durationInMinutes % 60;

    // Return formatted interval string
    return `${hours} hours ${minutes} minutes`;
  }


  editRowShiftsDetails() {
    if (this.addOrEditform.invalid) {
      this.submitted = true;
      return;
    }

    const editEmittedData = {
      master_id: this.addOrEdit.masterId.value.master_id,
      master_name: this.addOrEdit.masterName.value.name,
      startTime: this.addOrEdit.startTime.value,
      data_item: this.addOrEdit.dataItem.value,
    };
    this.editShiftsEventEmitter.emit(editEmittedData);
    this.dialogeRef.close();

  }
  onTimeChange(newTime: string): void {
    console.log("New time selected:", newTime);  // Log or process the new time
    this.startTime = newTime;  // Update the component model with the new time
  }
  removeMessage() {
    this.warningMessage = '';
    this.successMessage = '';
  };

  errorCallback = (error) => {
    console.error(error);
    this.hideSpinner();

    this.removeMessage();
    this.warningMessage = `${warningMessage.apiLogicFail}  ${this.itContact}.`;
  };
  hideSpinner() {
    this.spinner.hide();
  }



  callRespectiveFunction() {
    if (this.data?.mode === 'edit') {
      this.editRowShiftsDetails();
    } else {
      console.log("add function is called");

      this.addShiftsDetails();
    }
  }
  onKeyPress(event: KeyboardEvent) {
    const allowedKeys = ['Backspace', 'Tab', 'ArrowLeft', 'ArrowRight', 'Delete'];
    const isNumber = /^[0-9]$/.test(event.key);

    // Allow number keys, allowed keys, and prevent default for others
    if (!isNumber && !allowedKeys.includes(event.key)) {
      event.preventDefault();
    }
  }
}

