import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable,of } from 'rxjs';
import { EnvironmentConfiguration } from 'src/app/environment/services/environment.configuration';
import { catchError } from 'rxjs/operators';


@Injectable({
  providedIn: 'root'
})
export class ModuleStockingService {
  getToken = () => localStorage.getItem('Token');

  getHttpCustomOptions() {
    return {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Accept: 'application/json',
        authorization: localStorage.getItem('Token'),
        'X-Api-Key': this.environment.moduleStockingKey,
      }),
    };
  }

  getApiHeaders = () => {
    return new HttpHeaders({
      'Content-Type': 'application/json',
      Accept: 'application/json',
      Authorization: this.getToken(),
      'X-Api-Key': this.environment.moduleStockingKey,
    });
  };

  getBusinessEntity = () => localStorage.getItem('namcvalue');

  getBusinessEntityName = () => localStorage.getItem('namcName');

  getUserRole = () => localStorage.getItem('UserRoles');

  getUserUPN = () => localStorage.getItem('UPN');

  getWorkID = () => localStorage.getItem('workdayId');

  getUserName = () => localStorage.getItem('UserName');

  moduleStockingApiUrl = '';

  constructor(private http: HttpClient,
    private environment: EnvironmentConfiguration) {

    this.moduleStockingApiUrl = environment.moduleStockingUrl;
  }

  getmoduleStockingData(data): Observable<any> {
    return this.http.post<any>(
      `${this.moduleStockingApiUrl}/modulestocking`,
      data,
      this.getHttpCustomOptions()).pipe(catchError(error => of({ error })))
    
  }

  editModuleStocking(data): Observable<any> {
    return this.http.put<any>(
      `${this.moduleStockingApiUrl}/modulestocking`,
      data,
      this.getHttpCustomOptions()
    )
  }
  editModuleType(data): Observable<any> {
    return this.http.put<any>(
      `${this.moduleStockingApiUrl}/editModuleType`,
      data,
      this.getHttpCustomOptions()
    )
  }
  deleteModuleType(data): Observable<any> {
    const httpCustomOptions = {
      body: data,
      headers: this.getApiHeaders(),
    };

    return this.http.delete<any>(
      `${this.moduleStockingApiUrl}/deleteModuleType`,


      httpCustomOptions
    );
  }
  getLine(): Observable<any> {
    const data = {
      business_entity: this.getBusinessEntity(),
      business_entity_name: this.getBusinessEntityName(),
      userid: this.getUserName(),
      workid: this.getWorkID(),
      user_role: this.getUserRole(),
    };
    return this.http.post<any>(`${this.moduleStockingApiUrl}/getLine`, data, this.getHttpCustomOptions());
  }

  getmoduleTypeGrid(data): Observable<any> {
    return this.http.post<any>(
      `${this.moduleStockingApiUrl}/getModuleTypeData`,
      data,
      this.getHttpCustomOptions()
    );
  }
  getTrackingPoint(): Observable<any> {
    const data = {
      business_entity: this.getBusinessEntity(),
      business_entity_name: this.getBusinessEntityName(),
      userid: this.getUserName(),
      workid: this.getWorkID(),
      user_role: this.getUserRole(),
    };
    return this.http.post<any>(`${this.moduleStockingApiUrl}/getTrackingPoint`, data, this.getHttpCustomOptions());
  }
  getActiveSelectionModuleToDock(data): Observable<any> {
    const data1 = {
      business_entity: this.getBusinessEntity(),
      module_type: data.module_type,
      business_entity_name: this.getBusinessEntityName(),
      userid: this.getUserName(),
      workid: this.getWorkID(),
      user_role: this.getUserRole(),
    };
    return this.http.post<any>(`${this.moduleStockingApiUrl}/getActiveSelectionModuleToDock`, data1, this.getHttpCustomOptions());
  }
  addModuleType(data): Observable<any> {
    return this.http.put<any>(
      `${this.moduleStockingApiUrl}/addModuleType`,
      data,
      this.getHttpCustomOptions()
    );
  }

  editModuleTypeCheck(data,urlValue): Observable<any> {
    return this.http.put<any>(
      `${this.moduleStockingApiUrl}/editModuleTypeCheck/${urlValue}`,
      data,
      this.getHttpCustomOptions()
    )
  }

  getModuleType(): Observable<any> {
    const data = {
      business_entity: this.getBusinessEntity(),
      business_entity_name: this.getBusinessEntityName(),
      userid: this.getUserName(),
      workid: this.getWorkID(),
      user_role: this.getUserRole(),
    };
    return this.http.post<any>(`${this.moduleStockingApiUrl}/getModuleType`, data, this.getHttpCustomOptions());
  }
  
}