import { Component } from '@angular/core';
import * as mm from 'moment-timezone';
import { AirfreightApicallsService } from 'src/app/services/air-freight-validation/airfreight-apicalls.service';
import * as moment from 'moment';
import { FormArray, UntypedFormBuilder, UntypedFormGroup, Validators, AbstractControl, ValidationErrors } from '@angular/forms';
@Component({
  selector: 'app-deadline-editable',
  template: `
  <div style="margin: 3px 0px 6px;">
    <input #input [(ngModel)]="value" type="date" [min]="minDate" class="grid-cell-edit-layout" (change)="textUpdated()"/>   
    <small 
    *ngIf="value && getTime(params.data.arriving_date) > getTime(value)"  class="error">
      <!-- Should be greater or equal to Arrival date -->
      Select a date not before arrival
    </small>
    <small *ngIf="!value && errorMsg && params.context.componentParent.submitted && !datecheck" class="error">{{errorMsg}}</small>
    <small *ngIf="datecheck" class="error">{{datecheck}}</small>
</div>
  `,
  styles: [
    `
      input::-webkit-outer-spin-button,
      input::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
      }
      .grid-cell-edit-layout {
        width: 100%;
        border: 1px solid #d1d1d1;
      }
      .invalid-text {
        border: 2px solid red !important;
      }
      .error{color:red}
    `,
  ],
})
export class DeadlineEditableComponent {
  public params: any;
  showInput = true;
  value: any;
  isEmpty = true;
  errorMsg = `Deadline DT is requird`;
  minDate: string;
  easternCurrentDate: string;
  datecheck = null;
  // calendarList: any[] = [];

  constructor(private readonly airFreightService: AirfreightApicallsService) { }

  async agInit(params: any): Promise<void> {
    params.data.deadlineDate = this;
    if (params.column === 'deadline_dates') {
      this.value = params.data.deadline_dates;
    }
    this.params = params;
    // Set the easternCurrentDate to the current date in US/Michigan timezone
    this.easternCurrentDate = String(mm().tz('US/Michigan').format('YYYY-MM-DD')).substring(0, 10);
    this.minDate = this.easternCurrentDate;
  }

  // called when the cell is refreshed
  refresh(params: any): boolean {
    this.params = params;
    return true;
  }

  textUpdated() {
    if (this.params.column === 'deadline_dates') {
      if (this.value !== this.params.data.deadline_dates) {
        this.params.data.deadline_dates = this.value;
        this.params.data.isUpdated = true;
      }
    }
    if(this.params.context.componentParent.empty_valueCheck(this.value)){
      this.errorMsg = `Deadline DT is requird`;
    }
    // if(this.params.context.componentParent.empty_valueCheck(this.params.data.arriving_date)){
    //   this.value = null;
    //   this.params.data.deadline_dates = null;
    //   this.errorMsg = 'Please select Arrival date first.';
    // }else {
    //   this.checkNonworkingday(this.params.data.partIndex,this.value);
    // }
    // if(this.value)
    //   this.checkNonworkingday(this.params.data.partIndex,this.value);
  }

  // onKeyPress(event: KeyboardEvent): boolean {
  //   if (event.target && event.target instanceof HTMLInputElement) {
  //     if (event.target.value.length <= 4) {
  //       return true;
  //     } else {
  //       return false;
  //     }
  //   }
  //   return false;
  // }
  checkNonworkingday(part_index, selected_date):void{
    let validDate: any = this.params.context.componentParent.checkNonworkingday(part_index, selected_date);
    // if (validDate) {
    //   this.datecheck = validDate;
    //   this.params.data.schedule_error = true;
    //   // this.value = null;
    //   // this.params.data.deadline_dates = null;
    // } else {
    //   this.datecheck = null;
    // }
    if (validDate) {
      if(validDate.check == 1){
      this.value = null;
      this.params.data.deadline_dates = null;
      }
      this.datecheck = validDate.msg;
      this.params.data.schedule_error = true;      
      
    } else {
      this.datecheck = null;
    }
  //   setTimeout(()=>{ 
  //     this.datecheck = null;
  // }, 10000);
  }
  getTime(date):number{
    return new Date(date).getTime()
  }
}
