import { Component, Inject, OnInit } from '@angular/core'
// import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog'
// import { ExportStateService } from 'src/app/services/export-state.service'
import {
  MatLegacyDialogRef as MatDialogRef,
  MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA,
} from '@angular/material/legacy-dialog';
@Component({
  selector: 'app-delete-breaks-co',
  templateUrl: './delete-breaks-co.component.html',
  styleUrls: ['./delete-breaks-co.component.scss'],
})
export class DeleteBreaksCoComponent implements OnInit {
  param: any
  fileName: string;
  mesaage: string;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private readonly dialogRef: MatDialogRef<DeleteBreaksCoComponent>,
  ) {}

  ngOnInit(): void {
   console.log('this.dialogRef.componentInstance.data.message',this.dialogRef.componentInstance.data.message);  
   this.mesaage = this.dialogRef.componentInstance.data.message;
  }

  closeDialog() {
    this.dialogRef.close()
  }

  dialogData(pNam: string) {
    this.dialogRef.close({ data: pNam })
  }
}