import { Component, OnInit, ViewChild } from '@angular/core';
import { GriddataService } from '../services/griddata.service';
import { Subscription, forkJoin } from 'rxjs';
import { GridRefreshComponent } from '../core/grid/grid-refresh/grid-refresh.component';
import { OSPStateService } from '../services/osp-state.service';
import { LookupService } from '../services/lookup/lookup.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { logErrors } from '../shared/logger';
import { HeaderCheckboxComponent } from '../core/grid/header-checkbox/header-checkbox.component';
import { RowCheckboxComponent } from '../core/grid/row-checkbox/row-checkbox.component';
import { dateComparator, dateFilterComparator, resetSortingAndFilters } from '../constants';
import { StockingPaceServiceService } from '../services/stocking-pace-service.service';
import { ExcelService } from '../services/excel.service';
import { validateVanningDates, warningMessage } from '../constants';
import * as mm from 'moment-timezone';

@Component({
  selector: 'app-stocking-pace-inquiry',
  templateUrl: './stocking-pace-inquiry.component.html',
  styleUrls: ['./stocking-pace-inquiry.component.scss'],
})
export class StockingPaceInquiryComponent implements OnInit {
  headerCheckboxChecked: any;
  dateComparator: any;
  @ViewChild('osPartsGrid') osPartsGrid;
  isEditMode = false;
  editEnabled: boolean = false;
  defaultPageSize: any = 100000;
  offset: number = 0;
  user: any;
  userRole: any;
  dataCount: number = 0;
  stockingPaceSubscription$: Subscription;
  selectedDock: any = [];
  selectedModuleType: any = [];
  selectedVanMonth: any = [];

  columnDefs: any;
  rowData: any;
  rowSelection: any;
  gridOptions: any;
  pagination: any;
  paginationPageSize: any;
  context: any;
  suppressClickEdit: boolean;
  frameworkComponents: any;
  tooltipShowDelay: any;
  editType: any;
  search_form = {
    dock: '',
    module_type: '',
    van_month: '',
  };
  dockModuleList: any = [];
  dockList: any = [];
  vanMonthList: any = [];
  moduleTypeList: any = [];
  // temp_dock_module_type: any = [];
  disablemodule = true;
  constructor(
    private readonly gridDataService: GriddataService,
    private readonly stateService: OSPStateService,
    private readonly lookupService: LookupService,
    private readonly spinner: NgxSpinnerService,
    private readonly stockingService: StockingPaceServiceService,
    private readonly excelService: ExcelService
  ) {
    this.context = {
      componentParent: this,
    };
  }
  ngOnInit(): void {
    console.log('on Init call.');
    this.columnDefs = [
      {
        headerName: '',
        field: 'rowCheckBox',
        // suppressSorting: true,
        width: 44,
        headerComponentParams: {
          headerChecked: this.headerCheckboxChecked,
        },
        headerComponentFramework: HeaderCheckboxComponent,
        floatingFilterComponentFramework: GridRefreshComponent,
        floatingFilterComponentParams: { suppressFilterButton: true },

        cellRendererFramework: RowCheckboxComponent,
        floatingFilter: true,
      },
      {
        headerName: 'Dock',
        field: 'dock',
        width: 100,
        floatingFilter: true,
        sortable: true,
      },
      {
        headerName: 'Module Description',
        field: 'module_description',
        width: 210,
        floatingFilter: true,
        tooltipField: 'name',
        sortable: true,
      },
      {
        headerName: 'Container From',
        field: 'container_from',
        width: 210,
        floatingFilter: true,        
        sortable: true
      },
      {
        headerName: 'Container To',
        field: 'container_to',
        width: 210,
        floatingFilter: true,
        sortable: true
      },
      {
        headerName: 'Module Type',
        field: 'module_type',
        width: 150,
        floatingFilter: true,
        sortable: true,
      },
      {
        headerName: 'VAN Month',
        field: 'vanning_month',
        width: 170,
        floatingFilter: true,
        sortable: true,
        // comparator: dateComparator,
        // filter: 'agDateColumnFilter',
        // filterParams: {
        //   comparator: dateFilterComparator,
        // },
      },
      // {
      //   headerName: 'Vanning From',
      //   field: 'vanning_from',
      //   width: 170,
      //   floatingFilter: true,
      //   sortable: true,
      //   comparator: dateComparator,
      //   filter: 'agDateColumnFilter',
      //   filterParams: {
      //     comparator: dateFilterComparator,
      //   },
      // },
      // {
      //   headerName: 'Vanning To',
      //   field: 'vanning_to',
      //   width: 170,
      //   floatingFilter: true,
      //   tooltipField: 'userid',
      //   sortable: true,
      //   comparator: dateComparator,
      //   filter: 'agDateColumnFilter',
      //   filterParams: {
      //     comparator: dateFilterComparator,
      //   },
      // },
      {
        headerName: 'No of Vehicles Vanned',
        field: 'total_veh_qty',
        width: 150,
        floatingFilter: true,
        filter: 'agNumberColumnFilter',
        floatingFilterComponentParams: { suppressFilterButton: false },
        filterParams: {
          numberParser: (text) => {
            return text === null ? null : parseFloat(text.replace(',', '.'));
          },
        },
      },
      {
        headerName: 'No of Modules',
        field: 'total_module_qty',
        width: 150,
        floatingFilter: true,
        filter: 'agNumberColumnFilter',
        floatingFilterComponentParams: { suppressFilterButton: false },
        filterParams: {
          numberParser: (text) => {
            return text === null ? null : parseFloat(text.replace(',', '.'));
          },
        },
      },

      {
        headerName: 'Calculated Pitch',
        field: 'est_stocking_pace',
        width: 150,
        floatingFilter: true,

        valueGetter: (params) => {
          return parseFloat(params.data.est_stocking_pace) || null;
        },
      },

      {
        headerName: 'Updated DT',
        field: 'updateddt',
        width: 150,
        floatingFilter: true,
        headerTooltip: 'Updated Date(mm/dd/yyyy hh:mm:ss (Eastern))',
      },
      {
        headerName: 'User',
        field: 'updated_userid',
        width: 198,
        floatingFilter: true,
        tooltipField: 'userid',
      },
    ];

    this.rowSelection = 'multiple';
    this.editType = 'fullRow';
    this.gridOptions = this.gridDataService.getGridOptions();
    this.gridOptions.onGridReady = function (params) {
      this.gridApi = params.api;
    };

    this.stockingPaceSubscription$ = this.stateService.getNamc().subscribe((observable) => {
      this.initializeData();
    });
  }

  namcValue = localStorage.getItem('namcvalue');
  userName = localStorage.getItem('userName');

  warningMessage: string;
  successMessage: string;

  removeMessage() {
    this.successMessage = '';
    this.warningMessage = '';
  }

  userCheckChanged(checked, rowIndex) {
    this.spinner.show();
    const rowNode = this.osPartsGrid.gridOptions.api.getDisplayedRowAtIndex(rowIndex);
    if (checked === true) {
      rowNode.setSelected(true);
      if (this.isEditMode) {
        rowNode.data.checkedEditMode = true;
      }
      rowNode.data.rowCheckBox = true;
    } else {
      rowNode.setSelected(false);
      rowNode.data.checkedEditMode = false;
      rowNode.data.rowCheckBox = false;
    }

    this.gridOptions.getRowStyle = function (params) {
      if (params.node.rowIndex === rowIndex) {
        const color = checked ? '#E4ECF0' : 'white';
        return { background: color };
      }
      return { background: 'white' };
    };

    rowNode.setData(rowNode.data);
    this.toggleHeaderCheckbox(checked);
    this.spinner.hide();
  }
  toggleHeaderCheckbox(checked) {
    if (!checked) {
      this.headerCheckboxChecked = false;

      this.osPartsGrid.gridOptions.api.refreshHeader();

      return;
    }

    const rowCount = this.osPartsGrid.gridOptions.rowData.length;
    const selectedRowCount = this.osPartsGrid.gridOptions.rowData.filter((x) => x.rowCheckBox === true).length;

    if (rowCount === selectedRowCount) {
      this.headerCheckboxChecked = true;

      this.osPartsGrid.gridOptions.api.refreshHeader();
    }
  }
  headerCheckChanged(event) {
    this.headerCheckboxChecked = event.checked;
    const headerChecked = event.checked;
    const isEditable = this.isEditMode;
    this.osPartsGrid.gridOptions.api.forEachNode(function (rowNode, index) {
      if (headerChecked) {
        if (isEditable) {
          rowNode.data.checkedEditMode = true;
        }
        rowNode.data.rowCheckBox = true;
      } else {
        rowNode.data.rowCheckBox = false;
        rowNode.data.checkedEditMode = false;
      }
      rowNode.setData(rowNode.data);
    });
  }

  onEditMode() {
    this.removeMessage();
    let data = [];

    data = this.getModifiedRowData();
    if (data.length > 0) {
      this.isEditMode = !this.isEditMode;
      this.editEnabled = this.isEditMode;

      if (this.isEditMode) {
        this.osPartsGrid.gridOptions.api.forEachNode(function (rowNode, index) {
          rowNode.data.checkedEditMode = true;
          if (!rowNode.data.rowCheckBox) {
            rowNode.data.checkedEditMode = false;
          }
          rowNode.setData(rowNode.data);
        });
      } else {
        this.osPartsGrid.gridOptions.api.forEachNode(function (rowNode, index) {
          if (rowNode.data.rowCheckBox) {
            rowNode.data.checkedEditMode = false;
          }
          rowNode.setData(rowNode.data);
        });
      }
      setTimeout(() => {
        window.scroll(0, document.body.scrollHeight);
      }, 100);
    } else {
      this.isEditMode = false;
      this.editEnabled = false;
      //this.warningMessage = warningMessage.selectRowsToEdit;
      window.scroll(0, 0);
    }
  }

  getModifiedRowData(): any {
    const modifiedRowData = this.rowData.filter((row) => row.rowCheckBox);
    return modifiedRowData;
  }

  onResetDropDown() {
    this.removeMessage();

    this.selectedDock = [];
    this.selectedModuleType = [];
    this.selectedVanMonth = [];

    this.selectedDock.push({ dock: 'ALL' });
    this.selectedModuleType.push({ module_type: 'ALL' });
    this.selectedVanMonth.push({ vanning_month: 'ALL' });
    this.disablemodule = false;

    this.appendDockModuleData(this.dockModuleList);
    if (this.osPartsGrid) {
      this.headerCheckboxChecked = false;
      this.osPartsGrid.gridOptions.api.refreshHeader();
      this.resetGrid();
    }

    this.setPageSizeToAll(this.defaultPageSize);

    this.rowData = [];

    this.offset = 0;
  }

  onSearch(offset) {
    if (this.osPartsGrid) {
      this.headerCheckboxChecked = false;
      this.osPartsGrid.gridOptions.api.refreshHeader();

      this.resetGrid();
    }

    this.removeMessage();

    if (offset === 0) {
      this.setPageSizeToAll(this.defaultPageSize);
      this.rowData = [];
      this.osPartsGrid.gridOptions.api.setRowData(this.rowData);

      this.offset = 0;
      this.spinner.show();
    }

    if (!this.checkIfAnySelected()) {
      this.rowData = [];
      this.warningMessage = warningMessage.rundownSearchError;

      window.scroll(0, 0);
      this.spinner.hide();
      return false;
    }

    let selectedDock;
    let selectedModuleType;
    let selectedVanMonth;

    if (this.selectedDock.length > 0) {
      if (!this.selectedDock.some((a) => a.dock === 'ALL')) {
        selectedDock = [];
        this.selectedDock.forEach((element) => {
          selectedDock.push(element.dock);
        });
      }
    }
    if (this.selectedModuleType.length > 0) {
      if (!this.selectedModuleType.some((a) => a.module_type === 'ALL')) {
        selectedModuleType = [];
        this.selectedModuleType.forEach((element) => {
          selectedModuleType.push(element.module_type);
        });
      }
      if (this.selectedVanMonth.length > 0) {
        if (!this.selectedVanMonth.some((a) => a.vanning_month === 'ALL')) {
          selectedVanMonth = [];
          this.selectedVanMonth.forEach((element) => {
            selectedVanMonth.push(element.vanning_month);
          });
        }
      }

      // selectedDock = [];
      // this.selectedDock.forEach((element) => {
      //   selectedDock.push(element.dock);
      // });
    }

    const data = {
      offset: offset,
      limit: 12000,
      business_entity: this.namcValue,
      workid: this.user,
      user_role: this.userRole,

      dock: selectedDock,
      module_type: selectedModuleType,
      vanning_month: selectedVanMonth,
    };

    this.stockingService.getStockingPaceData(data).subscribe({
      error: this.errorCallback,
      next: (res) => {
        if (res.body && res.body.data) {
          this.rowData.push(...res.body.data);
          this.osPartsGrid.gridOptions.api.setRowData(this.rowData);
          this.dataCount = parseInt(res.body.rowCount);
          const records = this.dataCount - (this.offset + 1) * 12000;
          if (records >= 1) {
            this.offset++;
            this.onSearch(this.offset * 12000);
          } else {
            this.spinner.hide();
          }
        } else {
          this.rowData = [];
          this.spinner.hide();
        }

        this.setPageSizeToAll();
      },
    });
    return true;
  }
  resetGrid() {
    if (this.osPartsGrid) {
      resetSortingAndFilters(this.osPartsGrid);

      return;
    }
  }

  setPageSizeToAll(pageSize?) {
    this.paginationPageSize = this.rowData.length;

    if (pageSize) {
      this.paginationPageSize = 0;
      this.rowData.length = 0;
    }

    this.onPageSizeChanged();
  }

  checkIfAnySelected() {
    if (this.selectedModuleType.length > 0 && this.selectedDock.length > 0 && this.selectedVanMonth.length > 0) {
      return true;
    }
    return false;
  }
  initializeData() {
    console.log('initialize data called');
    this.rowData = [];
    this.spinner.show();

    this.namcValue = localStorage.getItem('namcvalue');

    if (localStorage.getItem('workdayId')) {
      this.user = localStorage.getItem('workdayId');
    }
    if (localStorage.getItem('UserRoles')) {
      this.userRole = localStorage.getItem('UserRoles');
    }

    this.loadDrop();
    this.onResetDropDown();
  }

  errorCallback = (error) => {
    logErrors(error);
    this.spinner.hide();

    this.warningMessage = 'Something went wrong! Please contact support team.';
    this.rowData = [];
    this.osPartsGrid.gridOptions.api.setRowData(this.rowData);
  };
  appendDockModuleData(data) {
    data.forEach((item) => {
      if (!this.dockList.some((dockItem) => dockItem.dock === item.dock)) {
        this.dockList.push({ dock: item.dock });
      }
      this.moduleTypeList.push({ module_type: item.module_type });
    });
  }

  loadDrop() {
    const lookupSubscriptions = [this.stockingService.getDockAndModuleType(), this.stockingService.getVanMonth()];
    forkJoin(lookupSubscriptions).subscribe({
      next: (res) => {
        this.dockList = [];
        this.vanMonthList = [];
        this.dockModuleList = [];
        this.moduleTypeList = [];
        this.dockList.push({ dock: 'ALL' });
        this.moduleTypeList.push({ module_type: 'ALL' });

        //set selected dock and module type to ALL
        this.selectedDock = [];
        this.selectedModuleType = [];
        this.selectedDock.push({ dock: 'ALL' });
        this.selectedModuleType.push({ module_type: 'ALL' });
        this.selectedVanMonth = [];
        this.selectedVanMonth.push({ vanning_month: 'ALL' });
        this.disablemodule = false;

        if (res[0].body != 'No Content') {
          this.dockModuleList.push(...res[0].body);
          this.appendDockModuleData(this.dockModuleList);
        }
        this.vanMonthList.push({ vanning_month: 'ALL' });
        if (res[1].body != 'No Content') {
          this.vanMonthList.push(...res[1].body);
        }

        this.spinner.hide();
      },
      error: this.errorCallback,
    });
  }

  dockChange(): void {
    if (this.selectedDock.length >= 1) {
      if (this.selectedDock[this.selectedDock.length - 1].dock === 'ALL') {
        // Select all docks and all modules
        this.selectedDock = [];
        this.selectedDock.push(this.dockList[0]); // Select the first dock
        this.moduleTypeList = [];
        this.moduleTypeList.push({ module_type: 'ALL' }); // Select all module types

        // Reset selectedModuleType to 'ALL'
        this.selectedModuleType = [{ module_type: 'ALL' }];

        this.dockModuleList.forEach((item: any) => {
          // Add module type only if it's not already in the list
          if (!this.moduleTypeList.some((m) => m.module_type === item.module_type)) {
            this.moduleTypeList.push({ module_type: item.module_type });
          }
        });
      } else {
        let indexAll = this.selectedDock.findIndex((data) => data.dock === 'ALL');
        if (indexAll > -1) {
          this.selectedDock.splice(indexAll, 1);
        }
      }
    }

    // When no "ALL" in selectedDock, we filter the module types based on selected docks
    if (this.selectedDock[0].dock !== 'ALL') {
      this.moduleTypeList = [];
      this.selectedModuleType = [];
      this.moduleTypeList.push({ module_type: 'ALL' });

      // Reset selectedModuleType to 'ALL' when no docks are selected
      this.selectedModuleType = [{ module_type: 'ALL' }];

      this.dockModuleList.forEach((item: any) => {
        if (this.selectedDock.some((s_dock) => s_dock.dock === item.dock)) {
          // Add module type only if it's not already in the list
          if (!this.moduleTypeList.some((m) => m.module_type === item.module_type)) {
            this.moduleTypeList.push({ module_type: item.module_type });
          }
        }
      });
    }
  }

  moduleChange(): void {
    if (this.selectedModuleType.length >= 1) {
      if (this.selectedModuleType[this.selectedModuleType.length - 1].module_type === 'ALL') {
        // Select all module types and all docks
        this.selectedModuleType = [];
        this.selectedModuleType.push(this.moduleTypeList[0]); // Select the first module
        // this.selectedDock = [];
        // this.selectedDock.push({ dock: 'ALL' }); // Select all docks
      } else {
        let indexAll = this.selectedModuleType.findIndex((data) => data.module_type === 'ALL');
        if (indexAll > -1) {
          this.selectedModuleType.splice(indexAll, 1);
        }
      }
    }

    // When no "ALL" in selectedModuleType, we filter the docks based on selected module types
    if (this.selectedModuleType[0].module_type !== 'ALL') {
      this.selectedDock = [];
      this.dockModuleList.forEach((item: any) => {
        if (this.selectedModuleType.some((s_module) => s_module.module_type === item.module_type)) {
          if (!this.selectedDock.some((s_dock) => s_dock.dock === item.dock)) {
            this.selectedDock.push({ dock: item.dock });
          }
        }
      });
    }
  }

  vanMonthChange() {
    if (this.selectedVanMonth.length >= 1) {
      if (this.selectedVanMonth[this.selectedVanMonth.length - 1].vanning_month === 'ALL') {
        this.selectedVanMonth = [];
        this.selectedVanMonth.push(this.vanMonthList[0]);
      } else {
        let indexAll = this.selectedVanMonth.findIndex((data) => data.vanning_month === 'ALL');
        if (indexAll > -1) {
          this.selectedVanMonth.splice(indexAll, 1);
        }
      }
    }
  }
  onPageSizeChanged() {
    if (this.gridOptions?.api) {
      this.gridOptions.api.paginationSetPageSize(Number(this.paginationPageSize));
    }
  }

  exportAsXLSX(): void {
    this.removeMessage();

    this.spinner.show();

    // eastern time api
    const easternCurrentDate = String(mm().tz('US/Michigan').format('YYYY-MM-DD_HH:mm:ss'));
    console.log(easternCurrentDate);
    let data = [];

    if (this.headerCheckboxChecked) {
      this.osPartsGrid.gridOptions.api.forEachNodeAfterFilter((node) => {
        data.push(node.data);
      });
    } else {
      data = this.getModifiedRowData();
    }

    if (data.length < 1) {
      this.spinner.hide();
      this.warningMessage = warningMessage.noRowsSelected;

      window.scroll(0, 0);
    } else {
      setTimeout(async () => {
        if (this.rowData.length > 0) {
          const exportData: any = [];

          data.forEach((e) => {
            const obj = {
              dock: e.dock,
              module_description: e.module_description,
              module_type: e.module_type,
              vanning_from: e.vanning_from,
              vanning_to: e.vanning_to,
              total_veh_qty: e.total_veh_qty,
              total_module_qty: e.total_module_qty,
              est_stocking_pace: parseFloat(e.est_stocking_pace),
              updated_dt: e.updateddt,
              updated_userid: e.updated_userid,
            };
            exportData.push(obj);
          });

          const headers = [
            'DOCK',
            'MODULE DESCRIPTION',
            'MODULE TYPE',
            'VANNING FROM',
            'VANNING TO',
            'NO OF VEHICLES VANNED',
            'NO OF MODULES',
            'CALCULATED PITCH',
            'UPDATED DT',
            'User',
          ];

          const timestamp = easternCurrentDate
            .split('.')[0]
            .replace('T', '_')
            .split('')
            .filter((d) => d !== '-' && d !== ':')
            .join('');

          this.excelService.exportAsExcelFile(exportData, 'Stockingpaceinquiry_' + timestamp, headers);
        }
        this.spinner.hide();
      }, 1000);
    }
  }
  consoleLog(element, console_for) {
    console.log(element, 'ms-1 - ' + console_for);
  }
}
