<div class="background">
  <div class="container-fluid">
    <div class="d-flex flex-row">
      <div class="p-2 flex-grow-1">
        <h2 class="heading"></h2>
      </div>

    </div>
    <div class="successMessage alert-dismissible">
      <div *ngIf="warningMessage" class="alert alert-warning" role="alert">
        {{ warningMessage }}
        <button type="button" class="btn-close" (click)="removeMessage()" data-bs-dismiss="alert"
          aria-label="Close"></button>
      </div>


      <div *ngIf="successMessage" class="alert alert-success" role="alert">
        {{ successMessage }}
        <button type="button" class="btn-close" (click)="removeMessage()" data-bs-dismiss="alert"
          aria-label="Close"></button>
      </div>
      
    </div>
  </div>
  <div class="container-fluid">
    <div class="row">
      <div class="col-3">
        <h2 class="heading">Production Progress</h2>
      </div>
      <div class="col-9">
        <!-- <button type="button" class="master-primary-btn" (click)="updateDevanPlan()">
          UPDATE DEVAN PLAN
        </button> -->

        <!-- <button *ngIf="addGetResponse.length>0" type="button" class="master-primary-btn" (click)="viewCart()">
            View Cart
          </button> -->
      </div>
    </div>
  </div>
  <!-- <div class="container-fluid">
    <div class="d-flex flex-row">
      <div class="p-2 flex-grow-1">
        <h2 class="heading">Production Progress</h2>
      </div>
      <div class="p-2">
        <button  type="button" class="search_btn" (click)="updateDevanPlan()">
          +update devan plan
        </button>
      </div>
    </div>
  </div> -->
  <div class="container-fluid">
    <div class="row">
      <div class="card">
        <div class="container-fluid">
          <div class="row">
            <div class="col-xl-1 col-md-2 col-sm-3">
              <div class="drpchildctrl">
                <span class="v1"></span>
                <label class="searchLable">DOCK</label>
                <p-dropdown [options]="dockList" [(ngModel)]="selectedDock" [filter]="true" [virtualScroll]="true"
                  [itemSize]="25" [showTransitionOptions]="'0ms'" placeholder="Select" optionLabel="dock"
                  [resetFilterOnHide]="true" [hideTransitionOptions]="'0ms'" autoWidth="true"
                  [filterBy]="'dock'"
                  [panelStyle]="{ width: '55%' }" 
                  [style]="{ width: '100%' }" (onChange)="onDockChange()" appendTo="body" scrollHeight="180px">
                </p-dropdown>
              </div>
            </div>

            <div class="col-xl-1 col-md-2 col-sm-3">
              <div class="drpchildctrl">
                <span class="v1"></span>

                <label class="searchLable">PROD DT FROM </label>
                <input [(ngModel)]="prodDate" [max]="prodDateTo" class="form-control-sm" type="date"
                  style="width:100px" />
              </div>
            </div>
            <div class="col-xl-1 col-md-2 col-sm-3">
              <div class="drpchildctrl">
                <label class="searchLable">PROD DT TO</label>
                <input [(ngModel)]="prodDateTo" [min]="prodDate" class="form-control-sm" type="date"
                  style="width:100px" />
              </div>
            </div>
            <div class="col-xl-1 col-md-2 col-sm-3 reset_btn">
              <label class="resetIcon" (click)="onResetDropDown()">
                <img src="./assets/images/Group 115380.png" alt="refresh icon" />
              </label>
              <label class="resetlabel" (click)="onResetDropDown()">Reset</label>
              <span class="vertical"></span>
            </div>
            <div class="col-xl-1 col-md-2 col-sm-3 search">
              <button class="search_btn" (click)="onSearch(0)">
                SEARCH
              </button>
            </div>
            <div class="col-xl-4 col-md-3 col-sm-4 ">
            </div>
            <!-- <div  class="col-xl-2 col-md-2 col-sm-3 tap">
                <label class="resetlabel1" >
                  <p style="color:blue" (click)="updateDevanPlan()">Update Devan Plan</p>
                </label>
              </div> -->
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="container-fluid">
    <div class="row">
      <div class="col-sm-6">
        <span class="edit_icon">
          <img
          *ngIf="access1.edit"
           (click)="onEditMode()" class="img-tool"
           src="./assets/images/edit.svg" 
           alt="edit button icon"
            title="Edit" />
          <img
              class="img-tool"
              (click)="exportAsXLSX()"
              src="./assets/images/download.svg"
              alt="download icon"
              title="Download"
            />
        </span>
      </div>

      <div class="col-sm-4">
        <div class="records">
          <div class="col-4 offset-11" >
            <button *ngIf="showButton" class="stock_btn" (click)="toggleExpandCollapse()" [disabled]="isButtonDisabled">{{ buttonLabel }}</button>
          </div>

        </div>
      </div>
      <div class="col-sm-2">
        <div class="records">

              <label class="page-drpdwn-label">RECORDS / PAGE:</label>
          <select class="page-drpdwn" (change)="onPageSizeChanged()" [(ngModel)]="paginationPageSize">
            <option value="10" selected>10</option>
            <option value="30">30</option>
            <option value="50">50</option>
            <option value="{{ rowData.length }}">All</option>
          </select>
        </div>
      </div>
    </div>
  </div>

  <div id="dvgrid" class="gridStyle">
    <ag-grid-angular class="table-outer" #osPartsGrid style="width: 100%; top: 1%; margin-bottom: 5%"
      class="ag-theme-balham" [columnDefs]="columnDefs" [rowData]="rowData" [rowSelection]="rowSelection"
      [gridOptions]="gridOptions" [pagination]="true" [paginationPageSize]="paginationPageSize" [context]="context"
      [suppressClickEdit]="suppressClickEdit" [frameworkComponents]="frameworkComponents"
      [tooltipShowDelay]="tooltipShowDelay" [defaultColDef]="defaultColDef" [groupDefaultExpanded]="groupDefaultExpanded"
      [masterDetail]="true" [detailRowAutoHeight]="true" [detailCellRendererParams]="detailCellRendererParams"
      [tooltipMouseTrack]="true" [class]="themeClass" [editType]="editType">
    </ag-grid-angular>
  </div>


  <div *ngIf="isEditMode" class="saveCancel">
    <button matRipple [matRippleCentered]="true" [matRippleUnbounded]="false" class="review_btn"
      (click)="ReviewChanges()">
      Review Changes
    </button>
    <button (click)="cancelEdit()" class="cancel_btn">Cancel</button>
  </div>

</div>
<ngx-spinner bdColor="rgba(51,51,51,0.8)" size="medium" color="#fff" type="ball-scale-multiple">
  <p class="loading">Loading... Please wait...</p>
</ngx-spinner>