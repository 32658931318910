<div *ngIf="data">
  <div class="row" style="border-bottom: 2px solid #ccc; margin-bottom: 10px">
    <div class="col-sm-11 movable">
      <h3
        class="head"
        cdkDrag
        cdkDragHandle
        cdkDragRootElement=".cdk-overlay-pane"
      >
        {{ data.mode | uppercase }} SHIFTS
      </h3>
    </div>
    

    <div class="col-sm-1">
      <button
        type="button"
        class="btn btn-dark btn-circle btn-xl"
        [mat-dialog-close]="true"
        data-dismiss="modal"
        aria-label="Close"
      >
        <span aria-hidden="true">×</span>
      </button>
    </div>
  </div>
  <form [formGroup]="addOrEditform">
    <div class="successMessage alert-dismissible">
      <div *ngIf="warningMessage" class="alert alert-warning" role="alert">
        {{ warningMessage }}
        <button
          type="button"
          class="btn-close"
          (click)="removeMessage()"
          data-bs-dismiss="alert"
          aria-label="Close"
        ></button>
      </div>
    
  
      <div *ngIf="successMessage" class="alert alert-success" role="alert">
      {{ successMessage }}
      <button
        type="button"
        class="btn-close"
        (click)="removeMessage()"
        data-bs-dismiss="alert"
        aria-label="Close"
      ></button>
      </div>
    </div>
    <div class="row">
      <div class="col-md-6">
        <div class="drpchildctrl">
          <span class="v1"></span>
          <label required class="searchLable">DOCK </label>

          <p-dropdown
            id="selSupplier"
            [filter]="true"
            [options]="data.dockList"
            [virtualScroll]="true" 
            [itemSize]="25"
            [showTransitionOptions]="'0ms'" 
            placeholder="Select" 
            formControlName="dock"
            optionLabel="dock" 
            optionValue="dock"
            [resetFilterOnHide]="true"
            [hideTransitionOptions]="'0ms'" 
            autoWidth="true" 
            [style]="{ width: '100%' }"
            scrollHeight="180px">
          >
          </p-dropdown>
          <ng-container *ngIf="addOrEdit.dock.errors && submitted">
            <small *ngIf="addOrEdit.dock.errors?.required">
              Dock is required.
            </small>
          </ng-container>
        </div>
      </div>
      <div class="col-md-6">
        <div class="drpchildctrl">
        </div>
      </div>
      <div class="col-md-6">
        <div class="drpchildctrl">
          <span class="v1"></span>
          <label required class="searchLable">SHIFT NUMBER</label>

          <p-dropdown
            id="selSupplier"
            [options]="shiftNumbers" 
            [filter]="true"
            [virtualScroll]="true"
            [itemSize]="30"
            placeholder="Select"
            optionLabel="value"
            optionValue="value"
            formControlName="shiftNumber"
            [resetFilterOnHide]="true"
            [hideTransitionOptions]="'0ms'"
            autoWidth="true"
            [style]="{ width: '100%' }"
            appendTo="body"
            scrollHeight="100px"




          >
          </p-dropdown>
        </div>
      </div>
      <div class="col-md-6">
        <div class="drpchildctrl">
          <label class="searchLable">DAY</label>

          <p-dropdown
            id="selSupplier"
            [options]="dayWeeks"
            [filter]="true"
            [virtualScroll]="true"
            [itemSize]="30"
            placeholder="Select"
            optionLabel="label"
            optionValue="value"
            formControlName="dayWeek"
            [resetFilterOnHide]="true"
            [hideTransitionOptions]="'0ms'"
            [style]="{ width: '100%' }"
            appendTo="body"
            scrollHeight="100px"
            [autoWidth]="false"

          >
          </p-dropdown>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-sm-6 col-lg-6" style="margin-top: 10px">
        <div class="drpchildctrl" style="width: 100%">
          <span class="v1"></span>
          <label class="searchLable">START TIME</label>
          <div>
            <select formControlName="startHoursHH" id="startHoursHH">
              <option value="" disabled selected>HH</option>
              <option *ngFor="let a of hoursArray; let index = index" [value]="index">
                {{ formatNumber(index) }}
              </option>
            </select>
            :
            <select formControlName="startMintuesMM" id="startMintuesMM">
              <option value="" disabled selected>mm</option>
              <option *ngFor="let a of mintuesArray; let index = index" [value]="index">
                {{ formatNumber(index) }}
              </option>
            </select>
          </div>
          <div>
            <ng-container *ngIf="addOrEdit.startHoursHH.errors && submitted">
              <small *ngIf="addOrEdit.startHoursHH.errors?.required">
                Start Time (hour) is required.
              </small>
            </ng-container><br>
            <ng-container *ngIf="addOrEdit.startMintuesMM.errors && submitted">
              <small *ngIf="addOrEdit.startMintuesMM.errors?.required">
                Start Time (minute) is required.
              </small>
            </ng-container>
          </div>
        </div>
      </div>

      <div class="col-sm-6" style="margin-top: 10px">
        <div class="drpchildctrl">
          <span class="v1"></span>
          <label for="timeSelect" class="searchLable">END TIME</label>
          <div>
            <select formControlName="endHoursHH" id="endHoursHH">
              <option value="" disabled selected>HH</option>
              <option *ngFor="let a of hoursArray; let index = index" [value]="index">
                {{ formatNumber(index) }}
              </option>
            </select>
            :
            <select formControlName="endMintuesMM" id="endMintuesMM">
              <option value="" disabled selected>mm</option>
              <option *ngFor="let a of mintuesArray; let index = index" [value]="index">
                {{ formatNumber(index) }}
              </option>
            </select>
          </div>
          <div>
            <ng-container *ngIf="addOrEdit.endHoursHH.errors && submitted">
              <small *ngIf="addOrEdit.endHoursHH.errors?.required">
                End Time (hour) is required.
              </small>
            </ng-container><br>
            <ng-container *ngIf="addOrEdit.endMintuesMM.errors && submitted">
              <small *ngIf="addOrEdit.endMintuesMM.errors?.required">
                End Time (minute) is required.
              </small>
            </ng-container>
          </div>
        </div>
      </div>
      
      <small *ngIf="errorMessage" class="error-message">{{ errorMessage }}</small>
      <div class="col-sm-6 col-lg-6" style="margin-top: 10px">
        <div class="drpchildctrl" style="width: 100%">
          <span class="v1"></span>
          <label class="searchLable">EFFECTIVE FROM</label>
          <input
            class="key"
            type="date"
            autoWidth="false"
            formControlName="effectiveFrom"
            [max]="effectiveTo" 
            (keydown)="onKeyPress($event)"
            maxlength="10"
            placeholder="--Type--"
            ng-required="true"
            (change)="onEffectiveFromChange($event)"
          />
          <ng-container *ngIf="addOrEdit.effectiveFrom.errors && submitted">
            <small *ngIf="addOrEdit.effectiveFrom.errors?.required">
              Effective From is required.
            </small>
          </ng-container>
        </div>
      </div>
      
      <div class="col-sm-6 col-lg-6" style="margin-top: 10px">
        <div class="drpchildctrl" style="width: 100%">
          <label class="searchLable">EFFECTIVE TO</label>
          <input
            class="key"
            autoWidth="false"
            type="date"
            formControlName="effectiveTo"
            [min]="effectiveFrom" 
            (keydown)="onKeyPress($event)"
            maxlength="10"
            placeholder="--Type--"
          />
        </div>
      </div>
      

      <div class="col-sm-6 col-lg-6" style="margin-top: 10px">
        <div class="drpchildctrl" style="width: 100%">
          <label class="searchLable">OVERRIDE NON-WORKING DAY IN MASTER CALENDAR?</label>
          <p-dropdown
            id="selSupplier"
            [options]="masterCalendar"
            [filter]="true"
            [virtualScroll]="true"
            [itemSize]="30"
            [showTransitionOptions]="'0ms'"
            placeholder="Select"
            optionLabel="value"
            optionValue="value"
            formControlName="inMasterCalendar"
            [resetFilterOnHide]="true"
            [hideTransitionOptions]="'0ms'"
            [autoWidth]="false"
            [style]="{ width: '100%' }"
            appendTo="body"
            scrollHeight="80px"
          >
          </p-dropdown>
        </div>
      </div>

      <div class="col-sm-6 col-lg-6" style="margin-top: 10px">
        <div class="drpchildctrl" style="width: 100%">
          <label class="searchLable">IN NEXT DAY?</label>
          <p-dropdown
            id="selSupplier"
            [options]="nextDay"
            [filter]="true"
            [virtualScroll]="true"
            [itemSize]="30"
            placeholder="Select"
            optionLabel="value"
            optionValue="value"
            formControlName="inNextDay"
            [resetFilterOnHide]="true"
            [hideTransitionOptions]="'0ms'"
            [style]="{ width: '100%' }"
            appendTo="body"
            scrollHeight="80px"
            [autoWidth]="false"

          >
          </p-dropdown>
        </div>
      </div>
    </div>
    <ng-container *ngIf="errorMessage1">
      <p style="color: red ;">{{ errorMessage1 }}</p>
    </ng-container>
  </form>
 
  <div class="text-right" style="margin-top: 20px">
    <button class="review_btn" mat-button (click)="callRespectiveFunction()">
      Save
    </button>

    <button class="cancel_btn" mat-button mat-dialog-close="cancel">
      Cancel
    </button>
  </div>
</div>
