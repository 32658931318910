
import { Component, OnInit, Inject, EventEmitter, ChangeDetectorRef, Output } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { PipeLookupService } from '../../services/pipe-lookup/pipe-lookup.service';
import { log } from 'console';
import { forkJoin, Subscription } from 'rxjs';
import { ModuleStockingService } from 'src/app/services/module-stocking/module-stocking.service';
import { LookupService } from 'src/app/services/lookup/lookup.service';

@Component({
  selector: 'app-module-type-edit-dialog',
  templateUrl: './module-type-edit-dialog.component.html',
  styleUrls: ['./module-type-edit-dialog.component.scss']
})

export class ModuleTypeEditDialogComponent implements OnInit {
  moduleTypeChanged = new EventEmitter();
  addMasterEventEmitter = new EventEmitter();
  addModuleMasterEventEmitter = new EventEmitter();
  editMasterEventEmitter = new EventEmitter();
  master_id: any;
  master_name: any;
  key_item: any;
  data_item: any;
  business_entity: any;
  new_key_item: any;
  cstatus: any;
  showaddpopup: boolean = false;
  showeditpopup: boolean = false;
  popupname: any;
  moduleType: any;
  moduleTypeDesc: any;
  line: any;
  dock: any =[];
  ospLinePath: any;
  trackingPoint: any;
  moduleTypeDockActive: any;
  dataItem: any;
  enableError: boolean = false;
  userForm: UntypedFormGroup;
  editMode:boolean =false;
  noteditMode:boolean=false;
  // selectedModuleType: any = [];
  selectedModuleType1: any = null;
  selectedDock: any =null;
  selectedLine:any = null;
  
  selectedModuleTypeAdd: any = null;
  moduleTypeToDockForpopup: any = [];
  newDockList: any = [];
  addOrEditform: UntypedFormGroup = this.formBuilder.group({
    dock: ['', Validators.required],
    module_type: ['', Validators.required],
    line: ['', Validators.required],
    linePathList: ['', Validators.required],
    tracking_point: ['', Validators.required],
    module_desc: ['', Validators.required],
    is_module_type_to_dock: ['', Validators.required],
  },
  );
  selectedModuleTypeText: any;
  parentData: any;
  lineList: any;
  filteredLineList: any[] = [];
  searchedValue: string = '';
  filteredTrackingPointList: any[] = [];
  filteredModuleTypeList: any[] = [];
  fb: any;
  isLine = false;
  isLinePath = false;



  constructor(
    @Inject(MAT_DIALOG_DATA) public data,
    private readonly dialogeRef: MatDialogRef<ModuleTypeEditDialogComponent>,
    private readonly formBuilder: UntypedFormBuilder,
    private cdr: ChangeDetectorRef,
    private readonly moduleStockingService: ModuleStockingService,
    private readonly lookupService: LookupService
  ) {
    this.parentData = data;
  }



  get addOrEdit() {
    return this.addOrEditform.controls;
  }


  ngOnInit(): void {
    console.log("this.parentData", this.parentData)
    if( this.parentData.mode ==='add'){
      this.noteditMode= true
      this.editMode=false
      //this.filteredLineList = [...this.parentData.lineList];
      this.filteredModuleTypeList = [...this.parentData.moduleTypeList];  
      this.addOrEditform.get('line')?.disable();
      this.addOrEditform.get('linePathList')?.disable();

    }
    else{
      this.noteditMode= false
      this.editMode=true

    }
    this.filteredTrackingPointList = [...this.parentData.trackingPointList];
    console.log("data---", this.data,);
    console.log("+++++++",this.addOrEditform)

    if (this.data?.mode === 'edit') {
      console.log("inside edit", this.data.data)
      // this.selectedModuleTypeAdd = { module_type: this.data.data?.module_type }
      // this.selectedDock = { dock: this.data.data?.dock }
      // this.selectedLine = { line: this.data.data?.line }

      // this.newDockList=[{dock:this.data.data.dock}]

      this.addOrEditform.patchValue({
        module_desc: this.data.data?.module_description,
        dock: this.data.data?.dock,
        line: this.data.data?.line,
        linePathList: this.data.data?.osp_line_path,
        is_module_type_to_dock: (this.data.data?.is_module_to_dock === true) ? 'Yes' : 'No' ,
        module_type : this.data.data?.module_type,
        tracking_point: {tracking_point : this.data.data?.tracking_point}
      });

      this.addOrEditform.get('dock')?.disable();
      this.addOrEditform.get('module_type')?.disable();
      this.addOrEditform.get('line')?.disable();
      this.addOrEditform.get('is_module_type_to_dock')?.disable();     
      this.addOrEditform.get('linePathList')?.disable();
    }
  }

  submitted: boolean = false;
  addMasterDetails() {
    console.log("adddata---", this.addOrEditform, this.addOrEdit);

    if (this.checkNull(this.addOrEdit.line.value)){
      this.isLine = true;
    }

    if (this.checkNull(this.addOrEdit.linePathList.value)){
      this.isLinePath = true;
    }

    if (this.checkNull(this.addOrEdit.line.value) || this.checkNull(this.addOrEdit.linePathList.value)){
      this.submitted = true;
      return;
    }

    if (this.addOrEditform.invalid) {
      this.submitted = true;
      return;
    }

    
    const addEmittedData = {
      dock: this.addOrEdit.dock.value.dock,
      module_type: this.addOrEdit.module_type.value.module_type,
      module_desc: this.addOrEdit.module_desc.value.trim(),
      line: this.addOrEdit.line.value,
      line_path: this.addOrEdit.linePathList.value,
      tracking_point: this.addOrEdit.tracking_point.value.tracking_point,
      is_module_type_to_dock: (this.addOrEdit.is_module_type_to_dock.value.value === 'Yes') ? true : false
    };
    console.log("addMaster--", this.addOrEdit, addEmittedData);

    this.addMasterEventEmitter.emit(addEmittedData);
    this.dialogeRef.close();
  }
  moduleMasterDetails() {
    console.log("moduleMasterDetails---", this.addOrEditform, this.addOrEdit);

    // if (this.addOrEditform.invalid) {
    //   this.submitted = true;
    //   return;
    // }

    const addModuleTypeEmittedData = {
      dock: this.addOrEdit.dock.value.moduleType,
    };
    // console.log("addMaster--",this.addOrEdit,addEmittedData);

    // this.addMasterEventEmitter.emit(addEmittedData);
    // this.dialogeRef.close();
  }
  editModuletype() {

    if (this.addOrEditform.invalid) {
      this.submitted = true;
      return;
    }
    console.log("this.addOrEditform", this.addOrEditform)
    const editEmittedData = {
      dock: this.addOrEdit.dock.value,
      module_type: this.addOrEdit.module_type.value,
      module_desc: this.addOrEdit.module_desc.value.trim(),
      line: this.addOrEdit.line.value,
      line_path: this.addOrEdit.linePathList.value,
      tracking_point: this.addOrEdit.tracking_point.value.tracking_point,
      is_module_type_to_dock: (this.addOrEdit.is_module_type_to_dock.value === 'Yes') ? true : false
    };
    this.editMasterEventEmitter.emit(editEmittedData);
    this.dialogeRef.close();

  }

  callRespectiveFunction() {
    if (this.data?.mode === 'edit') {
      console.log("edit function is called");

      this.editModuletype();
    } else {
      console.log("add function is called");

      this.addMasterDetails();
    }
  }
  onKeyPress(event: KeyboardEvent) {
    const allowedKeys = ['Backspace', 'Tab', 'ArrowLeft', 'ArrowRight', 'Delete'];
    const isNumber = /^[0-9]$/.test(event.key);

    // Allow number keys, allowed keys, and prevent default for others
    if (!isNumber && !allowedKeys.includes(event.key)) {
      event.preventDefault();
    }
  }

  moduleTypeChange() {
    // console.log(this.parentData.dockList);
    // console.log(typeof this.parentData.dockList);
    let dockInfo = [];
    let dockList = [];

    let data = {
      "module_type": this.addOrEditform.get('module_type')?.value?.module_type
    }
    // let res = this.pipeLookupService.getActiveSelectionModuleToDock(data);
    //console.log('Selected Module Type:', this.selectedModuleTypeAdd);

    const lookupSubscriptions = [
      this.moduleStockingService.getActiveSelectionModuleToDock(data),
      this.lookupService.getDockInfo(this.addOrEditform.get('module_type')?.value?.module_type)
    ];
    forkJoin(lookupSubscriptions).subscribe({
      next: (res) => {

        dockInfo = res[1]['body']['data'];

        if (dockInfo.length > 0){
          dockList = dockInfo.map(item => ({ dock: item.dock }));
          const line = dockInfo[0].line_no;
          const galcLine = dockInfo[0].galc_line;
  
          this.addOrEditform.patchValue({
            line: galcLine,
            linePathList : line
          });
  
          if (!this.checkNull(galcLine)){
            this.isLine = false;
          }
          if (!this.checkNull(line)){
            this.isLinePath = false;
          }

        }else{
          dockList = [];
          this.addOrEditform.patchValue({
            line: '',
            linePathList : ''
          });
        }
                  
        const count = parseInt(res[0]['body'][0].count);
        console.log(count);
        if (count > 0) {
          console.log(count);
          this.moduleTypeToDockForpopup = [{ value: 'No' }];
        }
        else {
          this.moduleTypeToDockForpopup = [{ value: 'Yes' }, { value: 'No' }];
        }
        if (res[0]['body'] != "No Content" && res[0]['body'] != '') {
          // Extract the dock values from sql_result
          const docksToRemove = res[0]['body'].map(item => item.dock);
          console.log(docksToRemove);
          
          // Remove those docks from dockList
          this.newDockList = dockList.filter(dock => !docksToRemove.includes(dock.dock));
          console.log(this.newDockList);          
        }
        else {
          this.newDockList = dockList;
          console.log(this.newDockList);
        }
        //set ddl if only one data in ddl
        if ( !this.checkNull(this.newDockList) && this.newDockList.length === 1){
          this.addOrEditform.patchValue({
            dock: this.newDockList[0]
          });
        }

      },
      // error: this.errorCallback,
    });
  }

  onFilter(event: any) {
    const query = event.filter;
    this.searchedValue = query;

    // Check if the query length is exactly 1 character
    if (query.length === 1) {
      // Check if the searched value exists in the list
      const isExisting = this.parentData.lineList.some(line => line.line.toLowerCase() === query.toLowerCase());

      if (!isExisting) {
        // If the searched value doesn't exist, add it temporarily to the list with a new class
        this.filteredLineList = [
          ...this.parentData.lineList,
          { line: query, isNew: true, newItemClass: 'new-item' } // Add new item with `newItemClass` for styling
        ];
      } else {
        // If the searched value exists, just filter the list based on the query
        this.filteredLineList = this.parentData.lineList.filter(line =>
          line.line.toLowerCase().includes(query.toLowerCase())
        );
      }
    } else {
      // If the query is not a single character, reset the filtered list to show only valid items with one character in `line`
      this.filteredLineList = this.parentData.lineList.filter(line => line.line.length === 1);
    }
  }

  onFilterTrackingPoint(event: any) {
    const query = event.filter;
    this.searchedValue = query;

    // Check if the query length is 2 characters
    if (!this.checkNull(query) && query.length === 2) {
      // Check if the searched value exists in the list
      const isExisting = this.parentData.trackingPointList.some(tracking_point => tracking_point.tracking_point.toLowerCase() === query.toLowerCase());

      if (!isExisting) {
        // If the searched value doesn't exist, add it temporarily to the list with a new class
        this.filteredTrackingPointList = [
          ...this.parentData.trackingPointList,
          { tracking_point: query, isNew: true, newItemClass: 'new-item' } // Add new item with `newItemClass` for styling
        ];
      } else {
        // If the searched value exists, just filter the list based on the query
        this.filteredTrackingPointList = this.parentData.trackingPointList.filter(tracking_point =>
          tracking_point.tracking_point.toLowerCase().includes(query.toLowerCase())
        );
      }
    } else if (!this.checkNull(query) && query.length === 1) {
      // If the query length is exactly 1 character, filter the list to show only items starting with that character
      this.filteredTrackingPointList = this.parentData.trackingPointList.filter(tracking_point =>
        tracking_point.tracking_point.toLowerCase().startsWith(query.toLowerCase()) // Matches items starting with the query
      );
    } else {
      // If the query is not a 1-character or 2-character string, reset the filtered list to show valid items with two characters in `tracking_point`
      this.filteredTrackingPointList = this.parentData.trackingPointList.filter(tracking_point => tracking_point.tracking_point.length === 2);
    }
  }


  onFilterModuleType(event: any) {
    const query = event.filter;
    this.searchedValue = query;

    // Check if the query length is 2 characters
    if (!this.checkNull(query) && query.length === 3) {
      // Check if the searched value exists in the list
      const isExisting = this.parentData.moduleTypeList.some(module_type => module_type.module_type.toLowerCase() === query.toLowerCase());

      if (!isExisting) {
        // If the searched value doesn't exist, add it temporarily to the list with a new class
        if (this.isFirstCharLetter(query) && this.isAlphanumeric(query)){

          this.filteredModuleTypeList = [
            ...this.parentData.moduleTypeList,
            { module_type: query.toUpperCase(), isNew: true, newItemClass: 'new-item' } // Add new item with `newItemClass` for styling
          ];
        }
      } else {
        // If the searched value exists, just filter the list based on the query
        this.filteredModuleTypeList = this.parentData.moduleTypeList.filter(module_type =>
          module_type.module_type.toLowerCase().includes(query.toLowerCase())
        );
      }
    } else if (!this.checkNull(query) && (query.length === 1 || query.length === 2)) {
      // If the query length is exactly 1 character, filter the list to show only items starting with that character
      this.filteredModuleTypeList = this.parentData.moduleTypeList.filter(module_type =>
        module_type.module_type.toLowerCase().startsWith(query.toLowerCase()) // Matches items starting with the query
      );
    } else {
      // If the query is not a 1-character or 2-character string, reset the filtered list to show valid items with two characters in `tracking_point`      
      this.filteredModuleTypeList = this.parentData.moduleTypeList.filter(f => f.module_type.length === 3);
    }
  }

  isFirstCharLetter(str: string): boolean {
    if (str.length === 0) return false;
    const charCode = str.charCodeAt(0);
    return (charCode >= 65 && charCode <= 90) || (charCode >= 97 && charCode <= 122);
  }

  checkNull(value: any): boolean {
    try {
  
      if (Object.prototype.toString.call(value) === '[object Array]') {
        return false;
      }
      else if (Object.prototype.toString.call(value) === '[object Object]') {
        return false;
      }
      else if (value === null || value === undefined || value.trim() === "" || value === 'undefined' || value === 'null') {
        return true;
      }
      else {
        return false;
      }
    } catch (error) {
      return false;
    }
  }

  isAlphanumeric(str) {
    if (!str || typeof str !== "string") return false; // Ensure valid input

    for (let i = 0; i < str.length; i++) {
      const charCode = str.charCodeAt(i);
      if (
        !(charCode >= 48 && charCode <= 57) &&  // 0-9
        !(charCode >= 65 && charCode <= 90) &&  // A-Z
        !(charCode >= 97 && charCode <= 122)    // a-z
      ) {
        return false;
      }
    }
    return true;
  }
}
