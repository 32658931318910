<div class="background">
  <div class="container-fluid">
    <div class="d-flex flex-row">
      <div class="p-2 flex-grow-1">
        <h2 class="heading">Container Ordering</h2>
      </div>
    </div>
  </div>
  <div class="successMessage alert-dismissible">
    <div *ngIf="successMessage" class="alert alert-success" role="alert">
      {{ successMessage }}
      <button
        type="button"
        class="btn-close"
        (click)="removeMessage()"
        data-bs-dismiss="alert"
        aria-label="Close"
      ></button>
    </div>
    <div *ngIf="warningMessage" class="alert alert-warning" role="alert">
      {{ warningMessage }}
      <button
        type="button"
        class="btn-close"
        (click)="removeMessage()"
        data-bs-dismiss="alert"
        aria-label="Close"
      ></button>
    </div>
  </div>
  <div class="container-fluid">
    <div class="row">
      <div class="card">
        <div class="container-fluid">
          <div class="row">
            <div class="col-xl-1 col-md-2 col-sm-6">
              <div class="drpchildctrl">
                  <span class="v1"></span>
                  <label class="searchLable" >DOCK</label>
                <p-dropdown [options]="dockList" [(ngModel)]="selectedDock" [filter]="true" [virtualScroll]="true"
                [itemSize]="25" [showTransitionOptions]="'0ms'" placeholder="--Select--" optionLabel="dock"
                [resetFilterOnHide]="true" [hideTransitionOptions]="'0ms'" autoWidth="true"
                [filterBy]="'dock'"
                [panelStyle]="{ width: '65%' }" 
                [style]="{ width: '100%' }" (onChange)="onDockChange()" appendTo="body" scrollHeight="180px">
              </p-dropdown>
                </div>
            </div>
            <div class="col-xl-1 col-md-2 col-sm-3">
              <div class="drpchildctrl">
               <span class="v1"></span>
                <label class="searchLable" title="Container Code">CC</label>
                <p-multiSelect
                  [(ngModel)]="selectedContainer"
                  [showToggleAll]="false"
                  (onChange)="onContainerChange()"
                  [options]="containerList"
                  optionLabel="cc"
                  [showTransitionOptions]="'0ms'"
                  [hideTransitionOptions]="'0ms'"
                  [defaultLabel]="'--Select--'"
                  autoWidth="true"
                  [style]="{ width: '100%' }"
                  [resetFilterOnHide]="true"
                >
                </p-multiSelect>
              </div>
            </div>
            <div class="col-xl-1 col-md-2 col-sm-6">
              <div class="drpchildctrl">
                  <span class="v1"></span>
                  <label class="searchLable" >RENBAN</label>
                  <p-multiSelect
                    [(ngModel)]="selectedrenban"
                    [showToggleAll]="false"
                    (onChange)="onRenbanChange()"
                    [options]="renbanList"
                    optionLabel="container_renban"
                    [showTransitionOptions]="'0ms'"
                    [hideTransitionOptions]="'0ms'"
                    [defaultLabel]="'--Select--'"
                    autoWidth="true"
                    [style]="{ width: '100%' }"
                    [resetFilterOnHide]="true"
                  >
                  </p-multiSelect>
                </div>
            </div>
            <div class="col-xl-1 col-md-2 col-sm-3">
              <div class="drpchildctrl">
                <span class="v1"></span>
                <label class="searchLable">VAN DT FROM</label>
                <input
                  [(ngModel)]="vanningFrom"
                  [max]="vanningTo"
                  class="form-control-sm"
                  type="date"
                  autoWidth="false"
                />
              </div>
            </div>
            <div class="col-xl-1 col-md-2 col-sm-3">
              <div class="drpchildctrl">
                <!-- <span class="v1"></span> -->
                <label class="searchLable">VAN DT TO</label>
                <input
                  [(ngModel)]="vanningTo"
                  [min]="vanningFrom"
                  class="form-control-sm"
                  type="date"
                  autoWidth="false"
                />
              </div>
            </div>
            <div class="col-xl-3 col-md-2 col-sm-6 reset_btn">
              <div class="drpchildctrl">
                <label class="resetIcon" (click)="onResetDropDown()">
                  <img src="./assets/images/Group 115380.png" alt="refresh icon" />
                </label>
                <label class="resetlabel" (click)="onResetDropDown()">Reset</label>
                <span class="vertical"></span>
                <button class="search_btn" (click)="onSearch(0, null)">SEARCH</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="container-fluid">
    <div class="row">
      <div class="col-4">
        <img
          class="img-tool"
          src="./assets/images/download.svg"
          alt="download icon"
          title="Download"
          (click)="exportAsXLSX()"
        />
      </div>
      <div class="col-4"></div>
      <div class="col-sm-4 parent">
        <div class="flex1">
          <button class="search_btn"
          *ngIf="access2"
          (click)="onSave()" [disabled]="disableSave">SAVE SEQ</button>
        </div>
        <div class="records flex2">
          <label class="page-drpdwn-label">RECORDS / PAGE:</label>
          <select
            class="page-drpdwn"
            (change)="onPageSizeChanged()"
            [(ngModel)]="paginationPageSize"
          >
            <option value="10" selected>10</option>
            <option value="30">30</option>
            <option value="50">50</option>
            <option value="{{ rowData.length }}">All</option>
          </select>
        </div>
      </div>
    </div>
  </div>

  <div id="dvgrid" class="gridStyle">
    <app-grid
      class="table-outer"
      #osPartsGrid
      [columnDefs]="columnDefs"
      [rowData]="rowData"
      [gridOptions]="gridOptions"
      [pagination]="pagination"
      [paginationPageSize]="paginationPageSize"
      [context]="context"
      [frameworkComponents]="frameworkComponents"
      [tooltipShowDelay]="tooltipShowDelay"
      [tooltipMouseTrack]="true"
      [editType]="editType"
      [rowSelection]="rowSelection"
      (selectionChanged)="onSelectionChanged()"
    >
    </app-grid>
  </div>
</div>

<ngx-spinner
  bdColor="rgba(51,51,51,0.8)"
  size="medium"
  color="#fff"
  type="ball-scale-multiple"
>
  <p style="font-size: 20px; color: white">Loading... Please wait...</p>
</ngx-spinner>
