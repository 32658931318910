<div class="row" style="border-bottom: 2px solid #ccc; margin-bottom: 10px">
  <div class="col-sm-11 movable">
    <h3
      class="head"
      cdkDrag
      cdkDragHandle
      cdkDragRootElement=".cdk-overlay-pane"
    >
      <strong>DELETE MODULE TYPE</strong> 
    </h3>
  </div>
  <div class="col-sm-1">
    <button
      type="button"
      class="btn btn-dark btn-circle btn-xl"
      [mat-dialog-close]="true"
      data-dismiss="modal"
      aria-label="Close"
    >  
      <span aria-hidden="true">×</span>
    </button>
  </div>
</div>
Are you sure you want to delete this module type?
<div class="text-right" style="margin-top: 20px">
  <button class="review_btn" mat-button mat-dialog-close="save">
    Delete
  </button>
  <button class="cancel_btn" mat-button mat-dialog-close="cancel">
    Cancel
  </button>
</div>
