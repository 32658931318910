<div class="background">
  <div class="successMessage alert-dismissible">
    <div class="successMessage alert-dismissible">
      <div *ngIf="warningMessage" class="alert alert-warning" role="alert">
        {{ warningMessage }}
        <button type="button" class="btn-close" (click)="removeMessage()" data-bs-dismiss="alert"
          aria-label="Close"></button>
      </div>
      <div *ngIf="successMessage" class="alert alert-success" role="alert">
        {{ successMessage }}
        <button type="button" class="btn-close" (click)="removeMessage()" data-bs-dismiss="alert"
          aria-label="Close"></button>
      </div>
    </div>
  </div>
  <div class="container-fluid">
    <div class="d-flex flex-row">
      <div class="p-2 flex-grow-1">
        <h2 class="heading">Module Type</h2>
      </div>
      <div class="p-2 addModuleType">
        <button type="button" *ngIf="access2" class="search_btn policy_btn" (click)="openDialog()">
          ADD NEW
        </button>
      </div>
    </div>
  </div>
  <div class="container-fluid">
    <div class="row">
      <div class="card">
        <div class="container-fluid">
          <div class="row">
            <div class="col-xl-1 col-md-2 col-sm-3">
              <div class="drpchildctrl">
                <span class="v1"></span>
                <label class="searchLable">MODULE TYPE</label>
                <p-multiSelect [(ngModel)]="selectedModuleType" [showToggleAll]="false" (onChange)="moduleTypeChange()"
                  [options]="moduleTypeList" [showTransitionOptions]="'0ms'" [hideTransitionOptions]="'0ms'"
                  [defaultLabel]="'--Select--'" [virtualScroll]="true" [itemSize]="25" autoWidth="false"
                  [style]="{ width: '100%' }" [resetFilterOnHide]="true" optionLabel="module_type"
                  [panelStyle]="{ width: '140%' }">
                </p-multiSelect>
              </div>
            </div>
            <div class="col-xl-1 col-md-2 col-sm-3">
              <div class="drpchildctrl">
                <span class="v1"></span>
                <label class="searchLable">LINE</label>
                <p-multiSelect [(ngModel)]="selectedLine" [showToggleAll]="false" (onChange)="lineChange()"
                  [options]="lineList" [showTransitionOptions]="'0ms'" [hideTransitionOptions]="'0ms'"
                  [defaultLabel]="'--Select--'" [virtualScroll]="true" [itemSize]="25" autoWidth="false"
                  [style]="{ width: '100%' }" [resetFilterOnHide]="true" optionLabel="line"
                  [panelStyle]="{ width: '140%' }">
                </p-multiSelect>
              </div>
            </div>
            <div class="col-xl-1 col-md-2 col-sm-3">
              <div class="drpchildctrl">
                <span class="v1"></span>
                <label class="searchLable">DOCK</label>
                <p-multiSelect [(ngModel)]="selectedDock" [showToggleAll]="false" (onChange)="dockChange()"
                  [options]="dockList" [showTransitionOptions]="'0ms'" [hideTransitionOptions]="'0ms'"
                  [defaultLabel]="'--Select--'" [virtualScroll]="true" [itemSize]="25" autoWidth="false"
                  [style]="{ width: '100%' }" [resetFilterOnHide]="true" optionLabel="dock"
                  [panelStyle]="{ width: '140%' }">
                </p-multiSelect>
              </div>
            </div>
            <!-- <div class="col-xl-1 col-md-2 col-sm-3">
                  <div class="drpchildctrl">
                    <label class="searchLable">CC</label>
                    <p-multiSelect
                      [(ngModel)]="selectedPartNo"
                      [showToggleAll]="false"
                      (onChange)="onPartNoChange($event)"
                      [options]="partNoList"
                      [showTransitionOptions]="'0ms'"
                      [hideTransitionOptions]="'0ms'"
                      [defaultLabel]="'--Select--'"
                      [virtualScroll]="true"
                      [itemSize]="25"
                      autoWidth="false"
                      [style]="{ width: '100%' }"
                      [resetFilterOnHide]="true"
                      optionLabel="item_id"
                      [panelStyle]="{ width: '140%' }"
                      [disabled]="disablePartNumber"
                      optionDisabled="disabled"
                      selected="false"
                    >
                    </p-multiSelect>
                  </div>
                </div> -->

            <div class="col-xl-3 col-md-2 col-sm-6 reset_btn">
              <div class="drpchildctrl">
                <label class="resetIcon" (click)="onResetDropDown()">
                  <img src="./assets/images/Group 115380.png" alt="refresh icon" />
                  Reset
                </label>
                <span class="vertical"></span>
                <button class="search_btn" (click)="onSearch(0)">SEARCH</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="container-fluid">
    <div class="row">
      <div class="col-sm-8">
        <!-- <img
              class="img-tool"
              src="./assets/images/edit.svg"
              alt="edit button icon"
              title="Edit" 
            />
            <img
              class="deleteIcon"
              src="./assets/images/icon-delete.svg"
              alt="edit button icon"
              title="Delete"
            /> -->
        <!-- <img
              class="img-tool"
              (click)="exportAsXLSX()"
              src="./assets/images/download.svg"
              alt="download icon"
              title="Download"
            /> -->
      </div>
      <div class="col-sm-2">
        <div class="records">
          <!-- <div class="drpchildctrl">
                <p-multiSelect #dd
                  class="page-drpdwn-label showhide"
                  [(ngModel)]="selectedShowHideList"
                  (onChange)="onShowHideChange($event)"
                  [options]="showHideList"
                  optionLabel="label"
                  [displaySelectedLabel]="false"
                  [defaultLabel]="'SHOW/HIDE COLUMN'"
                  [showTransitionOptions]="'0ms'"
                  [hideTransitionOptions]="'0ms'"
                  autoWidth="true"
                  [style]="{ width: '100%', background: '#F5F5F5' }"
                  [resetFilterOnHide]="true"
                >
                <p-footer>
                  <div class="flex justify-content-between p-1">
                    <button type="button" (click)="saveShowHide();dd.hide()" class="save_btn"> Save</button>
                    
                  </div>
                </p-footer>
                </p-multiSelect>
              </div> -->
        </div>
      </div>

      <div class="col-sm-2">
        <div class="records">
          <label class="page-drpdwn-label">RECORDS / PAGE:</label>
          <select class="page-drpdwn" (change)="onPageSizeChanged()" [(ngModel)]="paginationPageSize">
            <option value="10" selected>10</option>
            <option value="30">30</option>
            <option value="50">50</option>
            <option value="{{ rowData.length }}">All</option>
          </select>
        </div>
      </div>
    </div>
  </div>

  <div id="dvgrid" class="gridStyle">
    <!-- <ag-grid-angular
        style="width: 102%;"
        class="ag-theme-balham"
        #osPartsGrid
      [rowData]="rowData1"
      [columnDefs]="columnDefs"
      [defaultColDef]="defaultColDef"
      [groupDefaultExpanded]="groupDefaultExpanded"
      [masterDetail]="true"
      [detailCellRendererParams]="detailCellRendererParams"
      [detailRowAutoHeight]="true"
      ></ag-grid-angular> -->

    <ag-grid-angular class="table-outer" #osPartsGrid style="width: 100%; top: 1%; margin-bottom: 5%"
      class="ag-theme-balham" [columnDefs]="columnDefs" [rowData]="rowData" [rowSelection]="rowSelection"
      [gridOptions]="gridOptions" [pagination]="true" [paginationPageSize]="paginationPageSize" [context]="context"
      [suppressClickEdit]="suppressClickEdit" [frameworkComponents]="frameworkComponents"
      [tooltipShowDelay]="tooltipShowDelay" [defaultColDef]="defaultColDef" [masterDetail]="true"
      [tooltipMouseTrack]="false" [editType]="editType">
    </ag-grid-angular>

    <!-- <app-grid
          class="table-outer"
          #osPartsGrid
          [columnDefs]="columnDefs"
          [rowData]="rowData"
          [rowSelection]="rowSelection"
          [gridOptions]="gridOptions"
          [pagination]="pagination"
          [paginationPageSize]="paginationPageSize"
          [context]="context"
          [suppressClickEdit]="suppressClickEdit"
          [frameworkComponents]="frameworkComponents"
          [tooltipShowDelay]="tooltipShowDelay"
          [tooltipMouseTrack]="true"
          [editType]="editType"
          
        >
        </app-grid> -->
  </div>
</div>

<ngx-spinner bdColor="rgba(51,51,51,0.8)" size="medium" color="#fff" type="ball-scale-multiple">
  <p style="font-size: 20px; color: white">Loading... Please wait...</p>
</ngx-spinner>