export let formatOrderData = async (orderData) => {
    let sorted_orderData = await alphanumericSortByDockAndPartNo(orderData);
    return await formatData(sorted_orderData);
}

let formatData = async (orders: any) => {
    let previousPart_dock = ``;
    let part_index = -1;
    let returnJSON = {
        orderDetails: {},
        order_parts: []
    }
    orders.forEach((element: any) => {
        if (part_index == -1) {
            returnJSON.orderDetails = {
                'orderType': element.order_type?.trim(),
                'partType': element.part_type?.trim(),
                'managerName': element.manager_name?.trim(),
                'kvcName': element.kvc?.trim(),
                'budgetCodes': element.buco_code?.trim(),
                'orderDate': element.date_raised?.trim(),
                'raisedBy': element.userid?.trim(),
                'responsibilityregion': element.resp?.trim(),
                'air_order_no': element.air_order_no?.trim(),
                'business_entity': element.business_entity?.trim()
            }
        }
        const key = `${element.part_no}-${element.dock}`;
        if (previousPart_dock != key) {
            previousPart_dock = key;
            part_index++;
            returnJSON.order_parts.push(
                {
                    'destCode': element.dest_code?.trim(),
                    'volumesize': element.volume?.trim(),
                    'reason': element.reason?.trim(),
                    'secondComment': element.comment2?.trim(),
                    'firstComment': element.comment1?.trim(),
                    'dockNo': element.dock?.trim(),
                    'qpcBox': element.order_lot,
                    'costCalculation': element.cost_calc?.trim(),
                    'kanbanNo': element.kanban?.trim(),
                    'piecesToOrder': element.pieces_to_order,
                    'lotsToOrder': element.lots_to_order,
                    'estimatedCost': element.est_cost?.trim(),
                    'partNo': element.part_no?.trim(),
                    'totalOrder': element.total_order,
                    'airCode': element.air_case_code?.trim(),
                    'part_description': element.part_desc?.trim(),
                    'cubicDimension': element.box_volume,
                    'boxWeight': element.box_weight,
                    'vanConfirmation': element.kvc_conf?.trim(),
                    'psetainfo':element.psetainfo?.trim(),
                    'schedules': []
                }
            );
        }
        returnJSON.order_parts[part_index].schedules.push(
            {
                'partIndex': part_index,
                'arrivalDate': element.arrival_date?.trim(),
                'deadlineDate': element.deadline_date?.trim(),
                'noOfPieces': element.number_of_pieces,
                'noOfLots': element.number_of_lots,
                'configurePieces': element.conf_pieces,
                'configureLots': element.conf_lots,
                'etaDate': element.eta_date?.trim(),
                'casenoEditable': element.case_no,
                'orderstatus': element.air_order_status?.trim() === "ARRIVED",
                'sched_id': parseInt(element.sched_id)
            }
        );

    });
    return returnJSON;
}
function reduceDataByPartNoAndDock(data) {
    return data.reduce((acc, item) => {
        const key = `${item.part_no}-${item.dock}`;

        if (!acc[key]) {
            acc[key] = {
                business_entity: item.business_entity?.trim,// "02",
                plant: item.plant?.trim,// null,
                air_order_no: item.air_order_no?.trim,// "02000215",
                pieces_to_order: item.pieces_to_order?.trim,// null,
                lots_to_order: item.lots_to_order?.trim,// 14,
                reason: item.reason?.trim,// "Sample",
                part_type: item.part_type?.trim,// "Production",
                order_type: item.order_type?.trim,// "Lots",
                resp: item.resp?.trim,// "Plant",
                order_lot: item.order_lot?.trim,// 18,
                volume: item.volume?.trim,// "Pipeline            ",
                air_case_code: item.air_case_code?.trim,// "T",
                arrival_date: item.arrival_date?.trim,// "02/26/2025",
                part_desc: item.part_desc?.trim,// "TRANSCEIVER, TELEPHONE",
                buco_code: item.buco_code?.trim,// "MWEST",
                comment1: item.comment1?.trim,// "comment",
                comment2: item.comment2?.trim,// "reasom",
                cost_calc: item.cost_calc?.trim,// "Weight    ",
                dest_code: item.dest_code?.trim,// "104S",
                date_raised: item.date_raised?.trim,// "02/19/2025",
                kvc_conf: item.kvc_conf?.trim,// "No ",
                dock: item.dock?.trim,// "H7",
                total_order: item.total_order?.trim,// 252,
                est_cost: item.est_cost?.trim,// "0.00",
                userid: item.userid?.trim,// "Rameshkumar Subbiah (TMNA)    ",
                box_volume: item.box_volume?.trim,// null,
                date_time: item.date_time?.trim,// "02/19/2025 04:29:38",
                number_of_pieces: item.number_of_pieces?.trim,// null,
                number_of_lots: item.number_of_lots?.trim,// "6",
                manager_name: item.manager_name?.trim,// "Matt Tooley",
                kvc: item.kvc?.trim,// "Kurimoto san",
                part_no: item.part_no?.trim,// "867414815000",
                kanban: item.kanban?.trim,// "0009",
                box_weight: item.box_weight?.trim,// null,
                case_no: item.case_no?.trim,// "R1",
                conf_lots: item.conf_lots?.trim,// null,
                conf_pieces: item.conf_pieces?.trim,// null,
                air_order_status: item.air_order_status?.trim,// "CREATED",
                eta_date: item.eta_date?.trim,// null,
                deadline_date: item.deadline_date?.trim,// "02/27/2025"
                // Add other properties you want to aggregate or keep
            };
        }
        return acc;
    }, {});
}
export let alphanumericSortByDockAndPartNo = async (arr) => {
    // function alphanumericSortByDockAndPartNo(arr) {
    return arr.sort((a, b) => {
        // First, compare by dock
        if (a.dock !== b.dock) {
            return a.dock.localeCompare(b.dock);
        }

        // If docks are the same, compare by PartNo
        const regex = /(\d+)|(\D+)/g;
        const aParts = a.part_no.match(regex);
        const bParts = b.part_no.match(regex);

        for (let i = 0; i < Math.max(aParts.length, bParts.length); i++) {
            const aPart = aParts[i] || '';
            const bPart = bParts[i] || '';

            if (aPart !== bPart) {
                const aIsNumber = !isNaN(aPart);
                const bIsNumber = !isNaN(bPart);

                if (aIsNumber && bIsNumber) {
                    return parseInt(aPart) - parseInt(bPart);
                } else {
                    return aPart.localeCompare(bPart);
                }
            }
        }

        return 0;
    });
}