import { Component,ChangeDetectorRef } from '@angular/core';
import { debounceTime } from 'rxjs/operators';
import { Subject } from 'rxjs';

@Component({
  selector: 'app-actual-arrival-editable',
  template: `
  <div style="margin: 3px 0px 6px;">
    <input #input type="number" [(ngModel)]="value" class="grid-cell-edit-layout " (input)="handleKeydown()" (wheel)="checknegativevalue($event)"  (change)="textUpdated()" (keydown)="onKeyPress($event)"/>
    <small *ngIf="!value && params.context.componentParent.submitted" class="error"># of pieces is required</small>
</div>
  `,
  styles: [
    `
      input::-webkit-outer-spin-button,
      input::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
      }
      .grid-cell-edit-layout {
        width: 100%;
        border: 1px solid #d1d1d1;
      }
      .invalid-text {
        border: 2px solid red !important;
      }
      .error{color:red}
    `,
  ],
})
export class NoOfPiecesEditableComponent {
  public params: any;
  showInput = true;
  value: any;
  isEmpty = true;

  lastDayOfVanMonth = localStorage.getItem('lastDayOfVanMonth');

  private inputSubject = new Subject<void>();
  constructor(private cdr: ChangeDetectorRef) {
    this.inputSubject.pipe(debounceTime(300)).subscribe(() => {
      this.checkNonworkingday(this.params.data.partIndex);
    });
  }

  agInit(params: any): void {
    params.data.noOfPieces = this;
    if (params.column === 'number_of_pieces') {
      this.value = params.data.number_of_pieces;
      // if (this.value > 0) {
      //   this.isEmpty = false;
      // } else {
      //   this.isEmpty = true;
      // }
    }

    this.params = params;
  }

  // called when the cell is refreshed
  refresh(params: any): boolean {
    this.params = params;
    return true;
  }
  errorMsg = null;
  handleKeydown(){
    this.textUpdated()
    this.checkNonworkingday(this.params.data.partIndex);
  }
  textUpdated() {
    // this.params.context.componentParent.updateSchedulecount(this.params.data.partIndex);
    if (this.params.column === 'number_of_pieces') {
      if (this.value !== this.params.data.number_of_pieces) {
        this.params.data.number_of_pieces = this.value;
        this.params.data.isUpdated = true;
      }
    }
    // this.params.context.componentParent.updateCountMatch(this.params.data.partIndex);
    // this.params.context.componentParent.updateSchedulecount(this.params.data.partIndex);
    // this.updateSchedulecount(this.params,this.value);
  }
  checkNonworkingday(part_index): void {
    this.params.context.componentParent.validatecountMatch(part_index);
    this.cdr.detectChanges(); // Trigger change detection
  }
  // updateSchedulecount(part_index, value): void {
  //   let validDate: string = this.params.context.componentParent.updateSchedulecount(this.params.data.partIndex);
  //   if (validDate) {
  //     this.errorMsg = validDate;
  //     this.params.data.schedule_error = true;      
  //     this.value = null;
  //     this.params.data.arriving_date = null;
  //   } else {
  //     this.errorMsg = null;
  //   }
  // }

  // onKeyPress(event) {
  //   if (event.target.value.length <= 4) {
  //     return true;
  //   } else {
  //     return false;
  //   }
  // }
  checknegativevalue(event: WheelEvent) {
      event.preventDefault();
  }
  onKeyPress(event: KeyboardEvent) {
    const allowedKeys = ['Backspace', 'Tab', 'ArrowLeft', 'ArrowRight', 'Delete'];
    const isNumber = /^[0-9]$/.test(event.key);

    // Access the input element
    const inputElement = event.target as HTMLInputElement;

    // Check if the current value plus the new key would exceed the max length
    const currentValue = inputElement.value;
    if (currentValue.length >= 5 && isNumber) {
      event.preventDefault(); // Prevent input if max length is reached
      return;
    }

    // Allow number keys, allowed keys, and prevent default for others
    if (!isNumber && !allowedKeys.includes(event.key)) {
      event.preventDefault();
    }
  }
}
