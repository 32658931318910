<div class="background">
  <div #formTop></div>

  <div class="p-2 messageBackground reset_btn">
    <label class="resetIcon" (click)="goBack()">
      <img src="./assets/images/back arrow.png" alt="bell icon" class="bell" />
    </label>

    <label class="message calendarFont" (click)="goBack()"> BACK</label>
  </div>

  <div>
    <div class="d-flex flex-row">
      <div class="p-2 flex-grow-1">
        <h2 class="heading" *ngIf="!for_edit">Enter New Air Freight</h2>
        <h2 class="heading" *ngIf="for_edit">Air Freight (Order No:
          {{addOrEditform.get('orderDetails.air_order_no').value}})</h2>
      </div>
      <div class="p-2">
        <button class="cancel_btn configButton" style="margin-right: 25px !important" (click)="ConfigureParameter()">
          CONFIGURE PARAMETER
        </button>
      </div>
      <!-- <div class="p-2">
        <button class="cancel_btn1" >Cancel</button>
      </div>
      <div class="p-2">
        <button class="review_btn SaveButton" (click)="saveOrder()">Save</button>
      </div> -->
    </div>
    <div class="successMessage alert-dismissible">
      <div *ngIf="warningMessage.length > 0" class="alert alert-warning" role="alert">
        <span *ngFor="let w of warningMessage; let w_i = index">
          <!-- {{w_i + 1}}.  -->
          {{ w }} <br>
        </span>
        <button type="button" class="btn-close" (click)="removeMessage()" data-bs-dismiss="alert"
          aria-label="Close"></button>
      </div>

      <div *ngIf="successMessage" class="alert alert-success" role="alert">
        {{ successMessage }}
        <button type="button" class="btn-close" (click)="removeMessage()" data-bs-dismiss="alert"
          aria-label="Close"></button>
      </div>
    </div>
    <form [formGroup]="addOrEditform" (ngSubmit)="onSubmit()">
      <fieldset formGroupName="orderDetails">
        <div class="card mb-3">
          <div class="card-body">
            <!-- 1st row -->
            <div class="row">
              <div class="col-4 col-lg-3 ">
                <!-- <span class="v1"></span> -->
                <label class="searchLable">ORDER TYPE</label>
                <p-dropdown [filter]="true" [virtualScroll]="true" [itemSize]="10" [options]="toOrder"
                  optionLabel="order_type" placeholder="Select" formControlName="orderType" [style]="{ width: '100%' }"
                  scrollHeight="100px" (onChange)="onLotsPiecesChange($event)" [appendTo]="'body'" [autoWidth]="false"
                  filterBy="order_type"></p-dropdown>
              </div>
              <div class="col-4 col-lg-3 ">
                <label required class="searchLable">PART TYPE</label>
                <div class="radio-group">
                  <label class="radio">
                    <input formControlName="partType" value="Production" (onChange)="partTypeChange()" type="radio" />
                    <span class="radio-label">Production</span>
                  </label>
                  <label class="radio">
                    <input formControlName="partType" value="Trial" (onChange)="partTypeChange()" type="radio" />
                    <span class="radio-label">Trial</span>
                  </label>
                </div>
              </div>
            </div>
            <!-- 2st row -->
            <div class="row">
              <div class="col-4 col-lg-3">
                <label class="searchLable">MANAGER</label>
                <p-dropdown [filter]="true" [virtualScroll]="true" [itemSize]="10" placeholder="Select"
                  [style]="{ width: '100%' }" scrollHeight="100px" [options]="managerDetails" optionLabel="contact_name"
                  formControlName="managerName" (onChange)="getMangerIdByNameAndRole()" [appendTo]="'body'"
                  [autoWidth]="false" filterBy="contact_name"></p-dropdown>
              </div>
              <div class="col-4 col-lg-3">
                <label class="searchLable">VAN CENTER CONTACT</label>
                <p-dropdown [filter]="true" [virtualScroll]="true" [itemSize]="10" placeholder="Select"
                  [style]="{ width: '100%' }" scrollHeight="100px" [options]="kvcDetails" formControlName="KvcName"
                  optionLabel="contact_name" (onChange)="getKvcIDdByNameAndRole()" [appendTo]="'body'"
                  [autoWidth]="false" filterBy="contact_name"></p-dropdown>
              </div>
              <div class="col-4 col-lg-3">
                <label class="searchLable">BUDGET CODE</label>
                <p-dropdown [filter]="true" [virtualScroll]="true" [itemSize]="10" placeholder="Select"
                  [style]="{ width: '100%' }" scrollHeight="100px" [options]="addBudgetCodes" optionLabel="budget_code"
                  formControlName="budgetCodes" [appendTo]="'body'" [autoWidth]="false"
                  filterBy="budget_code"></p-dropdown>
              </div>
            </div>
            <!-- Row no 3 -->
            <div class="row">
              <div class="col-4 col-lg-3 ">
                <!-- <span class="v1"></span> -->
                <label class="searchLable">ORDER DT</label>
                <input class="key" type="date" [min]="minDate" formControlName="orderDate" [readOnly]="true"
                  [tabindex]=-1 />
              </div>
              <div class="col-4 col-lg-3 ">
                <label class="searchLable">RAISED BY</label>
                <input formControlName="raisedBy" class="key" type="text" placeholder="--Type--" readonly
                  [tabindex]=-1 />
              </div>
              <div class="col-4 col-lg-3 ">
                <label required class="searchLable">RESPONSIBILITY</label>
                <div class="radio-group">
                  <label class="radio">
                    <input formControlName="responsibilityregion" value="Plant" type="radio" />
                    <span class="radio-label"> Plant</span>
                  </label>
                  <label class="radio">
                    <input formControlName="responsibilityregion" value="TMC" type="radio" />
                    <span class="radio-label">TMC</span>
                  </label>
                </div>
              </div>
            </div>
          </div>
        </div>
      </fieldset>

      <fieldset formArrayName="obj_parts">
        <div class="container-fluid">
          <mat-accordion>
            <mat-expansion-panel *ngFor="let p of obj_parts?.controls; let i = index" [formGroupName]="i"
              [expanded]="isExpanded[i]">
              <mat-expansion-panel-header>
                <mat-icon>
                  {{ isExpanded[i] ? "expand_more" : "chevron_right" }}
                </mat-icon>
                <span *ngIf="!for_edit">PART {{ i + 1 }}</span>
                <span *ngIf="for_edit && addOrEditform.get('orderDetails.partType').value == 'Production' ">
                  Part:{{obj_partsByIndex(i).get('partNo').value?.item_id}} (Kanban: {{obj_partsByIndex(i).get('kanbanNo').value?.kanban}})
                </span>
                <span *ngIf="for_edit && addOrEditform.get('orderDetails.partType').value == 'Trial'">
                  Part:{{obj_partsByIndex(i).get('partNo').value}} (Kanban: {{obj_partsByIndex(i).get('kanbanNo').value}})
                </span>
                <img class="detailiconhome" src="assets/images/icon-delete.svg" class="expansion-header-image"
                  (click)="removeParts(i)" title="delete" alt="Delete" />
              </mat-expansion-panel-header>
              <!-- <div class="row"
                *ngIf="payload != null && payload.payloadArray[i] && payload.payloadArray[i].warnings.length > 0">
                <small clas="red" *ngFor="let error_ of payload.payloadArray[i].warnings; let v = index">
                  {{v+1}}. {{error_}}</small><br>
              </div> -->

              <!-- row No 1 part Level -->
              <div class="row mt-5px-mb-5px">
                <div class="col-4 col-lg-3 ">
                  <span class="v1"></span>
                  <label class="searchLable">DOCK </label>
                  <p-dropdown #dockNoDropdown [filter]="true" [virtualScroll]="true" [itemSize]="10"
                    [options]="addAirOrderDock[i]" optionLabel="dock" placeholder="Select" formControlName="dockNo"
                    [style]="{ width: '100%' }" scrollHeight="100px" (onChange)="onDockChange(i)" [appendTo]="'body'"
                    [autoWidth]="false" filterBy="dock"></p-dropdown>
                  <ng-container *ngIf="!obj_partsByIndex(i).get('dockNo').value && submitted">
                    <small>DOCK is required.</small>
                  </ng-container>
                </div>
                <div class="col-4 col-lg-3 ">
                  <span class="v1"></span>
                  <label class="searchLable">AIR CASE CODE </label>
                  <p-dropdown #aircaseInput [filter]="true" [virtualScroll]="true" [itemSize]="10"
                    [options]="addAirCaseCode[i]" placeholder="Select" formControlName="airCode"
                    [style]="{ width: '100%' }" scrollHeight="100px" optionLabel="air_case_code" [appendTo]="'body'"
                    [autoWidth]="false" filterBy="air_case_code"
                    [tabindex]="!obj_partsByIndex(i).get('airCode').value ? 0 : -1"></p-dropdown>
                  <ng-container *ngIf="!obj_partsByIndex(i).get('airCode').value  && submitted">
                    <small>AIR CASE CODE is required.</small>
                  </ng-container>
                </div>
                <div class="col-4 col-lg-3 ">
                  <span class="v1"></span>
                  <label class="searchLable">DEST CODE</label>
                  <p-dropdown [filter]="true" [virtualScroll]="true" [itemSize]="10" [options]="addAirOrderDestcode"
                    optionLabel="dest_code" placeholder="Select" formControlName="destCode" [style]="{ width: '100%' }"
                    scrollHeight="100px" [appendTo]="'body'" [autoWidth]="false" filterBy="dest_code"
                    [tabindex]="!obj_partsByIndex(i).get('destCode').value ? 0 : -1"></p-dropdown>
                  <ng-container *ngIf="!obj_partsByIndex(i).get('destCode').value && submitted">
                    <small>DEST CODE is required.</small>
                  </ng-container>
                </div>
              </div>
              <!-- row no 2 part level -->
              <div class="row">
                <div class="col-4 col-lg-3" *ngIf="addOrEditform.get('orderDetails.partType').value == 'Production'">
                  <span class="v1"></span>
                  <label class="searchLable">KANBAN</label>
                  <p-dropdown #kanbanNoInput [filter]="true" [virtualScroll]="true" [itemSize]="10"
                    [options]="addAirOrderKanban[i]" optionLabel="kanban" placeholder="Select"
                    formControlName="kanbanNo" [style]="{ width: '100%' }" scrollHeight="100px"
                    (onChange)="onKanbanChange(i)" [appendTo]="'body'" [autoWidth]="false" filterBy="kanban"
                    [tabindex]="!obj_partsByIndex(i).get('kanbanNo').value ? 0 : -1"></p-dropdown>
                  <ng-container *ngIf="!obj_partsByIndex(i).get('kanbanNo').value  && submitted">
                    <small>KANBAN is required.</small>
                  </ng-container>
                </div>
                <div class="col-4 col-lg-3" *ngIf="addOrEditform.get('orderDetails.partType').value == 'Trial'">
                  <span class="v1"></span>
                  <label class="searchLable">KANBAN</label>
                  <input class="key" type="text" placeholder="--Type--" formControlName="kanbanNo" maxlength="4"
                    minlength="4" [tabindex]="!obj_partsByIndex(i).get('kanbanNo').value ? 0 : -1" />
                  <ng-container *ngIf="!obj_partsByIndex(i).get('kanbanNo').value  && submitted">
                    <small>KANBAN is required.</small>
                  </ng-container>
                  <ng-container
                    *ngIf="obj_partsByIndex(i).get('kanbanNo').value && obj_partsByIndex(i).get('kanbanNo').value.length != 4 && submitted">
                    <small>Length KANBAN should be 4</small>
                  </ng-container>
                </div>
                <div class="col-4 col-lg-3" *ngIf="addOrEditform.get('orderDetails.partType').value == 'Production'">
                  <span class="v1"></span>
                  <label class="searchLable">PART NUMBER</label>
                  <p-dropdown [filter]="true" [virtualScroll]="true" [itemSize]="10" [options]="addAirOrderPart[i]"
                    optionLabel="item_id" placeholder="Select" formControlName="partNo" [style]="{ width: '100%' }"
                    scrollHeight="100px" (onChange)="onPartNoChange(i)" [appendTo]="'body'" [autoWidth]="false"
                    filterBy="item_id" [tabindex]="!obj_partsByIndex(i).get('partNo').value ? 0 : -1"></p-dropdown>
                  <ng-container *ngIf="!obj_partsByIndex(i).get('partNo').value  && submitted">
                    <small>PART NUMBER is required.</small>
                  </ng-container>
                </div>
                <div class="col-4 col-lg-3" *ngIf="addOrEditform.get('orderDetails.partType').value == 'Trial'">
                  <span class="v1"></span>
                  <label class="searchLable">PART NUMBER</label>
                  <input class="key" type="text" placeholder="--Type--" formControlName="partNo" maxlength="12"
                    minlength="12" [tabindex]="!obj_partsByIndex(i).get('partNo').value ? 0 : -1" />
                  <ng-container *ngIf="!obj_partsByIndex(i).get('partNo').value  && submitted">
                    <small>PART NUMBER is required.</small>
                  </ng-container>
                  <ng-container
                    *ngIf="obj_partsByIndex(i).get('partNo').value && obj_partsByIndex(i).get('partNo').value.length != 12 && submitted">
                    <small>Length of PART NUMBER should be 12</small>
                  </ng-container>
                </div>
                <div class="col-4 col-lg-3 ">
                  <label class="searchLable">PART DESCRIPTION</label>
                  <input class="key" type="text" placeholder="--Type--" formControlName="part_description"
                    [readOnly]="true" [tabindex]="-1" />
                </div>
              </div>
              <!-- row no 3 part level -->
              <div class="row">
                <div class="col-4 col-lg-3" *ngIf="!isLotsSelected">
                  <span class="v1"></span>
                  <label class="searchLable"># OF PIECES TO ORDER</label>
                  <input #piecesToOrderInput class="key" type="text" placeholder="--Type--" maxlength="5"
                    formControlName="piecesToOrder" (input)="handleKeydown('input1', $event,i)"
                    (keydown)="onKeyPress($event)"
                    [tabindex]="!obj_partsByIndex(i).get('piecesToOrder').value ? 0 : -1" />
                  <ng-container
                    *ngIf="(obj_partsByIndex(i).get('piecesToOrder').value ==null || obj_partsByIndex(i).get('piecesToOrder').value =='') && submitted">
                    <small># OF PIECES TO ORDER is required</small>
                  </ng-container>
                  <ng-container *ngIf="obj_partsByIndex(i).get('piecesToOrder').value !=null && obj_partsByIndex(i).get('piecesToOrder').value !='' && 
                  submitted && countMatch[i] == false">
                    <small># OF PIECES TO ORDER and sum of schedule's # of PIECES should be equal</small>
                  </ng-container>
                </div>
                <div class="col-4 col-lg-3" *ngIf="isLotsSelected">
                  <span class="v1"></span>
                  <label class="searchLable"># OF LOTS TO ORDER</label>
                  <input class="key" type="text" placeholder="--Type--" formControlName="lotsToOrder" maxlength="5"
                    (input)="handleKeydown('input1', $event,i)" (keydown)="onKeyPress($event)"
                    [tabindex]="!obj_partsByIndex(i).get('lotsToOrder').value ? 0 : -1" />
                  <ng-container
                    *ngIf="(obj_partsByIndex(i).get('lotsToOrder').value ==null || obj_partsByIndex(i).get('lotsToOrder').value =='') && submitted">
                    <small># OF LOTS TO ORDER is required</small>
                  </ng-container>
                  <ng-container *ngIf="obj_partsByIndex(i).get('lotsToOrder').value !=null && obj_partsByIndex(i).get('lotsToOrder').value !='' &&
                  countMatch[i] == false && submitted">
                    <small># OF LOTS TO ORDER and sum of schedule's # of LOTS should be equal</small>
                  </ng-container>
                  <!-- <small *ngIf="validNoOflots(partIndes)"># OF LOTS TO ORDER and sum of schedule's # of LOTS should be equal</small> -->
                </div>
                <div class="col-4 col-lg-3 ">
                  <label required class="searchLable">COST CALCULATION</label>
                  <div class="radio-group">
                    <label class="radio">
                      <input formControlName="costCalculation" value="Weight" type="radio"
                        (change)="handleCostCalculationChange(i,$event)" [tabindex]="-1" />
                      <span class="radio-label">Weight</span>
                    </label>
                    <label class="radio">
                      <input formControlName="costCalculation" value="Volume" type="radio"
                        (change)="handleCostCalculationChange(i,$event)" [tabindex]="-1" />
                      <span class="radio-label">Volume</span>
                    </label>
                  </div>
                </div>
                <div class="col-4 col-lg-3 ">
                  <label class="searchLable">TOTAL ORDER (In Pieces)</label>
                  <input class="key" type="text" placeholder="--Type--" formControlName="totalOrder" maxlength="10"
                    [readOnly]="true" [tabindex]="-1" />
                </div>
                <!-- <div class="col-4 col-lg-3 ">
                  <label required class="searchLable">Part Arrangement</label>
                  <div class="radio-group" style="gap: 82px;">
                    <label class="radio" style="width: auto;">
                      <input formControlName="partArrangement" value="ADD" type="radio" [tabindex] = "-1"/>
                      <span class="radio-label" style="width: max-content;">ADD</span>
                    </label>
                    <label class="radio">
                      <input formControlName="partArrangement" value="PIPE" type="radio" [tabindex] = "-1"/>
                      <span class="radio-label">PIPE</span>
                    </label>
                  </div>
                </div> -->
              </div>
              <!-- row no 4 part level -->
              <div class="row">
                <div class="col-4 col-lg-3" *ngIf="obj_partsByIndex(i).get('costCalculation').value == 'Volume'">
                  <span class="v1"></span>
                  <label class="searchLable">CUBIC DIMENSION</label>
                  <input class="key" type="text" formControlName="cubicDimension" placeholder="--Type--" maxlength="10"
                    (keydown)="allowonlytwodecimalandnumber($event)" (keyup)="calculateEstimationCost(i)"
                    [tabindex]="!obj_partsByIndex(i).get('cubicDimension').value ? 0 : -1" />
                  <ng-container *ngIf="!obj_partsByIndex(i).get('cubicDimension').value  && submitted">
                    <small>CUBIC DIMENSION is required</small>
                  </ng-container>
                </div>
                <div class="col-4 col-lg-3" *ngIf="obj_partsByIndex(i).get('costCalculation').value == 'Weight'">
                  <span class="v1"></span>
                  <label class="searchLable">BOX WEIGHT(KG)</label>
                  <input class="key" type="text" placeholder="--Type--" formControlName="boxWeight" maxlength="10"
                    (keydown)="allowonlytwodecimalandnumber($event)" (keyup)="calculateEstimationCost(i)"
                    [tabindex]="!obj_partsByIndex(i).get('boxWeight').value ? 0 : -1" />
                  <ng-container *ngIf="!obj_partsByIndex(i).get('boxWeight').value  && submitted">
                    <small>BOX WEIGHT(KG) is required</small>
                  </ng-container>
                </div>
                <div class="col-4 col-lg-3 ">
                  <label class="searchLable">ESTD COST (USD)</label>
                  <input class="key" type="text" maxlength="10" placeholder="--Type--" formControlName="estimatedCost"
                    readonly [tabindex]="-1" />
                </div>
                <div class="col-4 col-lg-3 ">
                  <!-- <span class="v1"></span> -->
                  <label class="searchLable">QPC</label>
                  <input class="key" type="text" placeholder="--Type--" formControlName="qpcBox" readonly
                    [tabindex]="-1" />
                  <ng-container
                    *ngIf="!obj_partsByIndex(i).get('qpcBox').value && addOrEditform.get('orderDetails.partType').value == 'Production' && submitted">
                    <small>KANBAN or PART NUMBER is required for QPC</small>
                  </ng-container>
                </div>

              </div>
              <!-- row no 5 part level -->
              <div class="row">
                <div class="col-4 col-lg-3 ">
                  <span class="v1"></span>
                  <label class="searchLable">REASON</label>
                  <p-dropdown [filter]="true" [virtualScroll]="true" [itemSize]="10" placeholder="Select"
                    [options]="addAirReason" optionLabel="reason_code" formControlName="reason"
                    [style]="{ width: '100%' }" scrollHeight="100px" [appendTo]="'body'" [autoWidth]="false"
                    filterBy="reason_code" [tabindex]="!obj_partsByIndex(i).get('reason').value ? 0 : -1"></p-dropdown>
                  <ng-container *ngIf="!obj_partsByIndex(i).get('reason').value  && submitted">
                    <small>REASON is required.</small>
                  </ng-container>
                </div>
                <div class="col-4 col-lg-3">
                  <label class="searchLable">COMMENT</label>
                  <input formControlName="firstComment" type="text" maxlength="250" placeholder="--Type--" class="key"
                    [tabindex]="!obj_partsByIndex(i).get('firstComment').value ? 0 : -1" />
                </div>
                <div class="col-4 col-lg-3 ">
                  <label required class="searchLable">Packing,Shipping & ETA Information</label>
                  <div class="radio-group" style="gap: 80px;">
                    <label class="radio" style="width: auto;">
                      <input formControlName="pSETAInfo" value="Long" type="radio" [tabindex]="-1" />
                      <span class="radio-label" style="width: max-content;">Long</span>
                    </label>
                    <label class="radio">
                      <input formControlName="pSETAInfo" value="Urgent" type="radio" [tabindex]="-1" />
                      <span class="radio-label">Urgent</span>
                    </label>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-4 col-lg-3">
                  <label class="searchLable">REASON EXPLANATION</label>
                  <input formControlName="secondComment" type="text" maxlength="250" placeholder="--Type--" class="key"
                    [tabindex]="!obj_partsByIndex(i).get('secondComment').value ? 0 : -1" />
                </div>
                <div class="col-4 col-lg-3 ">
                  <label required class="searchLable">VOLUME</label>
                  <div class="radio-group" style="gap: 20px;">
                    <label class="radio" style="width: auto;">
                      <input formControlName="volumesize" value="Additional Parts" type="radio" [tabindex]="-1" />
                      <span class="radio-label" style="width: max-content;">Additional Parts</span>
                    </label>
                    <label class="radio">
                      <input formControlName="volumesize" value="Pipeline" type="radio" [tabindex]="-1" />
                      <span class="radio-label">Pipeline</span>
                    </label>
                  </div>
                </div>
                <div class="col-4 col-lg-3 ">
                  <label required class="searchLable">VAN CENTER CONFIRMATION</label>
                  <div class="radio-group" style="gap: 30px;">
                    <label class="radio">
                      <input formControlName="vanConfirmation" value="Yes" type="radio" [tabindex]=" -1" />
                      <span class="radio-label">Yes</span>
                    </label>
                    <label class="radio">
                      <input formControlName="vanConfirmation" value="No" type="radio" [tabindex]=" -1" />
                      <span class="radio-label">No</span>
                    </label>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-10">
                  <p style="font-weight: bold; margin-top: 17px">
                    Required Arrival Schdules
                  </p>
                </div>
                <div class="col-2 detailiconhome">
                  <button type="button" class="cancel_btn arrival_btn" (click)="addArrival_schedules(i)">ADD ARRIVAL
                    DATE</button>
                </div>
              </div>
              <div class="row">
                <ag-grid-angular class="ag-theme-balham" #ospMorningGrid [columnDefs]="columnDefs"
                  style="width: 100%; height: 25vh" [gridOptions]="gridOptions" [rowData]="p.get('obj_schedules').value"
                  [tooltipMouseTrack]="true" [context]="context">
                </ag-grid-angular>
              </div>
            </mat-expansion-panel>
          </mat-accordion>
        </div>
      </fieldset>
      <fieldset>
        <div class="card mb-3" id="submit-btn">
          <div class="card-body">
            <button class="review_btn" type="submit" [disabled]="isSubmitting">Save</button>
            <button class="cancel_btn" type="button" (click)="onCancel()">Cancel</button>
            <button class="cancel_btn" type="button" (click)="addParts()" *ngIf="!for_edit">Add Part</button>
          </div>
        </div>
      </fieldset>
    </form>
  </div>
  <ngx-spinner bdColor="rgba(51,51,51,0.8)" size="medium" color="#fff" type="ball-scale-multiple">
    <p style="font-size: 20px; color: white">Loading... Please wait...</p>
  </ngx-spinner>
</div>