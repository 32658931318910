import { Component, OnInit, ViewChild } from '@angular/core';
import { GriddataService } from '../services/griddata.service';
import { Subscription, forkJoin } from 'rxjs';
import { GridRefreshComponent } from '../core/grid/grid-refresh/grid-refresh.component';
import { OSPStateService } from '../services/osp-state.service';
import { LookupService } from '../services/lookup/lookup.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { logErrors } from '../shared/logger';
import { HeaderCheckboxComponent } from '../core/grid/header-checkbox/header-checkbox.component';
import { RowCheckboxComponent } from '../core/grid/row-checkbox/row-checkbox.component';
import { PitchfreezingMsg, dateFilterComparator, resetSortingAndFilters } from '../constants';
import { UpdatedStockingPaceComponent } from './updated-stocking-pace.component';
import { StockingPaceServiceService } from '../services/stocking-pace-service.service';
import { ExcelService } from '../services/excel.service';
import { validateVanningDates, warningMessage } from '../constants';
import * as mm from 'moment-timezone';

import {
  MatLegacyDialog as MatDialog,
  MatLegacyDialogConfig as MatDialogConfig,
} from '@angular/material/legacy-dialog';
import { ConfirmCancelDailogComponent } from '../core/confirm-cancel-dailog/confirm-cancel-dailog.component';
import { OspService } from '../core/master-services/osp.service';
import {
  // dateFilterComparator,
  dateComparator,
  // resetSortingAndFilters,
  // warningMessage,
  successMessage,
  checkAccessPipe,
} from '../constants';

@Component({
  selector: 'app-stocking-pace-maintenance',
  templateUrl: './stocking-pace-maintenance.component.html',
  styleUrls: ['./stocking-pace-maintenance.component.scss'],
})
export class StockingPaceMaintenanceComponent implements OnInit {
  headerCheckboxChecked: any;
  // dateComparator: any;
  @ViewChild('osPartsGrid') osPartsGrid;
  isEditMode = false;
  editEnabled: boolean = false;
  defaultPageSize: any = 100000;
  offset: number = 0;
  user: any;
  userRole: any;
  dataCount: number = 0;
  stockingPaceSubscription$: Subscription;
  selectedDock: any = [];
  selectedModuleType: any = [];
  selectedVanMonth: any = [];

  columnDefs: any;
  rowData: any;
  rowSelection: any;
  gridOptions: any;
  pagination: any;
  paginationPageSize: any;
  context: any;
  suppressClickEdit: boolean;
  frameworkComponents: any;
  tooltipShowDelay: any;
  editType: any;
  search_form = {
    dock: '',
    module_type: '',
    van_month: '',
  };
  dockModuleList: any = [];
  dockList: any = [];
  vanMonthList: any = [];
  moduleTypeList: any = [];
  vanMonthWiseList: any = [];
  selectedVanMonthWise: any[];

  modalComponent = 'modal-component';
  editApiDefaultLimit = 200;
  timesEditApiCalled: number = 0;
  editApiStartPosition: number = 0;
  editApiEndPosition: number = this.editApiDefaultLimit;
  itContact: string;
  vanningFrom;
  vanningTo;
  planStockingDT;
  batchCheck = true;
  access1: { edit: boolean; matchmismatch: boolean; modulelevaltapping: boolean; };
  access2: boolean = false;

  constructor(
    private readonly gridDataService: GriddataService,
    private readonly stateService: OSPStateService,
    private readonly lookupService: LookupService,
    private readonly spinner: NgxSpinnerService,
    private readonly stockingService: StockingPaceServiceService,
    private readonly excelService: ExcelService,
    private readonly dialog: MatDialog,
    private readonly ospService: OspService
  ) {
    this.context = {
      componentParent: this,
    };
  }
  ngOnInit(): void {
    const access = checkAccessPipe();
    this.access1 = access.access;
    this.access2 = access.access.edit;


    this.selectedVanMonth = this.vanMonthWiseList[0];
    console.log('on Init call.');
    this.columnDefs = [
      {
        headerName: '',
        field: 'rowCheckBox',
        // suppressSorting: true,
        width: 46,
        headerComponentParams: {
          headerChecked: this.headerCheckboxChecked,
        },
        headerComponentFramework: HeaderCheckboxComponent,
        floatingFilterComponentFramework: GridRefreshComponent,
        floatingFilterComponentParams: { suppressFilterButton: true },

        cellRendererFramework: RowCheckboxComponent,
        floatingFilter: true,
      },
      {
        headerName: 'Module Type',
        field: 'module_type',
        width: 150,
        floatingFilter: true,
        headerTooltip: 'Module Type',
        tooltipField: 'duration',
      },
      {
        headerName: 'Renban',
        field: 'container_renban',
        width: 140,
        floatingFilter: true,
        tooltipField: 'name',
      },
      {
        headerName: 'Module',
        field: 'module_number',
        width: 140,
        floatingFilter: true,
      },
      {
        headerName: 'Van DT',
        field: 'vandt',
        width: 170,
        floatingFilter: true,
        sortable: true,

        comparator: dateComparator,
        filter: 'agDateColumnFilter',
        filterParams: {
          comparator: dateFilterComparator,
        },
      },
      {
        headerName: 'Scheduled Stocking DT',
        field: 'planstockingdt',
        width: 170,
        floatingFilter: true,
        sortable: true,
        comparator: dateComparator,
        filter: 'agDateColumnFilter',
        filterParams: {
          comparator: (filterLocalDateAtMidnight, cellValue) => {
            const cellDate = new Date(cellValue);
            if (cellDate < filterLocalDateAtMidnight) return -1;
            if (cellDate > filterLocalDateAtMidnight) return 1;
            return 0;
          }
        },
      },
      {
        headerName: 'Stocking Pace',
        field: 'est_stocking_pace',
        width: 170,
        floatingFilter: true,
        valueGetter: (params) => {
          return parseFloat(params.data.est_stocking_pace) || null;
        },
      },

      {
        headerName: 'Actual/Updated stocking Pace',
        field: 'modifiedStockingPace',
        width: 200,
        cellRendererFramework: UpdatedStockingPaceComponent,
        cellRendererParams: { column: 'modified_stocking_pace' },
        floatingFilter: true,
        valueGetter: (params) => {
          console.log(params.data.modifiedStockingPace?.value);
          const value =
            parseFloat(params.data.modifiedStockingPace?.value) || parseFloat(params.data.modified_stocking_pace);
          return isNaN(value) ? null : value; // Ensure returning numeric values or null
        },
      },

      {
        headerName: 'Updated DT',
        field: 'updateddt',
        width: 190,
        floatingFilter: true,
        headerTooltip: 'Updated Date(mm/dd/yyyy hh:mm:ss (Eastern))',
      },
      {
        headerName: 'User',
        field: 'updated_userid',
        width: 198,
        floatingFilter: true,
        tooltipField: 'userid',
      },
    ];

    this.rowSelection = 'multiple';
    this.editType = 'fullRow';
    this.gridOptions = this.gridDataService.getGridOptions();
    this.gridOptions.onGridReady = function (params) {
      this.gridApi = params.api;
    };

    this.stockingPaceSubscription$ = this.stateService.getNamc().subscribe((observable) => {
      this.initializeData();
    });
  }

  dateComparator(date1: Date, date2: Date): number {
    if (date1 < date2) {
      return -1; // date1 is earlier
    } else if (date1 > date2) {
      return 1; // date1 is later
    } else {
      return 0; // dates are equal
    }
  }

  namcValue = localStorage.getItem('namcvalue');
  userName = localStorage.getItem('userName');



  warningMessage: string;
  successMessage: string;

  removeMessage() {
    this.successMessage = '';
    this.warningMessage = '';
  }

  userCheckChanged(checked, rowIndex) {
    this.spinner.show();
    const rowNode = this.osPartsGrid.gridOptions.api.getDisplayedRowAtIndex(rowIndex);
    if (checked === true) {
      rowNode.setSelected(true);
      if (this.isEditMode) {
        rowNode.data.checkedEditMode = true;
      }
      rowNode.data.rowCheckBox = true;
    } else {
      rowNode.setSelected(false);
      rowNode.data.checkedEditMode = false;
      rowNode.data.rowCheckBox = false;
    }

    this.gridOptions.getRowStyle = function (params) {
      if (params.node.rowIndex === rowIndex) {
        const color = checked ? '#E4ECF0' : 'white';
        return { background: color };
      }
      return { background: 'white' };
    };

    rowNode.setData(rowNode.data);
    this.toggleHeaderCheckbox(checked);
    this.spinner.hide();
  }
  toggleHeaderCheckbox(checked) {
    if (!checked) {
      this.headerCheckboxChecked = false;

      this.osPartsGrid.gridOptions.api.refreshHeader();

      return;
    }

    const rowCount = this.osPartsGrid.gridOptions.rowData.length;
    const selectedRowCount = this.osPartsGrid.gridOptions.rowData.filter((x) => x.rowCheckBox === true).length;

    if (rowCount === selectedRowCount) {
      this.headerCheckboxChecked = true;

      this.osPartsGrid.gridOptions.api.refreshHeader();
    }
  }
  headerCheckChanged(event) {
    this.headerCheckboxChecked = event.checked;
    const headerChecked = event.checked;
    const isEditable = this.isEditMode;
    this.osPartsGrid.gridOptions.api.forEachNode(function (rowNode, index) {
      if (headerChecked) {
        if (isEditable) {
          rowNode.data.checkedEditMode = true;
        }
        rowNode.data.rowCheckBox = true;
      } else {
        rowNode.data.rowCheckBox = false;
        rowNode.data.checkedEditMode = false;
      }
      rowNode.setData(rowNode.data);
    });
  }

  onEditMode() {
    this.removeMessage();
    let data = [];

    data = this.getModifiedRowData();
    if (data.length > 0) {
      this.isEditMode = !this.isEditMode;
      this.editEnabled = this.isEditMode;

      if (this.isEditMode) {
        this.osPartsGrid.gridOptions.api.forEachNode(function (rowNode, index) {
          rowNode.data.checkedEditMode = true;
          if (!rowNode.data.rowCheckBox) {
            rowNode.data.checkedEditMode = false;
          }
          rowNode.setData(rowNode.data);
        });
      } else {
        this.osPartsGrid.gridOptions.api.forEachNode(function (rowNode, index) {
          if (rowNode.data.rowCheckBox) {
            rowNode.data.checkedEditMode = false;
          }
          rowNode.setData(rowNode.data);
        });
      }
      setTimeout(() => {
        window.scroll(0, document.body.scrollHeight);
      }, 100);
    } else {
      this.isEditMode = false;
      this.editEnabled = false;
      //this.warningMessage = warningMessage.selectRowsToEdit;
      window.scroll(0, 0);
    }
  }

  getModifiedRowData(): any {
    this.modifiedRowData = this.rowData.filter((row) => row.rowCheckBox);
    console.log(this.modifiedRowData);
    return this.modifiedRowData;
  }

  onResetDropDown() {
    this.removeMessage();
    this.editEnabled = false;
    this.isEditMode = false;

    this.selectedDock = [];
    this.selectedModuleType = [];
    this.selectedVanMonth = [];

    this.selectedModuleType.push({ module_type: 'ALL' });
    this.vanningFrom = '';
    this.vanningTo = '';
    if (this.osPartsGrid) {
      this.headerCheckboxChecked = false;
      this.osPartsGrid.gridOptions.api.refreshHeader();
      this.resetGrid();
    }

    this.setPageSizeToAll(this.defaultPageSize);

    this.rowData = [];

    this.offset = 0;
  }

  onSearch(offset) {
    //this.refreshView()
    if (this.osPartsGrid) {
      this.headerCheckboxChecked = false;
      this.osPartsGrid.gridOptions.api.refreshHeader();
      this.resetGrid();
    }
    this.removeMessage();
    this.editEnabled = false;
    this.isEditMode = false;

    if (offset === 0) {
      this.setPageSizeToAll(this.defaultPageSize);
      this.rowData = [];
      this.osPartsGrid.gridOptions.api.setRowData(this.rowData);
      const { valid, error } = validateVanningDates({ vanningFrom: this.vanningFrom, vanningTo: this.vanningTo, });
      if (!valid) {
        this.warningMessage = error;
        window.scroll(0, 0);
        this.spinner.hide();
        return;
      }
      this.offset = 0;
      this.spinner.show();
    }
    if (!this.checkIfAnySelected()) {
      this.rowData = [];
      let msg = `Please select`;
      let before = false;
      if (this.selectedModuleType.length == 0) {
        msg += ` MODULE TYPE`;
        before = true;
      }
      if (!this.vanningFrom) {
        if (before)
          msg += ` and`;
        msg += ` VAN FROM`;
        before  = true;

      }
      if (!this.vanningTo) {
        if (before)
          msg += ` and`;
        msg += ` VAN TO`;
        before  = true;

      }
      this.warningMessage = msg;

      window.scroll(0, 0);
      this.spinner.hide();
      return false;
    }

    let kanban;

    let selectedModuleType;
    let selectedVanMonth;

    if (this.selectedModuleType.length > 0) {
      if (!this.selectedModuleType.some((a) => a.module_type === 'ALL')) {
        selectedModuleType = [];
        this.selectedModuleType.forEach((element) => {
          selectedModuleType.push(element.module_type);
        });
      }
      if (this.selectedVanMonth.length > 0) {
        if (!this.selectedVanMonth.some((a) => a.vanning_month === 'ALL')) {
          selectedVanMonth = [];
          this.selectedVanMonth.forEach((element) => {
            selectedVanMonth.push(element.vanning_month);
          });
        }
      }
    }

    const data = {
      offset: offset,
      limit: 12000,
      business_entity: this.namcValue,
      workid: this.user,
      user_role: this.userRole,

      module_type: selectedModuleType,
      vanningFrom: this.vanningFrom,
      vanningTo: this.vanningTo,
      planStockingDT:this.planStockingDT
    };

    this.stockingService.getStockingPaceMaintenanceData(data).subscribe({
      error: this.errorCallback,
      next: (res) => {
        if (res.body && res.body.data) {
          this.rowData.push(...res.body.data);
          this.osPartsGrid.gridOptions.api.setRowData(this.rowData);
          this.dataCount = parseInt(res.body.rowCount);
          const records = this.dataCount - (this.offset + 1) * 12000;
          if (records >= 1) {
            this.offset++;
            this.onSearch(this.offset * 12000);
          } else {
            this.spinner.hide();
          }
        } else {
          this.rowData = [];
          this.spinner.hide();
        }

        this.setPageSizeToAll();
      },
    });
    return true;
  }
  resetGrid() {
    if (this.osPartsGrid) {
      resetSortingAndFilters(this.osPartsGrid);

      return;
    }
  }

  setPageSizeToAll(pageSize?) {
    this.paginationPageSize = this.rowData.length;

    if (pageSize) {
      this.paginationPageSize = 0;
      this.rowData.length = 0;
    }

    this.onPageSizeChanged();
  }

  checkIfAnySelected() {
    if (this.selectedModuleType.length > 0 && this.vanningFrom && this.vanningTo) {
      return true;
    }
    return false;
  }
  initializeData() {
    console.log('initialize data called');
    this.rowData = [];
    this.spinner.show();

    this.namcValue = localStorage.getItem('namcvalue');

    if (localStorage.getItem('workdayId')) {
      this.user = localStorage.getItem('workdayId');
    }
    if (localStorage.getItem('UserRoles')) {
      this.userRole = localStorage.getItem('UserRoles');
    }
    if (localStorage.getItem('UserName')) {
      this.userName = localStorage.getItem('UserName');
    }
    const access = checkAccessPipe();
    this.access1 = access.access;
    this.access2 = access.access.edit;


    this.loadDrop();
    this.onResetDropDown();
  }

  errorCallback = (error) => {
    logErrors(error);
    this.spinner.hide();

    this.warningMessage = 'Something went wrong! Please contact support team.';
    this.rowData = [];
    this.osPartsGrid.gridOptions.api.setRowData(this.rowData);
  };


  loadDrop() {
    var data = {
      business_entity: this.namcValue,
      workid: this.user,
      user_role: this.userRole,
    };
    const lookupSubscriptions = [
      this.stockingService.getMaintenanceModuleType(),
      this.stockingService.pitchBatchStatus(data),
    ];
    forkJoin(lookupSubscriptions).subscribe({
      next: (res) => {
        this.moduleTypeList = [];

        this.moduleTypeList.push({ module_type: 'ALL' });
        this.selectedModuleType = [];

        this.selectedModuleType.push({ module_type: 'ALL' });
        if (res[0]?.body) {
          // this.dockModuleList.forEach((item) => {
          //   this.moduleTypeList.push({ module_type: item.module_type });
          // });
          this.moduleTypeList.push(...res[0].body);
        }
        const status = res[1].body.data[0]?.status;
        console.log('status', status, res[1].body);

        if (status === 'RUNNING') {
          this.warningMessage = `${PitchfreezingMsg}`;
          console.log('status', this.warningMessage);

          this.spinner.hide();
          this.batchCheck = true;
        } else {
          this.warningMessage = '';
          this.batchCheck = false;
        }

        this.spinner.hide();
      },
      error: this.errorCallback,
    });
  }

  dockChange(): void {
    if (this.selectedDock.length >= 1) {
      if (this.selectedDock[this.selectedDock.length - 1].dock === 'ALL') {
        this.selectedDock = [];
        this.selectedDock.push(this.dockList[0]);
      } else {
        let indexAll = this.selectedDock.findIndex((data) => data.dock === 'ALL');

        if (indexAll > -1) {
          this.selectedDock.splice(indexAll, 1);
        }
      }
    }
  }
  onPageSizeChanged() {
    if (this.gridOptions?.api) {
      this.gridOptions.api.paginationSetPageSize(Number(this.paginationPageSize));
    }
  }

  onDockChange() {
    this.moduleTypeList = [];

    this.dockModuleList.forEach((element) => {
      if (element.dock === this.selectedDock) {
        this.moduleTypeList.push({ module_type: element.module_type });
      }
    });

    console.log('Updated Module Type List:', this.moduleTypeList);
  }

  exportAsXLSX(): void {
    this.removeMessage();

    this.spinner.show();

    const easternCurrentDate = String(mm().tz('US/Michigan').format('YYYY-MM-DD_HH:mm:ss'));
    console.log(easternCurrentDate);
    let data = [];

    if (this.headerCheckboxChecked) {
      this.osPartsGrid.gridOptions.api.forEachNodeAfterFilter((node) => {
        data.push(node.data);
      });
    } else {
      data = this.getModifiedRowData();
    }

    if (data.length < 1) {
      this.spinner.hide();
      this.warningMessage = warningMessage.noRowsSelected;

      window.scroll(0, 0);
    } else {
      setTimeout(async () => {
        if (this.rowData.length > 0) {
          const exportData: any = [];

          data.forEach((e) => {
            const obj = {
              module_type: e.module_type,
              container_renban: e.container_renban,
              module_number: e.module_number,
              vandt: e.vandt,
              est_stocking_pace: e.est_stocking_pace,
              modifiedStockingPace: e.modified_stocking_pace,
              updated_userid: e.updated_userid,
              updateddt: e.updateddt,
            };
            exportData.push(obj);
          });

          const headers = [
            'MODULE TYPE',
            'RENBAN',
            'MODULE',
            'VAN DT',
            'STOCKING PACE',
            'ACTUAL/UPDATED STOCKING PACE',
            'USER ID',
            'UPDATED DT',
          ];

          const timestamp = easternCurrentDate
            .split('.')[0]
            .replace('T', '_')
            .split('')
            .filter((d) => d !== '-' && d !== ':')
            .join('');

          this.excelService.exportAsExcelFile(exportData, 'Stockingpace-maintenance_' + timestamp, headers);
        }
        this.spinner.hide();
      }, 1000);
    }
  }

  openDialog1() {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.id = this.modalComponent;
    dialogConfig.height = '250px';
    dialogConfig.width = '470px';
    dialogConfig.data = 'ospipe';
    const dialogRef = this.dialog.open(ConfirmCancelDailogComponent, dialogConfig);
    dialogRef.afterClosed().subscribe((res) => {
      if (res === 'cancel') {
        this.columnDefs[7].cellStyle = function (params) {
          if ('modified' in params.data) {
            return { backgroundColor: '#E4ECF0' };
          } else {
            return { backgroundColor: 'white' };
          }
        };

        this.onSearch(0);
        this.editEnabled = false;
        this.isEditMode = false;
        this.osPartsGrid.gridOptions.api.refreshHeader();
      }
    });
  }
  cancelEdit(): void {
    this.removeMessage();
    this.openDialog1();
  }
  modifiedRowData = [];

  updatedRows = [];
  edit() {
    this.spinner.show();
    this.removeMessage();

    this.getBatchStatus(this.editAPIcall);
    // this.editAPIcall()
  }
  editAPIcall = async () => {
    var editedRecords = this.getModifiedRowData();

    this.updatedRows = editedRecords.filter((element) => element.isUpdated);

    if (this.updatedRows.length === 0) {
      this.warningMessage = warningMessage.editToReview;
      this.spinner.hide();
      window.scroll(0, 0);
      return;
    }

    console.log('this.get', this.updatedRows);
    if (this.updatedRows) {
      var updatedData = [];
      this.updatedRows.forEach((e) => {
        var updatedFields = {
          container_renban: e.container_renban,
          modified_stocking_pace: e.modified_stocking_pace,
          module_type: e.module_type,
          vanning_from: e.vanning_from,
          vanning_to: e.vanning_to,
          module_number: e.module_number,
          total_module_qty: e.total_module_qty,
          userid: this.userName,
        };
        updatedData.push(updatedFields);
      });

      this.spinner.show();
      this.editStocking(updatedData);
    }
  };
  onModuleTypeChange(): void {
    if (this.selectedModuleType.length >= 1) {
      if (this.selectedModuleType[this.selectedModuleType.length - 1].module_type === 'ALL') {
        this.selectedModuleType = [];
        this.selectedModuleType.push(this.moduleTypeList[0]);
      } else {
        let indexAll = this.selectedModuleType.findIndex((data) => data.module_type === 'ALL');

        if (indexAll > -1) {
          this.selectedModuleType.splice(indexAll, 1);
        }
      }
    }
  }
  editStocking(updatedData) {
    var data1 = {
      business_entity: this.namcValue,
      userid: this.userName,
      workid: this.user,
      user_role: this.userRole,
      data: updatedData.slice(this.editApiStartPosition, this.editApiEndPosition),
    };

    this.spinner.show();
    this.stockingService.stokingEdit(data1).subscribe({
      error: this.errorCallback,
      next: (response) => {
        console.log('response.body.data', response);

        const updatedRows = response.body.updatedRows;

        if (updatedData.length > this.editApiEndPosition) {
          this.timesEditApiCalled++;

          this.editApiStartPosition = this.timesEditApiCalled * this.editApiDefaultLimit;

          this.editApiEndPosition = this.editApiStartPosition + this.editApiDefaultLimit;
          return this.editStocking(updatedData);
        }

        this.spinner.hide();
        this.timesEditApiCalled = 0;
        this.editApiStartPosition = 0;
        this.editApiEndPosition = this.editApiDefaultLimit;

        if (updatedRows.length > 0) {
          this.successMessage = successMessage.recordUpdated;
          this.rowData = updatedRows;
        } else {
          this.warningMessage = `${warningMessage.reportsNotUpdated}  ${this.itContact}.`;
        }
        this.editEnabled = false;
        this.isEditMode = false;
        window.scroll(0, 0);
        this.setPageSizeToAll();
      },
    });
  }
  canDeactivate() {
    return this.editEnabled;
  }

  getBatchStatus(callback) {
    var data = {
      business_entity: this.namcValue,
      workid: this.user,
      user_role: this.userRole,
    };

    this.stockingService.pitchBatchStatus(data).subscribe({
      error: this.errorCallback,
      next: (res) => {
        const status = res.body.data[0]?.status;
        console.log('status', status, res.body);

        if (status === 'RUNNING') {
          this.warningMessage = `${PitchfreezingMsg}`;
          this.spinner.hide();
          this.batchCheck = true;
        } else {
          this.warningMessage = '';
          this.batchCheck = false;
          callback ? callback() : (this.batchCheck = false);
        }
      },
    });
  }
}
