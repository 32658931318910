import { Component, HostListener, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { HeaderCheckboxComponent } from '../core/grid/header-checkbox/header-checkbox.component';
import { GridRefreshComponent } from '../core/grid/grid-refresh/grid-refresh.component';
import { RowCheckboxComponent } from '../core/grid/row-checkbox/row-checkbox.component';
import { NgxSpinnerService } from 'ngx-spinner';
import { CustomTooltipComponent } from '../core/custom-tooltip/custom-tooltip.component';
import { GriddataService } from '../services/griddata.service';
import { ExcelService } from '../services/excel.service';
import * as moment from 'moment';
import * as XLSX from 'xlsx';
import * as mm from 'moment-timezone';
import { forkJoin, Subscription } from 'rxjs';
import { LookupService } from '../services/lookup/lookup.service';
import { checkAccessPipe, dateFilterComparator, getCellStyleConfig, getCellStyleConfig2, resetSortingAndFilters, validateVanningDates } from '../constants';

import { ProdProgressFinalEditComponent } from './edit-dialog/progress-grid-final-edit.component';

import { FluctuationAllowanceService } from '../services/fluctuation-allowance/fluctuation-allowance.service';

import { OSPStateService } from '../services/osp-state.service';
import { ProdprogressService } from '../services/production-progress/production-progress.service';

import {
  MatLegacyDialog as MatDialog,
  MatLegacyDialogConfig as MatDialogConfig,
} from '@angular/material/legacy-dialog';
import { ConfirmCancelDailogComponent } from '../core/confirm-cancel-dailog/confirm-cancel-dailog.component';
import { freezingMesssage } from '../constants';
import { ProgressEditDialogComponent } from './edit-dialog/edit-dialog.component';
import { warningMessage, successMessage } from '../constants';
import { logData, logErrors } from '../shared/logger';
import { AgGridAngular } from 'ag-grid-angular';
import { ICellRendererParams, IDetailCellRendererParams } from 'ag-grid-community';
import { updateDevanPlanComponent } from './confirmation-popup/delete-airorder/update-devanplan.component';
import { SetupActionsComponent } from '../core/setup-actions/setup-actions.component';
import { editCumulplanComponent } from './edit-dialog/edit-cumul-plannet.component';
import { EditCumulPlanNetComponent } from './edit-dialog/edit-popup/cumul-plannet-popup.component';
import { ProdProgressIntvolEditComponent } from './edit-dialog/progress-grid-intvol-edit-component';
import { recalculateComponent } from './recalculate/recalculate-component';
import { UpdateDevanPlanPPComponent } from './recalculate/update-devan-plan-component';
import { BulkadjustmentService } from '../services/bulk-adjustment/bulkadjustment.service';
import { EnvironmentConfiguration } from '../environment/services/environment.configuration';
import { OspService } from '../core/master-services/osp.service';
import { ContinuousScale } from 'ag-charts-community/dist/cjs/es5/sparklines-scale';

@Component({
  selector: 'app-production-progress-maintenance',
  templateUrl: './production-progress.component.html',
  styleUrls: ['./production-progress.component.scss'],
})
export class ProductionProgressComponent implements OnInit, OnDestroy {
  showDownloadUploadButtons: boolean = true;

  namcValue = '01';
  user = '';
  userRole = '';
  rowGroupingDisplayType = 'custom';
  public groupDefaultExpanded;
  rowData: any = [];
  @ViewChild('osPartsGrid') osPartsGrid;
  columnDefs;

  public defaultColDef;
  rowSelection;

  groupDisplayType = 'groupRows';
  autoGroupColumnDef;
  getDataPath;

  public detailCellRendererParams;
  msgNoContent = 'No Content';
  gridOptions;
  paginationPageSize;
  paginationNumberFormatter;
  pagination = 'true';
  context: any;
  editType;
  suppressClickEdit = true;
  tooltipShowDelay = 0;
  frameworkComponents = {
    customTooltip: CustomTooltipComponent,
    buttonRenderer: editCumulplanComponent,
    buttonRenderer2: recalculateComponent,
    buttonRenderer3: UpdateDevanPlanPPComponent,


  };
  editEnabled: boolean = false;
  freezeStatus: boolean = false;
  showToggleAll: boolean = false;
  showClear: boolean = true;
  isFinalupdated: string = "false";
  public themeClass: string =
    "ag-theme-balham";

  // Dropdowns
  selectedKanban: any = [];
  selectedPartNo: any = [];
  selectedDestCode: any = [];
  selectedContainer: any = [];
  selectedSupplier: any = [];
  selectedDock: any = [];
  selectedSpecialist: any = [];
  selectedLifecycle: any = [];
  successMessage = '';
  warningMessage = '';
  vanningFrom: any;
  vanningTo: any;

  kanbanListSelected = [];
  // Dropdown Options
  prodDate: any;
  prodDateTo: any;
  onInitprodDate: any;
  onIntprodDateTo: any;
  batchCheck = true;
  finalInialBatchCheck = false;
  freezingMesssage = '';
  dateFormat = 'YYYY-MM-DD';
  newDate = String(mm().tz('US/Michigan').format(this.dateFormat)).substring(0, 10);
  previousDate = String(mm().tz('US/Michigan').subtract(1, 'day').format(this.dateFormat)).substring(0, 10);
  sixDaysLater
  currentUtcDateTime = moment().utc(); // Example UTC date-time
  michiganDate = this.currentUtcDateTime.tz('US/Michigan');
  formattedDate1 = this.michiganDate.format('MM/DD/YYYY HH:mm:ss');



  dockList: any = [];
  specialistList: any = [];
  rowCount: number = 0;
  isEditMode: boolean = false;
  dataCount: number = 0;
  offset: number = 0;
  treeData

  showHideList: any = [
    // { value: 'vanning_date', label: 'Vanning DT' },
    { value: 'production_date', label: 'Production Date' },
    { value: 'vehicle_type', label: 'Module Type' },
    { value: 'shift', label: 'Shift' },
    { value: 'plan_vol', label: 'Plan Vol' },
    { value: 'int_plan_vol', label: 'Int Plan Vol' },
    { value: 'ALC', label: 'ALC' },
    { value: 'Final', label: 'final' },
    { value: 'plan_net', label: 'Plan Net' },
    { value: 'cumul_plan_net', label: 'Cumul Plan Net' },
    { value: 'date_time', label: 'Updated DT' },
    { value: 'userid', label: 'User' },
    { value: 'comment', label: 'Comments' },
  ];

  selectedShowHideList: any = [...this.showHideList];

  kanbanSelected = 1;
  disablePartNumber: boolean;
  disableKanban: boolean;
  // Dropdowns

  selectedLinePath: any = [];

  // Dropdown Options
  linePathList: any = [];

  page = 0;
  apiDefaultLimit = 5000;
  icons: { groupExpanded: string; groupContracted: string };
  commentsList: any = [];

  uploadError: boolean;
  uploadEnabled: boolean;
  namcName: string;
  files: any[];
  fileBuffer: any;

  exportData: any;

  minVanDate: any;
  maxVanDate: any;

  uploading: boolean = false;



  editApiDefaultLimit = 200;
  timesEditApiCalled: number = 0;
  editApiStartPosition: number = 0;
  editApiEndPosition: number = this.editApiDefaultLimit;
  batchRunLogID: any;
  headerHeight: number;
  loading: any;

  flalSubscription$: Subscription;
  headerCheckboxChecked: boolean = false;
  allowedFilesize: number;
  onInitVanningFrom: any;
  onInitVanningTo: any;
  justFileName: any;
  validating: boolean;
  itContact: string;
  easternDate: any;
  defaultPageSize: any = 100000;
  kanbanSearchValue: any;
  flalLicenseKeySubscription$: Subscription;
  animateRows: boolean;
  groupUseEntireRow: boolean;
  spinnerMessage: any;
  modalComponent: string;
  modifiedRowData: any;
  selectedLine: any;
  selectedModuleType: any;
  lineList: any[];
  moduleTypeList: any[];
  prodProgressSubscription$: Subscription;
  userName: string;
  gridApi: any;
  rowDataforshift = [];
  rowDatafinal: any;
  access2;
  isButtonDisabled: boolean;
  showButton: boolean = false;


  constructor(
    private readonly spinner: NgxSpinnerService,
    private readonly excelService: ExcelService,
    private readonly gridDataService: GriddataService,
    private readonly flalService: FluctuationAllowanceService,
    private readonly lookupService: LookupService,
    private readonly prodProgressService: ProdprogressService,
    private readonly stateService: OSPStateService,
    private readonly bulkadjustmentService: BulkadjustmentService,
    private environment: EnvironmentConfiguration,
    private readonly ospService: OspService,



    public dialog: MatDialog
  ) {
    this.context = {
      componentParent: this,
    };
  }


  fileName: string = '';
  access1;



  // DATE COMPARATOR FOR SORTING
  dateComparator(date1, date2) {
    function monthToNum(date) {
      if (date === undefined || date === null) {
        return null;
      }

      const newDate: Date = new Date(date);

      const yearNumber = newDate.getFullYear();
      const monthNumber = newDate.getMonth();
      const dayNumber = newDate.getDate();
      const hoursNumber = newDate.getHours();
      const minutesNumber = newDate.getMinutes();

      const result = yearNumber * 10000 + monthNumber * 100 + dayNumber + hoursNumber * 60 + minutesNumber * 10;
      // 29/08/2004 => 20040829
      return result;
    }

    const date1Number = monthToNum(date1);
    const date2Number = monthToNum(date2);

    if (date1Number === null && date2Number === null) {
      return 0;
    }
    if (date1Number === null) {
      return -1;
    }
    if (date2Number === null) {
      return 1;
    }

    return date1Number - date2Number;
  }

  ngOnDestroy(): void {
    if (this.flalSubscription$) this.flalSubscription$.unsubscribe();
    if (this.flalLicenseKeySubscription$) this.flalLicenseKeySubscription$.unsubscribe();
  }

  ngOnInit(): void {
    const access = checkAccessPipe();
    this.access1 = access.access;
    this.buttonLabel = "Expand ALL"; // Initial button label
    this.isExpanded = false; // Initial state
    this.groupDefaultExpanded = 0
    // this.toggleExpandCollapse();
    // Call toggleExpandCollapse only if needed
    // This prevents resetting states inappropriately
    if (this.osPartsGrid && this.rowData) {
      this.toggleExpandCollapse();
    }


    this.currentUtcDateTime = moment().utc(); // Example UTC date-time
    this.michiganDate = this.currentUtcDateTime.tz('US/Michigan');
    this.formattedDate1 = this.michiganDate.format('MM/DD/YYYY HH:mm:ss');


    this.gridOptions = this.gridDataService.getGridOptions();
    this.gridOptions.onGridReady = function (params) {
      params.api.hideOverlay();
      this.gridApi = params.api;
    };

    this.gridOptions.defaultColDef.floatingFilter = true;

    this.detailCellRendererParams = {
      detailGridOptions: {

        columnDefs: [
          { headerName: 'Plan Lo DT', field: "plan_lodate", flex: 1 },
          { headerName: 'Plan Lo QTY', field: "plan_loqty", flex: 1 }],

        defaultColDef: {
          flex: 1,
        },
        masterDetail: true,
        detailRowHeight: 0.5,

      },
      getDetailRowData: (params) => {
        params.successCallback(params.data.children);
      },
    } as IDetailCellRendererParams;


    this.columnDefs = [
      {
        headerName: '',
        field: 'rowCheckBox',
        width: 40,
        headerComponentParams: {
          headerChecked: this.headerCheckboxChecked,
        },
        headerComponentFramework: HeaderCheckboxComponent,
        floatingFilterComponentFramework: GridRefreshComponent,
        floatingFilterComponentParams: { suppressFilterButton: true },
        cellRendererFramework: RowCheckboxComponent,
        floatingFilter: true,
        pinned: 'left',
      },
      {
        headerName: '',
        sortable: false,
        width: 60,
        cellRenderer: 'buttonRenderer2',

        cellRendererParams: {
          label: 'Click 2',
        },
        pinned: 'left',


      },
      {
        headerName: 'Prod DT',
        field: 'actual_date',
        sortable: true,
        width: 130,
        floatingFilter: true,
        filter: 'agDateColumnFilter',
        filterParams: {
          comparator: dateFilterComparator,
        },

        comparator: this.dateComparator,


        tooltipField: 'actual_date',
        headerTooltip: 'Production Date (mm/dd/yyyy)',

        cellRenderer: 'agGroupCellRenderer',
      },
      // {
      //   headerName: 'Module Type',
      //   field: 'module_type',
      //   sortable: true,
      //   width: 120,
      //   floatingFilter: true,
      //   tooltipField: 'module_type',
      //   headerTooltip: 'Module Type',


      // },
      {
        headerName: 'Shift',
        field: 'actual_shift',
        sortable: true,
        width: 80,
        floatingFilter: true,
        tooltipField: 'actual_shift',
        headerTooltip: 'Actual Shift',


      },
      {
        headerName: 'Plan VOL',
        field: 'plan_volume',
        sortable: true,
        width: 100,
        floatingFilter: true,
        headerTooltip: 'Plan Volume',
        filterParams: {
          numberParser: (text) => {
            return text === null ? null : parseFloat(text.replace(',', '.'));
          },
        },

        filter: 'agNumberColumnFilter',
        floatingFilterComponentParams: { suppressFilterButton: false },
      },
      {
        headerName: 'Int Plan VOL',
        field: 'intPlanVol',
        sortable: true,
        width: 120,
        floatingFilter: true,
        cellRendererParams: { column: 'int_plan_volume' },
        cellRendererFramework: ProdProgressIntvolEditComponent,
        valueGetter: (params) => {
          if (!this.editEnabled || !params.data.rowCheckBox) {
            return params.data.int_plan_volume;
          } else {
            const value = params.data.intPlanVol?.value ?? params.data.int_plan_volume;

            return value;
          }
        },

        headerTooltip: 'Internal Plan Volume',
        filterParams: {
          numberParser: (text) => {
            return text === null ? null : parseFloat(text.replace(',', '.'));
          },
        },

        filter: 'agNumberColumnFilter',
        floatingFilterComponentParams: { suppressFilterButton: false },

      },
      {
        headerName: 'ALC',
        field: 'alc_result',
        sortable: true,
        width: 100,
        floatingFilter: true,
        filterParams: {
          numberParser: (text) => {
            return text === null ? null : parseFloat(text.replace(',', '.'));
          },
        },

        filter: 'agNumberColumnFilter',
        floatingFilterComponentParams: { suppressFilterButton: false },
      },
      {
        headerName: 'Final',
        field: 'Final',
        sortable: true,
        width: 90,
        floatingFilter: true,
        cellRendererFramework: ProdProgressFinalEditComponent,
        cellRendererParams: { column: 'final' },

        valueGetter: (params) => {
          if (!this.editEnabled || !params.data.rowCheckBox) {
            return params.data.final;
          } else {
            const value = params.data.Final?.value ?? params.data.final;

            return value;
          }
        },

        filter: 'agNumberColumnFilter',


      },
      {
        headerName: 'Plan Net',
        field: 'plan_net',
        sortable: true,
        width: 90,
        floatingFilter: true,
        tooltipField: 'plan_net',
        valueGetter: (params) => {
          if (!this.editEnabled || !params.data.rowCheckBox) {
            return params.data.plan_net;
          } else {
            const value = params.data.Final?.value ?? params.data.plan_net;

            return value;
          }
        },

        filter: 'agNumberColumnFilter',
      },
      {
        headerName: 'Cumul Plan Net',
        field: 'cumul_plan_net',
        sortable: true,
        width: 100,
        floatingFilter: true,
        filter: 'agNumberColumnFilter',
        tooltipField: 'cumul_plan_net',
        headerTooltip: 'Cumulative Plan Net',
      },
      {
        headerName: '',
        sortable: false,
        width: 50,
        cellRenderer: 'buttonRenderer',

        cellRendererParams: {
          label: 'Click 2',
        },

      },



      // {
      //   headerName: 'Plan LO Date',
      //   field: 'plan_lo_dt',
      //   sortable: true,
      //   width: 130,
      //   floatingFilter: true,

      // },
      // {
      //   headerName: 'Plan LO QTY',
      //   field: 'plan_lo_qty',
      //   sortable: true,
      //   width: 130,
      //   floatingFilter: true,

      // },
      {
        headerName: 'User',
        headerTooltip: 'User',
        field: 'userid',
        sortable: true,
        width: 150,
        floatingFilter: true,
        tooltipField: 'userid',

      },
      {
        headerName: 'Updated DT',
        field: 'updated_datetime',
        headerTooltip: 'Updated Date (mm/dd/yyyy hh:mm:ss (Eastern))',
        sortable: true,
        width: 150,
        floatingFilter: true,
        tooltipField: 'updated_datetime',
      },
      {
        headerName: 'Action',
        field: '',
        sortable: false,
        width: 180,
        floatingFilterComponentParams: { suppressFilterButton: true },
        cellClass: 'ui-grid-cell-contents-auto',

        floatingFilter: false,
        cellRenderer: 'buttonRenderer3',

        cellRendererParams: {
          label: 'Click 2',
        },
      },




    ],    // Define the raw data with a custom structure


      this.headerHeight = 45;
    this.setPageSizeToAll();
    this.rowSelection = 'multiple';
    this.editType = 'fullRow';
    this.groupDefaultExpanded = 0
    this.gridOptions = this.gridDataService.getGridOptions();
    this.gridOptions.onGridReady = function (params) {
      this.gridApi = params.api;
      this.gridColumnApi = params.columnApi;
      this.gridOptions.rowBuffer = 300;
      this.gridOptions.rowModelType = 'infinite'
      this.gridOptions.suppressRowHoverHighlight = true
      this.gridOptions.suppressRowHoverHighlight = true,
        this.gridOptions.cacheBlockSize = 100,  // Number of rows per request
        this.gridOptions.maxBlocksInCache = 2,  // Limit number of blocks in cache
        this.gridOptions.suppressColumnMoveAnimation = true,
        this.gridOptions.suppressRowTransform = true,
        //  this.gridOptions.pagination= true,  // If applicable
        //  this.gridOptions.paginationPageSize= 100,  // Adjust pagination size
        this.gridOptions.suppressAggFuncInHeader = true,
        this.gridOptions.suppressAnimationFrame = true,
        this.gridOptions.suppressScrollOnNewData = true; // Prevent scrolling to the top when new data is loaded


      params.api.sizeColumnsToFit();  // Adjust columns to fit the available width

    };

    // this.prodProgressSubscription$ = this.stateService.getNamc().subscribe((observable) => {
    //   this.initializeData();
    // });
    this.gridOptions.defaultColDef.cellStyle = getCellStyleConfig2;
    this.prodProgressSubscription$ = this.stateService.getNamc().subscribe((observable) => {
      this.initializeData();
      if (this.gridApi) {
        this.spinner.show()

        this.gridApi.setRowData(this.rowData);  // Set updated row data
        this.gridApi.sizeColumnsToFit();  // Adjust columns to fit the available width
        this.spinner.hide()
      }
    });





  }



  initializeData() {
    this.onResetDropDown();

    this.itContact = localStorage.getItem('itContact');

    this.rowData = [];

    if (localStorage.getItem('namcvalue')) {
      this.namcValue = localStorage.getItem('namcvalue');
    }
    if (localStorage.getItem('namcName')) {
      this.namcName = localStorage.getItem('namcName');
    }
    if (localStorage.getItem('workdayId')) {
      this.user = localStorage.getItem('workdayId');
    }
    if (localStorage.getItem('UserRoles')) {
      this.userRole = localStorage.getItem('UserRoles');
    }
    if (localStorage.getItem('UserName')) {
      this.userName = localStorage.getItem('UserName');
    }

    const access = checkAccessPipe();
    this.access1 = access.access;
    this.access2 = access.access.edit



    localStorage.setItem('prodProgress', this.access2);



    this.loadDrop();

  }



  loadDrop() {
    // Show the spinner when starting the data fetch
    this.spinner.show();

    const data = {
      business_entity: this.namcValue,
      workid: this.user,
      user_role: this.userRole,
    };

    const lookupSubscriptions = [
      this.prodProgressService.getDock(data),
    ];

    forkJoin(lookupSubscriptions).subscribe({
      next: (res) => {
        this.dockList = [];
        if (!res[0].error) {

          if (res[0]?.body && Array.isArray(res[0].body)) {
            this.dockList.push(...res[0].body);
          }
        } else {
          this.warningMessage = 'Try after some time! Error in get dock list';
        }
        // Hide the spinner after the data is bound
        this.prodDate = this.previousDate
        this.sixDaysLater = moment(this.newDate).add(6, 'days').format(this.dateFormat);
        this.prodDateTo = this.sixDaysLater

        this.spinner.hide();
      },
      error: (err) => {
        // Handle the error and hide the spinner
        this.errorCallback(err);
        this.spinner.hide();
      },
    });
  }



  onSearch(offset) {
    this.showButton = true;

    // Set initial collapsed state (only if it's the first search)
    if (offset === 0) {
        this.buttonLabel = "Expand ALL"; // Default state
        this.isExpanded = false; // Ensure all rows start collapsed
        this.groupDefaultExpanded = 0; // Collapse state for all rows
    }

    if (this.osPartsGrid) {
        this.headerCheckboxChecked = false;
        this.osPartsGrid.gridOptions.api.refreshHeader();
        this.resetGrid();
    }

    this.removeMessage();
    this.editEnabled = false;
    this.isEditMode = false;

    if (offset === 0) {
        this.setPageSizeToAll(this.defaultPageSize);
        this.rowData = [];
        this.osPartsGrid.gridOptions.api.setRowData(this.rowData);
        this.offset = 0;
        this.spinner.show();
    }

    if (!this.checkIfAnySelected()) {
        this.rowData = [];
        this.warningMessage = warningMessage.selectToSearch2;

        window.scroll(0, 0);
        this.spinner.hide();
        return false;
    }

    let selectedDock;
    if (this.selectedDock.length > 0) {
        if (!this.selectedDock.some((a) => a.dock === 'ALL')) {
            selectedDock = [];
            this.selectedDock.forEach((element) => {
                selectedDock.push(element.dock);
            });
        }
    }

    const data = {
        offset: offset,
        limit: 12000,
        business_entity: this.namcValue,
        workid: this.user,
        user_role: this.userRole,
        dock: [this.selectedDock.dock],
        prod_date_from: this.prodDate,
        prod_date_to: this.prodDateTo,
    };

    this.prodProgressService.getProdProgressData(data).subscribe({
        error: this.errorCallback,
        next: (res) => {
            if (!res.error) {
                if (res.body && res.body.data) {
                    this.rowData.push(...res.body.data);

                    // Synchronize the grid expansion state after data is updated
                    this.groupDefaultExpanded = this.isExpanded ? 1 : 0; // Sync with current state
                    this.osPartsGrid.gridOptions.api.setRowData(this.rowData);
                    this.osPartsGrid.gridOptions.api.refreshCells();
                    this.dataCount = parseInt(res.body.rowCount);
                    const records = this.dataCount - (this.offset + 1) * 12000;
                    if (records >= 1) {
                        this.offset++;
                        this.onSearch(this.offset * 12000);
                    } else {
                        this.spinner.hide();
                    }
                } else {
                    this.rowData = [];
                    this.spinner.hide();
                }

                this.setPageSizeToAll();
            } else {
                this.spinner.hide();
                this.warningMessage =
                    'Try again! Error in getting production progress data, please connect Support team';
            }
        },
    });

    // Get shift data
    this.ospService.getShiftDetails(data).subscribe({
        error: this.errorCallback,
        next: (res) => {
            if (res.body && res.body.shiftResponce) {
                this.rowDataforshift.push(...res.body.shiftResponce.data);

                let currentDate = moment().tz('US/Michigan'); // Current time in US/Michigan timezone
                const currentDateString = String(currentDate.format('YYYY-MM-DD')); // Current date (YYYY-MM-DD)
                const currentTimeString = String(currentDate.format('HH:mm:ss')); // Current time (HH:mm:ss)
                const currentDayOfWeek = currentDate.day() + 1; // Current day of the week (0-6)

                const adjustedCurrentDayOfWeek = currentDayOfWeek === 0 ? 7 : currentDayOfWeek; // Adjust Sunday to 7

                const filteredData = this.rowDataforshift.filter((item) => {
                    item.day_of_week = parseInt(item.day_of_week, 10); // Ensure integer comparison
                    return (
                        item.shift_no === 1 &&
                        item.day_of_week === adjustedCurrentDayOfWeek &&
                        currentDateString >= moment(item.from_date).format('YYYY-MM-DD') &&
                        currentDateString <= moment(item.to_date).format('YYYY-MM-DD')
                    );
                });

                let shiftEnded = false;
                localStorage.setItem('isShiftoneEnded', 'false');
                if (filteredData.length > 0) {
                    const shiftEndTime = filteredData[0].end_time;
                    shiftEnded = currentTimeString >= shiftEndTime; // Compare current time with shift end time
                    localStorage.setItem('isShiftoneEnded', 'true');
                    this.rowDataforshift = [];
                }
            } else {
                this.spinner.hide();
            }

            this.setPageSizeToAll();
        },
    });

    return true;
}




  onPageSizeChanged() {
    if (this.gridOptions?.api) {
      this.gridOptions.api.paginationSetPageSize(Number(this.paginationPageSize));
    }
  }

  checkIfAnySelected() {
    // return true
    console.log("this.prod", this.prodDate)

    if (
      this.selectedDock.length != 0 &&
      this.prodDate
    ) {
      return true;
    }

    return false;
  }
  hideSpinner() {
    this.spinner.hide();
  }


  onEditMode() {
    this.removeMessage();
    let data = [];
    data = this.getModifiedRowData();

    if (data.length > 0) {
      this.isEditMode = !this.isEditMode;
      this.editEnabled = this.isEditMode;

      if (this.isEditMode) {
        this.osPartsGrid.gridOptions.api.forEachNode(function (rowNode, index) {
          rowNode.data.checkedEditMode = true;
          if (rowNode.data.rowCheckBox) {
          } else {
            rowNode.data.checkedEditMode = false;
          }
          rowNode.setData(rowNode.data);
        });
      } else {
        this.osPartsGrid.gridOptions.api.forEachNode(function (rowNode, index) {
          if (rowNode.data.rowCheckBox) {
            rowNode.data.checkedEditMode = false;
          }
          rowNode.setData(rowNode.data);
        });
      }
      setTimeout(() => {
        window.scroll(0, document.body.scrollHeight);
      }, 200);


    } else {
      this.isEditMode = false;
      this.editEnabled = false;
      this.warningMessage = warningMessage.selectRowsToEdit;
      window.scroll(0, 0);
    }
  }
  ReviewChanges() {
    this.removeMessage();

    this.modifiedRowData = this.getModifiedRowData();
    console.log('editedRecords', this.modifiedRowData);
    this.modifiedRowData = this.modifiedRowData.filter((element) => element.Updated);


    if (this.modifiedRowData.length === 0) {
      this.warningMessage = warningMessage.editToReview;

      window.scroll(0, 0);
      return;
    }
    console.log('value', this.modifiedRowData.intPlanVol)



    Promise.all(
      this.modifiedRowData.map(async (element, index) => {
        let p = [];
        element.error = '';

        // validation
        if (element.isinternalplan) {
          element.final = element.int_plan_volume;

        }


        if (p.length > 0) {
          element.error = p
            .map(function (val, index) {
              return `${(index + 1).toString()}.   ${val}`;
            })
            .join('\n');
        }
      })
    );

    if (this.modifiedRowData.length > 0) {
      this.openReviewDialog(this.modifiedRowData);
    }
  }
  reviewData() {
    this.openReviewDialog(this.modifiedRowData);
  }

  openReviewDialog(editedRecords) {
    var data = {
      modifiedRowData: editedRecords,
    };
    this.currentUtcDateTime = moment().utc(); // Example UTC date-time
    this.michiganDate = this.currentUtcDateTime.tz('US/Michigan');
    this.formattedDate1 = this.michiganDate.format('MM/DD/YYYY HH:mm:ss');

    console.log("modifieddata", data.modifiedRowData)
    if (data.modifiedRowData.length > 0) {
      const dialogConfig = new MatDialogConfig();
      dialogConfig.disableClose = true;
      dialogConfig.id = this.modalComponent;
      dialogConfig.height = '350px';
      dialogConfig.width = '1000px';

      dialogConfig.data = data;
      const dialogRef = this.dialog.open(ProgressEditDialogComponent, dialogConfig);
      dialogRef.afterClosed().subscribe((res) => {
        if (res === 'cancel') {
          return;
        } else if (res === 'save') {
          var updatedData = [];
          var updatedDataTodisplay = [];
          console.log(data.modifiedRowData)
          data.modifiedRowData.forEach((e) => {
            var updatedFields = {
              actual_date: e.actual_date,
              cumul_plan_net: e.cumul_plan_net,
              plan_volume: e.plan_volume,
              plan_net: e.plan_net,
              final: e.final,
              actual_shift: e.actual_shift,
              alc_result: e.alc_result,
              dock: e.dock,
              int_plan_volume: e.int_plan_volume,
              userid: localStorage.getItem('UserName'),
            };
            var displayupdatedFields = {
              actual_date: e.actual_date,
              cumul_plan_net: e.cumul_plan_net,
              plan_volume: e.plan_volume,
              plan_net: e.plan_net,
              final: e.final,
              actual_shift: e.actual_shift,
              alc_result: e.alc_result,
              dock: e.dock,
              int_plan_volume: e.int_plan_volume,
              updated_datetime: this.formattedDate1,
              userid: localStorage.getItem('UserName'),
              children: e.children
            };



            updatedData.push(updatedFields);
            updatedDataTodisplay.push(displayupdatedFields)

          });

          this.spinner.show();
          this.isFinalupdated = localStorage.getItem('isFinal')
          // console.log("this.isFinalupadated ",this.isFinalupdated )
          // if(this.isFinalupdated === 'true'){
          //   console.log("inside if")
          //   this.spinner.hide();
          //   return;
          // }

          this.editProdProgress(updatedData, updatedDataTodisplay);


        }
      });

    } else {
      this.warningMessage = warningMessage.selectRowsToEdit;

      window.scroll(0, 0);
    }
  }

  openDialog1() {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.id = this.modalComponent;
    dialogConfig.height = '204px';
    dialogConfig.width = '475px';
    dialogConfig.data = 'ospipe'
    const dialogRef = this.dialog.open(ConfirmCancelDailogComponent, dialogConfig);
    dialogRef.afterClosed().subscribe((res) => {
      if (res === 'cancel') {
        this.columnDefs[7].cellStyle = function (params) {
          if ('modified' in params.data) {
            return { backgroundColor: '#E4ECF0' };
          } else {
            return { backgroundColor: 'white' };
          }
        };
        this.rowData = []
        this.onSearch(0);
        this.editEnabled = false;
        this.isEditMode = false;
        this.headerCheckboxChecked = false;
        this.osPartsGrid.gridOptions.api.refreshHeader();
      }
    });
  }

  updatedRows = [];
  editProdProgress(updatedData, updatedDataTodisplay) {
    var data1 = {
        business_entity: this.namcValue,
        userid: this.userName,
        workid: this.user,
        user_role: this.userRole,
        data: updatedData.slice(this.editApiStartPosition, this.editApiEndPosition),
    };
    console.log("data1", data1);
    this.spinner.show();

    this.prodProgressService.IntplanVolUpdate(data1).subscribe({
        error: this.errorCallback,
        next: (response) => {
            if (!response.error) {
                console.log('response.body.data', response, response.body.data);
                const updatedRows = [response.body.data];
                this.updatedRows.push(...updatedRows);

                // Handle pagination logic for large data updates
                if (updatedData.length > this.editApiEndPosition) {
                    this.timesEditApiCalled++;
                    this.editApiStartPosition = this.timesEditApiCalled * this.editApiDefaultLimit;
                    this.editApiEndPosition = this.editApiStartPosition + this.editApiDefaultLimit;
                    return this.editProdProgress(updatedData, updatedDataTodisplay);
                }

                this.spinner.hide();

                // Reset edit-specific variables
                this.timesEditApiCalled = 0;
                this.editApiStartPosition = 0;
                this.editApiEndPosition = this.editApiDefaultLimit;

                if (updatedRows.length > 0) {
                    // Update the grid with the new data
                    this.rowData = [];
                    this.spinner.hide();
                    this.successMessage = successMessage.recordUpdated;
                    this.rowData = updatedDataTodisplay;
                    this.osPartsGrid.gridOptions.api.setRowData(this.rowData);

                    // Ensure the collapsed view
                    this.buttonLabel = "Expand ALL"; // Button defaults to "Expand ALL"
                    this.isExpanded = false; // Ensure rows are marked as collapsed
                    this.groupDefaultExpanded = 0; // Collapse all rows

                    // Refresh the grid to apply collapsed state
                    this.osPartsGrid.gridOptions.api.setRowData(this.rowData); // Update grid data
                    this.osPartsGrid.gridOptions.api.refreshCells(); // Ensure cells render properly
                } else {
                    this.warningMessage = `${warningMessage.apiLogicFail} ${this.itContact}.`;
                }

                // Reset UI states
                this.editEnabled = false;
                this.isEditMode = false;
                this.headerCheckboxChecked = false;
                this.osPartsGrid.gridOptions.api.refreshHeader();
                this.resetGrid();

                // Scroll back to the top of the page
                window.scroll(0, 0);
                this.setPageSizeToAll();
            } else {
                this.spinner.hide();
                this.warningMessage = 'Try again! Error in getting updated data, please connect Support team';
            }
        },
    });
}


  cancelEdit(): void {
    this.removeMessage();
    this.openDialog1();
  }



  // saveShowHide() {
  //   let shift_column = this.shift_column;
  //   const data = {
  //     business_entity: this.namcValue,
  //     business_entity_name: this.namcName,
  //     workid: this.user,
  //     user_role: this.userRole,
  //   };

  //   if (shift_column) {
  //     this.lookupService.updateUserShowHideColumns(shift_column).subscribe({
  //       error: this.errorCallback,
  //       next: (res) => {
  //         this.spinner.hide();
  //       },
  //     });
  //   } else {
  //     this.spinner.hide();
  //   }
  // }



  errorCallback = (error) => {
    console.error(error);
    this.hideSpinner();

    this.removeMessage();
    this.warningMessage = `${warningMessage.apiLogicFail}  ${this.itContact}.`;
  };
  setPageSizeToAll(pageSize?) {
    this.paginationPageSize = this.rowData.length;

    if (pageSize) {
      this.paginationPageSize = 0;
      this.rowData.length = 0;
    }

    this.onPageSizeChanged();
  }

  canDeactivate() {
    return this.editEnabled;
  }

  getModifiedRowData(): any {
    console.log(this.rowData)
    const modifiedRowData = this.rowData.filter((row) => row.rowCheckBox);
    return modifiedRowData;
  }

  getSelectedRows(): any {
    const modifiedRowData = this.rowData.filter((row) => row.rowCheckBox);
    return modifiedRowData;
  }

  onResetDropDown() {
    this.removeMessage();
    this.prodDate = this.previousDate
    this.sixDaysLater = moment(this.newDate).add(6, 'days').format(this.dateFormat);
    this.prodDateTo = this.sixDaysLater


    this.isExpanded = false; // Initial state
    this.groupDefaultExpanded = 0
    this.toggleExpandCollapse();
    this.isButtonDisabled = true
    this.showButton = false;
    this.selectedKanban = [];
    this.selectedPartNo = [];
    this.selectedDestCode = [];
    this.selectedContainer = [];
    this.selectedSupplier = [];
    this.selectedDock = [];
    this.selectedSpecialist = [];
    this.selectedModuleType = [];
    this.selectedLine = [];

    this.kanbanSearchValue = null;
    this.editEnabled = false;
    this.isEditMode = false



    this.setPageSizeToAll(this.defaultPageSize);

    this.rowData = [];
    this.disableKanban = false;
    this.disablePartNumber = false;

    if (this.osPartsGrid) {
      this.headerCheckboxChecked = false;
      this.osPartsGrid.gridOptions.api.refreshHeader();
      this.resetGrid();
    }
  }

  resetGrid() {
    if (this.osPartsGrid) {
      resetSortingAndFilters(this.osPartsGrid);
      return;
    }
  }
  Recalculate(data) {
    const exportData = [];
    // exportData.push(data);
    exportData.push({ ...data, isRecalculate: true });
    var dataEdited = {
      business_entity: this.namcValue,
      dock: exportData[0].dock,
      act_prod_date: moment(exportData[0].actual_date, 'MM/DD/YYYY').format('YYYY-MM-DD'),
      act_shift: exportData[0].actual_shift,
      userid: this.userName,
      is_recalculate: true,
      recalculate: true,
      cumul_plan_net: exportData[0].cumul_plan_net,
      finalResultForEdit: null,
      internalPlanVolumeForEdit: null,
      action: "Recalculate"
    }
    var data1 = {
      business_entity: this.namcValue,
      workid: this.user,
      user_role: this.userRole,
      userid: this.userName,
      data: [dataEdited]
    }




    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.id = 'modal-component';
    dialogConfig.height = '204px';
    dialogConfig.width = '470px';
    dialogConfig.data = exportData;

    const dialogRef = this.dialog.open(updateDevanPlanComponent, dialogConfig);
    dialogRef.afterClosed().subscribe((res) => {
      if (res === 'cancel') {
        return;
      } else if (res === 'save') {
        console.log("save", exportData)
        this.spinner.show();
        this.prodProgressService.RecalculatePP(data1).subscribe({
          error: this.errorCallback,
          next: (response) => {
            if (!response.error) {

              if (response.body.msg = "Recalculateted Successfully") {
                this.prodProgressService.setIsRecalculate(data1).subscribe({
                  error: this.errorCallback,
                  next: (data) => {
                    // console.log('daya', data);
                    if (data.body.data.updatedCount > 0) {
                      this.rowData = []
                      this.onSearch(0)
                      this.successMessage = "Recalculated Successfully";
                      window.scroll(0, 0);
                    } else {
                      this.spinner.hide();
                    }
                  },
                });
              }
              else {
                this.spinner.hide();
              }
            } else {
              this.spinner.hide();
              this.warningMessage = 'Try again! Error in  recalculating production progress please connect Support team';

            }
          },
        });


      }
    });
  }
  editData(data) {
    const exportData = [];
    exportData.push(data);
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.id = 'modal-component';
    dialogConfig.height = '300px';
    dialogConfig.width = '600px';
    dialogConfig.data = exportData;
    const dialogRef = this.dialog.open(EditCumulPlanNetComponent, dialogConfig);
    const editGetResponse =
      // Format it as YYYYMMDD
      dialogRef.componentInstance.editEventEmitter.subscribe((res) => {
        var dataEdited = {
          business_entity: this.namcValue,
          dock: res.dock,
          actual_date: moment(res.actual_date, 'MM/DD/YYYY').format('YYYYMMDD'),
          actual_shift: res.actual_shift,
          cumul_plan_net: res.cumul_plan_net,
          finalResultForEdit: null,
          internalPlanVolumeForEdit: null,
          alc: res.alc_result,
          userid: this.userName,
          act_prod_date: moment(res.actual_date, 'MM/DD/YYYY').format('YYYY-MM-DD'),
          act_shift: res.actual_shift,
          cumulative_net_veh_qty: res.cumul_plan_net,
          action: 'reset',
          is_recalculate: true,
          recalculate: true
        }
        var data = {
          business_entity: this.namcValue,
          workid: this.user,
          user_role: this.userRole,
          userid: this.userName,
          data: [dataEdited]
        }
        var dataEdited2 = {
          business_entity: this.namcValue,
          dock: res.dock,
          actual_date: moment(res.actual_date, 'MM/DD/YYYY').format('YYYYMMDD'),
          actual_shift: '2',
          cumul_plan_net: res.cumul_plan_net,
          alc: res.alc_result,
          finalResultForEdit: null,
          internalPlanVolumeForEdit: null,
          userid: this.userName,
          act_prod_date: moment(res.actual_date, 'MM/DD/YYYY').format('YYYY-MM-DD'),
          act_shift: '2',
          cumulative_net_veh_qty: res.cumul_plan_net,
          action: 'reset',
          is_recalculate: true,
          recalculate: true
        }
        var data2 = {
          business_entity: this.namcValue,
          workid: this.user,
          user_role: this.userRole,
          userid: this.userName,
          data: [dataEdited2]
        }
        this.spinner.show()
        this.prodProgressService.editCumulPlanNet(
          data
        ).subscribe({
          error: this.errorCallback,
          next: (response) => {
            console.log("response", response)
            if (response.body.msg === "Cumul Plan net reset Successfully") {
              this.prodProgressService.setIsRecalculate(data2).subscribe({
                error: this.errorCallback,
                next: (data) => {
                  // console.log('daya', data);
                  if (data.body.data.updatedCount > 0) {
                    this.rowData = []
                    this.onSearch(0)
                    this.successMessage = successMessage.recordUpdated;
                    window.scroll(0, 0);
                  } else {
                    this.spinner.hide();
                  }
                },
              });

            } else {
              this.spinner.hide();
              this.warningMessage = `${warningMessage.apiLogicFail}  ${this.itContact}.`;
            }

            window.scroll(0, 0);
            this.setPageSizeToAll();
            window.scroll(0, 0);
          },
        });
      });
    dialogRef.afterClosed().subscribe((res) => {
      editGetResponse.unsubscribe();
    });
  }

  convertNaN(value) {
    value = Math.round(parseFloat(value?.trim()));
    return isNaN(value) ? '' : value;
  }

  userCheckChanged(checked, rowIndex) {
    if (
      this.warningMessage === 'Please select at least one row to edit.' ||
      this.warningMessage === 'No rows are selected.'
    ) {
      this.warningMessage = '';
    }
    //this.showSpinner();

    const rowNode = this.osPartsGrid.gridOptions.api.getDisplayedRowAtIndex(rowIndex);

    if (checked === true) {
      rowNode.setSelected(true);
      if (this.isEditMode) {
        rowNode.data.checkedEditMode = true;
      }
      rowNode.data.rowCheckBox = true;
    } else {
      rowNode.setSelected(false);
      rowNode.data.checkedEditMode = false;
      rowNode.data.rowCheckBox = false;
    }

    this.gridOptions.getRowStyle = function (params) {
      if (params.node.rowIndex === rowIndex) {
        const color = checked ? '#E4ECF0' : 'white';
        return { background: color };
      }
      return { background: 'white' };
    };

    rowNode.setData(rowNode.data);
    this.toggleHeaderCheckbox(checked);
    this.hideSpinner();
  }

  headerCheckChanged(event) {
    if (
      this.warningMessage === 'Please select at least one row to edit.' ||
      this.warningMessage === 'No rows are selected.'
    ) {
      this.warningMessage = '';
    }
    this.headerCheckboxChecked = event.checked;
    const headerChecked = event.checked;
    const isEditable = this.isEditMode;
    this.osPartsGrid.gridOptions.api.forEachNode(function (rowNode, index) {
      if (headerChecked) {
        if (isEditable) {
          rowNode.data.checkedEditMode = true;
        }
        rowNode.data.rowCheckBox = true;
      } else {
        rowNode.data.rowCheckBox = false;
        rowNode.data.checkedEditMode = false;
      }

      rowNode.setData(rowNode.data);
    });
  }

  toggleHeaderCheckbox(checked) {
    if (!checked) {
      this.headerCheckboxChecked = false;

      this.osPartsGrid.gridOptions.api.refreshHeader();

      return;
    }

    const rowCount = this.osPartsGrid.gridOptions.rowData.length;
    const selectedRowCount = this.osPartsGrid.gridOptions.rowData.filter((x) => x.rowCheckBox === true).length;
    console.log('rowCount', rowCount);
    console.log('selectedRowCount', selectedRowCount);

    if (rowCount === selectedRowCount) {
      this.headerCheckboxChecked = true;

      this.osPartsGrid.gridOptions.api.refreshHeader();
    }
  }

  // showSpinner(message?: string) {
  //   if (!message) {
  //     this.spinnerMessage = this.spinnerMessage;
  //   } else {
  //     this.spinnerMessage = message;
  //   }
  // }
  removeMessage() {
    this.warningMessage = '';
    this.successMessage = '';
  }
  expandAll() {
    this.groupDefaultExpanded = 1
    this.spinner.show()
    this.osPartsGrid.gridOptions.api.setRowData(this.rowData);
    this.spinner.hide()

  }
  buttonLabel = "Expand ALL"; // Initial button label
  isExpanded = false; // Initial state

  // toggleExpandCollapse() {
  //   // Disable button while the spinner is active
  //   console.log("isExpanded,", this.isExpanded, this.buttonLabel, this.groupDefaultExpanded);
  //   this.isButtonDisabled = true;

  //   // Show spinner immediately
  //   this.spinner.show();

  //   // Toggle the expanded state
  //   this.isExpanded = !this.isExpanded;

  //   // Immediately update the button label based on isExpanded state
  //   this.buttonLabel = !this.isExpanded ? "Collapse ALL" : "Expand ALL";

  //   // Introduce a timeout before setting row data and hiding the spinner
  //   setTimeout(() => {
  //     // Set group expanded state based on isExpanded flag
  //     console.log("isExpanded,", this.isExpanded, this.buttonLabel, this.groupDefaultExpanded);

  //     this.groupDefaultExpanded = this.isExpanded ? 1 : 0;

  //     // Update grid data (assuming the row data is already set properly)
  //     this.osPartsGrid.gridOptions.api.setRowData(this.rowData); // Update grid data

  //     // Introduce a slight delay before refreshing the grid cells
  //     setTimeout(() => {
  //       this.osPartsGrid.gridOptions.api.refreshCells(); // Refresh grid expansion

  //       // Hide spinner after the delay
  //       this.spinner.hide();

  //       // Re-enable the button once the spinner hides
  //       this.isButtonDisabled = false;
  //     }, 50); // 50ms delay before refreshing cells
  //   }, 1000); // 1000ms delay (1 second)
  // }

  toggleExpandCollapse() {
    this.isButtonDisabled = true;
    this.spinner.show();

    this.isExpanded = !this.isExpanded;
    this.buttonLabel = this.isExpanded ? "Collapse ALL" : "Expand ALL";
    this.groupDefaultExpanded = this.isExpanded ? 1 : 0;

    setTimeout(() => {
        this.osPartsGrid.gridOptions.api.setRowData(this.rowData); // Update grid data
        setTimeout(() => {
            this.osPartsGrid.gridOptions.api.refreshCells(); // Refresh cells
            this.spinner.hide();
            this.isButtonDisabled = false;
        }, 50);
    }, 100);
}

  exportAsXLSX(): void {
    this.groupDefaultExpanded = -1;
    this.spinner.show();
    let easternCurrentDate = String(mm().tz('US/Michigan').format('YYYY-MM-DD_HH:mm:ss'));
  
    let data = [];
    if (this.headerCheckboxChecked) {
      console.log('Hello');
      this.osPartsGrid.gridOptions.api.forEachNodeAfterFilter((node) => {
        data.push(node.data);
      });
    } else {
      data = this.getSelectedRowData();
    }
  
    setTimeout(() => {
      if (data.length > 0) {
        const exportData: any = [];
  
        // Determine the maximum number of child entries
        let maxChildren = 0;
        data.forEach((e) => {
          if (e.children && e.children.length > maxChildren) {
            maxChildren = e.children.length;
          }
        });
  
        // Create the headers dynamically
        const headers = [
          'Prod DT',
          'Shift',
          'Plan Vol',
          'Int Plan Vol',
          'ALC',
          'Final',
          'Plan Net',
          'Cumul Plan Net',
          'User',
          'Updated DT',
        ];
  
        for (let i = 1; i <= maxChildren; i++) {
          headers.push(`Plan Lo DT ${i}`);
          headers.push(`Plan Lo Qty ${i}`); // Optional: include quantities
        }
  
        // Generate the export data
        data.forEach((e) => {
          const obj: any = {
            production_date: e.actual_date,
            shift: e.actual_shift,
            plan_vol: e.plan_volume,
            int_plan_vol: e.int_plan_volume,
            alc: e.alc_result,
            final: e.final,
            plan_net: e.plan_net,
            cumul_plan_net: e.cumul_plan_net,
            updated_userid: e.userid,
            updated_date_time: e.updated_datetime,
          };
  
          if (e.children && e.children.length > 0) {
            e.children.forEach((child, index) => {
              obj[`plan_lodate${index + 1}`] = child.plan_lodate;
              obj[`plan_loqty${index + 1}`] = child.plan_loqty; // Optional
            });
          }
  
          exportData.push(obj);
        });
  
        const timestamp = easternCurrentDate
          .split('.')[0]
          .replace('T', '_')
          .split('')
          .filter((d) => d !== '-' && d !== ':')
          .join('');
  
        if (exportData.length > 0) {
          this.excelService.exportAsExcelFile(exportData, 'ProductionProgress' + timestamp, headers);
        } else {
          this.warningMessage = warningMessage.noRowsSelected;
        }
  
        this.spinner.hide();
      }
    }, 1000);
  }
  

  

  getSelectedRowData(): any {
    const selectedRowData = this.rowData.filter((row) => row.rowCheckBox);
    return selectedRowData;
  }

  onDockChange(): void {
    
    if (this.selectedDock.length >= 1) {
      if (this.selectedDock[this.selectedDock.length - 1].dock === 'ALL') {
        this.selectedDock = [];
        this.selectedDock.push(this.dockList[0]);
      } else {
        let indexAll = this.selectedDock.findIndex((data) => data.dock === 'ALL');

        if (indexAll > -1) {
          this.selectedDock.splice(indexAll, 1);
        }
      }
    }
  }
  updateDevanPlan(data) {
    if (this.isEditMode) {
      return;
    }
    console.log(data);
    const exportData = [];
    exportData.push({ ...data, updateDevanplan: true });
    var dataEdited = {
      business_entity: this.namcValue,
      dock: exportData[0].dock,
      act_prod_date: exportData[0].actual_date,
      act_shift: exportData[0].actual_shift,
      userid: this.userName,
      is_recalculate: false,
      updateDevanPlan: true

    }
    var data1 = {
      business_entity: this.namcValue,
      workid: this.user,
      user_role: this.userRole,
      userid: this.userName,
      data: [dataEdited]
    }
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.id = 'modal-component';
    dialogConfig.height = '204px';
    dialogConfig.width = '470px';
    dialogConfig.data = exportData;
    console.log("exportData", exportData)
    const dialogRef = this.dialog.open(updateDevanPlanComponent, dialogConfig);
    dialogRef.afterClosed().subscribe((res) => {
      if (res === 'cancel') {
        return;
      } else if (res === 'save') {
        console.log("save", exportData)
        this.spinner.show();
        let env = this.environment
        console.log("env")
        const dataTosend = {
          business_entity: this.namcValue,
          workid: this.user,
          user_role: this.userRole,
          NAMC: this.namcName,
          userid: this.userName,
          batch_name: `osp-shopfloor-devanning-batch`,
          dock: exportData[0].dock,
          shift: exportData[0].actual_shift,
          actual_date: moment(exportData[0].actual_date, 'MM/DD/YYYY').format('YYYY-MM-DD'),
          lineoff_date: moment(exportData[0].children[0].plan_lodate, 'MM/DD/YYYY').format('YYYY-MM-DD')
        };
        this.bulkadjustmentService.calculation(dataTosend).subscribe(
          (success) => {
            if (success.body.status) {
              console.log('osp-shopfloor-devanning-batch  batch started successfully for your request');
              // set is recalculate false one devan plan updated
              this.prodProgressService.setIsRecalculate(data1).subscribe({
                error: this.errorCallback,
                next: (data) => {
                  console.log('daya', data);
                  if (data.body.data.updatedCount > 0) {
                    this.rowData = [];
                    this.onSearch(0)
                    this.successMessage='Shopfloor Devannning batch has been initiated. Please check the plan stock date time in Module stocking page'
                    window.scroll(0, 0);
                  } else {
                    this.spinner.hide();
                  }
                },
              });

            }
            this.spinner.hide();
          },
          (err) => {
            this.spinner.hide();
            this.successMessage = '';
            this.warningMessage = `${warningMessage.errorLLPshopfloordevannigBatch}  ${this.itContact}.`;

            window.scroll(0, 0);
          }
        );
      }
    });
  }


  getBatchStatus(callback) {
    var data = {
      business_entity: this.namcValue,
      workid: this.user,
      user_role: this.userRole,
    };

    this.lookupService.getBatchStatus(data).subscribe({
      error: this.errorCallback,
      next: (res) => {
        const count = res.body.data.runningBatches?.length;

        if (count > 0) {
          this.freezingMesssage = `${res.body.data.runningBatches}  ${freezingMesssage}`;
          this.spinner.hide();
          this.batchCheck = true;
        } else {
          this.freezingMesssage = '';
          this.batchCheck = false;
          callback ? callback() : (this.batchCheck = false);
        }
      },
    });
  }





}


