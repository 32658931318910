import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { HeaderCheckboxComponent } from '../core/grid/header-checkbox/header-checkbox.component';
import { GridRefreshComponent } from '../core/grid/grid-refresh/grid-refresh.component';
import { RowCheckboxComponent } from '../core/grid/row-checkbox/row-checkbox.component';
import { NgxSpinnerService } from 'ngx-spinner';
import { CustomTooltipComponent } from '../core/custom-tooltip/custom-tooltip.component';
import { GriddataService } from '../services/griddata.service';
import { GridConatinerActualdtEditorComponent } from './review-changes/review-container.component/grid-editactualnamcdt';
import { GridDevancontainerComponent } from './review-changes/grid-devancontainer.component';
import {
  MatLegacyDialog as MatDialog,
  MatLegacyDialogConfig as MatDialogConfig,
} from '@angular/material/legacy-dialog';
import { PartstmaintenanceService } from '../services/orderforecast/parts-maintenance/partsmaintenance.service';

import { ContainerundevanDialogComponent } from './containermaintenace-dialog.ts/containerundevan-dialog/containerundevan-dialog.component';
import { ContainerDevandialogComponent } from './containermaintenace-dialog.ts/container-devandialog/container-devandialog.component';

import { ConfirmCancelDailogComponent } from '../core/confirm-cancel-dailog/confirm-cancel-dailog.component';
import { ExcelService } from '../services/excel.service';
import * as moment from 'moment';
import { DevanButtonrenderComponent } from './devanbutton/devanbuttonrender.component';
import { OSPStateService } from '../services/osp-state.service';
import { forkJoin, Subject, Subscription } from 'rxjs';
import { ReviewContainerComponentComponent } from './review-changes/review-container.component/review-container.component';

import {
  dateFilterComparator,
  dateComparator,
  resetSortingAndFilters,
  warningMessage,
  successMessage,
  checkAccessPipe,
} from '../constants';
import * as mm from 'moment-timezone';
import { PipeLookupService } from '../services/pipe-lookup/pipe-lookup.service';
import { GridApi } from 'ag-grid-community';

@Component({
  selector: 'app-container-maintenance',
  templateUrl: './container-maintenance.component.html',
  styleUrls: ['./container-maintenance.component.scss'],
})
export class ContainerMaintenanceComponent implements OnInit, OnDestroy {
  namcValue = '';
  user = '';
  userRole = '';
  count = 0;
  files: any[];
  fileBuffer: any;

  exportData: any;
  editApiDefaultLimit = 200;
  timesEditApiCalled: number = 0;
  editApiStartPosition: number = 0;
  editApiEndPosition: number = this.editApiDefaultLimit;
  seletedStatus: any = [];


  rowData = [];
  @ViewChild('osPartsGrid') osPartsGrid;
  columnDefsMaster;
  columnDefs;
  // columnDefsTemp;
  public defaultColDef;
  headerHeight: number;

  rowSelection;
  modalComponent = 'modal-component';
  containerSubscription$: Subscription;

  gridApi: GridApi;

  msgNoContent = 'No Content';
  gridOptions;
  paginationPageSize;
  pageSize;
  paginationNumberFormatter;
  pagination = 'true';
  context: any;
  editType;
  suppressClickEdit = true;
  tooltipShowDelay = 0;
  frameworkComponents = {
    customTooltip: CustomTooltipComponent,
    buttonRenderer: DevanButtonrenderComponent,
  };

  editEnabled: boolean = false;
  filter: boolean = false;
  showToggleAll: boolean = false;

  // Dropdowns
  selectedKanban: any = [];
  selectedrenban: any = [];
  selectedVesselName: any = [];
  statusList: any = [];

  selectedPartNo: any = [];
  selectedDestCode: any = [];
  selectedContainer: any = [];
  containerStatus: any = [];
  selectedDock: any = [];
  successMessage = '';
  warningMessage = '';
  estDate: any;

  kanbanSearchValue: any;
  renbanSearchValue: any;
  vesselSerachValue: any;

  kanbanListSelected = [];
  // Dropdown Options
  kanbanList: any = [];
  partNoList: any = [];
  renbanList: any = [];
  vesselNameList: any = [];
  containerList: any = [];
  rowCount: number = 0;
  isEditMode: boolean = false;
  dataCount: number = 0;
  offset: number = 0;

  renbanValue: any;
  renbanFrom: any;
  renbanTo: any;
  renbanRangeSearchValue: any;
  renbanFromSearchValue: any;
  process: string;

 

  showHideList: any = [
    { label: 'Status', value: 'container_status' },
    { label: 'Renban', value: 'container_renban' },
    {
      label: 'Shipping Carrier',
      value: 'vessel_company',
    },
    {
      value: 'vessel_name',
      label: 'Vessel Name',
    },

    {
      label: 'Container No',
      value: 'container_number',
    },
    {
      label: 'Van Comp DT',
      value: 'tmc_van_completion_date',
    },
    {
      label: 'Vess Sail DT',
      value: 'tmc_port_departure',
    },
    {
      label: 'Est Arr DT',
      value: 'estArrival',
    },
    {
      label: 'Act Arr DT',
      value: 'actualArrival',
    },
    {
      label: 'Devan DT',
      value: 'devan_date',
    },
    {
      label: 'User',
      value: 'updated_userid',
    },
    {
      label: 'Updated DT',
      value: 'updated_date_time',
    },
  ];

  selectedShowHideList: any = [...this.showHideList];

  kanbanSelected = 1;
  disablePartNumber: boolean;
  disableKanban: boolean;
  showGrid: boolean;
  itContact: string;

  namcName: string;
  errorData: any;
  onInitEstdate: any;
  easternDate: any;

  defaultSpinnerMessage = 'Loading... Please wait...';
  dataLoadingMessage = 'Loading data in Bulk! It might take a while, Please wait...';
  spinnerMessage = this.defaultSpinnerMessage;
  showDownloadUploadButtons: boolean = true;

  headerCheckboxChecked: boolean = false;
  defaultPageSize = 100000;

  dateFormat = 'MM/DD/YYYY';
  newDate = String(mm().tz('US/Michigan').format(this.dateFormat)).substring(0, 10);
  today = moment().format('MM/DD/YYYY');
  isExist: boolean;
  isDevanAll = localStorage.setItem('isDevanAll', 'false');
  isSearchDisabledRenban = false;
  isSearchDisabled = false;

  constructor(
    private readonly spinner: NgxSpinnerService,
    private readonly excelService: ExcelService,
    private readonly gridDataService: GriddataService,
    private readonly pipeLookupService: PipeLookupService,
    public dialog: MatDialog,
    private readonly stateService: OSPStateService,
    private readonly partstmaintenanceService: PartstmaintenanceService
  ) {
    this.context = {
      componentParent: this,
      refreshHeader: new Subject<void>()
    };
  }

  // DATE COMPARATOR FOR SORTING
  dateComparator(date1, date2) {
    function monthToNum(date) {
      if (date === undefined || date === null) {
        return null;
      }

      const newDate: Date = new Date(date);

      const yearNumber = newDate.getFullYear();
      const monthNumber = newDate.getMonth();
      const dayNumber = newDate.getDate();
      const hoursNumber = newDate.getHours();
      const minutesNumber = newDate.getMinutes();

      const result = yearNumber * 10000 + monthNumber * 100 + dayNumber + hoursNumber * 60 + minutesNumber * 10;
      return result;
    }

    const date1Number = monthToNum(date1);
    const date2Number = monthToNum(date2);

    if (date1Number === null && date2Number === null) {
      return 0;
    }
    if (date1Number === null) {
      return -1;
    }
    if (date2Number === null) {
      return 1;
    }

    return date1Number - date2Number;
  }

  ngOnDestroy(): void {
    this.containerSubscription$.unsubscribe();
  }

  ngOnInit(): void {
    const access = checkAccessPipe();
    this.access1 = access.access;

    this.columnDefs = [
      {
        headerName: '',
        field: 'rowCheckBox',
        width: 44,
        headerComponentFramework: HeaderCheckboxComponent,
        floatingFilterComponentFramework: GridRefreshComponent,
        floatingFilterComponentParams: { suppressFilterButton: true },
        cellClass: 'ui-grid-cell-contents-auto',

        cellRendererFramework: RowCheckboxComponent,
        floatingFilter: true,
        // 	Check box should be disabled for “User Devanned” Renbans
        tooltipValueGetter: (params) => {
          if (params.data.container_status === 'USER DEVANNED') {
            return 'can not edit for status user devanned';
          } else {
            return '';
          }
        },
      },
      {
        headerName: 'Status',
        headerTooltip: 'Status',
        field: 'container_status',
        sortable: true,
        width: 120,
        floatingFilter: true,
      },
      {
        headerName: 'Renban',
        headerTooltip: 'Renban',
        field: 'container_renban',
        sortable: true,
        width: 100,
        floatingFilter: true,
      },
      {
        headerName: 'Shipping Carrier',
        field: 'vessel_company',
        sortable: true,
        width: 150,
        floatingFilter: true,
        headerTooltip: 'Shipping Carrier',
      },
      {
        headerName: 'Vessel Name',
        headerTooltip: 'Vessel Name',
        field: 'vessel_name',
        width: 150,
        floatingFilter: true,
        tooltipField: 'vessel_name',
        sortable: true,
      },

      {
        headerName: 'Container No',
        headerTooltip: 'Container Number',
        field: 'container_number',
        sortable: true,
        width: 120,
        floatingFilter: true,
        tooltipField: 'container_number',
      },

      {
        headerName: 'Van Comp DT',
        headerTooltip: 'Van Completion Date(mm/dd/yyyy)',
        field: 'tmc_van_completion_date',
        sortable: true,
        width: 130,

        filter: 'agDateColumnFilter',
        filterParams: {
          comparator: dateFilterComparator,
        },
        floatingFilter: true,

        comparator: this.dateComparator,
      },
      {
        headerName: 'Vess Sail  DT',
        headerTooltip: 'Vessel Sail  Date(mm/dd/yyyy)',
        field: 'tmc_port_departure',
        sortable: true,
        width: 125,

        filter: 'agDateColumnFilter',
        filterParams: {
          comparator: dateFilterComparator,
        },
        floatingFilter: true,

        comparator: this.dateComparator,
      },

      {
        headerName: 'Est Arr DT',
        headerTooltip: 'Estimated Arrival Date(mm/dd/yyyy)',
        field: 'estArrival',
        cellRendererFramework: GridConatinerActualdtEditorComponent,
        cellRendererParams: { column: 'estimated_namc_arrival' },
        valueGetter: (params) => {
          if (!this.editEnabled || !params.data.rowCheckBox) {
            return params.data.estimated_namc_arrival;
          } else {
            if (params.data.estArrival) {
              params.data.estArrival = moment(params.data.estArrival).format('MM/DD/YYYY');
            }
            if (params.data.estimated_namc_arrival) {
              params.data.estimated_namc_arrival = moment(params.data.estimated_namc_arrival).format('MM/DD/YYYY');
            }
            const value = params.data.estArrival?.value ?? params.data.estimated_namc_arrival;

            return value;
          }
        },

        sortable: true,
        width: 120,
        floatingFilter: true,
        cellEditor: 'datePicker',
        filter: 'agDateColumnFilter',

        filterParams: {
          comparator: dateFilterComparator,
        },

        comparator: this.dateComparator,
      },
      {
        headerName: 'Act Arr DT',
        headerTooltip: 'Actual Arrival Date(mm/dd/yyyy)',
        field: 'actualArrival',
        cellRendererFramework: GridConatinerActualdtEditorComponent,
        cellRendererParams: { column: 'actual_namc_arrival' },
        valueGetter: (params) => {
          if (!this.editEnabled || !params.data.rowCheckBox) {
            return params.data.actual_namc_arrival;
          } else {
            if (params.data.actual_namc_arrival) {
              params.data.actual_namc_arrival = moment(params.data.actual_namc_arrival).format('MM/DD/YYYY');
            }
            if (params.data.actualArrival) {
              params.data.actualArrival = moment(params.data.actualArrival).format('MM/DD/YYYY');
            }

            const value = params.data.actualArrival?.value ?? params.data.actual_namc_arrival;

            return value;
          }
        },

        sortable: true,
        width: 120,
        floatingFilter: true,

        filter: 'agDateColumnFilter',
        comparator: dateComparator,
        filterParams: {
          comparator: dateFilterComparator,
        },
      },

      {
        headerName: 'Devan DT',
        headerTooltip: 'Devan Date(mm/dd/yyyy)',
        field: 'devan_date',
        sortable: true,
        width: 120,

        filter: 'agDateColumnFilter',
        filterParams: {
          comparator: dateFilterComparator,
        },
        floatingFilter: true,

        comparator: this.dateComparator,
      },

      {
        headerName: 'User',
        headerTooltip: 'User',
        field: 'updated_userid',
        sortable: true,
        valueGetter: function (params) {
          console.log('params', params);
          if (params.data) {
            if (params.data.updated_userid.startsWith('T_')) {
              return params.data.updated_userid.slice(2);
            } else {
              return params.data.updated_userid;
            }
          }
        },
        tooltipField: 'updated_userid',

        width: 150,
        floatingFilter: true,
      },
      {
        headerName: 'Updated DT',
        headerTooltip: 'Updated Date (mm/dd/yyyy hh:mm:ss (Eastern))',
        field: 'updated_date_time',
        sortable: true,
        width: 150,
        floatingFilter: true,
        tooltipField: 'updated_date_time',
      },

      {
        headerName: 'Action',
        field: '',
        sortable: false,
        width: 120,
        floatingFilterComponentFramework: GridDevancontainerComponent,
        floatingFilterComponentParams: { suppressFilterButton: true },
        cellClass: 'ui-grid-cell-contents-auto',

        floatingFilter: true,
        cellRenderer: 'buttonRenderer',

        cellRendererParams: {
          label: 'Click 2',
        },
      },
    ];

    this.headerHeight = 45;

    this.rowSelection = 'multiple';
    this.editType = 'fullRow';
    this.rowSelection = 'multiple';
    this.editType = 'fullRow';
    this.gridOptions = this.gridDataService.getGridOptions();
    this.gridOptions.onGridReady = function (params) {
      this.gridApi = params.api;
    };
    this.gridOptions.onFilterChanged = () => {
      this.checkStatus();
    };
    localStorage.setItem('disableDevan', 'false');

    this.containerSubscription$ = this.stateService.getNamc().subscribe((observable) => {
      this.initializeData();
    });
  }
  initializeData(): void {
    this.onResetDropDown();
    this.itContact = localStorage.getItem('itContact');

    this.rowData = [];
    this.offset = 0;
    this.isEditMode = false;
    this.editEnabled = this.isEditMode;

    this.showSpinner();

    this.namcValue = localStorage.getItem('namcvalue');

    this.user = localStorage.getItem('workdayId');

    this.userRole = localStorage.getItem('UserRoles');

    this.namcName = localStorage.getItem('namcName');

    const access = checkAccessPipe();
    this.access1 = access.access;

    //hide action column when Readonly role or edit =false;
    if (!this.access1.edit) {
      const obj = this.columnDefs.find(
        (o) => o.field === '' // pass this dynamically 'action'
      );

      obj.hide = true;
      if (this.osPartsGrid) {
        this.osPartsGrid.gridOptions.api.refreshHeader();
      }
    }

    this.loadDrop();
    localStorage.setItem('isDevanAll', 'false');
  }

  onPageSizeChanged() {
    if (this.gridOptions?.api) {
      this.gridOptions.api.paginationSetPageSize(Number(this.paginationPageSize));
    }
  }

  canDeactivate() {
    return this.editEnabled;
  }
  showSpinner(message?: string) {
    if (!message) {
      this.spinnerMessage = this.defaultSpinnerMessage;
    } else {
      this.spinnerMessage = message;
    }
  }

  hideSpinner() {
    this.spinner.hide();
  }

  access1;
  onSearch(offset) {
    this.removeMessage();
    if (this.osPartsGrid) {
      this.headerCheckboxChecked = false;
      this.osPartsGrid.gridOptions.api.refreshHeader();

      this.resetGrid();
    }
    if (this.renbanSearchValue && this.vesselSerachValue && this.renbanFromSearchValue && this.renbanRangeSearchValue) {
      this.renbanSearchValue = null;
      this.renbanValue = null;
      this.renbanFrom = null;
      this.renbanTo = null;

      this.vesselSerachValue = null;
      return;
    }
    if (this.vesselSerachValue) {
      this.vesselSerachValue = this.vesselSerachValue.trim();
      const value = this.vesselSerachValue;

      this.rowData = [];

      const searchedVessel = this.vesselNameList.find(
        (vesselName) => vesselName.vessel_name?.toLowerCase() === value.toLowerCase()
      );

      if (!searchedVessel || searchedVessel.vessel_name === 'ALL') {
        this.onResetDropDown();
        this.warningMessage = warningMessage.invalidVesselName;
        return;
      }
      this.selectedrenban = [];

      this.selectedContainer = [];
      this.selectedVesselName = [searchedVessel];
    }
    if (this.renbanSearchValue) {
      this.renbanSearchValue = this.renbanSearchValue.trim();
      const value = this.renbanSearchValue;

      this.rowData = [];

      const searchedRenban = this.renbanList.find(
        (renban) => renban.container_renban?.toLowerCase() === value.toLowerCase()
      );

      if (!searchedRenban || searchedRenban.container_renban === 'ALL') {
        this.onResetDropDown();
        this.warningMessage = warningMessage.invalidRenbanSearch;
        return;
      }

      this.selectedKanban = [];
      this.selectedPartNo = [];
      this.selectedrenban = [];

      this.selectedContainer = [];
      this.selectedrenban = [searchedRenban];
    }

    this.removeMessage();
    this.editEnabled = false;
    this.isEditMode = false;

    if (offset === 0) {
      this.setPageSizeToAll(this.defaultPageSize);
      this.rowData = [];
      this.osPartsGrid.gridOptions.api.setRowData(this.rowData);

      this.offset = 0;
      this.spinner.show();
    }

    if (!this.checkIfAnySelected()) {
      this.rowData = [];
      this.rowData = [];
      this.warningMessage = warningMessage.selectToSearch;

      window.scroll(0, 0);
      this.spinner.hide();
      return false;
    }
    let selectedrenban;
    let selectedContainer;
    let selectedVesselName;
    let seletedStatus;

    if (this.selectedVesselName.length > 0) {
      if (!this.selectedVesselName.some((a) => a.vessel_name === 'ALL')) {
        selectedVesselName = [];
        this.selectedVesselName.forEach((element) => {
          selectedVesselName.push(element.vessel_name);
        });
      }
    }

    if (this.selectedrenban.length > 0) {
      if (!this.selectedrenban.some((a) => a.container_renban === 'ALL')) {
        selectedrenban = [];
        this.selectedrenban.forEach((element) => {
          selectedrenban.push(element.container_renban);
        });
      }
    }
    if (this.selectedContainer.length > 0) {
      if (!this.selectedContainer.some((a) => a.cc === 'ALL')) {
        selectedContainer = [];
        this.selectedContainer.forEach((element) => {
          selectedContainer.push(element.cc);
        });
      }
    }

    if (this.seletedStatus.length > 0) {
      if (!this.seletedStatus.some((a) => a.container_status === 'ALL')) {
        seletedStatus = [];
        this.seletedStatus.forEach((element) => {
          seletedStatus.push(element.container_status);
        });
      }
    }

    const data = {
      offset: offset,
      limit: 8000,
      business_entity: this.namcValue,
      workid: this.user,
      user_role: this.userRole,
      renban: selectedrenban,
      cc: selectedContainer,
      vessel_name: selectedVesselName,

      estimated_arrival: this.estDate,
      renbanFrom: this.renbanFrom,
      renbanTo: this.renbanTo,
      status: seletedStatus,
    };

    this.partstmaintenanceService.getContainerData(data).subscribe({
      error: this.errorCallback,
      next: async (res) => {
        console.log('--------------------', res);
        if (res.body && res.body.containerdata) {
          this.rowData.push(...res.body.containerdata.data);
          this.osPartsGrid.gridOptions.api.setRowData(this.rowData);
          this.dataCount = parseInt(res.body.containerdata.rowCount);
          const records = this.dataCount - (this.offset + 1) * 8000;
          if (records >= 1) {
            this.offset++;
            this.onSearch(this.offset * 8000);
          } else {
            await this.checkStatus();
            this.spinner.hide();
          }
        } else {
          this.rowData = [];
          this.spinner.hide();
        }
        localStorage.setItem('disableDevan', 'false');

        this.setPageSizeToAll();
      },
    });
    return true;
  }

  setPageSizeToAll(pageSize?) {
    this.paginationPageSize = this.rowData.length;

    if (pageSize) {
      this.paginationPageSize = 0;
      this.rowData.length = 0;
    }

    this.onPageSizeChanged();
  }

  loadDrop() {
    this.spinner.show();

    const data = {
      business_entity: this.namcValue,
      workid: this.user,
      user_role: this.userRole,
    };
    const data1 = {
      business_entity: this.namcValue,
      page_name: 'conatiner_maintenance',
      workid: this.user,
      user_role: this.userRole,
    };
    const dataShowHide = {
      business_entity: this.namcValue,
      business_entity_name: this.namcName,
      workid: this.user,
      user_role: this.userRole,
    };

    const lookupSubscriptions = [
      this.pipeLookupService.getCcList(data1),
      this.pipeLookupService.getCcList(data1),
      this.pipeLookupService.getUserShowHideColumns(dataShowHide),
      this.pipeLookupService.getVesselNameList(data),
      this.pipeLookupService.getStatuList(data),
    ];

    forkJoin(lookupSubscriptions).subscribe({
      next: (res) => {
        // if kanban list, add ALL option
        this.renbanList = [];
        this.partNoList = [];
        this.containerList = [];
        this.vesselNameList = [];
        this.statusList = [];
        if (res[0].body.data) {
          this.containerList.push({ cc: 'ALL' });
        }
        this.containerList.push(...res[0].body.data);
        this.containerList = this.containerList.filter(
          (value, index, self) => index === self.findIndex((t) => t.cc === value.cc)
        );

        if (res[1].body.data) {
          this.renbanList.push({ container_renban: 'ALL' });
        }

        this.renbanList.push(...res[1].body.data);

        if (res[3].body.data) {
          this.vesselNameList.push({ vessel_name: 'ALL' });
        }

        this.vesselNameList.push(...res[3].body.data);
        if (res[4].body.data) {
          this.statusList.push({ container_status: 'ALL' });
        }

        this.statusList.push(...res[4].body.data);
        //remove userdevanned and devan completed status from statuslist dropdown

        const statusToRemove = ['DEVAN COMPLETE', 'VANNING COMPLETE'];

        this.statusList = this.statusList.filter((item) => !statusToRemove.includes(item.container_status));

        const { container_maintenance_columns } = res[2].body.showHideColumnsResponse;

        try {
          const hideItems = JSON.parse(container_maintenance_columns);

          if (typeof hideItems === 'object' && hideItems.length > 0) {
            this.selectedShowHideList = this.showHideList.filter(
              (sItem) => !hideItems.map((item) => item.value).includes(sItem.value)
            );

            this.onShowHideChange({
              value: this.selectedShowHideList,
              onInit: true,
            });
          }
        } catch (error) {}

        this.spinner.hide();
      },
      error: this.errorCallback,
    });
  }

  // on click of Undevan button in grid
  // User clicks on “UNDEVAN” button, Pop up to ask confirmation about Undevanning a Renban. After confirmation,
  //Renban should be Undevanned and updated as “NAMC Arrived”.
  undevan(data) {
    this.process='UNDEVAN'

    if (this.isEditMode) {
      return;
    }

    var undevanData = data;
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.id = 'modal-component';
    dialogConfig.height = '204px';
    dialogConfig.width = '450px';
    dialogConfig.data = undevanData;
    const dialogRef = this.dialog.open(ContainerundevanDialogComponent, dialogConfig);
    dialogRef.afterClosed().subscribe((res) => {
      if (res === 'cancel') {
        return;
      } else if (res === 'save') {
        var updatedData = [];
        var pieupdatedData = [];
        var updatedFields = {
          container_status: 'NAMC ARRIVED',
          devan_flag: '',
          estimated_namc_arrival: data.estimated_namc_arrival,
          actual_namc_arrival: data.actual_namc_arrival,
          devan_date: '',
          userid: localStorage.getItem('UserName'),
          container_renban: data.container_renban,
          container_number: data.container_number,
          tmc_port_departure: data.tmc_port_departure,
          tmc_van_completion_date: data.tmc_van_completion_date,
        };
        var updatedFields_pie = {
          container_status: 'NAMC ARRIVED',
          devan_flag: '',
          estimated_namc_arrival: data.estimated_namc_arrival,
          actual_namc_arrival: data.actual_namc_arrival,
          devan_date: '',
          userid: localStorage.getItem('UserName'),
          container_renban: data.container_renban,
          container_number: data.container_number,
          tmc_port_departure: data.tmc_port_departure,
          tmc_van_completion_date: data.tmc_van_completion_date,
          business_entity: this.namcValue,
          source_name: 'PIPE user',
          process_name: 'Container Undevan User',
        };

        updatedData.push(updatedFields);
        pieupdatedData.push(updatedFields_pie);

        this.spinner.show();
        this.editContainer(updatedData, pieupdatedData);

        this.successMessage = successMessage.containerUndevan;
      }
    });
  }

  // on click of Devan in grid conatiner_staus should be updated as "USER DEVANNED"
  // and devan date should be updted as current date
  devan(data) {
    this.process='DEVAN'

    if (this.isEditMode) {
      return;
    }
    console.log(data);
    const exportData = [];
    exportData.push(data);

    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.id = 'modal-component';
    dialogConfig.height = '204px';
    dialogConfig.width = '470px';
    dialogConfig.data = exportData;

    const dialogRef = this.dialog.open(ContainerDevandialogComponent, dialogConfig);

    dialogRef.afterClosed().subscribe((res) => {
      if (res === 'cancel') {
        return;
      } else if (res === 'save') {
        var updatedData = [];
        var pieupdatedData = [];
        var updatedFields = {
          container_status: 'USER DEVANNED',
          devan_flag: 'D',
          estimated_namc_arrival: data.estimated_namc_arrival,
          actual_namc_arrival: data.actual_namc_arrival,
          devan_date: data.devan_date,
          userid: localStorage.getItem('UserName'),
          container_renban: data.container_renban,
          container_number: data.container_number,
          tmc_port_departure: data.tmc_port_departure,
          tmc_van_completion_date: data.tmc_van_completion_date,
        };

        var updatedFields_pie = {
          container_status: 'USER DEVANNED',
          devan_flag: 'D',
          estimated_namc_arrival: data.estimated_namc_arrival,
          actual_namc_arrival: data.actual_namc_arrival,
          devan_date: data.devan_date,
          userid: localStorage.getItem('UserName'),
          container_renban: data.container_renban,
          container_number: data.container_number,
          tmc_port_departure: data.tmc_port_departure,
          tmc_van_completion_date: data.tmc_van_completion_date,
          source_name: 'PIPE user',
          process_name: 'Container Devan User',
          business_entity: this.namcValue,
        };

        updatedData.push(updatedFields);
        pieupdatedData.push(updatedFields_pie);

        updatedData.push(updatedFields);
        this.spinner.show();

        this.editContainer(updatedData, pieupdatedData);

        this.successMessage = successMessage.containerDevan;
      }
    });
  }
  //The DEVAN ALL button should enable only
  //when user has “NAMC Arrived” Renbans in the grid.

  checkStatus() {
    localStorage.setItem('isDevanAll', 'false');

    const exportData = [];
    if (this.osPartsGrid) {
      this.osPartsGrid.gridOptions.api.forEachNodeAfterFilter((node) => {
        exportData.push(node.data);
      });

      const isDevanall = exportData.filter((row) => row.container_status == 'NAMC ARRIVED');
      if (exportData.length !== 0) {
        if (exportData.length === isDevanall.length) {
          localStorage.setItem('isDevanAll', 'true');
          this.context.refreshHeader.next();
          return;
        } else {
          localStorage.setItem('isDevanAll', 'false');
          this.context.refreshHeader.next();
          return;
        }
      }
    }
  }

  devanAll(data) {
    this.process='DEVAN'

    if (this.isEditMode) {
      return;
    }
    const exportData = [];
    if (this.osPartsGrid) {
      this.osPartsGrid.gridOptions.api.forEachNodeAfterFilter((node) => {
        exportData.push(node.data);
      });
    }
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.id = 'modal-component';
    dialogConfig.height = '250px';
    dialogConfig.width = '470px';
    dialogConfig.data = exportData;

    const dialogRef = this.dialog.open(ContainerDevandialogComponent, dialogConfig);

    dialogRef.afterClosed().subscribe((res) => {
      if (res === 'cancel') {
        return;
      } else if (res === 'save') {
        var updatedData = [];
        var pieupdatedData = [];
        exportData.forEach((data) => {
          var updatedFields = {
            container_status: 'USER DEVANNED',
            devan_flag: 'D',
            estimated_namc_arrival: data.estimated_namc_arrival,
            actual_namc_arrival: data.actual_namc_arrival,
            devan_date: data.devan_date,
            userid: localStorage.getItem('UserName'),
            container_renban: data.container_renban,
            container_number: data.container_number,
            tmc_port_departure: data.tmc_port_departure,
            tmc_van_completion_date: data.tmc_van_completion_date,
          };

          var updatedFields_pie = {
            container_status: 'USER DEVANNED',
            devan_flag: 'D',

            estimated_namc_arrival: data.estimated_namc_arrival,
            actual_namc_arrival: data.actual_namc_arrival,
            devan_date: data.devan_date,
            userid: localStorage.getItem('UserName'),
            container_renban: data.container_renban,
            container_number: data.container_number,
            tmc_port_departure: data.tmc_port_departure,
            tmc_van_completion_date: data.tmc_van_completion_date,
            source_name: 'PIPE user',
            process_name: 'Container Devan User',
            business_entity: this.namcValue,
          };

          updatedData.push(updatedFields);
          pieupdatedData.push(updatedFields_pie);
        });

        this.spinner.show();

        this.editContainer(updatedData, pieupdatedData);

        this.successMessage = successMessage.containerDevan;
      }
    });

    // this.opendevanDialog;
  }

  updatedRows = [];
  pietokenValue = '';

  // editContainer(updatedData, pieupdatedData) {
  //   var data1 = {
  //     business_entity: this.namcValue,
  //     workid: this.user,
  //     user_role: this.userRole,
  //     data: updatedData.slice(this.editApiStartPosition, this.editApiEndPosition),
  //   };
  //   this.spinner.show();
  //   this.partstmaintenanceService.editContainer(data1).subscribe({
  //     error: this.errorCallback,
  //     next: (response) => {
  //       console.log("response", response);

  //       this.updatedRows.push(...response.body.containerdata.updatedRows);

  //       if (updatedData.length > this.editApiEndPosition) {
  //         this.timesEditApiCalled++;

  //         this.editApiStartPosition = this.timesEditApiCalled * this.editApiDefaultLimit;

  //         this.editApiEndPosition = this.editApiStartPosition + this.editApiDefaultLimit;
  //         return this.editContainer(updatedData, pieupdatedData);
  //       }

  //       const data = {
  //         business_entity: this.namcValue,
  //         workid: this.user,
  //         user_role: this.userRole
  //       };
  //       this.pietokenValue = '';
  //       this.pipeLookupService.getPieToken(data)
  //         .subscribe({
  //           error: this.errorCallback,
  //           next: (res) => {
  //             console.log("res token---------", res);
  //             this.pietokenValue = res.body.auth_token.access_token;
  //             var updatedData1 = [];
  //             pieupdatedData.forEach((e) => {
  //               var updatedFields = {
  //                 devan_flag: e.devan_flag,
  //                 container_renban: e.container_renban,
  //                 estimated_namc_arrival: moment(e.estimated_namc_arrival).format('YYYY-MM-DD') === 'Invalid date'?
  //                 e.estimated_namc_arrival: moment(e.estimated_namc_arrival).format('YYYY-MM-DD'),
  //                 actual_namc_arrival: moment(e.actual_namc_arrival)?.format('YYYY-MM-DD') === 'Invalid date'?
  //                 '':moment(e.actual_namc_arrival).format('YYYY-MM-DD'),
  //                 source_name: e.source_name,
  //                 process_name: e.process_name,
  //                 business_entity: this.namcValue,
  //                 devan_date: moment(e.devan_date)?.format('YYYY-MM-DD') === 'Invalid date'? e.devan_date:moment(e.devan_date)?.format('YYYY-MM-DD')

  //               };
  //               updatedData1.push(updatedFields);
  //             });
  //             var d1 = {
  //               data: updatedData1
  //             }

  //             this.partstmaintenanceService.sendToPieData(d1, this.pietokenValue).subscribe({
  //               error: this.errorCallback,
  //               next: (response) => {
  //                 console.log("response-pie", response);
  //                 this.spinner.hide();
  //                 updatedData1 = [];
  //               },
  //             });

  //           }
  //         })

  //       this.timesEditApiCalled = 0;
  //       this.editApiStartPosition = 0;
  //       this.editApiEndPosition = this.editApiDefaultLimit;

  //       if (this.updatedRows.length > 0) {

  //         this.rowData = [];
  //         this.spinner.hide();
  //         this.rowData = this.updatedRows;
  //         this.updatedRows = [];

  //         this.osPartsGrid.gridOptions.api.setRowData(this.rowData);
  //       } else {
  //         this.updatedRows = [];
  //         this.warningMessage = `${warningMessage.reportsNotUpdated}  ${this.itContact}.`;
  //       }

  //       this.editEnabled = false;
  //       this.isEditMode = false;
  //       this.headerCheckboxChecked = false;
  //       this.osPartsGrid.gridOptions.api.refreshHeader();
  //       this.resetGrid();
  //       this.checkStatus();
  //       window.scroll(0, 0);
  //       this.setPageSizeToAll();

  //     },
  //   });

  // }
  editContainer(updatedData, pieupdatedData) {
    var data1 = {
      business_entity: this.namcValue,
      workid: this.user,
      user_role: this.userRole,
      data: updatedData.slice(this.editApiStartPosition, this.editApiEndPosition),
    };

    this.spinner.show();
    this.partstmaintenanceService.editContainer(data1).subscribe({
      error: this.errorCallback,
      next: (response) => {
        console.log('response', response);
        const updatedRows = response.body.containerdata.updatedRows;

        // Push new rows to updatedRows
        this.updatedRows.push(...updatedRows);

        if (updatedData.length > this.editApiEndPosition) {
          this.timesEditApiCalled++;
          this.editApiStartPosition = this.timesEditApiCalled * this.editApiDefaultLimit;
          this.editApiEndPosition = Math.min(this.editApiStartPosition + this.editApiDefaultLimit, updatedData.length);

          return this.editContainer(updatedData, pieupdatedData);
        }
         else {
          this.sendDataToPie(pieupdatedData);

          // Reset variables
          this.timesEditApiCalled = 0;
          this.editApiStartPosition = 0;
          this.editApiEndPosition = this.editApiDefaultLimit;

          // Process updatedRows
          if (this.updatedRows.length > 0) {
            console.log('ipdated rows', this.updatedRows);
            this.updatedRows = this.updatedRows.filter(
              (value, index, self) => index === self.findIndex((t) => t.container_renban === value.container_renban)
            );
            this.rowData = [];
            this.spinner.hide();
            this.rowData = this.updatedRows;
            this.updatedRows = [];
            this.osPartsGrid.gridOptions.api.setRowData(this.rowData);
          } else {
            this.warningMessage = `${warningMessage.reportsNotUpdated}  ${this.itContact}.`;
          }

          // Other UI updates
          this.editEnabled = false;
          this.isEditMode = false;
          this.headerCheckboxChecked = false;
          this.osPartsGrid.gridOptions.api.refreshHeader();
          this.resetGrid();
          this.checkStatus();
          window.scroll(0, 0);
          this.setPageSizeToAll();
        }
      },
    });
  }

  sendDataToPie(pieupdatedData) {
    const data = {
      business_entity: this.namcValue,
      workid: this.user,
      user_role: this.userRole,
    };
    // Mail code added
    this.piedata=[];
    let dataSlice1=pieupdatedData
    dataSlice1.forEach(item => {
      let formattedRecord = {
          business_entity:this.namcValue,
          renban:item.container_renban,
          estimated_namc_arrival: moment(item.estimated_namc_arrival)?.isValid() ? moment(item.estimated_namc_arrival).format('YYYY-MM-DD') : '          ',
          actual_namc_arrival: moment(item.actual_namc_arrival)?.isValid() ? moment(item.actual_namc_arrival).format('YYYY-MM-DD') : '          ',
          devan_date: moment(item.devan_date)?.isValid() ? moment(item.devan_date).format('YYYY-MM-DD') : '          ',
          devan_flag:item.devan_flag === null ? '   ':item.devan_flag==='' ? '   ' :item.devan_flag,
          user: localStorage.getItem('UserName')
      };
  
      this.piedata.push(formattedRecord);
  });


    this.pipeLookupService.getPieToken(data).subscribe({
      error: this.pieerrorCallback,
      next: (res) => {
        console.log('res token---------', res);
        const pietokenValue = res.body.auth_token.access_token;
        let Index = 0;
        const dataLimt = this.editApiDefaultLimit;
        const sendData = () => {
          const startIdx = Index * dataLimt;
          const endIdx = Math.min(startIdx + dataLimt, pieupdatedData.length);
          const pieData = pieupdatedData.slice(startIdx, endIdx).map((e) => ({
            devan_flag: e.devan_flag,
            container_renban: e.container_renban,
            estimated_namc_arrival: moment(e.estimated_namc_arrival).isValid()
              ? moment(e.estimated_namc_arrival).format('YYYY-MM-DD')
              : '',
            actual_namc_arrival: moment(e.actual_namc_arrival)?.isValid()
              ? moment(e.actual_namc_arrival).format('YYYY-MM-DD')
              : '',
            source_name: e.source_name,
            process_name: e.process_name,
            business_entity: this.namcValue,
            devan_date: moment(e.devan_date)?.isValid() ? moment(e.devan_date).format('YYYY-MM-DD') : '',
          }));
          const d1 = { data : pieData };
          this.partstmaintenanceService.sendToPieData(d1, pietokenValue).subscribe({
            error: this.pieerrorCallback,
            next: (response) => {
              console.log('response-pie', response);
              Index++;
              if (endIdx < pieupdatedData.length) {
                this.piedata=[]

                sendData();
              } else {
                this.spinner.hide();
              }
            },
          });
        };
        sendData();
      },
    });
  }

  onRenbanChange(): void {
    if (this.selectedrenban.length >= 1) {
      if (this.selectedrenban[this.selectedrenban.length - 1].container_renban === 'ALL') {
        this.renbanSearchValue = null;
        this.vesselSerachValue = null;
        this.selectedVesselName = [];

        this.renbanValue = null;
        this.renbanFrom = null;
        this.renbanTo = null;

        this.selectedrenban = [];
        this.selectedrenban.push(this.renbanList[0]);
      } else {
        this.renbanSearchValue = null;
        this.renbanValue = null;
        this.renbanFrom = null;
        this.renbanTo = null;

        this.vesselSerachValue = null;
        this.selectedVesselName = [];

        let indexAll = this.selectedrenban.findIndex((data) => data.container_renban === 'ALL');

        if (indexAll > -1) {
          this.selectedrenban.splice(indexAll, 1);
        }
      }
    }
  }

  onStatusChange(): void {
    if (this.seletedStatus.length >= 1) {
      if (this.seletedStatus[this.seletedStatus.length - 1].container_status === 'ALL') {
        this.renbanSearchValue = null;
        this.renbanValue = null;
        this.renbanFrom = null;
        this.renbanTo = null;
        this.vesselSerachValue = null;

        this.seletedStatus = [];
        this.seletedStatus.push(this.statusList[0]);
      } else {
        this.renbanSearchValue = null;
        this.renbanValue = null;
        this.renbanFrom = null;
        this.renbanTo = null;
        this.vesselSerachValue = null;

        const indexAll = this.seletedStatus.findIndex((data) => data.container_status === 'ALL');

        if (indexAll > -1) {
          this.seletedStatus.splice(indexAll, 1);
        }
      }
    }
  }
  onResetDropDown() {
    this.removeMessage();
    localStorage.setItem('isDevanAll', 'false');
    this.selectedKanban = [];
    this.selectedPartNo = [];
    this.selectedContainer = [];
    this.selectedrenban = [];
    this.selectedVesselName = [];
    this.seletedStatus = [];

    this.kanbanSearchValue = null;
    this.renbanSearchValue = null;
    this.vesselSerachValue = null;
    this.renbanValue = null;
    this.renbanFrom = null;
    this.renbanTo = null;

    this.renbanFromSearchValue = null;
    this.renbanRangeSearchValue = null;

    this.estDate = this.onInitEstdate;

    if (this.osPartsGrid) {
      this.headerCheckboxChecked = false;
      this.osPartsGrid.gridOptions.api.refreshHeader();
      this.resetGrid();
    }
    this.setPageSizeToAll(this.defaultPageSize);

    this.rowData = [];
    this.disableKanban = false;
    this.disablePartNumber = false;
    this.offset = 0;
  }

  onESTChange() {
    this.renbanValue = null;
    this.renbanFrom = null;
    this.renbanTo = null;
    this.vesselSerachValue = null;
  }

  resetGrid() {
    if (this.osPartsGrid) {
      resetSortingAndFilters(this.osPartsGrid);

      return;
    }
  }

  container_maintenance_columns;
  onShowHideChange(ev: any) {
    this.osPartsGrid.gridOptions.columnApi.setColumnsVisible([...this.showHideList.map((e) => e.value)], true);

    const hideItems = this.showHideList.filter((item) => {
      return !ev.value.map((sItem) => sItem.value).includes(item.value);
    });

    this.osPartsGrid.gridOptions.columnApi.setColumnsVisible([...hideItems.map((e) => e.value)], false);

    if (ev.onInit) {
      return;
    }

    this.container_maintenance_columns = hideItems;
    if (!hideItems.length) this.container_maintenance_columns = [{}];
  }
  saveShowHide() {
    let container_maintenance_columns = this.container_maintenance_columns;
    const data = {
      business_entity: this.namcValue,
      business_entity_name: this.namcName,
      workid: this.user,
      user_role: this.userRole,
    };

    if (container_maintenance_columns) {
      this.pipeLookupService.updateUserShowHideColumns(data, { container_maintenance_columns }).subscribe({
        error: this.errorCallback,
        next: (res) => {
          this.spinner.hide();
        },
      });
    } else {
      this.spinner.hide();
    }
  }

  onKanbanChange(): void {
    if (this.selectedKanban.length >= 1) {
      if (this.selectedKanban[this.selectedKanban.length - 1].kanban === 'ALL') {
        this.renbanSearchValue = null;
        this.vesselSerachValue = null;
        this.renbanValue = null;
        this.renbanFrom = null;
        this.renbanTo = null;

        this.selectedKanban = [];
        this.selectedKanban.push(this.kanbanList[0]);
      } else {
        let indexAll = this.selectedKanban.findIndex((data) => data.kanban === 'ALL');

        this.renbanSearchValue = null;
        this.vesselSerachValue = null;
        this.renbanValue = null;
        this.renbanFrom = null;
        this.renbanTo = null;

        if (indexAll > -1) {
          this.selectedKanban.splice(indexAll, 1);
        }
      }
    }
    if (this.selectedKanban.length > 0) {
      this.disablePartNumber = true;
    } else {
      this.disablePartNumber = false;
    }
  }

  onContainerChange(): void {
    if (this.selectedContainer.length >= 1) {
      if (this.selectedContainer[this.selectedContainer.length - 1].cc === 'ALL') {
        this.renbanSearchValue = null;
        this.vesselSerachValue = null;

        this.renbanValue = null;
        this.renbanFrom = null;
        this.renbanTo = null;

        this.selectedVesselName = [];
        this.selectedContainer = [];
        this.selectedContainer.push(this.containerList[0]);
      } else {
        this.renbanSearchValue = null;
        this.vesselSerachValue = null;

        this.renbanValue = null;
        this.renbanFrom = null;
        this.renbanTo = null;

        this.selectedVesselName = [];

        const indexAll = this.selectedContainer.findIndex((data) => data.cc === 'ALL');

        if (indexAll > -1) {
          this.selectedContainer.splice(indexAll, 1);
        }
      }
    }
  }

  openDialog1() {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.id = this.modalComponent;
    dialogConfig.height = '250px';
    dialogConfig.width = '470px';
    dialogConfig.data='ospipe'
    const dialogRef = this.dialog.open(ConfirmCancelDailogComponent, dialogConfig);
    dialogRef.afterClosed().subscribe((res) => {
      if (res === 'cancel') {
        this.columnDefs[7].cellStyle = function (params) {
          if ('modified' in params.data) {
            return { backgroundColor: '#E4ECF0' };
          } else {
            return { backgroundColor: 'white' };
          }
        };

        this.onSearch(0);
        this.editEnabled = false;
        this.isEditMode = false;
        this.osPartsGrid.gridOptions.api.refreshHeader();
      }
    });
  }

  cancelEdit(): void {
    this.removeMessage();
    this.openDialog1();
  }

  openConfirmationDialog() {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.id = 'modal-component';
    dialogConfig.height = '204px';
    dialogConfig.width = '470px';

    const dialogRef = this.dialog.open(ConfirmCancelDailogComponent, dialogConfig);

    dialogRef.afterClosed().subscribe((res) => {
      if (res === 'cancel') {
        return;
      }
    });
  }

  reviewData(): void {
    this.removeMessage();
    this.modifiedRowData = this.getModifiedRowData();
    console.log('modifiedData', this.modifiedRowData);

    if (this.modifiedRowData.length === 0) {
      this.warningMessage = warningMessage.editToReview;

      window.scroll(0, 0);
      return;
    }
    var editedRecords = [];
    this.modifiedRowData = this.modifiedRowData.filter((element) => element.isActualarrival || element.isestArrival);
    console.log('editedRecords', this.modifiedRowData);

    if (this.modifiedRowData.length === 0) {
      this.warningMessage = warningMessage.editToReview;

      window.scroll(0, 0);
      return;
    }

    Promise.all(
      this.modifiedRowData.map(async (element, index) => {
        let p = [];
        element.error = '';
        element.container_status_updated = element.container_status;

        // 	If the actual arrival date emptied out,
        // once the user confirms the same in popup screen,
        // user can save changes – The status of the Renban should be updated
        // as “In-Transit”
        if (element.actualArrival) {
          if (
            element.container_status?.toLowerCase() === 'NAMC ARRIVED'.toLowerCase() &&
            element.actual_namc_arrival === ''
          ) {
            p = [];
            element.container_status_updated = 'IN-TRANSIT';
          }
          if (
            element.container_status?.toLowerCase() === 'IN-TRANSIT'.toLowerCase() &&
            element.estimated_namc_arrival == ''
          ) {
            p.push('Estimated Arrival date cannot be empty/blank');
          }
        }

        if (element.isestArrival) {
          if (
            element.container_status?.toLowerCase() === 'NAMC ARRIVED'.toLowerCase() &&
            element.estimated_namc_arrival == ''
          ) {
            p = [];

            p.push('Estimated Arrival date cannot be empty/blank');
          }
          if (
            element.container_status?.toLowerCase() === 'IN-TRANSIT'.toLowerCase() &&
            element.estimated_namc_arrival == ''
          ) {
            p = [];

            p.push('Estimated Arrival date cannot be empty/blank');
          }
        }

        // EDIT ON INTRANSIT-If the actual arrival date is having data, once the user confirms the data entered,
        //user can save changes – The Status of the Renban should be updated
        // as “NAMC Arrived”
        if (
          element.container_status?.toLowerCase() === 'IN-TRANSIT'.toLowerCase() &&
          element.actual_namc_arrival != null
        ) {
          element.container_status_updated = 'NAMC ARRIVED';
        }
        if (
          (element.container_status?.toLowerCase() === 'IN-TRANSIT'.toLowerCase() &&
            element.actual_namc_arrival === '') ||
          element.actual_namc_arrival === null
        ) {
          element.container_status_updated = 'IN-TRANSIT';
        }

        if (p.length > 0) {
          element.error = p
            .map(function (val, index) {
              return `${(index + 1).toString()}.   ${val}`;
            })
            .join('\n');
        }
      })
    );

    if (this.modifiedRowData.length > 0) {
      this.openReviewDialog(this.modifiedRowData);
    }
  }

  getModifiedRowData(): any {
    const modifiedRowData = this.rowData.filter((row) => row.rowCheckBox);
    return modifiedRowData;
  }

  getSelectedRowData(): any {
    const selectedRowData = this.rowData.filter((row) => row.rowCheckBox);
    return selectedRowData;
  }

  checkIfAnySelected() {
    if (this.renbanSearchValue) return true;
    if (this.vesselSerachValue) return true;
    if (this.renbanRangeSearchValue) return true;
    if (this.renbanFromSearchValue) return true;

    if (
      this.selectedContainer.length > 0 ||
      this.selectedrenban.length > 0 ||
      this.estDate ||
      this.seletedStatus.length > 0
    ) {
      return true;
    }

    return false;
  }

  clearKanbanValue() {
    this.renbanSearchValue = null;
    this.renbanValue = null;
    this.renbanFrom = null;
    this.renbanTo = null;
  }
  onEnterPressed(event) {
    if(this.isSearchDisabled) {
      return;
    }
    this.isSearchDisabled = true;
    this.handleVesselSearch(event);
    setTimeout(()=>{
    this.isSearchDisabled = false;
    }, 1500);
  }
  handleVesselSearch(event) {
    this.vesselSerachValue = this.vesselSerachValue.trim();
    const value = this.vesselSerachValue;

    this.rowData = [];

    const searchedVessel = this.vesselNameList.find(
      (vesselName) => vesselName.vessel_name?.toLowerCase() === value.toLowerCase()
    );

    if (!searchedVessel || searchedVessel.vessel_name === 'ALL') {
      this.onResetDropDown();
      this.warningMessage = warningMessage.invalidVesselName;
      return;
    }

    this.selectedrenban = [];
    this.estDate = null;
    this.selectedContainer = [];
    this.seletedStatus = [];
    this.renbanValue = null;
    this.renbanFrom = null;
    this.renbanTo = null;
    this.renbanSearchValue = null;
    this.renbanFromSearchValue = null;
    this.renbanRangeSearchValue = null;

    this.selectedVesselName = [searchedVessel];

    this.onSearch(0);
  }
  handleRenbanSearch(event) {
    this.vesselSerachValue = null;
    this.renbanSearchValue = this.renbanSearchValue.trim();
    const value = this.renbanSearchValue;

    this.rowData = [];

    const searchedRenban = this.renbanList.find(
      (renban) => renban.container_renban?.toLowerCase() === value.toLowerCase()
    );

    if (!searchedRenban || searchedRenban.container_renban === 'ALL') {
      this.onResetDropDown();
      this.warningMessage = warningMessage.invalidRenbanSearch;
      return;
    }

    this.selectedKanban = [];
    this.selectedPartNo = [];
    this.selectedrenban = [];
    this.seletedStatus = [];
    this.estDate = null;
    this.selectedContainer = [];
    this.selectedVesselName = [];
    this.selectedrenban = [searchedRenban];

    this.onSearch(0);
  }
  onEnterPressedRenban(event) {
    if(this.isSearchDisabledRenban) {
      return;
    }
    this.isSearchDisabledRenban = true;
    this.renbanSearch(event);
    setTimeout(()=>{
    this.isSearchDisabledRenban = false;
    }, 1500);
 }
  renbanSearch(event) {
    this.selectedrenban = [];

    this.renbanValue = this.renbanValue.trim().toUpperCase();

    this.omit_special_char(event);
    const onlyHyphens = this.containsOnlyHyphens(this.renbanValue);
    if (onlyHyphens) {
      this.warningMessage = 'Invalid Format, Format should be X, X-Y, X-';
      return false;
    }

    const lastChar = this.renbanValue.substring(this.renbanValue.length - 1);
    if (lastChar === '-' && this.renbanValue) {
      this.renbanFromSearchValue = this.renbanValue;
      this.handleRenbanFromSearch(event);
      return false;
    }

    if (this.renbanValue.includes('-')) {
      this.renbanRangeSearchValue = this.renbanValue;
      this.handleRenbanRangeSearch(event);
      return false;
    }
    if (!this.renbanValue.includes('-')) {
      this.renbanSearchValue = this.renbanValue;
      this.handleRenbanSearch(event);
      return false;
    }
  }

  handleRenbanFromSearch(event) {
    console.log('renban from search...');

    this.selectedrenban = [];
    this.renbanRangeSearchValue = null;
    this.renbanSearchValue = null;

    this.vesselSerachValue = null;
    this.renbanFromSearchValue = this.renbanFromSearchValue.trim().toUpperCase();

    const renbanFromSearchValue = this.renbanFromSearchValue;

    const renbanRangeSearchValueSplit = renbanFromSearchValue.split('-');
    this.renbanFrom = renbanRangeSearchValueSplit[0];
    this.renbanTo = this.renbanList[this.renbanList.length - 1].container_renban;

    this.rowData = [];

    const searchedFromRenban = this.renbanList.filter(
      (renban) => renban.container_renban?.toLowerCase() === this.renbanFrom.toLowerCase()
    );

    if (!searchedFromRenban || searchedFromRenban.container_renban === 'ALL') {
      this.onResetDropDown();
      this.warningMessage = warningMessage.invalidRenbanSearch;
      return;
    }

    this.selectedKanban = [];
    this.selectedPartNo = [];
    this.selectedrenban = [];

    this.selectedContainer = [];
    this.selectedrenban = [];
    this.seletedStatus = [];
    this.selectedVesselName = [];

    this.onSearch(0);
  }

  handleRenbanRangeSearch(event) {
    this.selectedrenban = [];

    this.renbanSearchValue = null;

    this.vesselSerachValue = null;
    this.renbanRangeSearchValue = this.renbanRangeSearchValue.trim().toUpperCase();

    const renbanRangeSearchValue = this.renbanRangeSearchValue;
    if (renbanRangeSearchValue.includes('-')) {
      const renbanRangeSearchValueSplit = renbanRangeSearchValue.split('-');
      this.renbanFrom = renbanRangeSearchValueSplit[0];
      this.renbanTo = renbanRangeSearchValueSplit[1];
    }

    const greaterRenban = this.findGreaterElement(this.renbanFrom, this.renbanTo);
    console.log(greaterRenban, 'greater');

    if (greaterRenban === this.renbanFrom) {
      this.warningMessage = 'Please enter valid range.';
      return false;
    }

    this.rowData = [];

    const searchedRangeRenban = this.checkIfExist([this.renbanFrom, this.renbanTo]);

    if (!searchedRangeRenban || searchedRangeRenban.container_renban === 'ALL') {
      this.onResetDropDown();
      this.warningMessage = warningMessage.invalidRenbanSearch;
      return;
    }

    this.selectedKanban = [];
    this.selectedPartNo = [];
    this.selectedrenban = [];
    this.seletedStatus = [];
    this.selectedVesselName = [];

    this.selectedContainer = [];
    this.selectedrenban = [];

    this.onSearch(0);
  }

  checkIfExist(inputValues) {
    // Check if any input value exists in the data array (case-insensitive)
    const uppercaseInputValues = inputValues.map((value) => value.toUpperCase());

    // Check if all input values exist in the data array (case-insensitive)
    return uppercaseInputValues.every((input) =>
      this.renbanList.some((item) => item.container_renban.toUpperCase() === input)
    );
  }

  findGreaterElement(str1: string, str2: string): string {
    const alpha1 = str1.match(/[a-zA-Z]+/)[0];
    const alpha2 = str2.match(/[a-zA-Z]+/)[0];

    if (alpha1 !== alpha2) {
      return alpha1 > alpha2 ? str1 : str2;
    } else {
      const num1 = parseInt(str1.match(/\d+/)[0], 10);
      const num2 = parseInt(str2.match(/\d+/)[0], 10);
      return num1 > num2 ? str1 : str2;
    }
  }

  omit_special_char(event) {
    var k;
    k = event.charCode;
    if (k == 45) {
      return true;
    }

    return (k > 64 && k < 91) || (k > 96 && k < 123) || k == 8 || k == 32 || (k >= 48 && k <= 57);
  }

  containsOnlyHyphens(str: string): boolean {
    return /^-+$/.test(str);
  }

  modifiedRowData = [];

  ReviewChanges() {
    this.modifiedRowData = this.getModifiedRowData();
    console.log('modifiedData', this.modifiedRowData);

    if (this.modifiedRowData.length === 0) {
      this.warningMessage = warningMessage.editToReview;

      window.scroll(0, 0);
      return;
    }
    var editedRecords = [];
    this.modifiedRowData = this.modifiedRowData.filter((element) => element.isActualarrival);
    console.log('editedRecords', this.modifiedRowData);

    if (this.modifiedRowData.length === 0) {
      this.warningMessage = warningMessage.editToReview;

      window.scroll(0, 0);
      return;
    }

    Promise.all(
      this.modifiedRowData.map(async (element, index) => {
        let p = [];
        element.error = '';

        //if USER EDIT ACTUAL ARRIVAL FOR IN-TRANSIT STATUS AND ESTIMATED DATE IS NOT EMPTY
        //CHANGE CONATINER STAUS TO NAMC ARRIVED
        if (element.isActualarrival) {
          if (element.container_status === 'IN-TRANSIT' && element.actual_namc_arrival) {
            element.container_status = 'NAMC ARRIVED';
          }
        }
        if (element.isestArrival) {
          if (element.container_status === 'NAMC ARRIVED') {
            element.container_status = 'IN-TRANSIT';
          }
        }

        //IF USER DATE FOR NAMC ARRIVED UPADTE STAUS AS IN-TRANSIT
        if (p.length > 0) {
          element.error = p
            .map(function (val, index) {
              return `${(index + 1).toString()}.   ${val}`;
            })
            .join('\n');
        }
      })
    );

    if (this.modifiedRowData.length > 0) {
      this.openReviewDialog(this.modifiedRowData);
    }
  }

  openReviewDialog(editedRecords) {
    var data = {
      modifiedRowData: editedRecords,
    };
    console.log('Modified', data.modifiedRowData);

    if (data.modifiedRowData.length > 0) {
      const dialogConfig = new MatDialogConfig();
      dialogConfig.disableClose = true;
      dialogConfig.id = this.modalComponent;
      dialogConfig.height = '350px';
      dialogConfig.width = '1000px';

      dialogConfig.data = data;
      const dialogRef = this.dialog.open(ReviewContainerComponentComponent, dialogConfig);
      dialogRef.afterClosed().subscribe((res) => {
        if (res === 'cancel') {
          return;
        } else if (res === 'save') {
          console.log('insiide save', data.modifiedRowData);
          var updatedData = [];
          var pieupdatedData = [];
          data.modifiedRowData.forEach((e) => {
            var updatedFields = {
              container_status: e.container_status_updated,
              devan_flag: e.devan_flag === null ? '' : e.devan_flag,
              estimated_namc_arrival: e.estimated_namc_arrival,
              actual_namc_arrival: e.actual_namc_arrival,
              devan_date: e.devan_date,
              userid: localStorage.getItem('UserName'),
              container_renban: e.container_renban,
              container_number: e.container_number,
              tmc_port_departure: e.tmc_port_departure,
              tmc_van_completion_date: e.tmc_van_completion_date,
            };

            var updatedFields_pie = {
              container_status: e.container_status_updated,
              devan_flag: e.devan_flag === null ? '' : e.devan_flag,
              estimated_namc_arrival: e.estimated_namc_arrival,
              actual_namc_arrival: e.actual_namc_arrival,
              devan_date: e.devan_date,
              userid: localStorage.getItem('UserName'),
              container_renban: e.container_renban,
              container_number: e.container_number,
              tmc_port_departure: e.tmc_port_departure,
              tmc_van_completion_date: e.tmc_van_completion_date,
              source_name: 'PIPE user',
              process_name: 'Container Status',
              business_entity: this.namcValue,
            };
            updatedData.push(updatedFields);
            pieupdatedData.push(updatedFields_pie);
          });
          // this.successMessage = 'Record has been updated successfully';
          window.scroll(0, 0);

          this.spinner.show();

          this.editContainer(updatedData, pieupdatedData);

          this.successMessage = successMessage.recordUpdated;
        }
      });
    } else {
      this.warningMessage = warningMessage.selectRowsToEdit;

      window.scroll(0, 0);
    }
  }

  errorCallback = (error) => {
    console.error(error);
    this.hideSpinner();

    this.removeMessage();
    this.warningMessage = `${warningMessage.apiLogicFail}  ${this.itContact}.`;
  };
  piedata=[]
  pieerrorCallback = (error) => {
    console.log("-----error in sending data to pie------")
    console.error(error);
    const data = {
      business_entity: this.namcValue,
      workid: this.user,
      user_role: this.userRole,
      msg: 'An error occurred while sending data to PIE during the '+ this.process +' process for NAMC '+ this.namcName,
      error: 'error',
      data: this.piedata
    };
    this.pipeLookupService.sendErrormsg(data).subscribe({
      error: this.errorCallback,
      next: (res) => {
        console.log(res);
        this.spinner.hide();
      },
    });
    this.spinner.hide();

    this.removeMessage();
  };


  onEditMode() {
    this.removeMessage();
    let data = [];
    data = this.getModifiedRowData();
    localStorage.setItem('disableDevan', 'true');

    if (data.length > 0) {
      this.isEditMode = !this.isEditMode;
      this.editEnabled = this.isEditMode;

      if (this.isEditMode) {
        this.osPartsGrid.gridOptions.api.forEachNode(function (rowNode, index) {
          rowNode.data.checkedEditMode = true;
          if (rowNode.data.rowCheckBox) {
          } else {
            rowNode.data.checkedEditMode = false;
          }
          rowNode.setData(rowNode.data);
        });
      } else {
        this.osPartsGrid.gridOptions.api.forEachNode(function (rowNode, index) {
          if (rowNode.data.rowCheckBox) {
            rowNode.data.checkedEditMode = false;
          }
          rowNode.setData(rowNode.data);
        });
      }
      setTimeout(() => {
        window.scroll(0, document.body.scrollHeight);
      }, 200);
    } else {
      this.isEditMode = false;
      this.editEnabled = false;
      this.warningMessage = warningMessage.selectRowsToEdit;
      window.scroll(0, 0);
    }
  }
  userCheckChanged(checked, rowIndex) {
    if (
      this.warningMessage === 'Please select at least one row to edit.' ||
      this.warningMessage === 'No rows are selected.'
    ) {
      this.warningMessage = '';
    }
    this.showSpinner();

    const rowNode = this.osPartsGrid.gridOptions.api.getDisplayedRowAtIndex(rowIndex);

    if (checked === true) {
      rowNode.setSelected(true);
      if (this.isEditMode) {
        rowNode.data.checkedEditMode = true;
      }
      rowNode.data.rowCheckBox = true;
    } else {
      rowNode.setSelected(false);
      rowNode.data.checkedEditMode = false;
      rowNode.data.rowCheckBox = false;
    }

    this.gridOptions.getRowStyle = function (params) {
      if (params.node.rowIndex === rowIndex) {
        const color = checked ? '#E4ECF0' : 'white';
        return { background: color };
      }
      return { background: 'white' };
    };

    rowNode.setData(rowNode.data);
    this.toggleHeaderCheckbox(checked);
    this.hideSpinner();
  }

  headerCheckChanged(event) {
    if (
      this.warningMessage === 'Please select at least one row to edit.' ||
      this.warningMessage === 'No rows are selected.'
    ) {
      this.warningMessage = '';
    }
    this.headerCheckboxChecked = event.checked;
    const headerChecked = event.checked;
    const isEditable = this.isEditMode;
    this.osPartsGrid.gridOptions.api.forEachNode(function (rowNode, index) {
      if (headerChecked) {
        if (isEditable) {
          rowNode.data.checkedEditMode = true;
        }
        rowNode.data.rowCheckBox = true;
      } else {
        rowNode.data.rowCheckBox = false;
        rowNode.data.checkedEditMode = false;
      }

      rowNode.setData(rowNode.data);
    });
  }

  toggleHeaderCheckbox(checked) {
    if (!checked) {
      this.headerCheckboxChecked = false;

      this.osPartsGrid.gridOptions.api.refreshHeader();

      return;
    }

    const rowCount = this.osPartsGrid.gridOptions.rowData.length;
    const selectedRowCount = this.osPartsGrid.gridOptions.rowData.filter((x) => x.rowCheckBox === true).length;
    console.log('rowCount', rowCount);
    console.log('selectedRowCount', selectedRowCount);

    if (rowCount === selectedRowCount) {
      this.headerCheckboxChecked = true;

      this.osPartsGrid.gridOptions.api.refreshHeader();
    }
  }

  exportAsXLSX(): void {
    this.spinner.show();
    let easternCurrentDate = String(mm().tz('US/Michigan').format('YYYY-MM-DD_HH:mm:ss'));
    console.log(easternCurrentDate);

    let data = [];
    if (this.headerCheckboxChecked) {
      console.log('Hello');
      this.osPartsGrid.gridOptions.api.forEachNodeAfterFilter((node) => {
        data.push(node.data);
      });
    } else {
      data = this.getSelectedRowData();
    }

    setTimeout(async () => {
      if (data.length > 0) {
        const exportData: any = [];

        data.forEach((e) => {
          const obj = {
            container_status: e.container_status,
            container_renban: e.container_renban,
            shipping_carrier: e.vessel_company,
            vessel_name: e.vessel_name,
            container_number: e.container_number,
            tmc_van_completion_date: e.tmc_van_completion_date,
            tmc_port_departure: e.tmc_port_departure,
            estimated_namc_arrival: e.estimated_namc_arrival,
            actual_namc_arrival: e.actual_namc_arrival,
            devan_date: e.devan_date,
            updated_userid: e.updated_userid,
            updated_date_time: e.updated_date_time,
          };
          exportData.push(obj);
        });
        const headers = [
          'Status',
          'Renban',
          'Shipping Carrier',
          'Vessel Name',
          'Container No',
          'Van Comp DT',
          'Vess Sail DT',
          'Est Arr DT',
          'Act Arr DT',
          'Devan DT',
          'User',
          'Updated DT',
        ];

        const timestamp = easternCurrentDate

          .split('.')[0]
          .replace('T', '_')
          .split('')
          .filter((d) => d !== '-' && d !== ':')
          .join('');

        if (exportData.length > 0) {
          this.excelService.exportAsExcelFile(exportData, 'ContainerMaintenance' + timestamp, headers);
        }
      } else {
        this.warningMessage = warningMessage.noRowsSelected;
      }

      this.hideSpinner();
    }, 1000);
  }
  removeMessage() {
    this.warningMessage = '';
    this.successMessage = '';
  }


}