import { Component, Inject } from '@angular/core';
import {
  MatLegacyDialogRef as MatDialogRef,
  MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA,
} from '@angular/material/legacy-dialog';


@Component({
  selector: 'app-update-devanplan',
  templateUrl: './update-devanplan.component.html',
  styleUrls: ['./update-devanplan.component.scss']
})
export class updateDevanPlanComponent {

  constructor(@Inject(MAT_DIALOG_DATA) public data,) { }
  rowdata: any = [];
  renban: any = [];
  renbans = '';
  mesaage: string;


  ngOnInit(): void {
    console.log("data",this.data,this.data[0].isSchedule)
    console.log("inside popup")
    this.mesaage = "Are you sure you want to update devan plan"
    if(this.data,this.data[0].isRecalculate){
      this.mesaage = "Are you sure you want recalculate"

    }
    if(this.data,this.data[0]?.updateDevanPlan){
      this.mesaage = "Are you sure you want to update devan plan"

    }


  }






}




