<div class="background">
  <div class="successMessage alert-dismissible">
    <div *ngIf="successMessage && !orderData" class="alert alert-success" role="alert">
      {{ successMessage }}

      <button type="button" class="btn-close" (click)="removeMessage()" data-bs-dismiss="alert"
        aria-label="Close"></button>
    </div>
    <div *ngIf="successMessage && orderData" class="alert alert-success" role="alert">
      {{ successMessage }}
      <button (click)="handleAirOrderClick(orderData)" type="button" class="btn btn-light download-icon">
        <img src="./assets/images/Group 115533.png" class="downloadIcon" alt="download icon" />
      </button>
      <button type="button" class="btn-close" (click)="removeMessage()" data-bs-dismiss="alert"
        aria-label="Close"></button>
    </div>
    <div *ngIf="warningMessage" class="alert alert-warning" role="alert">
      {{ warningMessage }}

      <button *ngIf="warningMessage" type="button" class="btn-close" (click)="removeMessage()" data-bs-dismiss="alert"
        aria-label="Close"></button>
    </div>
  </div>

  <div class="container-fluid">
    <div class="d-flex flex-row">
      <div class="p-2 flex-grow-1">
        <h2 class="heading">Air Freight Order</h2>
      </div>
      <div class="p-2">
        <button (click)="getdownloadTemplate()" type="button" class="btn btn-light">
          <img src="./assets/images/Group 115533.png" class="downloadIcon" alt="download icon"
            (click)="getdownloadTemplate()" />
          TEMPLATE
        </button>
      </div>
      <div class="p-2">
        <button (click)="configureParameter()" type="button" class="btn btn-light" style="font-size: 12px;">          
          CONFIGURE PARAMETER
        </button>
      </div>
      <div class="p-2">
        <button type="button" class="master-primary-btn" (click)="addNew()" style="margin-top: -5px;">
          ADD AIR FREIGHT
        </button>
        <!-- <button *ngIf="addGetResponse.length>0" type="button" class="master-primary-btn" (click)="viewCart()">
            View Cart
          </button> -->
      </div>
    </div>
  </div>
  <div class="container-fluid">
    <div class="row">
      <div class="card">
        <div class="container-fluid">
          <div class="row">
            <div class="col-xl-1 max-width-01 col-md-2 col-sm-3">
              <div class="drpchildctrl">
                <span class="v1"></span>
                <label class="searchLable">DEST CODE</label>
                <p-multiSelect [(ngModel)]="selectedDestCode" [showToggleAll]="false" (onChange)="onDestCodeChange()"
                  [options]="destCodeList" optionLabel="dest_code" [showTransitionOptions]="'0ms'"
                  [hideTransitionOptions]="'0ms'" [defaultLabel]="'--Select--'" autoWidth="true"
                  [style]="{ width: '100%' }" [resetFilterOnHide]="true" [appendTo]="'body'"
                  [panelStyle]="{ width: '60%' }">
                </p-multiSelect>
              </div>
            </div>
            <div class="col-xl-1 max-width-01 col-md-2 col-sm-3">
              <div class="drpchildctrl">
                <label class="searchLable">ORDER DT FROM </label>
                <input [(ngModel)]="ordDate" [max]="ordDateTo" class="form-control-sm" type="date" autoWidth="false" />
              </div>
            </div>
            <div class="col-xl-1 max-width-01 col-md-2 col-sm-3">
              <div class="drpchildctrl">
                <label class="searchLable">ORDER DT TO</label>
                <input [(ngModel)]="ordDateTo" [min]="ordDate" class="form-control-sm" type="date" autoWidth="false" />
              </div>
            </div>
            <div class="col-xl-1 max-width-01 col-md-2 col-sm-3">
              <div class="drpchildctrl">
                <label class="searchLable">DOCK</label>
                <p-multiSelect [(ngModel)]="selectedDock" [showToggleAll]="false" (onChange)="dockChange()"
                  [options]="dockList" [showTransitionOptions]="'0ms'" [hideTransitionOptions]="'0ms'"
                  [defaultLabel]="'--Select--'" [virtualScroll]="true" [itemSize]="25" autoWidth="false"
                  [style]="{ width: '100%' }" [resetFilterOnHide]="true" optionLabel="dock"
                  [panelStyle]="{ width: '140%' }">
                </p-multiSelect>
              </div>
            </div>
            <div class="col-xl-1 max-width-01 col-md-2 col-sm-3">
              <div class="drpchildctrl">
                <label class="searchLable">AIR CASE CODE</label>
                <p-multiSelect [showToggleAll]="false" [showTransitionOptions]="'0ms'" [hideTransitionOptions]="'0ms'"
                  [defaultLabel]="'--Select--'" [(ngModel)]="selectedAircaseCode" autoWidth="true"
                  [style]="{ width: '100%' }" [resetFilterOnHide]="true" (onChange)="airCaseCodeChange()"
                  selected="false" [options]="airCaseCode" optionLabel="air_case_code" [appendTo]="'body'">
                </p-multiSelect>
              </div>
            </div>
            <div class="col-xl-1 max-width-01 col-md-2 col-sm-3">
              <div class="drpchildctrl">
                <label class="searchLable">PART NO</label>
                <p-multiSelect [(ngModel)]="selectedPartNo" [showToggleAll]="false" (onChange)="onPartNoChange($event)"
                  [options]="partNoList" [showTransitionOptions]="'0ms'" [hideTransitionOptions]="'0ms'"
                  [defaultLabel]="'--Select--'" [virtualScroll]="true" [itemSize]="25" autoWidth="false"
                  [style]="{ width: '100%' }" [resetFilterOnHide]="true" optionLabel="part_no"
                  [panelStyle]="{ width: '140%' }" selected="false" [disabled]="disablePartNumber">
                </p-multiSelect>


                <!-- <p-multiSelect [(ngModel)]="selectedPartNo" (onChange)="onPartNoChange($event)" [showToggleAll]="false"
                  [options]="partNoList" [showTransitionOptions]="'0ms'" [hideTransitionOptions]="'0ms'"
                  [defaultLabel]="'--Select--'" [virtualScroll]="true" [itemSize]="10" autoWidth="false"
                  [style]="{ width: '100%' }" [resetFilterOnHide]="true" optionLabel="part_no"
                  [panelStyle]="{ width: '140%' }" selected="false" [disabled]="disablePartNumber" [appendTo]="'body'">
                </p-multiSelect> -->
              </div>
            </div>
            <div class="col-xl-1 max-width-01 col-md-2 col-sm-3">
              <div class="drpchildctrl">
                <label class="searchLable">KANBAN</label>
                <p-multiSelect [(ngModel)]="selectedKanban" [showToggleAll]="false" (onChange)="onKanbanChange()"
                  [options]="kanbanList" [showTransitionOptions]="'0ms'" [hideTransitionOptions]="'0ms'"
                  [defaultLabel]="'--Select--'" [virtualScroll]="true" [itemSize]="25" autoWidth="true"
                  [style]="{ width: '100%' }" 
                  [resetFilterOnHide]="true" optionLabel="kanban"
                  [panelStyle]="{ width: '140%' }" 
                  selected="false" [disabled]="disableKanban" [appendTo]="'body'">
                </p-multiSelect>
              </div>
            </div>
            <div class="col-xl-1 max-width-01 col-md-2 col-sm-3">
              <div class="drpchildctrl">
                <label class="searchLable">STATUS</label>
                <p-multiSelect [(ngModel)]="selectedStatus" [showToggleAll]="false" (onChange)="statusChange()"
                  [options]="statusList" optionLabel="air_order_status" [showTransitionOptions]="'0ms'"
                  [hideTransitionOptions]="'0ms'" [defaultLabel]="'--Select--'" autoWidth="true"
                  [style]="{ width: '100%' }" [resetFilterOnHide]="true" selected="false" [appendTo]="'body'">
                </p-multiSelect>
              </div>
            </div>
            <div class="col-xl-1 max-width-02 col-md-4 col-sm-6 reset_btn">
              <label class="resetIcon">
                <img src="./assets/images/Group 115380.png" alt="refresh icon" (click)="onResetDropDown()" />
              </label>
              <label class="resetlabel" (click)="onResetDropDown()">Reset</label>
              <span class="vertical"></span>
              <button class="search_btn" (click)="onSearch(0)">SEARCH</button>
            </div>
            <!-- <div class="col-xl-1 max-width-02 col-md-2 col-sm-3 search">
              
            </div> -->
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="container-fluid">
    <div class="row">
      <div class="col-sm-6">
        <span class="edit_icon">
          <img class="img-tool" (click)="exportAsXLSX()" src="./assets/images/download.svg" alt="download icon"
            title="Download" />
        </span>
      </div>

      <div class="col-sm-4"></div>

      <!-- <div class="col-sm-4">
        <div class="records">
          <p-multiSelect #dd
            class="page-drpdwn-label showhide"
            [(ngModel)]="selectedShowHideList"
            (onChange)="onShowHideChange($event)"
            [options]="showHideList"
            optionLabel="label"
            [displaySelectedLabel]="false"
            [defaultLabel]="'SHOW/HIDE COLUMN'"
            [showTransitionOptions]="'0ms'"
            [hideTransitionOptions]="'0ms'"
            autoWidth="true"
            [style]="{ width: '50%', background: '#F5F5F5' }"
            [resetFilterOnHide]="true"
          >
            <p-footer>
              <div class="flex justify-content-between p-1" style="width: 100%; padding: 10px;">
                <button type="button" (click)="saveShowHide(); dd.hide()" class="save_btn" >Save</button>
              </div>
            </p-footer>
          </p-multiSelect>
        </div>
      </div> -->

      <div class="col-sm-2">
        <div class="records">
          <label class="page-drpdwn-label">RECORDS / PAGE:</label>
          <select class="page-drpdwn" (change)="onPageSizeChanged()" [(ngModel)]="paginationPageSize">
            <option value="10" selected>10</option>
            <option value="30">30</option>
            <option value="50">50</option>
            <option value="{{ rowData.length }}">All</option>
          </select>
        </div>
      </div>
    </div>
  </div>

  <div id="dvgrid" class="gridStyle">
    <ag-grid-angular #osPartsGrid style="width: 100%; top: 1%; margin-bottom: 5%" class="ag-theme-balham"
      [columnDefs]="columnDefs" [rowData]="rowData" [rowSelection]="rowSelection" [gridOptions]="gridOptions"
      [pagination]="true" [paginationPageSize]="paginationPageSize" [context]="context"
      [suppressClickEdit]="suppressClickEdit" [frameworkComponents]="frameworkComponents"
      [tooltipShowDelay]="tooltipShowDelay" [tooltipMouseTrack]="true" [editType]="editType"
      [groupRowsSticky]="groupRowsSticky" [autoGroupColumnDef]="autoGroupColumnDef"
      [groupDefaultExpanded]="groupDefaultExpanded" [editType]="editType" [showOpenedGroup]="showOpenedGroup"
      [groupSelectsChildren]="groupSelectsChildren" [suppressRowClickSelection]="suppressRowClickSelection"
      [suppressAggFuncInHeader]="suppressAggFuncInHeader" [groupDisplayType]="groupDisplayType">
    </ag-grid-angular>
  </div>

  <ngx-spinner bdColor="rgba(51,51,51,0.8)" size="medium" color="#fff" type="ball-scale-multiple">
    <p style="font-size: 20px; color: white">Loading... Please wait...</p>
  </ngx-spinner>
</div>