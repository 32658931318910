import { Component, Inject, OnInit, EventEmitter } from '@angular/core'
import { MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';

@Component({
  selector: 'app-edit-breaks',
  templateUrl: './edit-breaks.component.html',
  styleUrls: ['./edit-breaks.component.scss'],
})
export class EditBreaksComponent implements OnInit {
  addBreaksEventEmitter = new EventEmitter();
  editBreaksEventEmitter = new EventEmitter();
  shiftNumbers: any[] = [];
  shiftNumber: any;
  dayWeek: any;
  startTime: any;
  endTime: any;
  toTime: any;
  fromTime: any;
  errorMessage: any;
  times: string[] = [];
  times2: string[] = [];
  hoursArray = new Array(24);
  mintuesArray = new Array(60);

  addOrEditform: UntypedFormGroup = this.formBuilder.group({
    shiftNumber: [''],
    toHoursHH: ['', Validators.required],
    toMintuesMM: ['', Validators.required],
    fromHoursHH: ['', Validators.required],
    fromMintuesMM: ['', Validators.required],
  },
  );

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private readonly dialogRef: MatDialogRef<EditBreaksComponent>,
    private readonly formBuilder: UntypedFormBuilder

  ) { }
  get addOrEdit() {
    return this.addOrEditform.controls;
  }
  ngOnInit(): void {
    this.addOrEditform.controls.shiftNumber.setValue(this.data.shift_no);
    if(this.data.mode === 'edit'){

    
    let fromHoursHH: number | undefined, fromMinutesMM: number | undefined;
    let toHoursHH: number | undefined, toMinutesMM: number | undefined;

    if (this.data?.field?.time_from) {
      const [hh, mm] = this.data.field.time_from.split(':').map(Number);
      fromHoursHH = hh;
      fromMinutesMM = mm;
    }

    if (this.data?.field?.time_to) {
      const [hh, mm] = this.data.field.time_to.split(':').map(Number);
      toHoursHH = hh;
      toMinutesMM = mm;
    }

    this.addOrEditform.patchValue({
      fromHoursHH: fromHoursHH,
      fromMintuesMM: fromMinutesMM,
      toHoursHH: toHoursHH,
      toMintuesMM: toMinutesMM,
      shiftNumber: this.addOrEdit.shiftNumber.value
    });
  }
    // Log for debugging
    this.addOrEditform.controls.fromHoursHH.valueChanges.subscribe((hour: number) => {
      if (hour !== null) {
        this.addOrEditform.controls.fromMintuesMM.setValue(0);  // Set minutes to 00
      }
    });
 
    // Listen for changes in the 'toHoursHH' field and set minutes to '00' when hour is selected
    this.addOrEditform.controls.toHoursHH.valueChanges.subscribe((hour: number) => {
      if (hour !== null) {
        this.addOrEditform.controls.toMintuesMM.setValue(0);  // Set minutes to 00
      }
    });

    this.shiftNumbers = [
      { value: 1 },
      { value: 2 },
      { value: 3 }
    ];
  }

  formatNumber(num: number): string {
    return num < 10 ? '0' + num : num.toString();
  }

  submitted: boolean = false;
  convertToFullTimeFormat(time: string): string {
    // Check if the time already includes seconds, if not, add ':00'
    if (time.length === 5) { // The format HH:mm (length 5)
      return `${time}:00`; // Append ':00' to represent seconds
    }
    return time; // If it already includes seconds, return as is
  }
  addBreakDetails() {
    this.addOrEditform.controls.shiftNumber.setValue(this.data.shift_no);
    const shiftStartTime = this.data.startTime;
    const shiftendTime = this.data.endTime;
    if (!this.addOrEditform.valid) {
      this.submitted = true;
      return;
    }

    const { toHoursHH, toMintuesMM, fromHoursHH, fromMintuesMM } = this.addOrEditform.value
    const toTimeNew = `${toHoursHH.toString().padStart(2, 0)}:${toMintuesMM.toString().padStart(2, 0)}:00`
    const fromTimeNew = `${fromHoursHH.toString().padStart(2, 0)}:${fromMintuesMM.toString().padStart(2, 0)}:00`
    const startTimeShift = shiftStartTime;      // Format should be HH:mm
    const endTimeShift = shiftendTime;      // Format should be HH:mm
    if (this.isStartTimeGreaterThanEndTime(fromTimeNew, toTimeNew)) {
      this.submitted = true;
      this.errorMessage = 'From Time must be Greater than To Time.';
      return;
    }
    if (!this.isStartTimeLessThanEndTime(endTimeShift, toTimeNew)) {
      this.submitted = true;
      this.errorMessage = 'break must be within shift.';
      return;
    }
    if (this.isStartTimeGreaterThanEndTime(startTimeShift, fromTimeNew)) {
      this.submitted = true;
      this.errorMessage = 'break must be within shift.';
      return;
    }
    const addEmittedData = {
      shiftNumber: this.addOrEdit.shiftNumber.value,
      fromTimeNew: fromTimeNew,
      toTimeNew: toTimeNew,
    };
    this.addBreaksEventEmitter.emit(addEmittedData);
    this.dialogRef.close();
  }
  private isStartTimeGreaterThanEndTime(startTimeShift: any, fromTimeNew: any): any {
    return startTimeShift >= fromTimeNew;
  }
  private isStartTimeLessThanEndTime(endTimeShift: any, toTimeNew: any): boolean {
    return endTimeShift > toTimeNew;
  }

  editRowBreakDetails() {
    const shiftStartTime = this.data.field.start_time;
    const shiftendTime = this.data.field.end_time;
    if (this.addOrEditform.invalid) {
      this.submitted = true;
      return;
    }
    const fromHoursHH = this.addOrEditform.get('fromHoursHH')?.value;
    const fromMintuesMM = this.addOrEditform.get('fromMintuesMM')?.value;
    const toHoursHH = this.addOrEditform.get('toHoursHH')?.value;
    const toMintuesMM = this.addOrEditform.get('toMintuesMM')?.value;

    // Combine the hours and minutes into time strings (HH:mm)
    const fromTimeNew = `${fromHoursHH.toString().padStart(2, '0')}:${fromMintuesMM.toString().padStart(2, '0')}:00`;
    const toTimeNew = `${toHoursHH.toString().padStart(2, '0')}:${toMintuesMM.toString().padStart(2, '0')}:00`;

    // Log combined time values for debugging
    const startTimeShift = shiftStartTime;      // Format should be HH:mm
    const endTimeShift = shiftendTime;
    if (this.isStartTimeGreaterThanEndTime(fromTimeNew, toTimeNew)) {
      this.submitted = true;
      this.errorMessage = 'break must be within shift.';
      // Optional: Set an error message
      return; // Return early, preventing further execution
    }
    if (!this.isStartTimeLessThanEndTime(endTimeShift, toTimeNew)) {
      this.submitted = true;
      this.errorMessage = 'break must be within shift.';
      return;
    }
    if (this.isStartTimeGreaterThanEndTime(startTimeShift, fromTimeNew)) {
      this.submitted = true;
      this.errorMessage = 'break must be within shift.';
      return;
    }
    const editEmittedData = {
      shiftNumber: this.addOrEdit.shiftNumber.value,
      toHoursHH: this.addOrEditform.controls['toHoursHH']?.value,
      toMintuesMM: this.addOrEditform.controls['toMintuesMM']?.value,
      fromHoursHH: this.addOrEditform.controls['fromHoursHH']?.value,
      fromMintuesMM: this.addOrEditform.controls['fromMintuesMM'].value,
      fromTimeNew: fromTimeNew,
      toTimeNew: toTimeNew,
    };
    
    this.editBreaksEventEmitter.emit(editEmittedData);
    this.dialogRef.close();
  }
  callRespectiveFunction() {
    if (this.data?.mode === 'edit') {
      this.editRowBreakDetails();
    } else {
      this.addBreakDetails();
    }
  }

}