import { Component } from '@angular/core';

@Component({
  selector: 'app-actual-arrival-editable',
  template: `
  <div style="margin: 3px 0px 6px;">
    <input #input [(ngModel)]="value" class="grid-cell-edit-layout " (change)="textUpdated()" maxlength="5" (input)="restrictNegativeValues($event)" />
    </div>
  `,
  styles: [
    `
      input::-webkit-outer-spin-button,
      input::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
      }
      .grid-cell-edit-layout {
        width: 100%;
        border: 1px solid #d1d1d1;
      }
      .invalid-text {
        border: 2px solid red !important;
      }
      .error{color:red}
    `,
  ],
})
export class CaseNoEditableComponent {
  public params: any;
  showInput = true;
  value: any;
  isEmpty = true;

  constructor() { }

  agInit(params: any): void {
    params.data.casenoEditable = this;
    if (params.column === 'case_no') {      
      this.value = params.data.case_no;
    }
    this.params = params;
  }

  // called when the cell is refreshed
  refresh(params: any): boolean {
    this.params = params;
    return true;
  }

  textUpdated() {
    if (this.params.column === 'case_no') {      
      if (this.value !== this.params.data.case_no) {
        this.params.data.case_no = this.value;
        this.params.data.isUpdated = true;
      }
    }
  }

  restrictNegativeValues(event: Event): void {
    const inputElement = event.target as HTMLInputElement;
    // Remove any negative sign (-)
    if (inputElement.value.includes('-')) {
      inputElement.value = inputElement.value.replace(/-/g, '');
    }
    // Update the ngModel value manually
    this.value = inputElement.value;
  }
}  
