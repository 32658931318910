import { Component, EventEmitter, Output } from '@angular/core';
import * as moment from 'moment';

@Component({
  selector: 'app-module-stocking-grid-checkbox',
  template: `
  <input type="checkbox" [disabled]="!access" [checked]="isChecked" (change)="onCheckboxChange($event)" />
  `,
  styles: [

  ],
})
export class ModuleStockingGridCheckboxComponent {

  // Define properties to hold the row data and checkbox state
  public params: any;
  public isChecked: boolean;
  access;

  agInit(params: any): void {
    this.params = params;
    this.access = JSON.parse(localStorage.getItem('stock'));

    if (params.column === 'stock') {
      params.data.stockCheck = this;
      this.isChecked = params.data?.module_status === "STOCKED";
    }
  }

  onCheckboxChange(event: Event): void {

    this.isChecked = (event.target as HTMLInputElement).checked;
    this.params.data.stock = this.isChecked;
    console.log("checking checkbox value..." + this.isChecked);

    var currentUtcDateTime = moment().utc(); // Example UTC date-time
  var michiganDate = currentUtcDateTime.tz('US/Michigan');
  var formattedDate1 = michiganDate.format('MM/DD/YYYY HH:mm:ss');

    this.params.data.module_status = this.isChecked?'STOCKED':'UNSTOCKED';
    this.params.data.updated_userid = (localStorage.getItem('UserName'));
    this.params.data.actual_stocking_datetime = this.isChecked?formattedDate1:null; 
    this.params.data.updated_date_time = formattedDate1; 
    console.log("this.params.data.module_status",this.params.data.module_status);
    
    // Optionally, call the parent function (onCallAPI)
    if (this.params.onCallAPI) {
      this.params.onCallAPI(this.params.data); // Send the row data to the parent function
    }
  }

  getValue(): any {
    return this.isChecked;
  }

  // Optionally, refresh the component (called when grid state is updated)
  refresh(params: any): boolean {
    debugger
    this.agInit(params);
    return true;
  }



}
