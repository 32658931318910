import { Component } from '@angular/core';
import * as moment from 'moment';
import { runInThisContext } from 'vm';

@Component({
  selector: 'production-progress-text-editor',
  template: `
    <label *ngIf="showInput">{{ value }} </label>
    <input
      *ngIf="!showInput"
      #input
      type="number"
      (keypress)="onKeyPress($event)"
      maxlength="5"
      pattern="[0-9]"
      [(ngModel)]="value"
      class="grid-cell-edit-layout "
      (change)="textUpdated()"
      
    />
  `,
  styles: [
    `
    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }
    .grid-cell-edit-layout {
      width: 100%;
      border: 1px solid black;
    }
    .invalid-text {
      border: 2px solid red !important;
    }
    `,
  ],
})
export class ProdProgressIntvolEditComponent {
  public params: any;
  showInput = true;  // Initially set showInput to true (input should be visible)
  value: any;
  isEmpty = true;
  isPastDate: boolean;
  isToday: boolean;
  reportdate = new Date();  // Current date
  shiftOneEnded = false;

  constructor() { }

  agInit(params: any): void {
    this.params = params;
    let isShiftoneEnded = localStorage.getItem('isShiftoneEnded')
    if (isShiftoneEnded === 'true') {
      this.shiftOneEnded = true

    }

    // Assuming 'params.column' contains 'int_plan_volume' and 'params.data' contains 'int_plan_volume' and 'actual_date'
    if (params.column === 'int_plan_volume') {
      params.data.intPlanVol = this;

      this.value = params.data.int_plan_volume;

      // Check if value is present to set isEmpty
      this.isEmpty = !this.value;
    }

    // Get today's date in 'MM/DD/YYYY' format
    let date = moment(this.reportdate).format('MM/DD/YYYY');
    console.log("Today's Date:", date);

    // Convert 'actual_date' to moment object and compare it with today's date
    let actualDate = moment(this.params?.data.actual_date, 'MM/DD/YYYY');
    this.isPastDate = actualDate.isBefore(moment(date, 'MM/DD/YYYY'), 'day');
    this.isToday = actualDate.isSame(moment(date, 'MM/DD/YYYY'), 'day');
    // Set isPastDate based on comparison

    console.log("isPastDate:", this.isPastDate);
    console.log("checkedEditMode:", this.params.data.checkedEditMode);
    // Only hide input if in edit mode and the actual_date is in the past
    if (this.params.data.checkedEditMode && !this.isPastDate) {
      this.showInput = false;
    } else {
      this.showInput = true;
    } 

     if (this.params.data.checkedEditMode && this.isToday && this.shiftOneEnded && String(this.params.data.actual_shift) === '1') {
      this.showInput = true;
    }
  
  }

  // Called when the cell is refreshed
  refresh(params: any): boolean {
    this.params = params;
    return true;
  }

  public textUpdated() {
    console.log("textUpdated");
    // Update the 'int_plan_volume' value if it has changed
    if (this.params.column === 'int_plan_volume') {
      if (this.value !== this.params.data.int_plan_volume) {
        this.params.data.int_plan_volume = this.value;
        this.params.data.intPlanVol = true;
        this.params.data.Updated = true;
        this.params.data.isinternalplan = true

      }
    } else {
      this.params.data.intPlanVol = this.value;
      this.params.data.Updated = false;
    }
  }

  onKeyPress(event) {
    const charCode = event.charCode;
    // Allow digits only (0-9)
    if (charCode < 48 || charCode > 57) {
      event.preventDefault();
    }
    if (event.target.value.length <= 4) {
      return true;
    } else {
      return false;
    }
  }

  stopTyping() {
    return false;
  }
}
