import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';

import { Observable } from 'rxjs';
import { EnvironmentConfiguration } from 'src/app/environment/services/environment.configuration';

@Injectable({
  providedIn: 'root'
})
export class PipeLookupService {

  getToken = () => localStorage.getItem('Token');
  appJson = 'application/json';

  httpCustomOptions = () => {
    return {
      headers: new HttpHeaders({
        'Content-Type': this.appJson,
        Accept: this.appJson,
        Authorization: this.getToken(),
        'X-Api-Key': this.environment.ospLookupKey,
      }),
    };
  };
  

  apiUrl = '';

  constructor(private http: HttpClient, private environment: EnvironmentConfiguration) {
    this.apiUrl = environment.ospLookupUrl;
  }

  getBusinessEntity = () => localStorage.getItem('namcvalue');

  getBusinessEntityName = () => localStorage.getItem('namcName');

  getUserRole = () => localStorage.getItem('UserRoles');

  getUserUPN = () => localStorage.getItem('UPN');

  getWorkID = () => localStorage.getItem('workdayId');

  getUserName = () => localStorage.getItem('UserName');



  getPartList(data): Observable<any> {
    return this.http.post<any>(
      `${this.apiUrl}/partList`,
    // 'http://localhost:3000/partcontList',
      data,
      this.httpCustomOptions()
    );
  }
  getContList(data): Observable<any> {
    return this.http.post<any>(
      `${this.apiUrl}/contList`,
      data,
      this.httpCustomOptions()
    );
  }
  getModuleList(data): Observable<any> {
    return this.http.post<any>(
      `${this.apiUrl}/moduleList`,
    data,
      this.httpCustomOptions()
    );
  }
  getRenbanList(data): Observable<any> {
    return this.http.post<any>(
      `${this.apiUrl}/renbanList`,
      data,
      this.httpCustomOptions()
    );
  }
  getCcList(data): Observable<any> {
    return this.http.post<any>(
      `${this.apiUrl}/ccList`,
      //  `http://localhost:3000/status1`,

      data,
      this.httpCustomOptions()
    );
  }
  getKanbanList(data): Observable<any> {
    return this.http.post<any>(
      `${this.apiUrl}/kanbanList`,
      data,
      this.httpCustomOptions()
    );
  }
  getPartNoList(data): Observable<any> {
    return this.http.post<any>(
      `${this.apiUrl}/partNoList`,
      data,
      this.httpCustomOptions()
    );
  }
  getPartNamcarrived(data): Observable<any> {
    return this.http.post<any>(
      `${this.apiUrl}/partnoNamcarrived`,
      data,
      this.httpCustomOptions()
    );
  }
  getRenbanNamcarrived(data): Observable<any> {
    return this.http.post<any>(
      `${this.apiUrl}/getRenbennamcArrived`,
      data,
      this.httpCustomOptions()
    );
  }
  getModuleNamcarrived(data): Observable<any> {
    return this.http.post<any>(
      `${this.apiUrl}/moduleNamcarrived`,
      data,
      this.httpCustomOptions()
    );
  }





  getModuleCodeList(data): Observable<any> {
    return this.http.post<any>(
      `${this.apiUrl}/moduleCodeList`,
      data,
      this.httpCustomOptions()
    );
  }
  getStatuList(data): Observable<any> {
    return this.http.post<any>(
      `${this.apiUrl}/statusList`,
      data,
      this.httpCustomOptions()
    );
  }
  getShippingCarrier(data): Observable<any> {
    
    return this.http.post<any>(`${this.apiUrl}/shippingCarrierList`, data, this.httpCustomOptions());
}

getVesselNameList(data): Observable<any> {
    
  return this.http.post<any>(`${this.apiUrl}/vesselNameList`, data, this.httpCustomOptions());
}

  getUserShowHideColumns(data): Observable<any> {
    
    return this.http.post<any>(`${this.apiUrl}/getShowHideColumns`, data, this.httpCustomOptions());
}

updateUserShowHideColumns(data,columns): Observable<any> {
const data1 = {
  business_entity: data.business_entity,
  business_entity_name: data.business_entity_name,
  userid: data.userid,
  workid: data.workid,
  user_role: data.user_role,
  ...columns,
};

return this.http.post<any>(`${this.apiUrl}/updateShowHideColumns`, data1, this.httpCustomOptions());
}

//usermanul

getUserManuals3Link(data): Observable<any> {
  return this.http.post<any>(
    `${this.apiUrl}/downloadUserManual`,
  // 'http://localhost:3000/partcontList',
    data,
    this.httpCustomOptions()
  );
}


getReleaseNotes(data): Observable<any> {
  return this.http.post<any>(`${this.apiUrl}/getReleaseNotes`, data, this.httpCustomOptions());
}

downloadReleaseNotes(data): Observable<any> {
  return this.http.post<any>(`${this.apiUrl}/downloadReleaseNotes`, data, this.httpCustomOptions());
}
getPieToken(data): Observable<any> {
  return this.http.post<any>(
    `${this.apiUrl}/getToken`,
    data,
    this.httpCustomOptions()
  );

}

sendErrormsg(data): Observable<any> {
  return this.http.post<any>(
    `${this.apiUrl}/getError`,
    data,
    this.httpCustomOptions()
  );
}

getModuleType(): Observable<any> {
  const data = {
    business_entity: this.getBusinessEntity(),
    business_entity_name: this.getBusinessEntityName(),
    userid: this.getUserName(),
    workid: this.getWorkID(),
    user_role: this.getUserRole(),
  };
  return this.http.post<any>(`${this.apiUrl}/getModuleType`, data, this.httpCustomOptions());
}
getLine(): Observable<any> {
  const data = {
    business_entity: this.getBusinessEntity(),
    business_entity_name: this.getBusinessEntityName(),
    userid: this.getUserName(),
    workid: this.getWorkID(),
    user_role: this.getUserRole(),
  };
  return this.http.post<any>(`${this.apiUrl}/getLine`, data, this.httpCustomOptions());
}
getTrackingPoint(): Observable<any> {
  const data = {
    business_entity: this.getBusinessEntity(),
    business_entity_name: this.getBusinessEntityName(),
    userid: this.getUserName(),
    workid: this.getWorkID(),
    user_role: this.getUserRole(),
  };
  return this.http.post<any>(`${this.apiUrl}/getTrackingPoint`, data, this.httpCustomOptions());
}
getActiveSelectionModuleToDock(data): Observable<any> {
  const data1 = {
    business_entity: this.getBusinessEntity(),
    module_type: data.module_type,
    business_entity_name: this.getBusinessEntityName(),
    userid: this.getUserName(),
    workid: this.getWorkID(),
    user_role: this.getUserRole(),
  };
  return this.http.post<any>(`${this.apiUrl}/getActiveSelectionModuleToDock`, data1, this.httpCustomOptions());
}
}
