import { Component } from '@angular/core';

@Component({
  selector: 'app-actual-arrival-editable',
  template: `
    
    <input
     
      #input
      
      (keypress)="onKeyPress($event)"
      maxlength="10"
      pattern="[0-9]"
      [(ngModel)]="value"
      type="text"
  placeholder="HH:MM"
  (input)="onTimeInput($event)"
     
      class="grid-cell-edit-layout "
      (change)="textUpdated()"
    />
  `,
  styles: [
    `
      input::-webkit-outer-spin-button,
      input::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
      }
      .grid-cell-edit-layout {
        width: 100%;
        border: 1px solid #d1d1d1;
    }
      .invalid-text {
        border: 2px solid red !important;
      }
    `,
  ],
})
export class EtaTimeEditableComponent {
  public params: any;
  showInput = true;
  value: any;
  isEmpty = true;

  lastDayOfVanMonth = localStorage.getItem('lastDayOfVanMonth');

  constructor() {}

  agInit(params: any): void {
    params.data.etaTime = this;
    if (params.column === 'eta_time') {
      this.value = params.data.eta_date;
      // if (this.value > 0) {
      //   this.isEmpty = false;
      // } else {
      //   this.isEmpty = true;
      // }
    }
   
    this.params = params;
   

  }

  // called when the cell is refreshed
  refresh(params: any): boolean {
    this.params = params;
    return true;
  }
  onTimeInput(event: Event) {
    const input = event.target as HTMLInputElement;
    let value = input.value.replace(/[^0-9]/g, ''); // Remove non-numeric characters

    if (value.length >= 3) {
      // Insert colon after the first two digits
      value = value.slice(0, 2) + ':' + value.slice(2, 4);
    }

    this.value = value; // Update the bound model
  }

  textUpdated() {
    if (this.params.column === 'eta_date') {
      if (this.value !== this.params.data.eta_date) {
        this.params.data.eta_date = this.value;
        this.params.data.isVanningVolUpdated = true;
      }
    } else {
      this.params.data.finalquantity = this.value;
    }
  }
  
  onKeyPress(event) {
    if (event.target.value.length <= 4) {
      return true;
    } else {
      return false;
    }
  }

}
