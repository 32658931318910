import { Component, OnInit, ViewChild, OnDestroy } from '@angular/core';
import { HeaderCheckboxComponent } from '../core/grid/header-checkbox/header-checkbox.component';
import { GridRefreshComponent } from '../core/grid/grid-refresh/grid-refresh.component';
import { RowCheckboxComponent } from '../core/grid/row-checkbox/row-checkbox.component';
import { NgxSpinnerService } from 'ngx-spinner';
import { CustomTooltipComponent } from '../core/custom-tooltip/custom-tooltip.component';
import { GriddataService } from '../services/griddata.service';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { ExcelService } from '../services/excel.service';
import * as moment from 'moment';
import { OSPStateService } from '../services/osp-state.service';
import { forkJoin, Subscription } from 'rxjs';
import { parseAdjustmentType } from '../shared/adjustment-type-parser';
import { LookupService } from '../services/lookup/lookup.service';
import {
  dateFilterComparator, resetSortingAndFilters, successMessage, validateVanningDates, warningMessage,checkAccessPipe
} from '../constants';
import { logErrors } from '../shared/logger';
import * as mm from 'moment-timezone';
import { SetupActionsComponent } from '../core/setup-actions/setup-actions.component';
import { ModuleTypeActionsComponent } from './module-type-actions/module-type-actions.component';
import { ModuleTypeEditDialogComponent } from './module-type-edit-dialog/module-type-edit-dialog.component';
import { MatDialogConfig } from '@angular/material/dialog';
import { ModuleTypeDeleteDialogComponent } from './module-type-delete-dialog/module-type-delete-dialog.component';
import { ColDef, IDetailCellRendererParams } from 'ag-grid-community';
import { PartsInventoryService } from '../services/parts-inventory/parts-inventory.service';
import { log } from 'console';
import { OrderforecastService } from '../services/orderforecast/orderforecast.service';
import { ModuleStockingService } from '../services/module-stocking/module-stocking.service';
import { ModuleRowCheckboxComponent } from './module-type-actions/module-type-checkbox.componet';
@Component({
  selector: 'app-module-type',
  templateUrl: './module-type.component.html',
  styleUrls: ['./module-type.component.scss']
})
export class ModuleTypeComponent implements OnInit, OnDestroy {
  namcValue = localStorage.getItem('namcvalue');
  userName = localStorage.getItem('UserName');
  count = 0;
  rowData = [];
  @ViewChild('osPartsGrid') osPartsGrid;
  columnDefsMaster;
  // columnDefs;
  // columnDefsTemp;
  // public defaultColDef;
  rowSelection;
  user: string;
  userRole: any;
  warningMessage = '';
  offset: number = 0;
  dataCount: number = 0;
  modalComponent = 'modal-component';
  gridOptions;
  paginationPageSize;
  paginationNumberFormatter;
  pagination = 'true';
  context: any;
  editType;
  suppressClickEdit = true;
  tooltipShowDelay = 0;
  frameworkComponents = { customTooltip: CustomTooltipComponent };
  headerCheckboxChecked: boolean = false;
  partDesc = 'Part Description';
  editEnabled = false;
  freezeStatus = false;
  filter = false;
  showToggleAll = false;
  showClear = true;
  disablePartNumber: boolean;
  disableKanban: boolean;
  // Dropdowns
  selectedKanban: any = [];
  selectedPartNo: any = [];
  // selectedDestCode: any = [];
  // selectedContainer: any = [];
  // selectedSupplier: any = [];
  selectedDock: any = [];
  selectedSpecialist: any = [];
  selectedpolicyName: any = [];
  // selectedLifecycle: any = [];
  // adjList: any = [];
  selectedModuleType: any = [];
  selectedLine: any = [];
  effectiveFrom: any;
  effectiveTo: any;
  adjustSubscription$: Subscription;
  // Dropdown Options
  kanbanList: any = [];
  partNoList: any = [];
  partNoListAdd: any = [];
  // destCodeList: any = [];
  containerList: any = [];
  // supplierList: any = [];
  dockList: any = [];
  dockListForpopup: any = [];
  moduleTypeList: any = [];
  moduleTypeListForpopup: any = [];
  moduleTypeToDockForpopup: any = [];
  linePathListForpopup: any = [];
  specialistList: any = [];
  policyNameList: any = [];
  // lifecycleList: any = [];
  rowCount = 0;
  count1 = 0;
  isEditMode = false;
  successMessage = '';
  kanbanSelected = 1;
  easternDate: any;
  defaultPageSize: any = 100000;
  itContact: any;
  linePathList: any = [];
  selectedLinePath: any = [];
  lineList: any[];
  lineListForpopup: any[];
  trackingPointList: any[];
  trackingPointListForpopup: any[];
  validationMessage = '';
  access1: { edit: boolean; matchmismatch: boolean; modulelevaltapping: boolean; };
  access2: boolean

  constructor(
    private readonly spinner: NgxSpinnerService,
    private readonly excelService: ExcelService,
    private readonly gridDataService: GriddataService,
    public dialog: MatDialog,
    private readonly stateService: OSPStateService,
    private readonly lookupService: LookupService,
    private readonly service: OrderforecastService,
    private readonly moduleStockingService: ModuleStockingService
  ) {
    this.context = {
      componentParent: this,
    };
  }
  numberComparator(number1, number2) {
    return number1 - number2;
  }

  dateComparator(date1, date2) {
    function monthToNum(date) {
      if (date === undefined || date === null) {
        return null;
      }
      const newDate: Date = new Date(date);
      const yearNumber = newDate.getFullYear();
      const monthNumber = newDate.getMonth();
      const dayNumber = newDate.getDate();
      const hoursNumber = newDate.getHours();
      const minutesNumber = newDate.getMinutes();
      const result =
        yearNumber * 10000 +
        monthNumber * 100 +
        dayNumber +
        hoursNumber * 60 +
        minutesNumber * 10;
      // 29/08/2004 => 20040829
      return result;
    }

    // To be used inside filter params when using date filter

    const date1Number = monthToNum(date1);
    const date2Number = monthToNum(date2);

    if (date1Number === null && date2Number === null) {
      return 0;
    }
    if (date1Number === null) {
      return -1;
    }
    if (date2Number === null) {
      return 1;
    }

    return date1Number - date2Number;
  }

  onPageSizeChanged() {
    if (this.gridOptions?.api) {
      this.gridOptions.api.paginationSetPageSize(
        Number(this.paginationPageSize)
      );
    }
  }

  setPageSizeToAll(pageSize?) {
    this.paginationPageSize = this.rowData.length;

    if (pageSize) {
      this.paginationPageSize = 0;
      this.rowData.length = 0;
    }

    this.onPageSizeChanged();
  }

  ngOnDestroy(): void {
    this.adjustSubscription$.unsubscribe();
  }


  public columnDefs: ColDef[]

  public defaultColDef;


  ngOnInit(): void {
    const access = checkAccessPipe();
    this.access1 = access.access;
    this.access2 = access.access.edit;


    this.columnDefsMaster = [
      // {
      //   headerName: '',
      //   field: 'rowCheckBox',
      //   // suppressSorting: true,
      //   width: 44,
      //   headerComponentPa44rams: {
      //     headerChecked: this.headerCheckboxChecked,
      //   },
      //   headerComponentFramework: HeaderCheckboxComponent,
      //   floatingFilterComponentFramework: GridRefreshComponent,
      //   floatingFilterComponentParams: { suppressFilterButton: true },

      //   cellRendererFramework: RowCheckboxComponent,
      //   floatingFilter: true,
      //   pinned: 'left',
      // },
      {
        headerName: 'Module Type',
        field: 'module_type',
        sortable: true,
        width: 120,
        floatingFilter: true,
        tooltipField: 'module_type',
        headerTooltip: 'Module Type',
        // cellRenderer: "agGroupCellRenderer"
      },
      {
        headerName: 'Line',
        field: 'line',
        sortable: true,
        width: 80,
        floatingFilter: true,
        tooltipField: 'line',
        headerTooltip: 'Line'
      },
      {
        headerName: 'Dock',
        field: 'dock',
        sortable: true,
        width: 100,
        floatingFilter: true,
        tooltipField: 'dock',
        headerTooltip: 'Dock'
      },
      {
        headerName: 'Controlling Module',
        field: 'is_controlling_module',
        sortable: true,
        width: 120,
        floatingFilter: false,
        tooltipField: 'controlling_module',
        headerTooltip: 'Controlling Module',
        floatingFilterComponentParams: { suppressFilterButton: true },
        cellRendererFramework: ModuleRowCheckboxComponent,
        cellRendererParams: { column: 'conModule' },
        cellClass: 'no-border',
         hide: !this.access2
      },
      {
        headerName: 'Dock ModuleType Active',
        field: 'is_module_to_dock',
        sortable: true,
        width: 160,
        floatingFilter: false,
        tooltipField: 'is_module_to_dock',
        headerTooltip: 'Dock ModuleType Active',
        cellRendererFramework: ModuleRowCheckboxComponent,
        cellRendererParams: { column: 'moduleToDock' },
        cellClass: 'no-border',
        hide: !this.access2


      },
      {
        headerName: 'OSP Line Path',
        field: 'osp_line_path',
        sortable: true,
        width: 120,
        floatingFilter: true,
        tooltipField: 'osp_line_path',
        headerTooltip: 'OSP Line Path'

      },
      {
        headerName: 'Description',
        field: 'module_description',
        sortable: true,
        comparator: (valueA, valueB) => {
          return valueA.localeCompare(valueB, undefined, { sensitivity: 'base' });
        },
        width: 150,
        floatingFilter: true,
        tooltipField: 'module_description',
        // headerTooltip:'Description'

      },
      {
        headerName: 'Tracking Point',
        field: 'tracking_point',
        sortable: true,
        width: 140,
        floatingFilter: true,
        tooltipField: 'tracking_point',
        headerTooltip: 'Tracking Point'
      },
      {
        headerName: 'User',
        field: 'updated_userid',
        sortable: true,
        comparator: (valueA, valueB) => {
          return valueA.localeCompare(valueB, undefined, { sensitivity: 'base' });
        },
        width: 150,
        floatingFilter: true,
        tooltipField: 'updated_userid',
        headerTooltip: 'User'
      },
      {
        headerName: 'Updated DT',
        field: 'updated_date_time',
        sortable: true,
        comparator: (valueA, valueB) => {
          return valueA.localeCompare(valueB, undefined, { sensitivity: 'base' });
        },
        width: 180,
        floatingFilter: true,
        tooltipField: 'updated_date',
        headerTooltip: 'Updated Date(mm/dd/yyyy hh:mm:ss (Eastern))'
      },
      {
        headerName: 'Action',
        cellRendererFramework: ModuleTypeActionsComponent,
        field: 'actions',
        width: 100,
        sortable: false,
        floatingFilter: false,
        hide: !this.access2

        // pinned: 'right',
      },
    ],


      this.columnDefs = [...this.columnDefsMaster];

    this.setPageSizeToAll(this.defaultPageSize);
    // this.rowData = [
    //   {updated_date : '12/16/2024'}
    // ];

    this.rowSelection = 'multiple';
    this.editType = 'fullRow';
    this.gridOptions = this.gridDataService.getGridOptions();
    this.gridOptions.onGridReady = function (params) {
      this.gridApi = params.api;
    };
    this.adjustSubscription$ = this.stateService
      .getNamc()
      .subscribe((observable) => {
        this.initializeData();
      });


  }



  initializeData() {
    this.spinner.show();
    this.spinner.show();

    this.namcValue = localStorage.getItem('namcvalue');

    if (localStorage.getItem('workdayId')) {
      this.user = localStorage.getItem('workdayId');
    }
    if (localStorage.getItem('UserRoles')) {
      this.userRole = localStorage.getItem('UserRoles');
    }
    const access = checkAccessPipe();
    this.access1 = access.access;
    this.access2 = access.access.edit;



    this.loadDrop();
    this.onResetDropDown();
    // this.rowData = [
    //   {
    //     module_type : 'AF4',
    //     line : '2',
    //     dock : 'S1',
    //     controlling_module : '',
    //     is_module_to_dock : '',
    //     osp_line_path : '11',
    //     description : 'Camry/ Avalon Hy',
    //     tracking_point : 'A',
    //     user : 'John Doe',
    //     updated_date : '10/01/2024 21:20'
    //   }
    // ];

    this.lookupService.getEasternDate().subscribe({
      error: this.errorCallback,
      next: (res) => {
        this.easternDate = res.body.data;
      },
    });
  }
  

  checkIfAnySelected() {
    this.validationMessage = '';
    const msgPrefix = 'Please select '
    let msgArr = [];
    
    if (this.selectedModuleType.length === 0){
      msgArr.push('Module Type');
    }
    if (this.selectedLine.length === 0){
      msgArr.push('Line');
    }
    if (this.selectedDock.length === 0){
      msgArr.push('Dock');
    }
    
    if (msgArr.length > 0){
      this.validationMessage = `${msgPrefix}${msgArr.join(', ')}.`;
      return false;
    }
     
    return true;
  }

  resetGrid() {
    if (this.osPartsGrid) {
      resetSortingAndFilters(this.osPartsGrid);
      return;
    }
  }

  onSearch(offset) {
    //this.refreshView()
    if (this.osPartsGrid) {
      this.headerCheckboxChecked = false;
      this.osPartsGrid.gridOptions.api.refreshHeader();

      this.resetGrid();
    }

    this.removeMessage();

    if (offset === 0) {
      this.setPageSizeToAll(this.defaultPageSize);
      this.rowData = [];
      this.osPartsGrid.gridOptions.api.setRowData(this.rowData);

      const { valid, error } = validateVanningDates({
        vanningFrom: this.effectiveFrom,
        vanningTo: this.effectiveTo,
      });

      if (!valid) {
        this.warningMessage = error;
        window.scroll(0, 0);

        this.spinner.hide();

        return;
      }

      this.offset = 0;
      this.spinner.show();
    }

    if (!this.checkIfAnySelected()) {
      this.rowData = [];
      this.warningMessage = this.validationMessage;

      window.scroll(0, 0);
      this.spinner.hide();
      return false;
    }

    let kanban;
    let selectedPartNo;
    // let selectedDestCode;
    // let selectedContainer;
    // let selectedSupplier;
    let selectedDock;
    let selectedModuleType;
    let selectedLine;


    if (this.selectedModuleType.length > 0) {
      if (!this.selectedModuleType.some((a) => a.module_type === 'ALL')) {
        selectedModuleType = [];
        this.selectedModuleType.forEach((element) => {
          selectedModuleType.push(element.module_type);
        });
      }
    }

    if (this.selectedLine.length > 0) {
      if (!this.selectedLine.some((a) => a.line === 'ALL')) {
        selectedLine = [];
        this.selectedLine.forEach((element) => {
          selectedLine.push(element.line);
        });
      }
    }

    if (this.selectedDock.length > 0) {
      if (!this.selectedDock.some((a) => a.dock === 'ALL')) {
        selectedDock = [];
        this.selectedDock.forEach((element) => {
          selectedDock.push(element.dock);
        });
      }
    }

    const data = {
      offset: offset,
      limit: 12000,
      business_entity: this.namcValue,
      workid: this.user,
      user_role: this.userRole,
      module_type: selectedModuleType,
      line: selectedLine,
      dock: selectedDock,
      currenteasternDate: this.easternDate,
    };

    this.moduleStockingService.getmoduleTypeGrid(data).subscribe({
      error: this.errorCallback,
      next: (res) => {
        if (res.body && res.body.data) {
          this.rowData.push(...res.body.data);
          this.osPartsGrid.gridOptions.api.setRowData(this.rowData);
          this.dataCount = parseInt(res.body.rowCount);
          const records = this.dataCount - (this.offset + 1) * 12000;
          if (records >= 1) {
            this.offset++;
            this.onSearch(this.offset * 12000);
          } else {
            this.spinner.hide();
          }
        } else {
          this.rowData = [];
          this.spinner.hide();
        }

        this.setPageSizeToAll();
      },
    });
    return true;
  }

  loadDrop() {
    this.spinner.show();

    const data = {
      business_entity: this.namcValue,
      workid: this.user,
      user_role: this.userRole,
    };

    const lookupSubscriptions = [
      this.lookupService.getDock(),
      this.service.getLinePath(this.namcValue, this.userRole, this.user),
      this.moduleStockingService.getModuleType(),
      this.moduleStockingService.getLine(),
      this.moduleStockingService.getTrackingPoint(),
    ];
    forkJoin(lookupSubscriptions).subscribe({
      next: (res) => {
        // get dock list
        this.dockList = [];
        this.dockListForpopup = [];
        if (res[0].body.data) {
          this.dockList.push({ dock: 'ALL' });
        }
        this.dockList.push(...res[0].body.data);
        this.dockListForpopup.push(...res[0].body.data);

        // get linepath list
        this.linePathList = [];
        this.linePathListForpopup = [];
        this.linePathList = res[1].body.data;
        if (this.linePathList.length === 1) {
          this.selectedLinePath = this.linePathList;
        } else {
          this.selectedLinePath = [];
        }
        // this.linePathList = [];
        // this.linePathListForpopup = [];
        // if (res[1].body.data) {
        //   this.linePathList.push({ line_path: 'Select' });
        // }
        // this.linePathList.push(...res[1].body.data);
        // this.linePathListForpopup.push(...res[1].body.data);


        this.moduleTypeToDockForpopup = [{ value: 'Yes' }, { value: 'No' }];

        // get module type list
        this.moduleTypeList = [];
        this.moduleTypeListForpopup = [];
        console.log(res);

        if (res[2].body.data) {
          this.moduleTypeList.push({ module_type: 'ALL' });
        }
        this.moduleTypeList.push(...res[2].body.data);
        this.moduleTypeListForpopup.push(...res[2].body.data);

        // get line list
        this.lineList = [];
        this.lineListForpopup = [];
        console.log(res);

        if (res[3].body.data) {
          this.lineList.push({ line: 'ALL' });
        }
        this.lineList.push(...res[3].body.data);
        this.lineListForpopup.push(...res[3].body.data);


        // get tracking_point list
        this.trackingPointList = [];
        this.trackingPointListForpopup = [];
        console.log(res);

        if (res[4].body.data) {
          this.trackingPointList.push({ tracking_point: 'ALL' });
        }
        this.trackingPointList.push(...res[4].body.data);
        this.trackingPointListForpopup.push(...res[4].body.data);

        this.spinner.hide();
      },
      error: this.errorCallback,
    });
  }

  onKeyPress(event: KeyboardEvent) {
    const allowedKeys = ['Backspace', 'Tab', 'ArrowLeft', 'ArrowRight', 'Delete'];
    const isNumber = /^[0-9]$/.test(event.key);

    // Allow number keys, allowed keys, and prevent default for others
    if (!isNumber && !allowedKeys.includes(event.key)) {
      event.preventDefault();
    }
  }

  errorCallback = (error) => {
    logErrors(error);
    this.spinner.hide();

    this.warningMessage = 'Something went wrong! Please contact support team.';
    this.rowData = [];
    this.osPartsGrid.gridOptions.api.setRowData(this.rowData);
  };

  onResetDropDown() {
    this.removeMessage();
    this.selectedKanban = [];
    this.selectedPartNo = [];

    this.selectedDock = [];
    this.selectedSpecialist = [];
    this.selectedpolicyName = [];
    this.selectedLine = [{line: 'ALL'}];
    this.selectedDock = [{dock: 'ALL'}];
    this.selectedModuleType = [{module_type: 'ALL'}];
    this.effectiveFrom = '';
    this.effectiveTo = '';



    if (this.osPartsGrid) {
      this.headerCheckboxChecked = false;
      this.osPartsGrid.gridOptions.api.refreshHeader();
      this.resetGrid();
    }

    this.setPageSizeToAll(this.defaultPageSize);

    this.rowData = [];
    this.disableKanban = false;
    this.disablePartNumber = false;
    this.offset = 0;
  }
  // toogleHeaderCheckBox(checked) {
  //   if (checked) {
  //     const rowCount = this.osPartsGrid.gridOptions.rowData.length;
  //     const selectedRowCount = this.osPartsGrid.gridOptions.rowData.filter(
  //       (x) => x.rowCheckBox === true
  //     ).length;
  //     if (rowCount === selectedRowCount) {
  //       this.osPartsGrid.gridOptions.columnDefs[0].headerComponentFramework.checked =
  //         checked;
  //       this.osPartsGrid.gridOptions.api.refreshHeader();
  //     }
  //   } else {
  //     this.osPartsGrid.gridOptions.columnDefs[0].headerComponentFramework.checked =
  //       checked;
  //     this.osPartsGrid.gridOptions.api.refreshHeader();
  //   }
  // }

  toggleHeaderCheckbox(checked) {
    if (!checked) {
      this.headerCheckboxChecked = false;

      this.osPartsGrid.gridOptions.api.refreshHeader();

      return;
    }

    const rowCount = this.osPartsGrid.gridOptions.rowData.length;
    const selectedRowCount = this.osPartsGrid.gridOptions.rowData.filter(
      (x) => x.rowCheckBox === true
    ).length;

    if (rowCount === selectedRowCount) {
      this.headerCheckboxChecked = true;

      this.osPartsGrid.gridOptions.api.refreshHeader();
    }
  }
  userCheckChanged(checked, rowIndex) {
    this.spinner.show();
    const rowNode =
      this.osPartsGrid?.gridOptions?.api?.getDisplayedRowAtIndex(rowIndex);
    if (checked === true) {
      rowNode.setSelected(true);
      if (this.isEditMode) {
        rowNode.data.checkedEditMode = true;
      }
      rowNode.data.rowCheckBox = true;
    } else {
      rowNode.setSelected(false);
      rowNode.data.checkedEditMode = false;
      rowNode.data.rowCheckBox = false;
    }

    this.gridOptions.getRowStyle = function (params) {
      if (params.node.rowIndex === rowIndex) {
        const color = checked ? '#E4ECF0' : 'white';
        return { background: color };
      }
      return { background: 'white' };
    };

    rowNode.setData(rowNode.data);
    this.toggleHeaderCheckbox(checked);
    this.spinner.hide();
  }
  userCheckChanged2(checked, rowIndex) {
    this.spinner.show();
    const rowNode =
      this.osPartsGrid?.gridOptions?.api?.getDisplayedRowAtIndex(rowIndex);
    if (checked === true) {
      rowNode.setSelected(true);
      if (this.isEditMode) {
        rowNode.data.checkedEditMode = true;
      }
      rowNode.data.rowCheckBox = true;
    } else {
      rowNode.setSelected(false);
      rowNode.data.checkedEditMode = false;
      rowNode.data.rowCheckBox = false;
    }

    this.gridOptions.getRowStyle = function (params) {
      if (params.node.rowIndex === rowIndex) {
        const color = checked ? '#E4ECF0' : 'white';
        return { background: color };
      }
      return { background: 'white' };
    };

    rowNode.setData(rowNode.data);
    this.toggleHeaderCheckbox(checked);
    this.spinner.hide();
  }
  headerCheckChanged(event) {
    this.headerCheckboxChecked = event.checked;
    const headerChecked = event.checked;
    const isEditable = this.isEditMode;
    this.osPartsGrid.gridOptions.api.forEachNode(function (rowNode, index) {
      if (headerChecked) {
        if (isEditable) {
          rowNode.data.checkedEditMode = true;
        }
        rowNode.data.rowCheckBox = true;
      } else {
        rowNode.data.rowCheckBox = false;
        rowNode.data.checkedEditMode = false;
      }
      rowNode.setData(rowNode.data);
    });
  }
  adjs_columns

  onKanbanChange(): void {
    if (this.selectedKanban.length >= 1) {
      if (
        this.selectedKanban[this.selectedKanban.length - 1].kanban === 'ALL'
      ) {
        this.selectedKanban = [];
        this.selectedKanban.push(this.kanbanList[0]);
      } else {
        let indexAll = this.selectedKanban.findIndex(
          (data) => data.kanban === 'ALL'
        );

        if (indexAll > -1) {
          this.selectedKanban.splice(indexAll, 1);
        }
      }
    }
    if (this.selectedKanban.length > 0) {
      this.disablePartNumber = true;
    } else {
      this.disablePartNumber = false;
    }
  }
  onPartNoChange(event): void {
    if (this.selectedPartNo.length >= 1) {
      if (
        this.selectedPartNo[this.selectedPartNo.length - 1].item_id === 'ALL'
      ) {
        this.selectedPartNo = [];
        this.selectedPartNo.push(this.partNoList[0]);
      } else {
        let indexAll = this.selectedPartNo.findIndex(
          (data) => data.item_id === 'ALL'
        );

        if (indexAll > -1) {
          this.selectedPartNo.splice(indexAll, 1);
        }
      }
    }

    if (this.selectedPartNo.length > 0) {
      this.disableKanban = true;
    } else {
      this.disableKanban = false;
    }
  }

  // onDestCodeChange(): void {
  //   if (this.selectedDestCode.length >= 1) {
  //     if (
  //       this.selectedDestCode[this.selectedDestCode.length - 1].dest_code ===
  //       'ALL'
  //     ) {
  //       this.selectedDestCode = [];
  //       this.selectedDestCode.push(this.destCodeList[0]);
  //     } else {
  //       let indexAll = this.selectedDestCode.findIndex(
  //         (data) => data.dest_code === 'ALL'
  //       );

  //       if (indexAll > -1) {
  //         this.selectedDestCode.splice(indexAll, 1);
  //       }
  //     }
  //   }
  // }
  // containerCodeChange(): void {
  //   if (this.selectedContainer.length >= 1) {
  //     if (
  //       this.selectedContainer[this.selectedContainer.length - 1].cont_code ===
  //       'ALL'
  //     ) {
  //       this.selectedContainer = [];
  //       this.selectedContainer.push(this.containerList[0]);
  //     } else {
  //       let indexAll = this.selectedContainer.findIndex(
  //         (data) => data.cont_code === 'ALL'
  //       );

  //       if (indexAll > -1) {
  //         this.selectedContainer.splice(indexAll, 1);
  //       }
  //     }
  //   }
  // }

  // supplierChange(): void {
  //   if (this.selectedSupplier.length >= 1) {
  //     if (
  //       this.selectedSupplier[this.selectedSupplier.length - 1]
  //         .customer_supp === 'ALL'
  //     ) {
  //       this.selectedSupplier = [];
  //       this.selectedSupplier.push(this.supplierList[0]);
  //     } else {
  //       let indexAll = this.selectedSupplier.findIndex(
  //         (data) => data.customer_supp === 'ALL'
  //       );

  //       if (indexAll > -1) {
  //         this.selectedSupplier.splice(indexAll, 1);
  //       }
  //     }
  //   }
  // }

  dockChange(): void {
    if (this.selectedDock.length >= 1) {
      if (this.selectedDock[this.selectedDock.length - 1].dock === 'ALL') {
        this.selectedDock = [];
        this.selectedDock.push(this.dockList[0]);
      } else {
        let indexAll = this.selectedDock.findIndex(
          (data) => data.dock === 'ALL'
        );

        if (indexAll > -1) {
          this.selectedDock.splice(indexAll, 1);
        }
      }
    }
  }


  moduleTypeChange(): void {
    if (this.selectedModuleType.length >= 1) {
      if (this.selectedModuleType[this.selectedModuleType.length - 1].module_type === 'ALL') {
        this.selectedModuleType = [];
        this.selectedModuleType.push(this.moduleTypeList[0]);
      } else {
        let indexAll = this.selectedModuleType.findIndex(
          (data) => data.module_type === 'ALL'
        );

        if (indexAll > -1) {
          this.selectedModuleType.splice(indexAll, 1);
        }
      }
    }
  }


  lineChange(): void {
    if (this.selectedLine.length >= 1) {
      if (this.selectedLine[this.selectedLine.length - 1].line === 'ALL') {
        this.selectedLine = [];
        this.selectedLine.push(this.lineList[0]);
      } else {
        let indexAll = this.selectedLine.findIndex(
          (data) => data.line === 'ALL'
        );

        if (indexAll > -1) {
          this.selectedLine.splice(indexAll, 1);
        }
      }
    }
  }



  getModifiedRowData(): any {
    return this.rowData.filter((row) => row.rowCheckBox);
  }
  exportAsXLSX(): void {
    this.removeMessage();

    this.spinner.show();

    // eastern time api

    const easternCurrentDate = String(
      mm().tz('US/Michigan').format('YYYY-MM-DD_HH:mm:ss')
    );
    console.log(easternCurrentDate);
    let data = [];

    if (this.headerCheckboxChecked) {
      this.osPartsGrid.gridOptions.api.forEachNodeAfterFilter((node) => {
        data.push(node.data);
      });
    } else {
      data = this.getModifiedRowData();
    }

    if (data.length < 1) {
      this.spinner.hide();
      this.warningMessage = warningMessage.noRowsSelected;

      window.scroll(0, 0);
    } else {
      setTimeout(async () => {
        if (this.rowData.length > 0) {
          const exportData: any = [];

          data.forEach((e) => {
            const obj = {
              moduleType: e.module_type,
              line: e.line,
              dock: e.dock,
            };
            exportData.push(obj);
          });

          const headers = [
            'MODULE TYPE',
            'LINE',
            'DOCK',
            'CONTROLLING MODULE',
            'DOCK MODULE TYPE ACTIVE',
            'OSP LINE PATH',
            'DESCRIOTION',
            'TRACKING POINT',
            'UPDATED DT',
            'USER',
          ];

          const timestamp = easternCurrentDate

            .split('.')[0]
            .replace('T', '_')
            .split('')

            .filter((d) => d !== '-' && d !== ':')
            .join('');

          this.excelService.exportAsExcelFile(
            exportData,
            'AdjustmentInquiry_' + timestamp,

            headers
          );
        }
        this.spinner.hide();
      }, 1000);
    }
  }
  removeMessage() {
    this.warningMessage = '';
    this.successMessage = '';
  }






  userDeleteChanged(rowIndex) {
    const rowNode =
      this.osPartsGrid.gridOptions.api.getDisplayedRowAtIndex(rowIndex);

    // this.mdid = rowNode.data.master_id;
    // this.mdkey = rowNode.data.key_item.trim();
    this.opendeleteDialog(rowNode.data);
  }

  userEdit(rowIndex) {
    const rowNode =
      this.osPartsGrid.gridOptions.api.getDisplayedRowAtIndex(rowIndex);

    // this.mid = rowNode.data.master_id;
    // this.mname = rowNode.data.name;
    // this.mkey = rowNode.data.key_item.trim();
    // this.mdata = rowNode.data.data_item.trim();
    this.openeditDialog(rowNode.data);
  }

  checkBoxEdit(rowIndex) {
    const rowNode =
      this.osPartsGrid.gridOptions.api.getDisplayedRowAtIndex(rowIndex);
    this.openeditDialog(rowNode.data);
  }

  
  editModuleTypeCheck(params){

    let urlValue: any;
    if (params.column === 'conModule'){
      urlValue = "is_controlling_module";
    } else if (params.column === 'moduleToDock'){
      urlValue = "is_module_to_dock";
    } else{
      //do nothing
    }

     const data =  {
        business_entity: this.namcValue,
        workid: this.user,
        user_role: this.userRole,
        userid: this.userName,
        dock: params.data.dock,
        line : params.data.line,
        module_type : params.data.module_type,
        is_controlling_module : params.data.is_controlling_module,
        is_module_to_dock : params.data.is_module_to_dock
      }
      this.spinner.show();
      this.moduleStockingService.editModuleTypeCheck(data, urlValue).subscribe({
        error: this.errorCallback,
        next: (data) => {
          this.onSearch(0);
        },
      });

  }

  
  //delete dialog popup
  opendeleteDialog(dataTODelete) {
    console.log("dataTODelete", dataTODelete);
    var data = {
      business_entity: this.namcValue,
      workid: this.user,
      user_role: this.userRole,
      userid: this.userName,
      dock: dataTODelete?.dock,
      module_type: dataTODelete?.module_type
    }
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.id = this.modalComponent;

    dialogConfig.width = '390px';
    const dialogRef = this.dialog.open(ModuleTypeDeleteDialogComponent, dialogConfig);
    dialogRef.afterClosed().subscribe((res) => {
      this.removeMessage();
      if (res === 'cancel') {
        return;
      }
      if (res === 'save') {
        console.log("inside save")
        const delMessage = `Module Type "${data.module_type}" associated with Dock "${data.dock}" has been deleted.`;
        this.moduleStockingService.deleteModuleType(data).subscribe({
          error: this.errorCallback,
          next: (data) => {
            console.log(data)
            if (data.body.DeletedCount >= 1) {
              this.onSearch(0);              
              this.successMessage = delMessage;
              this.spinner.hide();
              window.scroll(0, 0);
            } else {
              this.warningMessage = `${warningMessage.apiLogicFail}  ${this.itContact}.`;
              this.rowData = [];
              this.osPartsGrid.gridOptions.api.setRowData(this.rowData);
              this.spinner.hide();
            }
          },
        });

        // return;
      }

      // this.OspService.deleteMasterDetails(
      //   res.business_entity,
      //   this.userRole,
      //   this.user,
      //   this.mdid,
      //   this.mdkey
      // ).subscribe({
      //   error: this.errorCallback,
      //   next: (data) => {
      //     if (data.body.masterdeleteResponse === 1) {
      //       this.successMessage = successMessage.deleteRecord;
      //       this.spinner.hide();
      //       window.scroll(0, 0);
      //       this.loadMasterDetails();
      //     } else {
      //       this.warningMessage = `${warningMessage.apiLogicFail}  ${this.itContact}.`;
      //       this.rowData = [];
      //       this.osPartsGrid.gridOptions.api.setRowData(this.rowData);
      //       this.master_id = '';
      //       this.m_name = '';
      //       this.spinner.hide();
      //     }
      //   },
      // });
    });
  }
  //edit dialog popup
  updatedRows = [];  

  openeditDialog(data) {
    console.log("edit clicked");
    console.log("data", data)
    const isControllingModule = data.is_controlling_module;
    // this.successMessage = successMessage.moduleTypeUpdated;
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.id = this.modalComponent;
    dialogConfig.width = '650px';
    dialogConfig.data = {
      partNoList: this.partNoListAdd,
      dockList: this.dockListForpopup,
      linePathList: this.linePathList,
      moduleTypeToDockList: this.moduleTypeToDockForpopup,
      moduleTypeList: this.moduleTypeListForpopup,
      lineList: this.lineListForpopup,
      trackingPointList: this.trackingPointListForpopup,
      data,
      mode: 'edit',
    };

    const dialogRef = this.dialog.open(ModuleTypeEditDialogComponent, dialogConfig);
    const editGetResponse = dialogRef.componentInstance.editMasterEventEmitter.subscribe((res) => {
      console.log("Emission received:", res); // Add this to check if the event is triggered
      var data1 = {
        module_type: res.module_type,
        module_description: res.module_desc,
        line: res.line,
        dock: res.dock,
        osp_line_path: res.line_path,
        galc_tracking_point: res.tracking_point,
        is_module_to_dock: res.is_module_type_to_dock,
        is_controlling_module: isControllingModule,
        created_userid: this.user,
        userid: this.userName,
      };

      var data = {
        business_entity: this.namcValue,
        workid: this.user,
        user_role: this.userRole,
        userid: this.userName,
        dock:  res.dock,
        data: [data1]
      };

      this.spinner.show();
      const editMessage = `Module Type "${data1.module_type}" associated with Dock "${data1.dock}" has been updated.`;
      this.moduleStockingService.editModuleType(data).subscribe({
        error: this.errorCallback,
        next: (response) => {
          this.updatedRows.push(...response.body.updatedRows);
          console.log("API Response:", response);
          if (this.updatedRows.length > 0) {
            this.successMessage = editMessage;
            this.spinner.hide();
            this.rowData = this.updatedRows;
            this.updatedRows = [];
          } else {
            this.updatedRows = [];
            this.warningMessage = `${warningMessage.reportsNotUpdated}  ${this.itContact}.`;
          }
    
          this.spinner.hide();
        },
      });
    });






  }

  addedRows = [];

  openDialog() {
    this.removeMessage();


    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.id = this.modalComponent;
    dialogConfig.width = '650px';
    // dialogConfig.height = '500px';
    dialogConfig.data = {
      partNoList: this.partNoListAdd,
      dockList: this.dockListForpopup,
      linePathList: this.linePathList,
      moduleTypeToDockList: this.moduleTypeToDockForpopup,
      moduleTypeList: this.moduleTypeListForpopup,
      lineList: this.lineListForpopup,
      trackingPointList: this.trackingPointListForpopup,
      mode: 'add',
    };
    const dialogRef = this.dialog.open(ModuleTypeEditDialogComponent, dialogConfig);
    const addGetResponse =
      dialogRef.componentInstance.addMasterEventEmitter.subscribe((res) => {
        console.log("res---", res);
        var data = {
          business_entity: this.namcValue,
          workid: this.user,
          user_role: this.userRole,
          userid: this.userName,
          module_type: res.module_type,
          module_description: res.module_desc,
          line: res.line,
          dock: res.dock,
          osp_line_path: res.line_path,
          tracking_point: res.tracking_point,
          is_module_to_dock: res.is_module_type_to_dock,
          is_controlling_module: false,
          created_userid: this.user,
          updated_userid: this.user,
          batch_run_logid: "1"
        }
        this.spinner.show();
        const addMessage = `Module Type "${data.module_type}" associated with Dock "${data.dock}" has been added.`;
        this.moduleStockingService.addModuleType(
          data
        ).subscribe({
          error: this.errorCallback,
          next: (response) => {
          console.log("API Response:", response);
          this.addedRows.push(...response.body.updatedRows);
          if (this.addedRows.length > 0) {
            this.successMessage = addMessage;
            this.spinner.hide();
            this.rowData = this.addedRows;
            this.addedRows = [];
          } else {
            this.addedRows = [];
            this.warningMessage = `${warningMessage.reportsNotUpdated}  ${this.itContact}.`;
          }
          this.spinner.hide();
          },
        });
      });
    dialogRef.componentInstance.moduleTypeChanged.subscribe((res) => {
      console.log("Hii");
    });

    dialogRef.afterClosed().subscribe((res) => {
      addGetResponse.unsubscribe();
    });
  }
  // This method will be called when the child emits the event
  AddModuleTypeChange(selectedModule: any) {
    console.log('Module Type Changed in Child:', selectedModule);
    // Add any logic you want to handle when the dropdown value changes
  }
}