import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { HeaderCheckboxComponent } from '../core/grid/header-checkbox/header-checkbox.component';
import { GridRefreshComponent } from '../core/grid/grid-refresh/grid-refresh.component';
import { RowCheckboxComponent } from '../core/grid/row-checkbox/row-checkbox.component';
import { NgxSpinnerService } from 'ngx-spinner';
import { CustomTooltipComponent } from '../core/custom-tooltip/custom-tooltip.component';
import { GriddataService } from '../services/griddata.service';
import {
  MatLegacyDialog as MatDialog,
  MatLegacyDialogConfig as MatDialogConfig,
} from '@angular/material/legacy-dialog';
import { ConfirmCancelDailogComponent } from '../core/confirm-cancel-dailog/confirm-cancel-dailog.component';
import { DialogGridComponent } from '../core/dialog-grid/dialog-grid.component';
import { GridTextEditorComponent } from '../core/grid/grid-text-edit.component';
import { ExcelService } from '../services/excel.service';
import { OrderforecastService } from '../services/orderforecast/orderforecast.service';
import { OspService } from '../core/master-services/osp.service';
import * as moment from 'moment';
import { OSPStateService } from '../services/osp-state.service';
import { forkJoin, Subscription } from 'rxjs';
import { BulkadjustmentService } from '../services/bulk-adjustment/bulkadjustment.service';
import { FluctuationAllowanceService } from '../services/fluctuation-allowance/fluctuation-allowance.service';
import { LookupService } from '../services/lookup/lookup.service';
import {
  dateFilterComparator,
  freezingMesssage,
  getCellStyleConfig,
  getRowCheckboxSyleConfig,
  resetSortingAndFilters,
  validateVanningDates,
} from '../constants';
import { checkAccess } from '../constants';
import { warningMessage, successMessage } from '../constants';
import * as mm from 'moment-timezone';
import { OfErrorDialogComponent } from './of-error-dialog/of-error-dialog.component';

@Component({
  selector: 'app-orderforecast',
  templateUrl: './orderforecast.component.html',
  styleUrls: ['./orderforecast.component.scss'],
})
export class OrderforecastComponent implements OnInit, OnDestroy {
  namcValue = '';
  user = '';
  userRole = '';
  count = 0;
  rowData = [];
  @ViewChild('osPartsGrid') osPartsGrid;
  columnDefsMaster;
  columnDefs;
  // columnDefsTemp;
  public defaultColDef;
  rowSelection;
  orderSubscription$: Subscription;

  msgNoContent = 'No Content';
  gridOptions;
  paginationPageSize;
  pageSize;
  paginationNumberFormatter;
  pagination = 'true';
  context: any;
  editType;
  suppressClickEdit = true;
  tooltipShowDelay = 0;
  frameworkComponents = { customTooltip: CustomTooltipComponent };

  editEnabled: boolean = false;
  freezeStatus: boolean = false;
  filter: boolean = false;
  showToggleAll: boolean = false;
  showClear: boolean = true;
  allRowSelected: boolean = false;

  // Dropdowns
  selectedKanban: any = [];
  selectedPartNo: any = [];
  selectedDestCode: any = [];
  selectedContainer: any = [];
  selectedSupplier: any = [];
  selectedDock: any = [];
  selectedSpecialist: any = [];
  selectedLifecycle: any = [];
  successMessage = '';
  warningMessage = '';
  vanningFrom: any;
  vanningTo: any;

  kanbanSearchValue: any;

  kanbanListSelected = [];
  // Dropdown Options
  kanbanList: any = [];
  partNoList: any = [];
  destCodeList: any = [];
  containerList: any = [];
  supplierList: any = [];
  dockList: any = [];
  specialistList: any = [];
  lifecycleList: any = [];
  adjCodeList: any = [];
  rowCount: number = 0;
  isEditMode: boolean = false;
  dataCount: number = 0;
  offset: number = 0;
  minVanningFrom;

  orderType = 'Order Type';
  orderForecast = 'Order/Forecast';
  adjReason = 'Adj Reason';
  //agRightAlignedCell = 'ag-right-aligned-cell';

  bulkAdjustmentHeaders = [
    'ITEM_ID',
    'ORDER_TYPE',
    'CONT_CODE',
    'ADJ_QTY',
    'VANNING_DATE',
    'ADJ_CODE',
    'ADJ_REASON',
    'REMARKS                                                         ',
  ];

  showHideList: any = [
    { label: 'Dock', value: 'dock' },
    { label: 'SPC (Specialist)', value: 'specialist' },
    {
      label: 'Life Cycle',
      value: 'life_cycle',
    },
    {
      label: this.orderType,
      value: 'order_type',
    },
    // {
    //   label: 'QPC (Quantity Per Container, Order Lot)',
    //   value: 'order_lot',
    // },
    {
      label: 'RQMT',
      value: 'basic_req_veh',
    },
    {
      label: 'NV RQMT',
      value: 'basic_req_nveh',
    },
    {
      label: 'C/O',
      value: 'carry_over_qty',
    },
    {
      label: 'Sys Adj',
      value: 'sys_adj',
    },
    {
      label: 'BOA (Back Order Adjustment)',
      value: 'boa',
    },
    {
      label: 'Order Plan',
      value: 'plan_qty',
    },
    {
      label: 'Man Adj',
      value: 'manualAdjQtyText',
    },
    {
      label: 'FA Min',
      value: 'rev_min_qty',
    },
    {
      label: 'FA Max',
      value: 'rev_max_qty',
    },
    {
      label: this.orderForecast,
      value: 'final_order_qty',
    },
    {
      label: 'BO Qty (Build Out Qty)',
      value: 'boQty',
    },
    {
      label: 'KVC Ship',
      value: 'act_van_qty',
    },
    {
      label: this.adjReason,
      value: 'adj_reason',
    },
    {
      label: 'Updated DT',
      value: 'date_time',
    },
    {
      label: 'User',
      value: 'userid',
    },
  ];

  selectedShowHideList: any = [...this.showHideList];

  kanbanSelected = 1;
  disablePartNumber: boolean;
  disableKanban: boolean;
  showGrid: boolean;

  fileBuffer: any;
  namcName: string;
  uploading: boolean;
  fileName: string;
  validationError: boolean;
  errorData: any;
  loading: boolean;

  onInitVanningFrom: any;
  onInitVanningTo: any;

  defaultSpinnerMessage = 'Loading... Please wait...';
  dataLoadingMessage = 'Loading data in Bulk! It might take a while, Please wait...';
  spinnerMessage = this.defaultSpinnerMessage;

  headerCheckboxChecked: boolean = false;
  itContact: string;
  defaultPageSize = 100000;

  dateFormat = 'MM/DD/YYYY';
  newDate = String(mm().tz('US/Michigan').format(this.dateFormat)).substring(0, 10);
  isSearchDisabled = false;

  constructor(
    private readonly spinner: NgxSpinnerService,
    private readonly service: OrderforecastService,
    private readonly OspService: OspService,
    private readonly excelService: ExcelService,
    private readonly gridDataService: GriddataService,
    public dialog: MatDialog,
    private readonly stateService: OSPStateService,
    private readonly bulkadjustmentService: BulkadjustmentService,
    private readonly flalService: FluctuationAllowanceService,
    private readonly lookupService: LookupService
  ) {
    this.context = {
      componentParent: this,
    };
  }

  comparator(row1, row2) {
    const date1 = new Date(row1.vanning_date).getTime();
    const date2 = new Date(row2.vanning_date).getTime();

    if (date1 !== date2) {
      // Ascending
      return date1 > date2 ? 1 : -1;

      // Descending
      // return date1 > date2 ? -1 : 1;
    } else {
      return row1.item_id > row2.item_id ? 1 : -1;
    }
  }

  numberComparator(number1, number2) {
    return number1 - number2;
  }

  // DATE COMPARATOR FOR SORTING
  dateComparator(date1, date2) {
    function monthToNum(date) {
      if (date === undefined || date === null) {
        return null;
      }

      const newDate: Date = new Date(date);

      const yearNumber = newDate.getFullYear();
      const monthNumber = newDate.getMonth();
      const dayNumber = newDate.getDate();
      const hoursNumber = newDate.getHours();
      const minutesNumber = newDate.getMinutes();

      const result = yearNumber * 10000 + monthNumber * 100 + dayNumber + hoursNumber * 60 + minutesNumber * 10;
      // 29/08/2004 => 20040829
      return result;
    }

    const date1Number = monthToNum(date1);
    const date2Number = monthToNum(date2);

    if (date1Number === null && date2Number === null) {
      return 0;
    }
    if (date1Number === null) {
      return -1;
    }
    if (date2Number === null) {
      return 1;
    }

    return date1Number - date2Number;
  }

  ngOnDestroy(): void {
    this.orderSubscription$.unsubscribe();
  }

  showSpinner(message?: string) {
    if (!message) {
      this.spinnerMessage = this.defaultSpinnerMessage;
    } else {
      this.spinnerMessage = message;
    }

    this.spinner.show();
  }

  hideSpinner() {
    this.spinner.hide();
  }

  ngOnInit(): void {
    this.showGrid = true;
    this.showSpinner();

    this.columnDefsMaster = [
      {
        headerName: '',
        field: 'rowCheckBox',
        width: 44,
        headerComponentFramework: HeaderCheckboxComponent,
        floatingFilterComponentFramework: GridRefreshComponent,
        floatingFilterComponentParams: { suppressFilterButton: true },
        cellClass: 'ui-grid-cell-contents-auto',
        cellStyle: getRowCheckboxSyleConfig,
        cellRendererFramework: RowCheckboxComponent,
        floatingFilter: true,
        pinned: 'left',
        tooltipValueGetter: (params) => {
          if (
            new Date(params.data.vanning_date) < new Date(this.onInitVanningFrom) ||
            (new Date(params.data.vanning_date) === new Date(this.onInitVanningFrom) &&
              params.data.order_status === 'F')
          ) {
            return 'You are not allowed to edit past records.';
          } else {
            return '';
          }
        },
      },

      {
        headerName: 'Van DT',
        headerTooltip: 'Vanning Date(mm/dd/yyyy)',
        field: 'vanning_date',
        sortable: true,
        width: 110,

        filter: 'agDateColumnFilter',
        filterParams: {
          comparator: dateFilterComparator,
        },
        floatingFilter: true,

        comparator: this.dateComparator,

        pinned: 'left',
      },
      {
        headerName: 'Prod DT',
        headerTooltip: 'Prod DT(mm/dd/yyyy)',
        field: 'prod_date',
        sortable: true,
        width: 110,

        filter: 'agDateColumnFilter',
        filterParams: {
          comparator: dateFilterComparator,
        },
        floatingFilter: true,

        comparator: this.dateComparator,

        pinned: 'left',
      },
      {
        headerName: 'Part No',
        field: 'item_id',
        sortable: true,
        width: 120,
        floatingFilter: true,
        pinned: 'left',
      },
      {
        headerName: 'Part Description',
        field: 'part_description',
        width: 150,
        floatingFilter: true,
        pinned: 'left',
        tooltipField: 'part_description',
      },
      {
        headerName: 'Kanban',
        field: 'kanban',
        sortable: true,
        width: 100,
        floatingFilter: true,
        pinned: 'left',
      },
      {
        headerName: 'CC',
        field: 'cont_code',
        headerTooltip: 'Container Code',
        width: 80,
        floatingFilter: true,
        pinned: 'left',
      },
      {
        headerName: 'QPC',
        pinned: 'left',
        headerTooltip: 'Quantity Per Container, Order Lot',
        field: 'order_lot',
        width: 105,
        floatingFilter: true,
        //cellClass: this.agRightAlignedCell,

        filter: 'agNumberColumnFilter',
        floatingFilterComponentParams: { suppressFilterButton: false },
        filterParams: {
          numberParser: (text) => {
            return text === null ? null : parseFloat(text.replace(',', '.'));
          },
        },
      },
      {
        headerName: 'Dock',
        field: 'dock',
        width: 120,
        floatingFilter: true,
        tooltipField: 'dock',
      },
      {
        headerName: 'SPC',
        field: 'specialist',
        width: 120,
        floatingFilter: true,
        headerTooltip: 'Specialist',
        tooltipField: 'specialist',
      },
      {
        headerName: 'Life Cycle',
        headerTooltip: 'Life Cycle',
        field: 'life_cycle',
        width: 100,
        floatingFilter: true,
        headerClass: 'ag-theme-custom-text-right',
      },
      {
        headerName: this.orderType,
        headerTooltip: 'Order Type',
        field: 'order_type',
        width: 100,
        floatingFilter: true,
        headerClass: 'ag-theme-custom-text-right',
      },
      {
        headerName: 'RQMT',
        field: 'basic_req_veh',
        width: 100,
        floatingFilter: true,
        //cellClass: this.agLe,
        // cellStyle: function (params) {
        //   if (
        //     new Date(params.data.vanning_date) <
        //     new Date(
        //       String(mm().tz('US/Michigan').format('MM/DD/YYYY')).substring(
        //         0,
        //         10
        //       )
        //     )
        //   ) {
        //     return {
        //       background: '#f8f9fa',
        //       // display: 'flex',
        //       // 'justify-content': 'center' /* align horizontal */,
        //       // 'align-items': 'center',
        //     }; // grey color
        //   } else if (
        //     new Date(params.data.vanning_date) >
        //     new Date(
        //       String(mm().tz('US/Michigan').format('MM/DD/YYYY')).substring(
        //         0,
        //         10
        //       )
        //     )
        //   ) {
        //     return {
        //       background: '#cfe4f5',
        //       // display: 'flex',
        //       // 'justify-content': 'center' /* align horizontal */,
        //       // 'align-items': 'center',
        //     }; // blue color
        //   } else {
        //     return { background: '#e6ef76cf' }; // yellow color
        //   }
        // },

        filter: 'agNumberColumnFilter',
        floatingFilterComponentParams: { suppressFilterButton: false },
        filterParams: {
          numberParser: (text) => {
            return text === null ? null : parseFloat(text.replace(',', '.'));
          },
        },
      },
      {
        headerName: 'NV RQMT',
        field: 'basic_req_nveh',
        width: 100,
        floatingFilter: true,
        //cellClass: this.agRightAlignedCell,

        filter: 'agNumberColumnFilter',
        floatingFilterComponentParams: { suppressFilterButton: false },
        filterParams: {
          numberParser: (text) => {
            return text === null ? null : parseFloat(text.replace(',', '.'));
          },
        },
      },
      {
        headerName: 'C/O',
        field: 'carry_over_qty',
        width: 100,
        floatingFilter: true,
        //cellClass: [this.agRightAlignedCell, 'my-class'],
        //type: 'rightAligned',
        valueGetter: (params) => {
          return parseInt(params.data.carry_over_qty);
        },

        filter: 'agNumberColumnFilter',
        floatingFilterComponentParams: { suppressFilterButton: false },
        filterParams: {
          numberParser: (text) => {
            return text === null ? null : parseFloat(text.replace(',', '.'));
          },
        },
        // cellStyle: function (params) {
        //   if (
        //     new Date(params.data.vanning_date) <
        //     new Date(
        //       String(mm().tz('US/Michigan').format('MM/DD/YYYY')).substring(
        //         0,
        //         10
        //       )
        //     )
        //   ) {
        //     return {
        //       background: '#f8f9fa',
        //       // 'padding-right':'30px',
        //     }; // grey color
        //   } else if (
        //     new Date(params.data.vanning_date) >
        //     new Date(
        //       String(mm().tz('US/Michigan').format('MM/DD/YYYY')).substring(
        //         0,
        //         10
        //       )
        //     )
        //   ) {
        //     return {
        //       background: '#cfe4f5',
        //       // display: 'flex',
        //       // 'justify-content': 'center' /* align horizontal */,
        //       // 'align-items': 'center',
        //       // 'padding-right':'30px',
        //     }; // blue color
        //   } else {
        //     return { background: '#e6ef76cf' }; // yellow color
        //   }
        // },
      },

      {
        headerName: 'Sys Adj',
        field: 'sys_adj',
        width: 100,
        floatingFilter: true,
        //cellClass: [this.agRightAlignedCell, 'my-class'],
        //type: 'rightAligned',

        valueGetter: (params) => parseInt(params.data.sys_adj),

        sortable: true,
        comparator: this.numberComparator,

        filter: 'agNumberColumnFilter',
        floatingFilterComponentParams: { suppressFilterButton: false },
        filterParams: {
          numberParser: (text) => {
            return text === null ? null : parseFloat(text.replace(',', '.'));
          },
        },
      },
      {
        headerName: 'BOA',
        field: 'boa',
        headerTooltip: 'Back Order Adjustment',
        width: 80,
        floatingFilter: true,
        //cellClass: this.agRightAlignedCell,

        valueGetter: (params) => parseInt(params.data.boa),

        sortable: true,
        comparator: this.numberComparator,

        filter: 'agNumberColumnFilter',
        floatingFilterComponentParams: { suppressFilterButton: false },
        filterParams: {
          numberParser: (text) => {
            return text === null ? null : parseFloat(text.replace(',', '.'));
          },
        },
      },
      {
        headerName: 'Order Plan',
        field: 'plan_qty',
        width: 100,
        floatingFilter: true,
        //cellClass: this.agRightAlignedCell,

        filter: 'agNumberColumnFilter',
        floatingFilterComponentParams: { suppressFilterButton: false },
        filterParams: {
          numberParser: (text) => {
            return text === null ? null : parseFloat(text.replace(',', '.'));
          },
        },
      },
      {
        headerName: 'Man Adj',
        field: 'manualAdjQtyText',
        cellRendererFramework: GridTextEditorComponent,
        cellRendererParams: { column: 'man' },
        width: 100,
        floatingFilter: true,

        valueGetter: (params) => {
          if (!this.editEnabled || !params.data.rowCheckBox) {
            return parseInt(params.data.man);
          } else {
            const value = params.data.manualAdjQtyText?.value ?? params.data.man;

            return parseInt(value);
          }
        },

        sortable: true,
        comparator: this.numberComparator,

        filter: 'agNumberColumnFilter',
        floatingFilterComponentParams: { suppressFilterButton: false },
        filterParams: {
          numberParser: (text) => {
            return text === null ? null : parseFloat(text.replace(',', '.'));
          },
        },

        //cellClass: this.agRightAlignedCell,
      },
      {
        headerName: 'FA Min',
        field: 'rev_min_qty',
        width: 95,
        floatingFilter: true,
        //cellClass: this.agRightAlignedCell,

        filter: 'agNumberColumnFilter',
        floatingFilterComponentParams: { suppressFilterButton: false },
        filterParams: {
          numberParser: (text) => {
            return text === null ? null : parseFloat(text.replace(',', '.'));
          },
        },
      },
      {
        headerName: 'FA Max',
        field: 'rev_max_qty',
        width: 95,
        floatingFilter: true,
        //cellClass: this.agRightAlignedCell,

        filter: 'agNumberColumnFilter',
        floatingFilterComponentParams: { suppressFilterButton: false },
        filterParams: {
          numberParser: (text) => {
            return text === null ? null : parseFloat(text.replace(',', '.'));
          },
        },
      },
      {
        headerName: this.orderForecast,
        headerTooltip: this.orderForecast,
        field: 'final_order_qty',
        width: 100,
        floatingFilter: true,
        //cellClass: this.agRightAlignedCell,

        filter: 'agNumberColumnFilter',
        floatingFilterComponentParams: { suppressFilterButton: false },
        filterParams: {
          numberParser: (text) => {
            return text === null ? null : parseFloat(text.replace(',', '.'));
          },
        },
      },
      {
        headerName: 'BO Qty',
        field: 'boQty',
        headerTooltip: 'Build Out Qty',
        width: 100,
        floatingFilter: true,
        //cellClass: this.agRightAlignedCell,

        filter: 'agNumberColumnFilter',
        floatingFilterComponentParams: { suppressFilterButton: false },
        filterParams: {
          numberParser: (text) => {
            return text === null ? null : parseFloat(text.replace(',', '.'));
          },
        },
      },
      {
        headerName: 'KVC Ship',
        field: 'act_van_qty',
        width: 100,
        floatingFilter: true,

        filter: 'agNumberColumnFilter',
        floatingFilterComponentParams: { suppressFilterButton: false },
        filterParams: {
          numberParser: (text) => {
            return text === null ? null : parseFloat(text.replace(',', '.'));
          },
        },
      },
      {
        headerName: this.adjReason,
        field: 'adj_reason',
        width: 120,
        floatingFilter: true,
        tooltipField: 'adj_reason',
      },
      {
        headerName: 'Updated DT',
        headerTooltip: 'Updated Date(mm/dd/yyyy hh:mm:ss (Eastern))',
        field: 'date_time',
        width: 152,
        floatingFilter: true,
      },
      {
        headerName: 'User',
        field: 'userid',
        width: 160,
        floatingFilter: true,
        tooltipField: 'userid',
      },
    ];

    this.columnDefs = [...this.columnDefsMaster];

    this.setPageSizeToAll();

    this.rowSelection = 'multiple';
    this.editType = 'fullRow';
    this.gridOptions = this.gridDataService.getGridOptions();
    this.gridOptions.onGridReady = function (params) {
      params.api.hideOverlay();
      this.gridApi = params.api;
    };
    this.gridOptions.defaultColDef.cellStyle = getCellStyleConfig;

    this.orderSubscription$ = this.stateService.getNamc().subscribe((observable) => {
      this.initializeData();
    });
  }

  setVanningDates(): void {
    const data = {
      business_entity: this.namcValue,
      workid: this.user,
      user_role: this.userRole,
    };

    this.flalService.getMaxDate(data).subscribe((success) => {
      if (!success.body.data.minVanDate[0].min) {
        this.hideSpinner();
        return;
      }

      const minVan = new Date(success.body.data.minVanDate[0].min);
      const inTenDays = new Date(success.body.data.minVanDateTo[0].min);

      this.vanningFrom = moment(minVan).format('YYYY-MM-DD');
      this.onInitVanningFrom = this.vanningFrom;

      // this.vanningTo = moment(inTenDays).format('YYYY-MM-DD');
      // this.onInitVanningTo = this.vanningTo;
    });
  }

  onPageSizeChanged() {
    if (this.gridOptions?.api) {
      this.gridOptions.api.paginationSetPageSize(Number(this.paginationPageSize));
    }
  }

  setPageSizeToAll(pageSize?) {
    this.paginationPageSize = this.rowData.length;

    if (pageSize) {
      this.paginationPageSize = 0;
      this.rowData.length = 0;
    }

    this.onPageSizeChanged();
  }

  canDeactivate() {
    return this.editEnabled;
  }
  access1;
  initializeData(): void {
    this.onResetDropDown();
    this.itContact = localStorage.getItem('itContact');

    this.rowData = [];
    this.offset = 0;
    this.isEditMode = false;
    this.editEnabled = this.isEditMode;

    this.showSpinner();

    this.namcValue = localStorage.getItem('namcvalue');

    this.user = localStorage.getItem('workdayId');

    this.userRole = localStorage.getItem('UserRoles');

    this.namcName = localStorage.getItem('namcName');

    const orderforecastPermission = checkAccess();
    this.access1 = orderforecastPermission;

    this.loadDrop();
  }

  loadDrop() {
    this.spinner.show();

    const data = {
      business_entity: this.namcValue,
      workid: this.user,
      user_role: this.userRole,
    };

    const lookupSubscriptions = [
      this.lookupService.getKanban(),
      this.lookupService.getPartNumber(),
      this.lookupService.getDestCode(),
      this.lookupService.getSupplier(),
      this.lookupService.getContainer(),
      this.lookupService.getSpecialist(),
      this.lookupService.getBatchStatus(data),
      this.flalService.getMaxDate(data),
      this.lookupService.getDock(),
      this.service.getLifeCycle(this.namcValue, this.userRole, this.user),
      this.lookupService.getUserShowHideColumns(),
    ];

    forkJoin(lookupSubscriptions).subscribe({
      next: (res) => {
        //if kanban list, add ALL option
        this.kanbanList = [];
        this.partNoList = [];
        this.destCodeList = [];
        this.containerList = [];
        this.supplierList = [];
        this.dockList = [];
        this.specialistList = [];
        this.lifecycleList = [];
        if (res[0].body.data) {
          this.kanbanList.push({ kanban: 'ALL' });
        }
        this.kanbanList.push(...res[0].body.data);

        //if item id list, add ALL option
        if (res[1].body.data) {
          this.partNoList.push({ item_id: 'ALL' });
        }

        this.partNoList.push(...res[1].body.data);

        if (res[2].body.data) {
          this.destCodeList.push({ dest_code: 'ALL' });
        }

        this.destCodeList.push(...res[2].body.data);

        if (res[3].body.data) {
          this.supplierList.push({ customer_supp: 'ALL' });
        }

        this.supplierList.push(...res[3].body.data);
        if (res[4].body.data) {
          this.containerList.push({ cont_code: 'ALL' });
        }
        this.containerList.push(...res[4].body.data);

        if (res[5].body.data) {
          this.specialistList.push({ EMPLOYEE: 'ALL' });
        }

        this.specialistList.push(...res[5].body.data);
        if (res[8].body.data) {
          this.dockList.push({ dock: 'ALL' });
        }
        this.dockList.push(...res[8].body.data);
        if (res[9].body.data) {
          this.lifecycleList.push({ life_cycle: 'ALL' });
        }
        this.lifecycleList.push(...res[9].body.data);

        // batch status check
        let runningBatches = res[6].body.data.runningBatches ?? '';
        const batchesToIgnore = res[6].body.data.batchesToIgnore.map((batch) => batch.batch_name);

        runningBatches = runningBatches
          .split(',')
          .filter((batchName) => !batchesToIgnore.includes(batchName))
          .join(',');

        const count = runningBatches.length;

        if (count > 0) {
          this.freezingMesssage = `${runningBatches}  ${freezingMesssage}`;
          this.spinner.hide();
          this.batchCheck = true;
        } else {
          this.freezingMesssage = '';
          this.batchCheck = false;
        }

        const { orderforecast_columns } = res[10].body.showHideColumnsResponse;

        try {
          const hideItems = JSON.parse(orderforecast_columns);

          if (typeof hideItems === 'object' && hideItems.length > 0) {
            this.selectedShowHideList = this.showHideList.filter(
              (sItem) => !hideItems.map((item) => item.value).includes(sItem.value)
            );

            // logData(hideItems);

            this.onShowHideChange({
              value: this.selectedShowHideList,
              onInit: true,
            });
          }
        } catch (error) {}

        // set vanning dates in UI dropdowns
        const vanningDatesData = res[7].body.data.minVanDate[0].vanning_date;

        if (!vanningDatesData) {
          this.spinner.hide();
          return;
        }

        this.vanningFrom = res[7].body.data.minVanDate[0].vanning_date;
        this.onInitVanningFrom = this.vanningFrom;

        localStorage.setItem('minVanningFrom', this.onInitVanningFrom);

        // this.vanningTo = res[7].body.data.minVanDateTo[0].vanning_date;
        // this.onInitVanningTo = this.vanningTo;
        this.spinner.hide();
      },
      error: this.errorCallback,
    });
  }

  oldDataLoad() {
    this.lookupService.getKanban().subscribe((res) => {
      this.kanbanList = res.body.data;
    });

    this.lookupService.getPartNumber().subscribe((res) => {
      this.partNoList = res.body.data;
    });

    this.lookupService.getDestCode().subscribe((res) => {
      this.destCodeList = res.body.data;
    });

    this.lookupService.getContainer().subscribe((res) => {
      this.containerList = res.body.data;
    });

    this.lookupService.getSupplier().subscribe((res) => {
      this.supplierList = res.body.data;
    });

    this.lookupService.getDock().subscribe((res) => {
      this.dockList = res.body.data;
    });

    this.lookupService.getSpecialist().subscribe((res) => {
      this.specialistList = res.body.data;
    });

    this.service.getLifeCycle(this.namcValue, this.userRole, this.user).subscribe((res) => {
      this.lifecycleList = res.body.data;
    });

    this.setVanningDates();

    this.getBatchStatus('');

    this.service.getAdjCode(this.namcValue, this.userRole, this.user).subscribe((res) => {
      this.adjCodeList = res.body.data;
    });
  }

  onResetDropDown() {
    this.removeMessage();
    this.selectedKanban = [];
    this.selectedPartNo = [];
    this.selectedDestCode = [];
    this.selectedContainer = [];
    this.selectedSupplier = [];
    this.selectedDock = [];
    this.selectedSpecialist = [];
    this.selectedLifecycle = [];

    this.kanbanSearchValue = null;

    this.setPageSizeToAll(this.defaultPageSize);

    this.vanningFrom = this.onInitVanningFrom;
    this.vanningTo = this.onInitVanningTo;

    this.rowData = [];
    this.disableKanban = false;
    this.disablePartNumber = false;
    this.offset = 0;

    if (this.osPartsGrid) {
      this.headerCheckboxChecked = false;
      this.osPartsGrid.gridOptions.api.refreshHeader();
      this.resetGrid();
    }
  }

  resetGrid() {
    if (this.osPartsGrid) {
      resetSortingAndFilters(this.osPartsGrid);

      return;
    }
  }

  clearVanningDates() {
    // Making these 2 undefined because of Kanban
    // Users want to search for a particular kanban irrespective of van date range
    this.vanningFrom = undefined;
    this.vanningTo = undefined;
  }
  orderforecast_columns;
  onShowHideChange(ev: any) {
    this.osPartsGrid.gridOptions.columnApi.setColumnsVisible([...this.showHideList.map((e) => e.value)], true);

    const hideItems = this.showHideList.filter((item) => {
      return !ev.value.map((sItem) => sItem.value).includes(item.value);
    });

    this.osPartsGrid.gridOptions.columnApi.setColumnsVisible([...hideItems.map((e) => e.value)], false);

    if (ev.onInit) return;

    this.orderforecast_columns = hideItems;
    if (!hideItems.length) this.orderforecast_columns = [{}];
  }
  saveShowHide() {
    this.spinner.show();
    let orderforecast_columns = this.orderforecast_columns;
    if (orderforecast_columns) {
      this.lookupService.updateUserShowHideColumns({ orderforecast_columns }).subscribe({
        error: this.errorCallback,
        next: (res) => {
          console.log(res);
          this.spinner.hide();
        },
      });
    } else {
      this.spinner.hide();
    }
  }

  onKanbanChange(): void {
    if (this.selectedKanban.length >= 1) {
      if (this.selectedKanban[this.selectedKanban.length - 1].kanban === 'ALL') {
        this.selectedKanban = [];
        this.selectedKanban.push(this.kanbanList[0]);
      } else {
        let indexAll = this.selectedKanban.findIndex((data) => data.kanban === 'ALL');

        if (indexAll > -1) {
          this.selectedKanban.splice(indexAll, 1);
        }
      }
    }
    if (this.selectedKanban.length > 0) {
      this.disablePartNumber = true;
    } else {
      this.disablePartNumber = false;
    }
  }
  onPartNoChange(): void {
    if (this.selectedPartNo.length >= 1) {
      if (this.selectedPartNo[this.selectedPartNo.length - 1].item_id === 'ALL') {
        this.selectedPartNo = [];
        this.selectedPartNo.push(this.partNoList[0]);
      } else {
        let indexAll = this.selectedPartNo.findIndex((data) => data.item_id === 'ALL');

        if (indexAll > -1) {
          this.selectedPartNo.splice(indexAll, 1);
        }
      }
    }
    if (this.selectedPartNo.length > 0) {
      this.disableKanban = true;
    } else {
      this.disableKanban = false;
    }
    this.selectedPartNo.forEach((element) => {
      this.kanbanListSelected.push(element.kanban);
    });
  }

  onDestCodeChange(): void {
    if (this.selectedDestCode.length >= 1) {
      if (this.selectedDestCode[this.selectedDestCode.length - 1].dest_code === 'ALL') {
        this.selectedDestCode = [];
        this.selectedDestCode.push(this.destCodeList[0]);
      } else {
        let indexAll = this.selectedDestCode.findIndex((data) => data.dest_code === 'ALL');

        if (indexAll > -1) {
          this.selectedDestCode.splice(indexAll, 1);
        }
      }
    }
  }

  onDockChange(): void {
    if (this.selectedDock.length >= 1) {
      if (this.selectedDock[this.selectedDock.length - 1].dock === 'ALL') {
        this.selectedDock = [];
        this.selectedDock.push(this.dockList[0]);
      } else {
        let indexAll = this.selectedDock.findIndex((data) => data.dock === 'ALL');

        if (indexAll > -1) {
          this.selectedDock.splice(indexAll, 1);
        }
      }
    }
  }
  onLifeCycleChange(): void {
    if (this.selectedLifecycle.length >= 1) {
      if (this.selectedLifecycle[this.selectedLifecycle.length - 1].life_cycle === 'ALL') {
        this.selectedLifecycle = [];
        this.selectedLifecycle.push(this.lifecycleList[0]);
      } else {
        let indexAll = this.selectedLifecycle.findIndex((data) => data.life_cycle === 'ALL');

        if (indexAll > -1) {
          this.selectedLifecycle.splice(indexAll, 1);
        }
      }
    }
  }
  onContainerChange(): void {
    if (this.selectedContainer.length >= 1) {
      if (this.selectedContainer[this.selectedContainer.length - 1].cont_code === 'ALL') {
        this.selectedContainer = [];
        this.selectedContainer.push(this.containerList[0]);
      } else {
        let indexAll = this.selectedContainer.findIndex((data) => data.cont_code === 'ALL');

        if (indexAll > -1) {
          this.selectedContainer.splice(indexAll, 1);
        }
      }
    }
  }

  onSupplierChange(): void {
    if (this.selectedSupplier.length >= 1) {
      if (this.selectedSupplier[this.selectedSupplier.length - 1].customer_supp === 'ALL') {
        this.selectedSupplier = [];
        this.selectedSupplier.push(this.supplierList[0]);
      } else {
        let indexAll = this.selectedSupplier.findIndex((data) => data.customer_supp === 'ALL');

        if (indexAll > -1) {
          this.selectedSupplier.splice(indexAll, 1);
        }
      }
    }
  }
  onSpecialistChange(): void {
    if (this.selectedSpecialist.length >= 1) {
      if (this.selectedSpecialist[this.selectedSpecialist.length - 1].EMPLOYEE === 'ALL') {
        this.selectedSpecialist = [];
        this.selectedSpecialist.push(this.specialistList[0]);
      } else {
        let indexAll = this.selectedSpecialist.findIndex((data) => data.EMPLOYEE === 'ALL');

        if (indexAll > -1) {
          this.selectedSpecialist.splice(indexAll, 1);
        }
      }
    }
  }

  cancelEdit(): void {
    this.openConfirmationDialog();
  }

  openConfirmationDialog() {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = false;
    dialogConfig.id = 'modal-component';
    dialogConfig.height = '204px';
    dialogConfig.width = '470px';

    const dialogRef = this.dialog.open(ConfirmCancelDailogComponent, dialogConfig);

    dialogRef.afterClosed().subscribe((res) => {
      if (res === 'cancel') {
        this.isEditMode = !this.isEditMode;
        this.editEnabled = this.isEditMode;
        this.search(0, true);
        this.osPartsGrid.gridOptions.api.refreshHeader();
      }
    });
  }

  reviewData(): void {
    this.removeMessage();

    this.getBatchStatus(this.reviewRecords);
  }

  reviewRecords = () => {
    this.validationError = false;
    this.openDialog();
  };

  getModifiedRowData(): any {
    const modifiedRowData = this.rowData.filter((row) => row.rowCheckBox && row.man !== row.old_man);
    return modifiedRowData;
  }

  getSelectedRowData(): any {
    const selectedRowData = this.rowData.filter((row) => row.rowCheckBox);
    return selectedRowData;
  }

  checkIfAnySelected() {
    if (this.kanbanSearchValue) return true;

    if (
      this.vanningFrom // Currently removing it to search for Kanban irrespective of range
      // this.selectedKanban.length > 0 ||
      // this.selectedPartNo.length > 0 ||
      // this.selectedDestCode.length > 0 ||
      // this.selectedContainer.length > 0 ||
      // this.selectedSupplier.length > 0 ||
      // this.selectedDock.length > 0 ||
      // this.selectedSpecialist.length > 0 ||
      // this.selectedLifecycle.length > 0 ||
      // (this.vanningFrom &&
      // this.vanningTo)
    ) {
      return true;
    }

    return false;
  }

  clearKanbanValue() {
    this.kanbanSearchValue = null;
  }

  onEnterPressed(event) {
     if(this.isSearchDisabled) {
       return;
     }
     this.isSearchDisabled = true;
     this.handleKanbanSearch(event);
     setTimeout(()=>{
     this.isSearchDisabled = false;
     }, 1500);
   }
  handleKanbanSearch(event) {
    this.kanbanSearchValue = this.kanbanSearchValue.trim();
    const value = this.kanbanSearchValue;

    this.rowData = [];

    const searchedKanban = this.kanbanList.find((kanban) => kanban.kanban === value);

    if (!searchedKanban || searchedKanban.kanban === 'ALL') {
      this.onResetDropDown();
      this.warningMessage = warningMessage.invalidKanbanSearch;
      return;
    }

    this.selectedKanban = [];
    this.selectedPartNo = [];
    this.selectedDestCode = [];
    this.selectedContainer = [];
    this.selectedSupplier = [];
    this.selectedDock = [];
    this.selectedSpecialist = [];
    this.selectedLifecycle = [];

    this.selectedKanban = [searchedKanban];
    this.vanningFrom = undefined;
    this.vanningTo = undefined;

    this.disablePartNumber = true;

    this.search(0, true);
  }

  search(offset, removeMessage, distinctItemIDs = []) {
    this.headerCheckboxChecked = false;
    this.osPartsGrid.gridOptions.api.refreshHeader();

    if (removeMessage) {
      this.removeMessage();
    }
    if (offset === 0) {
      if (distinctItemIDs.length === 0) {
        this.rowData = [];
        this.setPageSizeToAll(this.defaultPageSize);
      }

      const { valid, error } = validateVanningDates({
        vanningFrom: this.vanningFrom,
        vanningTo: this.vanningTo,
      });

      if (!valid) {
        this.warningMessage = error;
        window.scroll(0, 0);

        this.spinner.hide();

        return;
      }

      this.offset = 0;
      this.isEditMode = false;
      this.editEnabled = this.isEditMode;
      this.validationError = false;
      this.resetGrid();
    }

    if (!this.checkIfAnySelected()) {
      this.rowData = [];
      this.warningMessage = warningMessage.selectVanFrom;

      window.scroll(0, 0);

      this.hideSpinner();
      return false;
    }

    if (this.offset === 0) {
      this.showSpinner(this.dataLoadingMessage);
    }

    this.osPartsGrid.gridOptions.api.showLoadingOverlay();

    this.loading = true;

    const apiDefaultLimit = 9000;

    const mappedItemIDs = this.selectedPartNo.map((e) => e.item_id);
    const mappedKanbans = this.selectedKanban.map((e) => e.kanban);

    const selectedItemIDs =
      distinctItemIDs.length > 0
        ? distinctItemIDs
        : mappedItemIDs.length === this.partNoList.length
        ? []
        : mappedItemIDs;

    const selectedKanbans = mappedKanbans.length === this.kanbanList.length ? [] : mappedKanbans;

    var data = {
      offset: offset,
      limit: apiDefaultLimit,
      business_entity: this.namcValue,
      workid: this.user,
      user_role: this.userRole,
      kanban: selectedKanbans,
      item_id: selectedItemIDs,
      dest_code: this.selectedDestCode.map((e) => e.dest_code),
      cont_code: this.selectedContainer.map((e) => e.cont_code),
      customer_supp: this.selectedSupplier.map((e) => e.customer_supp),
      specialist: this.selectedSpecialist.map((e) => e.EMPLOYEE),
      dock: this.selectedDock.map((e) => e.dock),
      life_cycle: this.selectedLifecycle.map((e) => e.life_cycle),
      vanning_to: this.vanningTo,
      vanning_from: this.vanningFrom,
    };
    //if all selected
    if (data.dest_code.includes('ALL')) {
      data.dest_code = [];
    }
    if (data.cont_code.includes('ALL')) {
      data.cont_code = [];
    }
    if (data.customer_supp.includes('ALL')) {
      data.customer_supp = [];
    }
    if (data.specialist.includes('ALL')) {
      data.specialist = [];
    }
    if (data.dock.includes('ALL')) {
      data.dock = [];
    }
    if (data.life_cycle.includes('ALL')) {
      data.life_cycle = [];
    }
    this.service.getOrderForecast(data).subscribe({
      next: (success) => {
        try {
          if (success.body === this.msgNoContent) {
            const err = new Error('No Data Available.');
            this.hideSpinner();
            throw err;
          } else {
            if (this.checkIfAnySelected()) {
              if (offset === 0 && distinctItemIDs.length > 0) {
                // Remove edited parts data from rowData,
                // so that it is not duplicated when we search again for the edited parts
                this.rowData = this.rowData.filter((row) => !distinctItemIDs.includes(row.item_id));
              }

              const data = success.body.data;

              if (!offset && data.length < 1) {
                this.spinner.hide();
                this.warningMessage = warningMessage.noDataAvailble;

                return;
              }

              this.rowData.push(...data);
              //this.osPartsGrid.gridOptions.api.refreshHeader();

              if (data.length === apiDefaultLimit) {
                this.offset++;

                this.search(this.offset * apiDefaultLimit, false);
              } else {
                this.rowData.sort(this.comparator);

                this.osPartsGrid.gridOptions.api.setRowData(this.rowData);
                this.setPageSizeToAll();
                this.getBuildOutData();
                this.loading = false;
              }
            }
          }

          if (success.statusCode === 204 || success.body === this.msgNoContent || success.statusCode === 502) {
            this.hideSpinner();
          }
        } catch (err) {
          console.error(err);
          this.hideSpinner();
        }
      },
      error: this.errorCallback,
    });
  }

  getBuildOutData() {
    let buildOutQuantityData;

    this.service
      .getCCSData({
        workid: this.user,
        user_role: this.userRole,
        business_entity: this.namcValue,
        namcName: this.namcName,
      })
      .subscribe({
        next: (res) => {
          buildOutQuantityData = res.body !== 'No Content' ? res.body : [];
          // && boRow.containerCode === row.cont_code
          this.rowData.forEach((row) => {
            return (row.boQty = buildOutQuantityData.filter((boRow) => {
              return boRow.partNumber === row.item_id && boRow.containerCode === row.cont_code;
            })[0]?.countdownQty);
          });

          this.setPageSizeToAll();

          this.osPartsGrid.gridOptions.api.setRowData(this.rowData);

          const rowIndex = this.rowData.findIndex((row) => {
            const rowVanDate = `${row.vanning_date.split('/')[2]}-${row.vanning_date.split('/')[0]}-${
              row.vanning_date.split('/')[1]
            }`;

            return rowVanDate === this.onInitVanningFrom;
          });

          // When we use ensureIndexVisible, it will display that index as last row.
          // So to show it at the top, adding 9 rows index offset here.
          // If The row we are trying to focus on, is in the last 10 rows of rowData then we dont need to add any offset.
          const rowOffset = this.rowData.length > rowIndex + 9 ? 9 : 0;
          if (rowIndex) this.osPartsGrid.gridOptions.api.ensureIndexVisible(rowIndex + rowOffset);

          this.hideSpinner();
        },
        error: (error) => {
          console.error(error);

          const { ccsIsEnabled } = error?.error?.error;

          this.hideSpinner();
          this.removeMessage();

          this.warningMessage = ccsIsEnabled
            ? warningMessage.ccsAPIFailed
            : warningMessage.ccsDataNotAvailable(this.namcName);
        },
      });

    this.osPartsGrid.gridOptions.api.setRowData(this.rowData);
  }

  async bulkAdjustmentValidation(bulkAdjustmentData, distinctItemIDs) {
    this.uploading = true;

    const timestamp = new Date()
      .toISOString()
      .split('.')[0]
      .replace('T', '_')
      .split('')
      .filter((d) => d !== '-' && d !== ':')
      .join('');

    const fileName = `orderforecast/${this.namcName}/upload/OrderForecast_${this.user}_${timestamp}.xlsx`;
    this.fileName = fileName;

    this.fileBuffer = await this.excelService.getFileBuffer(bulkAdjustmentData, fileName, this.bulkAdjustmentHeaders);

    await this.uploadExcelDataToS3({ fileName });

    const data = {
      business_entity: this.namcValue,
      workid: this.user,
      user_role: this.userRole,
      namcName: this.namcName,
      key: fileName,
    };

    this.bulkadjustmentService.getValidationData(data).subscribe({
      next: async (res) => {
        console.log(res);

        const { status, JSONData, minn_vann_date } = res.body;

        if (!status) {
          this.validationError = true;

          const errorData = JSONData.map((element) => {
            return {
              item_id: element.item_id,
              order_type: element.order_type,
              cont_code: element.cont_code,
              adj_qty: element.adj_qty,
              vanning_date: element.vanning_date,
              adj_reason: element.adj_reason,
              remarks: element.remarks,
            };
          });

          this.errorData = errorData;

          // console.log(errorData);

          const dialogConfig = new MatDialogConfig();
          dialogConfig.disableClose = true;
          dialogConfig.id = 'modal-component';
          dialogConfig.height = '350px';
          dialogConfig.width = '826px';
          var data = {
            modifiedRowData: errorData,
          };

          dialogConfig.data = data;
          const dialogRef = this.dialog.open(OfErrorDialogComponent, dialogConfig);

          dialogRef.componentInstance.editEventEmitter.subscribe(async (res) => {
            this.showSpinner();

            if (res === 'cancel') {
              // this.osPartsGrid.gridOptions.api.refreshHeader();
            } else {
              const distinctItemIDs = [];
              const bulkAdjustmentData = data.modifiedRowData.map((row) => {
                if (!distinctItemIDs.includes(row.item_id)) {
                  distinctItemIDs.push(row.item_id);
                }

                return {
                  item_id: row.item_id,
                  order_type: row.order_type,
                  cont_code: row.cont_code,
                  adj_qty: row.man,
                  vanning_date: row.vanning_date,
                  adj_code: res.adjCode,
                  adj_reason: res.adjReason,
                };
              });

              await this.bulkAdjustmentValidation(bulkAdjustmentData, distinctItemIDs);

              return;
            }

            this.isEditMode = !this.isEditMode;
            this.editEnabled = this.isEditMode;
            this.osPartsGrid.gridOptions.api.forEachNode(function (rowNode, index) {
              if (rowNode.data.rowCheckBox) {
                rowNode.setSelected(false);
                rowNode.data.checkedEditMode = false;
                rowNode.data.rowCheckBox = false;
              }
              rowNode.setData(rowNode.data);
            });
          });

          this.hideSpinner();

          return;
        }

        // No errors - Proceeding with calculation
        this.fileName = fileName.replace('upload', 'inbound');
        this.fileBuffer = await this.excelService.getFileBuffer(
          bulkAdjustmentData,
          this.fileName,
          this.bulkAdjustmentHeaders
        );

        await this.uploadExcelDataToS3({ fileName: this.fileName });

        const calculationData = {
          business_entity: this.namcValue,
          workid: this.user,
          user_role: this.userRole,
          namcName: this.namcName,
          key: fileName,
          userid: localStorage.getItem('UserName'),
          minn_vann_date: minn_vann_date,
          distinctItemIDs,
          fileKey: this.fileName,
        };

        this.service.orderForecastCalculation(calculationData).subscribe({
          next: (res) => {
            this.hideSpinner();

            this.validationError = false;
            this.isEditMode = !this.isEditMode;
            this.editEnabled = this.isEditMode;
            this.osPartsGrid.gridOptions.api.forEachNode(function (rowNode) {
              if (rowNode.data.rowCheckBox) {
                rowNode.setSelected(false);
                rowNode.data.checkedEditMode = false;
                rowNode.data.rowCheckBox = false;
              }
              rowNode.setData(rowNode.data);
            });

            // Set Vanning To Date 120 days ahead
            // const vanFrom = new Date(this.vanningFrom);
            // const toDate = vanFrom.setDate(vanFrom.getDay() + 120);

            // this.vanningTo = moment(toDate).format('YYYY-MM-DD');

            // this.vanningTo = undefined;

            // this.search(0, false, distinctItemIDs);

            // This is added on search method at the start, but now that we are not calling that,
            // adding it here
            this.headerCheckboxChecked = false;
            this.osPartsGrid.gridOptions.api.refreshHeader();

            // Showing only the records user has edited after edit is complete with updated data
            this.rowData = res.body.data;
            this.osPartsGrid.gridOptions.api.setRowData(this.rowData);
            this.setPageSizeToAll();

            this.removeMessage();
            this.successMessage = successMessage.recordUpdated;
          },
          error: this.errorCallback,
        });
      },
      error: this.errorCallback,
    });
  }

  async downloadErrorReport() {
    this.removeMessage();
    const timestamp = new Date()
      .toISOString()
      .split('.')[0]
      .replace('T', '_')
      .split('')
      .filter((d) => d !== '-' && d !== ':')
      .join('');

    const headers = ['Part No', this.orderType, 'CC', 'Adj Qty', 'Van DT', this.adjReason, 'Remarks/Comments'];
    this.excelService.exportExcelFileStyle(this.errorData, 'OrderForecast_Error' + timestamp + '.xlsx', headers);
  }

  openDialog() {
    const modifiedRowData = this.getModifiedRowData();

    if (modifiedRowData.length < 1) {
      this.warningMessage = warningMessage.editToReview;
      return;
    }

    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.id = 'modal-component';
    dialogConfig.height = '350px';
    dialogConfig.width = '708px';
    var data = {
      modifiedRowData: this.getModifiedRowData(),
      adjCodeList: this.adjCodeList,
    };

    dialogConfig.data = data;
    const dialogRef = this.dialog.open(DialogGridComponent, dialogConfig);

    dialogRef.componentInstance.editEventEmitter.subscribe(async (res) => {
      this.showSpinner();

      if (res === 'cancel') {
        // this.osPartsGrid.gridOptions.api.refreshHeader();
      } else {
        const distinctItemIDs = [];
        const bulkAdjustmentData = data.modifiedRowData.map((row) => {
          if (!distinctItemIDs.includes(row.item_id)) {
            distinctItemIDs.push(row.item_id);
          }

          return {
            item_id: row.item_id,
            order_type: row.order_type,
            cont_code: row.cont_code,
            adj_qty: row.man,
            vanning_date: row.vanning_date,
            adj_code: res.adjCode,
            adj_reason: res.adjReason,
          };
        });

        await this.bulkAdjustmentValidation(bulkAdjustmentData, distinctItemIDs);

        return;
      }

      this.isEditMode = !this.isEditMode;
      this.editEnabled = this.isEditMode;
      this.osPartsGrid.gridOptions.api.forEachNode(function (rowNode, index) {
        if (rowNode.data.rowCheckBox) {
          rowNode.setSelected(false);
          rowNode.data.checkedEditMode = false;
          rowNode.data.rowCheckBox = false;
        }
        rowNode.setData(rowNode.data);
      });
    });
  }

  uploadToS3(): void {
    this.showSpinner();

    const exportData: any = [];
    this.rowData
      .filter((x) => x.rowCheckBox === true)
      .forEach((e) => {
        const obj = {
          vanDate: e.vanning_date,
          partNo: e.item_id,
          kanban: e.kanban,
          dock: e.dock,
          container: e.cont_code,
          lifecycle: e.life_cycle,
          qpc: e.order_lot,
          rqmt: e.basic_req_veh,
          nvRqmt: e.basic_req_nveh,
          co: e.carry_over_qty,
          sysadj: e.sys_adj,
          boa: e.boa,
          orderPlan: e.plan_qty,
          manualAdjQty: e.man,
          faMin: e.rev_min_qty,
          faMax: e.rev_max_qty,
          orderForecast: e.final_order_qty,
          boQty: e.boQty,
          partDescription: e.part_description,
          adjReason: e.adj_reason,
          updatedDate: e.date_time,
          user: e.userid,
        };
        exportData.push(obj);
      });
    const fileName = this.getFileName();
    const headers = [
      'VAN DT MM/DD/YYYY',
      'PART NO',
      'KANBAN',
      'DOCK',
      'CONTAINER',
      'LIFE CYCLE',
      'QPC',
      'RQMT',
      'NV RQMT',
      'C/O',
      'SYS ADJ',
      'BOA',
      'ORDER PLAN',
      'MAN ADJ',
      'FA MIN',
      'FA MAX',
      'ORDER/FORECAST',
      'BO QTY',
      'PART DESCRIPTION',
      'ADJ REASON',
      'UPDATED DT MM/DD/YYYY',
      'USER',
    ];

    setTimeout(async () => {
      this.fileBuffer = await this.excelService.getFileBuffer(exportData, fileName, headers);

      this.uploadExcelDataToS3({ fileName });
    }, 1000);
  }
  getFileName() {
    var namcName;
    if (localStorage.getItem('namcName')) {
      namcName = localStorage.getItem('namcName');
    }
    const timestamp = new Date()
      .toISOString()
      .split('.')[0]
      .replace('T', '_')
      .split('')
      .filter((d) => d !== '-' && d !== ':')
      .join('');
    return `orderforecast/${namcName}/inbound/Orderforecast_${this.user}_${timestamp}.xlsx`;
  }
  async uploadExcelDataToS3({ fileName }) {
    return new Promise((resolve) => {
      const data = {
        workid: this.user,
        user_role: this.userRole,
        fileName: fileName,
      };

      this.bulkadjustmentService.getExcelFileUrl(data).subscribe({
        next: (response) => {
          if (response.body.signedUrl) {
            const a = document.createElement('a');
            a.href = response.body.signedUrl;

            this.bulkadjustmentService.putDatainExcel(this.fileBuffer, a.href).subscribe({
              next: (res) => {
                console.log('successfully uploated to s3');
                resolve({});
              },
            });
          }
        },
      });
    });
  }
  // callCalculation(fileName) {
  //   const dataTosend = {
  //     workid: this.user,
  //     user_role: this.userRole,
  //     key: fileName,
  //     business_entity: this.namcValue,
  //     minn_vann_date: this.vanningFrom,
  //     userid: localStorage.getItem('UserName'),
  //     batch_name: `osp-bulk-adjustment-batch-dev`,
  //   };

  //   this.bulkadjustmentService.calculation(dataTosend).subscribe(
  //     (success) => {
  //       setTimeout(() => {
  //         window.scroll(0, 0);
  //       }, 100);
  //       if (success.body.status) {
  //         // this.uploadExcelDatatoS3('inbound');
  //         // this.cancelSave();
  //         this.successMessage = `Bulk adjustment batch started successfully for your request`;
  //         setTimeout(() => {
  //           this.successMessage = '';
  //         }, 6500);
  //       }
  //     },
  //     (err) => {
  //       this.warningMessage = `Some problem occurred. Please contact support team.`;
  //       setTimeout(() => {
  //         this.warningMessage = '';
  //       }, 6500);
  //     }
  //   );
  // }

  //freezing logic

  batchCheck = true;
  freezingMesssage = '';

  getBatchStatus(callback) {
    this.spinner.show();
    var data = {
      business_entity: this.namcValue,
      workid: this.user,
      user_role: this.userRole,
    };

    this.lookupService.getBatchStatus(data).subscribe({
      error: this.errorCallback,
      next: (res) => {
        let runningBatches = res.body.data.runningBatches ?? '';
        const batchesToIgnore = res.body.data.batchesToIgnore.map((batch) => batch.batch_name);

        runningBatches = runningBatches
          .split(',')
          .filter((batchName) => !batchesToIgnore.includes(batchName))
          .join(',');

        const count = runningBatches.length;

        if (count > 0) {
          this.freezingMesssage = `${runningBatches}  ${freezingMesssage}`;
          this.spinner.hide();
          this.batchCheck = true;
        } else {
          this.freezingMesssage = '';
          this.batchCheck = false;
          callback ? callback() : (this.batchCheck = false);
        }
        this.spinner.hide();
      },
    });
  }

  errorCallback = (error) => {
    console.error(error);
    this.hideSpinner();

    this.removeMessage();
    this.warningMessage = `${warningMessage.apiLogicFail}  ${this.itContact}.`;
  };

  onEditMode(): void {
    this.removeMessage();
    let data = [];
    data = this.getSelectedRowData();

    if (this.loading) {
      this.warningMessage = warningMessage.waitToLoadData;

      window.scroll(0, 0);

      return;
    }
    // console.log(data, 'editcheck');
    if (data.length > 0) {
      this.isEditMode = !this.isEditMode;
      this.editEnabled = this.isEditMode;

      if (this.isEditMode) {
        this.osPartsGrid.gridOptions.api.forEachNode(function (rowNode, index) {
          rowNode.data.checkedEditMode = true;
          if (rowNode.data.rowCheckBox) {
          } else {
            rowNode.data.checkedEditMode = false;
          }
          rowNode.setData(rowNode.data);
        });
      } else {
        this.osPartsGrid.gridOptions.api.forEachNode(function (rowNode, index) {
          if (rowNode.data.rowCheckBox) {
            rowNode.data.checkedEditMode = false;
          }
          rowNode.setData(rowNode.data);
        });
      }
      setTimeout(() => {
        window.scroll(0, document.body.scrollHeight);
      }, 100);
    } else {
      this.warningMessage = warningMessage.selectRowsToEdit;
      this.isEditMode = false;
      this.editEnabled = false;
      window.scroll(0, 0);
    }
  }

  userCheckChanged(checked, rowIndex) {
    if (
      this.warningMessage === 'Please select at least one row to edit.' ||
      this.warningMessage === 'No rows are selected.'
    ) {
      this.warningMessage = '';
    }
    this.showSpinner();

    const rowNode = this.osPartsGrid.gridOptions.api.getDisplayedRowAtIndex(rowIndex);

    if (checked === true) {
      rowNode.setSelected(true);
      if (this.isEditMode) {
        rowNode.data.checkedEditMode = true;
      }
      rowNode.data.rowCheckBox = true;
    } else {
      rowNode.setSelected(false);
      rowNode.data.checkedEditMode = false;
      rowNode.data.rowCheckBox = false;
    }

    this.gridOptions.getRowStyle = function (params) {
      if (params.node.rowIndex === rowIndex) {
        const color = checked ? '#E4ECF0' : 'white';
        return { background: color };
      }
      return { background: 'white' };
    };

    rowNode.setData(rowNode.data);
    this.toggleHeaderCheckbox(checked);
    this.hideSpinner();
  }

  headerCheckChanged(event) {
    if (
      this.warningMessage === 'Please select at least one row to edit.' ||
      this.warningMessage === 'No rows are selected.'
    ) {
      this.warningMessage = '';
    }
    this.headerCheckboxChecked = event.checked;
    const headerChecked = event.checked;
    const isEditable = this.isEditMode;
    this.osPartsGrid.gridOptions.api.forEachNode(function (rowNode, index) {
      if (headerChecked) {
        if (isEditable) {
          rowNode.data.checkedEditMode = true;
        }
        rowNode.data.rowCheckBox = true;
      } else {
        rowNode.data.rowCheckBox = false;
        rowNode.data.checkedEditMode = false;
      }

      rowNode.setData(rowNode.data);
    });
  }

  toggleHeaderCheckbox(checked) {
    if (!checked) {
      this.headerCheckboxChecked = false;

      this.osPartsGrid.gridOptions.api.refreshHeader();

      return;
    }

    const rowCount = this.osPartsGrid.gridOptions.rowData.length;
    const selectedRowCount = this.osPartsGrid.gridOptions.rowData.filter((x) => x.rowCheckBox === true).length;

    if (rowCount === selectedRowCount) {
      this.headerCheckboxChecked = true;

      this.osPartsGrid.gridOptions.api.refreshHeader();
    }
  }

  exportAsXLSX(): void {
    this.showSpinner();
    let easternCurrentDate = String(mm().tz('US/Michigan').format('YYYY-MM-DD_HH:mm:ss'));
    console.log(easternCurrentDate);

    let data = [];
    if (this.headerCheckboxChecked) {
      console.log('Hello');
      this.osPartsGrid.gridOptions.api.forEachNodeAfterFilter((node) => {
        data.push(node.data);
      });
    } else {
      data = this.getSelectedRowData();
    }

    setTimeout(async () => {
      if (data.length > 0) {
        const exportData: any = [];

        data.forEach((e) => {
          e.carry_over_qty = e.carry_over_qty === null ? null : parseInt(e.carry_over_qty);

          const obj = {
            vanDate: e.vanning_date,
            prodDate: e.prod_date,
            partNo: e.item_id,
            partDescription: e.part_description,
            kanban: e.kanban,
            container: e.cont_code,
            qpc: e.order_lot,
            dock: e.dock,
            specialist: e.specialist,
            lifecycle: e.life_cycle,
            orderType: e.order_type,
            rqmt: e.basic_req_veh,
            nvRqmt: e.basic_req_nveh,
            co: e.carry_over_qty,
            sysadj: e.sys_adj,
            boa: e.boa,
            orderPlan: e.plan_qty,
            manualAdjQty: e.man,
            faMin: e.rev_min_qty,
            faMax: e.rev_max_qty,
            orderForecast: e.final_order_qty,
            boQty: e.boQty,
            kvcShip: e.act_van_qty,
            adjReason: e.adj_reason,
            updatedDate: e.date_time,
            user: e.userid,
          };
          exportData.push(obj);
        });

        const headers = [
          'VAN DT',
          'PROD DT',
          'PART NO',
          'PART DESCRIPTION',
          'KANBAN',
          'CC',
          'QPC',
          'DOCK',
          'SPC',
          'LIFE CYCLE',
          'ORDER TYPE',
          'RQMT',
          'NV RQMT',
          'C/O',
          'SYS ADJ',
          'BOA',
          'ORDER PLAN',
          'MAN ADJ',
          'FA MIN',
          'FA MAX',
          'ORDER/FORECAST',
          'BO QTY',
          'KVC SHIP',
          'ADJ REASON',
          'UPDATED DT',
          'USER',
        ];

        const timestamp = easternCurrentDate

          .split('.')[0]
          .replace('T', '_')
          .split('')
          .filter((d) => d !== '-' && d !== ':')
          .join('');

        if (exportData.length > 0) {
          this.excelService.exportAsExcelFile(exportData, 'OrderForecast_' + timestamp, headers);
        }
      } else {
        this.warningMessage = warningMessage.noRowsSelected;
      }

      this.hideSpinner();
    }, 1000);
  }

  removeMessage() {
    this.warningMessage = '';
    this.successMessage = '';
    // this.fileName='';
    // this.uploadError = false;
  }

  exportAsXLSXbulkupload(): void {
    this.showSpinner();
    let easternCurrentDate = String(mm().tz('US/Michigan').format('YYYY-MM-DD_HH:mm:ss'));
    console.log(easternCurrentDate);

    let data = [];
    if (this.headerCheckboxChecked) {
      console.log('Hello');
      this.osPartsGrid.gridOptions.api.forEachNodeAfterFilter((node) => {
        data.push(node.data);
      });
    } else {
      data = this.getSelectedRowData();
    }

    let isAllFuture = data.filter(
    //   (element) =>
    //     new Date(element.vanning_date) > new Date(this.onInitVanningFrom) ||
    //     (new Date(element.vanning_date) === new Date(this.onInitVanningFrom) && element.order_status !== 'F')
    // );
    (element) =>
    new Date(element.vanning_date).getTime() > new Date(this.onInitVanningFrom).getTime() ||
    (new Date(element.vanning_date).getTime() === new Date(this.onInitVanningFrom).getTime() && element.order_status !== 'F')
    )
    if (isAllFuture.length !== data.length) {
      this.warningMessage = warningMessage.bulkDownloadError;
      this.hideSpinner();
      return;
    }
    this.warningMessage = '';
    setTimeout(async () => {
      if (data.length > 0) {
        const exportData: any = [];

        data.forEach((e) => {
          e.carry_over_qty = e.carry_over_qty === null ? null : parseInt(e.carry_over_qty);

          const obj = {
            partNo: e.item_id,
            orderType: e.order_type,
            container: e.cont_code,
            manualAdjQty: e.man,
            vanDate: e.vanning_date,
            adjReason: e.adj_reason,
          };
          exportData.push(obj);
        });

        const headers = ['PART NO', 'ORDER TYPE', 'CC', 'ADJ QTY', 'VAN DT', 'ADJ REASON'];

        const timestamp = easternCurrentDate

          .split('.')[0]
          .replace('T', '_')
          .split('')
          .filter((d) => d !== '-' && d !== ':')
          .join('');

        if (exportData.length > 0) {
          this.excelService.exportAsExcelFile(exportData, 'OrderForecast_for_Bulk_Upload_' + timestamp, headers);
        }
      } else {
        this.warningMessage = warningMessage.noRowsSelected;
      }

      this.hideSpinner();
    }, 1000);
  }
}
