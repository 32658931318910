<div class="background">
  <div class="container-fluid">
    <div class="d-flex flex-row">
      <div class="p-2 flex-grow-1">
        <h2 class="heading">Stocking Pace Maintenance</h2>
      </div>
    </div>
  </div>
  <!-- <div class="container-fluid">
    <div class="d-flex flex-row">
      <div class="p-2 flex-grow-1">
        <label class="info-label"> To Change the devan pace, </label>
        <a class="info-label"> Module Stocking Maintenance</a>
      </div>
    </div>
  </div> -->
  <div class="successMessage alert-dismissible">
    <div *ngIf="successMessage" class="alert alert-success" role="alert">
      {{ successMessage }}
      <button type="button" class="btn-close" (click)="removeMessage()" data-bs-dismiss="alert"
        aria-label="Close"></button>
    </div>
    <div *ngIf="warningMessage" class="alert alert-warning" role="alert">
      {{ warningMessage }}
      <button type="button" class="btn-close" (click)="removeMessage()" data-bs-dismiss="alert"
        aria-label="Close"></button>
    </div>
  </div>
  <div class="container-fluid">
    <div class="row">
      <div class="card">
        <div class="container-fluid">
          <div class="row">
            <!-- <div class="col-xl-1 col-md-2 col-sm-3">
              <div class="drpchildctrl">
                <span class="v1"></span>
                <label class="searchLable">MODULE TYPE</label>
                <p-multiSelect
                  [showToggleAll]="false"
                  [(ngModel)]="selectedDock"
                  (onChange)="dockChange()"
                  [options]="dockList"
                  [showTransitionOptions]="'0ms'"
                  [hideTransitionOptions]="'0ms'"
                  [defaultLabel]="'--Select--'"
                  [virtualScroll]="true"
                  [itemSize]="25"
                  autoWidth="false"
                  [style]="{ width: '120%' }"
                  [resetFilterOnHide]="true"
                  optionLabel="dock"
                  [panelStyle]="{ width: '140%' }"
                >
                </p-multiSelect>
              </div>
            </div> -->
            <div class="col-xl-1 col-md-2 col-sm-6">
              <div class="drpchildctrl">
                <span class="v1"></span>
                <label class="searchLable">MODULE TYPE</label>
                <p-multiSelect [showToggleAll]="false" [options]="moduleTypeList" [(ngModel)]="selectedModuleType"
                  [showTransitionOptions]="'0ms'" [hideTransitionOptions]="'0ms'" [defaultLabel]="'--Select--'"
                  [virtualScroll]="true" [itemSize]="30" autoWidth="true" [style]="{ width: '100%' }"
                  [resetFilterOnHide]="true" optionLabel="module_type" [panelStyle]="{ width: '100%' }"
                  (onChange)="onModuleTypeChange()">
                </p-multiSelect>
              </div>
            </div>

            <div class="col-xl-1 col-md-2 col-sm-3">
              <div class="drpchildctrl">
                <span class="v1"></span>
                <label class="searchLable">VAN FROM</label>
                <input [(ngModel)]="vanningFrom" [max]="vanningTo" class="form-control-sm" type="date" autoWidth="false"
                  [max]="vanningTo" />
              </div>
            </div>
            <div class="col-xl-1 col-md-2 col-sm-3">
              <div class="drpchildctrl">
                <span class="v1"></span>
                <label class="searchLable">VAN TO</label>
                <input [(ngModel)]="vanningTo" [min]="vanningFrom" class="form-control-sm" type="date"
                  autoWidth="false" />
              </div>
            </div>
            <div class="col-xl-1 col-md-2 col-sm-3">
              <div class="drpchildctrl">
                <!-- <span class="v1"></span> -->
                <label class="searchLable">Scheduled Stocking DT</label>
                <input [(ngModel)]="planStockingDT" class="form-control-sm" type="date" autoWidth="false" />
              </div>
            </div>


            <!-- <div class="col-xl-2 col-md-2 col-sm-6">
              <div class="drpchildctrl">
                <span class="v1"></span>
                <label class="searchLable">VAN DT RANGE</label>
                <p-calendar 
                  [(ngModel)]="selectedDateRange" 
                  selectionMode="range" 
                  [readOnlyInput]="false" 
                  dateFormat="mm/dd/yy"
                  [showIcon]="true" 
                  [showButtonBar]="true" 
                  [monthNavigator]="true" 
                  [yearNavigator]="true" 
                  [yearRange]="'2024:2025'"
                  [showTime]="false" 
                  [style]="{ width: '120%' }" 
                  [itemSize]="12"
                  [resetFilterOnHide]="true"
                  [showTransitionOptions]="'0ms'"
                  [hideTransitionOptions]="'0ms'"
                  autoWidth="false"
                  [panelStyle]="{ width: '140%' }"
                  [icon]="calendarIconClass"
                  (onSelect)="onDateRangeChange()">
                </p-calendar>
               
              </div>
            </div> -->
            <div class="col-xl-3 col-md-2 col-sm-6 reset_btn">
              <div class="drpchildctrl">
                <label class="resetIcon" (click)="onResetDropDown()">
                  <img src="./assets/images/Group 115380.png" alt="refresh icon" />
                  Reset
                </label>
                <span class="vertical"></span>
                <button class="search_btn" (click)="onSearch(0)">SEARCH</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="container-fluid">
    <div class="row">
      <div class="col-4">

        <img *ngIf="access2" src="./assets/images/edit.svg" (click)="onEditMode()" [ngClass]="{ disabled: batchCheck}"
          alt="edit button icon" title="Edit" class="img-tool">
        <!-- <img src="./assets/images/edit.svg" (click)="onEditMode()"  alt="edit button icon" title="Edit" class="img-tool"> -->
        <img class="img-tool" src="./assets/images/download.svg" alt="download icon" title="Download"
          (click)="exportAsXLSX()" />
      </div>
      <div class="col-4"></div>
      <div class="col-sm-4">
        <div class="records">
          <label class="page-drpdwn-label">RECORDS / PAGE:</label>
          <select class="page-drpdwn" (change)="onPageSizeChanged()" [(ngModel)]="paginationPageSize">
            <option value="10" selected>10</option>
            <option value="30">30</option>
            <option value="50">50</option>
            <option value="{{ rowData.length }}">All</option>
          </select>
        </div>
      </div>
    </div>
  </div>

  <div id="dvgrid" class="gridStyle">
    <app-grid class="table-outer" #osPartsGrid [columnDefs]="columnDefs" [rowData]="rowData"
      [rowSelection]="rowSelection" [gridOptions]="gridOptions" [pagination]="pagination"
      [paginationPageSize]="paginationPageSize" [context]="context" [suppressClickEdit]="suppressClickEdit"
      [frameworkComponents]="frameworkComponents" [tooltipShowDelay]="tooltipShowDelay" [tooltipMouseTrack]="true"
      [editType]="editType" [detailRowAutoHeight]="true">
    </app-grid>
  </div>
  <div *ngIf="isEditMode" class="saveCancel">
    <button matRipple [matRippleCentered]="true" [matRippleUnbounded]="false" class="review_btn" (click)="edit()">
      Save Changes
    </button>
    <button (click)="cancelEdit()" class="cancel_btn">Cancel</button>
  </div>
</div>

<ngx-spinner bdColor="rgba(51,51,51,0.8)" size="medium" color="#fff" type="ball-scale-multiple">
  <p style="font-size: 20px; color: white">Loading... Please wait...</p>
</ngx-spinner>