import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { CustomTooltipComponent } from '../core/custom-tooltip/custom-tooltip.component';
import { HeaderCheckboxComponent } from '../core/grid/header-checkbox/header-checkbox.component';
import { GridRefreshComponent } from '../core/grid/grid-refresh/grid-refresh.component';
import { RowCheckboxComponent } from '../core/grid/row-checkbox/row-checkbox.component';
import {
  checkAccessPipe,
  dateFilterComparator,
  getUnique,
  getUniqueByKey,
  resetSortingAndFilters,
  validateVanningDates,
  warningMessage,
} from '../constants';
import { GriddataService } from '../services/griddata.service';
import { Subscription, forkJoin } from 'rxjs';
import { OSPStateService } from '../services/osp-state.service';
import { LookupService } from '../services/lookup/lookup.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { logErrors } from '../shared/logger';
import * as mm from 'moment-timezone';
import { ExcelService } from '../services/excel.service';
import { ModuleStockingGridCheckboxComponent } from './module-stocking-grid-checkbox.component';
import { ModuleStockingService } from '../services/module-stocking/module-stocking.service';
import { successMessage } from '../constants';
import { AirfreightApicallsService } from '../services/air-freight-validation/airfreight-apicalls.service';

@Component({
  selector: 'app-module-stocking-devanning',
  templateUrl: './module-stocking-devanning.component.html',
  styleUrls: ['./module-stocking-devanning.component.scss'],
})
export class ModuleStockingDevanningComponent implements OnInit, OnDestroy {
  namcValue = localStorage.getItem('namcvalue');
  userName = localStorage.getItem('userName');

  @ViewChild('osPartsGrid') osPartsGrid;

  user: string;
  userRole: any;
  offset: number = 0;

  count = 0;
  dataCount: number = 0;
  rowData = [];
  columnDefs;
  columnDefsMaster;
  rowSelection;
  context: any;

  gridOptions;
  paginationPageSize;
  paginationNumberFormatter;
  pagination = 'true';

  suppressClickEdit = true;
  frameworkComponents = { customTooltip: CustomTooltipComponent };
  tooltipShowDelay = 0;
  editType;
  headerCheckboxChecked: boolean = false;
  isEditMode = false;
  successMessage = '';

  moduleStockingGrid: any;
  warningMessage: string;
  disableModuleType: boolean;

  //Dropdowns

  selectedDock: any = [];
  stockingDtFrom: any;
  stockingDtTo: any;
  onInstockingDateFrom: any;
  onInstockingDateTo: any;

  //Dropdowns option
  dockList: any = [];
  easternDate: any;
  defaultPageSize: any = 100000;

  updatedAllRows: boolean =false;
  isStockAll: boolean = false;
  uniqueDock: any = [];
  isAllSelect: boolean = true;
  showHideList: any = [
    {
      label: 'Dock',
      value: 'dock',
    },
    {
      label: 'Renban',
      value: 'container_renban',
    },
    {
      label: 'Module',
      value: 'module_number',
    },
    {
      label: 'Plan LO DT',
      value: 'lineoff_date',
    },

    {
      label: 'Plan Stock DT/Time',
      value: 'planned_stocking_datetime',
    },
    {
      label: 'Shift',
      value: 'planned_shift',
    },
    {
      label: 'Plan Veh',
      value: 'planned_vehicle_number',
    },
    // {
    //   label: 'Countdown to next unload',
    //   value: 'vehcountdown',
    // },
    {
      label: 'Stocked',
      value: 'stockCheck',
    },
    // {
    //   label: 'Status',
    //   value: 'module_status',
    // },
    {
      label: 'Actual Stock DT/Time',
      value: 'actual_stocking_datetime',
    },
    // {
    //   label: 'Actual Veh',
    //   value: 'actual_vehicle_number',
    // },
    // {
    //   label: '+/- Veh Stocking',
    //   value: 'vehicle_count_ahead_behind',
    // },
    // {
    //   label: '+/- Module Stocking',
    //   value: 'module_count_ahead_behind',
    // },

    {
      label: 'User',
      value: 'updated_userid',
    },
  ];

  selectedShowHideList: any = [...this.showHideList];

  moduleSubscription$: Subscription;
  access2: boolean;
  access3;
  access1: { edit: boolean; matchmismatch: boolean; modulelevaltapping: boolean };

  constructor(
    private readonly gridDataService: GriddataService,
    private readonly stateService: OSPStateService,
    private readonly lookupService: LookupService,
    private readonly spinner: NgxSpinnerService,
    private readonly excelService: ExcelService,
    private readonly OspService: ModuleStockingService,
    private readonly AirfreightApicallsService: AirfreightApicallsService
  ) {
    this.context = {
      componentParent: this,
    };
  }

  ngOnInit(): void {
    const access = checkAccessPipe();
    this.access1 = access.access;
    this.access2 = access.access.edit;
    if (!this.access2) {
      // this.osPartsGrid.gridOptions.columnApi.setColumnVisible('stockCheck', false);
    }

    this.columnDefsMaster = [
      {
        headerName: '',
        field: 'rowCheckBox',
        // suppressSorting: true,
        width: 44,
        headerComponentParams: {
          headerChecked: this.headerCheckboxChecked,
        },
        headerComponentFramework: HeaderCheckboxComponent,
        floatingFilterComponentFramework: GridRefreshComponent,
        floatingFilterComponentParams: { suppressFilterButton: true },
        cellRendererFramework: RowCheckboxComponent,
        floatingFilter: true,
      },
      {
        headerName: 'Dock',
        field: 'dock',
        width: 120,
        floatingFilter: true,
      },
      {
        headerName: 'Renban',
        field: 'container_renban',
        sortable: true,
        width: 100,
        floatingFilter: true,
      },
      {
        headerName: 'Module',
        field: 'module_number',
        sortable: true,
        width: 100,
        floatingFilter: true,
        tooltipValueGetter: (params) => {
          const kanban_details = params.data?.kanban_details;
          if (kanban_details === undefined) {
            return `${params.data.module_number}`;
          } else {
            return `${params.data.kanban_details} `;
          }
        },
      },
      {
        headerName: 'Plan LO DT',
        field: 'lineoff_date',
        width: 140,
        headerTooltip: 'LineOff Date(mm/dd/yyyy)',
        filter: 'agDateColumnFilter',
        filterParams: {
          comparator: dateFilterComparator,
        },
        floatingFilter: true,
        comparator: this.dateComparator,
      },
      {
        headerName: 'Plan Stock DT/Time',
        field: 'planned_stocking_datetime',
        width: 150,
        floatingFilter: true,
      },
      {
        headerName: 'Shift',
        field: 'planned_shift',
        sortable: true,
        width: 100,
        floatingFilter: true,
      },
      {
        headerName: 'Plan Veh',
        field: 'planned_vehicle_number',
        width: 120,
        floatingFilter: true,
      },
      // {
      //   headerName: 'Countdown to next unload ',
      //   field: 'vehcountdown',
      //   width: 120,
      //   floatingFilter: true,
      //   hide: true
      // },
      {
        headerName: 'Stocked',
        field: 'stockCheck',
        sortable: false,
        floatingFilter: false,
        cellRendererFramework: ModuleStockingGridCheckboxComponent,
        cellRendererParams: { column: 'stock', onCallAPI: this.onCallAPI.bind(this) },
        width: 80,
        hide: !this.access2,
      },
      // {
      //   headerName: 'Status',
      //   field: 'module_status',
      //   width: 100,
      //   floatingFilter: true,
      //   hide: 'true',
      // },
      {
        headerName: 'Actual Stock DT/Time',
        field: 'actual_stocking_datetime',
        width: 150,
        floatingFilter: true,
      },
      // {
      //   headerName: 'Actual Veh',
      //   field: 'actual_vehicle_number',
      //   sortable: true,
      //   width: 100,
      //   floatingFilter: true,
      //   hide:true
      // },
      // {
      //   headerName: '+/- Veh Stocking',
      //   field: 'vehicle_count_ahead_behind',
      //   sortable: true,
      //   width: 120,
      //   floatingFilter: true,
      //   hide:true
      // },
      // {
      //   headerName: '+/- Module Stocking',
      //   field: 'module_count_ahead_behind',
      //   sortable: true,
      //   width: 120,
      //   floatingFilter: true,
      //   hide:true
      // },
      {
        headerName: 'Updated DT',
        headerTooltip: 'Updated Date(mm/dd/yyyy hh:mm:ss (Eastern))',
        field: 'updated_date_time',
        sortable: true,
        width: 200,
        floatingFilter: true,
        tooltipField: 'updated_date_time',
      },
      {
        headerName: 'User',
        field: 'updated_userid',
        sortable: true,
        width: 200,
        floatingFilter: true,
        tooltipField: 'updated_userid',
      },
    ];

    this.gridOptions = {
      onSelectionChanged: this.onSelectionChanged.bind(this),
    };
    this.columnDefs = [...this.columnDefsMaster];
    this.setPageSizeToAll(this.defaultPageSize);
    this.rowSelection = 'multiple';
    this.editType = 'fullRow';
    this.gridOptions = this.gridDataService.getGridOptions();
    this.gridOptions.onGridReady = function (params) {
      this.gridApi = params.api;
    };
    this.moduleSubscription$ = this.stateService.getNamc().subscribe((observable) => {
      this.initializeData();
    });

    this.gridOptions.onFilterChanged = () => {
      let data: any = [];
      if (this.osPartsGrid) {
        this.osPartsGrid.gridOptions.api.forEachNodeAfterFilter((node) => {
          data.push(node.data.dock);
        });
      }
      if (data) {
        this.uniqueDock = getUnique(data, 'dock');
      }
      this.isAllSelected();
    };
  }

  numberComparator(number1, number2) {
    return number1 - number2;
  }

  dateComparator(date1, date2) {
    function monthToNum(date) {
      if (date === undefined || date === null) {
        return null;
      }
      const newDate: Date = new Date(date);
      const yearNumber = newDate.getFullYear();
      const monthNumber = newDate.getMonth();
      const dayNumber = newDate.getDate();
      const hoursNumber = newDate.getHours();
      const minutesNumber = newDate.getMinutes();

      const result = yearNumber * 10000 + monthNumber * 100 + dayNumber + hoursNumber * 60 + minutesNumber * 10;
      return result;
    }

    // To be used inside filter params when using date filter

    const date1Number = monthToNum(date1);
    const date2Number = monthToNum(date2);

    if (date1Number === null && date2Number === null) {
      return 0;
    }
    if (date1Number === null) {
      return -1;
    }
    if (date2Number === null) {
      return 1;
    }

    return date1Number - date2Number;
  }

  removeMessage() {
    this.warningMessage = '';
    this.successMessage = '';
  }
  onResetDropDown() {
    this.isAllSelect = true;
    this.removeMessage();
    this.selectedDock = [];
    this.stockingDtFrom = this.onInstockingDateTo;
    this.stockingDtTo = this.easternDate;

    if (this.osPartsGrid) {
      this.headerCheckboxChecked = false;
      this.osPartsGrid.gridOptions.api.refreshHeader();
      this.resetGrid();
    }
    this.setPageSizeToAll(this.defaultPageSize);

    this.rowData = [];
    this.offset = 0;
  }
  toggleHeaderCheckbox(checked) {
    if (!checked) {
      this.headerCheckboxChecked = false;
      this.osPartsGrid.gridOptions.api.refreshHeader();
      return;
    }

    const rowCount = this.osPartsGrid.gridOptions.rowData.length;
    const selectedRowCount = this.osPartsGrid.gridOptions.rowData.filter((x) => x.rowCheckBox === true).length;

    if (rowCount === selectedRowCount) {
      this.headerCheckboxChecked = true;
      this.osPartsGrid.gridOptions.api.refreshHeader();
    }
  }

  userCheckChanged(checked, rowIndex) {
    this.spinner.show();
    const rowNode = this.osPartsGrid.gridOptions.api.getDisplayedRowAtIndex(rowIndex);
    if (checked === true) {
      rowNode.setSelected(true);
      if (this.isEditMode) {
        rowNode.data.checkedEditMode = true;
      }
      rowNode.data.rowCheckBox = true;
    } else {
      rowNode.setSelected(false);
      rowNode.data.checkedEditMode = false;
      rowNode.data.rowCheckBox = false;
    }

    this.gridOptions.getRowStyle = function (params) {
      if (params.node.rowIndex === rowIndex) {
        const color = checked ? '#E4ECF0' : 'white';
        return { background: color };
      }
      return { background: 'white' };
    };

    rowNode.setData(rowNode.data);
    this.toggleHeaderCheckbox(checked);
    this.spinner.hide();
  }

  headerCheckChanged(event) {
    this.headerCheckboxChecked = event.checked;
    const headerChecked = event.checked;
    const isEditable = this.isEditMode;
    this.osPartsGrid.gridOptions.api.forEachNode(function (rowNode, index) {
      if (headerChecked) {
        if (isEditable) {
          rowNode.data.checkedEditMode = true;
        }
        rowNode.data.rowCheckBox = true;
      } else {
        rowNode.data.rowCheckBox = false;
        rowNode.data.checkedEditMode = false;
      }
      rowNode.setData(rowNode.data);
    });
  }
  module_stocking_columns;
  onShowHideChange(ev: any) {
    this.osPartsGrid.gridOptions.columnApi.setColumnsVisible([...this.showHideList.map((e) => e.value)], true);

    const hideItems = this.showHideList.filter((item) => {
      return !ev.value.map((sItem) => sItem.value).includes(item.value);
    });

    this.osPartsGrid.gridOptions.columnApi.setColumnsVisible([...hideItems.map((e) => e.value)], false);

    if (ev.onInit) return;

    this.module_stocking_columns = hideItems;
    if (!hideItems.length) this.module_stocking_columns = [{}];
  }

  onSearch(offset) {
    this.uniqueDock = [];
    this.isAllSelected();
    if (this.osPartsGrid) {
      this.headerCheckboxChecked = false;
      this.osPartsGrid.gridOptions.api.refreshHeader();
      this.resetGrid();
    }

    this.removeMessage();
    if (this.updatedAllRows) {
      this.successMessage = this.isStockAll
        ? successMessage.stockAll + this.uniqueDock
        : successMessage.unstockAll + this.uniqueDock;
    }

    if (offset === 0) {
      this.setPageSizeToAll(this.defaultPageSize);
      this.rowData = [];
      this.osPartsGrid.gridOptions.api.setRowData(this.rowData);

      const { valid, error } = validateVanningDates({
        vanningFrom: this.stockingDtFrom,
        vanningTo: this.stockingDtTo,
      });

      if (!valid) {
        this.warningMessage = warningMessage.stockingdateInvalid;
        window.scroll(0, 0);
        this.spinner.hide();
        return;
      }

      this.offset = 0;
      this.spinner.show();
    }

    if (!this.checkIfAnySelected()) {
      this.rowData = [];
      this.warningMessage = warningMessage.selectDock;

      window.scroll(0, 0);
      this.spinner.hide();
      return false;
    }
    let selectedDock;

    if (this.selectedDock.length > 0) {
      if (!this.selectedDock.some((a) => a.dock === 'ALL')) {
        selectedDock = [];
        this.selectedDock.forEach((element) => {
          selectedDock.push(element.dock);
        });
      }
    }

    const data = {
      offset: offset,
      limit: 12000,
      business_entity: this.namcValue,
      workid: this.user,
      user_role: this.userRole,
      dock: selectedDock,
      stockingDtFrom: this.stockingDtFrom,
      stockingDtTo: this.stockingDtTo,
      currenteasternDate: this.easternDate,
    };

    this.OspService.getmoduleStockingData(data).subscribe({
      error: this.errorCallback,
      next: (res) => {
        if (!res.error) {
          if (res.body && res.body.data) {
            this.rowData.push(...res.body.data);
            this.osPartsGrid.gridOptions.api.setRowData(this.rowData);
            console.log('parseInt(res.body.data.rowCount)', this.offset, parseInt(res.body.data));
            this.dataCount = parseInt(res.body.rowCount);
            const records = this.dataCount - (this.offset + 1) * 12000;
            console.log('records', records);
            if (records >= 1) {
              this.offset++;
              this.onSearch(this.offset * 12000);
            } else {
              this.spinner.hide();

              //Call onSearchSchedules function
              this.onSearchSchedules(0);
              this.updatedAllRows =false;
            }
          } else {
            this.rowData = [];
            //Call onSearchSchedules function
            this.onSearchSchedules(0);
            this.spinner.hide();
            this.updatedAllRows =false;

          }

          this.setPageSizeToAll();
        } else {
          this.spinner.hide();
          this.warningMessage = 'Try again! Error in  getting module stocking data please connect Support team';
        }
      },
    });

    return true;
  }
  onSearchSchedules(offset) {
    if (this.osPartsGrid) {
      this.headerCheckboxChecked = false;
      this.osPartsGrid.gridOptions.api.refreshHeader();
      this.resetGrid();
    }

    // this.removeMessage();

    let selectedDock;

    if (this.selectedDock.length > 0) {
      if (!this.selectedDock.some((a) => a.dock === 'ALL')) {
        selectedDock = [];
        this.selectedDock.forEach((element) => {
          selectedDock.push(element.dock);
        });
      }
    }

    const data = {
      offset: offset,
      limit: 12000,
      business_entity: this.namcValue,
      workid: this.user,
      user_role: this.userRole,
      dock: selectedDock,
      stockingDtFrom: this.stockingDtFrom,
      stockingDtTo: this.stockingDtTo,
      currenteasternDate: this.easternDate,
    };

    this.AirfreightApicallsService.getModuleSchedules(data).subscribe({
      error: this.errorCallback,
      next: (res) => {
        console.log('getModuleSchedules res', res);

        if (res.body && res.body?.addResponse?.data) {
          this.rowData.push(...res.body.addResponse.data);
          console.log('this.rowData', this.rowData, res.body);

          this.osPartsGrid.gridOptions.api.setRowData(this.rowData);
          this.dataCount = this.dataCount + parseInt(res.body.addResponse?.rowCount);
          const records = parseInt(res.body.addResponse?.rowCount) - (this.offset + 1) * 12000;
          if (records >= 1) {
            this.offset++;
            this.onSearchSchedules(this.offset * 12000);
          } else {
            this.spinner.hide();
          }
        } else {
          // this.rowData = [];
          this.spinner.hide();
        }

        this.setPageSizeToAll();
      },
    });
    return true;
  }

  onPageSizeChanged() {
    if (this.gridOptions?.api) {
      this.gridOptions.api.paginationSetPageSize(Number(this.paginationPageSize));
    }
  }

  setPageSizeToAll(pageSize?) {
    this.paginationPageSize = this.rowData.length;

    if (pageSize) {
      this.paginationPageSize = 0;
      this.rowData.length = 0;
    }

    this.onPageSizeChanged();
  }

  getModifiedRowData() {
    return this.rowData.filter((row) => row.rowCheckBox);
  }

  exportAsXLSX(): void {
    this.removeMessage();
    this.spinner.show();

    const easternCurrentDate = String(mm().tz('US/Michigan').format('YYYY-MM-DD_HH:mm:ss'));

    let data: any[] = [];
    // Collect selected rows from the grid
    if (this.headerCheckboxChecked) {
      this.osPartsGrid.gridOptions.api.forEachNodeAfterFilter((node) => {
        data.push(node.data);
      });
    } else {
      data = this.getModifiedRowData();
    }

    // If no rows are selected, show warning message
    if (data.length < 1) {
      this.spinner.hide();
      this.warningMessage = warningMessage.noRowsSelected;
    } else {
      setTimeout(async () => {
        if (this.rowData.length > 0) {
          const exportData: any = [];
          data.forEach((e) => {
            const obj = {
              dock: e.dock,
              renban: e.container_renban,
              module: e.module_number,
              planLoDate: e.lineoff_date,
              planStockDTime: e.planned_stocking_datetime,
              plannedShift: e.planned_shift,
              plannedVehicalNumber: e.planned_vehicle_number,
              moduleStatus: e.module_status,
              actualStockDTIME: e.actual_stocking_datetime,
              // actualVehicalNumber: e.actual_vehicle_number,
              // vehStocking: e.vehicle_count_ahead_behind,
              // moduleStocking: e.module_count_ahead_behind,
              userId: e.updated_userid,
            };
            exportData.push(obj);
          });

          const headers = [
            'Dock',
            'Renban',
            'Module',
            'Plan LO DT',
            'Plan Stock DT/TIME',
            'Shift',
            'Plan Veh',
            'Status',
            'Actual Stock DT/TIME',
            // 'Actual Veh',
            // '+/- Veh Stocking',
            // '+/- Module Stocking',
            'User',
          ];

          const timestamp = easternCurrentDate

            .split('.')[0]
            .replace('T', '_')
            .split('')

            .filter((d) => d !== '-' && d !== ':')
            .join('');
          this.excelService.excelModuleStocking(exportData, 'ModuleStocking_' + timestamp, headers);
        }
        this.spinner.hide();
      }, 1000);
    }
  }

  // function for checkbox, stock/unstock
  onCallAPI(rowData: any): void {
    this.updatedAllRows = false;
    this.isStockAll = false;

    if (rowData.container_renban === 'AIRFR') {
      const dataAir = {
        offset: 0,
        limit: 12000,
        business_entity: this.namcValue,
        workid: this.user,
        user_role: this.userRole,
        userid: this.userName,
        is_module_status: rowData.stock,
        data: [rowData.sched_id],
      };
      this.callingEditAir(dataAir, this.updatedAllRows, this.isStockAll);
    } else {
      const data = {
        offset: 0,
        limit: 12000,
        business_entity: this.namcValue,
        workid: this.user,
        user_role: this.userRole,
        is_module_status: rowData.stock,
        userid: this.userName,
        data: [rowData.module_type + ',' + rowData.module_number + ',' + rowData.container_renban + ',' + rowData.dock],
      };
      this.callingEditService(data, this.updatedAllRows, this.isStockAll);
    }
  }

  // edit AP call
  callingEditService(data, updatedAllRows, isStockAll) {
    const batchSize = 300;
    const totalBatches = Math.ceil(data.data.length / batchSize);

    const processBatch = (batchIndex) => {
      const start = batchIndex * batchSize;
      const end = Math.min(start + batchSize, data.data.length);
      const batchData = data.data.slice(start, end);
      const data2 = {
        offset: 0,
        limit: 12000,
        business_entity: this.namcValue,
        workid: this.user,
        user_role: this.userRole,
        is_module_status: data.is_module_status,
        userid: this.userName,
        data: batchData,
      };

      this.OspService.editModuleStocking(data2).subscribe({
        error: this.errorCallback,
        next: (res) => {
          this.spinner.show();

          if (res.body && res.body.updatedRows) {
            if (!updatedAllRows) {
              const updatedRows = res.body.updatedRows;
              updatedRows.forEach((updatedRow) => {
                const rowNode = this.osPartsGrid.gridOptions.api.getRowNode(updatedRow.id);
                if (rowNode) {
                  rowNode.data.module_number = updatedRow.module_number;
                  rowNode.data.actual_stocking_datetime = updatedRow.actual_stocking_datetime;
                  rowNode.data.updated_userid = updatedRow.updated_userid;
                }
              });

              // Assuming this.rowData contains all the rows including the updated ones
              this.osPartsGrid.gridOptions.api.setRowData(this.rowData);
            } else if (batchIndex < totalBatches - 1) {
              processBatch(batchIndex + 1);
            } else {
              this.onSearch(0);
              // if (updatedAllRows) {
              //   this.successMessage = isStockAll
              //     ? successMessage.stockAll + this.uniqueDock
              //     : successMessage.unstockAll + this.uniqueDock;
              // }
            }
          }
        },
        complete: () => {
          if (batchIndex >= totalBatches - 1) {
            window.scroll(0, 0);
            this.setPageSizeToAll();
            this.spinner.hide();
            // this.onSearch(0); // Call onSearch here
            // if (updatedAllRows) {
            //   this.successMessage = isStockAll
            //     ? successMessage.stockAll + this.uniqueDock
            //     : successMessage.unstockAll + this.uniqueDock;
            // }
          }
        },
      });
    };

    processBatch(0);
  }

  // callingEditService(data, updatedAllRows, isStockAll) {
  //   this.OspService.editModuleStocking(data).subscribe({
  //     error: this.errorCallback,
  //     next: (res) => {
  //       this.spinner.hide();

  //       if (res.body && res.body.updatedRows) {
  //         if (!updatedAllRows) {
  //           const selectedRowNodes = this.osPartsGrid.gridOptions.api.getSelectedNodes();
  //           const selectedIds = selectedRowNodes.map(function (rowNode) {
  //             return rowNode.id;
  //           });
  //           this.rowData = this.rowData.map(function (dataItem) {
  //             const itemSelected = selectedIds.indexOf(dataItem.symbol) >= 0;
  //             if (itemSelected) {
  //               return {
  //                 module_number: res.body.updatedRows.module_number,
  //                 actual_stocking_datetime: dataItem.actual_stocking_datetime,
  //                 updated_userid: dataItem.updated_userid,
  //               };
  //             } else {
  //               return dataItem;
  //             }
  //           });
  //           this.osPartsGrid?.gridOptions.api.setGridOption('rowData', this.rowData);
  //         } else {
  //           this.onSearch(0);
  //           if (isStockAll) {
  //             this.successMessage = successMessage.stockAll + this.uniqueDock;
  //           } else {
  //             this.successMessage = successMessage.unstockAll + this.uniqueDock;
  //           }
  //         }
  //       }
  //       window.scroll(0, 0);
  //       this.setPageSizeToAll();
  //     },
  //   });
  // }
  callingEditAir(data, updatedAllRows, isStockAll) {
    this.AirfreightApicallsService.updateAirModule(data).subscribe({
      error: this.errorCallback,
      next: (res) => {
        // this.spinner.hide();
        console.log('callingEditAir res', res);

        if (res.body) {
          if (!updatedAllRows) {
            const selectedRowNodes = this.osPartsGrid.gridOptions.api.getSelectedNodes();
            const selectedIds = selectedRowNodes.map((rowNode) => rowNode.id);
            this.rowData = this.rowData.map((dataItem) => {
              const itemSelected = selectedIds.indexOf(dataItem.symbol) >= 0;
              if (itemSelected) {
                return {
                  module_number: res.body.updatedRows.module_number,
                  actual_stocking_datetime: dataItem.actual_stocking_datetime,
                  updated_userid: dataItem.updated_userid,
                };
              } else {
                return dataItem;
              }
            });
            this.osPartsGrid.gridOptions.api.setRowData(this.rowData);
          }
        }
        window.scroll(0, 0);
        this.setPageSizeToAll();
        this.onSearch(0); // Call onSearch here
        if (updatedAllRows) {
          this.successMessage = isStockAll
            ? successMessage.stockAll + this.uniqueDock
            : successMessage.unstockAll + this.uniqueDock;
        }
      },
    });
  }
  stockUnstockAll(isStockAll: boolean) {
    this.updatedAllRows = true;
    this.isStockAll = isStockAll;
    let allData = [];
    allData = this.getModifiedcheckedRowData(isStockAll);

    if (allData.length < 1) {
      this.spinner.hide();
      if (isStockAll) {
        this.warningMessage = 'All the records in grid are in stock.';
      } else {
        this.warningMessage = 'All the records in grid are in unstock.';
      }
      return false;
    }
    const airRecords = allData.filter((row) => row.container_renban === 'AIRFR');
    const noAirRecords = allData.filter((row) => row.container_renban !== 'AIRFR');

    if (noAirRecords.length > 0) {
      //  let arrayData =  noAirRecords.map((record) => ({
      //     module_type: record.module_type,
      //     module_number: record.module_number,
      //     container_renban: record.container_renban,
      //     dock: record.dock
      //   }))
      let arrayData = noAirRecords.map(
        (record) => record.module_type + ',' + record.module_number + ',' + record.container_renban + ',' + record.dock
      );
      const data = {
        offset: 0,
        limit: 12000,
        business_entity: this.namcValue,
        workid: this.user,
        user_role: this.userRole,
        is_module_status: isStockAll,
        userid: this.userName,
        data: arrayData,
      };
      this.callingEditService(data, this.updatedAllRows, isStockAll);
    }
    if (airRecords.length > 0) {
      const dataAir = {
        offset: 0,
        limit: 12000,
        business_entity: this.namcValue,
        workid: this.user,
        user_role: this.userRole,
        is_module_status: isStockAll,
        userid: this.userName,
        data: airRecords.map((record) => record.sched_id),
      };
      this.callingEditAir(dataAir, this.updatedAllRows, isStockAll);
    }
  }

  // Method to check condition stockAll/unStock button will be visible
  isAllSelected() {
    const isAllSelectedCondition =
      this.selectedDock.length === 0 ||
      this.uniqueDock.length > 1 ||
      (this.uniqueDock.length === 0 && this.selectedDock.some((dock) => dock.dock === 'ALL')) ||
      (this.selectedDock.length > 1 && this.uniqueDock.length !== 1) ||
      this.stockingDtFrom != this.stockingDtTo;
    console.log('this.selectedDock', this.selectedDock, 'this.uniqueDock', this.uniqueDock);

    console.log('isAllSelectedCondition', isAllSelectedCondition, this.stockingDtFrom, this.stockingDtTo);

    this.isAllSelect = isAllSelectedCondition;
  }

  getModifiedcheckedRowData(isStockAll: boolean) {
    let data: any = [];
    this.osPartsGrid.gridOptions.api.forEachNodeAfterFilter((node) => {
      data.push(node.data);
    });
    console.log('data----', data);

    if (isStockAll) {
      return data.filter(
        (row) =>
          row.module_status === 'UNSTOCKED' ||
          row.module_status === null ||
          row.module_status === '' ||
          (row.stockCheck && row.stockCheck.isChecked === false)
      );
    } else {
      return data.filter(
        (row) => row.module_status === 'STOCKED' && (!row.stockCheck || row.stockCheck.isChecked !== false)
      );
    }
  }

  ngOnDestroy(): void {
    this.moduleSubscription$.unsubscribe();
  }

  initializeData() {
    this.spinner.show();
    this.rowData = [];
    this.spinner.show();

    this.namcValue = localStorage.getItem('namcvalue');

    if (localStorage.getItem('workdayId')) {
      this.user = localStorage.getItem('workdayId');
    }
    if (localStorage.getItem('UserRoles')) {
      this.userRole = localStorage.getItem('UserRoles');
    }
    if (localStorage.getItem('UserName')) {
      this.userName = localStorage.getItem('UserName');
    }
    const access = checkAccessPipe();
    this.access1 = access.access;
    this.access2 = access.access.edit;
    this.access3 = access.access.edit;

    localStorage.setItem('stock', this.access3);

    this.loadDrop();

    this.onResetDropDown();

    // eastern time api
    this.lookupService.getEasternDate().subscribe({
      error: this.errorCallback,
      next: (res) => {
        this.stockingDtTo = res.body.data;
        this.easternDate = res.body.data;
      },
    });
  }

  checkIfAnySelected() {
    if (this.selectedDock.length > 0) {
      return true;
    }
    return false;
  }

  resetGrid() {
    if (this.osPartsGrid) {
      resetSortingAndFilters(this.osPartsGrid);
      return;
    }
  }

  errorCallback = (error) => {
    logErrors(error);
    this.spinner.hide();
    this.warningMessage = 'Something went wrong! Please contact support team.';
    this.rowData = [];
    this.osPartsGrid.gridOptions.api.setRowData(this.rowData);
  };

  // Service call
  loadDrop() {
    this.spinner.show();
    const access = checkAccessPipe();
    this.access1 = access.access;
    this.access2 = access.access.edit;

    const data = {
      offset: this.offset,
      limit: 12000,
      business_entity: this.namcValue,
      workid: this.user,
      user_role: this.userRole,
      stocking_to: this.stockingDtTo,
      stocking_from: this.stockingDtFrom,
      currenteasternDate: this.easternDate,
    };

    const lookupSubscriptions = [this.lookupService.getDock(), this.lookupService.getUserShowHideColumns()];

    forkJoin(lookupSubscriptions).subscribe({
      next: (res) => {
        this.dockList = [];
        if (!res[0].error) {
          if (res[0].body.data) {
            this.dockList.push({ dock: 'ALL' });
          }
        } else {
          this.warningMessage = 'Try after some time! Error in get dock list';
        }
        this.dockList.push(...res[0].body.data);
        const { module_stocking_columns } = res[1].body.showHideColumnsResponse;
        try {
          const hideItems = JSON.parse(module_stocking_columns);

          if (typeof hideItems === 'object' && hideItems.length > 0) {
            this.selectedShowHideList = this.showHideList.filter(
              (sItem) => !hideItems.map((item) => item.value).includes(sItem.value)
            );

            this.onShowHideChange({
              value: this.selectedShowHideList,
              onInit: true,
            });
          }
        } catch (error) {}
        this.spinner.hide();
      },
      error: this.errorCallback,
    });
  }
  saveShowHide() {
    this.spinner.show();

    let module_stocking_columns = this.module_stocking_columns;
    if (module_stocking_columns) {
      this.lookupService.updateUserShowHideColumns({ module_stocking_columns }).subscribe({
        error: this.errorCallback,
        next: (res) => {
          this.spinner.hide();
        },
      });
    } else {
      this.spinner.hide();
    }
  }

  dockChange(): void {
    if (this.selectedDock.length >= 1) {
      if (this.selectedDock[this.selectedDock.length - 1].dock === 'ALL') {
        this.selectedDock = [];
        this.selectedDock.push(this.dockList[0]);
      } else {
        let indexAll = this.selectedDock.findIndex((data) => data.dock === 'ALL');

        if (indexAll > -1) {
          this.selectedDock.splice(indexAll, 1);
        }
      }
    }
  }

  onSelectionChanged() {
    const selectedRows = this.gridOptions.api.getSelectedRows();
    const allRows = [];
    this.gridOptions.api.forEachNode((node) => {
      allRows.push(node.data);
    });
  }

  onRowChanged(event) {
    const changedRows = event.api.getSelectedRows();
  }
}
