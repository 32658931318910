<div *ngIf="data">
  <div class="row" style="border-bottom: 2px solid #ccc; margin-bottom: 10px">
    <div class="col-sm-11 movable">
      <h3
        class="head"
        cdkDrag
        cdkDragHandle
        cdkDragRootElement=".cdk-overlay-pane"
      >
        <strong>{{ data.mode | uppercase }} MODULE TYPE</strong>
      </h3>
    </div>
    <div class="col-sm-1">
      <button
        type="button"
        class="btn btn-dark btn-circle btn-xl"
        [mat-dialog-close]="true"
        data-dismiss="modal"
        aria-label="Close"
      >
        <span aria-hidden="true">×</span>
      </button>
    </div>
  </div>
  <form  *ngIf="editMode" [formGroup]="addOrEditform">
    <div class="row my-1">
      <div class="col-md-4">
        <div class="drpchildctrl">
          <span class="v1"></span>
          <label required class="searchLable">Module Type</label>
          <input
            type="text"
            class="form-control desc"
            placeholder="Enter"
            formControlName="module_type"
          />
          <ng-container *ngIf="addOrEdit.module_type.errors && submitted">
            <small *ngIf="addOrEdit.module_type.errors?.required">
              Module Type is required.
            </small>
          </ng-container>
        </div>
      </div>
      <div class="col-md-4">
        <div class="drpchildctrl">
          <span class="v1"></span>
          <label required class="searchLable">Module Desc </label>
          <input
            type="text"
            class="form-control desc"
            placeholder="Enter"
            formControlName="module_desc"
            maxlength="100"
          />
          <ng-container *ngIf="addOrEdit.module_desc.errors && submitted">
            <small *ngIf="addOrEdit.module_desc.errors?.required">
              Module Desc is required.
            </small>
          </ng-container>
        </div>
      </div>     
      <div class="col-md-4">
        <div class="drpchildctrl">
          <span class="v1"></span>
          <label required class="searchLable">Dock</label>
          <input
            type="text"
            class="form-control desc"
            placeholder="Enter"
            formControlName="dock"
          />
            <ng-container *ngIf="addOrEdit.dock.errors && submitted">
            <small *ngIf="addOrEdit.dock.errors?.required">
              Dock is required.
            </small>
          </ng-container>
        </div>
      </div>
    </div>
    <div class="row my-1">
     <div class="col-md-4">
      <div class="drpchildctrl">
        <span class="v1"></span>
        <label required class="searchLable">OSP Line Path</label>
        <input
          type="text"
          class="form-control desc"
          placeholder=""
          formControlName="linePathList"
        />
          <ng-container *ngIf="addOrEdit.linePathList.errors && submitted">
          <small *ngIf="addOrEdit.linePathList.errors?.required">
            Osp Line Path is required.
          </small>
        </ng-container>
      </div>
    </div> 
      <div class="col-md-4">
        <div class="drpchildctrl">
          <span class="v1"></span>
          <label required class="searchLable">Line</label>
          <input
            type="text"
            class="form-control desc"
            placeholder="Enter"
            formControlName="line"
          />

          <!-- <span class="v1"></span>
          <label required class="searchLable">LINE </label>
          <p-dropdown
          id="line"
          [(ngModel)]="selectedLine"

          [options]="filteredLineList"
          [filter]="true"
          [virtualScroll]="true"
          [itemSize]="30"
          optionLabel="line"
          placeholder="Select"
          formControlName="line"
          [style]="{ width: '100%' }"
          scrollHeight="100px"
          [appendTo]="'body'" 
          [autoWidth]="false"
          filterBy="line"
          (onFilter)="onFilter($event)"
          ></p-dropdown> -->
          <ng-container *ngIf="addOrEdit.line.errors && submitted">
            <small *ngIf="addOrEdit.line.errors?.required">
              Line is required.
            </small>
          </ng-container>
        </div>
      </div>      
      <div class="col-md-4">
        <div class="drpchildctrl">
        <span class="v1"></span>
        <label required class="searchLable">Tracking Point </label>
        <p-dropdown
        id="trackingPoint"
        [options]="filteredTrackingPointList"
        [filter]="true"
        [virtualScroll]="true"
        [itemSize]="1"
        optionLabel="tracking_point"
        placeholder="Select"
        formControlName="tracking_point"
        [style]="{ width: '100%', height : '25px' }"
        scrollHeight="100px"
        [appendTo]="'body'" 
        [autoWidth]="false"
        filterBy="tracking_point"
        (onFilter)="onFilterTrackingPoint($event)"
      ></p-dropdown>
        <ng-container *ngIf="addOrEdit.tracking_point.errors && submitted">
            <small *ngIf="addOrEdit.tracking_point.errors?.required">
            Tracking Point is required.
            </small>
        </ng-container>
        </div>
      </div>
    </div>
    <div class="row my-1">
      <div class="col-md-4">
        <div class="drpchildctrl">
          <span class="v1"></span>
          <label required class="searchLable">Dock ModuleType Active</label>
          <input
            type="text"
            class="form-control desc"
            placeholder="Enter"
            formControlName="is_module_type_to_dock"
          />


          <!-- <span class="v1"></span>
          <label required class="searchLable">Dock ModuleType Active</label>
          <p-dropdown
          id="is_module_type_to_dock"
          [options]="moduleTypeToDockForpopup"
          [filter]="true"
          [virtualScroll]="true"
          [itemSize]="30"
          optionLabel="value"
          placeholder="Select"
          formControlName="is_module_type_to_dock"
          [style]="{ width: '100%' }"
          scrollHeight="100px"
          [appendTo]="'body'" 
          [autoWidth]="false"
          filterBy="is_module_type_to_dock"
        ></p-dropdown> -->
          <ng-container *ngIf="addOrEdit.is_module_type_to_dock.errors && submitted">
            <small *ngIf="addOrEdit.is_module_type_to_dock.errors?.required">
              Dock ModuleType Active is required.
            </small>
          </ng-container>
        </div>
      </div> 
    </div> 
  </form>
  <form  *ngIf="noteditMode" [formGroup]="addOrEditform">
    <div class="row my-1">
      <div class="col-md-4">
        <div class="drpchildctrl">
          <span class="v1"></span>
          <label required class="searchLable">Module Type</label>
          <p-dropdown
            [filter]="true"
            [virtualScroll]="true"
            [itemSize]="30"
            [options]="filteredModuleTypeList"
            optionLabel="module_type"
            placeholder="Select"
            formControlName="module_type"
            [style]="{ width: '100%', height : '25px'}"
            scrollHeight="100px"
            (onChange)="moduleTypeChange()"
            [appendTo]="'body'" 
            [autoWidth]="false"
            filterBy="module_type"
            (onFilter)="onFilterModuleType($event)"
          ></p-dropdown>
          <ng-container *ngIf="addOrEdit.module_type.errors && submitted">
            <small *ngIf="addOrEdit.module_type.errors?.required">
              Module Type is required.
            </small>
          </ng-container>
        </div>
      </div>
      <div class="col-md-4">
        <div class="drpchildctrl">
          <span class="v1"></span>
          <label required class="searchLable">Module Desc </label>
          <input
            type="text"
            class="form-control desc"
            placeholder="Enter"
            formControlName="module_desc"
            maxlength="100"
          />
          <ng-container *ngIf="addOrEdit.module_desc.errors && submitted">
            <small *ngIf="addOrEdit.module_desc.errors?.required">
              Module Desc is required.
            </small>
          </ng-container>
        </div>
      </div>
      <div class="col-md-4">
        <div class="drpchildctrl">
          <span class="v1"></span>
          <label required class="searchLable">Dock </label>
          <p-dropdown
          id="dock"
          [options]="newDockList"
          [filter]="true"
          [virtualScroll]="true"
          [itemSize]="30"
          optionLabel="dock"
          placeholder="Select"
          formControlName="dock"
          [style]="{ width: '100%', height : '25px' }"
          scrollHeight="100px"
          [appendTo]="'body'" 
          [autoWidth]="false"
          filterBy="dock"
        ></p-dropdown>
          <ng-container *ngIf="addOrEdit.dock.errors && submitted">
            <small *ngIf="addOrEdit.dock.errors?.required">
              Dock is required.
            </small>
          </ng-container>
        </div>
      </div>
    </div>
    <div class="row my-1">
      <div class="col-md-4">
        <div class="drpchildctrl">
          <span class="v1"></span>
          <label required class="searchLable">OSP Line Path</label>
          <input
            type="text"
            class="form-control desc"
            placeholder=""
            formControlName="linePathList"
          />
            <!-- <ng-container *ngIf="addOrEdit.linePathList.errors && submitted">
            <small *ngIf="addOrEdit.linePathList.errors?.required">
              Osp Line Path is required.
            </small>
          </ng-container> -->
          <ng-container >
            <small *ngIf="isLinePath">
              Osp Line Path is required.
            </small>
          </ng-container>
        </div>
      </div>      
      <div class="col-md-4">
        <div class="drpchildctrl">
          <span class="v1"></span>
          <label required class="searchLable">Line</label>
          <input
            type="text"
            class="form-control desc"
            placeholder=""
            formControlName="line"
          />
            <!-- <ng-container *ngIf="addOrEdit.line.errors && submitted">
            <small *ngIf="addOrEdit.line.errors?.required">
              Line is required.
            </small>
            
          </ng-container> -->
          <ng-container >
            <small *ngIf="isLine">
              Line is required.
            </small>
          </ng-container>
        </div>
      </div>   
      <div class="col-md-4">
          <div class="drpchildctrl">
          <span class="v1"></span>
          <label required class="searchLable">Tracking Point </label>
          <p-dropdown
          id="trackingPoint"
          [options]="filteredTrackingPointList"
          [filter]="true"
          [virtualScroll]="true"
          [itemSize]="1"
          optionLabel="tracking_point"
          placeholder="Select"
          formControlName="tracking_point"
          [style]="{ width: '100%', height : '25px' }"
          scrollHeight="100px"
          [appendTo]="'body'" 
          [autoWidth]="false"
          filterBy="tracking_point"
          (onFilter)="onFilterTrackingPoint($event)"
        ></p-dropdown>
          <ng-container *ngIf="addOrEdit.tracking_point.errors && submitted">
              <small *ngIf="addOrEdit.tracking_point.errors?.required">
              Tracking Point is required.
              </small>              
          </ng-container>
          </div>
      </div>
    </div>
    <div class="row my-1">
      <div class="col-md-4">
        <div class="drpchildctrl">
          <span class="v1"></span>
          <label required class="searchLable">Dock ModuleType Active</label>
          <p-dropdown
          id="is_module_type_to_dock"
          [options]="moduleTypeToDockForpopup"
          [filter]="true"
          [virtualScroll]="true"
          [itemSize]="30"
          optionLabel="value"
          placeholder="Select"
          formControlName="is_module_type_to_dock"
          [style]="{ width: '100%', height : '25px' }"
          scrollHeight="100px"
          [appendTo]="'body'" 
          [autoWidth]="false"
          filterBy="value"
        ></p-dropdown>
          <ng-container *ngIf="addOrEdit.is_module_type_to_dock.errors && submitted">
            <small *ngIf="addOrEdit.is_module_type_to_dock.errors?.required">
              Dock Moduletype Active is required.
            </small>
          </ng-container>
        </div>
      </div> 
    </div> 
  </form>
  <div class="text-right" style="margin-top: 20px">
    <button class="review_btn" mat-button (click)="callRespectiveFunction()">
      Save
    </button>

    <button class="cancel_btn" mat-button mat-dialog-close="cancel">
      Cancel
    </button>
  </div>
</div>
