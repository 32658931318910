<div *ngIf="data">
    <div class="row" style="border-bottom: 2px solid #ccc; margin-bottom: 10px">
      <div class="col-sm-11 movable">
        <h3
          class="head"
          cdkDrag
          cdkDragHandle
          cdkDragRootElement=".cdk-overlay-pane"
        >
          {{ data.mode | uppercase }} BREAKS
        </h3>
      </div>
  
      <div class="col-sm-1">
        <button
          type="button"
          class="btn btn-dark btn-circle btn-xl"
          [mat-dialog-close]="true"
          data-dismiss="modal"
          aria-label="Close"
        >
          <span aria-hidden="true">×</span>
        </button>
      </div>
    </div>
    <form [formGroup]="addOrEditform">
      <div class="row">
        <div class="col-md-6">
          <div class="drpchildctrl">
            <span class="v1"></span>
            <label required class="searchLable">SHIFT NUMBER</label>
            <input
              formControlName="shiftNumber"
              class="key"
              type="text"
              placeholder="--Type--"
              readonly
            />
          </div>
        </div>
      </div>
      <div class="row" style="margin-top: 10px">
        <div class="col-md-6">
          <div class="drpchildctrl">
            <span class="v1"></span>
            <label for="timeSelect" class="searchLable">FROM TIME</label>
            <div>
              <select formControlName="fromHoursHH" id="fromHoursHH">
                <option value="" selected disabled>HH</option>
                <option *ngFor="let a of hoursArray; let index = index" [value]="index">
                  {{ formatNumber(index) }}
                </option>
              </select>
              :
              <select formControlName="fromMintuesMM" id="fromMintuesMM">
                <option value="" selected disabled>mm</option>
                <option *ngFor="let a of mintuesArray; let index = index" [value]="index">
                  {{ formatNumber(index) }}
                </option>
              </select>
            </div>
            <div>
              <ng-container *ngIf="addOrEdit.fromHoursHH.errors && submitted">
                <small *ngIf="addOrEdit.fromHoursHH.errors?.required">
                  From Time (hour) is required.
                </small>
              </ng-container>
              <ng-container *ngIf="addOrEdit.fromMintuesMM.errors && submitted">
                <small *ngIf="addOrEdit.fromMintuesMM.errors?.required">
                  From Time (minute) is required.
                </small>
              </ng-container>
            </div>
          </div>
        </div>
        <div class="col-md-6">
          <div class="drpchildctrl">
            <span class="v1"></span>
            <label for="timeSelect" class="searchLable">TO TIME</label>
            <div>
              <select formControlName="toHoursHH" id="toHoursHH">
                <option value="" selected disabled>HH</option>
                <option *ngFor="let a of hoursArray; let index = index" [value]="index">
                  {{ formatNumber(index) }}
                </option>
              </select>
              :
              <select formControlName="toMintuesMM" id="toMintuesMM">
                <option value="" selected disabled>mm</option>
                <option *ngFor="let a of mintuesArray; let index = index" [value]="index">
                  {{ formatNumber(index) }}
                </option>
              </select>
            </div>
            <div>
              <ng-container *ngIf="addOrEdit.toHoursHH.errors && submitted">
                <small *ngIf="addOrEdit.toHoursHH.errors?.required">
                  To Time (hour) is required.
                </small>
              </ng-container><br>
              <ng-container *ngIf="addOrEdit.toMintuesMM.errors && submitted">
                <small *ngIf="addOrEdit.toMintuesMM.errors?.required">
                  To Time (minute) is required.
                </small>
              </ng-container>
            </div>
          </div>
        </div>
        <small *ngIf="errorMessage" class="error-message">{{ errorMessage }}</small>
      </div>
      
      
      <div class="row"></div>
    </form>
    <div class="text-right" style="margin-top: 20px">
        <button class="review_btn" mat-button (click)="callRespectiveFunction()">
            Save
        </button>
    
        <button class="cancel_btn" mat-button mat-dialog-close="cancel">
            Cancel
        </button>
    </div>
  </div>
  