import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { HeaderCheckboxComponent } from '../core/grid/header-checkbox/header-checkbox.component';
import { GridRefreshComponent } from '../core/grid/grid-refresh/grid-refresh.component';
import { RowCheckboxComponent } from '../core/grid/row-checkbox/row-checkbox.component';
import { NgxSpinnerService } from 'ngx-spinner';
import { CustomTooltipComponent } from '../core/custom-tooltip/custom-tooltip.component';
import { LookupService } from '../services/lookup/lookup.service';
import { GriddataService } from '../services/griddata.service';
import { ViewBreakComponent } from './breaks-detailview/review-changes/review-changes.component';
import {
  MatLegacyDialog as MatDialog,
} from '@angular/material/legacy-dialog';
import { MatDialogConfig } from '@angular/material/dialog';
import { ConfirmCancelDailogComponent } from '../core/confirm-cancel-dailog/confirm-cancel-dailog.component';
import { ExcelService } from '../services/excel.service';
import * as moment from 'moment';
import { OSPStateService } from '../services/osp-state.service';
import { forkJoin, Subject, Subscription } from 'rxjs';
import { OspService } from '../core/master-services/osp.service';

import {
  dateFilterComparator,
  dateComparator,
  resetSortingAndFilters,
  warningMessage,
  successMessage,
  checkAccessPipe,
  checkAccess
} from '../constants';
import * as mm from 'moment-timezone';
import { GridApi } from 'ag-grid-community';
import { BreaksDetailviewComponent } from './breaks-detailview/breaks-detailview.component';
import {  GridShiftsDateEditorComponent } from './breaks-detailview/review-changes/grid-editaStartdate';
import { ReviewShiftsComponent } from './breaks-detailview/review-changes/review-container.component/review-shifts.component';
import { PartstmaintenanceService } from '../services/orderforecast/parts-maintenance/partsmaintenance.service';
import { GridShiftsTimeEditorComponent } from './breaks-detailview/review-changes/review-container.component/grid-edittimeshifts';
import { ShiftsEditDialogComponent } from './breaks-detailview/shifts-edit-dialog/shifts-edit-dialog.component';
import { DeleteBtnCellRender } from './breaks-detailview/review-changes/review-container.component/delete-btn-cell-render';
import { DeleteBreaksComponent } from './breaks-detailview/delete-breaks/delete-breaks.component';
import { CopyShiftsDialogComponent } from './breaks-detailview/copy-shifts-dialog/copy-shifts-dialog.component';
import { StockingPaceServiceService } from '../services/stocking-pace-service.service';

@Component({
  selector: 'app-configuration-shifts',
  templateUrl: './configuration-shifts.component.html',
  styleUrls: ['./configuration-shifts.component.scss'],
})
export class ShiftConfigurationComponent implements OnInit, OnDestroy {
  [x: string]: any;
  namcValue = '';
  userName = '';
  user = '';
  userRole = '';
  count = 0;
  files: any[];
  fileBuffer: any;

  exportData: any;
  editApiDefaultLimit = 200;
  timesEditApiCalled: number = 0;
  editApiStartPosition: number = 0;
  editApiEndPosition: number = this.editApiDefaultLimit;
  seletedStatus: any = [];


  rowData = [];
  rowDatafinal =[];
  rowCount: number = 0;
  shiftAccess1;
  shiftAccess2;

  @ViewChild('osPartsGrid') osPartsGrid;
  columnDefsMaster;
  columnDefs;
  // columnDefsTemp;
  headerHeight: number;
  rowSelection;
  modalComponent = 'modal-component';
  shiftSubscription$: Subscription;
  gridApi: GridApi;
  msgNoContent = 'No Content';
  gridOptions;
  paginationPageSize;
  pageSize;
  paginationNumberFormatter;
  pagination = 'true';
  context: any;
  detailRowHeight = 300;
  public detailCellRenderer;
  public isRowMaster;
  masterDetail: boolean;
  public icons;

  editType;
  suppressClickEdit = true;
  tooltipShowDelay = 0;
  editEnabled: boolean = false;
  filter: boolean = false;
  showToggleAll: boolean = false;
  selectedDock: any = [];
  dockList: any = [];

  showHideList: any = [
    { label: 'Shift No', value: 'shift_no'},
    { label: 'Day', value: 'day_of_week'},
    { label: 'Start Time', value: 'start_time' },
    { label: 'End Time', value: 'end_time'},
    { label: 'From', value: 'from_date'},
    { label: 'To',  value: 'to_date' },
    { label: 'Force', value: 'force_flag' },
    { label: 'Duration', value: 'duration'},
    { label: 'Error', value: 'error' },
  ];

  selectedShowHideList: any = [...this.showHideList];

  // Dropdowns
  kanbanSelected = 1;
  disablePartNumber: boolean;
  disableKanban: boolean;
  showGrid: boolean;
  itContact: string;

  namcName: string;
  errorData: any;
  onInitEstdate: any;
  easternDate: any;

  defaultSpinnerMessage = 'Loading... Please wait...';
  dataLoadingMessage = 'Loading data in Bulk! It might take a while, Please wait...';
  spinnerMessage = this.defaultSpinnerMessage;
  showDownloadUploadButtons: boolean = true;

  headerCheckboxChecked: boolean = false;
  defaultPageSize = 100000;

  dateFormat = 'MM/DD/YYYY';
  newDate = String(mm().tz('US/Michigan').format(this.dateFormat)).substring(0, 10);
  today = moment().format('MM/DD/YYYY');
  isExist: boolean;
  offset: 0;
  isEditMode: boolean;
  warningMessage: string;
  successMessage: string;
  dataCount: number;
  public frameworkComponents

  constructor(
    private readonly spinner: NgxSpinnerService,
    private readonly excelService: ExcelService,
    private readonly gridDataService: GriddataService,
    private readonly lookupService: LookupService,
    private readonly dialog: MatDialog,
    private readonly stateService: OSPStateService,
    private readonly ospService: OspService,
    private readonly stockingService: StockingPaceServiceService,

    private readonly matDialog: MatDialog
    ) {
    this.frameworkComponents = { buttonRenderer: BreaksDetailviewComponent };
    {
      this.context = {
        componentParent: this,
        refreshHeader: new Subject<void>()
      };
    }
  }

  // DATE COMPARATOR FOR SORTING
  dateComparator(date1, date2) {
    function monthToNum(date) {
      if (date === undefined || date === null) {
        return null;
      }

      const newDate: Date = new Date(date);
      const yearNumber = newDate.getFullYear();
      const monthNumber = newDate.getMonth();
      const dayNumber = newDate.getDate();
      const hoursNumber = newDate.getHours();
      const minutesNumber = newDate.getMinutes();
      const result = yearNumber * 10000 + monthNumber * 100 + dayNumber + hoursNumber * 60 + minutesNumber * 10;
      return result;
    }

    const date1Number = monthToNum(date1);
    const date2Number = monthToNum(date2);

    if (date1Number === null && date2Number === null) {
      return 0;
    }
    if (date1Number === null) {
      return -1;
    }
    if (date2Number === null) {
      return 1;
    }

    return date1Number - date2Number;
  }

  ngOnDestroy(): void {
    // this.shiftSubscription$.unsubscribe();
  }

  ngOnInit(): void {
    const access = checkAccessPipe();
    this.access1 = access.access;
    const access3 = checkAccess();
      this.access1 = access.access;
      this.access2 = access3;
      this.shiftAccess1 = access.access.edit
      this.shiftAccess2 = access3.edit

      localStorage.setItem('shiftAccess1', this.shiftAccess1);
      localStorage.setItem('shiftAccess2', this.shiftAccess2);



    this.columnDefs = [
      {
        headerName: '',
        field: 'rowCheckBox',
        width: 44,
        headerComponentFramework: HeaderCheckboxComponent,
        floatingFilterComponentFramework: GridRefreshComponent,
        floatingFilterComponentParams: { suppressFilterButton: true },
        cellClass: 'ui-grid-cell-contents-auto',
        cellRendererFramework: RowCheckboxComponent,
        floatingFilter: true,
        pinned: 'left',
      },
      {
        headerName: '',
        field: 'delete',
        sortable: false,
        unSortIcon: false,
        pinned: 'left',
        width: 50,
        cellRendererFramework: DeleteBtnCellRender,
        cellRendererParams: {
          clicked: (field?: any) => {
            this.deleteButton(field)
          },
        },
        cellStyle: { cursor: 'pointer' },
      },
      {
        headerName: '',
        field: '',
        sortable: false,
        width: 50,
        cellRenderer: 'buttonRenderer',
        cellRendererParams: {
          label: 'Click 2',
        },
        pinned: 'left',

      },
      {
        headerName: 'Shift No',
        headerTooltip: 'Shift Number',
        field: 'shift_no',
        sortable: true,
        width: 100,
        floatingFilter: true,
      },
      {
        headerName: 'Day',
        headerTooltip: 'Day of week',
        field: 'day_of_week',
        sortable: true,
        width: 100,
        floatingFilter: true,
        valueGetter: (params) => {
          switch (params.data.day_of_week) {
            case "1": return 'Sunday';
            case "2": return 'Monday';
            case "3": return 'Tuesday';
            case "4": return 'Wednesday';
            case "5": return 'Thursday';
            case "6": return 'Friday';
            case "7": return 'Saturday';
            default: return ''; 
          }

        }

      },
      {
        headerName: 'Start Time',
        field: 'start_time',
        sortable: true,
        width: 130,
        floatingFilter: true,
        headerTooltip: 'Start Time',
        cellRendererParams: { column: 'startTime' },
        cellRendererFramework: GridShiftsTimeEditorComponent,
        valueGetter: (params) => {
          const data = params.data;
          if (!this.editEnabled || !data.rowCheckBox) {
            return data.start_time;
          } else {
            if (data.start_time) {
              if (moment(data.start_time, 'HH:mm:ss', true).isValid()) {
                return data.start_time; 
              }
            }
      
            if (data.startTime) {
              if (moment(data.startTime, 'HH:mm:ss', true).isValid()) {
                return data.startTime; 
              }
            }
            const value = moment(data.start_time ?? data.startTime, 'HH:mm:ss', true).isValid()
              ? moment(data.start_time).format('HH:mm:ss')
              : moment(data.startTime).format('HH:mm:ss'); // Fallback to empty string if the format is invalid
      
            return value;
          }
        },
      },
      {
        headerName: 'End Time',
        headerTooltip: 'End Time',
        field: 'end_time',
        width: 130,
        floatingFilter: true,
        sortable: true,
        cellRendererParams: { column: 'endTime' },
        cellRendererFramework: GridShiftsTimeEditorComponent,
        valueGetter: (params) => {
          const data = params.data;
          if (!this.editEnabled || !data.rowCheckBox) {
            return data.end_time;
          } else {
            if (data.end_time) {
              if (moment(data.end_time, 'HH:mm:ss', true).isValid()) {
                return data.end_time; 
              }
            }
      
            if (data.endTime) {
              if (moment(data.endTime, 'HH:mm', true).isValid()) {
                return  data.endTime; 
              }
            }
            const value = moment(data.end_time ?? data.endTime, 'HH:mm:ss', true).isValid()
              ? moment(data.end_time).format('HH:mm:ss')
              : moment(data.endTime).format('HH:mm:ss'); // Fallback to empty string if the format is invalid
      
            return value;
          }
        },
      },

      {
        headerName: 'Duration',
        headerTooltip: 'Duration',
        field: 'duration',
        sortable: true,
        width: 100,
        floatingFilter: true,
        tooltipField: 'duration',
      },

      {
        headerName: 'From',
        headerTooltip: 'From Date(mm/dd/yyyy)',
        field: 'from_date',
        sortable: true,
        width: 130,
        cellRendererFramework: GridShiftsDateEditorComponent,
        cellRendererParams: { column: 'fromDate' },
        valueGetter: (params) => {
          if (!this.editEnabled || !params.data.rowCheckBox) {
            return params.data.from_date;
          } else {
            if (params.data.from_date) {
              params.data.from_date = moment(params.data.from_date).format('MM/DD/YYYY');
            }
            if (params.data.fromDate) {
              params.data.fromDate = moment(params.data.fromDate).format('MM/DD/YYYY');
            }
            const value = params.data.from_date ?? params.data.fromDate;
            return value;
          }
        },
        filter: 'agDateColumnFilter',
        filterParams: {
          comparator: dateFilterComparator,
        },
        floatingFilter: true,

        comparator: this.dateComparator,
      },
      {
        headerName: 'To',
        headerTooltip: 'To Date(mm/dd/yyyy)',
        field: 'to_date',
        sortable: true,
        width: 130,
        filter: 'agDateColumnFilter',
        cellRendererFramework: GridShiftsDateEditorComponent,
        cellRendererParams: { column: 'toDate' },
        valueGetter: (params) => {
          const toDateValue = params.data.to_date;
          if (toDateValue === '12/31/2999' || moment(toDateValue).isSame('12/31/2999', 'day')) {
            return null; // Return empty string if it's '12/31/2999'
          }
          if (!this.editEnabled || !params.data.rowCheckBox) {
            return params.data.to_date;
          } else {
            if (params.data.to_date) {
              params.data.to_date = moment(params.data.to_date).format('MM/DD/YYYY');
            }
            if (params.data.toDate) {
              params.data.toDate = moment(params.data.toDate).format('MM/DD/YYYY');
            }
            const value = params.data.to_date ?? params.data.toDate?.value;
            return value;
          }
        },
        filterParams: {
          comparator: dateFilterComparator,
        },
        floatingFilter: true,
        comparator: this.dateComparator,
      },
      {
        headerName: 'Force',
        headerTooltip: 'Force',
        field: 'force_flag',
        sortable: true,
        width: 90,
        floatingFilter: true,
        tooltipField: 'force_flag',
      },
      {
        headerName: 'User',
        field: 'userid',
        width: 150,
        floatingFilter: true,
        headerClass: 'ag-theme-custom-text-right',
        tooltipField: 'userid',


      },
      {
        headerName: 'Updated DT',
        field: 'updated_datetime',
        width: 150,
        floatingFilter: true,
        tooltipField: 'updated_datetime',
        headerClass: 'ag-theme-custom-text-right',
        headerTooltip: 'Updated Date(mm/dd/yyyy hh:mm:ss (Eastern))',

      },
    ];
    this.headerHeight = 45;
    this.rowSelection = 'multiple';
    this.rowSelection = 'multiple';
    this.gridOptions = this.gridDataService.getGridOptions();
    this.gridOptions.onGridReady = function (params) {
      this.gridApi = params.api;
    };
    this.shiftSubscription$ = this.stateService.getNamc().subscribe((observable) => {
      this.initializeData();
    });
  }

  initializeData(): void {
    this.onResetDropDown();
    this.itContact = localStorage.getItem('itContact');
    this.rowDatafinal =[];
    this.rowData = [];
    this.offset = 0;
    this.isEditMode = false;
    this.editEnabled = this.isEditMode;
    this.showSpinner();
    this.namcValue = localStorage.getItem('namcvalue');
    this.user = localStorage.getItem('workdayId');
    this.userRole = localStorage.getItem('UserRoles');
    this.namcName = localStorage.getItem('namcName');
    this.userName = localStorage.getItem('UserName');

    const access = checkAccessPipe();
    const access3 = checkAccess();
      this.access1 = access.access;
      this.access2 = access3;
      this.shiftAccess1 = access.access.edit
      this.shiftAccess2 = access3.edit

      localStorage.setItem('shiftAccess1', this.shiftAccess1);
      localStorage.setItem('shiftAccess2', this.shiftAccess2);




    // if (!this.access1.edit) {
    //   const obj = this.columnDefs.find(
    //     (o) => o.field === '' // pass this dynamically 'action'
    //   );
    //   obj.hide = true;
    //   if (this.osPartsGrid) {
    //     this.osPartsGrid.gridOptions.api.refreshHeader();
    //   }
    // }
    this.loadDrop();
  }
  onEditMode() {
    this.removeMessage();
    let data = [];
    data = this.getModifiedRowData();
    localStorage.setItem('disableDevan', 'true');

    if (data.length > 0) {
      this.isEditMode = !this.isEditMode;
      this.editEnabled = this.isEditMode;

      if (this.isEditMode) {
        this.osPartsGrid.gridOptions.api.forEachNode(function (rowNode, index) {
          rowNode.data.checkedEditMode = true;
          if (rowNode.data.rowCheckBox) {
          } else {
            rowNode.data.checkedEditMode = false;
          }
          rowNode.setData(rowNode.data);

        });
      } else {
        this.osPartsGrid.gridOptions.api.forEachNode(function (rowNode, index) {
          if (rowNode.data.rowCheckBox) {
            rowNode.data.checkedEditMode = false;
          }
          rowNode.setData(rowNode.data);
        });
      }
      setTimeout(() => {
        window.scroll(0, document.body.scrollHeight);
      }, 200);
    } else {
      this.isEditMode = false;
      this.editEnabled = false;
      this.warningMessage = warningMessage.selectRowsToEdit;
      window.scroll(0, 0);
    }
  }
  openDialog1() {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.id = this.modalComponent;
    dialogConfig.height = '250px';
    dialogConfig.width = '470px';
    dialogConfig.data='ospipe'
    const dialogRef = this.dialog.open(ConfirmCancelDailogComponent, dialogConfig);
    dialogRef.afterClosed().subscribe((res) => {
      if (res === 'cancel') {
        this.columnDefs[7].cellStyle = function (params) {
          if ('modified' in params.data) {
            return { backgroundColor: '#E4ECF0' };
          } else {
            return { backgroundColor: 'white' };
          }
        };

        this.onSearch(0);
        this.editEnabled = false;
        this.isEditMode = false;
        this.osPartsGrid.gridOptions.api.refreshHeader();
      }
    });
  }

  cancelEdit(): void {
    this.removeMessage();
    this.openDialog1();
  }
  modifiedRowData = [];

  reviewData(): void {
    this.removeMessage();
    this.modifiedRowData = this.getModifiedRowData();
    console.log('modifiedData', this.modifiedRowData);

    if (this.modifiedRowData.length === 0) {
      this.warningMessage = warningMessage.editToReview;

      window.scroll(0, 0);
      return;
    }
    var editedRecords = [];
    this.modifiedRowData = this.modifiedRowData.filter((element) => element.isFromDate || element.isToDate || element.isStartTime || element.isEndTime);
    console.log('editedRecords', this.modifiedRowData);

    if (this.modifiedRowData.length  === 0) {
      this.warningMessage = warningMessage.editToReview;

      window.scroll(0, 0);
      return;
    }

    Promise.all(
      this.modifiedRowData.map(async (element, index) => {
        let p = [];
        element.error = '';
        element.shift_id_updated = element.shift_id; 

        // Save the old duration
        element.old_duration = element.duration;
        
        // Calculate the new duration in minutes from the start and end times
        element.duration_updated = this.calculateDurationInMinutes((element.end_time ?? element.endTime), (element.start_time ?? element.startTime));
        
        // Calculate the break time as the difference between the old duration and the updated duration
        element.breaktime = element.old_duration - element.duration_updated;
        
        // Check if the breaktime is negative (which shouldn't happen)
        if (element.breaktime < 0) {
            // In this case, if breaktime becomes negative, set it to 0, as we can't have negative break time
            element.breaktime = 0;
        }
        
        // Calculate the updated duration after accounting for the break time
        element.duration_updated_new = element.duration_updated - element.breaktime;
        
        // Ensure the new duration doesn't become negative (in case there's not enough total duration)
        if (element.duration_updated_new < 0) {
            // If the calculated duration is negative, set it to 0
            element.duration_updated_new = 0;
        }
                if (p.length > 0) {
          element.error = p
            .map(function (val, index) {
              return `${(index + 1).toString()}.   ${val}`;
            })
            .join('\n');
        }
      })
    );

    if (this.modifiedRowData.length > 0) {
      this.openReviewDialog(this.modifiedRowData);
    }
  }
  openReviewDialog(editedRecords) {
    var data = {
      modifiedRowData: editedRecords,
    };
    console.log('Modified', data.modifiedRowData);
    let breaks = [];
    breaks.push(data);
    // Filter the rowData to find matching items based on shift_no and shift_id
    const result = this.rowData.filter(item =>
        breaks.some(criterion =>
            item.shift_no === criterion.shift_no &&
            item.shift_id === criterion.shift_id
        )
    );
          console.log("result",result);
    if (data.modifiedRowData.length > 0) {
      const dialogConfig = new MatDialogConfig();
      dialogConfig.disableClose = true;
      dialogConfig.id = this.modalComponent;
      dialogConfig.height = '350px';
      dialogConfig.width = '1000px';

      dialogConfig.data = data;
      const dialogRef = this.dialog.open(ReviewShiftsComponent, dialogConfig);
      dialogRef.afterClosed().subscribe((res) => {
        if (res === 'cancel') {
          return;
        } else if (res === 'save') {
          console.log('inside save', data.modifiedRowData);
          var updatedData = [];
          data.modifiedRowData.forEach((e) => {
            e.to_date =  (e.to_date == null) ? '12/31/2999' : e.to_date
            var updatedFields = {
              shift_no: e.shift_no,
              day_of_week: e.day_of_week,
              start_time: e.startTime ?? e.start_time,
              end_time: e.endTime ?? e.end_time,
              duration:e.duration_updated_new,
              toDate: e.to_date ?? e.toDate,
              to_date: e.to_date ?? e.toDate,
              from_date: e.from_date ?? e.fromDate,
              force_flag: e.force_flag,
              day_offset: e.day_offset,
              shift_id:e.shift_id_updated,
              error:e.error,
              userid: localStorage.getItem('UserName'),
            };
            updatedData.push(updatedFields);
          });
          // this.successMessage = 'Record has been updated successfully';
          window.scroll(0, 0);
          this.spinner.show();
          this.editShifts(updatedData);
          // this.successMessage = successMessage.recordUpdated;
        }
      });
    } else {
      // this.warningMessage = warningMessage.selectRowsToEdit;
      window.scroll(0, 0);
    }
  }
  // Convert HH:mm time format to total minutes from midnight
  convertToMinutes(time: string): number {
    const [hours, minutes] = time.split(':').map(Number);
    return (hours * 60) + minutes;
  }

  // Calculate the duration in minutes between startTime and endTime
  calculateDurationInMinutes(endTime: string, startTime: string): number {
    const startInMinutes = this.convertToMinutes(startTime);  // Convert start_time to minutes
    const endInMinutes = this.convertToMinutes(endTime);  // Convert end_time to minutes

    let durationInMinutes = endInMinutes - startInMinutes;

    // If end_time is earlier than start_time (next day scenario), add 1440 minutes (24 hours)
    if (durationInMinutes < 0) {
      durationInMinutes += 1440; // Add 1440 minutes (1 full day)
    }

    return durationInMinutes;
  }
  updatedRows = [];
  editShifts(updatedData) {
    var data1 = {
      business_entity: this.namcValue,
      workid: this.user,
      user_role: this.userRole,
      data: updatedData.slice(this.editApiStartPosition, this.editApiEndPosition),
      shift_id:this.modifiedRowData[0].shift_id,

    };
    // let shiftId = this.modifiedRowData[0].shift_id
    console.log('before calling APi')
    this.spinner.show();
    this.ospService.editShifts(data1).subscribe({
      error: this.errorCallback,
      next: (response) => {
        console.log('response', response);
        const updatedRows = response.body.updatedRows;

        // Push new rows to updatedRows
        this.updatedRows.push(...updatedRows);

        if (updatedData.length > this.editApiEndPosition) {
          this.timesEditApiCalled++;
          this.editApiStartPosition = this.timesEditApiCalled * this.editApiDefaultLimit;
          this.editApiEndPosition = Math.min(this.editApiStartPosition + this.editApiDefaultLimit, updatedData.length);

          return this.editShifts(updatedData);
        }
         else {
          // Reset variables
          this.timesEditApiCalled = 0;
          this.editApiStartPosition = 0;
          this.editApiEndPosition = this.editApiDefaultLimit;

          // Process updatedRows
          if (this.updatedRows.length > 0) {
            console.log('ipdated rows', this.updatedRows);
            this.updatedRows = this.updatedRows.filter(
              (value, index, self) => index === self.findIndex((t) => t.shift_id === value.shift_id)
            );
            this.rowData = [];
            this.spinner.hide();
            this.rowData = this.updatedRows;
            this.updatedRows = [];
            this.osPartsGrid.gridOptions.api.setRowData(this.rowData);
            this.successMessage = successMessage.recordUpdated;
          } else {
            this.warningMessage = `${warningMessage.reportsNotUpdated}  ${this.itContact}.`;
          }

          // Other UI updates
          this.editEnabled = false;
          this.isEditMode = false;
          this.headerCheckboxChecked = false;
          this.osPartsGrid.gridOptions.api.refreshHeader();
          this.resetGrid();
          // this.checkStatus();
          window.scroll(0, 0);
          this.setPageSizeToAll();
        }
      },
    });
  }
  
  onDockChange(): void {
    if (this.selectedDock.length >= 1) {
      this.selectedDock = {
        dock: [this.selectedDock.dock],
      };
    }
  }

  onPageSizeChanged() {
    if (this.gridOptions?.api) {
      this.gridOptions.api.paginationSetPageSize(Number(this.paginationPageSize));
    }
  }

  canDeactivate() {
    return this.editEnabled;
  }
  showSpinner(message?: string) {
    if (!message) {
      this.spinnerMessage = this.defaultSpinnerMessage;
    } else {
      this.spinnerMessage = message;
    }
  }

  hideSpinner() {
    this.spinner.hide();
  }

  showbreak(data) {
    console.log("inside show breaks", data);
    let breaks = [];
    breaks.push(data);
    // Filter the rowData to find matching items based on shift_no and shift_id
    const result = this.rowData.filter(item =>
        breaks.some(criterion =>
            item.shift_no === criterion.shift_no &&
            item.shift_id === criterion.shift_id
        )
    );

    // Filter out items where break_id is null
    const filteredResult = result.filter(item => item.break_id !== null);
    // Proceed with opening the dialog and passing valid rows
    const dialogConfig = new MatDialogConfig();
    dialogConfig.id = this.modalComponent;
    dialogConfig.height = '420px';
    dialogConfig.width = '700px';
    dialogConfig.data = { rows: filteredResult, hasData: filteredResult.length > 0, result };  // Pass the valid rows
    // dialogConfig.data = result;
    const dialogRef = this.dialog.open(ViewBreakComponent, dialogConfig);
    dialogRef.afterClosed().subscribe((res) => {
        if (res === 'cancel') {
            this.onSearch(0);
            return;
        }
    });
  }
  access1;
  access2;

  setPageSizeToAll(pageSize?) {
    this.paginationPageSize = this.rowData.length;
     
    if (pageSize) {
      this.paginationPageSize = 0;
      this.rowData.length = 0;
    }

    this.onPageSizeChanged();
  }


  loadDrop() {
    this.spinner.show();

    const data = {
      business_entity: this.namcValue,
      workid: this.user,
      user_role: this.userRole,
    };

    const lookupSubscriptions = [
      // this.lookupService.getDock(),
      this.stockingService.getDockAndModuleType(),
      this.lookupService.getUserShowHideColumns(),
    ];

    forkJoin(lookupSubscriptions).subscribe({
      next: (res) => {
        this.dockList = [];

        if (!res[0].error) {

        if (res[0]?.body && Array.isArray(res[0].body)) {
          this.dockList.push(...res[0].body);
        }
      } else {
        this.warningMessage = 'Try after some time! Error in get dock list';
      }


        this.dockList = this.dockList?.filter(
          (value, index, self) => index === self.findIndex((t) => t.dock === value.dock)
        );


        const { shift_columns } = res[1].body.showHideColumnsResponse;
        try {
          const hideItems = JSON.parse(shift_columns);
          if (typeof hideItems === 'object' && hideItems.length > 0) {
            this.selectedShowHideList = this.showHideList.filter(
              (sItem) => !hideItems.map((item) => item.value).includes(sItem.value)
            );
            this.onShowHideChange({
              value: this.selectedShowHideList,
              onInit: true,
            });
          }
        } catch (error) {}
        this.spinner.hide();
      },
      error: this.errorCallback,
    });
  }
  onSearch(offset) {
    this.removeMessage();
    if (this.osPartsGrid) {
      this.headerCheckboxChecked = false;
      this.osPartsGrid.gridOptions.api.refreshHeader();
      this.resetGrid();
    }


    this.removeMessage();
    this.editEnabled = false;
    this.isEditMode = false;
    if (offset === 0) {
      this.setPageSizeToAll(this.defaultPageSize);
      this.rowData = [];
      this.rowDatafinal = [];
      this.osPartsGrid.gridOptions.api.setRowData(this.rowDatafinal);

      this.offset = 0;
      this.spinner.show();
    }

    if (!this.checkIfAnySelected()) {
      this.rowData = [];
      this.warningMessage = warningMessage.selectToSearch;

      window.scroll(0, 0);
      this.spinner.hide();
      return false;
    }
    let selectedDock;

    if (this.selectedDock.length > 0) {
      if (!this.selectedDock.some((a) => a.dock === 'ALL')) {
        selectedDock = [];
        this.selectedDock.forEach((element) => {
          selectedDock.push(element.dock);
        });
      }
    }

    const data = {
      offset: offset,
      limit: 8000,
      business_entity: this.namcValue,
      workid: this.user,
      user_role: this.userRole,
      dock: [this.selectedDock.dock]
    };
    interface  Break{
      shift_id: number;
      shift_no: string
      time_from: string;
      time_to: string;
    }

    this.ospService.getShiftDetails(data).subscribe({
      error: this.errorCallback,
      next: (res) => {
        console.log('--------------------', res);
        if (res.body && res.body.shiftResponce) {
          this.rowData.push(...res.body.shiftResponce.data);
          console.log("this.rowdata", this.rowData)
          const uniqueData: Break[] = this.rowData.reduce<Break[]>((acc, current) => {
            const found = acc.find(item => item.shift_id === current.shift_id && item.shift_no === current.shift_no);
            if (!found) {
              acc.push(current);
            }
            return acc;
          }, []);
          this.rowDatafinal=uniqueData;
          this.osPartsGrid?.gridOptions?.api?.setRowData(uniqueData);
          this.dataCount = parseInt(res.body.shiftResponce.rowCount);
          const records = this.dataCount - (this.offset + 1) * 8000;
          if (records >= 1) {
            this.offset++;
            this.onSearch(this.offset * 8000);
          } else {
            this.spinner.hide();
            if (res.body.shiftResponce.rowCount === 0) {
              this.warningMessage = "No data For the selected dock"
            }
          }
        } else {
          this.rowData = [];
          this.rowDatafinal =[]
          this.spinner.hide();
        }

        this.setPageSizeToAll();
      },
    });
    return true;
  }
  resetGrid() {
    if (this.osPartsGrid) {
      resetSortingAndFilters(this.osPartsGrid);

      return;
    }
  }

  shift_columns;
  onShowHideChange(ev: any) {
    this.osPartsGrid.gridOptions.columnApi.setColumnsVisible([...this.showHideList.map((e) => e.value)], true);

    const hideItems = this.showHideList.filter((item) => {
      return !ev.value.map((sItem) => sItem.value).includes(item.value);
    });

    this.osPartsGrid.gridOptions.columnApi.setColumnsVisible([...hideItems.map((e) => e.value)], false);

    if (ev.onInit) {
      return;
    }

    this.shift_columns = hideItems;
    if (!hideItems.length) this.shift_columns = [{}];
  }
  saveShowHide() {
    let shift_columns = this.shift_columns;
    const data = {
      business_entity: this.namcValue,
      business_entity_name: this.namcName,
      workid: this.user,
      user_role: this.userRole,
    };

    if (shift_columns) {
      this.lookupService.updateUserShowHideColumns( { shift_columns }).subscribe({
        error: this.errorCallback,
        next: (res) => {
          this.spinner.hide();
        },
      });
    } else {
      this.spinner.hide();
    }
  }

  openConfirmationDialog() {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.id = 'modal-component';
    dialogConfig.height = '204px';
    dialogConfig.width = '470px';
    const dialogRef = this.dialog.open(ConfirmCancelDailogComponent, dialogConfig);
    dialogRef.afterClosed().subscribe((res) => {
      if (res === 'cancel') {
        return;
      }
    });
  }

  getModifiedRowData(): any {
    console.log(this.rowData,'getModifiedRowData')
    const modifiedRowData = this.rowData.filter((row) => row.rowCheckBox);
    return modifiedRowData;
  }

  getSelectedRowData(): any {
    const selectedRowData = this.rowData.filter((row) => row.rowCheckBox);
    return selectedRowData;
  }

  checkIfAnySelected() {
    if (
      this.selectedDock.length != 0

    ) {
      return true;
    }
    return false;
  }

  errorCallback = (error) => {
    console.error(error);
    this.hideSpinner();
    this.removeMessage();
    this.warningMessage = `${warningMessage.apiLogicFail}  ${this.itContact}.`;
  };

  userCheckChanged(checked, rowIndex) {
    if (
      this.warningMessage === 'Please select at least one row to edit.' ||
      this.warningMessage === 'No rows are selected.'
    ) {
      this.warningMessage = '';
    }
    this.showSpinner();

    const rowNode = this.osPartsGrid.gridOptions.api.getDisplayedRowAtIndex(rowIndex);

    if (checked === true) {
      rowNode.setSelected(true);
      if (this.isEditMode) {
        rowNode.data.checkedEditMode = true;
      }
      rowNode.data.rowCheckBox = true;
    } else {
      rowNode.setSelected(false);
      rowNode.data.checkedEditMode = false;
      rowNode.data.rowCheckBox = false;
    }

    this.gridOptions.getRowStyle = function (params) {
      if (params.node.rowIndex === rowIndex) {
        const color = checked ? '#E4ECF0' : 'white';
        return { background: color };
      }
      return { background: 'white' };
    };

    rowNode.setData(rowNode.data);
    this.toggleHeaderCheckbox(checked);
    this.hideSpinner();
  }

  headerCheckChanged(event) {
    if (
      this.warningMessage === 'Please select at least one row to edit.' ||
      this.warningMessage === 'No rows are selected.'
    ) {
      this.warningMessage = '';
    }
    this.headerCheckboxChecked = event.checked;
    const headerChecked = event.checked;
    const isEditable = this.isEditMode;
    this.osPartsGrid.gridOptions.api.forEachNode(function (rowNode, index) {
      if (headerChecked) {
        if (isEditable) {
          rowNode.data.checkedEditMode = true;
        }
        rowNode.data.rowCheckBox = true;
      } else {
        rowNode.data.rowCheckBox = false;
        rowNode.data.checkedEditMode = false;
      }
      rowNode.setData(rowNode.data);
    });
  }

  toggleHeaderCheckbox(checked) {
    if (!checked) {
      this.headerCheckboxChecked = false;
      this.osPartsGrid.gridOptions.api.refreshHeader();
      return;
    }

    const rowCount = this.osPartsGrid.gridOptions.rowData.length;
    const selectedRowCount = this.osPartsGrid.gridOptions.rowData.filter((x) => x.rowCheckBox === true).length;
    console.log('rowCount', rowCount);
    console.log('selectedRowCount', selectedRowCount);

    if (rowCount === selectedRowCount) {
      this.headerCheckboxChecked = true;
      this.osPartsGrid.gridOptions.api?.refreshHeader();
    }
  }
  onResetDropDown() {
    this.removeMessage();
    this.selectedDock = [];
    this.setPageSizeToAll(this.defaultPageSize);
    this.rowData = [];
    this.offset = 0;
    if (this.osPartsGrid) {
      this.headerCheckboxChecked = false;
      this.osPartsGrid.gridOptions.api.refreshHeader();
      this.resetGrid();
    }
  }
   
  removeMessage() {
    this.warningMessage = '';
    this.successMessage = '';
  };
  //Add shifts
  openDialog() {
    this.removeMessage();
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.id = this.modalComponent;
    dialogConfig.width = '650px';
    dialogConfig.height = '480px';
    dialogConfig.data = {
      // partNoList:this.partNoListAdd,
      dockList: this.dockList,
      // business_entity: this.namcValue,
      // shift_id:2192,
      mode: 'add',
    };
    const dialogRef = this.dialog.open(ShiftsEditDialogComponent, dialogConfig);
    const addGetResponse =
      dialogRef.componentInstance?.addShiftsEventEmitter?.subscribe({error: this.errorCallback,
        next:(res) => {
        const data1 = {
          business_entity: this.namcValue,
          workid: this.user,
          user_role: this.userRole,
          userid: this.userName,
          dock: res.dock,
          shift_no: res.shiftNumber,
          day_of_week: res.dayWeek,
          start_time: res.startTime,
          end_time: res.endTime,
          duration:res.duration,
          from_date: res.effectiveFrom,
          // to_date:(res.effectiveTo === null) ? '12/31/2999' : res.effectiveTo,
          to_date: res.effectiveTo,
          force_flag: res.inMasterCalendar,
          nextDay: res.inNextDay,
          day_offset: (res.inNextDay === "Y") ? 1 : 0,
          plant: '01',
        }
        this.spinner.show();
        this.ospService?.addShift(data1)?.subscribe({
          error: this.errorCallback,
          next: (response) => {
            this.spinner.hide();
            const updatedRows  = response.body.updatedRows;
            if (updatedRows.length > 0) {
              this.successMessage = successMessage.shiftsAdded;
              this.rowData = updatedRows;
              this.osPartsGrid.gridOptions.api.setRowData(this.rowData);
              // this.osPartsGrid
            } else {
              this.warningMessage = `${warningMessage.reportsNotUpdated}  ${this.itContact}.`;
            }
   
            this.setPageSizeToAll();
            window.scroll(0, 0);
          },
        });
      }});
    dialogRef.afterClosed().subscribe((res) => {
      addGetResponse.unsubscribe();
    });
  }
  // copy shifts
  copyDialog() {
    this.removeMessage();
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.id = this.modalComponent;
    dialogConfig.width = '380px';
    dialogConfig.height = '300px';
    dialogConfig.data = {
      // partNoList:this.partNoListAdd,
      dockList: this.dockList,
      // business_entity: this.namcValue,
      // shift_id:2192,
      mode: 'copy',
    };
    console.log(dialogConfig.data, 'copy_1436')
    const dialogRef = this.dialog.open(CopyShiftsDialogComponent, dialogConfig);
    console.log(dialogRef.componentInstance.addCopyShiftsEventEmitter,'componentInstance');
    const addGetResponse =
      dialogRef.componentInstance?.addCopyShiftsEventEmitter?.subscribe({error: this.errorCallback,
        next:(res) => {
        console.log("res---",res);
       
        const data1 = {
          business_entity: this.namcValue,
          // business_entity: "02",
          workid: this.user,
          user_role: this.userRole,
          userId: this.userName,
          toDock: res.copyToNumber,
          fromDock: res.copyFrom,
          // shift_no: res.shiftNumber,
          // plant: '8',
        }
        this.spinner.show();
        this.ospService?.copyShift(data1)?.subscribe({
          error: this.errorCallback,
          next: (response) => {
            this.spinner.hide();
            const updatedRows1  =  true;
            const updatedRows  = response.body.copyShiftResponse.response;
            if (updatedRows1) {
              this.selectedDock = {dock:res.copyToNumber}
              this.onSearch(0);
              this.successMessage = updatedRows;
              this.osPartsGrid.gridOptions.api.setRowData(this.rowData);
              // this.osPartsGrid
            } else {
              this.warningMessage = `${warningMessage.reportsNotUpdated}  ${this.itContact}.`;
              this.rowData = [];
              this.osPartsGrid.gridOptions.api.setRowData(this.rowData);
              this.spinner.hide();
            }
            this.setPageSizeToAll();
            window.scroll(0, 0);
          },
        });
      }});
    dialogRef.afterClosed().subscribe((res) => {
      addGetResponse.unsubscribe();
    });
  }
  //delete Api
  deleteButton(field: any): void {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.id = this.modalComponent;
    dialogConfig.width = '460px';
    dialogConfig.height = '200px';
    dialogConfig.data = {
      mode: 'Shifts',
    };
    const data1 = {
      offset: 0,
      limit: 1200,
      business_entity: this.namcValue,
      workid: this.user,
      user_role: this.userRole,
      shift_id: field?.shift_id,
    };
    const dialogRef = this.dialog.open(DeleteBreaksComponent, dialogConfig)
    dialogRef.afterClosed().subscribe((res) => {
      if (res === 'cancel') {
        return;
      } else if (res === 'save') {
        this.ospService.getDeleteShift(data1).subscribe({
          error: this.errorCallback,
          next: (data) => {
            if (data.body.table1DeletedCount >= 1 || data.body.table2DeletedCount >= 1) {
              this.onSearch(0);
              this.successMessage = successMessage.deleteRecord;
              this.spinner.hide();
              window.scroll(0, 0);
            } else {
              this.warningMessage = `${warningMessage.apiLogicFail}  ${this.itContact}.`;
              this.rowData = [];
              this.osPartsGrid.gridOptions.api.setRowData(this.rowData);
              this.spinner.hide();
            }
          },
        });
      }
    })
  }
  
}