import { AfterViewInit, ChangeDetectorRef, Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { DataServiceService } from 'src/app/services/air-freight-validation/data-service.service';
import { Location } from '@angular/common';
import { ScheduleDeleteComponent } from '../../schedule-delete/schedule-delete.component';
import { CustomHeaderComponent } from '../../custom-header/custom-header.component';
import { ActualArrivalEditableComponent } from '../../add-edit-air-record/actual-arrival-editable/actual-arrival-editable.component';
import { GriddataService } from 'src/app/services/griddata.service';
import { AirfreightApicallsService } from 'src/app/services/air-freight-validation/airfreight-apicalls.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { onKeyPress } from 'src/app/constants';

import { LookupService } from 'src/app/services/lookup/lookup.service';
import { Subscription, first, forkJoin } from 'rxjs';
import { Router } from '@angular/router';
import { ValidationService } from 'src/app/services/air-freight-validation/validations.service';
import { successMessage, warningMessage, roundUp } from 'src/app/constants';
import { AirorderDeleteComponent } from '../../delete-airorder/airorder-delete.component';
import {
  MatLegacyDialog as MatDialog,
  MatLegacyDialogConfig as MatDialogConfig,
} from '@angular/material/legacy-dialog';
import { EditActualArrivalEditableComponent } from '../../add-edit-air-record/edit-schedule-editable/edit-actual-arrival-editable.component';
import * as moment from 'moment';
import { EditDeadlineEditableComponent } from '../edit-schedule-editable/edit-deadline-editable.component ';
import { EditNoOfPiecesEditableComponent } from '../edit-schedule-editable/edit-no-of-pieces-editable.component';
import { AirfreightArrivedCheckboxComponent } from '../../airfreight-arrived-checkbox/airfreight-arrived-checkbox.component';
import { CaseNoEditableComponent } from '../actual-arrival-editable/case-no-editable.component';
import { EditVanCenterEtaEditableComponent } from '../edit-schedule-editable/edit-vancenter-etadate-editable.component';
import { EditNoOfLotsEditableComponent } from '../edit-schedule-editable/edit-no-of-lots-editable.component';
import { EditConfLotsEditableComponent } from '../edit-schedule-editable/edit-conf-lots-editable.component';
import { EditConfPiecesEditableComponent } from '../edit-schedule-editable/edit-conf-pieces-editable.component ';
import { group } from '@angular/animations';
import { OSPStateService } from 'src/app/services/osp-state.service';
import { ConfigParameterComponent } from '../config-parameter/config-parameter.component';
import { log } from 'console';

@Component({
  selector: 'app-edit-form',
  templateUrl: './edit-form.component.html',
  styleUrls: ['./edit-form.component.scss'],
})
export class EditFormComponent implements OnInit, AfterViewInit {
  @ViewChild('osPartsGrid') osPartsGrid;
  frameworkComponents = {
    scheduleDeleteComponent: ScheduleDeleteComponent,
    actualArrivalEditableComponent: ActualArrivalEditableComponent,
  };
  namcValue = localStorage.getItem('namcvalue');

  userForm: FormGroup;
  filteredData = [];
  @ViewChild('formTop', { static: false }) formTop!: ElementRef;
  uniquePartNumbers: string[] = [];
  originalPiecesToOrder = new Map<number, number>(); // To store original values of pieces_to_order
  originalLotsToOrder = new Map<number, number>();
  oldTotalOrder = new Map<number, number>();
  oldEstCost = new Map<number, number>();
  originalGridNumberOfPiecesToOrder = new Map<number, number>();
  originalQPC = new Map<number, number>();
  warningMessage = '';
  successMessage = '';
  airOrderNo: any;
  columnDefs;
  rowData: any = [];
  rowDatas: any = [];
  context: any;
  gridOptions = {} as any;
  kvcDetails: any = [];
  kvcDetails1: any = [];

  //addAirOrderKanban: { kanban: string }[] = [{ kanban: '0001' }, { kanban: '0002' }, { kanban: '0003' }];
  addAirOrderKanban: any = [];
  toOrder: { order_type: string }[];
  addAirOrderPart: any = [];
  //addAirOrderKanban: any;
  addAirOrderDestcode: any = [];
  addAirOrderDock: any = [];
  addAirReason: any = [];
  selectedDestCode: any = [];
  user: string;
  userRole: string;
  userName: string;
  itContact: string;
  addbudgetCodes: any = [];
  managerList: any = [];
  kvcList: any = [];
  airCaseCodesList: any = [];
  isExpanded: boolean[] = []; // Declare and initialize here
  isExpandedIndex: number | null = null; // Track the index of the currently expanded panel
  aircostKilo: any = [];
  isLotsSelected: boolean = true;
  globleqpc: any;
  managerDetails: any = [];
  manager_filter_id: any;
  managerDetails1: any;
  kvc_filter_id: any;
  adjustSubscription$: Subscription;
  isFirstLoad = true;
  calendarList: any = [];
  constructor(
    private fb: FormBuilder,
    private dataService: DataServiceService,
    private location: Location,
    private readonly gridDataService: GriddataService,
    private lookupservice: LookupService,
    private readonly airFreightService: AirfreightApicallsService,
    private readonly spinner: NgxSpinnerService,
    public dialog: MatDialog,
    private router: Router,
    private orderService: ValidationService,
    private readonly stateService: OSPStateService,
    private cdr: ChangeDetectorRef
  ) {
    this.userForm = this.fb.group({
      // reasonCode: [''],
      destCodeNo: [''],
      orderType: [''],
      responsibilityregion: [''],
      raisedBy: [''],
      raisedDT: [''],
      volumeSize: [''],
      budgetCode: [''],
      firstComment: [''],
      secondComment: [''],
      managerName: [''],
      kvcName: [''],
      users: this.fb.array([]),
    });
    this.context = {
      componentParent: this,
    };
  }
  ngAfterViewInit(): void {
    // this.spinner.hide();
  }
  ngOnDestroy(): void {
    this.adjustSubscription$.unsubscribe();
  }
  ngOnInit(): void {
    this.spinner.show();
    localStorage.setItem('isClearMsg', 'false');

    this.namcValue = localStorage.getItem('namcvalue');
    this.userName = localStorage.getItem('UserName');

    if (localStorage.getItem('workdayId')) {
      this.user = localStorage.getItem('workdayId');
    }
    if (localStorage.getItem('UserRoles')) {
      this.userRole = localStorage.getItem('UserRoles');
    }

    // Clear filteredData on component load to avoid old values
    this.filteredData = [];

    this.setColumnstable();

    this.itContact = localStorage.getItem('itContact');

    this.callApiForEdit();

    // data set in to fields
    this.dataService.filteredData$.subscribe((orderData) => {
      if (orderData) {
        console.log(orderData);
        // Reset filteredData when new data is received
        this.filteredData = orderData;
        console.log(this.filteredData, 'f-6');

        this.airOrderNo = this.filteredData[0]?.air_order_no;

        // Extract unique part numbers after data is received
        this.uniquePartNumbers = Array.from(new Set(this.filteredData.map((item) => item.part_no)));

        // Initialize fields like reasonCode, dockNo

        // Set row data for the grid
        // this.setRowsForSchedule(); // Initialize rows based on filteredData
      }
      this.initializeUsersForm();
      this.initializeOtherFields();
      this.setRowData();
    });

    this.isExpanded[0] = true;

    this.adjustSubscription$ = this.stateService.getNamc().subscribe((observable) => {
      if (!this.isFirstLoad) {
        this.router.navigate(['ordering/airfreightorder']);
      }

      this.isFirstLoad = false; // Set to false after the first load
    });
  }

  // Initialize form array based on filteredData
  initializeUsersForm() {
    console.log(this.filteredData, 'f-1');
    //this.spinner.show();
    const usersArray = this.userForm.get('users') as FormArray;

    // Clear any existing controls in the array
    usersArray.clear();

    // Create a form group for each record in filteredData
    this.filteredData.forEach((user, index) => {
      console.log(this.airCaseCodesList, 'temp_');
      console.log(user.part_type, 'temp_');

      usersArray.push(
        this.fb.group({
          partNo: [user.part_no],
          kanbanNo: [user.kanban],
          reasonCode: [user.reason],
          piecesToOrder: [user.pieces_to_order],
          lotsToOrder: [user.lots_to_order],
          noOfPieces: [user.number_of_pieces],
          noOfLots: [user.number_of_lots],
          arrivalDate: [user.arrival_date],
          deadlineDate: [user.deadline_date],
          etaDate: [user.eta_date],
          confLots: [user.conf_lots],
          confPieces: [user.conf_pieces],
          dateRaised: [user.date_raised],
          dockNo: [user.dock],
          partType: [user.part_type],
          qpcBox: [user.order_lot],
          kvcConf: [user.kvc_conf],
          // airCase: [user.air_case_code],
          airCase: { air_case_code: user.air_case_code },
          boxWeight: [user.box_weight],
          partDesc: [user.part_desc],
          costCalc: [user.cost_calc],
          totalOrder: [user.total_order],
          estCost: [user.est_cost],

          // reasonCode: [user.reason, Validators.required],
          // destCodeNo: [user.dest_code, Validators.required],
        })
      );

      // Store the original pieces_to_order value
      this.originalPiecesToOrder.set(index, user.pieces_to_order);
      this.originalLotsToOrder.set(index, user.lots_to_order);
      this.oldTotalOrder.set(index, user.total_order);
      this.oldEstCost.set(index, user.est_cost);

      // this.originalGridNumberOfPiecesToOrder.set(index, user.number_of_pieces);
      this.originalQPC.set(index, user.order_lot);
    });

    // Now update each form group with the correct data for the corresponding user
    this.filteredData.forEach((user, index) => {
      console.log(user, 'u-1');
      const userGroup = usersArray.at(index) as FormGroup;

      userGroup.patchValue({
        partNo: { item_id: user.part_no },
        kanbanNo: { kanban: user.kanban },
        reasonCode: { reason_code: user.reason },
        piecesToOrder: user.pieces_to_order,
        lotsToOrder: user.lots_to_order,
        gridPieces: user.number_of_pieces,
        arrivalDate: user.arrival_date,
        confLots: user.conf_lots,
        deadlineDate: user.deadline_date,
        etaDate: user.eta_date,
        noOfPieces: user.number_of_pieces,
        noOfLots: user.number_of_lots,
        dateRaised: user.date_raised,
        dockNo: { dock: user.dock },
        partType: user.part_type?.trim(),
        qpcBox: user.order_lot,
        kvcConf: user.kvc_conf?.trim(),
        airCase: { air_case_code: user.air_case_code?.trim() },
        boxWeight: user.box_weight,
        partDesc: user.part_desc?.trim(),
        costCalc: user.cost_calc?.trim(),
        totalOrder: user.total_order,
        estCost: user.est_cost,

        // destCodeNo: { dest_code: user.dest_code },
      });
      // Disable the partNo dropdown (FormControl) after setting its value
      userGroup.get('partNo')?.disable();
      userGroup.get('kanbanNo')?.disable();
      userGroup.get('dockNo')?.disable();

      userGroup.get('qpcBox')?.disable();
      userGroup.get('partDesc')?.disable();

      userGroup.get('totalOrder')?.disable();
      userGroup.get('estCost')?.disable();
      // userGroup.get('costCalc')?.disable();
      // userGroup.get('kvcConf')?.disable();
      // userGroup.get('partType')?.disable();
      console.log('part type', user.part_type);
      console.log('end n-1');
      console.log(this.filteredData.length, index + 1, 'call_temp');
      if (this.filteredData.length == index + 1) {
        setTimeout(() => {
          this.spinner.hide();
        }, 2000);
      }
    });
  }

  get users() {
    return this.userForm.get('users') as FormArray;
  }

  // In your component.ts
  getKanbanValue(i: number) {
    const kanbanNo = this.users.at(i).get('kanbanNo')?.value; // Get the value of the kanbanNo control
    return this.addAirOrderKanban.find((k) => k.kanban === kanbanNo);
  }

  onDeletePart(index: number): void {
    const usersArray = this.userForm.get('users') as FormArray;
    if (usersArray.length === 1) {
      this.warningMessage = 'Air order having only one part, cannot be deleted.';
      return; // Prevent further action if only one part is left
    }

    const exportData = [];
    // exportData.push(data);
    exportData.push({ ...usersArray, isParts: true });

    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.id = 'modal-component';
    dialogConfig.height = '204px';
    dialogConfig.width = '470px';
    dialogConfig.data = exportData;

    const dialogRef = this.dialog.open(AirorderDeleteComponent, dialogConfig);

    dialogRef.afterClosed().subscribe((res) => {
      if (res === 'cancel') {
        return;
      } else if (res === 'save') {
        // Remove the form group (part) from the form array
        const formValue = this.userForm.getRawValue(); // This will include disabled controls like partNo
        var updatedData = [];
        var updatedFields = {
          business_entity: this.namcValue,
          air_order_no: this.filteredData[0].air_order_no,
          dock: formValue.users[index].dockNo.dock,
          kanban: formValue.users[index].kanbanNo.kanban,
          dest_code: this.filteredData[0].dest_code,
          part_no: formValue.users[index].partNo.item_id,
          arrival_date: formValue.users[index].arrivalDate,
          eta_date: formValue.users[index].etaDate,
          number_of_lots: formValue.users[index].number_of_lots,
        };
        updatedData.push(updatedFields);

        const data = {
          offset: 0,
          limit: 1200,
          business_entity: this.namcValue,
          workid: this.user,
          user_role: this.userRole,
          data: updatedData,
        };
        // Get the values at that index (the form group values)
        usersArray.removeAt(index);
        this.airFreightService.getDeleteAirorder(data).subscribe({
          error: this.errorCallback,
          next: (data) => {
            if (data.body.data.table1DeletedCount >= 1 || data.body.data.table2DeletedCount >= 1) {
              this.successMessage = successMessage.deleteRecord;
              //this.spinner.hide();
              window.scroll(0, 0);
            } else {
              this.warningMessage = `${warningMessage.apiLogicFail}  ${this.itContact}.`;
              //this.spinner.hide();
            }
          },
        });

        // You may also want to remove the part number from the uniquePartNumbers array
        // If you want to remove it from the UI completely
        this.uniquePartNumbers.splice(index, 1);
      }
    });
  }

  goBack() {
    localStorage.setItem('isClearMsg', 'true');
    this.location.back();
  }

  // saveOrder() {

  //   if (this.userForm.valid) {
  //     console.log('valid....');
  //     //  const formValue = this.userForm.value;
  //     // Use getRawValue() to include disabled controls in the form value
  //     const formValue = this.userForm.getRawValue(); // This will include disabled controls like partNo

  //     // Create a payload to send only updated piecesToOrder
  //     const payload = formValue.users
  //       .map((user, index) => {
  //         const originalPieces = this.originalPiecesToOrder.get(index);
  //         const originalQPC = this.originalQPC.get(index);
  //         const newPieces = parseInt(user.piecesToOrder, 10);
  //         const newQPC = parseInt(user.qpcBox, 10);

  //         // Compare the old and new piecesToOrder values
  //         if (originalPieces !== newPieces) {
  //           return {
  //             part_no: user.partNo.item_id, // Access partNo even though it's disabled
  //             kanbanNo: user.kanbanNo.kanban, // assuming kanbanNo is an object with a 'kanban' property
  //             airCaseCodes:user.airCase.air_case_code,
  //             old_pieces_to_order: originalPieces,
  //             new_pieces_to_order: newPieces,
  //             old_order_lot: originalQPC,
  //             new_order_lot: newQPC,
  //             air_order_no: this.filteredData[0].air_order_no,
  //             business_entity: this.namcValue,
  //             userid: this.userName,
  //             workid: this.user,
  //             user_role: this.userRole,
  //           };
  //         } else {
  //           // If no change, return null or undefined
  //           return null;
  //         }
  //       })
  //       .filter(Boolean); // Remove any null or undefined entries from the array

  //     // If there are any changes to submit
  //     if (payload.length > 0) {
  //       // Send the constructed payload to the API
  //       // this.airFreightService.editAirOrderDetails(payload).subscribe(
  //       //   (responseData) => {
  //       //     this.orderService.setEditedData(responseData);
  //       //     this.router.navigate(['ordering/airfreightorder']);
  //       //   },
  //       //   (error) => {
  //       //     console.error('Error occurred while saving the order:', error);
  //       //   }
  //       // );
  //     } else {
  //       this.warningMessage = 'Please edit before proceeding.';
  //       this.scrollToTop();
  //       console.error('No changes to submit'); // Log if no changes were made
  //     }
  //   } else {
  //     alert('form invali...');
  //   }
  // }
  empty_valueCheck(value: any) {
    if (Number.isNaN(value) || value == undefined || value == '' || value == null) {
      return true;
    } else return false;
  }
  validate_peaceOfOrder(peaceofOrderr: number, requiredArrivalSchdulesArray: any, value_: number) {
    var error_msg = false;
    var schedulesNoOfPeace: number = 0;
    requiredArrivalSchdulesArray.forEach((value: any, index: number) => {
      // if (!this.empty_valueCheck(value.conf_lots) && value.conf_lots <= 0) {
      //   if (!error_msg) {
      //     this.warningMessage = "Conf lots can't be negative or 0 in row No." + (index + 1);
      //     error_msg = true;
      //   }
      // }
      console.log(value);
      if (value_ == 1 && !this.empty_valueCheck(value.conf_lots) && value.conf_lots <= 0) {
        if (!error_msg) {
          this.warningMessage = "Conf lots can't be negative or 0 in row No." + (index + 1);
          error_msg = true;
        }
      }
      if (value_ == 2 && !this.empty_valueCheck(value.conf_pieces) && value.conf_pieces <= 0) {
        if (!error_msg) {
          this.warningMessage = "Conf Pieces can't be negative or 0 in row No." + (index + 1);
          error_msg = true;
        }
      }
      // if ((!this.empty_valueCheck(value.conf_lots) && value.conf_lots > 0) && (value.etaDate.value == "Invalid date" || this.empty_valueCheck(value.etaDate.value))) {
      //   if (!error_msg) {
      //     this.warningMessage = 'Please Enter Van Center ETA in row No.' + (index + 1);
      //     error_msg = true;
      //   }
      // }

      if (value_ == 1) {
        if (
          (this.empty_valueCheck(value.conf_lots) || value.conf_lots <= 0) &&
          value.etaDate.value != 'Invalid date' &&
          !this.empty_valueCheck(value.etaDate.value)
        ) {
          if (!error_msg) {
            this.warningMessage = 'Conf lots must be enter when Van Center ETA date is filled in row No.' + (index + 1);
            error_msg = true;
          }
        }
      } else if (value_ == 2) {
        if (
          (this.empty_valueCheck(value.conf_pieces) || value.conf_pieces <= 0) &&
          value.etaDate.value != 'Invalid date' &&
          !this.empty_valueCheck(value.etaDate.value)
        ) {
          if (!error_msg) {
            // Lakshay = 7404355390 - 615341C
            this.warningMessage =
              'Conf Pieces must be enter when Van Center ETA date is filled in row No.' + (index + 1);
            error_msg = true;
          }
        }
      }
      if (value.etaDate.value != 'Invalid date' && !this.empty_valueCheck(value.etaDate.value)) {
        // const filteredData = this.calendarList.filter(
        //   (item) => new Date(item.date.split('T')[0]).getTime() == new Date(value.etaDate.value).getTime()
        // );
        const filteredData = this.calendarList
        if (filteredData[0]?.work_status == '0') {
          if (!error_msg) {
            this.warningMessage =
              'Please select another Van center date in row No.' + (index + 1) + '. It is non working day';
            error_msg = true;
          }
        }
      }
      // if (
      //   (this.empty_valueCheck(value.conf_lots) || value.conf_lots <= 0) &&
      //   value.etaDate.value != 'Invalid date' &&
      //   !this.empty_valueCheck(value.etaDate.svalue)
      // ) {
      //   if (!error_msg) {
      //     this.warningMessage = 'Please Enter conf lots in row No.' + (index + 1);
      //     error_msg = true;
      //   }
      // }

      if (
        value_ == 1 &&
        value.isArrived &&
        (this.empty_valueCheck(value.conf_lots) ||
          value.etaDate.value == 'Invalid date' ||
          this.empty_valueCheck(value.etaDate.value))
      ) {
        if (!error_msg) {
          this.warningMessage =
            'Arrived Flag can only be checked when ETA DAte and Conf lots filled in row No.' + (index + 1);
          error_msg = true;
        }
      }

      if (
        value_ == 2 &&
        value.isArrived &&
        (this.empty_valueCheck(value.conf_pieces) ||
          value.etaDate.value == 'Invalid date' ||
          this.empty_valueCheck(value.etaDate.value))
      ) {
        if (!error_msg) {
          // Lakshay = 7404355390 - 615341C
          this.warningMessage =
            'Arrived Flag can only be checked when ETA DAte and Conf pieces filled in row No.' + (index + 1);
          error_msg = true;
        }
      }

      if (value_ == 2) {
        if (this.empty_valueCheck(value.number_of_pieces) || parseInt(value.number_of_pieces) <= 0) {
          if (!error_msg) {
            this.warningMessage = 'Please enter Valid No of Pieces in row No.' + (index + 1);
            error_msg = true;
          }
        }
        schedulesNoOfPeace = schedulesNoOfPeace + parseInt(value.number_of_pieces);
      } else if (value_ == 1) {
        if (this.empty_valueCheck(value.number_of_lots) || parseInt(value.number_of_lots) <= 0) {
          if (!error_msg) {
            this.warningMessage = 'Please enter Valid No of Lots in row No.' + (index + 1);
            error_msg = true;
          }
        }
        schedulesNoOfPeace = schedulesNoOfPeace + parseInt(value.number_of_lots);
      }

      if (this.empty_valueCheck(value.arrival_date)) {
        if (!error_msg) {
          this.warningMessage = "Arrival date can't be empty in row No." + (index + 1);
          error_msg = true;
        }
      } else {
        // const filteredData = this.calendarList.filter(
        //   (item) => new Date(item.date.split('T')[0]).getTime() == new Date(value.arrivalDate.value).getTime()
        // );
        const filteredData = this.calendarList
        if (filteredData[0]?.work_status == '0') {
          if (!error_msg) {
            this.warningMessage =
              'Please select another arrival date in row No.' + (index + 1) + '. It is non working day';
            error_msg = true;
          }
        }
      }
      if (this.empty_valueCheck(value.deadline_date)) {
        if (!error_msg) {
          this.warningMessage = "Deadline date can't ne empty in row No." + (index + 1);
          error_msg = true;
        }
      }
      // else{
      //   const filteredData = this.calendarList.filter(item => new Date(item.date.split('T')[0]).getTime() == new Date(value.deadlineDate.value).getTime());
      //   if(filteredData.work_status == '0'){
      //     if (!error_msg) {
      //       this.warningMessage = "Please select another deadline date in row No." + (index + 1) + ". It is non working day";
      //       error_msg = true;
      //     }
      //   }
      // }

      if (new Date(value.arrival_date).getTime() > new Date(value.deadline_date).getTime()) {
        if (!error_msg) {
          this.warningMessage = 'Arrival date should be less than or equal to deadline data';
          error_msg = true;
        }
      }
    });
    if (error_msg) {
      this.scrollToTop();
      return false;
    }
    if (peaceofOrderr == schedulesNoOfPeace) return true;
    else {
      if (value_ == 1)
        //this.warningMessage = 'No. of Lots to order and sum of schdules\'s No. of Lots should be same.';
        this.warningMessage =
          ' Total no of lots from the required arrival schedule(s) must be equal to number of lots to order';
      else if (value_ == 2)
        //this.warningMessage = 'No. of Pieces to order and sum of schdules\'s No. of peaces should be same.';
        this.warningMessage =
          ' Total no of pieces from the required arrival schedule(s) must be equal to number of pieces to order';
      this.scrollToTop();
      return false;
    }
  }

  async saveOrder(i: any) {
    if (!this.userForm.valid) {
      console.info('Form is invalid...');
      return;
    }

    const formValue = this.userForm.getRawValue(); // Includes disabled controls

    // Construct the payload
    const payload = formValue.users
      .map((user, index) => this.constructPayload(user, index, formValue, i))
      .filter(Boolean); // Remove null/undefined entries
    console.log(payload[0].air_case_code);

    this.getMangerIdByNameAndRole();
    if (this.empty_valueCheck(payload[0].air_case_code)) {
      this.warningMessage = 'Air case code should not be empty';
      this.scrollToTop();
      return false;
    }
    await this.calendarApiCall();
    if (formValue.orderType.order_type == 'Lots') {
      if (!this.validate_peaceOfOrder(parseInt(payload[0].new_lots_to_order), this.rowDatas, 1)) return false;
    } else if (formValue.orderType.order_type == 'Pieces') {
      if (!this.validate_peaceOfOrder(parseInt(payload[0].new_pieces_to_order), this.rowDatas, 2)) return false;
    }

    if (payload.length > 0) {
      // Submit the payload to the API
      // this.submitChanges(payload);

      // Send the constructed payload to the API
      let payload1 = [];
      payload1.push(payload[0]);
      console.log(payload1);
      this.airFreightService.editAirOrderDetails(payload1).subscribe({
        error: this.errorCallback,
        next: (responseData) => {
          if (responseData) {
            this.orderService.setEditedData(responseData);
            localStorage.setItem('isAdd', 'false');
            this.router.navigate(['ordering/airfreightorder']);
          }
        },
      });
    } else {
      this.warningMessage = 'Please edit before proceeding.';
      this.scrollToTop();
      console.log('No changes to submit.');
    }
  }

  /**
   * Constructs the payload for a single user entry.
   */
  constructPayload(user, index, formValue, i) {
    console.log(user, 'user......');
    console.log('constructor payload called...', user, formValue);
    this.globleqpc = user.qpcBox;
    let newArrivalDate: any;
    let newPieces;
    let newLots;
    let newDeadlineDate;
    let newEtaDate;
    let newConfLots;
    let newConfPieces;

    // Assign values based on conditions later in the code
    // newPieces = this.rowDatas[index]?.noOfPieces?.params?.data?.number_of_pieces;
    // newLots = this.rowDatas[index]?.noOfLots?.params?.data?.number_of_lots;
    // newDeadlineDate = this.rowDatas[index]?.deadlineDate?.value;
    // newEtaDate = this.rowDatas[index]?.etaDate?.value;
    // newConfLots = this.row;

    const originalPieces = user.noOfPieces ? parseInt(user.noOfPieces, 10) : null;
    const originalLots = user.noOfLots ? parseInt(user.noOfLots, 10) : null;
    const originalArrivalDate = user.arrivalDate;
    const originalDeadlineDate = user.deadlineDate;
    const originalEtaDate = user.etaDate;
    const originaConfLots = user.confLots;
    const originalConfPieces = user.confPieces;

    // Form pieces and lots
    const oldformPiece = this.originalPiecesToOrder.get(index);
    const newformPiece = user.piecesToOrder ? parseInt(user.piecesToOrder, 10) : null;
    const oldformLots = this.originalLotsToOrder.get(index);
    const newformLots = user.lotsToOrder ? parseInt(user.lotsToOrder, 10) : null;

    const oldTotalOrder = this.oldTotalOrder.get(index);
    const newTotalOrder = user.totalOrder ? parseInt(user.totalOrder, 10) : null;
    const oldEstCost = this.oldEstCost.get(index);
    const newEstCost = user.estCost;

    console.log(this.rowDatas, 'Payload:-final row datas');
    // this.rowDatas.forEach((ele_data: any) => {
    //   console.log(ele_data.arrival_date);
    //   console.log(ele_data.deadline_date);
    //   console.log(ele_data.eta_date);
    //   console.log(ele_data.number_of_pieces);
    //   console.log(ele_data.number_of_lots);
    //   console.log(ele_data.conf_lots);
    //   console.log(ele_data.conf_pieces);

    //   newArrivalDate = ele_data.arrival_date;
    //   newDeadlineDate = ele_data.deadline_date;
    //   newEtaDate = ele_data.eta_date;
    //   newPieces = ele_data.number_of_pieces;
    //   newLots = ele_data.number_of_lots;
    //   newConfLots = ele_data.conf_lots;
    //   newConfPieces = ele_data.conf_pieces;
    //   // status:ele_data.isArrived
    // });

    const isPiecesChanged = originalPieces !== newPieces;
    const isArrivalDateChanged = this.isDateChanged(originalArrivalDate, newArrivalDate);
    const isDeadlineDateChanged = this.isDateChanged(originalDeadlineDate, newDeadlineDate);
    const isEtaDateChanged = this.isDateChanged(originalEtaDate, newEtaDate);
    const isLotsChanged = originalLots !== newLots;

    // Update newArrivalDate if no change detected
    if (!isArrivalDateChanged) {
      newArrivalDate = originalArrivalDate;
    }

    if (!isDeadlineDateChanged) {
      newDeadlineDate = originalDeadlineDate;
    }

    if (!isEtaDateChanged) {
      newEtaDate = originalEtaDate;
    }

    if (!isPiecesChanged) {
      newPieces = originalPieces;
    }

    if (!isLotsChanged) {
      newLots = originalLots;
    }

    // Ensure payload creation proceeds if either `piecesToOrder` or `lotsToOrder` is provided
    if (
      newformPiece !== null ||
      newformLots !== null ||
      isPiecesChanged ||
      isArrivalDateChanged ||
      isDeadlineDateChanged ||
      isEtaDateChanged ||
      isLotsChanged
    ) {
      //const status = this.rowData[0].isArrived ? 'CONFIRMED' : 'IN-TRANSIT';

      var schedullers = [];
      const kvc_conf = user.kvcConf;
      this.rowDatas.forEach((value: any, index: any) => {
        console.log(value);
        var obj = {
          arrival_date: value.arrival_date,
          deadline_date: value.deadline_date,
          eta_date: this.formatDate(value.etaDate.value),
          number_of_pieces: this.handleEmptyValue(value.number_of_pieces),
          number_of_lots: value.number_of_lots,
          conf_lots: this.handleEmptyValue(value.conf_lots),
          conf_pieces: this.handleEmptyValue(value.conf_pieces),
          case_no: this.handleEmptyValue(value.case_no),
          air_order_status: value.isArrived ? 'ARRIVED' : kvc_conf === 'Yes' ? 'CONFIRMED' : 'CREATED',
        };

        console.log(this.isLotsSelected);
        console.log(obj.number_of_pieces);
        console.log(this.globleqpc);
        console.log(obj.number_of_lots);

        if (this.isLotsSelected) {
          obj.number_of_pieces = obj.number_of_lots * this.globleqpc;
        } else {
          obj.number_of_lots = obj.number_of_pieces / this.globleqpc;
        }

        schedullers.push(obj);
      });

      return {
        part_no: user.partNo?.item_id,
        reason: user.reasonCode?.reason_code,
        air_case_code: user.airCase?.air_case_code,
        // manager_id: formValue.managerName?.contact_name ?? null,
        manager_id: this.manager_filter_id ? this.manager_filter_id : null,
        kvc_contact_id: this.kvc_filter_id ? this.kvc_filter_id : null,
        kanbanNo: user.kanbanNo?.kanban,
        old_order_lot: this.originalQPC.get(index),
        new_order_lot: parseInt(user.qpcBox, 10),
        air_order_no: this.filteredData[0]?.air_order_no,
        new_pieces_to_order: this.handleEmptyValue(newformPiece),
        old_pieces_to_order: this.handleEmptyValue(oldformPiece),
        old_lots_to_order: this.handleEmptyValue(oldformLots),
        new_lots_to_order: this.handleEmptyValue(newformLots),
        old_total_order: oldTotalOrder,
        new_total_order: newTotalOrder,
        old_est_cost: oldEstCost,
        new_est_cost: newEstCost,
        business_entity: this.namcValue,
        userid: this.userName,
        workid: this.user,
        user_role: this.userRole,
        schedule: schedullers,
        kvc_conf: user.kvcConf,
        part_type: user.partType,
        cost_calc: user.costCalc,
        resp: formValue.responsibilityregion,
        volume: formValue.volumeSize,
        buco_code: formValue.budgetCode?.budget_code ?? '',
        comment1: formValue.firstComment ?? '',
        comment2: formValue.secondComment ?? '',
        box_weight: this.handleEmptyValue(user.boxWeight),
        //globleqpc: parseInt(user.qpcBox, 10),
      };
    }

    return null; // No changes detected or neither piecesToOrder nor lotsToOrder provided
  }

  /**
   * Checks if two dates are different.
   */
  isDateChanged(originalDate, newDate) {
    const originalDateValue = originalDate ? moment(originalDate).format('MM/DD/YYYY') : '';
    const newDateValue = newDate ? moment(newDate).format('MM/DD/YYYY') : '';
    return originalDateValue !== newDateValue;
  }

  /**
   * Creates a schedule object with old and new values.
   */
  /**
   * Creates a schedule object with old and new values.
   */
  // createSchedule(
  //   originalArrivalDate,
  //   newArrivalDate,
  //   originalDeadlineDate,
  //   newDeadlineDate,
  //   originalEtaDate,
  //   newEtaDate,
  //   originalPieces,
  //   newPieces,
  //   originalLots,
  //   newLots,
  //   originaConfLots,
  //   newConfLots,
  //   originalConfPieces,
  //   newConfPieces,
  //   status
  // ) {

  //   return [
  //     {
  //       old_arrival_date: this.formatDate(originalArrivalDate),
  //       new_arrival_date: this.formatDate(newArrivalDate),
  //       old_deadline_date: this.formatDate(originalDeadlineDate),
  //       new_deadline_date: this.formatDate(newDeadlineDate),
  //       old_eta_date: this.formatDate(originalEtaDate),
  //       new_eta_date: this.formatDate(newEtaDate),
  //       old_number_of_pieces: this.handleEmptyValue(originalPieces),
  //       // new_number_of_pieces: this.handleEmptyValue(newPieces),
  //       newlots:newLots,
  //       newPieces:newPieces,
  //       new_number_of_pieces: (newLots == null || newLots === '' || newLots === undefined)
  //         ? newPieces  // If newLots is empty or not provided, use newPieces
  //         : newLots * this.globleqpc,
  //       new_number_of_lots: (newPieces == null || newPieces === '' || newPieces === undefined)
  //         ? newLots  // If newLots is empty or not provided, use newPieces
  //         : newPieces / this.globleqpc,
  //       old_number_of_lots: this.handleEmptyValue(originalLots),
  //       // new_number_of_lots: this.handleEmptyValue(newLots),
  //       old_conf_lots: this.handleEmptyValue(originaConfLots),
  //       new_conf_lots: this.handleEmptyValue(newConfLots),
  //       old_conf_pieces: this.handleEmptyValue(originalConfPieces),
  //       new_conf_pieces: this.handleEmptyValue(newConfPieces),
  //       status,
  //     },
  //   ];
  // }

  /**
   * Handles empty values and returns null if the value is undefined, null, or empty.
   */
  handleEmptyValue(value) {
    return value === undefined || value === null || value === '' ? null : value;
  }

  /**
   * Formats a date to MM/DD/YYYY or returns an empty string if the date is null/undefined.
   */
  formatDate(date) {
    console.log(date);
    return date && date !== '' && moment(date).isValid() ? moment(date).format('MM/DD/YYYY') : null;
  }

  setColumnstable() {
    this.columnDefs = [
      {
        headerName: '', // New column for actions (Delete button)
        field: 'actions',
        cellRendererFramework: ScheduleDeleteComponent, // Renders the delete button
        width: 100, // Adjust the width as needed
        sortable: false,
      },
      {
        headerComponentFramework: CustomHeaderComponent,
        headerName: 'Arrival Date',
        field: 'arrivalDate',
        sortable: false,
        flex: 1,
        cellRendererFramework: EditActualArrivalEditableComponent,

        cellRendererParams: { column: 'arrival_date' },
      },
      {
        headerComponentFramework: CustomHeaderComponent,
        headerName: 'Deadline Date',

        field: 'deadlineDate',
        sortable: false,
        flex: 1,
        cellRendererFramework: EditDeadlineEditableComponent,
        cellRendererParams: { column: 'deadline_date' },
      },
      {
        headerComponentFramework: CustomHeaderComponent,
        headerName: 'No.of Pieces',
        field: 'noOfPieces',
        sortable: false,
        flex: 1,
        cellRendererFramework: EditNoOfPiecesEditableComponent,
        cellRendererParams: { column: 'number_of_pieces' },
        hide: !this.isLotsSelected,
      },
      {
        headerComponentFramework: CustomHeaderComponent,
        headerName: 'No of Lots',
        field: 'noOfLots',
        sortable: false,
        flex: 1,
        cellRendererFramework: EditNoOfLotsEditableComponent,
        cellRendererParams: { column: 'number_of_lots' },
        hide: this.isLotsSelected,
      },
      {
        headerName: 'Conf Lots',
        field: 'configureLots',
        flex: 1,

        sortable: false,
        cellRendererFramework: EditConfLotsEditableComponent,
        cellRendererParams: { column: 'conf_lots' },
        hide: !this.isLotsSelected,
      },
      {
        headerName: 'Conf Pieces',
        field: 'configurePieces',
        flex: 1,
        sortable: false,
        cellRendererFramework: EditConfPiecesEditableComponent,
        cellRendererParams: { column: 'conf_pieces' },
        hide: this.isLotsSelected,
      },

      {
        // headerComponentFramework: CustomHeaderComponent,
        headerName: 'Van Center ETA DT',
        field: 'etaDate',
        sortable: false,
        flex: 1,
        cellRendererFramework: EditVanCenterEtaEditableComponent,
        cellRendererParams: { column: 'eta_date' },
      },

      {
        headerName: 'Renban',
        field: 'casenoEditable',
        sortable: false,
        flex: 1,
        cellRendererFramework: CaseNoEditableComponent,
        cellRendererParams: { column: 'case_no' },
      },
      {
        headerName: 'Arrived?',
        field: 'ArrivedChecked',
        flex: 1,
        tooltipField: 'Arrived?',
        cellRendererFramework: AirfreightArrivedCheckboxComponent,
        cellRendererParams: { column: 'isArrived' },
        sortable: false,
      },
      //AirfreightArrivedCheckboxComponent
    ];

    this.gridOptions = this.gridDataService.getGridOptions();
    this.gridOptions.onGridReady = function (params) {
      this.gridApi = params.api;
      this.gridApi.setColumnDefs(this.columnDefs);
    };

    this.getGridOptions();
  }

  getGridOptions() {
    this.gridOptions = {} as any;

    this.gridOptions.headerHeight = 38;

    this.gridOptions.rowHeight = 28;
    this.gridOptions.floatingFiltersHeight = 0;

    this.gridOptions.rowBuffer = 20;
    this.gridOptions.suppressMovableColumns = true;

    this.gridOptions.enableCellTextSelection = true;
    this.gridOptions.suppressContextMenu = false;
    this.gridOptions.suppressClipboardPaste = true;

    this.gridOptions.defaultColDef = {
      sortable: true,
      suppressMenu: true,
      filter: false,
      floatingFilter: false,

      wrapText: true,
      autoHeight: true,

      suppressPaste: false,

      floatingFilterComponentParams: { suppressFilterButton: true },
      unSortIcon: true,
      icons: {
        sortUnSort: '<img src="../../../assets/images/Rectangle8.svg">',
      },
      filterParams: {
        textCustomComparator(filter, value, filterText) {
          const filterTextLoweCase = filterText.toLowerCase();

          let valueLowerCase = '';
          if (value.params) {
            valueLowerCase = value.value.toString().toLowerCase();
          } else {
            valueLowerCase = value.toString().toLowerCase();
          }
          function contains(target, lookingFor) {
            if (target === null) {
              return false;
            }
            return target.indexOf(lookingFor) >= 0;
          }
          return contains(valueLowerCase, filterTextLoweCase);
        },

        // Debounce time - Delay before Floating filter search
        debounceMs: 1000,

        caseSensitive: true,
        suppressAndOrCondition: true,
        inRangeInclusive: true,
      },
    };

    return this.gridOptions;
  }

  // setRowsForSchedule() {
  //   // Loop through filteredData and create empty rows
  //   console.log(this.filteredData,this.filteredData.length,'filterdataschedule')
  //   for (let i = 0; i < this.filteredData.length; i++) {
  //     const newRow = {
  //      arriving_date:''
  //     };

  //     // Push the empty row to rowData
  //     this.rowData.push(newRow);
  //   }

  //   // Update the grid with the new rows
  //   //this.gridOptions.api.setRowData(this.rowData);

  //   console.log('Rows added:', this.rowData);
  // }

  // setRowData() {
  //   console.log(this.filteredData,'schduledata')
  //   this.rowData = [
  //     {
  //       actions: '',
  //       arriving_date: this.filteredData[0]?.arrival_date,
  //     },
  //   ];
  // }

  setRowData() {
    console.log('n-3');
    //this.spinner.show();
    // Group the data by part_no
    const groupedData = this.filteredData.reduce((acc, item) => {
      const partKey = item.part_no; // Assuming 'part_no' uniquely identifies a part
      if (!acc[partKey]) {
        acc[partKey] = {
          ...item,
          dates: [], // Initialize dates array
        };
      }
      // Add date details to the dates array
      acc[partKey].dates.push({
        arrival_date: item.arrival_date,
        date_raised: item.date_raised,
        date_time: item.date_time,
        eta_date: item.eta_date,
        deadline_date: item.deadline_date,
        lots_to_order: item.lots_to_order,
        number_of_lots: parseInt(item.number_of_lots),
        number_of_pieces: item?.number_of_pieces,
        conf_lots: parseInt(item?.conf_lots),
        conf_pieces: parseInt(item?.conf_pieces),
        pieces_to_order: item?.pieces_to_order,
        case_no: item.case_no,
        isArrived: item.air_order_status == 'ARRIVED' ? true : false,
      });

      return acc;
    }, {}); // Properly initialize the accumulator as an empty object

    // Convert groupedData into an array of parts
    const transformedData = Object.values(groupedData);

    // Create rows for each part
    transformedData.forEach((element: any) => {
      this.rowDatas = element.dates;

      //   const rows = element.dates.map((dateItem) => ({
      //     actions: '', // Actions field
      //     arriving_date: dateItem?.arrival_date,
      //     eta_date: dateItem?.eta_date,
      //     deadline_date: dateItem?.deadline_date,
      //     pieces_to_order: dateItem?.pieces_to_order,
      //     lots_to_order: dateItem?.lots_to_order,
      //     air_order_no: element?.air_order_no, // Part-level details
      //     part_num: element?.part_no,
      //     case_no: element?.case_no,
      //     number_of_lots: dateItem?.number_of_lots,
      //     number_of_pieces: dateItem?.number_of_pieces,
      //     conf_lots: dateItem?.conf_lots,
      //     conf_pieces: dateItem?.conf_pieces,
      //   }));
      //   return { ...element, rows };

      // setTimeout(() => {
      //this.spinner.hide();
      // }, 9000);
    });

    ////this.spinner.hide();
  }

  callApiForEdit() {
    
    const addApiSubcriptions = [
      this.lookupservice.getPartNumber(),
      this.lookupservice.getKanban(),
      this.lookupservice.getDestCode(),
      this.lookupservice.getDock(),
      this.airFreightService.getReason(),
      this.airFreightService.getBudgetCodes(),
      this.airFreightService.getCreatedManagerDetails(),
      this.airFreightService.getCreatedKvcDetails(),
      this.airFreightService.getAirCaseCodes(),
      this.airFreightService.getAirCostData(),
      this.airFreightService.getManagerDetails(),
      this.airFreightService.getKvcDetails(),
      // this.airFreightService.getCalendar(data),
    ];

    forkJoin(addApiSubcriptions).subscribe({
      error: this.errorCallback,
      next: (res) => {
        // this.addAirOrderPart.push(...res[0].body.data);
        const partList = res[0].body.data;
        const kanbnaList = res[1].body.data;
        const destCodes = res[2].body.data;
        const dockList = res[3].body.data;
        const reasonList = res[4].body.data;
        const budgetCodes = res[5].body.data;
        const managerDetails = res[6].body.data;
        const kvcDetails = res[7].body.data;
        const airCaseCodes = res[8].body.data;
        const airCost = res[9].body.data;
        const managerDetails1 = res[10].body.data;
        const kvcDetails1 = res[11].body.data;
        // const calendar = res[12].body.data;

        this.addAirOrderPart = partList;
        this.addAirOrderDestcode = destCodes;
        this.addAirOrderDock = dockList;
        this.addAirReason = reasonList;
        this.addAirOrderKanban = kanbnaList;
        this.addbudgetCodes = budgetCodes;
        // this.managerDetails = managerDetails;
        this.managerList = managerDetails;
        this.aircostKilo = airCost;
        this.managerDetails1 = managerDetails1;
        this.kvcDetails1 = kvcDetails1;
        // this.calendarList = calendar;

        this.kvcList = kvcDetails;
        const airCaseCodesWithKey = airCaseCodes.map((item) => ({ air_case_code: item.air_case_code }));

        console.log(airCaseCodesWithKey);
        this.airCaseCodesList = airCaseCodesWithKey;
        console.log(this.airCaseCodesList, 'v-1');
        this.airCaseCodesList = this.airCaseCodesList.filter(
          (value, index, self) => index === self.findIndex((t) => t.air_case_code === value.air_case_code)
        );

        // if (this.addAirOrderDestcode.length === 1) {
        //   // Set the entire object to the form control, not just the dest_code
        //   this.selectedDestCode = this.addAirOrderDestcode[0]; // The object to be selected

        //   //// this.addOrEditform.controls.destCode.setValue(this.selectedDestCode);
        // }

        if (this.filteredData) {
          let kanbanResult = this.filteredData.map((record) => ({ kanban: record.kanban }));

          //this.addOrEditform.controls.kanbanNo.setValue(kanbanResult[0]);
        }
        //this.spinner.hide();
      },
    });

    this.toOrder = [
      {
        order_type: 'Lots',
      },
      {
        order_type: 'Pieces',
      },
    ];
  }

  errorCallback = (error) => {
    console.error(error);
    //this.spinner.hide();

    this.warningMessage = 'Something went wrong! Please contact support team.';
    // this.rowData = [];
  };

  initializeOtherFields() {
    //this.spinner.show();
    if (this.filteredData.length > 0) {
      const firstUser = this.filteredData[0]; // Assuming the first user has the common data for these fields

      if (firstUser.order_type == 'Lots') {
        this.isLotsSelected = true;
        this.updateColumnVisibility();
      } else {
        this.isLotsSelected = false;
        this.updateColumnVisibility();
      }

      this.userForm.patchValue({
        // Assuming the reason field is in the filtered data
        destCodeNo: { dest_code: firstUser.dest_code }, // Same for dockNo
        orderType: { order_type: firstUser.order_type },
        responsibilityregion: firstUser.resp,
        raisedBy: firstUser.userid?.trim(),
        volumeSize: firstUser.volume?.trim(),
        budgetCode: { budget_code: firstUser.buco_code },
        // budgetCode: { budget_code:'QB000'},
        firstComment: firstUser.comment1,
        secondComment: firstUser.comment2,
        kvcName: { contact_name: firstUser?.kvc?.trim() },
        managerName: { contact_name: firstUser?.manager_name?.trim() },

        //raisedDT:firstUser.date_raised

        raisedDT: moment(firstUser.date_raised).format('YYYY-MM-DD'),
      });

      this.userForm.get('destCodeNo')?.disable();

      this.userForm.get('orderType')?.disable();
      this.userForm.get('raisedBy')?.disable();
      this.userForm.get('raisedDT')?.disable();
      // this.userForm.get('budgetCode')?.disable();
      // this.userForm.get('firstComment')?.disable();
      // this.userForm.get('secondComment')?.disable();
      // this.userForm.get('responsibilityregion')?.disable();
      // this.userForm.get('volumeSize')?.disable();
      // this.userForm.get('managerName')?.disable();
      // this.userForm.get('kvcName')?.disable();
      console.log('end n-2');
      //this.spinner.hide();
    }
  }
  removeMessage() {
    this.warningMessage = '';
    this.successMessage = '';
  }

  deleteSchedule(data) {
    if (this.rowDatas.length == 1) {
      this.warningMessage = 'Part having only one Schedule, cannot be deleted.';
      this.scrollToTop();
      return; // Exit the function without deleting
    }
    const exportData = [];
    exportData.push(data);
    console.log('delet schedule');
    exportData.push({ ...data, isSchedule: true });

    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.id = 'modal-component';
    dialogConfig.height = '204px';
    dialogConfig.width = '470px';
    dialogConfig.data = exportData;

    const dialogRef = this.dialog.open(AirorderDeleteComponent, dialogConfig);

    dialogRef.afterClosed().subscribe((res) => {
      if (res === 'cancel') {
        return;
      } else if (res === 'save') {
        //  let rowData2 = this.rowData.filter(value => value !== data);
        if (data >= 0 && data < this.rowDatas.length) {
          // Remove the row at rowNodeIndex
          this.rowDatas.splice(data, 1);
          this.gridOptions.api.setRowData(this.rowDatas);
        }
      }
    });
  }

  // deleteSchedule(data) {
  //   console.log('inside dlete schedule', data);
  //   const exportData = [];
  //   exportData.push(data);
  //   if (this.rowData.length === 1) {
  //     this.warningMessage = 'only one arrival schedule can not delete';
  //     window.scroll(0, 0);

  //     return;
  //   }

  //   exportData.push({ ...data, isSchedule: true });

  //   const dialogConfig = new MatDialogConfig();
  //   dialogConfig.disableClose = true;
  //   dialogConfig.id = 'modal-component';
  //   dialogConfig.height = '204px';
  //   dialogConfig.width = '470px';
  //   dialogConfig.data = exportData;

  //   const dialogRef = this.dialog.open(AirorderDeleteComponent, dialogConfig);

  //   dialogRef.afterClosed().subscribe((res) => {
  //     if (res === 'cancel') {
  //       return;
  //     } else if (res === 'save') {
  //       console.log('indise save delete ', data);
  //       // console.log(this.filteredData[0]);
  //       console.log(this.rowData, '+++++++++++++++++++++++');
  //       console.log(this.rowData[data]);

  //       console.log(this.rowData);
  //       //  let rowData2 = this.rowData.filter(value => value !== data);
  //       if (data >= 0 && data < this.rowData.length && this.rowData.length != 1) {
  //         // Remove the row at rowNodeIndex
  //         var updatedData = [];
  //         var updatedFields = {
  //           business_entity: this.namcValue,
  //           air_order_no: this.filteredData[0].air_order_no,
  //           arrival_date: this.rowData[data]?.arriving_date,
  //           case_no: this.rowData[data]?.case_no,
  //           part_no: this.rowData[data]?.part_num,
  //           conf_lots: this.filteredData[0].conf_lots,
  //           number_of_lots: this.rowData[data]?.number_of_lots,
  //           deadline_date: this.rowData[data]?.deadline_date,
  //           eta_date: this.rowData[data]?.eta_date,
  //           no_of_pieces: this.rowData[data]?.number_of_pieces,
  //         };
  //         updatedData.push(updatedFields);

  //         const data2 = {
  //           offset: 0,
  //           limit: 1200,
  //           business_entity: this.namcValue,
  //           workid: this.user,
  //           user_role: this.userRole,
  //           data: updatedData,
  //         };

  //         // Get the values at that index (the form group values)
  //         this.airFreightService.getDeleteSchedule(data2).subscribe({
  //           error: this.errorCallback,
  //           next: (data) => {
  //             if (data.body.data.airscheduleDeletedCount >= 1) {
  //               this.successMessage = successMessage.deleteRecord;
  //               //this.spinner.hide();
  //               let newRowData = [...this.rowData]; // Make a shallow copy of the array
  //               newRowData.splice(data, 1);
  //               this.rowData = newRowData;
  //               this.gridOptions.api.setRowData(this.rowData);
  //               window.scroll(0, 0);
  //             } else {
  //               this.warningMessage = `${warningMessage.apiLogicFail}  ${this.itContact}.`;
  //               //this.spinner.hide();
  //             }
  //           },
  //         });
  //       }
  //     }
  //   });
  // }

  // scrool to top on form errors
  scrollToTop() {
    if (this.formTop) {
      this.formTop.nativeElement.scrollIntoView({ behavior: 'smooth' });
    }
  }
  toggleExpansion(index: number) {
    if (this.isExpandedIndex === index) {
      // If the current index is already expanded, collapse it
      this.isExpandedIndex = null;
    } else {
      // Otherwise, set the new expanded index
      this.isExpandedIndex = index;
    }
  }

  handleKeydown(event) {
    this.allowOnlyNumbers(event);
    this.calculateTotalOrderAndEstCost(this.users, this.isLotsSelected);
  }

  calculateTotalOrderAndEstCost(users, isLotsSelected: boolean) {
    const piecesToOrder = users.value[0].piecesToOrder;
    const lotsToOrder = users.value[0].lotsToOrder;

    const usersArray = this.userForm.get('users') as FormArray;

    // Update each form group with the correct data for the corresponding user
    this.filteredData.forEach((user, index) => {
      console.log(user, 'user');
      const userGroup = usersArray.at(index) as FormGroup;

      console.log(userGroup, 'group');

      const cost_per_kilo = Number(this.aircostKilo[0].cost_per_kilo);
      const boxWeight = Number(userGroup.controls.boxWeight.value);
      const qpc_orderlot = user.order_lot;
      console.log(lotsToOrder, boxWeight, cost_per_kilo, 'hi...');

      const calculateCostWhenPieces = (piecesToOrder / qpc_orderlot) * boxWeight * cost_per_kilo;
      const calculateCostWhenLots = lotsToOrder * boxWeight * cost_per_kilo;
      const calculateTotalOrderWhenLots = lotsToOrder * qpc_orderlot;

      console.log(lotsToOrder, qpc_orderlot, 'orderlots...');

      if (isLotsSelected) {
        userGroup.patchValue({
          totalOrder: calculateTotalOrderWhenLots,
          estCost: roundUp(calculateCostWhenLots, 2),
        });
      } else {
        userGroup.patchValue({
          totalOrder: piecesToOrder,
          estCost: roundUp(calculateCostWhenPieces, 2),
        });
      }
    });

    const newPieces = parseInt(users.piecesToOrder, 10);

    // Additional logic can go here if needed
  }

  // allow only numbers

  allowOnlyNumbers(event: KeyboardEvent) {
    const allowedKeys = ['Backspace', 'Tab', 'ArrowLeft', 'ArrowRight', 'Delete'];
    const isNumber = /^[0-9]$/.test(event.key);

    // Allow number keys, allowed keys, and prevent default for others
    if (!isNumber && !allowedKeys.includes(event.key)) {
      event.preventDefault();
    }
  }
  calculateEstimationCost() {
    // console.log('calculateEstimationCost', this.costperKilo[0]);
    // //   total cost = box weight (in kilo) * no. of boxes * cost per kilo
    // //est cost (when weight) = no. of pieces / qpc * weight * cost per kilo
    // const cost_per_kilo = Number(this.costperKilo[0].cost_per_kilo);
    // const boxWeight = Number(this.addOrEdit.boxWeight.value);
    // const piecesToOrder = Number(this.addOrEdit.piecesToOrder.value);
    // const qpc_orderlot = Number(this.addOrEdit.qpcBox.value);
    // console.log('piecesToOrder:', piecesToOrder);
    // console.log('qpc_orderlot:', qpc_orderlot);
    // console.log('boxWeight:', boxWeight);
    // console.log('cost_per_kilo:', cost_per_kilo);
    // console.log(this.boxWeight,this.piecesToOrder,'nalini')
    // if (!boxWeight && !piecesToOrder && !qpc_orderlot && !cost_per_kilo) {
    //   this.addOrEdit.estimatedCost.setValue(''); // Clear totalOrder
    // } else {
    //   const calculateCost = (piecesToOrder / qpc_orderlot) * boxWeight * cost_per_kilo;
    //   this.addOrEdit.estimatedCost.setValue(calculateCost);
    // }
  }

  addRow() {
    // Create a new empty row with all fields initialized to default or empty values

    const newRow: any = {
      actions: '',
      arrival_date: '',
      deadline_date: '',
      noOfPieces: '',
      noOfLots: '',
      configureLots: '',
      configurePieces: '',
      eta_date: '',
      lots_to_order: '',
      number_of_lots: '',
      number_of_pieces: '',
      conf_lots: '',
      conf_pieces: '',
      casenoEditable: '',
      ArrivedChecked: false,
    };

    this.rowDatas = [...this.rowDatas, newRow];
    // this.gridOptions.api.setRowData(this.rowDatas);
  }

  onCancel() {
    localStorage.setItem('isClearMsg', 'true');
    this.router.navigate(['ordering/airfreightorder']);
  }

  updateColumnVisibility() {
    this.columnDefs = this.columnDefs.map((colDef) => {
      if (colDef.field === 'noOfLots') {
        return {
          ...colDef,
          hide: !this.isLotsSelected, // Show this column if isLotsSelected is true
        };
      }
      if (colDef.field === 'configureLots') {
        return {
          ...colDef,
          hide: !this.isLotsSelected, // Hide this column if isLotsSelected is true
        };
      }
      if (colDef.field === 'noOfPieces') {
        return {
          ...colDef,
          hide: this.isLotsSelected, // Hide this column if isLotsSelected is true
        };
      }
      if (colDef.field === 'configurePieces') {
        return {
          ...colDef,
          hide: this.isLotsSelected, // Hide this column if isLotsSelected is true
        };
      }
      return colDef; // Keep other columns unchanged
    });

    // Apply the updated column definitions
    // this.gridApi.setColumnDefs(this.columnDefs);
  }

  getMangerIdByNameAndRole() {
    const formValue = this.userForm.getRawValue(); // Includes disabled controls
    console.log(formValue);
    const manager_id = formValue.managerName?.contact_name;
    const role = this.managerDetails1.filter((role) => role.contact_name === manager_id);
    this.manager_filter_id = role[0]?.contact_id;
    console.log('this.manager_filter_id', this.manager_filter_id);
    return role;
  }

  getKvcIDdByNameAndRole() {
    const formValue = this.userForm.getRawValue();

    const kvc_id = formValue.kvcName?.contact_name;
    console.log('manager', kvc_id);

    const role = this.kvcDetails1.filter((role) => role.contact_name === kvc_id);
    this.kvc_filter_id = role[0]?.contact_id;
    console.log('this.manager_filter_id', this.kvc_filter_id);
    return role;
  }

  handleKeyPress(event: KeyboardEvent): void {
    this.onKeyPressDecimalAllow(event);
  }

  onKeyPressDecimalAllow(event: KeyboardEvent) {
    const usersArray = this.userForm.get('users') as FormArray;
    console.log(usersArray, 'hi');

    const allowedKeys = ['Backspace', 'Tab', 'ArrowLeft', 'ArrowRight', 'Delete'];

    // Access the value of boxWeight directly from the form control
    const inputValue = usersArray.value[0].boxWeight;

    const isNumber = /^[0-9]$/.test(event.key);
    const isDecimalPoint = event.key === '.';

    // Allow number keys and allowed keys
    if (isNumber || allowedKeys.includes(event.key)) {
      return;
    }

    if (isDecimalPoint) {
      // Allow only one decimal point
      if (inputValue.includes('.')) {
        event.preventDefault();
      }
      return;
    }

    // Allow numbers only up to two decimal places
    const [integerPart, decimalPart] = inputValue.split('.');

    // Simplify logic: Prevent input if decimal part exceeds two digits
    if (decimalPart && decimalPart.length >= 2) {
      event.preventDefault();
    }
  }

  ConfigureParameter() {
    console.log('add-edit config');
    const modalComponent = 'modal-component';
    localStorage.setItem('isBudgetCodeSetToDefault', 'false');
    localStorage.setItem('isAirCaseCodeSetToDefault', 'false');
    localStorage.setItem('isContactadded', 'false');

    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.id = modalComponent;
    dialogConfig.maxHeight = '520px';
    dialogConfig.width = '900px';
    const dialogRef = this.dialog.open(ConfigParameterComponent, dialogConfig);
    dialogRef.afterClosed().subscribe((res) => {
      console.log('res');
      if (res === 'cancel') {
        let isBudgetCodeSetToDefault = localStorage.getItem('isBudgetCodeSetToDefault');
        let isAirCaseCodeSetToDefault = localStorage.getItem('isAirCaseCodeSetToDefault');
        let isContactadded = localStorage.getItem('isContactadded');
        let isDefaultContact = localStorage.getItem('isMrgSetToDefault');

        if (isBudgetCodeSetToDefault === 'true') {
          console.log('inside if');
          this.spinner.show();
          this.airFreightService.getBudgetCodesDefault().subscribe({
            error: this.errorCallback,
            next: (data) => {
              console.log('daya', data);
              if (data.body) {
                const newBudgetCodes = data.body.data.filter(
                  (item) => !this.addbudgetCodes.some((existingItem) => existingItem.budget_code === item.budget_code)
                );

                this.addbudgetCodes.push(newBudgetCodes[0]);
                let a = data.body.data.find((item) => item.is_default === true)?.budget_code;
                console.log('a---', a, this.addbudgetCodes);

                //remove if deleted
                this.addbudgetCodes = this.addbudgetCodes.filter((item) =>
                  data.body.data.some((apiItem) => apiItem?.budget_code === item?.budget_code)
                );

                if (a) {
                  console.log('patching budgetCode', this.userForm);
                  this.userForm.patchValue({
                    budgetCode: { budget_code: a },
                  });
                }

                this.spinner.hide();
                window.scroll(0, 0);
              } else {
                this.spinner.hide();
              }
            },
          });
        }
        if (isAirCaseCodeSetToDefault === 'true') {
          console.log('inside if');
          this.spinner.show();
          this.airFreightService.getAirCaseCodes().subscribe({
            error: this.errorCallback,
            next: (data) => {
              console.log('daya', data);
              if (data.body) {
                const newairCaseCodes = data.body.data.filter(
                  (item) =>
                    !this.airCaseCodesList.some((existingItem) => existingItem.air_case_code === item.air_case_code)
                );
                this.airCaseCodesList.push(newairCaseCodes[0]);

                //remove if deleted
                this.airCaseCodesList = this.airCaseCodesList.filter((item) =>
                  data.body.data.some((apiItem) => apiItem?.air_case_code === item?.air_case_code)
                );

                let a = data.body.data.find((item) => item.is_default === true)?.air_case_code?.trim();
                console.log('a---', a);

                if (a) {
                  console.log('patching budgetCode', this.userForm);
                  this.userForm.patchValue({
                    airCase: { air_case_code: a },
                  });
                }
                this.spinner.hide();
                window.scroll(0, 0);
              } else {
                this.spinner.hide();
              }
            },
          });
        }
        if (isContactadded === 'true' || isDefaultContact === 'true') {
          console.log('inside if');
          this.spinner.show();
          this.airFreightService.getKvcDetails().subscribe({
            error: this.errorCallback,
            next: (data) => {
              console.log('daya', data);
              if (data.body) {
                // this.kvcList = [];
                console.log('kvcList', this.kvcList);

                const newKvc = data.body.data.filter(
                  (item) => !this.kvcList.some((existingItem) => existingItem.contact_name === item.contact_name)
                );
                console.log('newKvc', newKvc);
                this.kvcList.push(newKvc[0]);

                //remove if deleted
                this.kvcList = this.kvcList.filter((item) =>
                  data.body.data.some((apiItem) => apiItem?.contact_name === item?.contact_name)
                );
                let a = this.kvcList.find((item) => item.is_default === true)?.contact_name;
                console.log('a---', a);

                if (a) {
                  console.log('patching budgetCode', this.userForm);
                  this.userForm.patchValue({
                    kvcName: { contact_name: a },
                  });
                }

                this.spinner.hide();
                window.scroll(0, 0);
              } else {
                this.spinner.hide();
              }
            },
          });
          this.airFreightService.getManagerDetails().subscribe({
            error: this.errorCallback,
            next: (data) => {
              console.log('daya', data);
              if (data.body) {
                // this.managerList = [];

                const newManager_name = data.body.data.filter(
                  (item) => !this.managerList.some((existingItem) => existingItem.contact_name === item.contact_name)
                );
                console.log('newManager_name', newManager_name);

                this.managerList.push(newManager_name[0]);

                //remove if deleted
                this.managerList = this.managerList.filter((item) =>
                  data.body.data.some((apiItem) => apiItem?.contact_name === item?.contact_name)
                );

                let a = this.managerList.find((item) => item.is_default === true)?.contact_name;
                console.log('a---', a);

                if (a) {
                  console.log('patching budgetCode', this.userForm);
                  this.userForm.patchValue({
                    managerName: { contact_name: a },
                  });
                }

                this.spinner.hide();
                window.scroll(0, 0);
              } else {
                this.spinner.hide();
              }
            },
          });
        }

        // this.callApiForAdd();
        this.columnDefs[7].cellStyle = function (params) {
          if ('modified' in params.data) {
            return { backgroundColor: '#E4ECF0' };
          } else {
            console.log('insidesave');
            return { backgroundColor: 'white' };
          }
        };
      }
    });
  }
  calendarApiCall() {
    console.log('calendarApiCall');
    const formValue = this.userForm.getRawValue(); 
    console.log('calendarApiCall',formValue.users[0].arrivalDate);
    const dock = formValue.users[0].dockNo.dock;
    const act_prod_date = formValue.users[0].arrivalDate;

    const data = {
      business_entity: this.namcValue,
      workid: this.user,
      user_role: this.userRole,
      dock: dock,
      act_prod_date: act_prod_date,
    };

    return new Promise((resolve, reject) => {
      this.airFreightService.getCalendar().subscribe({
        error: (error) => {
          this.errorCallback(error); // Handle error if needed
          reject(error); // Reject the Promise on error
        },
        next: (res) => {
          if (res.body.data) {
            this.calendarList = res.body.data;
            console.log(this.calendarList);
            resolve(this.calendarList); // Resolve with the data if success
          } else {
            this.spinner.hide();
            resolve(null); // Resolve with null if no data
          }
        },
      });
    });
  }
}
