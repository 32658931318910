import { Component, ViewChild } from '@angular/core';
import { GriddataService } from '../services/griddata.service';
import { Subscription, forkJoin } from 'rxjs';
import { GridRefreshComponent } from '../core/grid/grid-refresh/grid-refresh.component';
import { OSPStateService } from '../services/osp-state.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { logErrors } from '../shared/logger';
import { HeaderCheckboxComponent } from '../core/grid/header-checkbox/header-checkbox.component';
import { RowCheckboxComponent } from '../core/grid/row-checkbox/row-checkbox.component';
import { dateFilterComparator, resetSortingAndFilters, validateVanningDates } from '../constants';
// import { UpdatedStockingPaceComponent } from './updated-stocking-pace.component';
import { StockingPaceServiceService } from '../services/stocking-pace-service.service';
import { ExcelService } from '../services/excel.service';
import { warningMessage,checkAccessPipe } from '../constants';
import * as mm from 'moment-timezone';
import {
  MatLegacyDialog as MatDialog,
  MatLegacyDialogConfig as MatDialogConfig,
} from '@angular/material/legacy-dialog';
import { ConfirmCancelDailogComponent } from '../core/confirm-cancel-dailog/confirm-cancel-dailog.component';
import { OspService } from '../core/master-services/osp.service';
import {
  successMessage,
} from '../constants';
import { DeleteBtnCellCoRender } from './container-ordering-actions/delete-btn-cell-co-render';
import { RowDragMoveEvent } from 'ag-grid-community';
import { ContainerOrderingService } from '../services/container-ordering/container-ordering.service';
import { DeleteBreaksCoComponent } from './container-ordering-dialog/delete-breaks-co/delete-breaks-co.component';

@Component({
  selector: 'app-container-ordering',
  templateUrl: './container-ordering.component.html',
  styleUrls: ['./container-ordering.component.scss']
})
export class ContainerOrderingComponent {

  //immutableStore: any[]
  headerCheckboxChecked: any;
  // dateComparator: any;
  @ViewChild('osPartsGrid') osPartsGrid;
  isEditMode = false;
  editEnabled: boolean = false;
  defaultPageSize: any = 100000;
  offset: number = 0;
  user: any;
  userRole: any;
  dataCount: number = 0;
  stockingPaceSubscription$: Subscription;
  selectedDock: any;
  selectedVanMonth: any = [];
  selectedrenban = [];
  selectedrenbanDelete = [];
  selectecontainernDelete = [];
  renbanList: any = [];
  renbanListInitial: any = [];
  selectedContainer: any = [];
  containerList: any = [];
  containerListFull: any = [];
  columnDefs: any;
  rowData: any;
  rowDataOnLoad: any = [];
  rowSelection: any;
  gridOptions: any;
  pagination: any;
  paginationPageSize: any;
  context: any;
  suppressClickEdit: boolean;
  frameworkComponents: any;
  tooltipShowDelay: any;
  editType: any;
  search_form = {
    dock: '',
    module_type: '',
    van_month: '',
  };
  dockModuleList: any = [];
  filterList: any = [];
  dockList: any = [];
  vanMonthList: any = [];
  moduleTypeList: any = [];
  vanMonthWiseList: any = [];
  selectedVanMonthWise: any[];
  disableSave = true;
  modalComponent = 'modal-component';
  editApiDefaultLimit = 200;
  timesEditApiCalled: number = 0;
  editApiStartPosition: number = 0;
  editApiEndPosition: number = this.editApiDefaultLimit;
  itContact: string;
  vanningFrom;
  vanningTo;
  validationMessage = '';
  access1: { edit: boolean; matchmismatch: boolean; modulelevaltapping: boolean; };
  access2:boolean;
  reorderedRenban = [];
  constructor(
    private readonly gridDataService: GriddataService,
    private readonly stateService: OSPStateService,
    private readonly containerOrderingService: ContainerOrderingService,
    private readonly spinner: NgxSpinnerService,
    private readonly stockingService: StockingPaceServiceService,
    private readonly excelService: ExcelService,
    private readonly dialog: MatDialog,
    private readonly ospService: OspService
  ) {
    this.context = {
      componentParent: this,
    };
  }
  ngOnInit(): void {
    const access = checkAccessPipe();
    this.access1 = access.access;
    this.access2 = access.access.edit;



    this.selectedVanMonth = this.vanMonthWiseList[0];
    //console.log('on Init call.');
    this.columnDefs = [
      {
        headerName: '',
        field: 'rowCheckBox',
        pinned: 'left',
        width: 46,
        headerComponentParams: {
          headerChecked: this.headerCheckboxChecked,
        },
        headerComponentFramework: HeaderCheckboxComponent,
        floatingFilterComponentFramework: GridRefreshComponent,
        floatingFilterComponentParams: { suppressFilterButton: true },

        cellRendererFramework: RowCheckboxComponent,
        floatingFilter: true
      },
      {
        headerName: 'Action',
        field: 'delete',
        sortable: false,
        unSortIcon: false,
        width: 70,
        cellRendererFramework: DeleteBtnCellCoRender,
        cellRendererParams: {
          clicked: (field?: any) => {
            this.deleteButton(field)
          },
        },
        cellStyle: { cursor: 'pointer' },
        headerTooltip: 'Action',
        hide: !this.access2

      },
      {
        headerName: '',
        field: 'drag',
        rowDrag: true,
        sortable: false,
        unSortIcon: false,
        tooltipField: 'drag',
        width: 50,
        headerTooltip: 'Drag',
        hide: !this.access2
      },
      {
        headerName: 'Dock',
        field: 'dock',
        sortable: false,
        width: 80,
        floatingFilter: true,
        // rowDrag: true,
        tooltipField: 'dock',
        headerTooltip: 'Dock'
      },
      {
        headerName: 'Renban',
        field: 'container_renban',
        sortable: false,
        width: 130,
        floatingFilter: true,
        // rowDrag: true,
        tooltipField: 'container_renban',
        headerTooltip: 'Renban'
      },
      {
        headerName: 'Van DT',
        field: 'vandt',
        sortable: false,
        width: 210,
        floatingFilter: true,
        filter: 'agDateColumnFilter',
        filterParams: {
          comparator: dateFilterComparator,
        },
        comparator: this.dateComparator,
        tooltipField: 'vandt',
        headerTooltip: 'Vanning Date(mm/dd/yyyy)'
      },
      {
        headerName: 'Estd Arrival DT',
        field: 'estdarrivaldate',
        sortable: false,
        width: 210,
        floatingFilter: true,
        filter: 'agDateColumnFilter',
        filterParams: {
          comparator: dateFilterComparator,
        },
        comparator: this.dateComparator,
        tooltipField: 'estdarrivaldate',
        headerTooltip: 'Estimated Arrival Date(mm/dd/yyyy)'
      },
      {
        headerName: 'Actual Arrival DT',
        field: 'actualarrivaldt',
        sortable: false,
        width: 210,
        floatingFilter: true,
        filter: 'agDateColumnFilter',
        filterParams: {
          comparator: dateFilterComparator,
        },
        comparator: this.dateComparator,
        tooltipField: 'actualarrivaldt',
        headerTooltip: 'Actual Arrival Date(mm/dd/yyyy)'
      },
      {
        headerName: 'User',
        field: 'userid',
        sortable: false,
        width: 230,
        floatingFilter: true,
        tooltipField: 'userid',
        headerTooltip: 'User'
      },
      {
        headerName: 'Updated DT',
        field: 'updateddt',
        sortable: false,
        width: 210,
        floatingFilter: true,
        tooltipField: 'updateddt',
        headerTooltip: 'Updated Date(mm/dd/yyyy hh:mm:ss (Eastern))',
      },
    ];

  
    this.rowSelection = 'multiple';
    this.editType = 'fullRow';
    this.gridOptions = this.gridDataService.getGridOptions();
    this.gridOptions.enableCellTextSelection = false;

    this.gridOptions.onGridReady = function (params) {
      this.gridApi = params.api;
    };

    this.gridOptions.onRowDragMove = (event: RowDragMoveEvent) => {
      if (event.node) {
        event.node.setSelected(true, true); // Select the row while dragging
      }
    }

    this.gridOptions.onRowDragEnd = (event: RowDragMoveEvent) => {
      //console.log('rowDragEnd',event);

      try {
        const movingNode = event.node;
        const overNode = event.overNode;
        // console.log('movingNode', movingNode.childIndex);
        // console.log('overNode', overNode.childIndex);
        // console.log('paginationGetCurrentPage', this.osPartsGrid.gridOptions.api.paginationGetCurrentPage());
        // console.log('paginationGetPageSize', this.osPartsGrid.gridOptions.api.paginationGetPageSize());
        const currentPage = this.osPartsGrid.gridOptions.api.paginationGetCurrentPage();
        const pageSize = this.osPartsGrid.gridOptions.api.paginationGetPageSize();
        const movingIndex = (pageSize * currentPage) + overNode.childIndex;
        const actualoverNode = this.osPartsGrid.gridOptions.api.getDisplayedRowAtIndex(movingIndex);
        const rowNeedsToMove = movingNode !== actualoverNode;

        // console.log('movingIndex', movingIndex);
        // console.log('movingNode', movingNode);
        // console.log('overNode', overNode);
        // console.log('actualoverNode', actualoverNode);
        // console.log('rowNeedsToMove', rowNeedsToMove);

        if (rowNeedsToMove && movingNode && actualoverNode) {
          const movingData = movingNode.data;
          //console.log('movingData',movingData);
          const overData = actualoverNode!.data;
          const fromIndex = this.rowData.indexOf(movingData);
          const toIndex = this.rowData.indexOf(overData);
          const newStore = this.rowData.slice();
          const element = newStore[fromIndex];
          newStore.splice(fromIndex, 1);
          newStore.splice(toIndex, 0, element);
          this.rowData = newStore;
          const rowNodeFrom = event.api.getRowNode(fromIndex);
          if (rowNodeFrom) {
            rowNodeFrom.data.ishighlight = true;
            if (!this.reorderedRenban.includes(rowNodeFrom.data.container_renban)) {
              this.reorderedRenban.push(rowNodeFrom.data.container_renban);
            }
          }
          this.osPartsGrid.gridOptions.api.setRowData(this.rowData);
          this.disableSave = false;
          setTimeout(() => {
            this.osPartsGrid.gridOptions.api.ensureIndexVisible(toIndex, 'middle'); // Scroll to the selected row
            // const rowNode = event.api.getRowNode(toIndex);
            // if (rowNode) {
            //   rowNode.setSelected(true); // Select the moved row
            // }
          }, 100);

          // setTimeout(() => {
          //   //const rowNode = event.api.getRowNode(toIndex);
          //   if (rowNode) {
          //     rowNode.setSelected(false); // Select the moved row
          //   }
          // }, 3000);
        }
      } catch (error) {
        console.log(error);
      }

    }

    this.gridOptions.getRowStyle = function (params) {
      if (params.data.ishighlight){
        return { background: '#e6ef76cf' };
      }
    };



    this.stockingPaceSubscription$ = this.stateService.getNamc().subscribe((observable) => {
      this.initializeData();
    });
  }

  dateComparator(date1: Date, date2: Date): number {
    if (date1 < date2) {
      return -1; // date1 is earlier
    } else if (date1 > date2) {
      return 1; // date1 is later
    } else {
      return 0; // dates are equal
    }
  }

  namcValue = localStorage.getItem('namcvalue');
  userName = localStorage.getItem('userName');

  onSelectionChanged() {
    throw new Error('Method not implemented.');
  }

  warningMessage: string;
  successMessage: string;

  removeMessage() {
    this.successMessage = '';
    this.warningMessage = '';
  }

  userCheckChanged(checked, rowIndex) {
    this.spinner.show();
    const rowNode = this.osPartsGrid.gridOptions.api.getDisplayedRowAtIndex(rowIndex);
    if (checked === true) {
      rowNode.setSelected(true);
      if (this.isEditMode) {
        rowNode.data.checkedEditMode = true;
      }
      rowNode.data.rowCheckBox = true;
    } else {
      rowNode.setSelected(false);
      rowNode.data.checkedEditMode = false;
      rowNode.data.rowCheckBox = false;
    }

    this.gridOptions.getRowStyle = function (params) {
      //console.log('this.gridOptions.getRowStyle1');
      if (params.node.rowIndex === rowIndex) {
        const color = checked ? '#E4ECF0' : 'white';
        return { background: color };
      }
      return { background: 'white' };
    };

    rowNode.setData(rowNode.data);
    this.toggleHeaderCheckbox(checked);
    this.spinner.hide();
  }
  toggleHeaderCheckbox(checked) {
    if (!checked) {
      this.headerCheckboxChecked = false;

      this.osPartsGrid.gridOptions.api.refreshHeader();

      return;
    }

    const rowCount = this.osPartsGrid.gridOptions.rowData.length;
    const selectedRowCount = this.osPartsGrid.gridOptions.rowData.filter((x) => x.rowCheckBox === true).length;

    if (rowCount === selectedRowCount) {
      this.headerCheckboxChecked = true;

      this.osPartsGrid.gridOptions.api.refreshHeader();
    }
  }
  headerCheckChanged(event) {
    this.headerCheckboxChecked = event.checked;
    const headerChecked = event.checked;
    const isEditable = this.isEditMode;
    this.osPartsGrid.gridOptions.api.forEachNode(function (rowNode, index) {
      if (headerChecked) {
        if (isEditable) {
          rowNode.data.checkedEditMode = true;
        }
        rowNode.data.rowCheckBox = true;
      } else {
        rowNode.data.rowCheckBox = false;
        rowNode.data.checkedEditMode = false;
      }
      rowNode.setData(rowNode.data);
    });
  }

  getModifiedRowData(): any {
    const modifiedRowData = this.rowData.filter((row) => row.rowCheckBox);
    return modifiedRowData;
  }

  onResetDropDown() {
    this.removeMessage();
    this.selectedDock = {};
    this.containerList = [];
    this.renbanList = [];
    this.selectedContainer = [];
    this.selectedrenban = [];
    this.selectedVanMonth = [];
    this.vanningFrom = this.getLastMonthStartDate();
    this.vanningTo = '';
    if (this.osPartsGrid) {
      this.headerCheckboxChecked = false;
      this.osPartsGrid.gridOptions.api.refreshHeader();
      this.resetGrid();
    }

    this.setPageSizeToAll(this.defaultPageSize);

    this.rowData = [];

    this.offset = 0;
    this.disableSave = true;
  }

  onSearch(offset, param) {

    if (this.osPartsGrid) {
      this.headerCheckboxChecked = false;
      this.osPartsGrid.gridOptions.api.refreshHeader();

      this.resetGrid();
    }

    if ((param !== "DEL") && (param !== "SAVE")) {
      this.removeMessage();
    }

    if (offset === 0) {
      this.setPageSizeToAll(this.defaultPageSize);
      this.rowData = [];
      this.osPartsGrid.gridOptions.api.setRowData(this.rowData);

      this.offset = 0;
      this.spinner.show();
    }
    
    if (!this.checkIfAnySelected()) {
      this.rowData = [];
      if (param !== "DEL") {
        this.warningMessage = this.validationMessage;
        window.scroll(0, 0);
      }
      this.spinner.hide();
      return false;
    }

    const { valid, error } = validateVanningDates({
      vanningFrom: this.vanningFrom,
      vanningTo: this.vanningTo,
    });

    if (!valid) {
      this.warningMessage = error;
      this.spinner.hide();
      return false;
    }

    let selectedrenban;

    if (this.selectedrenban.length > 0) {
      if (!this.selectedrenban.some((a) => a.container_renban === 'ALL')) {
        selectedrenban = [];
        this.selectedrenban.forEach((element) => {
          selectedrenban.push(element.container_renban);
        });
      }
    }

    const data = {
      business_entity: this.namcValue,
      workid: this.user,
      user_role: this.userRole,
      offset: offset,
      limit: 12000,
      vanDtFrom: this.vanningFrom,
      vanDtTo: this.vanningTo,
      container_renban: selectedrenban,
      dock: this.selectedDock.dock
    };

    this.containerOrderingService.getcontainerOrdering(data).subscribe({
      error: this.errorCallback,
      next: (res) => {
        if (res.body && res.body.data) {
          this.rowData.push(...res.body.data);
          this.rowDataOnLoad = [];
          this.rowDataOnLoad.push(...this.rowData);
          this.osPartsGrid.gridOptions.api.setRowData(this.rowData);
          this.dataCount = parseInt(res.body.rowCount);
          const records = this.dataCount - (this.offset + 1) * 12000;
          if (records >= 1) {
            this.offset++;
            this.onSearch(this.offset * 12000, null);
          } else {
            this.spinner.hide();
          }
        } else {
          this.rowData = [];
          this.spinner.hide();
        }
        this.setPageSizeToAll();
      },
    });
    this.disableSave = true;
    return true;
  }

  onSave() {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.id = this.modalComponent;
    dialogConfig.width = '460px';
    dialogConfig.height = '200px';
    dialogConfig.data = {
      mode: 'ordering',
      message: ''
    };

    const distinctDocks = [...new Set(this.rowData.map(item => item.dock))];

    let oldData = [];
    let reOrderData = [];
    const saveData = [];
    this.warningMessage = '';

    distinctDocks.forEach((item) => {
      reOrderData = this.rowData.filter(f => f.dock === item);
      oldData = this.rowDataOnLoad.filter(f => f.dock === item);
      //console.log('oldData', oldData);
      //console.log('reOrderData',reOrderData);

      const outputArray = reOrderData.map((item, index) => ({
        container_renban: item.container_renban,
        userid: this.userName,
        business_entity: item.business_entity,
        dock: item.dock,
        renban_seq: oldData[index].renban_seq // Update value from firstArray by index
      }));

      //console.log('outputArray',outputArray);

      const exactArray = outputArray.filter(newItem =>
        !oldData.some(oldItem =>
          oldItem.container_renban === newItem.container_renban &&
          oldItem.renban_seq === newItem.renban_seq
        )
      );

      //console.log('exactArray',exactArray);
      saveData.push(...exactArray);
    });

    //console.log('saveData',saveData);

    if (saveData.length === 0) {
      this.warningMessage = "The data has not been reordered, please try reordering again.";
      this.disableSave = true;
      window.scroll(0, 0);
    } else {
      const updateData =
      {
        business_entity: this.namcValue,
        workid: this.user,
        user_role: this.userRole,
        data: saveData
      };
      console.log('updateData',updateData);
      console.log('saveData', saveData);
      console.log('this.reorderedRenban',this.reorderedRenban);

      // Extract container_renban values from array1 into a Set
      const renbanSet = new Set(saveData.map(item => item.container_renban));

      // Filter and sort in ascending (SC) order using string comparison
      const reoArray = this.reorderedRenban
        .filter(item => renbanSet.has(item))
        .sort((a, b) => a.localeCompare(b)); // Ensures proper string sorting
    
      if (reoArray.length === 1){
        dialogConfig.data.message = `Container ${reoArray[0]} has been reordered.`;
      }else if (reoArray.length > 1){
        dialogConfig.data.message = `Container(s) ${reoArray.join(' ')} have been reordered.`;
      }else{
        dialogConfig.data.message = `Container has not been reordered.`;
      }
      this.beforeContainerOrderingEdit(dialogConfig, updateData);
    }
  }

  beforeContainerOrderingEdit(dialogConfig: any, data1: any) {
    const dialogRef = this.dialog.open(DeleteBreaksCoComponent, dialogConfig)
    dialogRef.afterClosed().subscribe((res) => {
      if (res === 'cancel') {
        return;
      } else if (res === 'save') {
        this.spinner.show();
        this.containerOrderingService.editContainerOrdering(data1).subscribe({
          error: this.errorCallback,
          next: (data) => {
            if (data.body.updatedRows >= 1) {
              this.successMessage = successMessage.recordUpdated;
              this.onSearch(0, 'SAVE');
            } else {
              this.warningMessage = `${warningMessage.apiLogicFail}  ${this.itContact}.`;
              this.rowData = [];
              this.osPartsGrid.gridOptions.api.setRowData(this.rowData);
              this.spinner.hide();
            }
          },
        });
      }
    })
  }

  resetGrid() {
    if (this.osPartsGrid) {
      resetSortingAndFilters(this.osPartsGrid);
      this.reorderedRenban = [];
      return;
    }
  }

  setPageSizeToAll(pageSize?) {
    this.paginationPageSize = this.rowData.length;

    if (pageSize) {
      this.paginationPageSize = 0;
      this.rowData.length = 0;
    }

    this.onPageSizeChanged();
  }

  checkIfAnySelected() {
    this.validationMessage = '';
    const msgPrefix = 'Please select '
    let msgArr = [];
    console.log('this.selectedDock', this.selectedDock);

    if (Object.keys(this.selectedDock).length === 0){
      msgArr.push('Dock');
    }
    if (this.selectedContainer.length === 0){
      msgArr.push('CC');
    }
    if (this.selectedrenban.length === 0){
      msgArr.push('Renban');
    }
    if (!this.vanningFrom){
      msgArr.push('Van Dt From');
    }
    // if (!this.vanningTo){
    //   msgArr.push('Van Dt To');
    // }

    if (msgArr.length > 0){
      this.validationMessage = `${msgPrefix}${msgArr.join(', ')}.`;
      return false;
    }
     
    return true;
  }
  initializeData() {
    //console.log('initialize data called');
    this.rowData = [];
    this.spinner.show();

    this.namcValue = localStorage.getItem('namcvalue');

    if (localStorage.getItem('workdayId')) {
      this.user = localStorage.getItem('workdayId');
    }
    if (localStorage.getItem('UserRoles')) {
      this.userRole = localStorage.getItem('UserRoles');
    }
    if (localStorage.getItem('UserName')) {
      this.userName = localStorage.getItem('UserName');
    }
    const access = checkAccessPipe();
    this.access1 = access.access;
    this.access2 = access.access.edit;


    this.loadDropdown(null);
    this.onResetDropDown();

  }

  errorCallback = (error) => {
    logErrors(error);
    this.spinner.hide();

    this.warningMessage = 'Something went wrong! Please contact support team.';
    this.rowData = [];
    this.osPartsGrid.gridOptions.api.setRowData(this.rowData);
  };

  loadDropdown(param) {

    const data = {
      business_entity: this.namcValue,
      workid: this.user,
      user_role: this.userRole
    };


    this.containerOrderingService.getCOFilter(data).subscribe(
      {
        next: (res) => {
          this.dockList = [];
          this.filterList = [];
          this.filterList.push(...res.body.data);

          this.dockList = [...new Set(this.filterList.map(item => item.dock))]
            .sort((a, b) => String(a).localeCompare(String(b)))
            .map(dock => ({ dock }));

          //debugger
          if (param === "DEL") {
            debugger
            this.onDockChange();

            if (this.selectedrenbanDelete[0].container_renban !== "ALL"){
            this.selectedrenban = this.renbanList.filter(item =>
              item.container_renban && item.container_renban !== "ALL" &&
              this.selectedrenbanDelete.some(searchItem => (searchItem.container_renban === item.container_renban) &&
              searchItem.container_renban !== "ALL"));              
            }

            //selectecontainernDelete
            if (this.selectecontainernDelete[0].cc !== "ALL"){
                this.selectedContainer = this.containerList.filter(item =>
                item.cc && item.cc !== "ALL" &&
                this.selectecontainernDelete.some(searchItem => (searchItem.cc === item.cc) &&
                searchItem.cc !== "ALL"));
            }

            //if all container deleted then set selected dock empty
            if (this.containerList.length === 1 && this.containerList[0].cc === "ALL"){
                this.selectedDock = {};

            }
              
            this.onSearch(0, param);
          } else {
            this.spinner.hide();
          }
        },
        error: this.errorCallback,
      }
    );
  }

  onPageSizeChanged() {
    if (this.gridOptions?.api) {
      this.gridOptions.api.paginationSetPageSize(Number(this.paginationPageSize));
    }
  }

  exportAsXLSX(): void {
    this.removeMessage();

    this.spinner.show();

    // eastern time api
    const easternCurrentDate = String(mm().tz('US/Michigan').format('YYYY-MM-DD_HH:mm:ss'));
    //console.log(easternCurrentDate);
    let data = [];

    if (this.headerCheckboxChecked) {
      this.osPartsGrid.gridOptions.api.forEachNodeAfterFilter((node) => {
        data.push(node.data);
      });
    } else {
      data = this.getModifiedRowData();
    }

    if (data.length < 1) {
      this.spinner.hide();
      this.warningMessage = warningMessage.noRowsSelected;
      window.scroll(0, 0);
    } else {
      setTimeout(async () => {
        if (this.rowData.length > 0) {
          const exportData: any = [];

          data.forEach((e) => {
            const obj = {
              dock: e.dock,
              renban: e.container_renban,
              vandt: e.vandt,
              estdarrivaldate: e.estdarrivaldate,
              actualarrivaldt: e.actualarrivaldt,
              user: e.userid,
              updateddt: e.updateddt
            };
            exportData.push(obj);
          });

          const headers = [
            'DOCK',
            'RENBAN',
            'VAN DT',
            'ESTD ARRIVAL DT',
            'ACTUAL ARRIVAL DT',
            'USER',
            'UPDATED DT',
          ];

          const timestamp = easternCurrentDate
            .split('.')[0]
            .replace('T', '_')
            .split('')
            .filter((d) => d !== '-' && d !== ':')
            .join('');

          this.excelService.exportAsExcelFile(exportData, 'Container-ordering_' + timestamp, headers);
        }
        this.spinner.hide();
      }, 1000);
    }
  }


  modifiedRowData = [];

  updatedRows = [];

  onDockChange(): void {
    //this.loadContainerDrop(null);

    this.renbanList = [];
    this.renbanListInitial = [];
    this.containerList = [];

    //populate container
    //this.containerList.push({ cc: 'ALL' });

    const filteredCC = [...new Set(
      this.filterList
        .filter(item => item.dock === this.selectedDock.dock) // Step 1: Filter by dock
        .map(item => item.cc) // Step 2: Extract container_renban values
    )]
      .sort((a, b) => String(a).localeCompare(String(b))) // Step 3: Sort in ascending order
      .map(cc => ({ cc })); // Step 4: Convert to object format

    this.containerList.push(...filteredCC);

    if (this.containerList.length > 0){
      this.containerList.unshift({ cc: 'ALL' });
      this.selectedContainer = [{ cc: 'ALL' }];
    }else{
      this.selectedContainer = [];
    }

    //populate renban
    //this.renbanList.push({ container_renban: 'ALL' });

    const filteredRenabn = [...new Set(
      this.filterList
        .filter(item => item.dock === this.selectedDock.dock) // Step 1: Filter by dock
        .map(item => item.container_renban) // Step 2: Extract container_renban values
    )]
      .sort((a, b) => String(a).localeCompare(String(b))) // Step 3: Sort in ascending order
      .map(container_renban => ({ container_renban })); // Step 4: Convert to object format

    this.renbanList.push(...filteredRenabn);
    this.renbanListInitial.push(...this.renbanList);

    if (this.renbanList.length > 0){
      this.renbanList.unshift({ container_renban: 'ALL' });
      this.selectedrenban = [{ container_renban: 'ALL' }];
    }else{
      this.selectedrenban = [];
    }

  }

  onRenbanChange(): void {
    console.log('this.selectedrenban', this.selectedrenban);
    if (this.selectedrenban.length >= 1) {
      if (this.selectedrenban[this.selectedrenban.length - 1].container_renban === 'ALL') {
        this.selectedrenban = [];
        this.selectedrenban.push(this.renbanList[0]);
        // this.selectedContainer = [];
        // this.selectedContainer.push(this.containerList[0]);
      } else {
        const indexAll = this.selectedrenban.findIndex((data) => data.container_renban === 'ALL');
        //const indexAll1 = this.selectedContainer.findIndex((data) => data.cc === 'ALL');

        if (indexAll > -1) {
          this.selectedrenban.splice(indexAll, 1);
        }
        // if (indexAll1 > -1) {
        //   this.selectedContainer.splice(indexAll1, 1);
        // }

        // this.selectedContainer = this.containerList.filter(item =>
        //   item.cc && item.cc !== "ALL" &&
        //   this.selectedrenban.some(searchItem => (searchItem.container_renban.substring(0, 1) === item.cc) &&
        //   searchItem.container_renban !== "ALL")
        // );
      }
    } else {
      //this.selectedContainer = [];
    }
  }

  onContainerChange(): void {
    console.log('this.selectedContainer', this.selectedContainer);
    if (this.selectedContainer.length >= 1) {
      if (this.selectedContainer[this.selectedContainer.length - 1].cc === 'ALL') {
        this.selectedContainer = [];
        this.selectedContainer.push(this.containerList[0]);
        this.renbanList = [{ container_renban: 'ALL' }];
        this.renbanList.push(...this.renbanListInitial);
        this.selectedrenban = [];
        this.selectedrenban.push(this.renbanList[0]);
      } else {
        let indexAll = this.selectedContainer.findIndex((data) => data.cc === 'ALL');

        if (indexAll > -1) {
          this.selectedContainer.splice(indexAll, 1);
        }

        this.renbanList = [{ container_renban: 'ALL' }];
        const renList = this.renbanListInitial.filter(item =>
          item.container_renban &&
          this.selectedContainer.some(searchItem => (searchItem.cc === item.container_renban.substring(0, 1))));
        this.renbanList.push(...renList);
        this.selectedrenban = [{ container_renban: 'ALL' }];
      
      }
    } else {
      this.renbanList = [];
      this.selectedrenban = [];
    }
  }

  deleteButton(field: any): void {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.id = this.modalComponent;
    dialogConfig.width = '460px';
    dialogConfig.height = '200px';


    const data = {
      business_entity: this.namcValue,
      workid: this.user,
      user_role: this.userRole,
      dock: field.dock,
      container_renban: field.container_renban
    }

    this.spinner.show();
    this.containerOrderingService.getModules(data).subscribe({
      error: this.errorCallback,
      next: (res) => {
        if (res.body && res.body.data) {
          this.spinner.hide();
          const modules = res.body.data;
          let restCount;
          let strmodules;

          if (modules.length > 3) {
            restCount = modules.length - 3;
            strmodules = modules.slice(0, 3).map(item => item.module_number).join(", ");
          } else {
            strmodules = modules.map(item => item.module_number).join(", ");
          }

          let message = '';
          message += `The following modules will be deleted from Renban ${field.container_renban} - ${strmodules || '(No Module)'}`;
          if (modules.length > 3) {
            message += ` and ${restCount} more`;
          }
          message += `. Kindly Confirm`;

          dialogConfig.data = {
            mode: 'ordering',
            message
          };

          const data = {
            business_entity: this.namcValue,
            workid: this.user,
            user_role: this.userRole,
            dock: field.dock,
            container_renban: field.container_renban
          }

          const deleteData = {
            business_entity: this.namcValue,
            workid: this.user,
            user_role: this.userRole,
            container_renban: field.container_renban,
            dock: field.dock
          };
          this.beforeContainerOrderingDelete(dialogConfig, deleteData);
        } else {
          this.rowData = [];
          this.spinner.hide();
        }
      },
    });

  }

  beforeContainerOrderingDelete(dialogConfig: any, data1: any) {
    const dialogRef = this.dialog.open(DeleteBreaksCoComponent, dialogConfig)
    dialogRef.afterClosed().subscribe((res) => {
      if (res === 'cancel') {
        return;
      } else if (res === 'save') {
        this.spinner.show();
        this.selectedrenbanDelete = [];
        this.selectecontainernDelete = [];
        this.containerOrderingService.deleteContainerOrdering(data1).subscribe({
          error: this.errorCallback,
          next: (data) => {
            if (data.body.DeletedCount >= 1) {
              this.selectedrenbanDelete.push(...this.selectedrenban); //temp storing the value            
              this.selectecontainernDelete.push(...this.selectedContainer); //temp storing the value            
              this.loadDropdown("DEL");
              this.successMessage = successMessage.deleteRecord;
            } else {
              this.warningMessage = `${warningMessage.apiLogicFail}  ${this.itContact}.`;
              window.scroll(0, 0);
              this.rowData = [];
              this.osPartsGrid.gridOptions.api.setRowData(this.rowData);
              this.spinner.hide();
            }
          },
        });
      }
    })
  }

  getLastMonthStartDate() {
    const date = new Date();
    // Set the date to the first day of the current month
    date.setDate(1);
    // Move back one month
    date.setMonth(date.getMonth() - 1);

    // Format the date as YYYY-MM-DD
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are 0-indexed
    const day = String(date.getDate()).padStart(2, '0');

    return `${year}-${month}-${day}`;
  }

}
