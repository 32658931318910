<div class="background">
  <div class="container-fluid">
    <div class="d-flex flex-row justify-content-between">
      <div class="p-2">
        <h2 class="heading">Stocking Pace Inquiry</h2>
      </div>
      <div class="p-2 d-flex flex-row justify-content-end align-items-center">
        <label class="info-label fs-6">To Change the devan pace, </label>
        <a class="info-label fs-6" [routerLink]="'/inventory/stocking-pace-maintenance'">
          Module Stocking Maintenance
        </a>
      </div>
    </div>
  </div>

  <div class="successMessage alert-dismissible">
    <div *ngIf="successMessage" class="alert alert-warning" role="alert">
      {{ successMessage }}
      <button type="button" class="btn-close" (click)="removeMessage()" data-bs-dismiss="alert"
        aria-label="Close"></button>
    </div>
    <div *ngIf="warningMessage" class="alert alert-warning" role="alert">
      {{ warningMessage }}
      <button type="button" class="btn-close" (click)="removeMessage()" data-bs-dismiss="alert"
        aria-label="Close"></button>
    </div>
  </div>
  <div class="container-fluid">
    <div class="row">
      <div class="card">
        <div class="container-fluid">
          <div class="row">
            <div class="col-xl-1 col-md-2 col-sm-3">
              <div class="drpchildctrl">
                <span class="v1"></span>
                <label class="searchLable">DOCK</label>
                <p-multiSelect [(ngModel)]="selectedDock" [showToggleAll]="false" (onChange)="dockChange()"
                  [options]="dockList" [showTransitionOptions]="'0ms'" [hideTransitionOptions]="'0ms'"
                  [defaultLabel]="'--Select--'" [virtualScroll]="true" [itemSize]="25" autoWidth="false"
                  [style]="{ width: '100%' }" [resetFilterOnHide]="true" optionLabel="dock"
                  [panelStyle]="{ width: '100%' }">
                </p-multiSelect>
              </div>
            </div>
            <div class="col-xl-1 col-md-2 col-sm-3">
              <div class="drpchildctrl">
                <span class="v1"></span>
                <label class="searchLable">MODULE TYPE</label>
                <p-multiSelect [showToggleAll]="false" [options]="moduleTypeList" [(ngModel)]="selectedModuleType"
                  (onChange)="moduleChange()" [showTransitionOptions]="'0ms'" [hideTransitionOptions]="'0ms'"
                  [defaultLabel]="'--Select--'" [virtualScroll]="true" [itemSize]="25" autoWidth="false"
                  [style]="{ width: '100%' }" [resetFilterOnHide]="true" optionLabel="module_type"
                  [panelStyle]="{ width: '100%' }">
                </p-multiSelect>
              </div>
              <!-- [disabled]="disablemodule" -->
            </div>
            <div class="col-xl-1 col-md-2 col-sm-3">
              <div class="drpchildctrl">
                <span class="v1"></span>
                <label class="searchLable">VAN MONTH</label>
                <p-multiSelect [showToggleAll]="false" [options]="vanMonthList" [(ngModel)]="selectedVanMonth"
                  [showTransitionOptions]="'0ms'" [hideTransitionOptions]="'0ms'" [defaultLabel]="'--Select--'"
                  [virtualScroll]="true" [itemSize]="25" autoWidth="false" [style]="{ width: '120%' }"
                  [resetFilterOnHide]="true" optionLabel="vanning_month" [panelStyle]="{ width: '120%' }"
                  (onChange)="vanMonthChange()">
                </p-multiSelect>
              </div>
            </div>
            <div class="col-xl-2 col-md-2 col-sm-3 reset_btn">
              <label class="resetIcon" (click)="onResetDropDown()">
                <img src="./assets/images/Group 115380.png" alt="refresh icon" />
                Reset
              </label>
              <span class="vertical"></span>
              <button class="search_btn" (click)="onSearch(0)">SEARCH</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="container-fluid">
    <div class="row">
      <div class="col-4">
        <!-- <img src="./assets/images/edit.svg" (click)="onEditMode()"  alt="edit button icon" title="Edit" class="img-tool"> -->
        <img class="img-tool" src="./assets/images/download.svg" alt="download icon" title="Download"
          (click)="exportAsXLSX()" />
      </div>
      <div class="col-4"></div>
      <div class="col-sm-4">
        <div class="records">
          <label class="page-drpdwn-label">RECORDS / PAGE:</label>
          <select class="page-drpdwn" (change)="onPageSizeChanged()" [(ngModel)]="paginationPageSize">
            <option value="10" selected>10</option>
            <option value="30">30</option>
            <option value="50">50</option>
            <option value="{{ rowData.length }}">All</option>
          </select>
        </div>
      </div>
    </div>
  </div>

  <div id="dvgrid" class="gridStyle">
    <app-grid class="table-outer" #osPartsGrid [columnDefs]="columnDefs" [rowData]="rowData"
      [rowSelection]="rowSelection" [gridOptions]="gridOptions" [pagination]="pagination"
      [paginationPageSize]="paginationPageSize" [context]="context" [suppressClickEdit]="suppressClickEdit"
      [frameworkComponents]="frameworkComponents" [tooltipShowDelay]="tooltipShowDelay" [tooltipMouseTrack]="true"
      [editType]="editType" [detailRowAutoHeight]="true">
    </app-grid>
  </div>
</div>

<ngx-spinner bdColor="rgba(51,51,51,0.8)" size="medium" color="#fff" type="ball-scale-multiple">
  <p style="font-size: 20px; color: white">Loading... Please wait...</p>
</ngx-spinner>