<div class="background">
  <div class="successMessage alert-dismissible">
    <div *ngIf="warningMessage" class="alert alert-warning" role="alert">
      {{ warningMessage }}
      <button
        type="button"
        class="btn-close"
        (click)="removeMessage()"
        data-bs-dismiss="alert"
        aria-label="Close"
      ></button>
    </div>
  

  <div *ngIf="successMessage" class="alert alert-success" role="alert">
    {{ successMessage }}
    <button
      type="button"
      class="btn-close"
      (click)="removeMessage()"
      data-bs-dismiss="alert"
      aria-label="Close"
    ></button>
  </div>
</div>
    <div class="container-fluid">
      <div class="d-flex flex-row">
        <div class="p-2 flex-grow-1">
          <h2 class="heading">Seal Verification</h2>
        </div>
        <div class="p-2 kanbanp2">
          <input
            type="text"
            [(ngModel)]="vesselSerachValue"
            class="form-control-sm kanbanform"
            placeholder="VESSEL NAME - Press Enter to Search"
            style="width:260px"
            (keyup.enter)="onEnterPressed($event)"
            [disabled]="isSearchDisabled"
          />
        </div>

      </div>
    </div>
  

  
  
    <div class="container-fluid">
      <div class="row">
        <div class="card">
          <div class="container-fluid">
            <div class="row">
              
                <div class="col-xl-1 col-md-2 col-sm-3">
                    <div class="drpchildctrl">
                      <label class="searchLable">EST ARRIVAL</label>
                      <input
                        [(ngModel)]="estDate"
                        class="form-control-sm"
                        type="date"
                        (change)="estChange()"
                        autoWidth="false"
                        [disabled]="disableEst"
                        style="width:100px"

                      />
                    </div>
                  </div>
              <div class="col-xl-1 col-md-2 col-sm-3 shipping_carrier">
                <div class="drpchildctrl">
                  <label class="searchLable">SHIPPING CARRIER</label>
                  <p-multiSelect
                    [(ngModel)]="selectedShippingCarrier"
                    [showToggleAll]="false"
                    (onChange)="onShippingCarrierChange()"
                    [options]="shipping_carrierList"
                    optionLabel="vessel_company"
                    [showTransitionOptions]="'0ms'"
                    [hideTransitionOptions]="'0ms'"
                    [defaultLabel]="'--Select--'"
                    autoWidth="true"
                    [style]="{ width: '100%' }"
                    [resetFilterOnHide]="true"
                    [disabled]="disableShippingCarrier"
                  >
                  </p-multiSelect>
                </div>
              </div>
              
              <div class="col-xl-1 col-md-2 col-sm-3 shipping_carrier">
                <div class="drpchildctrl">
                  <label class="searchLable">VESSEL NAME</label>
                  <p-multiSelect
                    [(ngModel)]="selectedVesselName"
                    [showToggleAll]="false"
                    (onChange)="onVesselNameChange()"
                    [options]="vesselNameList"
                    [showTransitionOptions]="'0ms'"
                    [hideTransitionOptions]="'0ms'"
                    [defaultLabel]="'--Select--'"
                    [virtualScroll]="true"
                    [itemSize]="25"
                    autoWidth="false"
                    [style]="{ width: '100%' }"
                    [resetFilterOnHide]="true"
                    optionLabel="vessel_name"
                    [panelStyle]="{ width: '140%' }"
                    [disabled]="disableVesselName"
                  >
                  </p-multiSelect>
                </div>
              </div>
  
              
              <div class="col-xl-1 col-md-2 col-sm-3 reset_btn">
                <label class="resetIcon" (click)="onResetDropDown()">
                  <img
                    src="./assets/images/Group 115380.png"
                    alt="refresh icon"
                  />
                </label>
                <label class="resetlabel" (click)="onResetDropDown()">Reset</label>
                <span class="vertical"></span>
              </div>
              <div class="col-xl-1 col-md-2 col-sm-3 search">
                <button class="search_btn" (click)="onSearch(0)">SEARCH</button>
              </div>
            </div>
          </div>
        </div>
      </div>
  
      
    </div>
  
    <div class="container-fluid">
      <div class="row">
        <div class="col-sm-6">
          <span class="edit_icon">
            <!-- <img
              *ngIf="access1.edit"
              (click)="onEditMode()"
              class="img-tool"
              src="./assets/images/edit.svg"
              alt="edit button icon"
              title="Edit"
            /> -->
            <img
              class="img-tool"
              (click)="exportAsXLSX()"
              src="./assets/images/download.svg"
              alt="download icon"
              title="Download"
            />
          </span>
        </div>
  


         <div class="col-sm-2">
          <!-- <button *ngIf="access1.matchmismatch"
            type="button"
            (click)="match()"
            class="seal_verify"

          >
            VERIFY
          </button> -->
        </div>
  
        <div class="col-sm-2">
          <div class="records">
            <div class="drpchildctrl">
              <p-multiSelect
                #dd
                class="page-drpdwn-label showhide"
                [(ngModel)]="selectedShowHideList"
                (onChange)="onShowHideChange($event)"
                [options]="showHideList"
                optionLabel="label"
                [displaySelectedLabel]="false"
                [defaultLabel]="'SHOW/HIDE COLUMN'"
                [showTransitionOptions]="'0ms'"
                [hideTransitionOptions]="'0ms'"
                autoWidth="true"
                [style]="{ width: '100%', background: '#F5F5F5' }"
                [resetFilterOnHide]="true"
              >
                <p-footer>
                  <div class="flex justify-content-between p-1">
                    <button type="button"  (click)="saveShowHide();dd.hide()" class="save_btn"> Save</button>
                  </div>
                </p-footer>
              </p-multiSelect>
            </div>
          </div>
        </div>
  
        <div class="col-sm-2">
          <div class="records">
            <label class="page-drpdwn-label">RECORDS / PAGE:</label>
            <select
              class="page-drpdwn"
              (change)="onPageSizeChanged()"
              [(ngModel)]="paginationPageSize"
            >
              <option value="10" selected>10</option>
              <option value="30">30</option>
              <option value="50">50</option>
              <option value="{{ rowData.length }}">All</option>
            </select>
          </div>
        </div>
      </div>
    </div>
  
    <div id="dvgrid" class="gridStyle">
      <app-grid
        class="table-outer"
        #osPartsGrid
        [columnDefs]="columnDefs"
        [rowData]="rowData"
        [rowSelection]="rowSelection"
        [gridOptions]="gridOptions"
        [pagination]="pagination"
        [paginationPageSize]="paginationPageSize"
        [context]="context"
        [suppressClickEdit]="suppressClickEdit"
        [frameworkComponents]="frameworkComponents"
        [tooltipShowDelay]="tooltipShowDelay"
        [tooltipMouseTrack]="true"
        [editType]="editType"
      >
      </app-grid>
    </div>
  
  
    <div  *ngIf="isEditMode" class="saveCancel">
      <button
        matRipple
        [matRippleCentered]="true"
        [matRippleUnbounded]="false"
        class="review_btn"
        (click)="saveChanges()"
      >
        Save Changes
      </button>
      <button (click)="cancelEdit()" class="cancel_btn">Cancel</button>
    </div>
  
  </div>
    <ngx-spinner
      bdColor="rgba(51,51,51,0.8)"
      size="medium"
      color="#fff"
      type="ball-scale-multiple"
    >
      <p class="loading">Loading... Please wait...</p>
    </ngx-spinner>
  
  