import {
  CUSTOM_ELEMENTS_SCHEMA,
 
  NgModule
} from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { MatRippleModule } from '@angular/material/core';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { NavbarComponent } from './core/navbar/navbar.component';
import { MatLegacyListModule as MatListModule } from '@angular/material/legacy-list';
import { MatLegacySelectModule as MatSelectModule } from '@angular/material/legacy-select';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatLegacyButtonModule as MatButtonModule } from '@angular/material/legacy-button';
import { MatLegacySlideToggleModule as MatSlideToggleModule } from '@angular/material/legacy-slide-toggle';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MatIconModule } from '@angular/material/icon';
import { MatLegacyMenuModule as MatMenuModule } from '@angular/material/legacy-menu';
import { EnvironmentModule } from './environment/environment.module';
import { PipeRelasenotesComponent } from './pipe-relasenotes/pipe-relasenotes.component';
import { TooltipModule } from 'primeng/tooltip';

import {
  Environments,
  environments,
  EnvironmentConfiguration,
} from './environment/services/environment.configuration';

import { DragDropModule } from '@angular/cdk/drag-drop';

import { HomeComponent } from './home/home.component';
import { FooterComponent } from './core/footer/footer.component';
import { JobstatusComponent } from './jobstatus/jobstatus.component';
import { ClickOutsideDirective } from './core/navbar/clickOutside.directive';

import { AgGridModule } from 'ag-grid-angular';

import { AgGridCheckboxComponent,saveDialog2 } from './ag-grid-checkbox/ag-grid-checkbox.component';
import { DownloadComponent } from './download/download.component';
import { AdjustmentInquiryComponent } from './adjustment-inquiry/adjustment-inquiry.component';
import { HeaderCheckboxComponent } from './core/grid/header-checkbox/header-checkbox.component';
import { GridRefreshComponent } from './core/grid/grid-refresh/grid-refresh.component';
import { RowCheckboxComponent } from './core/grid/row-checkbox/row-checkbox.component';
import { DropdownModule } from 'primeng/dropdown';
import { NgxSpinnerModule } from 'ngx-spinner';
import { MatLegacyCheckboxModule as MatCheckboxModule } from '@angular/material/legacy-checkbox';
import { GridComponent } from './core/grid/grid/grid.component';
import { MultiSelectModule } from 'primeng/multiselect';
import { BreadcrumbComponent } from './core/breadcrumb/breadcrumb.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { PartbystatusDateviewComponent } from './home/partbystatus-dateview/partbystatus-dateview.component';
import { PipeReportsComponent } from './pipe-reports/pipe-reports.component';
import { GridDevancontainerComponent } from './container-maintenance/review-changes/grid-devancontainer.component';
import { ModuleTypeComponent } from './module-type/module-type.component';
import { ModuleTypeActionsComponent } from './module-type/module-type-actions/module-type-actions.component';
import { ModuleTypeEditDialogComponent } from './module-type/module-type-edit-dialog/module-type-edit-dialog.component';
import { ModuleTypeDeleteDialogComponent } from './module-type/module-type-delete-dialog/module-type-delete-dialog.component';

//Msal
import {
  MsalService,
  MSAL_INSTANCE,
  MsalGuard,
  MsalInterceptor,
  MsalBroadcastService,
} from './msal';
import {
  IPublicClientApplication,
  PublicClientApplication,
  InteractionType,
} from '@azure/msal-browser';
import { MSAL_GUARD_CONFIG, MSAL_INTERCEPTOR_CONFIG } from './msal/constants';
import { MsalGuardConfiguration } from './msal/msal.guard.config';
import { MsalInterceptorConfig } from './msal/msal.interceptor.config';
import { MatExpansionModule } from '@angular/material/expansion'
import { RoleGuard } from './msal/roles.guard';
import { ShiftConfigurationComponent } from './configuration-shifts/configuration-shifts.component';
import { BreaksDetailviewComponent } from './configuration-shifts/breaks-detailview/breaks-detailview.component';
import { ViewBreakComponent } from './configuration-shifts/breaks-detailview/review-changes/review-changes.component';
import { GridShiftsDateEditorComponent } from './configuration-shifts/breaks-detailview/review-changes/grid-editaStartdate';
import { GridShiftsTimeEditorComponent } from './configuration-shifts/breaks-detailview/review-changes/review-container.component/grid-edittimeshifts';
import { ReviewShiftsComponent } from './configuration-shifts/breaks-detailview/review-changes/review-container.component/review-shifts.component';
import { DeleteBreaksComponent } from './configuration-shifts/breaks-detailview/delete-breaks/delete-breaks.component';
import { ShiftsEditDialogComponent } from './configuration-shifts/breaks-detailview/shifts-edit-dialog/shifts-edit-dialog.component';
import { EditBreaksComponent } from './configuration-shifts/breaks-detailview/edit-breaks/edit-breaks.component';
import { EditBtnCellRender } from './configuration-shifts/breaks-detailview/review-changes/review-container.component/edit-btn-cell-render';
import { GridBreaksTimeEditorComponent } from './configuration-shifts/breaks-detailview/review-changes/review-container.component/grid-edittimebreaks';
import { CopyShiftsDialogComponent } from './configuration-shifts/breaks-detailview/copy-shifts-dialog/copy-shifts-dialog.component';
import { editCumulplanComponent } from './production-progress/edit-dialog/edit-cumul-plannet.component';
import { EditCumulPlanNetComponent } from './production-progress/edit-dialog/edit-popup/cumul-plannet-popup.component';
import { ProdProgressIntvolEditComponent } from './production-progress/edit-dialog/progress-grid-intvol-edit-component';
import { recalculateComponent } from './production-progress/recalculate/recalculate-component';
import { UpdateDevanPlanPPComponent } from './production-progress/recalculate/update-devan-plan-component';
import { ProductionProgressComponent } from './production-progress/production-progress.component';
import { ProgressEditDialogComponent } from './production-progress/edit-dialog/edit-dialog.component';
import { ProdProgressFinalEditComponent } from './production-progress/edit-dialog/progress-grid-final-edit.component';
import { updateDevanPlanComponent } from './production-progress/confirmation-popup/delete-airorder/update-devanplan.component';
import { DeleteBtnCellRender } from './configuration-shifts/breaks-detailview/review-changes/review-container.component/delete-btn-cell-render';
export function MSALInstanceFactory(): IPublicClientApplication {
  const env = new EnvironmentConfiguration(environments);

  return new PublicClientApplication({
    auth: {
      clientId: env.CLIENT_ID,
      authority: env.TENANT_ID,
      redirectUri: env.REDIRECT_URL,
      postLogoutRedirectUri: env.REDIRECT_URL,
    },
  });
}

export function MSALInterceptorConfigFactory(): MsalInterceptorConfig {
  const protectedResourceMap = new Map<string, Array<string>>();
  protectedResourceMap.set('https://graph.microsoft.com/v1.0/me', [
    'user.read',
  ]);

  return {
    interactionType: InteractionType.Redirect,
    protectedResourceMap,
  };
}

import { OrderforecastComponent } from './orderforecast/orderforecast.component';
import { CustomTooltipComponent } from './core/custom-tooltip/custom-tooltip.component';
import { ConfirmCancelDailogComponent } from './core/confirm-cancel-dailog/confirm-cancel-dailog.component';
import { MatLegacyDialogModule as MatDialogModule } from '@angular/material/legacy-dialog';
import { DialogGridComponent } from './core/dialog-grid/dialog-grid.component';
import { GridTextEditorComponent } from './core/grid/grid-text-edit.component';
import { MasterSetupComponent } from './master-setup/master-setup.component';
import { MasterRefreshheaderComponent } from './core/master-refreshheader/master-refreshheader.component';
import { MasterGridrefreshComponent } from './core/master-gridrefresh/master-gridrefresh.component';
import { SetupActionsComponent } from './core/setup-actions/setup-actions.component';

import { MatLegacyCardModule as MatCardModule } from '@angular/material/legacy-card';
import { MasterEditDialogComponent } from './core/master-edit-dialog/master-edit-dialog.component';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { MasterDeleteComponent } from './core/master-delete/master-delete.component';
import {
  RunDownMaintenanceComponent,
  saveDialog,
} from './run-down-maintenance/run-down-maintenance.component';
import { ButtonrenderComponent } from './jobstatus/buttonrender/buttonrender.component';
import {
  BulkAdjustmentComponent,
  saveDialog1,
} from './bulk-adjustment/bulk-adjustment.component';
import { DndDirective } from './bulk-adjustment/dnd.directive';
import { ReportsComponent } from './reports/reports.component';
import { GridProdVolEditorComponent } from './core/grid/grid-text-editrundown.component';
import { GridVanningVolEditorComponent } from './core/grid/grid-vanvolum-edit.component';
import { GridProdDtEditorComponent } from './core/grid/grid-prodDt-edit.component';
import { GridVanDtEditorComponent } from './core/grid/grid-vanDt-edit.component';
import { GridOrdDtEditorComponent } from './core/grid/grid-ordDt-edit.component';
import { GridCalcDtEditorComponent } from './core/grid/grid-calcDt-edit.component';
import { RundownDialogComponent } from './core/rundown-dialog/rundown-dialog.component';
import { ReportCheckboxComponent } from './core/grid/report-checkbox/report-checkbox.component';
import { ReportHeaderCheckboxComponent } from './core/grid/report-header-checkbox/report-header-checkbox.component';
import { GridtextchangeComponent } from './reports/gridtextchange/gridtextchange.component';
import { RundownUploadComponent } from './run-down-maintenance/rundown-upload/rundown-upload.component';
import { FluctuationAllowanceMaintenanceComponent } from './fluctuation-allowance-maintenance/fluctuation-allowance-maintenance.component';
import { FAGridTextEditorComponent } from './fluctuation-allowance-maintenance/fa-grid-text-edit.component';
import { FACommentsEditorComponent } from './fluctuation-allowance-maintenance/fa-comments-edit.component';
import { CalenderSetupComponent } from './calender-setup/calender-setup.component';
import { CalenderGridCheckboxComponent } from './calender-setup/calender-grid-checkbox/calender-grid-checkbox.component';
import { KanpanDialogComponent } from './home/kanpan-dialog/kanpan-dialog.component';
import { ContactComponent } from './contact/contact.component';
import { GetSudoStatusComponent } from './get-sudo-status/get-sudo-status.component';
import { GetSudoCellRendererComponent } from './get-sudo-status/get-sudo-cell-renderer/get-sudo-cell-renderer.component';
import { FaqComponent } from './faq/faq.component';
import { EditDialogComponent } from './fluctuation-allowance-maintenance/edit-dialog/edit-dialog.component';
import { OfErrorDialogComponent } from './orderforecast/of-error-dialog/of-error-dialog.component';
import { TrainingVideosComponent } from './training-videos/training-videos.component';
import { ReleaseNotesComponent } from './release-notes/release-notes.component';
import { PipeJobstatusComponent } from './pipe-jobstatus/pipe-jobstatus.component';
import {StatusrenderComponent} from "./pipe-jobstatus/statusrender/statusrender.component";
import { TogglestatusComponent } from './pipe-jobstatus/togglestatus/togglestatus.component';
import { PartsinquireComponent } from './partsinquire/partsinquire.component';
import { PartMaintenanceComponent } from './part-maintenance/part-maintenance.component';
import { InquiryCommentsEditorComponent } from './partsinquire/inquirycomments-component';
import {ReviewChangesComponent} from './part-maintenance/review-changes/review-changes.component'
import {GridTapVolEditorComponent} from './part-maintenance/grid-tapVol-component';
import {PartsMaintenanceCommentsComponent} from './part-maintenance/part-maintenance-comments.component'
import{ContainerMaintenanceComponent} from './container-maintenance/container-maintenance.component'
import {ContainerDevandialogComponent} from './container-maintenance/containermaintenace-dialog.ts/container-devandialog/container-devandialog.component'
import { ContainerundevanDialogComponent } from './container-maintenance/containermaintenace-dialog.ts/containerundevan-dialog/containerundevan-dialog.component';
import { DevanButtonrenderComponent } from './container-maintenance/devanbutton/devanbuttonrender.component';
import { ReviewContainerComponentComponent } from './container-maintenance/review-changes/review-container.component/review-container.component';
import { GridConatinerActualdtEditorComponent } from './container-maintenance/review-changes/review-container.component/grid-editactualnamcdt';
import { SealverificationdialogComponent } from './seal-verification/sealverification-dialog/sealverification-dialog-component';
import { ModifiedboltComponent } from './seal-verification/modifiedboltseal-component';
import { SealVerificationComponent ,matchMismatchDialog} from './seal-verification/seal-verification.component';
import { TapmoduleComponent } from './part-maintenance/dialog-tapmodule/tapmodule.component';
import { TapconfirmComponent } from './part-maintenance/tapconfirmation-dialog/tapconfirm.component';
import { SealStatusHistoryComponent } from './seal-verification/sealStatusHistory-component';
import { AirFreightOrderComponent } from './air-freight-order/air-freight-order.component';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { AirorderDeleteComponent } from './air-freight-order/delete-airorder/airorder-delete.component';
import { AirFreightActionsComponent } from './air-freight-order/air-freight-actions/air-freight-actions.component';
import { AddEditAirRecordComponent } from './air-freight-order/add-edit-air-record/add-edit-air-record.component';
import { EditFormComponent } from './air-freight-order/add-edit-air-record/edit-form/edit-form.component';
import { ScheduleDeleteComponent } from './air-freight-order/schedule-delete/schedule-delete.component';
import { CustomHeaderComponent } from './air-freight-order/custom-header/custom-header.component';
import { ActualArrivalEditableComponent } from './air-freight-order/add-edit-air-record/actual-arrival-editable/actual-arrival-editable.component';
import { DeadlineEditableComponent } from './air-freight-order/add-edit-air-record/actual-arrival-editable/deadline-editable.component';
import { NoOfPiecesEditableComponent } from './air-freight-order/add-edit-air-record/actual-arrival-editable/no-of-pieces-editable.component';
import { NoOfLotsEditableComponent } from './air-freight-order/add-edit-air-record/actual-arrival-editable/no-of-lots.editable.component';
import { ConfLotsEditableComponent } from './air-freight-order/add-edit-air-record/actual-arrival-editable/conf-lots-editable.component';
import { EtaDateEditableComponent } from './air-freight-order/add-edit-air-record/actual-arrival-editable/eta-date-editable.component';
import { CaseNoEditableComponent } from './air-freight-order/add-edit-air-record/actual-arrival-editable/case-no-editable.component';
import { AirfreightArrivedCheckboxComponent } from './air-freight-order/airfreight-arrived-checkbox/airfreight-arrived-checkbox.component';
import { ConfigParameterComponent } from './air-freight-order/add-edit-air-record/config-parameter/config-parameter.component';
import { EditActualArrivalEditableComponent } from './air-freight-order/add-edit-air-record/edit-schedule-editable/edit-actual-arrival-editable.component';
import { EditDeadlineEditableComponent } from './air-freight-order/add-edit-air-record/edit-schedule-editable/edit-deadline-editable.component ';
import { EditConfPiecesEditableComponent } from './air-freight-order/add-edit-air-record/edit-schedule-editable/edit-conf-pieces-editable.component ';
import { EditNoOfLotsEditableComponent } from './air-freight-order/add-edit-air-record/edit-schedule-editable/edit-no-of-lots-editable.component';
import { EditNoOfPiecesEditableComponent } from './air-freight-order/add-edit-air-record/edit-schedule-editable/edit-no-of-pieces-editable.component';
import { EditVanCenterEtaEditableComponent } from './air-freight-order/add-edit-air-record/edit-schedule-editable/edit-vancenter-etadate-editable.component';
import { ConfEditableComponent } from './air-freight-order/add-edit-air-record/actual-arrival-editable/conf-editable.component';
import { EtaTimeEditableComponent } from './air-freight-order/add-edit-air-record/actual-arrival-editable/eta-time-editable.component';
import { MatTabsModule } from '@angular/material/tabs';
import { EditConfLotsEditableComponent } from './air-freight-order/add-edit-air-record/edit-schedule-editable/edit-conf-lots-editable.component';
import { AirOrderCellRendererComponent } from './air-freight-order/air-order-cell-renderer/air-order-cell-renderer.component';
import { StockingPaceInquiryComponent } from './stocking-pace-inquiry/stocking-pace-inquiry.component';
import { StockingPaceMaintenanceComponent } from './stocking-pace-maintenance/stocking-pace-maintenance.component';
import { UpdatedStockingPaceComponent } from './stocking-pace-maintenance/updated-stocking-pace.component';
import { ContainerOrderingComponent } from './container-ordering/container-ordering.component';
import { DeleteBreaksCoComponent } from './container-ordering/container-ordering-dialog/delete-breaks-co/delete-breaks-co.component';
import { ModuleRowCheckboxComponent } from './module-type/module-type-actions/module-type-checkbox.componet';
import { ModuleStockingDevanningComponent } from './module-stocking-devanning/module-stocking-devanning.component';
import { ModuleStockingGridCheckboxComponent } from './module-stocking-devanning/module-stocking-grid-checkbox.component';

@NgModule({
  exports: [DragDropModule],
  declarations: [
    AppComponent,
    ClickOutsideDirective,
    NavbarComponent,
    HomeComponent,
    FooterComponent,
    JobstatusComponent,
    BreadcrumbComponent,
    AgGridCheckboxComponent,
    DownloadComponent,
    AdjustmentInquiryComponent,
    HeaderCheckboxComponent,
    GridRefreshComponent,
    RowCheckboxComponent,
    GridComponent,
    OrderforecastComponent,
    CustomTooltipComponent,
    ConfirmCancelDailogComponent,
    DialogGridComponent,
    GridTextEditorComponent,
    GridProdVolEditorComponent,
    GridVanningVolEditorComponent,
    GridProdDtEditorComponent,
    GridVanDtEditorComponent,
    GridOrdDtEditorComponent,
    GridCalcDtEditorComponent,
    MasterSetupComponent,
    MasterRefreshheaderComponent,
    MasterGridrefreshComponent,
    SetupActionsComponent,
    MasterEditDialogComponent,
    MasterDeleteComponent,
    RunDownMaintenanceComponent,
    ButtonrenderComponent,
    saveDialog2,
    saveDialog,
    saveDialog1,
    BulkAdjustmentComponent,
    DndDirective,
    ReportsComponent,
    RundownDialogComponent,
    ReportCheckboxComponent,
    ReportHeaderCheckboxComponent,
    GridtextchangeComponent,
    RundownUploadComponent,

    FluctuationAllowanceMaintenanceComponent,
    FAGridTextEditorComponent,
    FACommentsEditorComponent,
    GetSudoStatusComponent,
    GetSudoCellRendererComponent,
    CalenderSetupComponent,
    CalenderGridCheckboxComponent,
    KanpanDialogComponent,
    ContactComponent,
    FaqComponent,
    EditDialogComponent,
    OfErrorDialogComponent,
    TrainingVideosComponent,
    ReleaseNotesComponent,
    PartbystatusDateviewComponent,
    InquiryCommentsEditorComponent,
    PipeJobstatusComponent,
    StatusrenderComponent,
    TogglestatusComponent,
    PipeReportsComponent,
    PartsinquireComponent,
    PartMaintenanceComponent,
    ReviewChangesComponent,
    GridTapVolEditorComponent,
    PartsMaintenanceCommentsComponent,
    ContainerMaintenanceComponent,
    ContainerDevandialogComponent,
    ContainerundevanDialogComponent,
    DevanButtonrenderComponent,
    ReviewContainerComponentComponent,
    GridConatinerActualdtEditorComponent,
    SealverificationdialogComponent,
    ModifiedboltComponent,
    SealVerificationComponent,
    TapmoduleComponent,
    TapconfirmComponent,
    matchMismatchDialog,
    SealStatusHistoryComponent,
    GridDevancontainerComponent,
    PipeRelasenotesComponent,
    AirFreightOrderComponent,
    AirorderDeleteComponent,
    AirFreightActionsComponent,
    AddEditAirRecordComponent,
    EditFormComponent,
    ScheduleDeleteComponent,
    CustomHeaderComponent,
    ActualArrivalEditableComponent,
    DeadlineEditableComponent,
    NoOfPiecesEditableComponent,
    NoOfLotsEditableComponent,
    ConfLotsEditableComponent,
    EtaDateEditableComponent,
    CaseNoEditableComponent,
    AirfreightArrivedCheckboxComponent,
    ConfigParameterComponent,
    EditActualArrivalEditableComponent,
    EditDeadlineEditableComponent,
    EditNoOfPiecesEditableComponent,
    EditNoOfLotsEditableComponent,
    EditConfPiecesEditableComponent,
    EditVanCenterEtaEditableComponent,
    ConfEditableComponent,
    EtaTimeEditableComponent,
    ConfigParameterComponent,
    EditConfLotsEditableComponent,
    AirOrderCellRendererComponent,
    StockingPaceInquiryComponent,
    StockingPaceMaintenanceComponent,
    UpdatedStockingPaceComponent,
    ContainerOrderingComponent,
    DeleteBreaksCoComponent,
    ModuleTypeComponent,
    ModuleTypeActionsComponent,
    ModuleTypeEditDialogComponent,
    ModuleTypeDeleteDialogComponent,
    ModuleRowCheckboxComponent,
    ShiftConfigurationComponent,
    BreaksDetailviewComponent,
    ViewBreakComponent,
    GridShiftsDateEditorComponent,
    GridShiftsTimeEditorComponent,
    ReviewShiftsComponent,
    DeleteBreaksComponent,
    ShiftsEditDialogComponent,
    EditBreaksComponent,
    EditBtnCellRender,
    GridBreaksTimeEditorComponent,
    CopyShiftsDialogComponent,

    ProductionProgressComponent,
    editCumulplanComponent,
    EditCumulPlanNetComponent,
    ProdProgressIntvolEditComponent,
    recalculateComponent,
    UpdateDevanPlanPPComponent,
    ProgressEditDialogComponent,
    updateDevanPlanComponent,
    ProdProgressFinalEditComponent,
    

    ModuleStockingGridCheckboxComponent,
    ModuleStockingDevanningComponent,
    DeleteBtnCellRender

  ],
  imports: [
    HttpClientModule,
    BrowserModule,
    AppRoutingModule,
    MatListModule,
    MatToolbarModule,
    BrowserAnimationsModule,
    MatButtonModule,
    MatSelectModule,
    MatIconModule,
    MatMenuModule,
    AgGridModule,
    FormsModule,
    DropdownModule,
    MatRippleModule,
    NgxSpinnerModule,
    MatCheckboxModule,
    MultiSelectModule,
    EnvironmentModule,
    MatIconModule,
    MatDialogModule,
    ReactiveFormsModule,
    MatCardModule,
    MatSlideToggleModule,
    DragDropModule,
    MatExpansionModule,
    TooltipModule,
    MatTabsModule
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],

  providers: [
    {
      provide: Environments,
      useValue: environments,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: MsalInterceptor,
      multi: true,
    },
    {
      provide: MSAL_INSTANCE,
      useFactory: MSALInstanceFactory,
    },
    {
      provide: MSAL_GUARD_CONFIG,
      useValue: {
        interactionType: InteractionType.Redirect,
      } as MsalGuardConfiguration,
    },
    {
      provide: MSAL_INTERCEPTOR_CONFIG,
      useFactory: MSALInterceptorConfigFactory,
    },
    MsalService,
    MsalGuard,
    RoleGuard,
    EnvironmentConfiguration,
    MsalBroadcastService,
    {provide: MatDialogRef, useValue: {}},
        {provide: MAT_DIALOG_DATA, useValue: []},
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
