import { Component } from '@angular/core';
import * as mm from 'moment-timezone';
import { AirfreightApicallsService } from 'src/app/services/air-freight-validation/airfreight-apicalls.service';
import * as moment from 'moment';

@Component({
  selector: 'app-actual-arrival-editable',
  template: `
    <div style="margin: 3px 0px 6px;">
      <input #input [(ngModel)]="value" type="date" [min]="minDate" class="grid-cell-edit-layout" (change)="textUpdated()"/>      
      <small *ngIf="!value && errorMsg && params.context.componentParent.submitted && !datecheck" class="error">{{errorMsg}}</small>
      <small *ngIf="datecheck" class="error">{{datecheck}}</small>
    </div>
  `,
  styles: [
    `
       input::-webkit-outer-spin-button,
      input::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
      }
      .grid-cell-edit-layout {
        width: 100%;
        border: 1px solid #d1d1d1;
      }
      .invalid-text {
        border: 2px solid red !important;
      }
      .error{color:red}
    `,
  ],
})
export class ActualArrivalEditableComponent {
  public params: any;
  showInput = true;
  value: any;
  isEmpty = true;
  minDate: string;
  easternCurrentDate: string;
  errorMsg = `Arrival DT is requird`;
  datecheck = null;
  // calendarList: any[] = [];

  constructor(private readonly airFreightService: AirfreightApicallsService) { }

  async agInit(params: any): Promise<void> {
    this.easternCurrentDate = String(mm().tz('US/Michigan').format('YYYY-MM-DD')).substring(0, 10);
    this.minDate = this.easternCurrentDate;

    params.data.arrivalDate = this;
    if (params.column === 'arriving_date') {
      this.value = params.data.arriving_date;
    }

    this.params = params;
  }
  // Called when the cell is refreshed
  refresh(params: any): boolean {
    this.params = params;
    return true;
  }

  textUpdated() {  
    if (this.params.column === 'arriving_date') {
      if (this.value !== this.params.data.arriving_date) {
        this.params.data.arriving_date = this.value;
        this.params.data.isUpdated = true;
      }
    }
    if (this.params.context.componentParent.empty_valueCheck(this.value)) {
      this.errorMsg = `Arrival DT is requird`;
    } else {
      // if(this.value)
        // this.checkNonworkingday(this.params.data.partIndex, this.value);
    }
    this.updatedeadlinedate();
  }
  checkNonworkingday(part_index, selected_date): void {
    let validDate: any = this.params.context.componentParent.checkNonworkingday(part_index, selected_date);
    if (validDate) {
      if(validDate.check == 1){
      this.value = null;
      this.params.data.arriving_date = null;
      }
      this.datecheck = validDate.msg;
      this.params.data.schedule_error = true;      
      
    } else {
      this.datecheck = null;
    }
  //   setTimeout(()=>{ 
  //     this.datecheck = null;
  // }, 10000);
  }
  updatedeadlinedate(): void {
    // this.params.context.componentParent.updateScheduleData(this.params.data.partIndex, this.params.node.rowIndex, "deadline_date", null);
  }
  onKeyPress(event) {
    if (event.target.value.length <= 4) {
      return true;
    } else {
      return false;
    }
  }
}