export interface Schedule {
    arriving_date: string;
    eta_date: string;
    case_no: string;
    deadline_date: string;
    number_of_pieces: number;
    number_of_lots: number;
    conf_lots: number;
    conf_pieces: number;
    air_order_status: string;
    isArrived: boolean;
    sched_id: any;
}

export interface Payload {
    order_type: string;
    part_num: string;
    kanban: string;
    dest_code: string;
    air_order_no: string;
    business_entity: string;
    userid: string;
    workid: string;
    date_raised: string;
    kvc_conf: string;
    dock: string;
    user_role: string;
    reason: string;
    pieces_to_order: number;
    part_type: string;
    resp: string;
    order_lot: number;
    volume: number;
    air_case_code: string;
    box_weight: number;
    part_desc: string;
    buco_code: string;
    comment1: any;
    comment2: any;
    cost_calc: number;
    manager_id: string;
    kvc_id: string;
    total_order: number;
    est_cost: number;
    lots_to_order: number;
    raised_by: string;
    box_volume: string;
    schedules: Schedule[];
    warnings: string[];
    isCountMatched: boolean;
    psetainfo: string;
}
export let createPayload = async (airOrdarValues: any, airOrdarRowValues: any, airorderNumber: any, businessEntity: any, userId: any, workId: any, userRole: any, calendarList) => {
    let partArray: any = []
    var validForm = true;
    let payloadArray: Payload[] = [];
    var od = airOrdarRowValues.orderDetails;
    let objParts: any = airOrdarValues.obj_parts;
    let rowObjPart: any = airOrdarRowValues.obj_parts;
    var warningMessage: string[] = [];
    console.log(objParts);
    console.log(rowObjPart);
    objParts.forEach(async (part, index) => {
        let schedulars = part.obj_schedules;
        let kanban: any = null;
        let partNo: any = null;
        if (od.partType == "Production") {
            kanban = part.kanbanNo?.kanban;
            if (!kanban) {
                kanban = rowObjPart[index].kanbanNo?.kanban;
            }
            partNo = part.partNo?.item_id;
            if (!partNo) {
                partNo = rowObjPart[index].partNo?.item_id;
            }
        } else {
            kanban = part.kanbanNo;
            if (!kanban) {
                kanban = rowObjPart[index].kanbanNo;
            }
            partNo = part.partNo;
            if (!partNo) {
                partNo = rowObjPart[index].partNo;
            }
        }
        let c_Dimention = part.cubicDimension;
        if (!c_Dimention)
            c_Dimention = rowObjPart[index].cubicDimension;
        let dest_code = part.destCode?.dest_code;
        if (!dest_code)
            dest_code = rowObjPart[index].destCode.dest_code;
        let b_weight = part.boxWeight;
        if (!b_weight)
            b_weight = rowObjPart[index].boxWeight;
        let dock = part.dockNo?.dock;
        if (!dock)
            dock = rowObjPart[index].dockNo?.dock;
        let payload: Payload = {
            // row 1
            order_type: od.orderType?.order_type,
            part_type: od.partType,

            // row 2
            manager_id: od.managerName?.contact_id,
            kvc_id: od.KvcName?.contact_id,
            buco_code: od.budgetCodes?.budget_code,
            // row 3
            date_raised: od.orderDate,
            raised_by: od.raisedBy,
            resp: od.responsibilityregion,

            // part details
            // row 1
            dock: dock,
            air_case_code: part.airCode?.air_case_code,
            dest_code: dest_code,
            // row 2
            kanban: kanban,
            part_num: partNo,
            part_desc: part.part_description,
            // row 3
            pieces_to_order: part.piecesToOrder,
            lots_to_order: part.lotsToOrder,
            cost_calc: part.costCalculation,
            total_order: part.totalOrder,
            // row 4
            box_volume: c_Dimention,
            box_weight: b_weight,
            est_cost: part.estimatedCost,
            order_lot: part.qpcBox,
            // row 5
            reason: part.reason?.reason_code,
            comment1: !empty_valueCheck(part.firstComment) ? part.firstComment.trim() : null,
            psetainfo: part.pSETAInfo,
            // row 6
            comment2: !empty_valueCheck(part.secondComment) ? part.secondComment.trim() : null,
            volume: part.volumesize,
            kvc_conf: part.vanConfirmation,
            schedules: [],

            air_order_no: od.air_order_no,
            business_entity: businessEntity,
            userid: userId,
            workid: workId,
            user_role: userRole,
            warnings: [],
            isCountMatched: true
        }
        schedulars.forEach((element: any) => {
            let sch: Schedule = {
                arriving_date: element.arrivalDate?.value,
                eta_date: element.etaDate?.value,
                case_no: element.casenoEditable?.value?.toUpperCase(),
                deadline_date: element.deadlineDate?.value,
                number_of_pieces: element.noOfPieces?.value,
                number_of_lots: element.noOfLots?.value,
                conf_lots: element.configureLots?.value,
                conf_pieces: element.conf_pieces,
                air_order_status: element.isArrived ? 'ARRIVED' : part.vanConfirmation === 'Yes' ? 'CONFIRMED' : 'CREATED',
                isArrived: element.isArrived,
                sched_id: element.sched_id
            }
            payload.schedules.push(sch);
        });
        payloadArray.push(payload);
        const filteredList = calendarList.filter((item) => item.dock == payload.dock);
        let schedular_validation_details: any = null;
        if (payload.order_type == 'Lots')
            schedular_validation_details = await validate_peaceOfOrder(payload.lots_to_order, payload.schedules, 1, (index + 1), filteredList);
        else if (payload.order_type == 'Pieces')
            schedular_validation_details = await validate_peaceOfOrder(payload.pieces_to_order, payload.schedules, 2, (index + 1), filteredList);
        payload.warnings = schedular_validation_details.warningMessage;
        payload.isCountMatched = schedular_validation_details.isCountMatched;
        if (payload.warnings.length > 0)
            if (validForm) validForm = false;
        if (empty_valueCheck(payload.order_type)) {
            warningMessage.push(`ORDER TYPE could not be empty`);
            if (validForm) validForm = false;
        } if (empty_valueCheck(payload.dest_code)) {
            warningMessage.push(`DEST CODE could not be empty`);
            if (validForm) validForm = false;
        }
        if (empty_valueCheck(payload.date_raised)) {
            warningMessage.push(`ORDER DT could not be empty`);
            if (validForm) validForm = false;
        }
        if ((empty_valueCheck(payload.pieces_to_order) && empty_valueCheck(payload.lots_to_order))
            || empty_valueCheck(payload.dock)
            || (empty_valueCheck(payload.kanban) || payload.kanban.length != 4)
            || (empty_valueCheck(payload.part_num) || payload.part_num.length != 12)
            || (empty_valueCheck(payload.box_weight) && empty_valueCheck(payload.box_volume))
            || empty_valueCheck(payload.air_case_code)
            || empty_valueCheck(payload.reason)
            || payload.warnings.length > 0) {
            if (!empty_valueCheck(payload.part_num) && !empty_valueCheck(payload.kanban)) {
                warningMessage.push(`Please correct errors for Part: ${payload.part_num} (Kanban: ${payload.kanban})`);
            } else {
                warningMessage.push(`Please check errors in part ${(index + 1)}`);
            }
            if (validForm) validForm = false;
        }
        if (payload.warnings.length > 0) {
            if (validForm) validForm = false;
        }
        var currentPart_detail = ``;
        if (!empty_valueCheck(payload.dock) && !empty_valueCheck(payload.part_num))
            currentPart_detail = `${payload.dock}-${payload.part_num}`;
        let filteredpartData = partArray.filter((x) => x.partDetail == currentPart_detail);
        if (filteredpartData.length > 0) {
            let temp_msg = `Part No could not be same for part ${index + 1} and `
            filteredpartData.forEach((pd, index) => {
                if (index != 0)
                    temp_msg += ` and `;
                temp_msg += pd.partNo;
            });
            warningMessage.push(temp_msg);
            if (validForm) validForm = false;
        } else {
            if (currentPart_detail !== ``)
                partArray.push({ "partNo": index + 1, "partDetail": currentPart_detail });
        }

    });
    if (warningMessage.length > 0) {
        if (validForm) validForm = false;
    }
    return { "payloadArray": payloadArray, "validForm": validForm, errors: warningMessage }
}

var validate_peaceOfOrder = async (peaceofOrderr: number, requiredArrivalSchdulesArray: any, value_: number, part_no: number, calendarList) => {
    let schedulesNoOfPeace: number = 0;
    let warningMessage: string[] = [];
    let isCountMatched: boolean = true;
    requiredArrivalSchdulesArray.forEach((value: Schedule, index: number) => {
        if (value_ == 1 && !empty_valueCheck(value.conf_lots) && parseInt(value.conf_lots + '') <= 0) {
            warningMessage.push(`Conf lots can't be negative or 0 in row No. ${index + 1}`);
        }
        if (value_ == 2 && !empty_valueCheck(value.conf_pieces) && parseInt(value.conf_pieces + '') <= 0) {
            warningMessage.push(`Conf Pieces can't be negative or 0 in row No. ${index + 1}`);
        }
        if (value_ == 1) {
            if ((empty_valueCheck(value.conf_lots) || parseInt(value.conf_lots + '') <= 0) && value.eta_date != 'Invalid date' && !empty_valueCheck(value.eta_date)) {
                warningMessage.push(`Conf lots must be enter when Van Center ETA date is filled in row No. ${index + 1}`);
            }
        } else if (value_ == 2) {
            if ((empty_valueCheck(value.conf_pieces) || parseInt(value.conf_pieces + '') <= 0) && value.eta_date != 'Invalid date' && !empty_valueCheck(value.eta_date)) {
                warningMessage.push(`Conf Pieces must be enter when Van Center ETA date is filled in row No. ${index + 1}`);
            }
        }
        // check Van center ETA date is working date or note
        // if (value.eta_date != 'Invalid date' && !empty_valueCheck(value.eta_date)) {
        //     const filteredData = calendarList.filter((item) => new Date(item.act_prod_date.split('T')[0]).getTime() == new Date(value.eta_date).getTime());
        //     if (filteredData.length == 0 || filteredData[0]?.work_status == '0') {
        //         warningMessage.push(`Please select another Van center date in row No. ${index + 1} It is non working day`);
        //     }
        // }
        // if (value.deadline_date != 'Invalid date' && !empty_valueCheck(value.deadline_date)) {
        //     const filteredData = calendarList.filter((item) => new Date(item.act_prod_date.split('T')[0]).getTime() == new Date(value.deadline_date).getTime());
        //     if (filteredData.length == 0 || filteredData[0]?.work_status == '0') {
        //         warningMessage.push(`Please select another Deadline date in row No. ${index + 1}. It is non working day`);
        //     }
        // }
        if (value_ == 2) {
            if (empty_valueCheck(value.number_of_pieces) || parseInt(value.number_of_pieces + '') <= 0) {
                warningMessage.push(`Please enter Valid No of Pieces in row No. ${index + 1}`);
            } else
                schedulesNoOfPeace = schedulesNoOfPeace + parseInt(value.number_of_pieces + '');
        } else if (value_ == 1) {
            if (empty_valueCheck(value.number_of_lots) || parseInt(value.number_of_lots + '') <= 0) {
                warningMessage.push(`Please enter Valid No of Lots in row No. ${index + 1}`);
            } else
                schedulesNoOfPeace = schedulesNoOfPeace + parseInt(value.number_of_lots + '');
        }

        if (empty_valueCheck(value.arriving_date)) {
            warningMessage.push(`Arrival date can't ne empty in row No. ${index + 1}`);
        }
        // else {
        //     const filteredData = calendarList.filter(
        //         (item) => new Date(item.act_prod_date.split('T')[0]).getTime() == new Date(value.arriving_date).getTime()
        //     );
        //     if (filteredData.length == 0 || filteredData[0]?.work_status == '0') {
        //         warningMessage.push(`Please select another arrival date in row No. ${index + 1}. It is non working day`);
        //     }
        // }

        if (empty_valueCheck(value.deadline_date))
            warningMessage.push(`Deadline date can't ne empty in row No. ${index + 1}`);
        if (new Date(value.arriving_date).getTime() > new Date(value.deadline_date).getTime())
            warningMessage.push(`Arrival date should be less than or equal to deadline data in row No. ${index + 1}`);
        if (value_ == 1 && value.isArrived && (empty_valueCheck(value.conf_lots) || value.eta_date == 'Invalid date' || empty_valueCheck(value.eta_date)))
            warningMessage.push(`Arrived Flag can only be checked when ETA DAte and Conf lots filled in row No. ${index + 1}`);
        if (value_ == 2 && value.isArrived && (empty_valueCheck(value.conf_pieces) || value.eta_date == 'Invalid date' || empty_valueCheck(value.eta_date)))
            warningMessage.push(`Arrived Flag can only be checked when ETA DAte and Conf pieces filled in row No. ${index + 1}`);
    });
    if (parseInt(peaceofOrderr + '') != schedulesNoOfPeace) {
        if (value_ == 1)
            warningMessage.push(`Total no of lots from the required arrival schedule(s) must be equal to number of lots to order`);
        else if (value_ == 2)
            warningMessage.push(`Total no of pieces from the required arrival schedule(s) must be equal to number of pieces to order`);
        isCountMatched = false;
    }
    return { "warningMessage": warningMessage, "isCountMatched": isCountMatched };
}
function empty_valueCheck(value: any) {
    if (Number.isNaN(value) || value == undefined || value == '' || value == null) return true;
    return false;
}