import { Component, OnInit, Input } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';

@Component({
  selector: 'app-schedule-delete',
  templateUrl: './schedule-delete.component.html',
  styleUrls: ['./schedule-delete.component.scss']
})
export class ScheduleDeleteComponent implements ICellRendererAngularComp {
  params: any
  @Input() partIndex: number;

  refresh(): boolean {
    return false;
  }
  agInit(params): void {
    this.params = params;
  }
  deleteSchedule() {
    this.params.context.componentParent.deleteArrival_schedules(
      this.params.value, this.params.node.rowIndex
    );
  }
}


